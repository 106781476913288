<template>
  <div style="min-height: 500px !important">
    <carousel
      v-if="this.existSlider && this.getVisualWidth > 1048"
      id="customCarouselFullSize"
      :autoplayTimeout="10000"
      :autoplay="true"
      :loop="true"
      :nav="true"
      :mouseDrag="true"
      :checkVisible="true"
      :pullDrag="true"
      :touchDrag="true"
      :dots="false"
      :center="true"
      :showNext="false"
      :showPrev="false"
      :items="1"
      :responsive="{
        0: {
          items: 1,
          loop: false,
          nav: false,
          autoplay: false,
          mouseDrag: false,
          pullDrag: false,
          touchDrag: false,
          navText: ['', ''],
          center: true
        },
        600: {
          items: 1,
          nav: false,
          center: true,
          navText: ['', '']
        }
      }"
      class="carousel-main animate__animated animate__bounceInLeft"
    >
      <div
        v-for="(slider, index) in this.getSliders"
        :key="index"
        :v-show="slider.load || (slider.load && this.getIsMobilResolution && index < 1)"
        :class="{
          'owl-slide d-flex cover background-image-style': true,
          'contenedor-img-carrusel': !getIsMobilResolution,
          'height-movil': getIsMobilResolution
        }"
        :style="'background-image: url(' + slider.url + ');'"
        :onLoad="isLoadImg(slider.url)"
        :data-id="slider.id"
        :data-orden="slider.orden"
        :data-status="slider.status"
      >
        <video v-if="isVideo(slider.img)" :src="slider.urlOem" autoplay loop playsinline muted class="video-bg"></video>
        <img v-else :src="slider.img" />
      </div>
    </carousel>

    <carousel
      v-if="this.existSlider && this.getVisualWidth < 1048 && this.getVisualWidth > 500"
      id="customCarouselFullSize"
      :autoplayTimeout="10000"
      :autoplay="true"
      :loop="true"
      :nav="true"
      :mouseDrag="true"
      :checkVisible="true"
      :pullDrag="true"
      :touchDrag="true"
      :dots="false"
      :center="true"
      :showNext="false"
      :showPrev="false"
      :items="1"
      :responsive="{
        0: {
          items: 1,
          loop: false,
          nav: false,
          autoplay: false,
          mouseDrag: false,
          pullDrag: false,
          touchDrag: false,
          navText: ['', ''],
          center: true
        },
        600: {
          items: 1,
          nav: false,
          center: true,
          navText: ['', '']
        }
      }"
      class="carousel-main animate__animated animate__bounceInLeft"
    >
      <div
        v-for="(slider, index) in this.getSliders"
        :key="index"
        :v-show="slider.load || (slider.load && this.getIsMobilResolution && index < 1)"
        :class="{
          'owl-slide d-flex cover background-image-style': true,
          'contenedor-img-carrusel': !getIsMobilResolution,
          'height-movil': getIsMobilResolution
        }"
        :style="'background-image: url(' + slider.url + ');'"
        :data-id="slider.id"
        :data-orden="slider.orden"
        :data-status="slider.status"
      >
        <video v-if="isVideo(slider.img)" :src="slider.urlOem" autoplay loop class="video-bg"></video>
        <img v-else :src="slider.img" />
      </div>
    </carousel>
    <carousel
      v-if="this.existSlider && this.getVisualWidth < 500"
      id="customCarouselFullSize"
      :autoplayTimeout="10000"
      :autoplay="true"
      :loop="true"
      :nav="true"
      :mouseDrag="true"
      :checkVisible="true"
      :pullDrag="true"
      :touchDrag="true"
      :dots="false"
      :center="true"
      :showNext="false"
      :showPrev="false"
      :items="1"
      :responsive="{
        0: {
          items: 1,
          loop: false,
          nav: false,
          autoplay: false,
          mouseDrag: false,
          pullDrag: false,
          touchDrag: false,
          navText: ['', ''],
          center: true
        },
        600: {
          items: 1,
          nav: false,
          center: true,
          navText: ['', '']
        }
      }"
      class="carousel-main animate__animated animate__bounceInLeft"
    >
      <div
        v-for="(slider, index) in this.getSliders"
        :key="index"
        :v-show="slider.load || (slider.load && this.getIsMobilResolution && index < 1)"
        :class="{
          'owl-slide d-flex cover background-image-style': true,
          'contenedor-img-carrusel': !getIsMobilResolution,
          'height-movil': getIsMobilResolution
        }"
        :onLoad="isLoadImg(slider.urlp)"
        :data-id="slider.id"
        :data-orden="slider.orden"
        :data-status="slider.status"
      >
        <video v-if="isVideo(slider.img)" :src="slider.urlOem" autoplay loop playsinline muted class="video-bg"></video>
        <img v-else :src="slider.img" />
      </div>
    </carousel>
  </div>
</template>
<script>
import { essentialsData } from "../mixins/essentialsDataMixin.js";
import carousel from "vue-owl-carousel2";
import { mapGetters } from "vuex";

export default {
  mixins: [essentialsData],
  components: {
    carousel
  },
  props: {
    appdata: Object,
    cargando: Boolean
  },
  name: "Home",
  computed: {
    existSlider() {
      return this.getSliders && this.getSliders[0] != undefined ? true : false;
    },
    isLoged() {
      return this.getUser && this.getUser != null && this.getUser != undefined ? true : false;
    },
    ...mapGetters(["getUser", "getSliders", "getIsMobilResolution", "getVisualWidth"])
  },
  data() {
    return {
      user: "",
      pass: "",
      deportes: [],
      register: false
    };
  },
  methods: {
    isVideo(nameMedia) {
      return nameMedia.includes("webm") || nameMedia.includes("mp4");
    },
    toPage: async function(page) {
      this.$emit("changePage", page);
    },
    ingresar: function() {
      if (this.user == "" || this.pass == "") {
        this.Swal.fire("Lo sentimos", "Pero no puede dejar campos vacios", "error");
      } else {
        this.$emit("login", { user: this.user, pass: this.pass });
      }
      this.user = "";
      this.pass = "";
    },
    registrarse: async function() {
      // this.Swal.fire("registrarse");
      console.log("registrarse HOME");
      //await this.$userService.registerAccount();
    },
    async isLoadImg(imgUrl) {
      // const delay = await this.$store.dispatch("dalayFor",1000);
      const resp = await this.$store.dispatch("ImgExistInCacheAsync", imgUrl);
      return resp == "ImgLoad" ? true : false;
    },
    openLogin() {
      this.register = !this.register;
    }
  },
  mounted() {
    this.deportes.push(
      {
        index: 0,
        deporte: "Futbol"
      },
      {
        index: 1,
        deporte: "Beisbol"
      }
    );
    document.getElementsByTagName("body")[0].style.backgroundColor = "#001f62";
  },
  beforeDestroy() {
    document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
  }
};
</script>
<style>
.background-image-style {
  height: 100vh;
  background-color: lightgray;
  background-position: center;
  background-position-y: 40%;
  background-size: cover;
}

/* Imagenes fondo
  
   background-size: cover;
   background-position-y: 20%;
   background-size: 100% 100%;

 */

/*@media only screen and (max-width: 1000px) {
  div.owl-item > div.owl-slide {
    background-image: url("~@/assets/img/slider/fondo-mobil2.jpg") !important;
  }
  .resto {
    display: none !important;
  }
}*/
.video-bg {
  margin: 0px;
  padding: 0px;
  position: absolute;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background: brown;
  object-fit: cover;
  background-position: center;
  background-size: 100%;
}

/* .img-fluid {
  height: 50px !important;
  width: 100px !important;
}
.carusel-item2 .owl-item {
  height: 300px !important;
  width: 300px !important;
  top: 15px;
} */
@media only screen and (min-width: 720px) {
  .owl-carousel .owl-stage {
    /* (no va  este espacio crea espacio gris) margin-top: 6em !important;*/
  }
}
#customCarouselFullSize {
  /* height: 34.375em !important; */
}
/*height: 600px !important;  tambien se puede calcular calc(100vh - 96px)*/
.contenedor-img-carrusel {
  height: 600px !important;
}

@media screen and (min-height: 100px) and (max-height: 750px) {
  .height-movil {
    width: 100%;
    top: 1.5em;
    max-height: 35em;
    background-size: 100% 100% !important;
  }
}

@media screen and (min-height: 730px) {
  .height-movil {
    width: 100%;
    max-height: 29em !important;
    background-size: 100% 100% !important;
  }
}

/*@media screen and (min-height: 730px) {
  .height-movil {
    width: 100%;
    height: 23.7rem !important;
  }
}

@media screen and (min-height: 730px) {
  .height-movil {
    width: 100%;
    height: 29.2rem !important;
  }
}*/
</style>
