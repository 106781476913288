<template>
  <div class="text-center animate__animated animate__backInUp top-sem">
    <div class="row">
      <div class="col-2">
        <i
          class="fas fa-chevron-left fa-lg text-light"
          :class="{ 'text-dark': this.getThemelight }"
          v-on:click="back()"
        ></i>
      </div>
      <div class="col-8">
        <div class="text-title" :class="{ 'text-light': !getThemelight, 'light-h1': getThemelight }">
          Reporte semanal
        </div>
      </div>
    </div>
    <!-- <div class="text-right form-inline">
      <i
        class="fas fa-chevron-left fa-lg text-light ml-2"
        :class="{ 'text-dark': this.getThemelight }"
        v-on:click="back()"
      ></i>
    </div>
    <div class="text-center mb-2">
      <span class="title-orange" :class="{ 'text-light': !getThemelight, 'light-h1': getThemelight }">
        Reporte semanal
      </span>
    </div> -->
    <form>
      <div class="row mb-2 mt-2 text-center">
        <v-select
          class="style-chooser"
          placeholder="Seleccione producto"
          :options="products"
          style="width: 100%; color: black"
          :reduce="product => product.clave"
          v-model="product"
          @input="getResults"
          label="valor"
          :clearable="false"
        >
        </v-select>
      </div>
      <div class="row mb-2 mt-2 text-center">
        <v-select
          class="style-chooser"
          placeholder="Seleccione semana"
          :options="weeks"
          style="width: 100%; color: black"
          :reduce="week => week.semana"
          v-model="week"
          @input="getResults"
          label="label"
          :clearable="false"
        >
        </v-select>
      </div>
    </form>
    <div class="card">
      <div class="row text-center border bg-title">
        <div class="col-6 border-right">
          <b>Fecha</b>
        </div>
        <div class="col-6">
          <b>Balance</b>
        </div>
      </div>
      <div class="row text-center" v-for="result of results" v-bind:key="result.fecha">
        <div class="col-6 border-bottom">
          {{ dateFormat(result.fecha) }}
        </div>
        <div class="col-6 text-right border-bottom">
          {{ formatoMonto(result.monto) }}
        </div>
      </div>
      <div class="row text-center border bg-title">
        <div class="col-6 border-right my-2">
          <strong>Total:</strong>
        </div>
        <div class="col-6 my-2 text-right">
          <strong>{{ formatoMonto(total) }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  data() {
    return {
      name: "ReporteSemanal",
      results: [],
      products: [],
      product: 0,
      opciones: { sport: 0 },
      weeks: [
        { semana: 0, label: "Semana actual" },
        { semana: 1, label: "Semana anterior" },
        { semana: 2, label: "Hace 2 semanas" },
        { semana: 3, label: "Hace 3 semanas" },
        { semana: 4, label: "Hace 4 semanas" }
      ],
      week: 0,
      total: 0
    };
  },
  components: {
    vSelect
  },
  methods: {
    back() {
      //this.$emit("toPage", "Cuenta");
      this.$bvModal.show("modal-cuenta");
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    cargarProductos() {
      for (const key in this.getProducts) {
        if (Object.hasOwnProperty.call(this.getProducts, key)) {
          this.products.push({
            clave: key,
            valor: this.getProducts[key]
          });
        }
      }
    },
    async getResults() {
      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${this.getToken.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(process.env.VUE_APP_URL_API + `api/client/sales?${this.product}=${this.week}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.results = result[this.product].rows;
          this.total = result[this.product].total;
        })
        .catch(error => console.log("error", error));
    }
  },
  computed: {
    ...mapGetters([
      "getProducts",
      "getToken",
      "getWeeklyReport",
      "getThemelight",
      "getIsMobilResolution",
      "getVisualWidth"
    ])
  },
  async mounted() {
    //$("#container-preguntas").animate({ scrollTop: 550 }, 600);

    await this.$store.dispatch("getProductsClientAsync");

    if (this.getProducts) {
      this.product = Object.keys(this.getProducts)[0];
      // ya se carga el request al momento de cambiar la pagina
      // para que el request solo se genere cuando se necesite
      // y no se genere peticion en la creacion sino cuando le
      // den click unicamente
      // this.getResults();
      this.cargarProductos();
    }
  }
};
</script>
<style>
.bg-title {
  background: #e8eaea;
}

.title-orange {
  font-size: 1.2em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
  color: rgb(243, 156, 18);
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: white;
  border: none;
  color: gray;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: black;
}

.style-chooser .vs__dropdown-option {
  color: black !important;
  font-weight: 800;
}

.style-chooser .vs__dropdown-option--highlight {
  background-color: #e6eaea;
}
</style>
<style scoped>
.top-sem {
  margin-top: 150px !important;
  height: 60vh;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.text-title {
  font-size: 1.2em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
}
</style>
