<template>
  <div class="container-game">
    <h5
      :class="{ 'text-center mt-2 sub-title': true, 'txt-color-theme-l': this.getThemelight }"
      v-if="this.gameDetails.muestraTitulo"
    >
      {{ this.gameDetails.gameName }}
    </h5>
    <gridNoTable :gameDetails="this.gameDetails"></gridNoTable>
    <JugadasDetailsComponent :gameDetails="gameDetails"></JugadasDetailsComponent>
  </div>
</template>
<script>
import JugadasDetailsComponent from "./JugadasDetailsComponent.vue";
import { juegosDetalles } from "../../../mixins/juegosDetallesMixin.js";
import { mapGetters } from "vuex";
import gridNoTable from "./gridNoTable";

export default {
  name: "gridBaseGame",
  mixins: [juegosDetalles],
  computed: {
    ...mapGetters(["getApuestas", "getFormatoTaquilla", "getThemelight"])
  },
  components: { JugadasDetailsComponent, gridNoTable },
  props: {
    gameDetails: Object
  }
};
</script>
<style>
.sub-title {
  font-weight: 200;
}

.txt-center {
  text-align: center !important;
}
</style>
