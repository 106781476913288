<template>
  <section
    class="animate__animated animate__backInUp"
    :class="{ 'contenedor-live': !getIsMobilResolution, 'section-movil': this.getIsMobilResolution }"
  >
    <div
      v-if="!this.validar"
      :class="{ 'container-fluid': !getIsMobilResolution, 'top-live': this.getIsMobilResolution }"
    >
      <div class="row p-2 container-botonera">
        <div class="col text-center">
          <button
            type="button"
            id="btnV"
            :class="{
              'btn-base border-green btn-jugadas': true
            }"
            @click="openModal()"
          >
            <span> <img v-lazy="'/img/livestream.webp'" /> {{ btnVideo }} </span>
          </button>
        </div>
      </div>
      <div class="row" v-if="!this.getIsMobilResolution">
        <iframe
          v-if="this.getUrliframe && this.getUrliframe.url"
          style="border: 0; width: 100%; height: 100vh; position: absolute; right: 0px; top: 20%"
          :src="this.getUrliframe.url"
          allowfullscreen=""
          webkitallowfullscreen=""
          mozallowfullscreen=""
        >
        </iframe>
      </div>
      <div class="row" v-else>
        <iframe
          v-if="this.getUrliframe && this.getUrliframe.url"
          class="top-movil"
          style="border: 0; width: 100%; height: 100vh; position: absolute; right: 0px"
          :src="this.getUrliframe.url"
          allowfullscreen=""
          webkitallowfullscreen=""
          mozallowfullscreen=""
        >
        </iframe>
      </div>
    </div>

    <div
      v-if="this.validar"
      class="text-center"
      :class="{ 'w-25': !this.getIsMobilResolution }"
      style="margin-top: 10% !important; heigth: 50px !important"
    >
      <div>
        <img v-lazy="'/img/mantenimiento/mantenimiento.png'" width="350" height="250" />
        <div class="form-inline text-danger"></div>
      </div>
    </div>

    <div>
      <b-modal
        id="modal-1"
        title="Video Streaming"
        name="video-streaming"
        size="lg"
        ok-title="Minimizar"
        ok-variant="warning"
        cancel-title="Cerrar"
        cancel-variant="default"
        no-close-on-backdrop
        draggable=".contenedor-modal-streaming"
        scrollable
        v-on:close="closeVideo()"
        v-on:cancel="closeVideo()"
      >
        <div class="contenedor-modal-streaming">
          <div class="text-center" v-if="this.noliveBetHoy">
            <h5>{{ $t("videos_1") }}</h5>
          </div>
          <div v-else>
            <b-tabs nav-wrapper-class="mt-3" v-model="tabIndex">
              <b-tab title="En vivo" title-link-class="color-tabs">
                <ul v-if="getVideo && getVideo != null">
                  <li v-for="item of getVideo.online" v-bind:key="item.id" class="li-custom" @click="watchVideo(item)">
                    {{ item.sport }} <br />
                    {{ item.name }} <br />
                    {{ item.date }}
                    <hr />
                  </li>
                </ul>
              </b-tab>
              <b-tab title="Proximos" title-link-class="color-tabs">
                <ul v-if="getVideo && getVideo != null">
                  <li v-for="item of getVideo.next" v-bind:key="item.id" class="li-custom">
                    {{ item.sport }} <br />
                    {{ item.name }} <br />
                    {{ item.date }}
                    <hr />
                  </li>
                </ul>
              </b-tab>
              <b-tab title="Video" title-link-class="color-tabs">
                <h3 class="text-center">{{ nameGame }}</h3>
                <iframe :src="urlgame" width="100%" height="500px"></iframe>
              </b-tab>
            </b-tabs>
          </div>
        </div>
        <template #modal-footer="{ ok, cancel }">
          <div class="contenedor-boton-footer">
            <b-button size="sm" class="boton-color-modal cerrar-btn" @click="cancel()"> {{ $t("cerrar") }} </b-button>

            <b-button size="sm" class="boton-color-modal" @click="ok()"> {{ $t("minimizar") }} </b-button>
          </div>
        </template>
      </b-modal>
    </div>
    <AvisoIframe v-if="!this.validar" :urlnotice="this.urlnotice" />
    <button
      data-v-70a0ee36=""
      data-toggle="tooltip"
      title="Pantalla completa"
      class="btn-base border-green btn-iframe"
      @click="abriIframeNuevaPagina"
    >
      <i data-v-70a0ee36="" class="fas fa-expand-arrows-alt" aria-hidden="true"></i>
    </button>
  </section>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import AvisoIframe from "../components/AvisoIframe.vue";
import openIframen from "../helpers/openIframen";
export default {
  name: "LiveBetting",
  components: {
    AvisoIframe
  },
  data() {
    return {
      tabIndex: 0,
      urlgame: null,
      nameGame: null,
      videoR: false,
      btnVideo: "Streaming",
      validar: false,
      urlnotice: null,
      noliveBetHoy: true
    };
  },
  computed: {
    ...mapGetters(["getIsMobilResolution", "getVideo", "getUrlLiveBetting", "getUrliframe", "getBonus", "getBalaces"])
  },
  methods: {
    habilitarStreaming() {
      var liveBetHoyBonus = this.getBonus.data.find(
        element =>
          moment(element.fecha).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD") &&
          element.tipo.includes("LIVEBETTING")
      );

      var liveBetHoyBalance = this.getBalaces.data.find(
        element =>
          moment(element.fecha).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD") &&
          element.tipo.includes("LIVEBETTING")
      );

      console.log("liveBetHoyBonus: ", liveBetHoyBonus);
      console.log("liveBetHoyBalance: ", liveBetHoyBalance);

      if (liveBetHoyBonus || liveBetHoyBalance) {
        this.noliveBetHoy = false;
      } else {
        this.noliveBetHoy = true;
      }
    },
    abriIframeNuevaPagina() {
      openIframen();
      //this.back();
    },
    async openModal() {
      await this.actualizaApuestas();
      await this.habilitarStreaming();

      this.tabIndex = 0;
      if (this.videoR) {
        this.tabIndex = 2;
      }
      modalDraggableAction();

      this.$bvModal.show("modal-1");
    },
    isMobilResolution() {
      return this.getIsMobilResolution;
    },
    watchVideo(e) {
      this.tabIndex = 2;
      this.urlgame = e.link;
      this.nameGame = e.name;
      this.videoR = true;
      this.btnVideo = "Reproduciendo";
    },
    closeVideo() {
      this.videoR = false;
      this.btnVideo = "Streaming";
    },
    async actualizaApuestas() {
      const opciones = {
        page: 1,
        perpage: 100
      };

      await this.$store.dispatch("getBonusUserAsync", opciones);
      await this.$store.dispatch("getBalacesUserAsync", opciones);
    }
  },
  mounted() {},
  async created() {
    // verifica la session y extiende por actividad
    await this.$store.dispatch("checkSession");

    const game_url = this.getIsMobilResolution ? 2 : 1;

    await this.$store.dispatch("getLiveBettingAsync", { game_url: game_url });

    localStorage.setItem("url_iframe", this.getUrliframe.url);
    await this.actualizaApuestas();
    await this.habilitarStreaming();

    this.validar = this.getUrliframe.url ? false : true;

    if (this.getUrliframe.notice) {
      this.urlnotice = this.getUrliframe.notice;
      this.$bvModal.show("modal-aviso");
    }

    await this.$store.dispatch("getVideoAsync");
  }
};

function modalDraggableAction() {
  setTimeout(() => {
    $("#modal-1").draggable();
  }, 200);
}
</script>
<style>
.btn.boton-color-modal.cerrar-btn.btn-secondary.btn-sm {
  /* background-color: #ddd !important; */
  background-color: transparent !important;
  color: #636363 !important;
  text-shadow: none;
  border-color: #dee2e6;
  border: solid 1px;
  font-weight: 400;
  text-transform: uppercase;
}

.btn.boton-color-modal.cerrar-btn.btn-secondary.btn-sm:hover {
  background-color: transparent !important;
}

.btn.boton-color-modal.min-btn.btn-secondary.btn-sm {
  border: 0;
  background-image: linear-gradient(178deg, #3e76f3, #001f62) !important;
  color: #eee !important;
  text-transform: uppercase;
  background-color: transparent !important;
  font-size: 15px;
  text-shadow: 1px 1px 3px black;
}

.container-botonera {
  background-color: #1b1b1b;
}
.no-border-no-padding {
  border: 0px;
  margin: 0px;
  padding: 0px;
}
.contenedor-live {
  display: flex;
  overflow: hidden;
  background-color: #333433;
  min-height: 105vh !important;
}
#chFrame {
  border: 0px;
  inset: 125px 0px 0px;
  width: 100%;
  min-height: 2100px;
  padding-bottom: 0px;
  overflow: hidden;
}

.customBarModalStreaming.__vuescroll,
.customBarModalStreaming.__vuescroll.hasVBar {
  height: 100px !important;
  padding-bottom: 10em !important;
}

.modal-content {
  background-color: #ffffffff !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: black !important;
  background-color: #fff;
}

@media only screen and (min-width: 1100px) {
  li.nav-item > a.nav-link.color-tabs {
    color: black !important;
  }
}
@media only screen and (max-width: 600px) {
  #btnV {
    margin-top: -15px;
  }
}

.li-custom {
  color: #555 !important;
  cursor: pointer;
}

.li-custom:hover {
  background: #ddd;
}
.modal-footer {
  background: #fff !important;
}
.contenedor-boton-footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
</style>
<style scoped>
.section-movil {
  height: 115vh;
  overflow: hidden;
}
.btn-iframe {
  top: 5% !important;
}
.top-movil {
  top: 12% !important;
}
.top-live {
  margin-top: 40px !important;
}
.container-fluid {
  margin-top: 120px !important;
}
</style>
