<template>
  <vuescroll class="container-app">
    <header class="header-app">
      <MenuWeb v-if="this.getVisualWidth >= 1100" @changePage="changePage" :appdata="this.$data"></MenuWeb>
      <MenuMovil
        v-if="this.getVisualWidth < 1100"
        :appdata="this.$data"
        @updateMenuMobilStatus="updateMenuMobilStatus"
        @changePage="changePage"
      ></MenuMovil>
    </header>
    <section class="body-app">
      <vuescroll class="contenido-scrollable" :ops="opcionesScroll">
        <p>contenido 1</p>
        <p>contenido 2</p>
        <p>contenido 3</p>
        <p>contenido 4</p>
        <p>contenido 5</p>
        <p>contenido 6</p>
        <p>contenido 7</p>
        <p>contenido 8</p>
        <p>contenido 9</p>
        <p>contenido 10</p>
        <p>contenido {{ this.getVisualWidth }}</p>
        <p>contenido 12</p>
        <p>contenido 13</p>
        <p>contenido 14</p>
        <p>contenido 15</p>
        <p>contenido 16</p>
        <p>contenido 17</p>
        <p>contenido 18</p>
        <p>contenido 19</p>
        <p>contenido 20</p>
        <p>contenido 21</p>
        <p>contenido 22</p>
        <p>contenido 23</p>
        <p>contenido 24</p>
        <p>contenido 25</p>
        <p>contenido 26</p>
        <p>contenido 27</p>
        <p>contenido 28</p>
        <p>contenido 29</p>
        <p>contenido 30</p>
        <p>contenido 31</p>
        <p>contenido 32</p>
        <p>contenido 33</p>
        <p>contenido 34</p>
        <p>contenido 35</p>
        <p>contenido 36</p>
        <p>contenido 37</p>
        <p>contenido 38</p>
        <p>contenido 39</p>
        <p>contenido 40</p>
        <p>contenido 41</p>
        <p>contenido 42</p>
        <p>contenido 43</p>
        <p>contenido 44</p>
        <p>contenido 45</p>
        <p>contenido 46</p>
        <p>contenido 47</p>
        <p>contenido 48</p>
        <p>contenido 49</p>
        <p>contenido 50</p>
      </vuescroll>
    </section>
    <footer
      style="display: block; background-color: #fff"
      :class="{
        'footer footer-app': true,
        'footer-width-table':
          this.activePage == 'Cuenta' || this.activePage == 'Recargas' || this.activePage == 'Retiros'
      }"
    >
      <FooterWeb v-if="this.getVisualWidth >= 1100"></FooterWeb>
    </footer>
  </vuescroll>
</template>
<script>
import { mapGetters } from "vuex";
import vuescroll from "vuescroll";
import MenuMovil from "./components/MenuMovil.vue";
import MenuWeb from "./components/MenuWeb.vue";
import FooterWeb from "./components/FooterWeb.vue";
export default {
  name: "Layout",
  components: {
    vuescroll,
    MenuMovil,
    MenuWeb,
    FooterWeb
  },
  computed: {
    ...mapGetters([
      "getBetInit",
      "getCountries",
      "getBonusTermsConditions",
      "getUser",
      "getPage",
      "getSliders",
      "getExistMinSlidersInCache",
      "getIsMobilResolution",
      "getVisualWidth",
      "getMiniBannersPromociones",
      "getAccounts",
      "getIsIOS"
    ])
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      cargando: true,
      activePage: "Home",
      navbarSolid: false,
      menumobil: false,
      scroll: 0,
      account: this.getUser,
      opcionesScroll: {
        vuescroll: {
          mode: "native",
          sizeStrategy: "percent",
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false
        },
        scrollPanel: {
          initialScrollY: true,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: "right"
        },
        rail: {
          background: "#01a99a",
          opacity: 0,
          size: "4px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "2px",
          keepShow: false
        },
        bar: {
          showDelay: 100,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: "#c1c1c1",
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0.1,
          size: "20px",
          disable: false
        }
      }
    };
  },
  methods: {
    changePage: function(page) {
      this.activePage = page;
      this.$store.dispatch("changePage", page);
    },
    updateMenuMobilStatus: function(status) {
      this.menumobil = status;
    }
  },
  created() {
    addEventListener("resize", event => {
      // actualizando cambio resolucion con evento nativo
      this.$store.commit("setVisualWidth", event.target.screen.width);
      this.$store.commit("setVisualHeight", event.target.screen.height);
    });
  }
};
</script>
<style>
.container-app {
  background-color: #232323;
  display: flex;
  flex-direction: column;
}

.header-app {
  background-color: #c1c4c1;
  min-height: 10vh;
  display: flex;
}

header.header-app > nav.navbar {
  width: 100% !important;
  padding: 0px;
  margin: 0px;
  position: unset !important;
  flex-wrap: wrap;
  max-width: 100vw;
  z-index: 10;
}

.body-app {
  background-color: orange;
  height: 90vh;
  /*
    	height: 80vh;
    	temporal*/
  overflow: hidden;
}

.contenido-scrollable {
  height: 100%;
  min-height: 100vh;
}

footer.footer-app {
  /*background-color: #3daf67 !important;*/
  min-height: 10vh;
}
/* Estilo Scroll principal del body */
body::-webkit-scrollbar {
  width: 0.7em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  opacity: 0.2;
  border-radius: 7px;
}

#navbarToggler > ul.navbar-nav.ml-auto > li.nav-item.dropdown.show > div {
  position: fixed;
  top: auto;
  right: 4em;
}

/*web*/
@media (min-width: 1100px) {
  .body-app {
    padding: 1em 0;
  }
}
/*mobil*/
@media (max-width: 1100px) {
  .body-app {
    padding: 4em 0;
  }
}
</style>
