<template>
  <div>
    <section>
      <iframe
        v-if="!this.validar"
        :class="{
          'estilo-frame': true,
          'table-frame': this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          'movil-frame': this.getVisualWidth < 500
        }"
        :src="this.getUrliframe.url"
      >
        <!--  Antes 
      src="https://play.latpoker.com/sellatuparley/?auth=1101F5C553C21A8C61C846A058C3B3CF&amp;lang=es" -->
      </iframe>

      <div
        v-if="this.validar"
        class="text-center bg-light"
        :class="{ 'w-25': !this.getIsMobilResolution }"
        style="margin-top: 10% !important"
      >
        <div>
          <img v-lazy="'/img/mantenimiento/mantenimiento.png'" width="350" height="250" />
          <div class="form-inline text-danger">
            <!-- <i class="fa fa-angle-double-left fa-lg mx-3"></i> -->
            <!-- <h3><b>Volver</b></h3> -->
          </div>
        </div>
      </div>
      <AvisoIframe v-if="!this.validar" :urlnotice="this.urlnotice" />
    </section>

    <button
      data-v-70a0ee36=""
      data-toggle="tooltip"
      title="Pantalla completa"
      class="btn-base border-green btn-iframe"
      @click="abriIframeNuevaPagina"
    >
      <i data-v-70a0ee36="" class="fas fa-expand-arrows-alt" aria-hidden="true"></i>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AvisoIframe from "../components/AvisoIframe.vue";
import openIframen from "../helpers/openIframen";
export default {
  name: "Poker",
  components: {
    AvisoIframe
  },
  data() {
    return {
      urlIframe: false,
      iframe: 0,
      avisoClosed: false,
      validar: false,
      urlnotice: null
    };
  },
  computed: {
    ...mapGetters(["getIsMobilResolution", "getVisualWidth", "getUser", "getUrliframe"])
  },
  methods: {
    closeAviso() {
      this.avisoClosed = true;
    },
    isVisibleAviso() {
      return this.getUser && this.getUser.center_id == 2 && this.avisoClosed == false ? true : false;
    },
    abriIframeNuevaPagina() {
      openIframen();
    }
  },
  async mounted() {
    localStorage.setItem("url_iframe", null);
    await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=1&provider=Poker" });
    this.validar = this.getUrliframe.url ? false : true;
    localStorage.setItem("url_iframe", this.getUrliframe.url);
    if (this.getUrliframe.notice) {
      this.urlnotice = this.getUrliframe.notice;
      this.$bvModal.show("modal-aviso");
    }
  }
};
</script>
<style scoped>
section {
  height: auto;
}
.estilo-frame {
  padding-top: 6em;
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.table-frame {
  padding-top: 1.1em;
}
.movil-frame {
  padding-top: 1.5em;
  height: 85%;
}
</style>
