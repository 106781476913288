<template>
  <div>
    <section class="animate__animated animate__backInUp top-web">
      <div class="container-fluid" v-if="!this.validar">
        <div class="row py-5" style="overflow: hidden">
          <iframe
            :src="this.getUrliframe.url"
            width="100%"
            frameborder="0"
            :class="{ 'top-movil': this.getIsMobilResolution, 'top-web': !this.getIsMobilResolution }"
          ></iframe>
        </div>
      </div>

      <div
        v-if="this.validar"
        class="text-center bg-light"
        :class="{ 'w-25': !this.getIsMobilResolution }"
        style="margin-top: 10% !important"
      >
        <div>
          <img v-lazy="'/img/mantenimiento/mantenimiento.png'" width="350" height="250" />
          <div class="form-inline text-danger"></div>
        </div>
      </div>
      <AvisoIframe v-if="!this.validar" :urlnotice="this.urlnotice" />
    </section>
    <button
      data-v-70a0ee36=""
      data-toggle="tooltip"
      title="Pantalla completa"
      class="btn-base border-green btn-iframe btn-iframe"
      @click="abriIframeNuevaPagina"
    >
      <i data-v-70a0ee36="" class="fas fa-expand-arrows-alt" aria-hidden="true"></i>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AvisoIframe from "../components/AvisoIframe.vue";
import openIframen from "../helpers/openIframen";

export default {
  name: "Spin500",
  components: {
    AvisoIframe
  },
  data() {
    return {
      isLoad: false,
      validar: false,
      urlnotice: null
    };
  },
  computed: {
    ...mapGetters(["getIsMobilResolution", "getUrliframe"])
  },
  methods: {
    abriIframeNuevaPagina() {
      openIframen();
    }
  },
  async created() {
    localStorage.setItem("url_iframe", null);
    await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=1&provider=spin500" });
    this.validar = this.getUrliframe.url ? false : true;
    localStorage.setItem("url_iframe", this.getUrliframe.url);
    if (this.getUrliframe.notice) {
      this.urlnotice = this.getUrliframe.notice;
      this.$bvModal.show("modal-aviso");
    }
  }
};
</script>
<style scope>
.iframeStyle {
  border: 0;
  position: absolute;
  top: 57px !important;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 0px;
  min-height: 1200px;
}
section {
  overflow: hidden;
  top: 100%;
}
.top-web {
  border: 0;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}
.top-movil {
  top: 0px;
  height: 80vh;
}
</style>
