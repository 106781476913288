<template>
  <div class="container-fluid pt-4">
    <div class="row justify-content-center pb-4">
      <div class="col-md-3"></div>
      <div class="col-md-2 text-right">
        <img
          style="height: 39px; width: 93px"
          v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/gaminglab_certified.jpg'"
          border="0"
        />
      </div>
      <div class="col-md-2 text-center">
        <img
          style="height: 50px; width: 50px"
          v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/iTech-Labs-logo_new.png'"
          border="0"
        />
      </div>
      <div class="col-md-2 text-left">
        <img
          style="height: 38px; width: 38px"
          v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/18_mas.png'"
          border="0"
        />
      </div>
      <div class="col-md-3"></div>
    </div>
    <p class="text-center txt-blanco">{{ $t("footer1") }} : 03-LC-004-PW-2019</p>
    <p class="text-center txt-blanco">
      {{ $t("footer2") }}
    </p>
    <p class="text-center txt-blanco">{{ $t("footer3") }}</p>
    <p class="text-center txt-blanco" style="padding-bottom: 10px">
      Copyright © 2021 Sellatuparley.com {{ $t("footer4") }}.
    </p>
  </div>
</template>
<script>
export default {
  name: "FooterWeb"
};
</script>
