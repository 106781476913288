var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-game"},[_c('details',{staticClass:"details card text-center",class:{ 'details-light text-dark': this.getThemelight, details: !this.getThemelight },on:{"click":function($event){return _vm.abrirDetalle(_vm.gameDetails.muestraTitulo)}}},[_c('summary',{staticClass:"my-2"},[(_vm.gameDetails.muestraTitulo || _vm.show)?_c('div',{staticClass:"titleGame text-center",class:{ 'titleGame-light': this.getThemelight }},[_c('p',[_vm._v(_vm._s(this.gameDetails.gameName)+" ("+_vm._s(this.gameDetails.gameDate)+")")])]):_vm._e(),_c('div',{staticClass:"row text-center",class:{ 'bg-header-light font-weight-bold': this.getThemelight },staticStyle:{"font-size":"0.9em"}},[_c('div',{staticClass:"col-4 border-right"},[_vm._v("\n          "+_vm._s(this.gameDetails.gameTime)+"\n        ")]),_c('div',{staticClass:"col-4 border-right"},[_c('span',[_vm._v(" "+_vm._s(this.gameDetails.teams[0].name)+" (1) ")])]),_c('div',{staticClass:"col-4"},[_c('span',[_vm._v(" "+_vm._s(this.gameDetails.teams[1].name)+" (2) ")])])])]),_c('table',{staticClass:"mb-1",attrs:{"width":"100%","id":"oddsTable"}},[_c('tbody',[(_vm.gameDetails.teams[0]['1X2FT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT1'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            1X2 FT "),_c('br'),_vm._v("\n            (A Ganar)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX1X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX1X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX1X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["1X2FT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX2X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX2X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX2X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["1X2FT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['HDPFT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['HDPFT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT2'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            HDP (FT) "),_c('br'),_vm._v("\n            (Runline)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX3X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX3X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["HDPFT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX4X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX4X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["HDPFT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESFT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESFT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT4'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            TOTALES FT "),_c('br')]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX5X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX5X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["TOTALESFT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX6X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX6X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["TOTALESFT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['1X2HT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['1X2HT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'HT1'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            1 y 2 HT "),_c('br'),_vm._v("\n            (A Ganar)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX1X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX1X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX1X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["1X2HT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX2X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX2X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX2X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["1X2HT"])+"\n          ")])]):_vm._e(),(this.gameDetails.teams[0]['HDPHT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(this.gameDetails.teams[0]['HDPHT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'HT2'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            HDP HT "),_c('br'),_vm._v("\n            (Runline)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX3X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX3X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["HDPHT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX4X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX4X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["HDPHT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESHT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESHT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'HT3'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            TOTALES HT\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX5X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX5X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["TOTALESHT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX6X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX6X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["TOTALESHT"])+"\n          ")])]):_vm._e()])]),(_vm.gameDetails.hayFT || _vm.gameDetails.hayHT)?_c('div',{staticClass:"my-2"},[_c('button',{staticClass:"btn boton-dark",class:{ 'boton-light': this.getThemelight },attrs:{"id":'btnJugadas' + _vm.gameDetails.id},on:{"click":function($event){return _vm.detalle(_vm.gameDetails.id)}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Jugadas\n      ")])]):_vm._e(),(_vm.verDetalle)?_c('div',{staticClass:"mb-1"},[(_vm.gameDetails.hayFT)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        JUEGO COMPLETO (FT)\n      ")]):_vm._e(),(this.gameDetails.par)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Marcador Par/Impar (FT)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX60X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FTX60X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX60X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.par)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX61X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FTX61X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX61X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.impar)+"\n            ")])])])]):_vm._e(),(this.gameDetails.gana1FT)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1 X 2 FT (Sin OT)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX129X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX129X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX129X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1FT)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX130X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX130X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX130X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXFT)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX131X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX131X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX131X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2FT)+"\n              ")])])])])]):_vm._e(),(_vm.gameDetails.hayHT)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        1ERA MITAD (HT)\n      ")]):_vm._e(),(this.gameDetails.parHT)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Marcador Par/Impar (HT)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('HTX60X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'HTX60X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX60X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.parHT)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('HTX61X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'HTX61X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX61X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.imparHT)+"\n            ")])])])]):_vm._e(),(this.gameDetails.gana1HT)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1 X 2 HT")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('HTX129X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'HTX129X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX129X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1HT)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('HTX130X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'HTX130X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX130X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXHT)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('HTX131X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'HTX131X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX131X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2HT)+"\n              ")])])])])]):_vm._e(),(_vm.gameDetails.hayST)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        2DA MITAD (ST)\n      ")]):_vm._e(),(this.gameDetails.parST)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Marcador Par/Impar (ST)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('STX60X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'STX60X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('STX60X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.parST)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('STX61X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'STX61X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('STX61X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.imparST)+"\n            ")])])])]):_vm._e(),(this.gameDetails.gana1ST)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1 X 2 ST")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('STX129X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'STX129X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('STX129X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1ST)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('STX130X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'STX130X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('STX130X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXST)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('STX131X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'STX131X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('STX131X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2ST)+"\n              ")])])])])]):_vm._e(),(_vm.gameDetails.hayFQ)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        1er Periodo (1st Prd)\n      ")]):_vm._e(),(this.gameDetails.gana1FQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1 X 2 (1st Prd)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FQX129X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FQX129X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX129X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1FQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FQX130X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FQX130X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX130X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXFQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FQX131X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FQX131X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX131X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2FQ)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.spreadFQ1)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          P. Spread/HDP´(1st Prd)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FQX3X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FQX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX3X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadFQ1)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FQX4X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FQX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX4X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadFQ2)+"\n            ")])])])]):_vm._e(),(this.gameDetails.totAltaFQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Totales (1st Prd)")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FQX5X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FQX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX5X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totAltaFQ)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FQX6X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FQX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX6X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totBajaFQ)+"\n            ")])])])]):_vm._e(),(this.gameDetails.parFQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Marcador Par/Impar (1st Prd)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FQX60X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FQX60X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX60X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.parFQ)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FQX61X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FQX61X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX61X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.imparFQ)+"\n            ")])])])]):_vm._e(),(_vm.gameDetails.haySQ)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        2do Periodo (2nd Prd)\n      ")]):_vm._e(),(this.gameDetails.gana1SQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1 X 2 (2nd Prd)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('SQX1X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'SQX1X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX1X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1SQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('SQX7X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'SQX7X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX7X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXSQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('SQX2X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'SQX2X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX2X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2SQ)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.spreadSQ1)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          P. Spread/HDP´(2nd Prd)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('SQX3X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'SQX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX3X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadSQ1)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('SQX4X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'SQX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX4X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadSQ2)+"\n            ")])])])]):_vm._e(),(this.gameDetails.totAltaSQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Totales (2nd Prd)")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('SQX5X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'SQX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX5X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totAltaSQ)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('SQX6X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'SQX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX6X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totBajaSQ)+"\n            ")])])])]):_vm._e(),(this.gameDetails.parSQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Marcador Par/Impar (2nd Prd)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('SQX60X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'SQX60X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX60X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.parSQ)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('SQX61X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'SQX61X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX61X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.imparSQ)+"\n            ")])])])]):_vm._e(),(_vm.gameDetails.hayTQ)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        3er Periodo (3rd Prd)\n      ")]):_vm._e(),(this.gameDetails.gana1TQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1 X 2 (3rd Prd)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('TQX1X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'TQX1X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX1X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1TQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('TQX7X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'TQX7X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX7X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXTQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('TQX2X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'TQX2X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX2X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2TQ)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.spreadTQ1)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          P. Spread/HDP´(3rd Prd)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('TQX3X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'TQX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX3X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadTQ1)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('TQX4X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'TQX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX4X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadTQ2)+"\n            ")])])])]):_vm._e(),(this.gameDetails.totAltaTQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Totales (3rd Prd)")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('TQX5X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'TQX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX5X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totAltaTQ)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('TQX6X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'TQX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX6X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totBajaTQ)+"\n            ")])])])]):_vm._e(),(this.gameDetails.parTQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Marcador Par/Impar (3rd Prd)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('TQX60X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'TQX60X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX60X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.parTQ)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('TQX61X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'TQX61X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX61X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.imparTQ)+"\n            ")])])])]):_vm._e(),(_vm.gameDetails.hayQQ)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        4to Periodo (4th Prd)\n      ")]):_vm._e(),(this.gameDetails.gana1QQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1 X 2 (4th Prd)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('QQX1X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'QQX1X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('QQX1X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1QQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('QQX7X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'QQX7X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('QQX7X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXQQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('QQX2X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'QQX2X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('QQX2X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2QQ)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.spreadQQ1)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          P. Spread/HDP´(4th Prd)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('QQX3X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'QQX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('QQX3X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadQQ1)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('QQX4X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'QQX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('QQX4X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadQQ2)+"\n            ")])])])]):_vm._e(),(this.gameDetails.totAltaQQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Totales (4th Prd)")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('QQX5X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'QQX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('QQX5X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totAltaQQ)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('QQX6X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'QQX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('QQX6X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totBajaQQ)+"\n            ")])])])]):_vm._e(),(this.gameDetails.parQQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Marcador Par/Impar (4th Prd)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('QQX60X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'QQX60X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('QQX60X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.parQQ)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('QQX61X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'QQX61X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('QQX61X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.imparQQ)+"\n            ")])])])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }