<template>
  <div class="container-reporte-semanal" v-if="!getIsMobilResolution">
    <div class="row">
      <div class="col">
        <v-select
          class="style-chooser"
          placeholder="Seleccione..."
          :options="products"
          style="width: 100%; color: black"
          v-model="product"
          :reduce="p => p.clave"
          label="valor"
          @input="getResults"
          :searchable="false"
        >
        </v-select>
      </div>
      <div class="col">
        <v-select
          class="style-chooser"
          placeholder="Seleccione..."
          :options="weeks"
          style="width: 100%; color: black"
          :reduce="s => s.semana"
          v-model="week"
          label="label"
          @input="getResults"
          :searchable="false"
        >
        </v-select>
      </div>
    </div>

    <table class="table table-hover table-striped table-light mt-2" v-if="!this.getIsMobilResolution">
      <thead>
        <tr class="text-center">
          <th width="50%">{{ $t("fecha") }}</th>
          <th width="50%">BALANCE</th>
        </tr>
      </thead>
      <tbody v-if="this.results">
        <tr v-for="(r, index) in this.results" :key="index" v-show="results" class="text-center">
          <td>{{ dateFormat(r.fecha) }}</td>
          <td>{{ getSimboloMoneda }}{{ formatoMonto(r.monto) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import vSelect from "vue-select";

export default {
  components: {
    vSelect
  },
  data() {
    return {
      name: "ReporteSemanal",
      results: [],
      products: [],
      product: null,
      opciones: { sport: 0 },
      weeks: [
        { semana: 0, label: "Semana actual" },
        { semana: 1, label: "Semana anterior" },
        { semana: 2, label: "Hace 2 semanas" },
        { semana: 3, label: "Hace 3 semanas" },
        { semana: 4, label: "Hace 4 semanas" }
      ],
      week: 0,
      total: 0
    };
  },
  methods: {
    toPage: function(page) {
      this.$emit("changePage", page);
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    cargarProductos() {
      for (const key in this.getProducts) {
        if (Object.hasOwnProperty.call(this.getProducts, key)) {
          this.products.push({
            clave: key,
            valor: this.getProducts[key]
          });
        }
      }
    },
    async getResults() {
      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${this.getToken.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(process.env.VUE_APP_URL_API + `api/client/sales?${this.product}=${this.week}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.results = result[this.product].rows;
          this.total = result[this.product].total;
        })
        .catch(error => console.log("error", error));
    }
  },
  computed: {
    ...mapGetters(["getProducts", "getToken", "getWeeklyReport", "getIsMobilResolution", "getSimboloMoneda"])
  },
  async mounted() {
    if (!this.getProducts) {
      await this.$store.dispatch("getProductsClientAsync");
    }
    if (this.getProducts) {
      this.product = Object.keys(this.getProducts)[0];
      this.cargarProductos();
      this.getResults();
    }
  }
};
</script>

<style scoped>
#reporte > div > table > tbody > tr:hover,
.table.table-light.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: #3c507c !important;
}

.table.table-light.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(229 229 229 / 58%);
}

#reporte > div > table > tbody > tr:hover td,
#reporte > div > table > tbody > tr:hover td a {
  color: #fff;
}
</style>
