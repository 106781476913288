import { render, staticRenderFns } from "./Juegos.vue?vue&type=template&id=1c51f626&scoped=true&"
import script from "./Juegos.vue?vue&type=script&lang=js&"
export * from "./Juegos.vue?vue&type=script&lang=js&"
import style0 from "./Juegos.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Juegos.vue?vue&type=style&index=1&id=1c51f626&scoped=scoped&lang=css&"
import style2 from "./Juegos.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c51f626",
  null
  
)

export default component.exports