<template>
  <select name="targetLimitResults" v-model="seletion" @change="updateTarget">
    <option v-for="(opcion, index) in this.opciones" :value="opcion" :key="index">{{ opcion }}</option>
  </select>
</template>
<script>
export default {
  name: "targetSelector",
  props: {
    rango: Array,
    selectTarget: Number
  },
  data() {
    return {
      opciones: [10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      seletion: 10
    };
  },
  methods: {
    updateTarget(target) {
      this.$emit("changeSelection", target.target.value);
    }
  },
  mounted() {
    this.seletion = this.selectTarget;
  }
};
</script>
