<template>
  <div class="container text-center" id="container-slots">
    <header
      v-if="!this.urlIframe"
      class="fixed-top mb-5"
      :class="{ 'bg-light': this.getThemelight }"
      style="margin-top: 7em; background-color: #232323; z-index: 1"
      id="header"
    >
      <!-- <div class="mx-2 mt-2">
        <img src="https://sellatuparley.com/img/iconos/banner_cashday_playson.webp" style="width: 100%" alt="" />
      </div> -->
      <div class="banner mx-2 mt-2">
        <carousel
          id="carouselBannersSlots"
          :dots="false"
          :autoplay="true"
          :loop="true"
          :center="true"
          :responsive="{
            0: { items: 1, nav: false, center: true, loop: true, autoplay: true },
            600: { items: 1, nav: false, center: true }
          }"
        >
          <div class="row center-block text-center">
            <a href="https://sellatuparley.com/pages/money-train/">
              <img
                src="https://sellatuparley.com/img/iconos/banner_cashday_playson.webp"
                style="width: 100%; max-height: 150px; margin: 0 auto"
              />
            </a>
          </div>

          <div class="row center-block text-center">
            <a href="https://sellatuparley.com/pages/fesstive-fire-place/" target="_blank">
              <img
                src="https://sellatuparley.com/img/iconos/banner_boongo_Fesstivefireplace.webp"
                class="img-responsive"
                style="margin: 0 auto"
              />
            </a>
          </div>

          <div class="row center-block text-center">
            <a href="https://sellatuparley.com/pages/dropsandwins/" target="_blank">
              <img
                src="https://sellatuparley.com/img/iconos/banner_dropswinswinter_slots.webp"
                class="img-responsive"
                style="margin: 0 auto"
              />
            </a>
          </div>

          <div class="row center-block text-center">
            <a href="https://sellatuparley.com/pages/merry-prize-month/" target="_blank">
              <img
                src="https://sellatuparley.com/img/iconos/banner_playson_Marryprizemonth.webp"
                class="img-responsive"
                style="margin: 0 auto"
              />
            </a>
          </div>
        </carousel>
      </div>

      <div class="my-1 text-center header-slot">
        <div class="row">
          <div class="col-5 mt-1">
            <div class="ml-3 mt-1 text-left" @click="ocultarHeader()" v-if="this.isLandscape">
              <i class="fa fa-chevron-up bg-success p-1" style="font-size: 1em"></i>
            </div>
          </div>
          <div class="col-6">
            <div class="input-group mt-1">
              <input
                type="text"
                class="form-control"
                placeholder="Buscar"
                aria-label="Buscar"
                aria-describedby="basic-addon1"
                style="height: 2em"
                v-model="strSlot"
                @input="buscarSlot($event)"
              />
            </div>
          </div>
        </div>
      </div>

      <carousel
        :loop="true"
        :nav="false"
        :mouseDrag="true"
        :checkVisible="true"
        :pullDrag="true"
        :touchDrag="true"
        :dots="false"
        :center="false"
        :showNext="false"
        :showPrev="false"
        :items="4"
        :rewind="true"
        style="border-bottom: 1px solid rgb(116, 114, 114)"
        id="carousel-slots"
        :class="{ 'fondo-slots-light': getThemelight, 'fondo-slots': !getThemelight }"
      >
        <div
          class="text-light my-2 ml-2 fondo-carrusel"
          style="height: 3.5em"
          v-for="categoria of this.categorias"
          v-bind:key="categoria.id"
          id="slots"
          :class="{ actived: categoria.type == 'todos' }"
        >
          <div class="item" style="line-height: 1" :id="categoria.type">
            <img class="text-center mt-1" :src="categoria.icon" style="width: 25px" />
            <div class="box">
              <div style="font-size: 0.8em">{{ categoria.label }}</div>
            </div>
          </div>
        </div>
      </carousel>
      <div v-if="showManufacturers">
        <carousel
          :loop="false"
          :nav="true"
          :mouseDrag="true"
          :checkVisible="true"
          :pullDrag="true"
          :touchDrag="true"
          :dots="true"
          :center="false"
          :showNext="true"
          :showPrev="true"
          :items="3"
          :rewind="true"
          class="text-center"
          style="border-bottom: 1px solid rgb(116, 114, 114)"
          id="carousel-fab"
        >
          <div
            class="text-light my-2 fondo-carrusel-fab d-flex justify-content-around"
            v-for="manufacturer of this.manufacturers"
            v-bind:key="manufacturer"
            id="slots-fab"
          >
            <div class="box" v-on:click="filtrarSlotsFab(manufacturer)" :id="manufacturer">
              <b>{{ manufacturer }}</b>
            </div>
          </div>
        </carousel>
      </div>
    </header>

    <div
      class="fixed-top ml-3 text-left"
      style="margin-top: 8em"
      @click="ocultarHeader()"
      v-if="this.hideHeader && this.isLandscape"
    >
      <i class="fa fa-chevron-down bg-success p-1" style="font-size: 2em"></i>
    </div>

    <div>
      <iframe v-if="this.iframeOpen" :src="this.urlIframe" frameborder="0" class="iframe-game mt-2"></iframe>
    </div>

    <div class="row">
      <iframe
        v-if="this.urlIframe"
        style="border: 0; width: 100%; position: absolute; right: 0px"
        :src="this.urlIframe"
        :class="{ 'top-landscape': this.isLandscape, 'top-portrait': !this.isLandscape }"
        allowfullscreen=""
        webkitallowfullscreen=""
        mozallowfullscreen=""
      >
      </iframe>
    </div>

    <div v-if="!this.isLandscape">
      <button class="btn bg-transparent btn-sm btn-back" v-if="this.urlIframe" @click="closeSlot()">
        <i class="fas fa-angle-left text-light" style="font-size: 25px"></i>
      </button>

      <button
        data-v-70a0ee36=""
        data-toggle="tooltip"
        title="Pantalla completa"
        class="btn-base border-green btn-iframe-slot"
        v-if="this.iframeOpen"
        @click="abriIframeNuevaPagina"
      >
        <i data-v-70a0ee36="" class="fas fa-expand-arrows-alt" aria-hidden="true"></i>
      </button>
    </div>

    <div
      id="slot-list"
      v-if="!this.iframeOpen"
      :class="{
        'bg-light': this.getThemelight,
        'list-slots': !this.showManufacturers,
        'list-slots-2': this.showManufacturers
      }"
    >
      <div class="flexbox">
        <div v-for="slot of this.dataResults" v-bind:key="slot.dd" style="z-index: 0">
          <i
            class="fa fa-lg text-warning"
            :class="{ 'fa-star': slot.id && estaEnFavoritos(slot.id), 'fa-star-o': !estaEnFavoritos(slot.id) }"
            style="position: relative; right: 10px; top: 18px; font-size: 20px"
            @click="clickFavorito(slot)"
          ></i>
          <img
            src="https://www.sellatuparley.com/img/iconos/sup-topten.png"
            class="img-responsive"
            style="position: relative; height: inherit !important; width: 30% !important; top: 18px"
          />
          <img
            src="https://www.sellatuparley.com/img/iconos/dropandwins.jpg"
            class="img-responsive"
            style="position: relative; height: inherit !important; width: inherit !important; top: 18px; right: -5px"
          />
          <a
            href="#"
            @click="clickOpenGame($event, urlBaseLink + 'game_url=' + slot.id + '&provider=' + slot.provider)"
            class="text-light"
            :class="{ 'text-dark': getThemelight }"
          >
            <img
              :src="urlCdn + 'slots/' + slot.provider + '/' + slot.id + '.png'"
              v-lazy="urlCdn + 'slots/' + slot.provider + '/' + slot.id + '.png'"
              class="img-fluid"
              style="border-radius: 25px"
            />
            <p class="mt-2" style="font-size: 14px">{{ slot.name }}</p>
          </a>
        </div>
      </div>
    </div>

    <div>
      <button
        class="btn boton-green"
        v-on:click="verMas()"
        v-if="this.totalSlots > this.dataResults.length && !this.iframeOpen"
      >
        Ver màs
      </button>
    </div>

    <div class="my-3">
      <img
        style="max-height: 100%; max-width: 100%"
        src="https://sellatuparley.com/img/marquesina_movil_slots.gif"
        border="0"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import carousel from "vue-owl-carousel";
import { slotsFavoritosMixin } from "../../mixins/slotsFavoritosMixin.js";
import { filtrarSlotsMixin } from "../../mixins/filtrarSlotsMixin.js";
import openIframen from "../../helpers/openIframen";

export default {
  name: "SlotsMovil",
  mixins: [slotsFavoritosMixin, filtrarSlotsMixin],
  data() {
    return {
      strSlot: "",
      categoria: "",
      categorias: [
        { id: 1, type: "todos", label: "Todos", icon: "/img/slots/todos.svg" },
        { id: 3, type: "Bingo", label: "Bingo", icon: "/img/slots/bingo.svg" },
        { id: 4, type: "Keno", label: "Video Keno", icon: "/img/slots/keno.svg" },
        { id: 5, type: "table", label: "Mesa", icon: "/img/slots/mesa.svg" },
        { id: 6, type: "card", label: "Cartas", icon: "/img/slots/cartas.svg" },
        { id: 7, type: "poker", label: "Poker", icon: "/img/slots/poker.svg" },
        { id: 8, type: "nuevos", label: "Nuevos", icon: "/img/slots/nuevos.svg" },
        { id: 9, type: "favorites", label: "Favoritos", icon: "/img/slots/favoritos.svg" },
        { id: 10, type: "drop", label: "Drop & Wins", icon: "/img/slots/cartas.svg" },
        { id: 11, type: "weekly", label: "Weekly Madness", icon: "/img/slots/cartas.svg" },
        { id: 12, type: "jelly", label: "Jelly Wins", icon: "/img/slots/cartas.svg" },
        { id: 13, type: "13", label: "Fabricantes", icon: "/img/slots/fabricantes.svg" },
        { id: 14, type: "tetris", label: "Tetris", icon: "/img/slots/tetris.svg" }
      ],
      categories: [],
      dataSlots: [],
      dataResults: [],
      slotResults: [],
      isLandscape: false,
      showManufacturers: false,
      urlIframe: "",
      hideHeader: false,
      iframeOpen: false,
      urlCdn: process.env.VUE_APP_CDN_SELLA,
      urlBaseLink: "https://apipwa.sellatuparley.com/api/client?",
      position: 1,
      cantSlotXPag: 9,
      totalSlots: 0
    };
  },
  computed: {
    ...mapGetters(["getThemelight", "getDataSlots", "getVisualWidth", "getVisualHeight", "getFavotitos"])
  },
  components: {
    carousel
  },
  watch: {
    getVisualWidth(w) {
      this.isLandscape = w > this.getVisualHeight;
      //document.getElementById("header").style.display = "block";
      this.hideHeader = false;
    },
    getThemelight(t) {
      if (t) {
        document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
      } else {
        document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
      }
    }
  },
  methods: {
    abriIframeNuevaPagina() {
      openIframen();
      //this.back();
    },
    ocultarHeader() {
      if (!this.hideHeader) {
        //document.getElementById("header").style.display = "none";
        document.getElementById("header").classList = "animate__animated animate__backOutLeft";
      } else {
        document.getElementById("header").classList = "fixed-top animate__animated animate__backInLeft";
        document.getElementById("header").style.display = "block";
      }
      this.hideHeader = !this.hideHeader;
    },
    buscarSlot(e) {
      if (e.target.value.length > 0)
        this.dataResults = this.dataSlots.filter(slot =>
          slot.name
            .toString()
            .toLowerCase()
            .includes(e.target.value.toString().toLowerCase())
        );
      else this.dataResults = this.dataSlots;

      this.totalSlots = this.dataResults.length;
    },
    closeSlot() {
      this.iframeOpen = false;
      this.urlIframe = "";
      this.$store.commit("setIframeActive", false);
      this.cargarData();
    },
    abrirDetalle() {
      $("details").on("click", function() {
        $("details[open]")
          .not(this)
          .removeAttr("open");
      });
    },
    async clickOpenGame(evt, link) {
      evt.preventDefault();
      this.$store.commit("showLoader");

      console.log("Url: ", link);

      const dataUrlIframe = await this.$store.dispatch("activarSlotGetURL", link);
      if (dataUrlIframe.game_url) {
        this.urlIframe = dataUrlIframe.game_url.url;
        this.iframeOpen = true;
        this.$store.commit("setIframeActive", true);

        localStorage.setItem("url_iframe", dataUrlIframe.game_url.url);
      } else if (!dataUrlIframe.game_url) {
        this.$store.commit("hideLoader");
        this.Swal.fire(
          "Ha ocurrido un error",
          "en estos momentos no se escuentra disponible intente mas tarde",
          "error"
        );
      }

      this.$store.commit("hideLoader");
    },
    estaEnFavoritos(id) {
      return (
        this.getFavotitos.filter(f => {
          return f.id && f.id == id;
        }).length > 0
      );
    },
    async filtrarSlots(categoria) {
      this.strSlot = "";
      this.dataSlots = [];
      this.position = 1;

      if (categoria == 13) {
        this.showManufacturers = true;
      } else {
        this.showManufacturers = false;
      }

      var slotFiltrados = await this.filtrarCategoria(categoria);
      this.dataSlots = slotFiltrados.length > 0 ? slotFiltrados : [];
      this.dataResults = this.dataSlots.slice(0, this.cantSlotXPag * this.position);
      this.totalSlots = this.dataSlots.length;
      this.top();
    },
    async filtrarSlotsFab(manufacturer) {
      var slotsfiltrados = [];
      this.dataSlots = [];

      // this.getDataSlots.games.forEach(element => {
      //   if (element.manufacturer == manufacturer) {
      //     slotsfiltrados.push(element);
      //   }
      // });

      slotsfiltrados = this.filtrarFabricante(manufacturer);

      $("#carousel-fab").on("click", ".box", function() {
        $("#carousel-fab #slots-fab").removeClass("actived");
        $("#carousel-fab #slots-fab #" + this.id)
          .parent()
          .addClass("actived");
      });

      this.dataSlots = slotsfiltrados.length > 0 ? slotsfiltrados : this.getDataSlots.games;
      this.dataResults = this.dataSlots.slice(0, this.cantSlotXPag * this.position);
      this.totalSlots = this.dataSlots.length;
    },
    verMas() {
      this.$store.commit("showLoader");
      this.position++;
      setTimeout(() => {
        this.$store.commit("hideLoader");
        this.dataResults = this.dataSlots.slice(0, this.cantSlotXPag * this.position);
      }, 2000);
    },
    top() {
      $("html, #container-slots").animate({ scrollTop: 0 }, 600);
    },
    async cargarData() {
      let me = this;

      this.checkFavoritos();

      if (this.getThemelight) {
        document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
      } else {
        document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
      }

      this.$store.commit("showLoader");

      await this.$store.dispatch("searchSlots");

      console.log("datos : ", this.getDataSlots);

      this.$store.commit("hideLoader");

      this.totalSlots = this.getDataSlots.games.length;
      this.dataSlots = this.getDataSlots.games;
      this.dataResults = this.dataSlots.slice(0, this.cantSlotXPag);
      this.manufacturers = this.getDataSlots.manufacturers;

      $("#carousel-slots").on("click", ".item", function() {
        $("#carousel-slots #slots").removeClass("actived");
        $("#carousel-slots #slots #" + this.id)
          .parent()
          .addClass("actived");
        me.filtrarSlots(this.id);
      });
    }
  },
  async mounted() {
    console.clear();

    await this.cargarData();

    this.filtrarSlots("todos");
  }
};
</script>
<style scoped="scoped">
@media screen and (max-height: 700px) {
  .container {
    padding-top: 1em !important;
  }
}
.btn-iframe-slot {
  top: 10% !important;
  position: fixed !important;
  z-index: 100 !important;
  width: 25px !important;
  height: 25px !important;
  padding: 0px !important;
  background: #6bff00 !important;
  color: #08680c !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 2px !important;
  margin-left: 40% !important;
}

@media (max-height: 650px) {
  .btn-iframe-slot {
    top: 13% !important;
  }
}

.iframe-game {
  border: 0;
  width: 100%;
  height: 600px;
  margin: 0px;
  padding: 0px;
}

.list-group-item {
  font-size: 1em;
  font-weight: 600;
  background: #00387f;
  padding: 6px !important;
}

.slider-categoria {
  width: 100%;
  background: white;
  height: 2.5em;
  color: white;
}
.header-slot {
  width: 96%;
  background: #00387f;
  height: 2.5em;
  color: white;
  border-radius: 10px 10px 0px 0px;
}
.col-4 img {
  border-radius: 10px 10px;
}
p {
  font-family: poppins, sans-serif !important;
  font-size: 14px;
  font-weight: 600;
}
.card {
  font-size: 1em !important;
  background-image: linear-gradient(-90deg, #728cfa, #2d4dd7);
  color: #ffffff !important;
}

.flexbox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.flexbox > div {
  width: 30%;
}

h4 {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  opacity: 0.7;
}

.fa-lg .fa-star {
  color: yellow;
  font-size: 24px;
}

.fa-star-o {
  text-shadow: 0 0 2px yellow;
}

.fondo-carrusel {
  /* background-image: linear-gradient(-90deg, #728cfa, #2d4dd7); */
  border: 0.2px solid rgb(116, 114, 114);
  border-radius: 10px 10px;
  width: 5em;
}

.fondo-carrusel-fab {
  font-size: 0.8em;
  height: 2.5em;
  background-image: linear-gradient(-90deg, #000000, #8b8b8b);
  border: 0.2px solid rgb(116, 114, 114);
  border-radius: 10px 10px;
  width: 8.5em;
}

#carousel-slots #slots {
  background-image: linear-gradient(-90deg, #000000, #8b8b8b);
}

/* #carousel-slots #slots:hover {
  background-image: linear-gradient(-90deg, #000000, #8b8b8b);
  background: linear-gradient(90deg, #f09819, #ff512f) !important;
} */

.actived {
  background: linear-gradient(90deg, #f09819, #ff512f) !important;
}

.fondo-slots {
  background-color: #2c498b;
}

.fondo-slots-light #slots {
  background-image: linear-gradient(-90deg, #728cfa, #2d4dd7);
}

.fondo-slots-light #slots:hover {
  background: linear-gradient(90deg, #f09819, #ff512f) !important;
}

.box {
  display: flex;
  align-self: center;
  height: 1.5em;
}

.box div {
  font-weight: 600;
}

.hide {
  display: none;
}

.list-slots {
  margin-top: 12em;
}

.list-slots-2 {
  margin-top: 14em;
}

.btn-back {
  z-index: 1000;
  position: fixed;
  border: 1px solid white;
  bottom: 2%;
  left: 5%;
  height: 35px;
  width: 50px;
}

/* .list-landscape {
  margin-top: 1em;
} */

.top-landscape {
  top: 0;
  height: 100%;
}

.top-portrait {
  top: 18%;
  height: 83vh;
}
</style>
