import axios from "axios";

const baseApiPWA = axios.create({
  baseURL: process.env.VUE_APP_URL_API
});

baseApiPWA.interceptors.request.use(
  config => {
    const token = localStorage.getItem("api_tk");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
export default baseApiPWA;
