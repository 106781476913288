export const getLeaguesStore = state => {
  return state.leagues;
};

export const getGamesStore = state => {
  return state.games;
};

export const getGamesType = state => {
  return state.gamesType;
};

export const getOdds = state => {
  return state.odds;
};

export const getFormat = state => {
  return state.format;
};

export const getPrize = state => {
  return state.prize;
};

export const getAmount = state => {
  return state.amount;
};

export const getBetType = state => {
  return state.betType;
};
export const getMinTicket = state => {
  return state.minTicket;
};
export const getFloatTicket = state => {
  return state.floatTicket;
};
export const getOddsRestricteds = state => {
  return state.oddsRestricteds;
};
export const getMarkRestricted = state => {
  return state.markRestricted;
};
export const getBetInitData = state => {
  return state.betInitData;
};
export const getMsjError = state => {
  return state.msjError;
};
