<template>
  <section>
    <!-- <div class="container-aviso" v-show="isVisibleAviso && !this.avisoClosed && this.isLoad">
      <label class="lbl-closed" v-on:click="closeAviso">x</label>
      <img
        width="300"
        height="300"
        v-lazy="'https://sellatuparley.com/img/evenbet/atencion_dolares.jpg'"
        v-if="this.getIsMobilResolution"
      />
      <img v-lazy="'https://sellatuparley.com/img/evenbet/atencion_dolares.jpg'" v-else />
    </div> -->
    <AvisoIframe v-if="!this.validar" :urlnotice="this.urlnotice" />

    <FullLoader :cargando="!this.isLoad && !this.getIsMobilResolution"></FullLoader>
    <iframe
      :class="{
        'estilo-frame': true,
        'table-frame': this.getVisualWidth < 1280 && this.getVisualWidth > 500,
        'movil-frame': this.getVisualWidth < 500
      }"
      v-if="this.getUrliframe"
      :src="this.getUrliframe.url"
      @load="cargoIframePropsBetting"
    >
    </iframe>

    <div
      v-if="this.validar"
      class="text-center bg-light"
      :class="{ 'w-25': !this.getIsMobilResolution }"
      style="margin-top: 10% !important"
    >
      <div>
        <img v-lazy="'/img/mantenimiento/mantenimiento.png'" width="350" height="250" />
        <div class="form-inline text-danger">
          <!-- <i class="fa fa-angle-double-left fa-lg mx-3"></i> -->
          <!-- <h3><b>Volver</b></h3> -->
        </div>
      </div>
    </div>
    <button
      data-v-70a0ee36=""
      data-toggle="tooltip"
      title="Pantalla completa"
      class="btn-base border-green btn-iframe"
      @click="abriIframeNuevaPagina"
    >
      <i data-v-70a0ee36="" class="fas fa-expand-arrows-alt" aria-hidden="true"></i>
    </button>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import FullLoader from "../components/FullLoader.vue";
import AvisoIframe from "../components/AvisoIframe.vue";
import openIframen from "../helpers/openIframen";
export default {
  name: "PropsBetting",
  components: {
    FullLoader,
    AvisoIframe
  },
  data() {
    return {
      urlIframe: false,
      iframe: 0,
      avisoClosed: false,
      isLoad: false,
      validar: false,
      urlnotice: null
    };
  },
  computed: {
    ...mapGetters(["getIsMobilResolution", "getVisualWidth", "getUser", "getUrliframe"])
  },
  methods: {
    cargoIframePropsBetting() {
      this.isLoad = true;
    },
    closeAviso() {
      this.avisoClosed = true;
    },
    isVisibleAviso() {
      return this.getUser && this.getUser.center_id == 2 && this.avisoClosed == false ? true : false;
    },
    abriIframeNuevaPagina() {
      openIframen();
      //this.back();
    }
  },
  async created() {
    // verifica la session y extiende por actividad
    await this.$store.dispatch("checkSession");
  },
  async mounted() {
    // await this.$store.dispatch("searchUrlPropsAsync");
    await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=1&provider=props" });
    this.validar = this.getUrliframe.url ? false : true;

    console.log("url: ", this.getUrliframe.url);
    localStorage.setItem("url_iframe", this.getUrliframe.url);

    if (this.getUrliframe.notice) {
      this.urlnotice = this.getUrliframe.notice;
      this.$bvModal.show("modal-aviso");
    }
  }
};
</script>
<style scoped>
.container-aviso {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: fixed;
  /*top: 120px;*/
  top: 0px;
  left: 0;
  transform: translate(calc(50vw - 50%), calc(50vh - 50%));
}
.lbl-closed {
  position: absolute;
  color: #fff;
  top: 0;
  right: 0.5em;
  font-weight: 300 !important;
  font-size: x-large;
  cursor: pointer;
}
.estilo-frame {
  padding-top: 6em;
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.table-frame {
  padding-top: 1.1em;
}
.movil-frame {
  padding-top: 1.5em;
  height: 85%;
}
</style>
