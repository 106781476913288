<template>
  <div class="text-center animate__animated animate__backInUp top-bonus">
    <div class="row">
      <div class="col-1">
        <i
          class="fas fa-chevron-left fa-lg text-light"
          :class="{ 'text-dark': this.getThemelight }"
          v-on:click="back()"
        ></i>
      </div>
      <div class="col-10">
        <div class="text-title" :class="{ 'text-light': !getThemelight, 'light-h1': getThemelight }">
          Estado de Bonus
        </div>
      </div>
    </div>
    <div style="height: 550px; !important; overflow: scroll; padding-bottom: 100px">
      <div class="card mt-2" v-for="bonus of results" v-bind:key="bonus.id">
        <div class="row text-center border bg-title">
          <div class="col-6 border-right">
            <b>Ticket</b>
          </div>
          <div class="col-6">
            <b>Fecha</b>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-6">
            {{ bonus.ticket }}
          </div>
          <div class="col-6">
            {{ dateFormat(bonus.fecha) }}
          </div>
        </div>
        <div class="text-center border bg-title">
          <b>Tipo</b>
        </div>
        <div class="row text-center">
          <div class="col">{{ bonus.tipo }}</div>
        </div>
        <div class="row text-center border bg-title">
          <div class="col-6">
            <b>Monto</b>
          </div>
          <div class="col-6">
            <b>Saldo</b>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-6">
            {{ formatoMonto(bonus.monto) }}
          </div>
          <div class="col-6">
            {{ formatoMonto(bonus.saldo) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  name: "BonusTableMovil",
  data() {
    return {
      results: [],
      position: 1,
      disablePager: false
    };
  },
  computed: {
    ...mapGetters(["getBonus", "getThemelight", "getPage"])
  },
  methods: {
    back() {
      //this.$emit("toPage", "Cuenta");
      this.$bvModal.show("modal-cuenta");
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    handleScroll() {
      // Any code to be executed when the window is scrolled
      if (
        $(window).scrollTop() >= $(document).height() - $(window).height() &&
        !this.disablePager &&
        this.getPage == "BonusTable"
      ) {
        this.$store.commit("showLoader");
        this.position++;
        setTimeout(() => {
          this.$store.commit("hideLoader");
          this.results = this.getBonus.data.slice(0, 5 * this.position);
        }, 1000);
      }
    },
    async getResults() {
      if (this.getBonus) this.results = await this.getBonus.data.slice(0, 5);
    }
  },
  async mounted() {
    await this.$store.dispatch("getBonusUserAsync");

    if (this.getThemelight) {
      document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
    }

    $("#container-preguntas").animate({ scrollTop: 550 }, 600);
    this.getResults();
  }
};
</script>

<style scoped>
.bg-title {
  background: #e8eaea;
}

.title-orange {
  font-size: 1.2em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
  color: rgb(243, 156, 18);
}

.top-bonus {
  margin-top: 150px !important;
  height: auto;
  min-height: 300px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
</style>
