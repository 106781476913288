<template>
  <div>
    <!-- Modal Chat -->
    <div class="modal fade" id="chatModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content modal-content-chat">
          <!-- <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
          <div class="modal-body">
            <div style="width: 100%; text-align: center">
              <img v-lazy="'/img/logo/sellatuparley.png'" width="150px" height="150px" alt="logo" /> <br />
              <h4 class="titulo1">
                {{ $t("bienvenido_1") }} <br />
                {{ $t("bienvenido_2") }}
              </h4>
              <br />
              <div class="boton">
                <a href="#" v-on:click="openZopin">
                  <img
                    v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/icon_chat.png'"
                    width="80"
                    height="80"
                  />
                  <h6 class="titulo1">Chat</h6>
                </a>
              </div>
              <br />
              <div class="boton">
                <a href="https://api.whatsapp.com/send?phone=584242592048&amp;text=" target="_blank">
                  <img
                    v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/icon_whatsapp.png'"
                    width="80"
                    height="80"
                  />
                  <h6 class="titulo1">Whatsapp</h6>
                </a>
              </div>
              <br />
              <div class="boton">
                <a href="https://t.me/sellatuparleyoficial/" target="_blank">
                  <img
                    v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/icon_telegram.png'"
                    width="80"
                    height="80"
                  />
                  <h6 class="titulo1">Telegram</h6>
                </a>
              </div>
              <br />
              <div>
                <button type="button" class="btn btn-cerrar btn-secondary" data-dismiss="modal">
                  {{ $t("cerrar") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { chatMixin } from "../mixins/chatMixin";
export default {
  mixins: [chatMixin],
  components: {},
  methods: {
    async openZopin() {
      await this.importLibChat();
      setTimeout(() => {
        window.$zopim.livechat.window.show();
      }, 1000);
    }
  }
};
</script>

<style scoped>
.boton:hover {
  width: 225px;
  background: #ebebeb !important;
  text-align: center;
  margin: 0 auto;
  /* padding: 5px 10px; */
  border-radius: 10px;
}

/* .titulo {
  color: #fff;
  font-family: arial;
  font-size: 17px;
  margin: 0px;
}

.l1 {
  font-family: arial;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
  line-height: 24px;
  text-transform: none;
}

.boton2 {
  width: 100%;
  background: #2f2f2f;
  border: 0px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  text-transform: none;
}
.boton:hover {
  background: #4e4e4e;
  cursor: pointer;
}
.boton2:hover {
  background: #4e4e4e;
  cursor: pointer;
} */

/* .textochat {
  font-family: arial;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
  line-height: 24px;
  text-transform: none;
  background: #00caf1;
  border-radius: 5px;
  cursor: pointer;
} */
.modal-content-chat {
  /* background-color: #2d2d2d !important; */
  width: 20em !important;
  margin: auto !important;
}
</style>
