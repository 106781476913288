<template>
  <div class="card animate__animated animate__backInUp">
    <div class="card-body mt-5">
      <div class="row">
        <div class="col-3"></div>
        <div class="col-sm-12 col-lg-6">
          <div class="form-group">
            <label for="bancoOrigen">{{ $t("banco") }}</label>
            <v-select
              class="style-chooser"
              placeholder="Seleccione..."
              :options="getCenterBanks"
              style="width: 100%; color: black"
              :reduce="center => center.id"
              v-model="bankId"
              label="nombre"
              :searchable="false"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-2 col-sm-8">
          <div class="form-group">
            <label for="">{{ $t("tipo_de_cuenta") }}</label>
            <v-select
              class="style-chooser"
              placeholder="Seleccione..."
              :options="tipoCuenta"
              style="width: 100%; color: black"
              :reduce="tipo => tipo.type"
              v-model="tipoCta"
              label="label"
              :searchable="false"
            >
            </v-select>
          </div>
        </div>
        <div class="col-sm-8 col-lg-4">
          <div class="form-group">
            <label for="nroCta">{{ $t("numero_de_cuenta") }}</label>
            <input type="number" class="form-control rounded-0" id="nroCuenta" v-model="nroCta" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="text-center">
          <button
            class="btn btn-primary"
            :class="{
              'mr-5': !this.getIsMobilResolution,
              'mr-2': this.getIsMobilResolution
            }"
            v-on:click="validarForm()"
          >
            {{ $t("agregar_cuenta") }}
          </button>
          <button class="btn btn-danger" v-on:click="toPage('Retiros')">{{ $t("regresar") }}</button>
        </div>
      </div>

      <div v-if="!this.getIsMobilResolution">
        <div class="row mt-4">
          <div class="col bg-light text-center">
            <h3>
              <b>{{ $t("cuentas_bancarias") }}</b>
            </h3>
          </div>
        </div>
        <hr />
        <div class="row mt-4 bg-primary text-center">
          <div class="col-2">{{ $t("banco") }}</div>
          <div class="col-2">{{ $t("tipo_de_cuenta") }}</div>
          <div class="col-6">{{ $t("numero_de_cuenta") }}</div>
          <div class="col-2">{{ $t("accion") }}</div>
        </div>
        <div class="row mt-2 text-center" v-for="account in getAccounts" v-bind:key="account.id">
          <div class="col-2" v-if="account.type_account_id == 1">Corriente</div>
          <div class="col-2" v-else>Ahorro</div>
          <div class="col-2">
            {{ account.banco }}
          </div>
          <div class="col-6">
            {{ account.account }}
          </div>
          <div class="col-2">
            <button class="btn bg-danger rounded border-0" v-on:click="eliminarCuenta(account.id)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card mt-4" v-for="account of getAccounts" v-bind:key="account.id">
          <div class="row border text-center bg-title">
            <div class="text-center">
              <b>{{ $t("banco") }}</b>
            </div>
          </div>
          <div class="row">
            <div class="text-center">
              {{ account.banco }}
            </div>
          </div>
          <div class="row border text-center bg-title">
            <div class="text-center">
              <b>{{ $t("tipo_de_cuenta") }}</b>
            </div>
          </div>
          <div class="row">
            <div class="text-center" v-if="account.type_account_id == 1">Corriente</div>
            <div class="text-center" v-else>Ahorro</div>
          </div>
          <div class="row border text-center bg-title">
            <div class="text-center">
              <b>{{ $t("numero_de_cuenta") }}</b>
            </div>
          </div>
          <div class="row">
            <div class="text-center">
              {{ account.account }}
            </div>
          </div>
          <div class="row text-center bg-danger border">
            <div class="text-center my-2" v-on:click="eliminarCuenta(account.id)">
              <i class="fa fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { essentialsData } from "../mixins/essentialsDataMixin.js";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";

export default {
  mixins: [essentialsData],
  data() {
    return {
      bankId: null,
      tipoCta: null,
      nroCta: null,
      tipoCuenta: [
        { type: "1", label: "Corriente" },
        { type: "4", label: "Ahorro" }
      ]
    };
  },
  components: {
    vSelect
  },
  computed: {
    ...mapGetters(["getCenterBanks", "getAccounts", "getIsMobilResolution"])
  },
  methods: {
    validarForm() {
      if (this.bankId == null) {
        this.Swal.fire("Por favor", "Debe seleccionar un banco", "error");
        return false;
      }

      if (this.tipoCta == null) {
        this.Swal.fire("Por favor", "Debe seleccionar el tipo de cuenta", "error");
        return false;
      }

      if (this.nroCta == null) {
        this.Swal.fire("Por favor", "Debe agregar el número de cuenta", "error");
        return false;
      }

      this.agregarCuenta();
    },
    toPage: function(page) {
      this.$emit("changeTarget", page);
    },
    async agregarCuenta() {
      const accountData = {
        typeAccountId: this.tipoCta,
        account: this.nroCta,
        bankId: this.bankId
      };

      this.$store.commit("showLoader");
      const reg = await this.$userService.registerUserBankAccount(accountData);
      this.$store.commit("hideLoader");

      if (reg.error && reg.error !== "ok") {
        this.Swal.fire("Lo sentimos", reg.error, "error");
      }

      if (reg.error && reg.error == "ok" && reg.result) {
        this.tipoCta = null;
        this.nroCta = null;
        this.bankId = null;

        this.refreshData();

        this.Swal.fire("Excelente!", "La cuenta fue agregada exitosamente.", "success");
      }
    },
    async eliminarCuenta(id) {
      this.Swal.fire({
        title: "¿Deseas eliminar esta cuenta bamcaria?",
        /*showDenyButton: true,*/
        showCancelButton: true,
        confirmButtonText: `Si`,
        cancelButtonText: `No`
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.commit("showLoader");
          this.$userService.deleteUserBankAccount(id);
          this.$store.commit("hideLoader");

          this.refreshData();
          this.Swal.fire("Cuenta bancaria Eliminada!", "", "success");
        }
      });
    }
  }
};
</script>
