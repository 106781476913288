<template>
  <div>
    <div
      id="registerModal"
      tabindex="-1"
      aria-labelledby="registerModalLabel"
      aria-hidden="true"
      style="overflow: auto"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content border-redondo">
          <div class="modal-header modal-header-customer">
            <div class="text-center">
              <img v-lazy="'/img/logo/sellatuparley.png'" width="150px" height="150px" alt="logo 150px" /> <br />
              <h5 style="color: #001f62; font-weight: bold" class="span-formulario">{{ $t("registro_de_usuario") }}</h5>
            </div>
          </div>
          <div class="modal-body">
            <div name="form-container-register">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 mb-1">
                    <label class="color-text">{{ $t("nombre_y_apellido") }}*</label>
                    <input
                      type="text"
                      v-model="name"
                      :class="{
                        'form-control': true,
                        'error-input': $v.name.$error,
                        'ok-input': $v.name.$invalid == false
                      }"
                    />
                    <small class="form-text text-muted txt-red" v-if="$v.name.$error">{{
                      $t("nombre_requerido")
                    }}</small>
                  </div>
                  <div class="col-7 mb-1">
                    <label class="color-text">Fecha Nac.</label>
                    <Datepicker
                      :language="languages[language]"
                      v-model="fecha"
                      placeholder="Seleccione fecha"
                      class="datepicker-custom max100"
                      :disabled-dates="disabledDates"
                    ></Datepicker>
                  </div>
                  <div class="col-5 mb-1">
                    <label class="color-text">Cédula</label>
                    <input
                      type="number"
                      v-model="cedula"
                      maxlength="10"
                      min="1000000"
                      max="100000000"
                      :class="{
                        'form-control': true,
                        'error-input': $v.cedula.$error,
                        'ok-input': $v.cedula.$invalid == false
                      }"
                    />
                    <small class="form-text text-muted txt-red" v-if="$v.cedula.$error">{{
                      $t("cedula_validacion")
                    }}</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 mb-1">
                    <label class="color-text">Estado</label>
                    <select name="cities" v-model="estado" class="form-control">
                      <option v-for="(cities, index) in getCities" :key="index" :value="cities.id" v-show="getCities">
                        {{ cities.name }}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="col-md-3 mb-1">
                    <label class="color-text">{{ $t("ciudad") }}</label>
                    <input
                      type="text"
                      v-model="ciudad"
                      :class="{
                        'form-control': true,
                        'error-input': $v.ciudad.$error,
                        'ok-input': $v.ciudad.$invalid == false
                      }"
                    />
                    <small class="form-text text-muted txt-red" v-if="$v.ciudad.$error">{{
                      $t("ciudad_requerida")
                    }}</small>
                  </div> -->
                  <!-- <div class="col-3 mb-1">
                    <label class="color-text">Pref</label>
                    <input
                      type="text"
                      v-model="preInternacional"
                      disabled
                      :class="{
                        'form-control': true
                      }"
                    />
                  </div> -->
                  <div class="col-4 mb-1">
                    <!-- <div class="form-group" v-if="moneda == 'Bs'"> -->
                    <label class="color-text">Operador</label>
                    <select name="countries" v-model="prefijoSelected" class="form-control">
                      <option v-for="(prefijo, index) in prefMovil" :key="index" :value="prefijo.code">
                        {{ prefijo.code }}
                      </option>
                    </select>
                    <!-- </div> -->
                  </div>
                  <div class="col-8 mb-1">
                    <!-- <div class="form-group"> -->
                    <label class="color-text">Teléfono *</label>
                    <input
                      type="text"
                      v-model="phone"
                      :class="{
                        'form-control text-center': true,
                        'error-input': $v.phone.$error,
                        'ok-input': $v.phone.$invalid == false
                      }"
                      maxlength="7"
                    />
                    <small class="form-text text-muted txt-red" v-if="$v.phone.$error">{{
                      $t("celular_validar")
                    }}</small>
                    <!-- </div> -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mb-1">
                    <label class="color-text">Correo Electrónico *</label>
                    <input
                      type="email"
                      v-model="email"
                      placeholder="email"
                      :class="{
                        'form-control': true,
                        'error-input': $v.email.$error,
                        'ok-input': $v.email.$invalid == false
                      }"
                    />
                    <small class="form-text text-muted txt-red" v-if="$v.email.$error">{{
                      $t("correo_validar")
                    }}</small>
                  </div>
                  <div class="col-12 mb-1">
                    <label class="color-text">{{ $t("contraseña") }} *</label>
                    <input
                      type="password"
                      v-model="password"
                      v-on:keyup="validarPassword"
                      autocomplete="off"
                      :class="{
                        'form-control fr': true,
                        'error-input': $v.password.$error,
                        'ok-input': $v.password.$invalid == false
                      }"
                    />

                    <small
                      class="form-text text-muted txt-red"
                      v-if="password && !$v.password.minLength && longitudPassword()"
                      >{{ $t("contraseña_validar1") }}</small
                    >

                    <small class="form-text text-muted txt-red" v-if="validatePass == false">{{
                      $t("contraseña_validar2")
                    }}</small>
                  </div>
                  <div class="col-12 mb-1">
                    <label class="color-text">{{ $t("repita_contraseña") }} *</label>
                    <input
                      type="password"
                      v-model="password_repit"
                      v-on:keyup="validarPassword"
                      autocomplete="off"
                      :class="{
                        'form-control': true,
                        'error-input': $v.password.$error,
                        'ok-input': $v.password.$invalid == false
                      }"
                    />
                    <small class="form-text text-muted txt-red" v-if="validatePass == false">{{
                      $t("contraseña_validar2")
                    }}</small>
                  </div>

                  <!-- <div class="col-12 mb-1">
                    <label class="color-text">{{ $t("codigo_registro") }}</label>
                    <input type="text" v-model="form.code_promotion" class="form-control" />
                  </div> -->

                  <div class="col-md-4">
                    <div class="form-group">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                        <label class="form-check-label" for="defaultCheck1">
                          {{ $t("notificaciones_via_sms_acepta") }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          :class="{
                            'form-check-input': true
                          }"
                          type="checkbox"
                          v-model="checkTerms"
                          id="invalidCheck"
                        />
                        <label
                          :class="{
                            'form-check-label': true
                          }"
                          for="invalidCheck"
                        >
                          <a
                            href="#"
                            style="color: #001f62; font-weight: 300"
                            data-toggle="modal"
                            data-target="#modalTerminosCondiciones"
                            class="span-formulario"
                          >
                            {{ $t("terminos_condi") }}
                          </a>
                        </label>
                      </div>
                      <div class="form-group">
                        <label class="color-text">&nbsp;</label>
                        <br />
                        <a
                          href="#"
                          style="color: #001f62; font-weight: 300"
                          data-toggle="modal"
                          data-target="#modalBonosTerminosCondiciones"
                          class="span-formulario"
                        >
                          {{ $t("terminos_condi_leer") }}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4"></div>
                </div>
              </div>
              <div class="container-fluid" :class="{ 'border border-danger': submitStatus === 'ERROR' }">
                <p class="form-text text-danger text-center mb-2" v-if="submitStatus === 'ERROR'">
                  {{ $t("verifique_datos") }}
                </p>
                <div class="form-text text-muted txt-red text-left pl-3 pt-2">
                  <ul>
                    <li v-if="submitStatus === 'ERROR' && $v.cedula.$invalid">
                      <strong>{{ $t("identificacion") }}</strong
                      >.
                    </li>
                  </ul>
                  <!-- <ul>
                    <li v-if="submitStatus === 'ERROR' && $v.ciudad.$invalid">
                      <strong>{{ $t("ciudad") }}</strong
                      >.
                    </li>
                  </ul> -->
                  <ul>
                    <li v-if="submitStatus === 'ERROR' && $v.name.$invalid">
                      <strong>{{ $t("nombre") }}</strong
                      >.
                    </li>
                  </ul>
                  <ul>
                    <li v-if="submitStatus === 'ERROR' && $v.email.$invalid">
                      <strong>{{ $t("correo_electrónico") }}</strong
                      >.
                    </li>
                  </ul>
                  <ul>
                    <li v-if="submitStatus === 'ERROR' && $v.phone.$invalid">
                      <strong>{{ $t("telefono_Fijo_celular") }}</strong
                      >.
                    </li>
                  </ul>
                  <ul>
                    <li v-if="submitStatus === 'ERROR' && $v.password.$invalid">
                      <strong>{{ $t("contraseña") }}</strong
                      >.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="modal-footer modal-footer-customer" style="margin-top: -30px">
                <button
                  type="submit"
                  v-on:click="submit"
                  class="btn btn1 border-green"
                  :disabled="!$v.checkTerms.$model"
                >
                  <span>{{ $t("registrarme") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalBonosTerminosCondiciones />
    <ModalTerminosCondiciones />
  </div>
</template>
<script>
const countries = require("../store/data/country.json");
// const cities = "";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, minLength, between, email, requiredIf } from "vuelidate/lib/validators";

import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";

// import Terminos from "../pages/Terminos.vue";

import ModalBonosTerminosCondiciones from "./modals/ModalBonosTerminosCondiciones.vue";
import ModalTerminosCondiciones from "./modals/ModalTerminosCondiciones.vue";
import { mapGetters } from "vuex";

export default {
  name: "FormRegister",
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["getCities", "getIsMobilResolution"])
  },
  components: {
    Datepicker,
    ModalBonosTerminosCondiciones,
    ModalTerminosCondiciones
  },
  validations: {
    name: {
      required,
      minLength: minLength(3)
    },
    // ciudad: {
    //   required,
    //   minLength: minLength(3)
    // },
    phone: {
      required,
      minLength: minLength(7)
    },
    cedula: {
      required,
      between: between(1000000, 100000000)
    },
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(8)
    },
    checkTerms: {
      required: requiredIf(function() {
        return this.$data.checkTerms;
      })
    }
  },
  data() {
    return {
      fecha: moment(new Date().getFullYear() - 19 + "0101", "YYYYMMDD").format("llll"),
      disabledDates: {
        customPredictor: function(date) {
          let minYears = new Date().getFullYear() - 18;
          return minYears <= new Date(date).getFullYear();
        }
      },
      language: "es", // the language I need
      languages: lang,
      countries: countries,
      pais: { country: "Venezuela", abbreviation: "VE" },
      estado: 43,
      cities: null,
      name: "",
      cedula: null,
      email: null,
      password: null,
      password_repit: null,
      submitStatus: null,
      checkTerms: false,
      coin: {},
      prefMovil: [{ code: "0412" }, { code: "0424" }, { code: "0414" }, { code: "0416" }, { code: "0426" }],
      prefijoSelected: "0412",
      ciudad: null,
      phone: null,
      moneda: "Bs",
      preInternacional: "+58",
      validatePass: null,
      form: {
        cedula: null,
        name: null,
        password: null,
        coin_id: 3,
        fecha_nac: null,
        ciudad: null,
        pref_movil: null,
        phone: null,
        code_promotion: null,
        pref_int: 58,
        bonus_col: 1,
        nacionalidad: null
      }
    };
  },
  methods: {
    async submit() {
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        console.log(this.$v, this.$v.$invalid);
      } else {
        this.submitStatus = "OK";

        let codigoMoneda = 1;

        if (this.moneda == "Bs") {
          codigoMoneda = 1;
        }

        if (this.moneda == "USD") {
          codigoMoneda = 3;
        }

        let dataUser = {
          name: this.name,
          cedula: this.cedula,
          email: this.email,
          password: this.password,
          phone: this.phone,
          coin_id: codigoMoneda,
          fecha_nac: moment(this.fecha_nac).format("YYYY-MM-DD"),
          ciudad: this.ciudad,
          pref_movil: this.prefijoSelected,
          pref_int: 58,
          bonus_col: 1,
          nacionalidad: "V-",
          sello_deportivo: 1
        };
        // code_promotion

        this.$store.commit("showLoader");
        const reg = await this.$userService.registerAccount(dataUser);
        this.$store.commit("hideLoader");

        // si aun existe fondo se quita
        if (document.getElementsByClassName("modal-backdrop fade show")[0]) {
          document.getElementsByClassName("modal-backdrop fade show")[0].classList.remove("show");
        }

        if (reg.error && reg.error !== "ok") {
          this.Swal.fire("Lo sentimos", reg.error, "error");
        }

        if (reg.error && reg.error == "ok" && reg.result) {
          this.name = null;
          this.cedula = null;
          this.email = null;
          this.password = null;
          this.phone = null;
          // this.ciudad = null;

          var width = "60%";
          var imagen = '<img src="/img/correo.png" width="150px" height="150px" alt="logo"> <br>';

          if (this.getIsMobilResolution) {
            width = "100%";
            imagen = "";
          }

          if (document.getElementById("btn-close-registerModal")) {
            document.getElementById("btn-close-registerModal").click();
          }

          this.Swal.fire({
            html:
              '<div class="text-center">' +
              '<img src="/img/logo/sellatuparley.png" width="150px" height="150px" alt="logo"> <br>' +
              '<h5 style="color:#001f62;font-weight: bold;"> ¡Usuario registrado con éxito! </h5>' +
              "</div>" +
              '<div class="text-center">' +
              imagen +
              '<h5 style="font-size: 20px;font-weight: 400;color: #3c3c3c;"> Debe revisar su correo para su definitiva activación <br>' +
              "(Revise correo spam ó no deseados) </h5>" +
              "</div>",
            width: width,
            showCancelButton: true,
            showConfirmButton: false,
            confirmButtonColor: "#001f62",
            cancelButtonColor: "#001f62",
            cancelButtonText: "Cerrar"
          });
        }
      }
    },
    longitudPassword() {
      return this.password ? new String(this.password).length > 0 : false;
    },
    validarPassword() {
      if (this.password_repit === this.password) {
        this.validatePass = true;
      } else {
        this.validatePass = false;
      }
    },
    toPage: function() {
      //(page)
      // console.log("modal",this.$bvModal.show("DetailsAccountsModal"));
      this.$bvModal.show("modal-terminosCondiciones");
      // this.$emit("changePage", page);
    }
  },
  async mounted() {
    await this.$store.dispatch("searchCountriesAsync");
  }
};
</script>
<style>
body {
  background: transparent;
}
#registerModal
  > div
  > div
  > div.modal-body
  > div
  > div:nth-child(5)
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > input[type="text"] {
  /*max-width: 100%;*/
}

#registerModal
  > div
  > div
  > div.modal-body
  > div
  > div:nth-child(4)
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > input[type="text"],
.datepicker-custom input[type="text"] {
  /* max-width: -webkit-fill-available !important; */
  width: 100% !important;
}
</style>
<style scoped>
.modal-content {
  background-color: rgba(255, 255, 255, 0.85);
}

.txt-red {
  color: #ec0c0c !important;
}

.error-input {
  border-color: #ec0c0c;
}

.ok-input {
  border-color: #6eeabd;
  /*#41B883*/
}

.btn.btn1.border-green {
  width: 100% !important;
  background: #001f62 !important;
}

.button-customer {
  background: #001f62 !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
  width: 500px !important;
}

.input-customer {
  width: 100% !important;
}

.border-redondo {
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
}

.modal-header-customer {
  border-bottom: 1px solid #fff !important;
}

.modal-footer-customer {
  border-top: 1px solid #fff !important;
}
</style>
