<template>
  <div class="text-center animate__animated animate__backInUp top-pass">
    <div class="row">
      <div class="col-1">
        <i
          class="fas fa-chevron-left fa-lg text-light ml-2"
          :class="{ 'text-dark': this.getThemelight }"
          v-on:click="back()"
        ></i>
      </div>
      <div class="col-10">
        <div class="text-title" :class="{ 'text-light': !getThemelight, 'light-h1': getThemelight }">
          Cambio de clave
        </div>
      </div>
    </div>

    <!-- <div class="text-right form-inline">
      <i
        class="fas fa-chevron-left fa-lg text-light ml-2"
        :class="{ 'text-dark': this.getThemelight }"
        v-on:click="back()"
      ></i>
    </div>
    <div class="mb-3 text-center">
      <span class="title-orange" :class="{ 'text-light': !getThemelight, 'light-h1': getThemelight }">
        Cambio de clave
      </span>
    </div> -->

    <div class="card mt-3 bg-light" :class="{ 'btn-bg-light': this.getThemelight }">
      <div class="card-body">
        <div class="form-group">
          <label for="current-pass" class="label-formulario">Contraseña actual</label>
          <input type="password" autocomplete="off" class="form-control change-pass" id="current-pass" />
        </div>
        <div class="form-group">
          <label for="new-pass" class="label-formulario">Nueva Contraseña</label>
          <input type="password" autocomplete="off" class="form-control change-pass" id="new-pass" />
        </div>
        <div class="form-group">
          <label for="confirm-pass" class="label-formulario">Confirmar contraseña</label>
          <input type="password" autocomplete="off" class="form-control change-pass" id="confirm-pass" />
        </div>
        <div class="form-group">
          <div class="text-center">
            <button type="button" class="btn btn-primary boton-color-fondo">GUARDAR</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ChangePassword",
  computed: {
    ...mapGetters(["getUser", "getIsMobilResolution", "getThemelight", "getVisualWidth"])
  },
  methods: {
    back() {
      this.$bvModal.show("modal-cuenta");
    }
  }
};
</script>

<style scoped>
.top-pass {
  margin-top: 150px !important;
  height: auto;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
</style>
