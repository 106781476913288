<template>
  <Ayudas :titulo="'PREGUNTAS FRECUENTES'" :data="this.getPreguntasCMS" />
</template>
<script>
import Ayudas from "../components/Ayudas.vue";
import { mapGetters } from "vuex";
import { essentialsData } from "../mixins/essentialsDataMixin.js";

export default {
  name: "Preguntas",
  mixins: [essentialsData],
  components: { Ayudas },
  computed: {
    ...mapGetters(["getPreguntasCMS"])
  },
  methods: {
    async getAyuda() {
      await this.$store.dispatch("getData", {
        url: `/api/v1/getAyuda?pagina_id=1&perPage=`,
        set: "setPreguntasCMS",
        hander: this.evaluaRespuestaErrorRequestMultiplesCasos,
        handerName: "Preguntas Frecuentes"
      });
    }
  },
  async mounted() {
    this.getAyuda();
  }
};
</script>
