<template>
  <div class="container-bonus">
    <table class="table table-hover table-striped table-light" v-if="!this.getIsMobilResolution">
      <thead>
        <tr class="text-center">
          <!-- <th width="5%" class="mailbox-star"></th> -->
          <th width="20%">{{ $t("fecha") }}</th>
          <th width="15%">TICKET</th>
          <th width="30%">{{ $t("tipo") }}</th>
          <th>{{ $t("monto") }}</th>
          <th>{{ $t("saldo") }}</th>
        </tr>
      </thead>
      <tbody v-if="this.getBonus && this.getBonus.data">
        <tr v-for="(bonus, index) in getBonus.data" :key="index" class="text-center">
          <td width="20%">{{ dateFormat(bonus.fecha) }}</td>
          <td width="15%">
            <b>{{ bonus.ticket }}</b>
          </td>
          <td width="20%" class="text-left">{{ bonus.tipo }}</td>
          <td class="text-right">{{ getSimboloMoneda }}{{ formatoMonto(bonus.monto) }}</td>
          <td class="text-right">{{ getSimboloMoneda }}{{ formatoMonto(bonus.saldo) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { paginatorTable } from "../../mixins/paginatorTableMixin.js";
//import BonusTableMovil from "../../components/movil/BonusTableMovil.vue";
export default {
  name: "BonusTable",
  props: {
    page: Number
  },
  mixins: [paginatorTable],
  computed: {
    ...mapGetters(["getBonus", "getIsMobilResolution", "getSimboloMoneda"])
  },
  methods: {
    toPage: function(page) {
      this.$emit("changePage", page);
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm a");
      }
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  // components: {
  //   BonusTableMovil
  // },
  data() {
    return {
      totalResults: 0,
      perPage: 10
    };
  },
  mounted() {
    this.totalResults = this.getBonus && this.getBonus.data ? this.getBonus.data.length : 0;
    this.$emit("updateTotalResults", this.totalResults);
    this.$emit("updatePageResults", 1);
  }
};
</script>
<style scoped>
/*#bonos > div > table > tbody > tr:nth-child(5)*/
#bonos > div > table > tbody > tr:hover,
.table.table-light.table-striped tbody tr:nth-of-type(odd):hover {
  /*background-color: #3c507c !important;*/
  cursor: pointer;
}

.table.table-light.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(229 229 229 / 58%);
}

#bonos > div > table > tbody > tr:hover td,
#bonos > div > table > tbody > tr:hover td a {
  /*color: #fff;*/
  color: #212529 !important;
}
</style>
