<template>
  <b-modal id="modal-aviso" title="" hide-footer hide-header body-bg-variant="yellow">
    <div>
      <img class="img-fluid" v-lazy="urlnotice" />
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["urlnotice"]
};
</script>

<style scoped></style>
