import Swal from "sweetalert2";
export default {
  state: {
    apuestas: [],
    restriccionesParley: [],
    premiosParley: [],
    premiosDirectas: [],
    tipoParley: true,
    premio: 0,
    monto: 0,
    ticketTarget: null,
    totalEnPremios: 0,
    excepcionParley: false,
    formatoTaquilla: "odd" // tambien puede ser odd_a
  },
  mutations: {
    setExcepcionParley(state, status) {
      state.excepcionParley = status;
    },
    setTicketTarget(state, ticket) {
      state.ticketTarget = ticket;
    },
    setPremiosParley(state, premios) {
      state.premiosParley = premios;
    },
    setPremiosDirectas(state, premios) {
      state.premiosDirectas = premios;
    },
    setTotalEnPremios(state, TotalEnPremios) {
      state.totalEnPremios = TotalEnPremios;
    },
    setMonto(state, monto) {
      state.monto = monto;
    },
    setPremio(state, premio) {
      state.premio = premio;
    },
    setTipoParley(state, tipoParley) {
      state.tipoParley = tipoParley;
    },
    setApuestas(state, apuestas) {
      state.apuestas = apuestas;
      if (localStorage) {
        localStorage.setItem("apuestas", JSON.stringify(state.apuestas));
      }
    },
    setFormatoTaquilla(state, formato) {
      state.formatoTaquilla = formato;
    },
    addApuesta(state, apuesta) {
      state.apuestas.push(apuesta);
      if (localStorage) {
        localStorage.setItem("apuestas", JSON.stringify(state.apuestas));
      }
    },
    addRestriccionParley(state, restincion) {
      state.restriccionesParley.push(restincion);
    },
    setRestriccionParley(state, restinciones) {
      state.restriccionesParley = restinciones;
    }
  },
  getters: {
    getExcepcionParley(state) {
      return state.excepcionParley;
    },
    getRestriccionesParley(state) {
      return state.restriccionesParley;
    },
    getPremiosParley(state) {
      return state.premiosParley;
    },
    getPremiosDirectas(state) {
      return state.premiosDirectas;
    },
    getTotalEnPremios(state) {
      return state.totalEnPremios;
    },
    getMonto(state) {
      return state.monto;
    },
    getTicketTarget(state) {
      return state.ticketTarget;
    },
    getPremio(state) {
      return parseFloat(state.premio).toFixed(2);
    },
    getApuestas(state) {
      return state.apuestas;
    },
    getTipoParley(state) {
      return state.tipoParley;
    },
    getFormatoTaquilla(state) {
      return state.formatoTaquilla;
    }
  },
  actions: {
    calculatePrize({ state, dispatch, commit }) {
      let carculoPremioParley = null;
      let totalPremiosDirectas = 0;
      let premiosIndividualesDirectas = [];
      // -- DIRECTAS
      if (!state.tipoParley) {
        state.apuestas.map(async a => {
          let newAmount = state.monto;
          if (isNaN(state.monto) || state.monto == 0) {
            newAmount = 0;
          }

          const dt = { odd: a.respuesta.odd.Odd.odd, amount: newAmount };
          carculoPremioParley = await dispatch("calculateParlay", dt);
          premiosIndividualesDirectas.push(parseFloat(parseFloat(carculoPremioParley).toFixed(2)));
          totalPremiosDirectas = parseFloat(parseFloat(premiosIndividualesDirectas.reduce((x, y) => x + y)).toFixed(2));
        });

        // necesario no quitar el delay
        setTimeout(() => {
          commit("setPremio", totalPremiosDirectas);
          commit("setTotalEnPremios", totalPremiosDirectas);
          commit("setPremiosDirectas", premiosIndividualesDirectas);
        }, 250);
      } else {
        // -- PARLEy
        let newAmount = state.monto;
        let tempApuestas = JSON.parse(JSON.stringify(state.apuestas));
        let premiosParley = [];

        tempApuestas.map(async (a, index) => {
          setTimeout(async () => {
            if (index != 0) {
              newAmount = state.totalEnPremios;
            }

            let dt = { odd: a.respuesta.odd.Odd.odd, amount: newAmount };

            let carculoPremioParley = await dispatch("calculateParlay", dt);
            let calculoNumericoFormateado = await parseFloat(parseFloat(carculoPremioParley).toFixed(2));

            if (calculoNumericoFormateado > 0) {
              premiosParley.push(parseFloat(calculoNumericoFormateado));
            }

            commit("setPremio", calculoNumericoFormateado);
            commit("setTotalEnPremios", calculoNumericoFormateado);
          }, 100);
        });

        setTimeout(() => {
          commit("setPremiosParley", premiosParley);
        }, 150);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async calculateParlay({ state }, data) {
      //odd, amount
      let prize = 0;
      let oddAbsolut = 0;
      let fullPrize = 0;
      if (data.odd == 0) {
        prize = data.amount;
      }
      if (data.odd > 0) {
        //HEMBRA POSITIVO
        oddAbsolut = data.odd / 100;
        prize = data.amount * oddAbsolut;
        fullPrize = prize * 1 + data.amount * 1;
      }
      if (data.odd < 0) {
        // MACHO NEGATIVO
        oddAbsolut = data.odd / -100;
        prize = data.amount / oddAbsolut;
        fullPrize = prize * 1 + data.amount * 1;
      }
      return fullPrize;
    },
    async consultarApuesta({ rootState }, apuestaIDcode) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${rootState.token.access_token}`);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      const URL_CONSULTAR_APUESTA =
        process.env.VUE_APP_JSON_NO_CORS + process.env.VUE_APP_URL_API + `api/sport?odd=${apuestaIDcode}`;

      return await fetch(URL_CONSULTAR_APUESTA, requestOptions).then(async resp => {
        return await resp.json();
      });
    },
    async valid_final({ commit, rootState, state }, datos) {
      let type = parseInt(datos.Odd.odd_type_id);
      let sport_id = datos.Game.sport_id;
      let final = datos.Odd.final;
      let hayRestriccion = false;

      if (datos.Game.sport_id === 3) {
        if (rootState.betInit.restr.indexOf(25) > -1) {
          let thisforb = Array(1, 2);
          if (thisforb.indexOf(type) > -1) {
            commit("addRestriccionParley", [datos.Game.ref]);
          }
        }
      }

      const jugadas = state.apuestas.flatMap(a => {
        return a && a.respuesta && a.respuesta.odd ? a.respuesta.odd : [];
      });

      if (jugadas.length > 0) {
        if ([1, 2, 7].indexOf(datos.Odd.odd_type_id) == -1) {
          var jugadas_iguales = jugadas.filter(
            jugada =>
              jugada.Odd.finalit === datos.Odd.finalit &&
              rootState.betInit.odd_by_type[jugada.Odd.finalit][jugada.Odd.odd_type_id] ===
                rootState.betInit.odd_by_type[datos.Odd.finalit][datos.Odd.odd_type_id]
          );

          if (jugadas_iguales.length + 1 > rootState.betInit.max_jug_rep_x_tck) {
            /*Swal.fire(
              "Error",
              "Limite de jugada repetida superado! intente seleccionando otro tipo de jugada.",
              "error"
            );*/
            Swal.fire({
              position: "top",
              icon: "error",
              text: "Limite de jugada repetida superado! intente seleccionando otro tipo de jugada"
            });
            hayRestriccion = false;
            return false;
          }
        }

        const odds = jugadas.filter(jugada => jugada.Game.id === datos.Game.id);

        odds.map(jugada => {
          var deportes = Array(1, 3, 4);
          if (deportes.indexOf(datos.Game.sport_id) > -1) {
            if (rootState.betInit.restr.indexOf(15) > -1) {
              if (jugada.Odd.final !== datos.Odd.final) {
                hayRestriccion = true;
              }
            } else {
              if (jugada.Odd.final !== datos.Odd.final) {
                let thisforb1 = Array(5, 6);
                let thisforb2 = Array(1, 2, 3, 4, 8, 9);
                if (
                  (thisforb1.indexOf(odty) > -1 && thisforb2.indexOf(type) > -1) ||
                  (thisforb2.indexOf(odty) > -1 && thisforb1.indexOf(type) > -1)
                ) {
                  hayRestriccion = false;
                }
              }
            }
          } else {
            if (jugada.Odd.final !== datos.Odd.final) {
              hayRestriccion = true;
            }
          }

          if ([3, 4, 5].indexOf(sport_id) > -1) {
            if ([0, 1, 2].indexOf(jugada.Odd.final) > -1) {
              if ([3, 4, 5, 6].indexOf(final) > -1) {
                hayRestriccion = true;
              }
            }
            if ([0, 1, 2].indexOf(final) > -1) {
              if ([3, 4, 5, 6].indexOf(jugada.Odd.final) > -1) {
                hayRestriccion = true;
              }
            }

            let thisforb = Array(3, 4, 5, 6);
            if (thisforb.indexOf(jugada.Odd.final) > -1 && thisforb.indexOf(final) > -1) {
              hayRestriccion = true;
            }

            thisforb = Array(1, 2, 3, 4, 5, 6, 7, 8, 9);
            if (
              (thisforb.indexOf(jugada.Odd.odd_type_id) > -1 && type > 9) ||
              (thisforb.indexOf(type) > -1 && jugada.Odd.odd_type_id > 9)
            ) {
              hayRestriccion = true;
            }

            if (jugada.Odd.odd_type_id > 9 && type > 9) {
              hayRestriccion = true;
            }
          }

          var odty = parseInt(jugada.Odd.odd_type_id);
          if (type <= 4 && odty <= 4) hayRestriccion = true;
          if ((type == 7 && odty <= 4) || (type <= 4 && odty == 7)) hayRestriccion = true;
          if ((type == 5 && odty == 6) || (type == 6 && odty == 5)) hayRestriccion = true;
          if (type == 1 || type == 2 || type == 3 || type == 4) {
            let thisforb = Array(1, 2, 3, 4);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (type == 1 || type == 2 || type == 8 || type == 9) {
            let thisforb = Array(1, 2, 8, 9);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (type == 3 || type == 4 || type == 8 || type == 9) {
            let thisforb = Array(3, 4, 8, 9);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(1) > -1) {
            let thisforb = Array(1, 2, 16, 17);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(2) > -1) {
            let thisforb = Array(3, 4, 16, 17);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(3) > -1) {
            let thisforb = Array(8, 9, 16, 17);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(4) > -1) {
            let thisforb = Array(1, 2, 10, 11, 12, 13);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(5) > -1) {
            let thisforb = Array(3, 4, 10, 11, 12, 13);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(6) > -1) {
            let thisforb = Array(8, 9, 10, 11, 12, 13);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(7) > -1) {
            let thisforb = Array(5, 6, 14, 15);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(8) > -1) {
            let thisforb = Array(5, 6, 18, 19);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(9) > -1) {
            let thisforb = Array(5, 6, 10, 11, 12, 13);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(10) > -1) {
            let thisforb = Array(14, 15, 18, 19);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(11) > -1) {
            let thisforb = Array(14, 15, 10, 11, 12, 13);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(12) > -1) {
            let thisforb = Array(18, 19, 10, 11, 12, 13);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (this.center_id == 6) {
            hayRestriccion = true;
          } else {
            let deportes_id = Array(2, 6, 9, 10, 11, 16, 17, 19, 20, 21, 22, 23, 24, 25, 26, 30, 41, 51);
            if (deportes_id.indexOf(sport_id) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(18) > -1 || rootState.betInit.restr.indexOf(21) > -1) {
            let thisforb = Array(10, 11, 12, 13, 16, 17);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(19) > -1 || rootState.betInit.restr.indexOf(22) > -1) {
            let thisforb = Array(14, 15, 16, 17);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (rootState.betInit.restr.indexOf(20) > -1 || rootState.betInit.restr.indexOf(23) > -1) {
            let thisforb = Array(18, 19, 16, 17);
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (sport_id === 1) {
            if (rootState.betInit.restr.indexOf(28) > -1) {
              let thisforb = Array(3, 4, 5, 6);
              if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
                hayRestriccion = true;
              }
            }

            if (rootState.betInit.restr.indexOf(29) > -1) {
              let thisforb = Array(8, 9, 5, 6);
              if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
                hayRestriccion = true;
              }
            }

            if (rootState.betInit.restr.indexOf(30) > -1) {
              let thisforb = Array(3, 4, 18, 19);
              if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
                hayRestriccion = true;
              }
            }

            if (rootState.betInit.restr.indexOf(31) > -1) {
              let thisforb = Array(8, 9, 18, 19);
              if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
                hayRestriccion = true;
              }
            }

            if (rootState.betInit.restr.indexOf(32) > -1) {
              let thisforb = Array(1, 2, 5, 6);
              if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
                hayRestriccion = true;
              }
            }

            let thisforb = Array(
              116,
              117,
              118,
              119,
              120,
              121,
              122,
              123,
              124,
              125,
              126,
              127,
              128,
              129,
              130,
              131,
              132,
              133,
              14,
              15
            );
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }

            thisforb = Array(
              1,
              2,
              116,
              117,
              118,
              119,
              120,
              121,
              122,
              123,
              124,
              125,
              126,
              127,
              128,
              129,
              130,
              131,
              132,
              133,
              14,
              15
            );
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }

            thisforb = Array(
              3,
              4,
              116,
              117,
              118,
              119,
              120,
              121,
              122,
              123,
              124,
              125,
              126,
              127,
              128,
              129,
              130,
              131,
              132,
              133,
              14,
              15
            );
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }

            thisforb = Array(
              5,
              6,
              116,
              117,
              118,
              119,
              120,
              121,
              122,
              123,
              124,
              125,
              126,
              127,
              128,
              129,
              130,
              131,
              132,
              133,
              14,
              15
            );
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }

            thisforb = Array(
              8,
              9,
              116,
              117,
              118,
              119,
              120,
              121,
              122,
              123,
              124,
              125,
              126,
              127,
              128,
              129,
              130,
              131,
              132,
              133,
              14,
              15
            );
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }

            thisforb = Array(
              10,
              11,
              12,
              13,
              116,
              117,
              118,
              119,
              120,
              121,
              122,
              123,
              124,
              125,
              126,
              127,
              128,
              129,
              130,
              131,
              132,
              133,
              14,
              15
            );
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }

            thisforb = Array(
              15,
              16,
              17,
              18,
              19,
              116,
              117,
              118,
              119,
              120,
              121,
              122,
              123,
              124,
              125,
              126,
              127,
              128,
              129,
              130,
              131,
              132,
              133,
              14,
              15
            );
            if (thisforb.indexOf(odty) > -1 && thisforb.indexOf(type) > -1) {
              hayRestriccion = true;
            }
          }

          if (sport_id === 2) {
            if (rootState.betInit.restr.indexOf(13) == -1 && rootState.betInit.restr.indexOf(24) == -1) {
              let thisforb1 = Array(1, 2);
              let thisforb2 = Array(5, 6);
              if (
                (thisforb1.indexOf(odty) > -1 && thisforb2.indexOf(type) > -1) ||
                (thisforb2.indexOf(odty) > -1 && thisforb1.indexOf(type) > -1)
              ) {
                hayRestriccion = false;
              }
            }
          }

          if (hayRestriccion) {
            if (!state.tipoParley) {
              commit("addRestriccionParley", [jugada.Game.ref, datos.Game.ref]);
            }
            this.hayRestriccion = true;
            hayRestriccion = false;
          }
        });

        if (state.tipoParley) {
          if (this.hayRestriccion) {
            //Swal.fire("Error", "Combinacion no permitida para la modalidad PARLEY", "error");
            const texto = "Combinación no permitida para la modalidad PARLEY. Debe cambiar a Modalidad Directas.";

            Swal.fire({
              position: "top",
              icon: "error",
              text: texto
            });
            this.hayRestriccion = false;
            return false;
          }
        }
      }
      return true;
    },
    async clickApuesta({ state, rootState, dispatch, commit }, apuesta) {
      // ************************************************************************
      // NO QUITAR  ya que si NO existe la configuracion Inicial hay que buscarla
      // ************************************************************************
      if (!rootState.betInit || !rootState.betInit.odd_type) {
        await dispatch("getBetInitAsync");
      }

      //rootState
      const busquedaApueta = state.apuestas.flatMap(a => {
        return a.id == apuesta.id ? a : [];
      });
      // si no consiguio la apuesta se agrega
      if (busquedaApueta.length == 0) {
        if (!rootState.betInit) {
          await dispatch("getBetInitAsync");
        }
        await dispatch("refresfToken");
        const detalle = await dispatch("consultarApuesta", apuesta.id);
        apuesta.respuesta = detalle;

        if (detalle && detalle.odd && detalle.odd.Error == "") {
          const jugada = detalle.odd;
          // Ahora Validacion de Restrincion

          const validar = await dispatch("valid_final", jugada);

          const fecha_match = jugada.Game.fecha_zone.replace("<br>", " ");

          const match = jugada.Game.match.replace("<BR>", " ");

          apuesta["fecha_match"] = fecha_match;
          apuesta["match"] = match.replace("BR", "");

          if (validar) {
            //apuesta["texto"] = state.tipoParley ? `Parley` : `Directas`;
            var tipo_jugada = rootState.betInit.odd_type[jugada.Odd.odd_type_id];
            apuesta["texto"] = tipo_jugada;
            commit("addApuesta", apuesta);
          }
        } else {
          // caso de error o restrincion
          //Swal.fire("Error", detalle.odd.Error, "error");
          Swal.fire({
            position: "top",
            icon: "error",
            text: detalle.odd.Error
          });
        }
      } else {
        // si existe la apuesta y clickea por segunda vez se elimina
        const apuetasConElTargetEliminando = state.apuestas.flatMap(a => {
          return a.id != apuesta.id ? a : [];
        });
        commit("setApuestas", apuetasConElTargetEliminando);
      }
      await dispatch("calculatePrize");
    },
    existeApuesta({ state }, apuestaId) {
      const busquedaApueta = state.apuestas.flatMap(a => {
        return a.id == apuestaId ? a : [];
      });
      return busquedaApueta.length > 0;
    }
  }
};
