export const chatMixin = {
  methods: {
    importLibChat() {
      const tagScript = document.createElement("script");
      tagScript.setAttribute("type", "text/javascript");
      tagScript.setAttribute("id", "zopim-chat");

      tagScript.innerHTML = `window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
_.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");
$.src="https://v2.zopim.com/?${process.env.VUE_APP_ZOPIM_APIKEY}";z.t=+new Date;$.
type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");`;

      if (!document.getElementById("zopim-chat")) {
        window.document.body.append(tagScript);
      }

      window.onload = () => {
        this.hideChat();
      };
    },
    async generateChat() {
      if (window.$zopim && window.$zopim.livechat && window.$zopim.livechat.window) {
        // si ya esta cargado solo muestre el chat
        window.$zopim.livechat.window.show();
      } else {
        window.onload = () => {
          // Cuando termine de cargar el script que muestre el chat
          window.$zopim.livechat.window.show();
        };
      }
      this.showChat();
    },
    hideChat() {
      if (document.getElementsByClassName("zopim") && document.getElementsByClassName("zopim")[0] != undefined) {
        document
          .getElementsByClassName("zopim")
          .item(0)
          .classList.add("no-display");
        window.$zopim.livechat.window.hide();
      }
      if (document.getElementsByClassName("box-chat") && document.getElementsByClassName("box-chat").item(0)) {
        document
          .getElementsByClassName("box-chat")
          .item(0)
          .classList.add("no-display");
      }

      if (document.getElementById("contenedor-contacto-flotante")) {
        document.getElementById("contenedor-contacto-flotante").classList.add("no-display");
      }

      if (window && window.$zopim && window.$zopim.livechat) {
        window.$zopim.livechat.hideAll();
      }
    },
    showChat() {
      if (
        document.getElementsByClassName("zopim") &&
        document.getElementsByClassName("zopim")[0] != undefined &&
        document.getElementsByClassName("zopim").item(0)
      ) {
        document
          .getElementsByClassName("zopim")
          .item(0)
          .classList.remove("no-display");
        window.$zopim.livechat.window.show();
        window.$zopim.livechat.window.hide();
      }

      if (document.getElementsByClassName("box-chat") && document.getElementsByClassName("box-chat").item(0)) {
        document
          .getElementsByClassName("box-chat")
          .item(0)
          .classList.remove("no-display");
      }

      if (document.getElementById("contenedor-contacto-flotante")) {
        document.getElementById("contenedor-contacto-flotante").classList.remove("no-display");
      }
    },
    removeChat() {
      if (document.getElementById("zopim-chat")) {
        document.getElementById("zopim-chat").remove();
        if (document.getElementsByClassName("zopim") && document.getElementsByClassName("zopim")[0] != undefined) {
          document.getElementsByClassName("zopim")[0].remove();
        }
      }
    }
  }
};
