<template>
  <div>
    <div
      class="footer container-sheet"
      :class="{
        'slide-top': selectOdds.length > 0 || !minimiza,
        'slide-min': minimiza
      }"
    >
      <div @touchstart="minimizar()" class="row" style="font-size: 1.5em">
        <div class="col text-center">
          <i class="fas fa-grip-lines" style="font-weight: bold"></i>
        </div>
      </div>

      <table
        width="100%"
        class="text-center"
        :class="{ 'game-row': selectOdds.length > 0 }"
        style="font-family: Arial"
        v-if="selectOdds.length > 0"
      >
        <!-- <thead> -->
        <tr class="text-dark fondo-title-apuestas">
          <th width="60%">Juego</th>
          <th width="30%">Apuesta</th>
          <th width="10%"></th>
        </tr>
        <!--  </thead> -->
        <tbody class="text-dark body-apuestas">
          <tr
            v-for="data in selectOdds"
            v-bind:key="data.id"
            class="text-center game-row"
            :class="{ 'bg-restriccion': restrictedParley(data.id) && getMarkRestricted }"
            style="display: table"
            :id="data.game_id"
          >
            <td width="60%" style="font-size: 1em !important">
              {{ data.home }} VS {{ data.away }} <br />
              {{ data.time }}
            </td>
            <td width="30%" style="font-size: 1em !important">
              {{ data.outcome }} ({{ data.type }}) <br />
              <template v-if="showSign(getFormat, data[getFormat])">+</template>{{ data[getFormat] }}
            </td>
            <td width="10%"><i class="fas fa-trash fa-lg text-danger" @click="deleteOdd(data)"></i></td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col form-inline">
          <button
            class="btn btn-primary w-75"
            :class="{ 'w-100': this.getOdds.length == 0 }"
            style="height: 2.5em"
            ref="btnTicket"
            @click="validacionesTickets()"
            :disabled="this.getOdds.length == 0"
          >
            Generar ticket - {{ selectOdds.length }} Jugadas
          </button>
          <button class="btn btn-danger w-25" style="height: 2.5em" @click="cleanData" v-if="this.getOdds.length > 0">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>

      <div>
        <!-- modal -->
        <b-modal id="my-modal" title="¿Seguro de generar el ticket?">
          <div class="form-inline mb-2">
            <b class="mr-3">USAR BONUS</b>
            <b-form-checkbox size="lg" @change="checkBonus = !checkBonus"></b-form-checkbox>
          </div>
          <div style="font-size: 0.9em">
            (Al sellar con bonus NO se deveuelve el monto apostado). <br />
            Una vez oprima [ACEPTAR] su apuesta sera registrada. <br />
            Oprima Cancelar para agregar/actualizar apuestas. <br />
          </div>
          <template #modal-footer>
            <div class="w-100 my-0">
              <b-button variant="danger" class="float-right" @click="$bvModal.hide('my-modal')">Cancelar </b-button>
              <b-button variant="success" class="float-right mr-2" @click="registrarTicket()">Aceptar </b-button>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      monto: "",
      checkBonus: 0,
      minimiza: false,
      ver: false
    };
  },
  props: ["selectOdds"],
  computed: {
    ...mapGetters([
      "getFormat",
      "getOdds",
      "getBetType",
      "getOddsRestricteds",
      "getMsjError",
      "getMarkRestricted",
      "getAmount",
      "getBetInitData",
      "getUser"
    ])
  },
  watch: {
    monto(m) {
      this.$store.commit("setAmount", m);
      this.updatePrize();
    },
    checkBonus(c) {
      this.checkBonus = c ? 1 : 0;
    }
  },
  methods: {
    ...mapActions(["processOdd", "updatePrize", "deleteRestrictednOdd", "validTicket"]),
    cleanData() {
      this.monto = 0;
      this.$store.dispatch("cleanOdds");
    },
    minimizar() {
      this.minimiza = !this.minimiza;
    },
    async validacionesTickets() {
      await this.validTicket();

      if (this.getMsjError) {
        this.Swal.fire("Ha ocurrido un error", "<p class='text-center'>" + this.getMsjError + "</p>", "error");
      } else {
        this.$bvModal.show("my-modal");
      }
    },
    async registrarTicket() {
      let objData = {};
      let dataTicket = [];

      this.getOdds.forEach(element => {
        let id = "FTX1X" + element.game_id;

        objData[id] = {
          code: element.game_id + "XFTX1",
          factor: "",
          game_id: element.game_id,
          odd: element.odds,
          odd_a: element.odds_a,
          odd_type: 3,
          status: 1,
          type: element.type
        };
      });

      const cantidad_amount_id = this.getBetType == "Parley" ? this.getAmount : this.getAmount * this.getOdds.length;

      dataTicket["Ticket"] = {
        amount: this.getAmount,
        amount_ind: cantidad_amount_id,
        modalidad: this.getBetType == "Parley",
        bonus: this.checkBonus,
        code: this.getBetInitData,
        // eslint-disable-next-line no-undef
        procesed: moment().format("YYYY-MM-DD"),
        moneda: this.getUser.Coin.moneda,
        Odds: objData
      };

      const respuesta = await this.$store.dispatch("registerTicketAsync", dataTicket);

      if (respuesta.Type_error && respuesta.Type_error != 0) {
        this.Swal.fire({
          position: "top",
          title: "Error",
          text: respuesta.Error,
          icon: "error"
        });
      }
    },

    async deleteOdd(item) {
      if (this.getBetType == "Directa") {
        this.deleteRestrictednOdd(item);
      }

      if (this.getBetType == "Directa") {
        let oddExisted = this.getOddsRestricteds.includes(item.id);

        if (oddExisted) {
          this.$store.commit("setOddsRestricteds", item.id);
        }
      }

      await this.processOdd(item);
      await this.updatePrize();
    },
    restrictedParley(id) {
      return this.getOddsRestricteds.includes(id);
    },
    showSign(format, value) {
      return format == "odds" && value > 0;
    }
  }
};
</script>

<style>
.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-min {
  -webkit-animation: slide-min 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-min 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.game-row {
  -webkit-animation: game-row 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: game-row 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.container-sheet {
  position: fixed;
  z-index: 1000;
  bottom: 0em;
  left: 0;
  width: 100%;
  background-color: #545454;
  color: #e0e0e0;
}

.fondo-title-apuestas {
  background-color: #b7b8b8;
  font-size: 0.9em;
  font-weight: 800;
  display: table;
  width: 100%;
  height: 2em;
}

.body-apuestas {
  background-color: #e1e8e8;
  font-size: 0.7em;
  font-weight: 600;
  max-height: 130px !important;
  overflow-y: auto !important;
  display: block;
}
.bg-restriccion {
  background: #745f00 !important;
  color: white !important;
}

@-webkit-keyframes game-row {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes game-row {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(calc(100% - 40px));
    transform: translateY(calc(100% - 40px));
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(calc(100% - 40px));
    transform: translateY(calc(100% - 40px));
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes slide-min {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(calc(100% - 35px));
    transform: translateY(calc(100% - 35px));
  }
}
@keyframes slide-min {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(calc(100% - 35px));
    transform: translateY(calc(100% - 35px));
  }
}
</style>
