<template>
  <div class="container" style="overflow: auto">
    <div class="text-center mb-2">
      <span :class="{ 'text-light': !this.getThemelight, 'light-h1': this.getThemelight }">
        <h3>RESULTADOS</h3>
      </span>
    </div>

    <div class="row mb-2 mt-2 text-center">
      <!-- <div class="mt-2 w-100">
        <input type="text" class="form-control" v-model="filterTermLeague" placeholder="Deporte" />
      </div>
      <div class="mt-2 w-100">
        <input type="text" class="form-control" placeholder="Liga" />
      </div> -->
      <div class="mt-2 w-100">
        <v-select
          class="style-chooser"
          placeholder="Deporte"
          :options="deportes"
          :reduce="deporte => deporte.id"
          v-model="deporte"
          label="label"
          @input="filtrarXDeporte()"
          :searchable="false"
        >
        </v-select>
      </div>
      <div class="mt-2 date">
        <datetime
          v-model="fecha"
          value-zone="America/Caracas"
          input-class="rounded text-center"
          input-style="height: 2.2em;"
          :phrases="textoCalendario"
        ></datetime>
      </div>
      <div class="mt-2 ml-3">
        <button class="btn btn-buscar" @click="filtrar()">Buscar <i class="fa fa-search"></i></button>
      </div>
    </div>

    <details
      class="card my-3 text-center"
      style="font-size: 1em; font-weight: 500"
      v-for="res of resultsFilter"
      v-bind:key="res.id"
    >
      <summary>
        <div class="row align-items-center">
          <div class="col">{{ dateFormat(res.time) }}</div>
        </div>
        <div class="row align-items-center">
          <div class="col-5">{{ res.Team[0].team_name }}</div>
          <div class="col-2">Vs</div>
          <div class="col-5">{{ res.Team[1].team_name }}</div>
        </div>
      </summary>
      <div class="text-center bg-light border"><b>HORA</b></div>
      <div class="row align-items-center">
        <div class="col">{{ dateFormat(res.time) }}</div>
      </div>
      <div v-if="res.results && res.results.result && res.results.result.FT">
        <div class="text-center bg-light border"><b>RESJC</b></div>
        <div class="row align-items-center">
          <div class="col-6 border-right">{{ res.results.result.FT[1] }}</div>
          <div class="col-6">{{ res.results.result.FT[0] }}</div>
        </div>
        <div class="text-center bg-light border"><b>A/BJC</b></div>
        <div class="row align-items-center">
          <div class="col">
            {{ Number(res.results.result.FT[0]) + Number(res.results.result.FT[1]) }}
          </div>
        </div>
      </div>
      <div v-if="res.results && res.results.result && res.results.result.HT">
        <div class="text-center bg-light border"><b>RESMJ</b></div>
        <div class="row align-items-center">
          <div class="col-6 border-right">{{ res.results.result.HT[1] }}</div>
          <div class="col-6">{{ res.results.result.HT[0] }}</div>
        </div>
      </div>
    </details>

    <!-- <div class="text-right mt-4 mb-4 fixed">
      <div class="badge shade" id="scroll" @click="top()">
        <i class="fas fa-chevron-up fa-lg"></i>
      </div>
    </div> -->
  </div>
</template>
<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  // created() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  data() {
    return {
      fecha: moment()
        .format()
        .toString(),
      apiUrl: `https://apipwa.sellatuparley.com/api/contenido/resultados?date=${this.getFechaFormat()}`,
      language: "es", // the language I need
      languages: lang,
      filterTermLeague: "",
      filterTermTeam: "",
      results: [],
      resultsFilter: [],
      deporte: "",
      deportes: [
        { id: 1, label: "Beisbol" },
        { id: 2, label: "Futbol" },
        { id: 3, label: "Basket" },
        { id: 4, label: "NFL" },
        { id: 5, label: "NHL" },
        { id: 6, label: "Otros" },
        { id: 0, label: "Todos" }
      ],
      leagues: [],
      position: 1,
      disablePager: false,
      textoCalendario: { ok: "Ok", cancel: "Cancelar" }
    };
  },
  components: {
    datetime: Datetime,
    vSelect
  },
  computed: {
    ...mapGetters(["getThemelight", "getLeagues"])
  },
  methods: {
    getFechaFormat() {
      return moment(this.fecha).format("YYYY-MM-DD");
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm A");
      }
    },
    async filtrarXDeporte() {
      if (this.deporte <= 6) {
        let ligas = this.getLeagues.filter(element => {
          if (this.deporte == 5) {
            return element.sport_id > 5;
          } else {
            return element.sport_id == this.deporte;
          }
        });

        this.resultsFilter = this.results.filter(element => {
          return ligas.find(liga => liga.id == element.league_id);
        });
      } else {
        this.resultsFilter = this.results;
      }
    },
    // handleScroll() {
    //   // Any code to be executed when the window is scrolled

    //   if ($(document).height() == $(window).height() + $(window).scrollTop()) {
    //     this.position++;
    //     this.resultsFilter;
    //     //window.scrollTo(0, $(window).scrollTop() - 4);
    //     setTimeout(() => {
    //       console.log("resultFilter");
    //       this.resultsFilter = this.results.slice(0, 5 * this.position);
    //     }, 5000);
    //   }

    //   console.log("Results : ", this.resultsFilter);
    // },
    // top() {
    //   $("html, #container-resultados").animate({ scrollTop: 0 }, 600);
    // },
    filtrar() {
      // this.resultsFilter = this.results.filter(element => {
      //   // var league = this.filterTermLeague.toUpperCase();
      //   // var team = this.filterTermTeam.toUpperCase();
      //   //var result = true;
      //   // if (league != "" && team != "") {
      //   //   result =
      //   //     this.leagues[element.league_id].search(league) >= 0 ||
      //   //     element.Team[0].team_name.search(team) >= 0 ||
      //   //     element.Team[1].team_name.search(team) >= 0;
      //   // } else if (league != "") {
      //   //   result = this.leagues[element.league_id].search(league) >= 0;
      //   // } else if (team != "") {
      //   //   result = element.Team[0].team_name.search(team) >= 0 || element.Team[1].team_name.search(team) >= 0;
      //   // }
      //   //if (!result) this.disablePager = true;
      //   return result;
      // });
    },
    itemsProvider() {
      this.resultsFilter = [];
      const requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      return fetch(this.apiUrl, requestOptions)
        .then(response => response.json())
        .then(response => {
          this.results = response.games;
          //se cargan los 5 primeros
          this.resultsFilter = this.results.slice(0, 5);
          this.leagues = response.leagues;
        });
    }
  },
  watch: {
    fecha() {
      this.apiUrl = `https://apipwa.sellatuparley.com/api/contenido/resultados?date=${this.getFechaFormat()}`;
      this.itemsProvider();
    }
  },
  async mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.itemsProvider();

    await this.$store.dispatch("getLeaguesAsync");
    console.log("Ligas: ", this.getLeagues);

    if (this.getThemelight) {
      document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
    }
  }
};
</script>
<style scoped>
.container {
  margin-top: 10em !important;
  min-height: 450px;
  height: auto;
}
details summary {
  outline: none;
}
::marker {
  content: "";
}

/*.fixed {
  position: fixed !important;
  top: 80%;
  left: 85%;
}*/

.shade {
  font-size: 20px;
  background-color: rgba(217, 243, 252, 0.7);
}

@media screen and (max-width: 360px) {
  .date {
    width: 50%;
  }
}

.date {
  width: 60%;
}

footer {
  background-color: #fff;
  font-size: 0.8em;
  position: absolute;
  left: 0;
  overflow: hidden;
}

/*.title-orange {
  font-size: 1.5em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
  color: rgb(243, 156, 18);
}*/

.btn-buscar {
  background: #001f62 !important;
  width: 100% !important;
  border-radius: 10px;
  color: #ffffff;
}

.datepicker-custom input[type="text"] {
  border-color: #6c757d;
  color: #495057;
  border: 1px solid #ced4da;
  text-align: center;
  height: 25px;
  background-clip: padding-box;
  border-radius: 0.25rem;
}
</style>
