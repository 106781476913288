<template>
  <div id="NotFound" class="container-NotFound">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a href="/" class="nav-link btn-return">{{ $t("regresar") }}</a>
      </li>
    </ul>

    <h1>Lo sentimos pero la pagina no fue Encontrada</h1>
  </div>
</template>
<script>
export default {
  name: "NotFound",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      cargando: true,
      activePage: "Home",
      navbarSolid: false,
      menumobil: false,
      scroll: 0,
      account: this.getUser
    };
  },
  methods: {
    changePage: function(page) {
      this.$store.dispatch("changePage", page);
    }
  }
};
</script>
<style scoped>
div#NotFound h1,
.container-NotFound {
  color: #fff;
}

p {
  text-align: center;
}
h1 {
  text-align: center;
}
.btn-return {
  max-width: 104px;
  text-align: center;
  margin: 10px 10px;
}
</style>
