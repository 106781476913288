var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-height":"500px !important"}},[(this.existSlider && this.getVisualWidth > 1048)?_c('carousel',{staticClass:"carousel-main animate__animated animate__bounceInLeft",attrs:{"id":"customCarouselFullSize","autoplayTimeout":10000,"autoplay":true,"loop":true,"nav":true,"mouseDrag":true,"checkVisible":true,"pullDrag":true,"touchDrag":true,"dots":false,"center":true,"showNext":false,"showPrev":false,"items":1,"responsive":{
      0: {
        items: 1,
        loop: false,
        nav: false,
        autoplay: false,
        mouseDrag: false,
        pullDrag: false,
        touchDrag: false,
        navText: ['', ''],
        center: true
      },
      600: {
        items: 1,
        nav: false,
        center: true,
        navText: ['', '']
      }
    }}},_vm._l((this.getSliders),function(slider,index){return _c('div',{key:index,class:{
        'owl-slide d-flex cover background-image-style': true,
        'contenedor-img-carrusel': !_vm.getIsMobilResolution,
        'height-movil': _vm.getIsMobilResolution
      },style:('background-image: url(' + slider.url + ');'),attrs:{"v-show":slider.load || (slider.load && this.getIsMobilResolution && index < 1),"onLoad":_vm.isLoadImg(slider.url),"data-id":slider.id,"data-orden":slider.orden,"data-status":slider.status}},[(_vm.isVideo(slider.img))?_c('video',{staticClass:"video-bg",attrs:{"src":slider.urlOem,"autoplay":"","loop":"","playsinline":"","muted":""},domProps:{"muted":true}}):_c('img',{attrs:{"src":slider.img}})])}),0):_vm._e(),(this.existSlider && this.getVisualWidth < 1048 && this.getVisualWidth > 500)?_c('carousel',{staticClass:"carousel-main animate__animated animate__bounceInLeft",attrs:{"id":"customCarouselFullSize","autoplayTimeout":10000,"autoplay":true,"loop":true,"nav":true,"mouseDrag":true,"checkVisible":true,"pullDrag":true,"touchDrag":true,"dots":false,"center":true,"showNext":false,"showPrev":false,"items":1,"responsive":{
      0: {
        items: 1,
        loop: false,
        nav: false,
        autoplay: false,
        mouseDrag: false,
        pullDrag: false,
        touchDrag: false,
        navText: ['', ''],
        center: true
      },
      600: {
        items: 1,
        nav: false,
        center: true,
        navText: ['', '']
      }
    }}},_vm._l((this.getSliders),function(slider,index){return _c('div',{key:index,class:{
        'owl-slide d-flex cover background-image-style': true,
        'contenedor-img-carrusel': !_vm.getIsMobilResolution,
        'height-movil': _vm.getIsMobilResolution
      },style:('background-image: url(' + slider.url + ');'),attrs:{"v-show":slider.load || (slider.load && this.getIsMobilResolution && index < 1),"data-id":slider.id,"data-orden":slider.orden,"data-status":slider.status}},[(_vm.isVideo(slider.img))?_c('video',{staticClass:"video-bg",attrs:{"src":slider.urlOem,"autoplay":"","loop":""}}):_c('img',{attrs:{"src":slider.img}})])}),0):_vm._e(),(this.existSlider && this.getVisualWidth < 500)?_c('carousel',{staticClass:"carousel-main animate__animated animate__bounceInLeft",attrs:{"id":"customCarouselFullSize","autoplayTimeout":10000,"autoplay":true,"loop":true,"nav":true,"mouseDrag":true,"checkVisible":true,"pullDrag":true,"touchDrag":true,"dots":false,"center":true,"showNext":false,"showPrev":false,"items":1,"responsive":{
      0: {
        items: 1,
        loop: false,
        nav: false,
        autoplay: false,
        mouseDrag: false,
        pullDrag: false,
        touchDrag: false,
        navText: ['', ''],
        center: true
      },
      600: {
        items: 1,
        nav: false,
        center: true,
        navText: ['', '']
      }
    }}},_vm._l((this.getSliders),function(slider,index){return _c('div',{key:index,class:{
        'owl-slide d-flex cover background-image-style': true,
        'contenedor-img-carrusel': !_vm.getIsMobilResolution,
        'height-movil': _vm.getIsMobilResolution
      },attrs:{"v-show":slider.load || (slider.load && this.getIsMobilResolution && index < 1),"onLoad":_vm.isLoadImg(slider.urlp),"data-id":slider.id,"data-orden":slider.orden,"data-status":slider.status}},[(_vm.isVideo(slider.img))?_c('video',{staticClass:"video-bg",attrs:{"src":slider.urlOem,"autoplay":"","loop":"","playsinline":"","muted":""},domProps:{"muted":true}}):_c('img',{attrs:{"src":slider.img}})])}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }