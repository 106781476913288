<template>
  <div
    class="itemCuston mb-1"
    :class="{ 'notify-width-movil': getIsMobilResolution, 'notify-width-web': !getIsMobilResolution }"
    style="border-bottom: 1px black solid;"
    @click="onLeer(obj.id)"
  >
    <div
      :class="{
        'card-body': true,
        'bg-danger': !obj.leido
      }"
    >
      <h5 class="card-title">{{ obj.titulo }}</h5>
      <p class="card-text" v-html="obj.contenido"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["obj"],
  computed: {
    ...mapGetters(["getNotificaciones", "getIsMobilResolution"])
  },
  methods: {
    onLeer(id) {
      this.$store.commit("setLeido", id);
      if (this.getNotificaciones.filter(x => x.leido == false).length == 0) {
        this.$store.commit("setCampana", true);
      }
    }
  }
};
</script>

<style>
.itemCuston {
  cursor: pointer;
}
.itemCuston:hover {
  background: #ecf0f1;
  filter: opacity(100%) !important;
  border-bottom: 1px green solid !important;
}
.notify-width-movil {
  width: 12rem;
}
.notify-width-web {
  width: 18rem;
}
</style>
