<template>
  <div
    style="
      background-image: url('../img/Hipismo/casino_background.jpg');
      background-repeat: no-repeat;
      background-size: cover;
    "
  >
    <section :class="{ 'section-movil': this.getIsMobilResolution }">
      <div
        :class="{
          degrado4: true,
          tabla: this.getVisualWidth < 1100 && this.getVisualWidth > 500,
          movil: this.getVisualWidth < 500
        }"
        v-if="!urlIframe"
      >
        CASINOS LIVE
      </div>
      <div
        :class="{
          'degrado4 row': true,
          tabla: this.getVisualWidth < 1100 && this.getVisualWidth > 500,
          movil: this.getVisualWidth < 500
        }"
        v-if="urlIframe"
      >
        <div class="col-4" :class="{ 'ml-4': this.getIsMobilResolution }">
          <button
            class="btn-red btn-regresar float-left"
            :class="{ 'float-left': !this.getIsMobilResolution, 'float-left': this.getIsMobilResolution }"
            @click="back()"
          >
            <span><i class="fa fa-angle-double-left"></i> REGRESAR</span>
          </button>
        </div>
        <div class="col-4 mt-2" v-if="!this.getIsMobilResolution">CASINOS LIVE</div>
        <div class="col-4" v-if="!this.getIsMobilResolution"></div>
      </div>

      <div
        :class="{
          'flex-container pt-4': this.getVisualWidth > 1100,
          'flex-container-tabla pt-4': this.getVisualWidth < 1100 && this.getVisualWidth > 500,
          'flex-container-movil': this.getVisualWidth < 500
        }"
        v-if="!urlIframe"
      >
        <div>
          <!-- src="/img/Casino/pragmatic.png" -->
          <img
            v-lazy="'/img/Casino/pragmatic.png'"
            class="img-fluid boder-img"
            alt=""
            @click="openIframe(1)"
            style="cursor: pointer"
          />
        </div>

        <div>
          <!-- src="/img/Casino/vivogaming.png" -->
          <img
            v-lazy="'/img/Casino/vivogaming.png'"
            class="img-fluid boder-img"
            alt=""
            @click="openIframe(2)"
            style="cursor: pointer"
          />
        </div>

        <div>
          <!-- src="/img/Casino/onetouch.png" -->
          <img
            v-lazy="'/img/Casino/onetouch.png'"
            class="img-fluid boder-img"
            alt=""
            @click="openIframe(3)"
            style="cursor: pointer"
          />
        </div>

        <div>
          <!-- src="/img/Casino/swintt.png" -->
          <img
            v-lazy="'/img/Casino/swintt.png'"
            class="img-fluid boder-img"
            alt=""
            @click="openIframe(4)"
            style="cursor: pointer"
          />
        </div>
      </div>

      <div class="w-100" v-if="!this.getIsMobilResolution">
        <!-- Pragmatic Play -->
        <iframe v-if="iframe == 1 && this.iframe > 0" :src="this.getUrliframe.url" class="style-iframe-casino-live">
        </iframe>

        <!-- vivogaming -->
        <iframe v-if="iframe == 2 && this.iframe > 0" class="style-iframe-casino-live" :src="this.getUrliframe.url">
        </iframe>

        <!-- ONETOUCH -->
        <iframe v-if="iframe == 3 && this.iframe > 0" class="style-iframe-casino-live" :src="this.getUrliframe.url">
        </iframe>

        <!-- Swintt -->
        <iframe v-if="iframe == 4 && this.iframe > 0" class="style-iframe-casino-live" :src="this.getUrliframe.url">
        </iframe>
      </div>
      <div v-else>
        <iframe
          v-if="iframe == 1"
          style="border: 0; width: 100%; height: 80vh; position: absolute; right: 0px; top: 30%"
          src="https://sellatuparley-dk3.pragmaticplay.net/gs2c/playGame.do?key=token%3DV2l4d0NucjFXMDZHeTNPekRIMTR2bk40d2l2NXVGOUV4OUUzbm9NSC95VT0%26symbol%3D101%26language%3Des%26tecnology%3DH5%26platform%3DWEB%26lobbyUrl%3Dhttps%253A%252F%252Fsellatuparley.com%252Fcasinos%252Findex&amp;stylename=sllp_sellatuparley"
          allowfullscreen=""
          webkitallowfullscreen=""
          mozallowfullscreen=""
        >
        </iframe>

        <iframe
          v-if="iframe == 2"
          style="
            border: 0;
            position: absolute;
            top: 155px;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            padding-bottom: 0px;
            height: 80vh;
            max-height: calc(100vh - 45px);
            min-height: 520px;
            overflow-y: auto;
          "
          src="https://play.livetables.io/auth/?operatorId=10377001&amp;token=SP11557089-1629901483-14&amp;nickName=YORMAR"
          allowfullscreen=""
          webkitallowfullscreen=""
          mozallowfullscreen=""
        >
        </iframe>

        <iframe
          v-if="iframe == 3"
          style="border: 0; width: 100%; height: 80vh; position: absolute; right: 0px; top: 20%"
          src="https://files.onetouch.io/game/ld-client/latest/index.html?config_id=60b8d0e527fd7b06f077b548&amp;session_id=0f754ea0-3e64-47b0-9cfb-45dd651ff900&amp;lobby_url=https%3A%2F%2Fsellatuparley.com%2Fcasinos_live%2Fscreen&amp;deposit_url=&amp;device_platform=desktop&amp;engine_base_url=wss://live.ot88.io&amp;sub_partner_id=sellatuparley&amp;lang=es&amp;ts=1629901577461"
          allowfullscreen=""
          webkitallowfullscreen=""
          mozallowfullscreen=""
        >
        </iframe>
      </div>
      <div
        v-if="this.validar"
        class="text-center bg-light"
        :class="{ 'w-25': !this.getIsMobilResolution }"
        style="margin-top: 10% !important"
      >
        <div>
          <img v-lazy="'/img/mantenimiento/mantenimiento.png'" width="350" height="250" />
          <div class="form-inline text-danger"></div>
        </div>
      </div>
      <AvisoIframe v-if="!this.validar" :urlnotice="this.urlnotice" />
    </section>
    <button
      data-v-70a0ee36=""
      data-toggle="tooltip"
      title="Pantalla completa"
      class="btn-base border-green btn-iframe"
      v-if="this.iframe"
      @click="abriIframeNuevaPagina"
    >
      <i data-v-70a0ee36="" class="fas fa-expand-arrows-alt" aria-hidden="true"></i>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AvisoIframe from "../components/AvisoIframe.vue";
import openIframen from "../helpers/openIframen";

export default {
  name: "Casino",
  components: {
    AvisoIframe
  },
  data() {
    return {
      urlIframe: false,
      iframe: null,
      avisoClosed: false,
      isLoad: false,
      validar: false,
      urlnotice: null
    };
  },
  computed: {
    ...mapGetters(["getIsMobilResolution", "getThemelight", "getVisualWidth", "getUser", "getUrliframe"])
  },
  mounted() {},
  methods: {
    closeAviso() {
      this.avisoClosed = true;
    },
    isVisibleAviso() {
      return this.getUser && this.getUser.center_id == 2 && this.avisoClosed == false ? true : false;
    },
    async openIframe(valor) {
      this.$store.commit("showLoader");
      localStorage.setItem("url_iframe", null);
      this.urlIframe = true;

      if (valor == 1) {
        await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=101&provider=pragmatic" });
        localStorage.setItem("url_iframe", this.getUrliframe.url);
        this.iframe = this.getUrliframe.url ? 1 : 0;
        this.validar = this.getUrliframe.url ? false : true;
      }
      if (valor == 2) {
        await this.$store.dispatch("urlProveedorIframeAsync", {
          url: "api/client?game_url=d37c7f1a804092380a0ebe6396e748762a9a0c51&provider=slotegrator"
        });
        localStorage.setItem("url_iframe", this.getUrliframe.url);
        this.validar = this.getUrliframe.url ? false : true;
        this.iframe = this.getUrliframe.url ? 2 : 0;
      }
      if (valor == 3) {
        await this.$store.dispatch("urlProveedorIframeAsync", {
          url: "api/client?game_url=20749&provider=onetouchlive"
        });
        localStorage.setItem("url_iframe", this.getUrliframe.url);
        this.iframe = this.getUrliframe.url ? 3 : 0;
        this.validar = this.getUrliframe.url ? false : true;
      }
      if (valor == 4) {
        await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=17600&provider=swintt" });
        localStorage.setItem("url_iframe", this.getUrliframe.url);
        this.iframe = this.getUrliframe.url ? 4 : 0;
        this.validar = this.getUrliframe.url ? false : true;
      }
      if (this.getUrliframe.notice) {
        this.urlnotice = this.getUrliframe.notice;
        this.$bvModal.show("modal-aviso");
      }

      this.$store.commit("hideLoader");
    },
    back() {
      this.urlIframe = false;
      this.iframe = null;
      this.validar = false;
    },
    abriIframeNuevaPagina() {
      openIframen();
      this.back();
    }
  }
};
</script>
<style scoped>
.container-aviso {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: fixed;
  /*top: 120px;*/
  top: 0px;
  left: 0;
  transform: translate(calc(50vw - 50%), calc(50vh - 50%));
}
.lbl-closed {
  position: absolute;
  color: #fff;
  top: 0;
  right: 0.5em;
  font-weight: 300 !important;
  font-size: x-large;
  cursor: pointer;
}
section {
  height: 120vh;
}

.style-iframe-casino-live {
  border: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

.section-movil {
  height: 90vh;
  overflow: hidden;
}
.degrado4 {
  border: 1px solid #333333;
  /* border-radius: 5px; */
  color: #fffefd;
  font-size: 22px;
  font-weight: bold;
  padding: 20px;
  text-align: center;
  background: #101010;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 4.3em;
}

.subtitulo_ {
  color: #cacaca;
  font-size: 14px;
}

.col-centrado {
  float: none;
  margin: 0 auto;
}

.boder-img {
  border-radius: 10px;
}

.subtitulo_ {
  /* color: #cacaca;
  font-size: 14px; */
  color: #fff;
  font-size: 16px;
  font-family: Poppins, sans-serif !important;
}
.subtitulo_light {
  color: #202020;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 0 !important;
}
.flex-container > div {
  width: 300px;
  margin: 10px;
  text-align: center;
}

.flex-container-movil {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-container-movil > div {
  width: 10em !important;
  margin: 10px !important;
  text-align: center !important;
}

.tabla {
  margin-top: 0.9em !important;
  font-size: 1.5em;
}

.movil {
  margin-top: 0.9em !important;
  font-size: 1.5em;
}
.flex-container-tabla {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.flex-container-tabla > div {
  width: 15em !important;
  margin: 10px !important;
  text-align: center !important;
}
.flex-container-movil {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.flex-container-movil > div {
  width: 10em;
  margin: 10px;
  text-align: center;
}
</style>
