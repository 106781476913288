export const slotsFavoritosMixin = {
  methods: {
    getDataFavoritos() {
      console.log("getDataFavoritos");
    },
    async clickFavorito(f) {
      let favoritos = await JSON.parse(localStorage.getItem("favoritos"));
      const existe = await this.existeFavorito(f.id);

      if (!existe) {
        // si no existe en favoritos se agrega en el storage y actualiza el stage
        favoritos.push(f);
        localStorage.setItem("favoritos", JSON.stringify(favoritos));
        this.$store.commit("setFavoritos", favoritos);

        localStorage.setItem("favoritos", JSON.stringify(favoritos));
      } else {
        // en caso de existir se elimina
        const excluyendoFavotito = favoritos.filter(fInternal => {
          return f.id != fInternal.id;
        });
        localStorage.setItem("favoritos", JSON.parse(JSON.stringify(excluyendoFavotito)));
        this.$store.commit("setFavoritos", excluyendoFavotito);
      }
    },
    checkFavoritos() {
      if (localStorage && !localStorage.getItem("favoritos")) {
        localStorage.setItem("favoritos", JSON.stringify([]));
      }

      if (localStorage && localStorage.getItem("favoritos")) {
        this.$store.commit("setFavoritos", JSON.parse(localStorage.getItem("favoritos")));
      }
    },
    async existeFavorito(id) {
      const favoritos = await JSON.parse(localStorage.getItem("favoritos"));
      const existeFavotito = await favoritos.filter(f => {
        return f.id == id;
      });
      return existeFavotito.length > 0;
    },
    removeFavorito(v) {
      console.log("removeFavorito", v);
    },
    addFavorito(v) {
      console.log("addFavorito", v);
    }
  }
};
