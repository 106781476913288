<template>
  <section id="container-mensajeria" class="animate__animated animate__backInUp">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-10">
              <h3 class="card-title capitalize">{{ $t("mensaje") }}</h3>
            </div>
            <div class="col-2">
              <button class="btn btn-primary float-right" @click="$store.commit('setPage', 'Mensajeria')">
                <i class="fas fa-reply">
                  <span class="mx-2">{{ $t("regresar") }}</span>
                </i>
              </button>
            </div>
          </div>
        </div>
        <div :class="{ 'card-body no-desbordar': true, isMobil: this.getVisualWidth < 1000 }" style="max-height: auto;">
          <div class="row">
            <div class="col-sm-12 col-lg-12">
              <div class="form-group">
                <label for="monto">{{ $t("asunto") }}</label>
                <div>{{ mensaje.asunto }}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-lg-12">
              <div class="form-group">
                <label for="monto">{{ $t("fecha") }}</label>
                <div>{{ dateFormat(mensaje.fecha) }}</div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6 col-lg-12">
              <div class="form-group">
                <label for="monto">{{ $t("mensaje") }}</label>
                <div v-html="mensaje.mensaje"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-lg-12">
              <div class="form-group">
                <label for="monto">{{ $t("respuesta") }}</label>
                <ckeditor v-model="respuesta"></ckeditor>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col text-right">
              <button class="btn btn-primary btn-calcular mr-4" v-on:click="registrar()">
                {{ $t("enviar") }}
              </button>
              <button class="btn cerrar-btn mr-4" v-on:click="toPage()">
                {{ $t("cancelar") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "mensaje",
  data() {
    return {
      mensaje: [],
      respuesta: null
    };
  },
  methods: {
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    async getMensaje() {
      this.$store.commit("showLoader");
      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${this.getToken.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + `api/client?message=${this.getMensajeId}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.mensaje = result.message;
          this.$store.commit("hideLoader");
          document.getElementById("mensajeDiv").innerHTML = this.mensaje.mensaje;
        })
        .catch(error => console.log("error", error));
    },
    toPage: function() {
      this.$emit("changePage", "Mensajeria");
    },
    async registrar() {
      const urlApi = process.env.VUE_APP_URL_API + `api/client/message`;
      let formdata = new FormData();

      if (this.respuesta == "") {
        this.Swal.fire("Por favor", "Debe incluir una respuesta", "error");
        return false;
      }

      formdata.append("id", this.mensaje.id);
      formdata.append("asunto", this.mensaje.asunto);
      formdata.append("message", this.respuesta);

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${this.getToken.access_token}`);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
      };

      await fetch(urlApi, requestOptions)
        .then(async response => {
          //await response.clone().json();
          if (response.ok) {
            this.Swal.fire("Excelente!", "Respuesta enviada!", "success").then(result => {
              if (result.value) {
                this.$emit("changePage", "Mensajeria");
              }
            });
          }
        })
        .catch(error => console.log("error", error));
    }
  },
  computed: {
    ...mapGetters(["getMensajeId", "getToken", "getVisualWidth"])
  },
  mounted() {
    this.getMensaje();
  },
  beforeDestroy() {
    this.mensaje = null;
  }
};
</script>

<style>
div.card-body.no-desbordar.isMobil a,
div.card-body.no-desbordar.isMobil a img,
div.card-body.no-desbordar.isMobil img,
div.card-body.no-desbordar.isMobil table {
  max-width: 85vw !important;
}

div.card-body.no-desbordar.isMobil img {
  width: 80vw !important;
}

section {
  display: block;
  height: auto;
  overflow-y: auto;
}
.container {
  margin-top: 60px;
}

@media screen and (min-width: 720px) {
  #container-mensajeria {
    overflow-y: hidden;
    padding-top: 100px;
    padding-bottom: 0.5px;
    margin-bottom: -100px;
    height: auto !important;
  }
}
</style>

<style scoped>
label {
  margin-bottom: -5rem !important;
}
</style>
