import Vue from "vue";
import Router from "vue-router";

import App from "./App.vue";
import ActivationUser from "./pages/ActivationUser";
import Layout from "./Layout.vue";
import Loader from "./components/Loader.vue";

Vue.use(Router);
//history: createWebHistory(process.env.BASE_URL),
export default new Router({
  history: true,
  root: "/",
  mode: "history",
  hash: false,
  routes: [
    {
      path: "/",
      name: "app",
      component: App
    },
    {
      path: "/home",
      name: "home",
      component: App
    },
    {
      path: "/Contacto",
      name: "contacto",
      component: App
    },
    {
      path: "/about",
      name: "about",
      component: Loader
    },
    {
      path: "/user/activate",
      name: "activate",
      component: ActivationUser
    },
    {
      path: "/user/activate/:tk",
      name: "activateTk",
      component: ActivationUser,
      params: true
    },
    {
      path: "/modelado-flexible",
      name: "modelado",
      component: Layout
    }
    //{ path: '*', redirect: '/' }, // catch all use case
  ]
});

// Referencia para Vuejs 2
// https://vueschool.io/articles/vuejs-tutorials/how-to-use-vue-router-a-complete-tutorial/
// https://codingpotions.com/vue-router

// {
//   path: '/about',
//   name: 'about',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
// }
