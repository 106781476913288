<template>
  <div>
    <div id="carouselControls2" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner" id="contenedor-carrusel"></div>

      <a
        v-show="this.getMiniBannersPromociones.length > 4"
        class="carousel-control-prev"
        href="#carouselControls2"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        v-show="this.getMiniBannersPromociones.length > 4"
        class="carousel-control-next"
        href="#carouselControls2"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SliderBaner",
  computed: {
    ...mapGetters(["getVisualWidth", "getMiniBannersPromociones"])
  },
  data() {
    return {
      activo: false,
      arrySlider: [],
      dispositivo: 4
    };
  },
  async mounted() {
    this.crearDispositivo();
    await this.crearCarrusel();
  },
  watch: {
    getVisualWidth() {
      this.crearDispositivo();
    },
    getMiniBannersPromociones() {
      this.crearCarrusel();
    }
  },
  methods: {
    async crearCarrusel() {
      let carrusel = "";
      let datos = new Array();
      let total = new Array();

      let contador = 1;
      let totalArray = this.getMiniBannersPromociones.length;
      this.getMiniBannersPromociones.forEach(element => {
        datos.push(element);
        if (datos.length == this.dispositivo) {
          total.push(datos);
          datos = new Array();
        }

        if (contador == totalArray && datos.length > 0) {
          total.push(datos);
        }
        contador++;
      });

      for (let i = 0; i < total.length; i++) {
        if (i == 0) {
          carrusel = this.crearCarruselActivo(total[i]);
        } else {
          carrusel = carrusel + this.crearPlantilla(total[i]);
        }
      }
      const a = document.getElementById("contenedor-carrusel");
      a.innerHTML = carrusel;
    },
    crearCarruselActivo(datos) {
      let rowSlider = "";
      if (this.dispositivo == 1) {
        for (let i = 0; i < datos.length; i++) {
          rowSlider =
            rowSlider +
            `<div class="row justify-content-center">
                          <a href="${datos[i].enlace}" target="_blank" > 
                            <img src="${datos[i].url}" class="img-fluid" width="200" height="80" />
                          <a/>
                        </div>`;
        }
        return `<div class="carousel-item active">
                      <div class="row justify-content-center">
                        ${rowSlider}              
                      </div>
                  </div>`;
      }
      if (this.dispositivo == 3) {
        for (let i = 0; i < datos.length; i++) {
          rowSlider =
            rowSlider +
            `<div class="col-md-3">
                          <a href="${datos[i].enlace}" target="_blank" > 
                              <img src="${datos[i].url}" class="img-fluid" width="200" height="80" />
                          <a/>   
                        </div>`;
        }
        return `<div class="carousel-item active">
                      <div class="row justify-content-center">
                        ${rowSlider}              
                      </div>
                  </div>`;
      }
      if (this.dispositivo == 4) {
        for (let i = 0; i < datos.length; i++) {
          rowSlider =
            rowSlider +
            `<div class="col-md-2">
                          <a href="${datos[i].enlace}" target="_blank" > 
                              <img src="${datos[i].url}" class="img-fluid" width="200" height="80" />
                          <a/>
                        </div>`;
        }
        return `<div class="carousel-item active">
                      <div class="row justify-content-center">
                        ${rowSlider}              
                      </div>
                  </div>`;
      }
    },
    crearPlantilla(datos) {
      let rowSlider = "";
      if (this.dispositivo == 1) {
        for (let i = 0; i < datos.length; i++) {
          rowSlider =
            rowSlider +
            `<div class="row justify-content-center">
                            <a href="${datos[i].enlace}" target="_blank" > 
                              <img src="${datos[i].url}" class="img-fluid" width="200" height="80" />
                            </a>
                        </div>`;
        }
        return `<div class="carousel-item">
                      <div class="row justify-content-center">
                        ${rowSlider}              
                      </div>
                  </div>`;
      }
      if (this.dispositivo == 3) {
        for (let i = 0; i < datos.length; i++) {
          rowSlider =
            rowSlider +
            `<div class="col-md-3">
                          <a href="${datos[i].enlace}" target="_blank" > 
                            <img src="${datos[i].url}" class="img-fluid" width="200" height="80"/>
                          <a/>
                        </div>`;
        }
        return `<div class="carousel-item">
                      <div class="row justify-content-center">
                        ${rowSlider}              
                      </div>
                  </div>`;
      }
      if (this.dispositivo == 4) {
        for (let i = 0; i < datos.length; i++) {
          rowSlider =
            rowSlider +
            `<div class="col-md-2">
                          <a href="${datos[i].enlace}" target="_blank" > 
                            <img src="${datos[i].url}" class="img-fluid" width="200" height="80"/>
                          <a/>
                        </div>`;
        }
        return `<div class="carousel-item">
                      <div class="row justify-content-center">
                        ${rowSlider}              
                      </div>
                  </div>`;
      }
    },
    crearDispositivo() {
      // web
      if (this.getVisualWidth > 1048) {
        this.dispositivo = 4;
        this.crearCarrusel();
      }
      //Table
      if (this.getVisualWidth < 1048 && this.getVisualWidth > 500) {
        this.dispositivo = 3;
        this.crearCarrusel();
      }
      if (this.getVisualWidth < 500) {
        this.dispositivo = 1;
        this.crearCarrusel();
      }
    }
  }
};
</script>
<style></style>
