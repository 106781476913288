<template>
  <div id="app" :class="{ 'overflow-hidden': this.getIsMobilResolution }">
    <FullLoader :cargando="this.$store.state.loader"></FullLoader>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-12">
          <div class="callout callout-info">
            <h5 class="p-3">
              <i class="fas fa-info"></i> {{ $t("nota") }}:
              <small
                >Proceso de activaci&oacute;n de cuenta espere un momento mientras se valida y verifica la
                informaci&oacute;n.</small
              >
            </h5>
          </div>
        </div>
      </div>
      <div class="invoice p-3 mb-3 mt-3">
        <div class="row">
          <div class="col-12">
            <h4><i class="fas fa-globe"></i> Procesando la activacion del token.</h4>
          </div>
        </div>
        <div class="row" v-if="this.activoCuenta">
          <div class="col-12">
            <p class="text-muted well well-sm shadow-none" style="margin-top: 10px;">
              Su cuenta ha sido activada satisfactoriamente.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

import FullLoader from "../components/FullLoader.vue";
export default {
  name: "ActivationUser",
  components: { FullLoader },
  computed: {
    ...mapGetters(["getVisualWidth", "getIsMobilResolution"])
  },
  data() {
    return {
      tkActivation: null,
      activePage: "Home",
      account: null,
      activoCuenta: false
    };
  },
  methods: {
    changePage: function(page) {
      this.$store.dispatch("changePage", page);
    }
  },
  async created() {
    this.$store.commit("showLoader");

    this.tkActivation =
      window.location.search.split("?token=")[1] != undefined ? window.location.search.split("?token=")[1] : null;

    let resp = await this.$userService.userActivate(this.tkActivation);

    console.log(resp, typeof resp);

    if (resp.toString().toUpperCase() == "OK") {
      this.activoCuenta = true;

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      });

      Toast.fire({
        icon: "success",
        title: "Cuenta Activada satisfactoriamente"
      });

      setTimeout(() => {
        localStorage.setItem("page", "Home");
        this.$store.commit("setPage", "Home");
        this.$store.dispatch("changePage", "Home");

        window.location.href = window.location.host.includes("localhost")
          ? "http://" + window.location.host + "?redirect=login"
          : "https://" + window.location.host + "?redirect=login";
      }, 2700);
    } else {
      let dt = JSON.parse(resp);

      if (dt.message && dt.message == "Unauthenticated.") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "Error",
          title: "Lo sentimos pero este token ya ha expirado"
        });

        console.log(this.$route.params);
        setTimeout(() => {
          //this.$router.push({ path: '/' })
          // this.$router.replace('../home');
          // this.$store.commit("setPage", "Home");
          // this.$store.dispatch("changePage", "Home");

          window.location.href = window.location.host.includes("localhost")
            ? "http://" + window.location.host
            : "https://" + window.location.host;
        }, 2200);
      }
    }

    this.$store.commit("hideLoader");
    console.log(this.tkActivation, resp);
    //userActivate
  }
};
</script>
<style scoped>
.callout.callout-info {
  border-left: 5px solid #00a9c5;
  background-color: #eff6fb;
  border-radius: 7px;
  padding: 0.5em;
}

.callout.callout-info p {
  word-wrap: break-word;
}

div.invoice {
  background-color: #eff6fb;
  border-radius: 7px;
  margin-top: 0.5em;
}
</style>
