<template>
  <iframe :src="this.url" width=" 100%" class="iframeStyle" />
</template>

<script>
export default {
  props: ["url"]
};
</script>

<style scoped>
.iframeStyle {
  border: 0;
  position: absolute;
  /* top: 180px !important; */
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 0px;
  height: 100%;
}
</style>
