//*****************************************************
// Jesus Hernandez y Lehy Guzman 18-04-2022 ***********
// Aqui filtramos la data de los slot a mobil y web ***
//****************************************************/

import { mapGetters } from "vuex";

export const filtrarSlotsMixin = {
  computed: {
    ...mapGetters(["getDataSlots", "getFavotitos"])
  },
  methods: {
    async filtrarCategoria(categoria) {
      const slotsfiltrados = await this.getDataSlots.games.filter(element => {
        if (categoria == "todos") return true;

        if (categoria == "slots" && element.type.includes(categoria)) {
          return true;
        }
        if (categoria == "Bingo" && element.type.includes(categoria)) {
          return true;
        }
        if (categoria == "Keno" && element.type.includes(categoria)) {
          return true;
        }
        if (categoria == "table" && element.type.includes(categoria)) {
          return true;
        }
        if (categoria == "card" && element.type.includes(categoria)) {
          return true;
        }
        if (categoria == "poker" && element.type.includes(categoria)) {
          return true;
        }
      });

      return slotsfiltrados;
    },
    filtrarFabricante(manufacturer) {
      var slotsfiltrados = [];

      this.getDataSlots.games.forEach(element => {
        if (element.manufacturer == manufacturer) {
          slotsfiltrados.push(element);
        }
      });
      console.log("Cantidad::", slotsfiltrados.length);

      return slotsfiltrados;
    }
  }
};
