export const setLeaguesStore = (state, leagues) => {
  state.leagues = leagues;
};
export const setGamesStore = (state, games) => {
  state.games = games;
};
export const setGamesType = (state, gamesType) => {
  state.gamesType = gamesType;
};
export const setOdds = (state, odd) => {
  let index = state.odds.findIndex(element => element.id == odd.id);

  if (odd.length == 0) {
    state.odds = [];
  } else {
    if (index > -1) state.odds.splice(index, 1);
    else state.odds.push(odd);
  }
};
export const setFormat = (state, format) => {
  state.format = format;
};
export const setPrize = (state, prize) => {
  state.prize = prize;
};
export const setAmount = (state, amount) => {
  state.amount = amount;
};
export const setBetType = (state, betType) => {
  state.betType = betType;
};
export const setMinTicket = (state, minTicket) => {
  state.minTicket = minTicket;
};
export const setFloatTicket = (state, floatTicket) => {
  state.floatTicket = floatTicket;
};
export const setOddsRestricteds = (state, oddsRestricteds) => {
  let index = state.oddsRestricteds.findIndex(element => element == oddsRestricteds);

  if (index > -1) state.oddsRestricteds.splice(index, 1);
  else state.oddsRestricteds.push(oddsRestricteds);
};
export const setCleanOddsRestrictions = state => {
  state.oddsRestricteds = [];
};
export const setMarkRestricted = (state, markRestricted) => {
  state.markRestricted = markRestricted;
};
export const setBetInitData = (state, betInitData) => {
  state.betInitData = betInitData;
};
export const setMsjError = (state, msjError) => {
  state.msjError = msjError;
};
