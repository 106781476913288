<template>
  <div class="container" style="margin-top: 8em !important">
    <!-- cabecera -->
    <div class="row align-items-center text-center">
      <div class="col-1">
        <div>
          <i class="fas fa-chevron-left fa-lg text-light" v-on:click="back()"></i>
        </div>
      </div>
      <div class="col-10 text-light mt-2">
        <div><h4>DEPORTES</h4></div>
      </div>
    </div>

    <div class="row my-2">
      <div class="col-6 float-left">
        <button
          class="boton-green rounded pulsar-btn w-100"
          :class="{ 'boton-blue': this.getThemelight }"
          v-on:click="$emit('changePage', 'TicketsMovil')"
        >
          Tickets jugados
        </button>
      </div>
      <div class="col-6 float-left">
        <button
          class="boton-green rounded pulsar-btn text-center w-100"
          :class="{ 'boton-blue': this.getThemelight }"
          v-b-modal.modal-2
        >
          Streaming
        </button>
      </div>
    </div>

    <div class="card mt-3 btn-bg" :class="{ 'btn-bg-light': this.getThemelight }">
      <div class="row my-2 text-center" id="div-lapso">
        <div class="col">
          <button
            class="btn boton-dark mr-2 pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnHoy"
            @click="filterTemp('Hoy')"
          >
            Hoy
          </button>

          <button
            class="btn boton-dark pulsar-btn active"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnTodas"
            @click="filterTemp('Todas')"
          >
            Todas
          </button>

          <button
            class="btn boton-dark ml-2 pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnFecha"
            v-on:click="verCalendario = !verCalendario"
          >
            Fecha
          </button>
        </div>
      </div>
    </div>

    <div class="card mt-3 btn-bg" :class="{ 'btn-bg-light': this.getThemelight }">
      <div
        class="row text-center my-1 font-weight-bold"
        :class="{ 'card-header-light': this.getThemelight, 'text-light': !this.getThemelight }"
      >
        <div class="col-6">Modalidad</div>
        <div class="col-6">Cuota</div>
      </div>
      <form>
        <div class="row text-center mt-0" id="div-tipos">
          <div class="col-6">
            <v-select
              class="style-chooser"
              placeholder="Modalidad"
              :options="modalidades"
              :reduce="modalidad => modalidad.type"
              v-model="modalidad"
              label="label"
              @input="changeTipoParley()"
              :searchable="false"
              :clearable="false"
            >
            </v-select>
          </div>
          <div class="col-6 text-left">
            <div class="mb-2">
              <v-select
                class="style-chooser"
                placeholder="Cuotas"
                :options="cuotas"
                :reduce="cuota => cuota.type"
                v-model="cuota"
                label="label"
                @input="changeFormat(cuota)"
                :searchable="false"
                :clearable="false"
              >
              </v-select>
            </div>
          </div>
          <div class="row text-center mb-2">
            <div class="col-6">
              <input
                type="number"
                id="amount"
                ref="amount2"
                placeholder="Monto Apuesta"
                size="15"
                style="height: 2.2em"
                class="form-control text-center"
                v-model.number="monto"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="col-6">
              <div class="bg-light rounded" style="height: 2.2em">
                <p style="padding-top: 0.4em !important" v-if="getPrize > 0">
                  {{ getPrize }}
                </p>
                <p style="padding-top: 0.4em !important; color: #6c757d" v-else>Monto Premio</p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row" v-if="verCalendario">
      <div class="col">
        <Datepicker
          :language="languages[language]"
          v-model="dateTargetPicker"
          placeholder="Seleccione fecha"
          class="datepicker-custom"
          readonly="readonly"
          id="btnCalendario"
          @input="filterTemp('Fecha')"
        ></Datepicker>
      </div>
    </div>

    <div
      class="card btn-bg mt-2"
      style="font-size: 0.8em"
      id="div-deportes"
      :class="{ 'btn-bg-light': this.getThemelight }"
    >
      <div class="row my-2 text-center">
        <div class="col-4">
          <button
            value="1"
            id="btnBeisbol"
            class="btn-sport boton-dark rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            v-on:click="filtrarTorneosXDeporte('Béisbol')"
          >
            Béisbol
          </button>
        </div>
        <div class="col-4">
          <button
            value="2"
            id="btnFutbol"
            class="btn-sport boton-dark rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            v-on:click="filtrarTorneosXDeporte('Fútbol')"
          >
            Fútbol
          </button>
        </div>
        <div class="col-4">
          <button
            value="3"
            class="btn-sport boton-dark rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnBasquet"
            v-on:click="filtrarTorneosXDeporte('Baloncesto')"
          >
            Basquet
          </button>
        </div>
      </div>
      <div class="row my-2 text-center">
        <div class="col-4">
          <button
            value="4"
            class="btn-sport boton-dark rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnFutame"
            v-on:click="filtrarTorneosXDeporte('Fútbol americano')"
          >
            Fut Amer
          </button>
        </div>
        <div class="col-4">
          <button
            value="5"
            class="btn-sport boton-dark rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnHockey"
            v-on:click="filtrarTorneosXDeporte('Hockey sobre hielo')"
          >
            Hockey
          </button>
        </div>
        <div class="col-4">
          <button
            value="6"
            class="btn-sport boton-dark btn-block rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnOtros"
            v-on:click="filtrarTorneosXDeporte('Otros')"
          >
            Otros
          </button>
        </div>
      </div>
    </div>

    <div v-if="activaSelTorneo">
      <div class="row my-2" v-if="this.torneos.length > 0">
        <div class="col">
          <v-select
            @input="filtrarLigas(torneo)"
            class="style-chooser"
            placeholder="Seleccione torneo"
            :options="torneos"
            style="width: 100%; color: black; z-index: 2"
            :reduce="torneo => torneo"
            label="torneo"
            :searchable="false"
            v-model="torneo"
          >
          </v-select>
        </div>
      </div>
      <div class="card dark-bg text-light text-center my-3" :class="{ 'light-bg': this.getThemelight }" v-else>
        <h5 class="my-4">No hay juegos disponibles</h5>
      </div>
    </div>

    <div class="row my-2" v-if="ligas.length > 0">
      <div class="col">
        <v-select
          @input="filtrarJuegos(liga)"
          class="style-chooser"
          placeholder="Seleccione liga"
          :options="ligas"
          style="width: 100%; color: black"
          :reduce="liga => liga"
          label="liga"
          :searchable="false"
          v-model="liga"
        >
        </v-select>
      </div>
    </div>

    <div class="card btn-bg" v-if="this.getUser" :class="{ 'btn-bg-light font-weight-bold': this.getThemelight }">
      <div class="m-2 text-sub" :class="{ 'bg-light': this.getThemelight }">
        <div class="row mt-2">
          <div class="col-6">
            <span class="ml-2"> Saldo: </span>
          </div>
          <div class="col-6 text-right">
            <span class="mr-2"> {{ getSimboloMoneda }}{{ formatNumeric(Number(this.getUser.credit)) }} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <span class="ml-2"> Saldo diferido: </span>
          </div>
          <div class="col-6 text-right">
            <span class="mr-2"> {{ getSimboloMoneda }}{{ formatNumeric(Number(this.getUser.diferido)) }} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <span class="ml-2"> Crédito: </span>
          </div>
          <div class="col-6 text-right">
            <span class="mr-2"> {{ getSimboloMoneda }}{{ formatNumeric(Number(this.getUser.credito)) }} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <span class="ml-2"> Total disponible: </span>
          </div>
          <div class="col-6 text-right">
            <span class="mr-2">
              {{ getSimboloMoneda
              }}{{
                formatNumeric(
                  Number(this.getUser.credit) + Number(this.getUser.credito) - Number(this.getUser.diferido)
                )
              }}
            </span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            <span class="ml-2"> Bonus: </span>
          </div>
          <div class="col-6 text-right">
            <span class="mr-2">{{ getSimboloMoneda }}{{ formatNumeric(this.getUser.bonus) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="card dark-bg goBottom" v-if="getGamesStore.length > 0">
      <div class="card-header bg-header">
        <div class="text-center">
          <h5>{{ titulo }}</h5>
        </div>
      </div>
      <div class="game" v-for="(game, index) in getGamesStore" :key="index">
        <component :is="'Logros'" :game="game"></component>
      </div>
    </div>
    <div class="card dark-bg text-light text-center my-3" :class="{ 'light-bg': this.getThemelight }" v-else>
      <h5 class="my-4">No hay juegos disponibles</h5>
    </div>

    <action-sheet-test v-bind:selectOdds="getOdds"></action-sheet-test>
    <StreamingModal />
  </div>
</template>

<script>
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";

import Logros from "./detalle/Logros.vue";
import StreamingModal from "./StreamingModal.vue";
import ActionSheetTest from "./ActionSheetTest.vue";

import { essentialsData } from "@/mixins/essentialsDataMixin.js";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      name: "TaquillaDeportiva",
      titulo: "Juegos Destacados",
      language: "es",
      languages: lang,
      fecha: moment().format(),
      btnFechaActivo: "btnHoy",
      games: [],
      logros: null,
      fecha_actual: "",
      liga_actual: "",
      modalidad: "Parley",
      modalidades: [
        { type: "Parley", label: "Parley" },
        { type: "Directa", label: "Directa" }
      ],
      cuota: "odds",
      cuotas: [
        { type: "odds", label: "Americano" },
        { type: "odds_a", label: "Decimal" }
      ],
      monto: "",
      ligas: [],
      liga: "",
      activaSelLigas: false,
      fechaFiltro: { date: moment().format("YYYY-MM-DD") },
      deporte_id: "",
      destacados: true,

      ///////////////Nuevos//////////////////////////
      torneos: [],
      torneo: "",
      activaSelTorneo: false,
      verCalendario: false,
      deporte: null,
      dateTargetPicker: moment().format("YYYY-MM-DD")
    };
  },
  mixins: [essentialsData],
  components: {
    Logros,
    vSelect,
    StreamingModal,
    Datepicker,
    ActionSheetTest
  },
  computed: {
    ...mapGetters([
      "getGames",
      "getLigas",
      "getGamesStore",
      "getLeagues",
      "getThemelight",
      "getPrize",
      "getUser",
      "getSimboloMoneda",
      "getLeaguesStore",
      "getOdds",
      "getBetType"
    ])
  },
  watch: {
    monto(m) {
      this.$store.commit("setAmount", m);
      this.updatePrize();
    }
  },
  methods: {
    ...mapActions(["actionGames", "actionLeagues", "updatePrize", "actionRestrictions"]),
    back() {
      this.$emit("changePage", "Home");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeTipoParley() {
      if (this.modalidad == "Parley") {
        this.$store.dispatch("verifyOdds");
      } else this.$store.commit("setBetType", "Directa");

      this.modalidad = this.getBetType;
    },
    async filtrarTorneosXDeporte(deporte) {
      this.torneo = "";
      this.torneos = [];
      this.deporte = deporte;
      this.ligas = [];
      this.liga = null;

      let ligas = [];

      if (this.getLeaguesStore[deporte]) {
        ligas = Object.entries(this.getLeaguesStore[deporte]);
      }
      this.activaSelTorneo = true;

      ligas.forEach(element => {
        this.torneos.push({
          torneo: element[0],
          ligas: Object.entries(element[1])
        });
      });
    },
    filtrarLigas(torneo) {
      this.ligas = [];
      this.liga = null;

      torneo.ligas.forEach(element => {
        this.ligas.push({
          id: element[0],
          liga: element[1]
        });
      });
    },
    async filtrarJuegos(liga) {
      const id = liga.id;
      const fecha = moment().format("YYYY-MM-DD");

      let params = {
        id,
        fecha
      };

      this.actionGames(params);
    },
    async filterTemp(period) {
      let paramDate = "";

      if (period == "Fecha") paramDate = `&date=${moment(this.dateTargetPicker).format("YYYY-MM-DD")}`;
      if (period == "Hoy") paramDate = `&date=${moment().format("YYYY-MM-DD")}`;

      await this.actionLeagues(paramDate);

      this.filtrarTorneosXDeporte(this.deporte);
    },
    changeFormat(oddFormat) {
      this.$store.commit("setFormat", oddFormat);
    }
  },
  async mounted() {
    this.actionLeagues();
    this.actionGames();
    this.actionRestrictions();
    this.modalidad = this.getBetType;
    $("#div-lapso .col > .btn").click(function() {
      $("#div-lapso .col > .btn").removeClass("active");
      $(this).addClass("active");
    });
    $("#div-deportes .row .col-4 > .btn-sport").click(function() {
      $("#div-deportes .row .col-4 > .btn-sport").removeClass("active");
      $(this).addClass("active");
    });
  },
  beforeUpdate() {
    let gameIdDiff = null;

    this.getGamesStore.map(element => {
      element.showTitle = element.League.id == gameIdDiff ? false : true;

      gameIdDiff = element.League.id;

      return element.showTitle;
    });
  }
};
</script>

<style scoped>
section {
  height: auto;
}
.dark-bg {
  background-color: #404040;
}
.boton-green {
  background-image: linear-gradient(-90deg, #6bff00, #0d6403);
  border: none;
  color: #ffffff !important;
  font-size: 1em !important;
  font-weight: 600;
  width: 9em;
  height: 2.5em;
  line-height: 1em;
}

.active {
  background: linear-gradient(90deg, #f09819, #ff512f);
  color: #fff !important;
  font-weight: normal;
}
</style>
