<template>
  <div class="p-0 no-margin text-center pt-2" v-if="!this.getIsMobilResolution">
    <div class="table-responsive mailbox-messages">
      <div class="table-balances">
        <table
          :class="{
            'table table-hover table-striped mb-0': true,
            'table-dark': !this.getThemelight,
            'table-light': this.getThemelight
          }"
        >
          <thead>
            <tr>
              <th class="col-fecha txt-center">{{ $t("fecha") }}</th>
              <th class="col-asunto txt-center">{{ $t("asunto") }}</th>
            </tr>
          </thead>
        </table>
        <div style="height: 25.5em; overflow: auto">
          <table
            id="contenido-table-mensajeria"
            :class="{ 'table table-hover table-striped': true, 'table-dark': !this.getThemelight }"
          >
            <tbody>
              <tr
                v-for="(menssage, index) in getMensajeria.data"
                :key="index"
                v-show="getMensajeria.data && getMensajeria.total > 0"
                v-on:click="toPage('Mensaje', menssage.id)"
              >
                <td
                  :class="{
                    'col-fecha': true,
                    msjNoLeido: !menssage.leido
                  }"
                >
                  {{ dateFormat(menssage.fecha) }}
                </td>
                <td
                  :class="{
                    'col-asunto text-left': true,
                    msjNoLeido: !menssage.leido
                  }"
                >
                  {{ menssage.asunto }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { paginatorTable } from "../../mixins/paginatorTableMixin.js";
import moment from "moment";

export default {
  name: "MensajeriaTable",
  mixins: [paginatorTable],
  computed: {
    ...mapGetters(["getMensajeria", "getIsMobilResolution", "getToken", "getThemelight"])
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalResults: 0
    };
  },
  methods: {
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY LT");
      }
    },
    toPage(page, id) {
      this.$store.commit("setMensajeId", id);
      this.$emit("changeTarget", page);
    }
  },
  mounted() {
    this.$store.dispatch("getMessengerUserAsync");

    this.totalResults = this.getMensajeria.total;
    console.log(this.totalResults);
    this.$emit("updateTotalResults", this.totalResults);
    this.$emit("updateLimitResults", this.perPage);
    this.$emit("updatePageResults", this.currentPage);
  }
};
</script>
<style scoped>
#contenido-table-mensajeria > tbody tr:hover {
  cursor: pointer;
}
table.table-dark > tbody tr td {
  color: #fff;
}
table.table-dark > tbody tr:hover td,
table.table-dark > tbody tr td:hover {
  color: #777;
}
.col-fecha {
  width: 30%;
}
.col-asunto {
  width: 60%;
}
tbody tr td {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 0.85em;
  color: #777;
}
.options {
  padding: 5px 0px;
}
.msjNoLeido {
  color: #28a745 !important;
}
</style>
