<template>
  <div class="container" id="container-ticket-detalle">
    <!-- <div class="text-right form-inline">
      <i class="fas fa-chevron-left fa-lg text-dark ml-2" v-on:click="back()"></i>
    </div>
    <div class="text-center mt-4 mb-2">
      <span class="text-title"> Detalle de ticket </span>
    </div> -->

    <div class="row text-light">
      <div class="col-1">
        <i class="fas fa-chevron-left fa-lg" v-on:click="back()"></i>
      </div>
      <div class="col-10 text-center text-title">
        <span> Detalle de ticket </span>
      </div>
    </div>
    <div class="card mt-2">
      <div class="row border text-center bg-title">
        <div class="col-6">
          <b>Número</b>
        </div>
        <div class="col-6">
          <b>Ticket Tipo</b>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-6">{{ getTicket.number }}</div>
        <div class="col-6" v-if="getTicket.modalidad">Parley</div>
        <div class="col-6" v-else>Directas</div>
      </div>
      <div class="row border text-center bg-title">
        <div class="col-6">
          <b>Monto apostado</b>
        </div>
        <div class="col-6">
          <b>Premio Total</b>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-6">{{ getSimboloMoneda }}{{ formatoMonto(getTicket.amount) }}</div>
        <div class="col-6">{{ getSimboloMoneda }}{{ formatoMonto(getTicket.prize) }}</div>
      </div>
      <div class="row border text-center bg-title">
        <div class="col-6">
          <b>Fecha</b>
        </div>
        <div class="col-6">
          <b>Monto c/jugada</b>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-6 p-0">{{ dateFormat(getTicket.created_at) }}</div>
        <div class="col-6">{{ getSimboloMoneda }}{{ formatoMonto(getTicket.amount_ind) }}</div>
      </div>
      <!-- </div> -->
      <!-- <div class="text-center" style="background-color: #616060; color: #ffffff; font-weight: 800">
      <div class="col">
        <b><h4>Juegos</h4></b>
      </div>
    </div> -->
      <!-- <div class="card" v-for="game in games" v-bind:key="game.id"> -->
      <!-- <div class="row border text-center" style="background-color: #616060; color: #ffffff; font-weight: 800">
        <div class="col my-2">
          <b><h4>Datos del juego</h4></b>
        </div>
      </div> -->
      <div class="" v-for="game in games" v-bind:key="game.id">
        <div class="row text-center bg-title-juego">
          <div class="col">
            <b>Equipos</b>
          </div>
        </div>
        <div class="row text-center">
          <p class="text-center">{{ game.equipos }}</p>
        </div>
        <div class="row border text-center bg-title">
          <div class="col-6">
            <b>Fecha</b>
          </div>
          <div class="col-6">
            <b>Apuesta</b>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-6 p-0">{{ game.fecha }}</div>
          <div class="col-6">{{ game.odd }} ({{ game.odd_a }})</div>
        </div>

        <div class="row border text-center bg-title">
          <div class="col-6">
            <b>Premio</b>
          </div>
          <div class="col-6">
            <b>Estatus</b>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-6">{{ game.premio }}</div>
          <div class="col-6">{{ game.estatus }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import * as u from "j899";

export default {
  data() {
    return {
      name: "TicketDetailMovil",
      games: []
    };
  },
  methods: {
    back() {
      this.$emit("changePage", "TicketsMovil");
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm A");
      }
    },
    formatoMonto(m) {
      let montoFormato = parseFloat(parseFloat(m).toFixed(2));
      return u.inNumeration("LA", montoFormato);
    },
    top() {
      $("html, #container-ticket-detalle").animate({ scrollTop: 0 }, 600);
      return false;
    }
  },
  computed: {
    ...mapGetters(["getTicket", "getBetInit", "getSimboloMoneda"])
  },
  async mounted() {
    var premio = this.getTicket.prize;
    var estatus = this.getTicket.status;

    var game = {
      fecha: "",
      apuesta: "",
      equipos: "",
      premio: "",
      estatus: ""
    };

    //Peticion provisional
    await this.$store.dispatch("getBetInitAsync");

    this.getTicket.odds.forEach(element => {
      game = {
        fecha: this.dateFormat(element.time),
        apuesta: this.getBetInit.odd_type[element.odd_type] + " (" + element.type + ")",
        equipos: element.teams,
        premio: premio,
        estatus: estatus,
        odd: element.odd,
        odd_a: element.odd_a
      };

      this.games.push(game);
    });

    this.top();
  }
};
</script>

<style scoped>
.container {
  padding-top: 3em;
  min-height: 500px;
}
.bg-title {
  background: #e8eaea;
}

.bg-title-juego {
  background: #b6c0c0;
  font-size: 18px;
}
</style>
