var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background-image":"url('../img/Hipismo/hipismo2.jpg')","background-repeat":"no-repeat","background-size":"cover"}},[_c('section',{class:{
      'top-movil': this.getIsMobilResolution,
      'overflow-auto': this.getUrliframe
    }},[_c('FullLoader',{attrs:{"cargando":!this.isLoad && !this.getIsMobilResolution}}),(!_vm.urlIframe)?_c('div',{class:{
        'degrado4 margin-title-1': true,
        tema: this.getThemelight,
        tabla: this.getVisualWidth < 1280 && this.getVisualWidth > 500,
        movil: this.getVisualWidth < 500
      }},[_vm._v("\n      "+_vm._s(_vm.$t("hipismo_internacional_y_nacional"))+"\n    ")]):_vm._e(),(_vm.urlIframe)?_c('div',{class:{
        degrado4: true,
        tabla: this.getVisualWidth < 1280 && this.getVisualWidth > 500,
        movil: this.getVisualWidth < 500
      }},[_c('button',{staticClass:"btn-red btn-regresar",on:{"click":function($event){return _vm.back()}}},[_c('span',[_c('i',{staticClass:"fa fa-angle-double-left"}),_vm._v(" "+_vm._s(_vm.$t("regresar")))])])]):_vm._e(),_c('br'),(!_vm.urlIframe)?_c('div',{class:{
        'flex-container': this.getVisualWidth > 1280,
        'flex-container-tabla': this.getVisualWidth < 1280 && this.getVisualWidth > 500,
        'flex-container-movil': this.getVisualWidth < 500
      }},[(_vm.monedaExtranjera)?_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/img/Hipismo/boton-hipismo-nacional-internacional.png'),expression:"'/img/Hipismo/boton-hipismo-nacional-internacional.png'"}],staticClass:"img-fluid boder-img",staticStyle:{"cursor":"pointer"},attrs:{"alt":""},on:{"click":function($event){return _vm.openIframe(2)}}})]):_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/img/Hipismo/boton-hipismo-internacional.png'),expression:"'/img/Hipismo/boton-hipismo-internacional.png'"}],staticClass:"img-fluid boder-img",staticStyle:{"cursor":"pointer"},attrs:{"alt":""},on:{"click":function($event){return _vm.openIframe(1)}}})])]):_vm._e(),(!_vm.urlIframe)?_c('div',{class:{
        'degrado4 margin-title-2': true,
        tema: this.getThemelight,
        titulo: this.getVisualWidth < 1280 && this.getVisualWidth > 500,
        movil: this.getVisualWidth < 500
      }},[_vm._v("\n      "+_vm._s(_vm.$t("juegos_virtuales"))+"\n    ")]):_vm._e(),(!_vm.urlIframe)?_c('div',{class:{
        'flex-container': this.getVisualWidth > 1280,
        'flex-container-tabla': this.getVisualWidth < 1280 && this.getVisualWidth > 500,
        'flex-container-movil': this.getVisualWidth < 500
      }},[_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/img/Hipismo/caballos_virtuales.png'),expression:"'/img/Hipismo/caballos_virtuales.png'"}],staticClass:"img-fluid boder-img",staticStyle:{"cursor":"pointer"},attrs:{"alt":""},on:{"click":function($event){return _vm.openIframe(3)}}})]),_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/img/Hipismo/galgos_virtuales.png'),expression:"'/img/Hipismo/galgos_virtuales.png'"}],staticClass:"img-fluid boder-img",staticStyle:{"cursor":"pointer"},attrs:{"alt":""},on:{"click":function($event){return _vm.openIframe(4)}}})])]):_vm._e(),(_vm.urlIframe)?_c('div',{staticClass:"row"},[(this.iframe == 0)?_c('div',{staticClass:"text-center bg-light",class:{ 'w-25': !this.getIsMobilResolution },staticStyle:{"margin-top":"10% !important"}},[_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/img/mantenimiento/mantenimiento.png'),expression:"'/img/mantenimiento/mantenimiento.png'"}],attrs:{"width":"350","height":"250"}}),_c('div',{staticClass:"form-inline text-danger",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fa fa-angle-double-left fa-lg mx-3"}),_c('h3',[_c('b',[_vm._v(_vm._s(_vm.$t("volver")))])])])])]):_vm._e(),_vm._m(0),_c('div',{staticClass:"row"},[(_vm.iframe != 0 && this.getUrliframe.url)?_c('ViewIframe',{staticStyle:{"top":"180px !important","bottom":"10px"},attrs:{"url":this.getUrliframe.url}}):_vm._e()],1)]):_vm._e(),(this.getUrliframe.url)?_c('AvisoIframe',{attrs:{"urlnotice":this.urlnotice}}):_vm._e()],1),(this.iframe != 0)?_c('button',{staticClass:"btn-base border-green btn-iframe",attrs:{"data-v-70a0ee36":"","data-toggle":"tooltip","title":"Pantalla completa"},on:{"click":_vm.abriIframeNuevaPagina}},[_c('i',{staticClass:"fas fa-expand-arrows-alt",attrs:{"data-v-70a0ee36":"","aria-hidden":"true"}})]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"})])}]

export { render, staticRenderFns }