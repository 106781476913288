<template>
  <b-modal
    id="DetailsAccountsModal"
    v-if="getUser"
    size="xl"
    centered
    scrollable
    title="Datos de su registro"
    hide-footer
    ref="details-accounts"
    content-class="details-accounts"
    @hide="closeModal()"
    @show="openModal()"
  >
    <template #modal-header>
      <button @click="closeModal" type="button" class="close x" data-dismiss="modal" aria-label="Close">
        <span @click="closeModal" style="font-size: 34px" aria-hidden="true">&times;</span>
      </button>
      <div class="row cabecera-modal">
        <div class="col-md-2">
          <img src="/img/logo/sellatuparley.png" width="120px" alt="" />
        </div>
        <div class="col-md-4">
          <br />
          <h5 style="color: #001f62; font-weight: bold; font-size: 20px">¡BIENVENIDO!</h5>
          <h5 style="color: #001f62; font-weight: bold; font-size: 18px">
            <i class="far fa-user"
              ><span class="span-formulario">{{ getUser.username }}</span>
            </i>
          </h5>
        </div>

        <div class="col-md-6 pt-4">
          <div class="callout callout-info blue-logo">
            <table>
              <tbody>
                <tr>
                  <td><strong style="margin-left: 5px">Saldo </strong></td>
                  <td class="text-right" style="margin-left: 5px">
                    {{ getSimboloMoneda }} {{ formatNumeric(getUser.credit) }}
                  </td>
                  <td><strong style="margin-left: 150px">Total Disponible </strong></td>
                  <td class="text-right">
                    <span style="margin-left: 10px; font-weight: 700;"
                      >{{ getSimboloMoneda }} {{ formatNumeric(calculoTotalDisponible()) }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td><strong style="margin-left: 5px">S.Diferido </strong></td>
                  <td class="text-right">
                    <span style="margin-left: 10px">{{ getSimboloMoneda }} {{ formatNumeric(getUser.diferido) }}</span>
                  </td>
                  <td><strong style="margin-left: 150px">Bonus </strong></td>
                  <td class="text-right">{{ getSimboloMoneda }} {{ formatNumeric(getUser.bonus) }}</td>
                </tr>
                <tr>
                  <td><strong style="margin-left: 5px">Crédito </strong></td>
                  <td class="text-right">
                    <span style="margin-left: 10px">{{ getSimboloMoneda }} {{ formatNumeric(getUser.credito) }}</span>
                  </td>
                  <td colspan="2" style="text-align: right;">
                    <button type="button" class="btn-red btn-custom-mini rojo-fijo" @click="logOut">
                      <span>Salir</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row cabecera-modal">
        <div class="col-md-12">
          <ul class="nav nav-tabs nav-pills nav-fill" id="opciones-tabs" role="tablist">
            <li
              :class="{
                'nav-item': true,
                'custom-size-20': getIsMobilResolution
              }"
            >
              <a
                style="background: #fff !important; text-decoration: none"
                :class="{
                  'nav-link': true,
                  active: activo == 'Recargas'
                }"
                id="recarga-tab"
                data-toggle="tab"
                href="#recarga"
                role="tab"
                aria-controls="recarga"
                aria-selected="true"
                @click="activar('Recargas')"
              >
                <span v-if="!getIsMobilResolution">Recarga</span>
              </a>
            </li>
            <li
              :class="{
                'nav-item': true,
                'custom-size-20': getIsMobilResolution
              }"
            >
              <a
                style="background: #fff !important; text-decoration: none"
                :class="{
                  'nav-link': true,
                  active: activo === 'my-accounts'
                }"
                id="my-accounts-tab"
                data-toggle="tab"
                href="#my-accounts"
                role="tab"
                aria-controls="my-accounts"
                aria-selected="true"
                @click="activar('my-accounts')"
                >Mis Cuentas Bancarias</a
              >
            </li>
            <li
              :class="{
                'nav-item': true,
                'custom-size-20': getIsMobilResolution
              }"
            >
              <a
                style="background: #fff !important; text-decoration: none"
                id="retiro-tab"
                data-toggle="tab"
                href="#retiro"
                role="tab"
                aria-controls="retiro"
                aria-selected="false"
                @click="activar('Retiros')"
                :class="{
                  'nav-link': true,
                  active: activo == 'Retiros'
                }"
              >
                <span v-if="!getIsMobilResolution">Retiro</span>
                <i v-if="getIsMobilResolution" class="fas fa-horse-head fa-lg"></i>
              </a>
            </li>
            <li
              :class="{
                'nav-item': true,
                'custom-size-20': getIsMobilResolution
              }"
            >
              <a
                style="background: #fff !important; text-decoration: none"
                :class="{
                  'nav-link': true,
                  active: activo == 'EstadoCuenta'
                }"
                id="cuenta-tab"
                data-toggle="tab"
                href="#cuenta"
                role="tab"
                aria-controls="cuenta"
                aria-selected="false"
                @click="buscarEstadosDeCuenta"
              >
                <span v-if="!getIsMobilResolution">Estado de cuenta</span>
                <i v-if="getIsMobilResolution" class="fas fa-book-reader fa-lg"></i>
              </a>
            </li>
            <li
              :class="{
                'nav-item': true,
                'custom-size-20': getIsMobilResolution
              }"
            >
              <a
                style="background: #fff !important; text-decoration: none"
                :class="{
                  'nav-link': true,
                  active: activo == 'Bonos'
                }"
                id="bonos-tab"
                data-toggle="tab"
                href="#bonos"
                role="tab"
                aria-controls="bonos"
                aria-selected="false"
                @click="buscarBonos"
              >
                <span v-if="!getIsMobilResolution">Bonos</span>
                <i v-if="getIsMobilResolution" class="fas fa-align-justify fa-lg"></i>
              </a>
            </li>
            <li
              :class="{
                'nav-item': true,
                'custom-size-20': getIsMobilResolution
              }"
            >
              <a
                style="background: #fff !important; text-decoration: none"
                :class="{
                  'nav-link': true,
                  active: activo == 'ReporteSemanal'
                }"
                id="reporte-tab"
                data-toggle="tab"
                href="#reporte"
                role="tab"
                aria-controls="reporte"
                aria-selected="false"
                @click="activar('ReporteSemanal')"
              >
                <span v-if="!getIsMobilResolution">Reportes Semanal</span>
                <i v-if="getIsMobilResolution" class="fas fa-baseball-ball fa-lg"></i>
              </a>
            </li>
            <li
              :class="{
                'nav-item': true,
                'custom-size-20': getIsMobilResolution
              }"
            >
              <a
                style="background: #fff !important; text-decoration: none"
                :class="{
                  'nav-link': true,
                  active: activo == 'EditPerfil'
                }"
                id="perfil-tab"
                data-toggle="tab"
                href="#perfil"
                role="tab"
                aria-controls="perfil"
                aria-selected="false"
                @click="activar('EditPerfil')"
              >
                <span v-if="!getIsMobilResolution">Editar Perfil</span>
                <i v-if="getIsMobilResolution" class="fas fa-baseball-ball fa-lg"></i>
              </a>
            </li>
            <li
              :class="{
                'nav-item': true,
                'custom-size-20': getIsMobilResolution
              }"
            >
              <a
                style="background: #fff !important; text-decoration: none"
                :class="{
                  'nav-link': true,
                  active: activo == 'changePass'
                }"
                id="clave-tab"
                data-toggle="tab"
                href="#clave"
                role="tab"
                aria-controls="clave"
                aria-selected="false"
                @click="activar('changePass')"
              >
                <span v-if="!getIsMobilResolution">Cambio de clave</span>
                <i v-if="getIsMobilResolution" class="fas fa-baseball-ball fa-lg"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <div class="container body-modal-content">
      <div class="row">
        <div class="tab-content" id="tab-container1">
          <div
            :class="{
              'tab-pane fade': true,
              'show active jh': activo == 'Recargas'
            }"
            id="recarga"
            role="tabpanel"
            aria-labelledby="recarga-tab"
          >
            <button
              v-if="!cuentasVisibles"
              class="btn btn-info float-right mb-2"
              @click="verCuentasBancariasYPagomovil"
            >
              <i class="fa fa-eye"></i> Ver cuentas bancarias y datos de pago móvil
            </button>
            <button
              v-if="cuentasVisibles"
              @click="cuentasVisibles = !cuentasVisibles"
              class="btn btn-default float-right mb-2"
            >
              <i class="fa fa-eye-slash"></i> Ocultar Cuentas Bancarias
            </button>
            <!-- Cuentas bancarias CMS -->
            <div v-show="cuentasVisibles" class="card w-100" v-if="getCuentasBancosCMS">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-phone-square-alt"></i>
                  Pago Movil
                </h3>
              </div>
              <div class="card-body container-detalles">
                <div
                  :class="{
                    'detalle-cuenta col-xl-4 col-md-6 col-sm-12': true,
                    inactivo: !cuenta.status,
                    inactivo: cuenta.tipo != 'm'
                  }"
                  v-for="(cuenta, i) in getCuentasBancosCMS"
                  :key="i"
                >
                  <blockquote>
                    <small>
                      Tipo:
                      <cite v-if="cuenta.tipo == 'm'">Pago Movil</cite>
                    </small>
                    <p>
                      {{ cuenta.banco }}<br />
                      Rif: {{ cuenta.documento }}<br />
                      Telèfono: <span v-if="cuenta.tipo == 'm'">{{ cuenta.telefono }}</span>
                    </p>
                  </blockquote>
                </div>
              </div>
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-university"></i>
                  Cuentas Bancarias
                </h3>
              </div>
              <div class="text-center">
                <div class="callout callout-warning my-3 bg-light text-center">
                  <h4>Transferencia bancaria</h4>
                  <div style="font-size: 0.9em">
                    <div>A NOMBRE DE: CORPORACIÓN TECNODATA 2015 C.A.</div>
                    <div>RIF: J404297286</div>
                    <div>EMAIL: info@sellatuparley.com</div>
                  </div>
                </div>
              </div>
              <div class="card-body container-detalles">
                <div
                  :class="{
                    'detalle-cuenta col-xl-4 col-md-6 col-sm-12': true,
                    inactivo: !cuenta.status,
                    inactivo: cuenta.tipo != 'c'
                  }"
                  v-for="(cuenta, i) in getCuentasBancosCMS"
                  :key="i"
                >
                  <blockquote>
                    <img class="img-fluid" width="140px" v-lazy="getImgBancos(cuenta.img)" alt="" /> <br />
                    <small>
                      Tipo:
                      <cite v-if="cuenta.tipo == 'c'">Cuenta Bancaria</cite>
                    </small>
                    <p>
                      {{ cuenta.banco }}<br />
                      Rif: {{ cuenta.documento }}<br />
                      <span v-if="cuenta.tipo == 'c'">cuenta: {{ viewAcount(cuenta.nro_cuenta) }}</span>
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <Recargas v-if="this.activo == 'Recargas'" @resgistroPagoMobil="showModalDetailsMobilRegister"></Recargas>
          </div>
          <div
            :class="{
              'tab-pane fade': true,
              'show active jh': activo == 'my-accounts'
            }"
            id="my-accounts"
            arial-labelledby="my-accounts"
          >
            <!-- Cuentas bancarias USER -->
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-university"></i>
                  Mis Cuentas Bancarias Registradas
                </h3>
              </div>
              <div class="card-body container-detalles">
                <div
                  :class="{ 'detalle-cuenta col-xl-4 col-md-6 col-sm-12': true }"
                  v-for="cuenta in getAccountsBancos"
                  v-bind:key="cuenta.id"
                >
                  <div class="card card-widget widget-user-2 shadow-sm">
                    <div class="widget-user-header bg-info">
                      <h3 class="widget-user-username">{{ cuenta.banco }}</h3>
                      <h5 class="widget-user-desc">{{ getTypeAccount(cuenta.type_account_id) }}</h5>
                    </div>
                    <div class="card-footer p-0">
                      <ul class="nav flex-column pb-1">
                        <li class="nav-item">
                          <div class="nav-link div-account">
                            {{ cuenta.account }}
                            <button
                              class="
                                btn btn-default btn-small
                                float-right
                                p0
                                btn-delete-account-bank btn-small btn-small
                              "
                            >
                              <i aria-hidden="true" class="fa fa-trash" @click="borrarAccount(cuenta)"></i>
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  :class="{ 'detalle-cuenta col-xl-4 col-md-6 col-sm-12': true }"
                  v-for="(cuenta, i) in getAccountsPagoMobil"
                  :key="i"
                >
                  <div class="card card-widget widget-user-2 shadow-sm">
                    <div class="widget-user-header bg-success text-white">
                      <h3 class="widget-user-username">{{ cuenta.banco }}</h3>
                      <h5 class="widget-user-desc">PAGO MÓVIL</h5>
                    </div>
                    <div class="card-footer p-0">
                      <ul class="nav flex-column pb-1">
                        <li class="nav-item">
                          <div class="nav-link div-account">
                            {{ formatoTelefonico(cuenta.account) }}
                            <button
                              class="
                                btn btn-default btn-small
                                float-right
                                p0
                                btn-delete-account-bank btn-small btn-small
                              "
                            >
                              <i aria-hidden="true" class="fa fa-trash" @click="borrarAccount(cuenta)"></i>
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="{
              'tab-pane fade': true,
              'show active jh': activo == 'Retiros'
            }"
            id="retiro"
            role="tabpanel"
            aria-labelledby="retiro-tab"
          >
            <Retiros v-if="this.activo == 'Retiros'"></Retiros>
          </div>
          <div
            :class="{
              'tab-pane fade': true,
              'show active jh': activo == 'EstadoCuenta'
            }"
            id="cuenta"
            role="tabpanel"
            aria-labelledby="cuenta-tab"
          >
            <div class="loader-table" v-show="cargando">
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">Cargando...</span>
              </div>
            </div>
            <EstadoCuenta v-show="!cargando" :page="1" :perPage="50"></EstadoCuenta>
          </div>
          <div
            id="bonos"
            role="tabpanel"
            aria-labelledby="bonos-tab"
            :class="{
              'tab-pane fade': true,
              'show active jh': activo == 'Bonos'
            }"
          >
            <BonusTable
              :page="pageResults"
              :perPage="limitResults"
              @updatePageResults="updatePageResults"
              @updateLimitResults="updateLimitResults"
              v-show="!cargando"
            ></BonusTable>
            <div class="loader-table" v-show="cargando">
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">Cargando...</span>
              </div>
            </div>
            <b-pagination
              size="sm"
              :per-page="limitResults"
              :limit="3"
              :total-rows="parseInt(totalResults)"
              v-model="pageResults"
              v-if="totalResults != null && pageResults != null && limitResults != null && parseInt(totalResults) > 0"
              custom="size"
              table-variant="light"
              :class="{ 'customPagination float-right light': true }"
              first-number
              last-number
              @input="changePage"
            />
          </div>
          <div
            :class="{
              'tab-pane fade': true,
              'show active jh': activo == 'ReporteSemanal'
            }"
            id="reporte"
            role="tabpanel"
            aria-labelledby="reporte-tab"
          >
            <ReporteSemanalTable v-if="activo == 'ReporteSemanal'"></ReporteSemanalTable>
          </div>
          <div
            :class="{
              'tab-pane fade': true,
              'show active jh': activo == 'EditPerfil'
            }"
            id="perfil"
            role="tabpanel"
            aria-labelledby="perfil-tab"
          >
            <EditPerfil></EditPerfil>
          </div>
          <div
            :class="{
              'tab-pane fade': true,
              'show active jh': activo == 'changePass'
            }"
            id="clave"
            role="tabpanel"
            aria-labelledby="clave-tab"
          >
            <ChangePassword></ChangePassword>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { essentialsData } from "../../mixins/essentialsDataMixin.js";

import Recargas from "../../pages/Recargas.vue";
import ChangePassword from "../../components/ChangePassword.vue";
import EditPerfil from "../../components/EditPerfil.vue";
import Retiros from "../../pages/Retiros.vue";
import BonusTable from "../../components/tables/BonusTable.vue";
import EstadoCuenta from "../../components/tables/EstadoCuentaTable.vue";
import ReporteSemanalTable from "../../components/tables/ReporteSemanalTable.vue";
import moment from "moment";

export default {
  name: "ModalMiCuenta",
  mixins: [essentialsData],
  components: {
    Recargas,
    ChangePassword,
    Retiros,
    EditPerfil,
    BonusTable,
    EstadoCuenta,
    ReporteSemanalTable
  },
  computed: {
    ...mapGetters([
      "getIsMobilResolution",
      "getUser",
      "getBonus",
      "getBalaces",
      "getProducts",
      "getThemelight",
      "getCuentasBancosCMS",
      "getPestana",
      "getCuentaBancos",
      "getAccounts",
      "getSimboloMoneda"
    ])
  },
  data() {
    return {
      totalResults: 0,
      activo: "Recargas",
      limitResults: 15,
      pageResults: 1,
      isTableModule: true,
      paginatorTarget: 1,
      targetModule: "bonus",
      dateTargetPicker: moment(String(new Date())).format("YYYY-MM-DD"),
      masksPicker: { input: "DD-MM-YYYY" },
      cargando: false,
      dateTarget: null,
      cuentasVisibles: false,
      getAccountsPagoMobil: null,
      getAccountsBancos: null
    };
  },
  methods: {
    logOut: function() {
      console.log("logging out");
      this.$store.dispatch("logOut");
    },
    async verCuentasBancariasYPagomovil() {
      this.cuentasVisibles = !this.cuentasVisibles;
    },
    calculoTotalDisponible() {
      //total disponible es = credit + credito
      return parseFloat(parseFloat(this.getUser.credit) + parseFloat(this.getUser.credito)).toFixed(2);
    },
    getTypeAccount(idType) {
      let tipo = "";
      if (idType == 1) {
        tipo = "CORRIENTE";
      }
      if (idType == 2) {
        tipo = "AHORRO";
      }
      if (idType == 4) {
        tipo = "PAGO MÓVIL";
      }
      return tipo;
    },
    formatoTelefonico(cadenaNumerica) {
      let cadena = new String(cadenaNumerica);
      return "+" + cadena.substr(0, 2) + " " + cadena.substr(2, 3) + "-" + cadena.substr(-7, 7);
    },
    async activar(modulo) {
      if (modulo == "my-accounts") {
        if (!this.getCuentaBancos) {
          await this.$store.dispatch("searchUserAccountData");
        }
        console.log(this.getCuentaBancos);
        this.getAccountsPagoMobil = this.getCuentaBancos.filter(d => {
          return d.type_account_id == 4;
        });
        this.getAccountsBancos = this.getCuentaBancos.filter(d => {
          return d.type_account_id == 1 || d.type_account_id == 2;
        });
      }

      if (modulo == "Retiros") {
        if (!this.getAccounts) {
          await this.$store.dispatch("getAccountsClientAsync");
        }
      }

      if (modulo == "ReporteSemanal") {
        await this.$store.dispatch("searcheWeklyReport", this.opciones);
      }

      this.activo = modulo;
    },
    openModal() {
      let pestana = this.getPestana || "Recargas";
      this.activar(pestana);
    },
    closeModal() {
      this.$store.commit("setPestana", "Recargas");
      this.$bvModal.hide("DetailsAccountsModal");
    },
    async showModalDetailsMobilRegister() {
      await this.$store.dispatch("searchUserAccountData");
      document.getElementById("dashboardModal").style.display = "none";
      if (document.getElementsByClassName("modal-backdrop fade show")[0]) {
        document.getElementsByClassName("modal-backdrop fade show")[0].classList.remove("show");
        document.getElementsByClassName("modal-backdrop fade")[0].classList.add("no-display");
      }

      this.$bvModal.show("details-mobil-register");
    },
    async searchCuentasCMS() {
      let ObjCuentaBancoRequest = { url: "/api/v1/getCuentaBanco", set: "setCuentasBancosCMS" };
      await this.$store.dispatch("getData", ObjCuentaBancoRequest);
    },
    getImgBancos(img) {
      if (img) {
        return process.env.VUE_APP_URL_IMG + img;
      }
      return null;
    },
    async updateRetiros() {
      this.activar("Retiros");
      //this.cargando = true;
      // await this.$store.dispatch("getAccountsClientAsync");
      //this.cargando = false;
    },
    async updateValuePicker(v) {
      this.$store.commit("showLoader");
      // Para la busqueda en el endpoind es en formato [YYYY-MM-DD]
      const opciones = {
        date: moment(v).format("YYYY-MM-DD"),
        customDate: true,
        perpage: parseInt(this.limitResults),
        page: parseInt(this.pageResults)
      };

      this.dateTarget = moment(v).format("YYYY-MM-DD");
      this.cargando = true;
      await this.$store.dispatch("getBonusUserAsync", opciones);
      this.cargando = false;
    },
    async changePage(pag) {
      this.cargando = true;

      if (this.targetModule == "balances") {
        await this.$store.dispatch("getBalacesUserAsync", {
          page: pag,
          perpage: this.limitResults
        });
      }

      if (this.targetModule == "recargas") {
        await this.$store.dispatch("getChargesUserAsync", {
          page: pag,
          perpage: this.limitResults
        });
      }

      if (this.targetModule == "bonus") {
        await this.$store.dispatch("getBonusUserAsync", {
          page: pag,
          perpage: parseInt(this.limitResults)
        });
        this.cargando = false;
      }
    },
    async buscarBonos() {
      this.activar("Bonos");
      this.targetModule = "bonus";
      this.cargando = true;
      await this.$store.dispatch("getBonusUserAsync");
      this.cargando = false;
    },
    async buscarEstadosDeCuenta() {
      this.activar("EstadoCuenta");
      this.targetModule = "balances";
      this.cargando = true;
      await this.$store.dispatch("getBalacesUserAsync");
      this.cargando = false;
    },
    updatePageResults(p) {
      this.pageResults = p;
    },
    updateLimitResults(l) {
      this.limitResults = l;
    },
    viewAcount(value) {
      return value.replace(new RegExp("\\-", "g"), "");
    },
    async borrarAccount(account) {
      console.log("borrarAccount", account);
      Swal.fire({
        title: "Seguro de Borrar esta cuenta?",
        text: `${account.banco} - ${account.account}`,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: `Cancelar`
      }).then(async result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.commit("showLoader");
          const resp = await this.$store.dispatch("eliminarCuentaAsync", account.id);
          this.$store.commit("hideLoader");

          if (resp.error && resp.error != "" && resp.error != "ok") {
            Swal.fire("Ah ocurrido un error!", resp.error, "error");
            return;
          }
          await this.$store.dispatch("searchUserAccountData");
          Swal.fire("Eliminada!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Cambios deshechos", "", "info");
        }
      });
    }
  }
};
</script>
<style>
.btn-red.btn-custom-mini {
  height: 25px !important;
  width: 100px !important;
  padding: 3px !important;
}
.btn-red.btn-custom-mini span {
  height: 20px !important;
  line-height: 0.4 !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 0.9em !important;
}
/*  background-color: #f00 !important;*/
.btn-red.btn-custom-mini.rojo-fijo {
  background: #fb1212 !important;
  font-weigth: bold !important;
  color: #fff !important;
  background-image: none !important;
}
.btn-red.btn-custom-mini.rojo-fijo span {
  background-color: transparent !important;
}
/*  background-image: linear-gradient(45deg, #8a0000, #c8305f);*/
.btn-red.btn-custom-mini.rojo-fijo:hover {
  background-color: #d30808 !important;
}

.row.cabecera-modal .callout.callout-info.blue-logo {
  padding: 0 1em !important;
}
.row.cabecera-modal .callout.callout-info.blue-logo > table {
  width: -webkit-fill-available;
}

div#bonos > .container-bonus {
  padding-top: 0px !important;
}
div.card-body.container-detalles .widget-user-username {
  font-size: 17px !important;
}

div.card-body.container-detalles .widget-user-desc,
div.card-body.container-detalles .nav-link.div-account {
  font-size: 15px !important;
}

.btn-delete-account-bank > i {
  color: #afafaf;
}
div.detalle-cuenta > div > div h3,
div.detalle-cuenta > div > div h5 {
  margin-left: 0px !important;
}

.div-account {
  min-height: 2em;
  font-size: 1.3em;
  font-weight: 100;
  color: #55585b;
}

.btn-delete-account-bank {
  padding: 0.1em;
  text-align: center;
  justify-content: center;
  align-content: center;
  font-size: 1em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-weight: 300;
  width: 1.5em;
  height: 1.5em;
}

div.card-body.container-detalles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

div.card-body.container-detalles div.detalle-cuenta {
  /*width: 50%;*/
}

div.detalle-cuenta.inactivo {
  display: none;
}

div.modal-content.details-accounts > header.modal-header {
  padding-bottom: 1px;
}

div.details-accounts > header.modal-header {
  flex-direction: column;
}

div.row.cabecera-modal {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

/*
  width: 1300px !important;
  max-height: 96vh !important;
*/
div.modal-content.details-accounts,
div.modal-dialog.modal-xl.modal-dialog-centered.modal-dialog-scrollable {
  max-width: 1300px !important;
  width: 99vw !important;
  min-height: 100vh !important;
  margin: 0px auto !important;
}

ul#opciones-tabs li.nav-item > a.nav-link {
  color: #495057 !important;
}

ul#opciones-tabs li.nav-item > a.nav-link.active {
  color: rgb(0, 31, 98) !important;
  font-weight: 500;
}

button.close.x {
  position: absolute;
  right: 8px;
  top: 0px;
  z-index: 1500;
}

.tab-content {
  width: 100%;
}

.body-modal-content {
  width: 100%;
  margin: 0px;
  padding: 1em;
  max-width: 1250px;
}

div.container.body-modal-content {
  height: auto !important;
}

@media (max-width: 1300px) {
  ul#opciones-tabs li.nav-item > a.nav-link {
    padding: 0.02rem 0.8rem !important;
  }

  div.modal-content.details-accounts,
  div.modal-dialog.modal-xl.modal-dialog-centered.modal-dialog-scrollable {
    width: 99vw !important;
  }
}
</style>

<style scoped>
.table td,
.table th {
  /* padding: 0.1rem; */
  vertical-align: top;
  border-top: none !important;
}

.callout {
  margin-bottom: 1rem;
  padding: 0rem;
}

td {
  height: 1.8em;
}
</style>
