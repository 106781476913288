import * as util from "j899";
import { Fraction } from "fractional";
import moment from "moment";
import Swal from "sweetalert2";

export const essentialsData = {
  methods: {
    contieneUSD(cadena) {
      return cadena
        .toString()
        .toUpperCase()
        .includes("USD");
    },
    toFraction(n) {
      let fraction = new Fraction(n);
      let txt = fraction.toString().replace(" ", ' <small class="fraction-txt">(') + ")</small>";
      txt = txt.includes("(") ? txt : '<small class="fraction-txt">(' + txt;

      return fraction.toString().includes("NaN") ? "" : txt;
    },
    formatNumeric: function(n) {
      return util.inNumeration("LA", n);
    },
    formatoPrefijo(n) {
      return parseInt(n) > 0 ? `+${n}` : n;
    },
    async searchSliders() {
      const URL_JSON_SLIDERS = process.env.VUE_APP_URL_API_CMS + "/api/v1/getSlider";

      const data = await fetch(URL_JSON_SLIDERS).then(async resp => {
        const obj_json = await resp.json();
        const respuesta = obj_json.sliders ? JSON.parse(obj_json.sliders) : obj_json;
        return respuesta;
      });

      let slider_activos = [];

      data.data.map(async s => {
        if (moment(s.fecha_fin, "YYYY-MM-DD HH:mm:ss").toDate() >= new Date() && s.status) {
          s.url = new String(encodeURI(s.img)).trim().replaceAll(" ", "%20");
          s.urlOem = new String(encodeURI(s.img)).trim().replaceAll(" ", "%20");
          s.urlp = new String(encodeURI(s.imgp)).trim().replaceAll(" ", "%20");

          s.load = false;

          slider_activos.push(s);

          var img = new Image();
          let _self = this;
          img.src = s.url;

          img.onload = function() {
            _self.$store.commit("updateStatusSliderLoad", s.url);
          };
        }
      });

      return slider_activos;
    },
    validateEnterLogin(e) {
      if (e.keyCode === 13) {
        // # Accion de logear al pisar [ENTER] sobre password
        this.ingresar();
      }
    },
    async refreshData() {
      this.$store.commit("showLoader");
      let _self = this;
      setTimeout(
        async () => {
          // if (_self.$store.state.user && _self.$store.state.token && _self.$store.state.token.access_token) {
          // }
          _self.$store.commit("hideLoader");
        },
        1000,
        _self
      );
    },
    formatSymbol(n) {
      const simbolo = this.$store.getters.getFormatoTaquilla == "odd" && parseFloat(n) > 0 ? "+" : "";
      return `${simbolo}${n}`;
      //return parseInt(n) > 0 ? "+" + n : n;
    },
    isArrayAndEmpty(d) {
      return d instanceof Array && d.length == 0;
    },
    evaluaRespuestaErrorRequestMultiplesCasos(data, serviceName) {
      if (data && (data.Error || data.error) && (data.code == 500 || data.code == 404)) {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2500,
          onOpen: toast => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "error",
          title: `El servicio ${serviceName} no esta disponible intente mas tarde`
        });

        console.log("toca lanzar", data, serviceName);
        return -1;
      }
      return data;
    }
  }
};
