export const paginatorTable = {
  methods: {
    isInPage(indexResult) {
      if (
        indexResult == 0 ||
        (indexResult <= this.page * this.perPage && indexResult > this.page * this.perPage - this.perPage)
      ) {
        return true;
      }
    }
  }
};
