<template>
  <b-modal
    id="details-mobil-register"
    size="lg"
    centered
    scrollable
    title="Datos de su registro"
    hide-footer
    ref="details-mobil"
    content-class="details-mobil-register-modal container-details-mobil-register"
  >
    <img class="banner" src="/img/gallery/banner_sella_800x100.png" alt="banner_sella_800x100" />
    <h4 class="title-banner-custom pt-2 pb-2">DATOS BANCARIOS</h4>
    <hr />
    <div
      class="card card-primary card-outline"
      v-if="this.getAccountsPagoMobil && this.getAccountsPagoMobil.length > 0"
    >
      <div class="card-body box-profile">
        <ul
          class="list-group list-group-unbordered mb-3"
          style="
"
        >
          <li class="list-group-item">
            <b>Banco</b>
            <p class="text-muted float-right">{{ this.getAccountsPagoMobil[0].banco }}</p>
          </li>
          <li class="list-group-item">
            <b>Teléfono Registrado</b>
            <p class="text-muted float-right">{{ this.getAccountsPagoMobil[0].account }}</p>
          </li>
          <li class="list-group-item">
            <b>Tipo: </b>
            <p v-if="this.getAccountsPagoMobil[0].type_account_id == 4" class="text-muted float-right">pago mobil</p>
            <p v-else class="text-muted float-right">cuenta bancaria</p>
          </li>
        </ul>
      </div>
      <!-- /.card-body -->
    </div>
    <!--  <pre> {{ this.getAccountsPagoMobil }} </pre> -->
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ModalRegistroMobilDetails",
  computed: {
    ...mapGetters(["getAccountsPagoMobil"])
  }
};
</script>
<style scoped>
.title-banner-custom {
  text-align: center;
  width: 100%;
  font-weight: 400;
}
ul.list-group.list-group-unbordered li,
ul.list-group.list-group-unbordered li b {
  color: #000 !important;
}

ul.list-group.list-group-unbordered li b {
  text-transform: uppercase;
}
.banner {
  width: 100%;
}
</style>
