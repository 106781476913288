export default () => ({
  leagues: [],
  games: [],
  gamesType: null,
  odds: [],
  format: "odds",
  prize: 0,
  amount: 0,
  betType: "Parley",
  minTicket: null,
  floatTicket: null,
  oddsRestricteds: [],
  markRestricted: false,
  betInitData: [],
  msjError: null
});
