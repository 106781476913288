<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="loginModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    @focus="focusInput"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-lg" :class="{ mlogin: !getIsMobilResolution }">
      <div class="modal-content border-redondo">
        <div class="modal-header modal-header-customer">
          <div class="text-center">
            <!-- <img v-lazy="'/img/logo/sellatuparley.png'" width="150px" height="150px" alt="logo" /> <br /> -->
            <h5 style="color: #001f62; font-weight: bold" class="titulo1" v-if="!recoverPassword">
              {{ $t("iniciar_sesion") }}
            </h5>
            <h5 style="color: #001f62; font-weight: bold" class="titulo1" v-if="recoverPassword">
              {{ $t("recuperar_contraseña") }}
            </h5>
          </div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cerrarModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="" name="form-login">
            <div class="container-fluid">
              <div class="row" v-if="!recoverPassword">
                <!-- <div class="col-md-2"></div> -->
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="color-text label-formulario"><i class="far fa-user"></i> {{ $t("usuario") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      ref="inputUser"
                      v-model="user"
                      name="user-none"
                      autocomplete="user-none"
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-if="!recoverPassword">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="color-text label-formulario"
                      ><i class="fas fa-lock"></i> {{ $t("contraseña") }}</label
                    >

                    <br />

                    <div class="input-group mb-1">
                      <input
                        v-model="pass"
                        name="user-p-none"
                        autocomplete="user-p-none"
                        v-on:keyup="validateEnterLogin"
                        :type="displayType()"
                        class="form-control"
                      />

                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i
                            v-if="!showPass"
                            class="fas fa-eye"
                            aria-hidden="true"
                            style="cursor: pointer"
                            @click="showPass = !showPass"
                          ></i>
                          <i
                            v-if="showPass"
                            class="fas fa-eye-slash"
                            aria-hidden="true"
                            style="cursor: pointer"
                            @click="showPass = !showPass"
                          ></i>
                        </span>
                      </div>
                    </div>

                    <div>
                      <div class="form-check">
                        <input
                          :class="{
                            'form-check-input': true
                          }"
                          type="checkbox"
                          v-model="chkPass"
                          id="invalidCheck1"
                        />
                        <label
                          :class="{
                            'form-check-label': true
                          }"
                          for="invalidCheck1"
                        >
                          {{ $t("recordar_datos_de_acceso") }}
                        </label>
                        <a href="#" @click="recuperarPass()" style="float: right">{{ $t("olvide_mi_contraseña") }}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2"></div>
              </div>
              <div class="row" v-if="recoverPassword">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label class="color-text"><i class="fas fa-envelope"></i> {{ $t("correo_electrónico") }}</label>
                    <input type="text" class="form-control" v-model="email" />
                  </div>
                </div>
                <div class="col-md-2"></div>
              </div>
              <br />
              <div class="text-center" v-if="!recoverPassword">
                <button type="button" class="btn btn-primary button-customer" v-on:click="ingresar($event)">
                  {{ $t("iniciar_sesion") }}
                </button>
              </div>
              <div class="text-center" v-if="recoverPassword">
                <button type="button" @click="sendRecuperarPassword()" class="btn btn-primary button-customer">
                  {{ $t("enviar_correo") }}
                </button>
              </div>
              <br />
              <div class="text-center" v-if="!recoverPassword">
                <a
                  href="#"
                  style="color: #001f62; font-weight: bold"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#registerModal"
                  class="span-formulario"
                >
                  {{ $t("registrarse") }}
                </a>
              </div>
              <div class="text-center" v-if="recoverPassword">
                <a href="#" style="color: #001f62; font-weight: bold" @click="recuperarPass()">Volver</a>
              </div>
              <div class="text-center" v-if="!recoverPassword">
                <a
                  href="#"
                  style="color: #001f62; font-weight: 300"
                  data-toggle="modal"
                  data-target="#modalTerminosCondiciones"
                  class="span-formulario"
                >
                  {{ $t("terminos_condi") }}
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { essentialsData } from "../mixins/essentialsDataMixin.js";
import { mapGetters } from "vuex";
// import carousel from "vue-owl-carousel";
import * as u from "j899";
import { Base64 } from "js-base64";

export default {
  mixins: [essentialsData],
  components: {},
  name: "FormLogin",
  computed: {
    isLoged() {
      return this.getUser && this.getUser != null && this.getUser != undefined ? true : false;
    },
    ...mapGetters(["getUser", "getScroll", "getUserRec", "getPassRec", "getIsMobilResolution"])
  },
  data() {
    return {
      user: "",
      pass: "",
      recoverPassword: false,
      email: "",
      chkPass: false,
      showPass: false
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    displayType() {
      return this.showPass ? "text" : "password";
    },
    focusInput: function() {
      // verifica si esta creado y renderizado antes de hacer focus
      if (this.$refs.inputUser) {
        this.$refs.inputUser.focus();
      }
    },
    toPage: function(page) {
      this.$emit("changePage", page);
    },
    isLogin() {
      return this.getUser && this.getUser !== null && this.getUser !== undefined ? true : false;
    },
    formatNumeric: function(n) {
      return u.inNumeration("LA", n);
    },
    ingresar: async function() {
      if (this.user == "" || this.pass == "") {
        this.Swal.fire("Lo sentimos", "Pero no puede dejar campos vacios", "error");
      } else {
        // this.$store.commit("showLoader");
        await this.$emit("login", { user: this.user, pass: this.pass, chkPass: this.chkPass });
        $("[data-dismiss=modal]").trigger({ type: "click" });
      }

      if (!this.chkPass) {
        this.user = "";
        this.pass = "";
      }
    },
    recuperarPass() {
      this.recoverPassword = !this.recoverPassword;
    },
    // Inicializa los datos del formulario cuando se crea el componente
    initForm() {
      const username = this.getUserRec;
      const password = this.getPassRec;

      if (username && password) {
        const passDecoded = Base64.decode(password);

        this.user = username;
        this.pass = passDecoded;
      }
    },

    // Obtener cookie
    // getCookie(key) {
    //   // if (document.cookie.length > 0) {
    //   //   let start = document.cookie.indexOf(key + "=");
    //   //   if (start !== -1) {
    //   //     start = start + key.length + 1;
    //   //     let end = document.cookie.indexOf(";", start);
    //   //     if (end === -1) {
    //   //       end = document.cookie.length;
    //   //       return document.cookie.substring(start, end);
    //   //     } else {
    //   //       return Base64.decode(document.cookie.substring(start, end));
    //   //     }
    //   //   }
    //   // }
    //   // return "";
    // },
    async sendRecuperarPassword() {
      if (this.email == "") {
        return this.Swal.fire("Lo sentimos", "Pero debe colocar un email", "error");
      } else {
        this.$store.commit("showLoader");
        let respuesta = await this.$userService.recoveryPass(this.email);
        this.email = "";
        this.$store.commit("hideLoader");
        this.Swal.fire("Atención", respuesta, "info");
        $("[data-dismiss=modal]").trigger({ type: "click" });
        this.toPage("Home");
        // await this.$store.dispatch("createPost", {
        //   url: "api/user/recovery_pass",
        //   data: {
        //     email: this.email
        //   }
        // });
        // this.recoverPassword = false;
        // this.email = "";
      }
    },
    cerrarModal() {
      this.recoverPassword = false;
      this.toPage("Home");
    }
  }
  // mounted() {
  //   //this.chat();
  //   this.initForm();
  // }
};
</script>
<style scoped="scoped">
.container-user {
  background-color: #c1c1c1;
  font-weight: 100;
}
.user.card-title {
  text-align: justify;
  width: 100%;
  padding: 0;
  font-weight: 300;
  font-size: 1.3em;
}
.lbl-user {
  font-weight: 500;
}
.user-card {
  text-align: justify;
}
.card-subtitle {
  text-align: center;
  font-weight: 400;
}
</style>
<style>
.link-color-custom {
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  width: 100%;
}
.color-text {
  color: #001f62;
  font-weight: 400 !important;
}
.button-customer {
  background: #001f62 !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
  width: 300px !important;
}
.input-customer {
  width: 100% !important;
}
.border-redondo {
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
}
.modal-header-customer {
  border-bottom: 1px solid #fff !important;
}

.mlogin {
  width: 500px;
}
</style>
