<template>
  <Ayudas :titulo="this.titulo" :data="this.getPreguntasCMS" />
</template>
<script>
import Ayudas from "../components/Ayudas.vue";
import { mapGetters } from "vuex";
import { essentialsData } from "../mixins/essentialsDataMixin.js";

export default {
  name: "Preguntas",
  mixins: [essentialsData],
  components: { Ayudas },
  computed: {
    ...mapGetters(["getPreguntasCMS"])
  },
  data() {
    return {
      titulo: "",
      id: ""
    };
  },
  methods: {
    async getAyuda() {
      await this.$store.dispatch("getData", {
        url: `/api/v1/getAyuda?pagina_id=${this.id}&perPage=`,
        set: "setPreguntasCMS",
        hander: this.evaluaRespuestaErrorRequestMultiplesCasos,
        handerName: "Preguntas Frecuentes"
      });
    }
  },
  async mounted() {
    const url_string = window.location.href;
    let url = new URL(url_string);
    let titulo = url.searchParams.get("titulo");
    let id = url.searchParams.get("id");
    this.titulo = titulo;
    this.id = id;
    this.getAyuda();
  }
};
</script>
