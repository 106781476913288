<template>
  <div class="container-game">
    <details
      class="details card text-center"
      v-on:click="abrirDetalle(gameDetails.muestraTitulo)"
      :class="{ 'details-light text-dark': this.getThemelight, details: !this.getThemelight }"
    >
      <summary class="my-2" style="color: #ffffff">
        <div
          class="titleGame text-center"
          v-if="gameDetails.muestraTitulo || show"
          :class="{ 'titleGame-light': this.getThemelight }"
        >
          <p>{{ this.gameDetails.gameName }} ({{ this.gameDetails.gameDate }})</p>
        </div>
        <div
          class="row text-center"
          style="font-size: 0.9em"
          :class="{ 'bg-header-light font-weight-bold': this.getThemelight }"
        >
          <div class="col-4 border-right">
            {{ this.gameDetails.gameTime }}
          </div>
          <div class="col-4 border-right">
            <span> {{ this.gameDetails.teams[0].name }} (1) </span>
          </div>
          <div class="col-4">
            <span> {{ this.gameDetails.teams[1].name }} (2) </span>
          </div>
        </div>
      </summary>
      <table width="100%" id="oddsTable" class="mb-1">
        <tbody>
          <tr :id="this.gameDetails.id + 'FT1'" v-if="gameDetails.teams[0]['1X2FT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              1X2 FT <br />
              (A Ganar)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX1X' + this.gameDetails.id"
              @click="clickApuesta('FTX1X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX1X'),
                'btnOdd-light font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["1X2FT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX2X' + this.gameDetails.id"
              @click="clickApuesta('FTX2X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX2X'),
                'btnOdd-light font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["1X2FT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['EMPATE']"
          />
          <tr :id="this.gameDetails.id + 'FT2'" v-if="gameDetails.teams[0]['EMPATE']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              X (FT) <br />Empate
            </td>
            <td
              width="60%"
              class="btnOdd"
              colspan="2"
              :id="'FTX7X' + this.gameDetails.id"
              @click="clickApuesta('FTX7X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX7X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["EMPATE"] }}
            </td>
          </tr>
        </tbody>
      </table>
    </details>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { juegosDetalles } from "../../../../mixins/juegosDetallesMixin.js";
export default {
  name: "WaterpoloMovil",
  data() {
    return {
      verDetalle: false,
      show: false
    };
  },
  mixins: [juegosDetalles],
  props: {
    gameDetails: Object
    // cartId: [],
    // oddId: String
  },
  computed: {
    ...mapGetters(["getThemelight"])
  },
  methods: {
    abrirDetalle(muestraTitulo) {
      if (muestraTitulo) this.show = true;
      this.$emit("label", "details");
    }
  }
};
</script>
