<template>
  <div class="height-taquilla" :class="{ 'bg-light': getThemelight, 'bg-dark': !getThemelight }">
    <Taquilla />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const Taquilla = () => ({
  component: import("../view/taquilla.vue"),
  delay: 200,
  timeout: 3000
});

export default {
  components: {
    Taquilla
  },
  computed: {
    ...mapGetters(["getThemelight"])
  },
  data() {
    return {
      name: "TaquillaLayout"
    };
  }
};
</script>
<style>
.height-taquilla {
  min-height: 750px;
  background-color: #41474e;
}
.bg-light {
  background: linear-gradient(360deg, #cde1f1, #ffffff);
}
</style>
