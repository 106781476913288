<template>
  <div
    :class="{
      'btn-group-vertical sidebar-container': true,
      'light-theme': this.getThemelight
    }"
    role="group"
    aria-label="Vertical button group"
    v-show="!sidebarOpen"
  >
    <button
      type="button"
      class="btn btn-secondary"
      style="text-align: center;font-weight: 500;border-top-right-radius: 0;"
      v-on:click="cerrarMiniMenu"
      v-show="this.getVisualWidth > 1280"
    >
      <i class="fa fa-angle-double-right"></i>
      <!-- <i class="fa fa-toggle-on" aria-hidden="true"></i> -->
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      style="text-align: center;font-weight: 500;border-top-right-radius: 0;"
      v-on:click="cerrarMiniMenu(true)"
      v-show="this.getVisualWidth <= 1280"
    >
      <i class="fa fa-angle-double-right"></i>
    </button>
    <button type="button" class="btn btn-secondary">
      <img
        class="img-steaming"
        src="/img/livestream.webp"
        v-b-tooltip.hover.right="'Streaming'"
        v-b-modal.modal-streaming
      />
    </button>
    <button type="button" class="btn btn-secondary">
      <i
        class="fa fa-ticket"
        aria-hidden="true"
        v-b-tooltip.hover.right="'Ver tickets jugados'"
        v-b-modal.modal-tickets
        @click="filter"
      ></i>
    </button>

    <button
      v-for="league in getOpcionesMenu"
      type="button"
      class="btn btn-secondary"
      @click="activadorSubmenu(league[1].name)"
    >
      <img
        v-b-tooltip.hover.right="`${league[1].name}`"
        :src="`/img/icons/games2/${league[1].icon}`"
        :alt="`icono ${league[1].name}`"
        width="25px"
      />
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SidebarMenuReducido",
  computed: {
    ...mapGetters(["getThemelight", "getVisualWidth", "getSportsLeagues"]),
    getOpcionesMenu() {
      return this.getSportsLeagues ? Object.entries(this.getSportsLeagues) : [];
    }
  },
  props: {
    sidebarOpen: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    cerrarMiniMenu(...args) {
      this.$emit("cerrarMiniMenu", args);
    },
    filter(evt) {
      this.$emit("filter", evt);
    },
    activadorSubmenu(opt) {
      this.$emit("activadorSubmenu", opt);
    }
  }
};
</script>
