var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!this.getIsMobilResolution)?_c('div',{staticClass:"p-0 no-margin text-center pt-2"},[_c('div',{staticClass:"table-responsive mailbox-messages"},[_c('div',{staticClass:"table-balances"},[_c('table',{class:{
          'table table-hover table-striped mb-0': true,
          'table-dark': !this.getThemelight,
          'table-light': this.getThemelight
        }},[_c('thead',[_c('tr',[_c('th',{staticClass:"col-fecha txt-center"},[_vm._v(_vm._s(_vm.$t("fecha")))]),_c('th',{staticClass:"col-asunto txt-center"},[_vm._v(_vm._s(_vm.$t("asunto")))])])])]),_c('div',{staticStyle:{"height":"25.5em","overflow":"auto"}},[_c('table',{class:{ 'table table-hover table-striped': true, 'table-dark': !this.getThemelight },attrs:{"id":"contenido-table-mensajeria"}},[_c('tbody',_vm._l((_vm.getMensajeria.data),function(menssage,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.getMensajeria.data && _vm.getMensajeria.total > 0),expression:"getMensajeria.data && getMensajeria.total > 0"}],key:index,on:{"click":function($event){return _vm.toPage('Mensaje', menssage.id)}}},[_c('td',{class:{
                  'col-fecha': true,
                  msjNoLeido: !menssage.leido
                }},[_vm._v("\n                "+_vm._s(_vm.dateFormat(menssage.fecha))+"\n              ")]),_c('td',{class:{
                  'col-asunto text-left': true,
                  msjNoLeido: !menssage.leido
                }},[_vm._v("\n                "+_vm._s(menssage.asunto)+"\n              ")])])}),0)])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }