import Vue from "vue";
import VueI18n from "vue-i18n";

import es from "./i18n/es";
import enUS from "./i18n/en-US";

Vue.use(VueI18n);

const messages = {
  en: enUS,
  es: es
};

export default new VueI18n({
  locale: "es",
  messages
});
