<template>
  <div id="loader-full" class="container-loader" v-if="cargando">
    <img v-lazy="'/img/logo/sellatuparley.png'" class="animate__animated animate__flash image-loader-logo" />
  </div>
</template>
<script>
export default {
  name: "FullLoader",
  props: {
    cargando: Boolean
  }
};
</script>

<style>
.lds-ellipsis div {
  width: 25px;
  height: 25px;
}

.lds-ellipsis div:nth-child(1) {
  left: 15px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 30px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 60px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 100px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1.2em, 0);
  }
}
</style>

<style scoped="scoped">
div.simpleLoader {
  line-height: 1.5;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: desvanece 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

div.text-center.d-none.simpleLoader.d-block div.lds-ellipsis div,
div.text-center.d-none.simpleLoader.d-block div.lds-ellipsis div:nth-child(1),
div.text-center.d-none.simpleLoader.d-block div.lds-ellipsis div:nth-child(2),
div.text-center.d-none.simpleLoader.d-block div.lds-ellipsis div:nth-child(3),
div.text-center.d-none.simpleLoader.d-block div.lds-ellipsis div:nth-child(4),
#loader-full > div > div > div:nth-child(1) {
  width: 3em !important;
  height: 3em !important;
}

div#loader-full.container-loader {
  width: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  text-align: center;
  align-content: center;
  position: fixed;

  top: 0;
  left: 0;
}

div#loader-full > div.loader {
  align-items: center;
  justify-content: center;
  border-width: 5px;
}

div#loader-full > img {
  /* Centrado de imagen */
  line-height: 1.5;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: desvanece 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes desvanece {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}
</style>
