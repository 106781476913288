<template>
  <div
    id="container-preguntas"
    :class="{
      'soporte-movil': this.getIsMobilResolution,
      'container-preguntas': !this.getIsMobilResolution,
      'light-theme bg-light': this.getThemelight,
      'animate__animated animate__backInUp': true
    }"
  >
    <div class="container">
      <div class="text-center mb-2" v-if="this.getIsMobilResolution">
        <span :class="{ 'text-dark': this.getThemelight, 'title-ayudas': true, 'text-light': !this.getThemelight }">
          {{ titulo }}
        </span>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h1
            v-if="!this.getIsMobilResolution"
            :class="{
              'title-orange-web pt-5': true,
              'mb-4': true,
              letra: true,
              'text-center': true,
              'dark-h1': !this.getThemelight,
              'light-h1': this.getThemelight
            }"
            style="color: white; font-weight: 700"
          >
            {{ titulo }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <AcordionCMS :data="this.data" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AcordionCMS from "../components/AcordionCMS.vue";
export default {
  name: "Ayudas",
  props: ["titulo", "data"],
  components: {
    AcordionCMS
  },
  computed: {
    ...mapGetters(["getIsMobilResolution", "getThemelight"])
  },
  watch: {
    getThemelight(t) {
      if (t && this.getIsMobilResolution) {
        document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
      } else {
        document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
      }
    }
  }
};
</script>
<style>
.container-html p,
#container-preguntas div.text-justify.font5.container-html p {
  color: #fff;
}

#container-preguntas > div > div > div.col-9 > div.light > div > p > strong > span {
  color: #343434 !important;
}

.contenido {
  padding-left: 20px;
  padding-right: 20px;
  background-color: aliceblue;
}

.container-preguntas {
  padding-top: 100px;
  height: auto !important;
  min-height: 650px !important;
}

.soporte-movil {
  padding-top: 4%;
  min-height: 500px !important;
}

.title-ayudas {
  font-size: 1.3em;
  font-weight: 700;
}
</style>
<style scoped>
.title-orange-web {
  font-size: 2em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
  color: rgb(243, 156, 18);
  /*color: #fff;*/
}
.container {
  margin-top: 60px;
}
h1,
.container-html p,
p {
  color: #fff;
}
.container {
  height: auto !important;
}

.accordion-container {
  width: 100% !important;
}
.card-customer {
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
}
</style>
