import { render, staticRenderFns } from "./Hipismo.vue?vue&type=template&id=036cb31b&scoped=true&"
import script from "./Hipismo.vue?vue&type=script&lang=js&"
export * from "./Hipismo.vue?vue&type=script&lang=js&"
import style0 from "./Hipismo.vue?vue&type=style&index=0&id=036cb31b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "036cb31b",
  null
  
)

export default component.exports