<template>
  <div class="text-center animate__animated animate__backInUp top-balance">
    <div class="row align-items-center my-3">
      <div class="col-1">
        <i
          class="fas fa-chevron-left fa-lg text-light"
          :class="{ 'text-dark': this.getThemelight }"
          v-on:click="back()"
        ></i>
      </div>
      <div class="col-10">
        <div class="text-title" :class="{ 'text-light': !getThemelight, 'light-h1': getThemelight }">
          {{ $t("estado_de_cuenta") }}
        </div>
      </div>
    </div>
    <div style="height: 550px; !important; overflow: scroll; padding-bottom: 100px">
      <div class="card" v-for="balance of results" v-bind:key="balance.id">
        <div class="row text-center border bg-title">
          <div class="col-7 border-right">
            <b>{{ $t("fecha") }}</b>
          </div>
          <div class="col-5">
            <b>Ticket</b>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-7">
            {{ dateFormat(balance.fecha) }}
          </div>
          <div class="col-5">
            {{ balance.ticket }}
          </div>
        </div>
        <div class="text-center border bg-title">
          <b>{{ $t("tipo") }}</b>
        </div>
        <div class="row text-center">
          <div class="col">{{ balance.tipo }}</div>
        </div>
        <div class="row text-center border bg-title">
          <div class="col-6">
            <b>{{ $t("monto") }}</b>
          </div>
          <div class="col-6">
            <b>{{ $t("saldo") }}</b>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-6">
            {{ formatoMonto(balance.monto) }}
          </div>
          <div class="col-6">
            {{ formatoMonto(balance.saldo) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      results: [],
      position: 1,
      disablePager: false
    };
  },
  computed: {
    ...mapGetters(["getPage", "getBalaces", "getThemelight"])
  },
  methods: {
    back() {
      //this.$emit("toPage", "Cuenta");
      this.$bvModal.show("modal-cuenta");
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm A");
      }
    },
    async handleScroll() {
      // Any code to be executed when the window is scrolled
      if ($(window).scrollTop() >= $(document).height() - $(window).height() && !this.disablePager) {
        this.position++;
        this.getResults();
        // setTimeout(() => {
        //   this.$store.commit("hideLoader");
        //   this.getBalaces.data.forEach(element => {
        //     this.results.push(element);
        //   });
        // }, 1000);
      }
    },
    async getResults() {
      //this.results = this.getBalaces ? this.getBalaces.data : [];
      await this.$store.dispatch("getBalacesUserAsync", { page: this.position });
      // this.getBalaces.data.forEach((element) => {
      //   this.results.push(element);
      // });
      //this.results = this.getBalaces.data;
      this.getBalaces.data.forEach(element => {
        this.results.push(element);
      });
    }
  },
  async mounted() {
    await this.$store.dispatch("getBalacesUserAsync", { page: this.position });

    if (this.getThemelight) {
      document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
    }
    this.getResults();
  }
};
</script>
<style>
.content {
  margin-top: 35% !important;
  width: 90%;
}

.bg-title {
  background: #e8eaea;
}
</style>

<style scoped>
.top-balance {
  margin-top: 150px !important;
  height: auto;
  min-height: 300px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.title-orange {
  font-size: 1.2em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
}
</style>
