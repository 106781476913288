<template>
  <div
    id="container-bonus"
    :class="{
      'light-theme bg-light': this.getThemelight,
      'animate__animated animate__backInUp': !this.modal,
      'container-bonus': !getIsMobilResolution,
      'modal-form': this.modal
    }"
  >
    <div class="container">
      <div class="row mt-5">
        <div v-if="!isMobilResolution()" class="col-3">
          <!-- <FormLogin2></FormLogin2> -->
        </div>
        <div class="col-12">
          <h1
            v-if="!this.modal"
            :class="{
              'title-orange-web': !getIsMobilResolution,
              'title-ayudas mt-5': getIsMobilResolution,
              'mb-4': true,
              letra: true,
              'text-center': true,
              'dark-h1': !this.getThemelight,
              'light-h1': this.getThemelight
            }"
            style="color: white; font-weight: 700"
          >
            {{ $t("bonos_terminos_y_condiciones") }}
          </h1>
          <h1
            v-if="this.modal"
            :class="{
              'title-orange-web py-3': true,
              'mb-4': true,
              letra: true,
              'text-center': true,
              'light-h1': true
            }"
            style="color: white; font-weight: 700"
          >
            {{ $t("bonos_terminos_y_condiciones") }}
          </h1>
          <div class="text-justify font5 container-html" :class="{ 'mx-2': this.getIsMobilResolution }">
            <AcordionCMS :data="this.getBonosTerminosCondiciones" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JQ from "jquery";
import AcordionCMS from "../components/AcordionCMS.vue";
import { mapGetters } from "vuex";
import { essentialsData } from "../mixins/essentialsDataMixin.js";

export default {
  name: "BonusTermsConditions",
  mixins: [essentialsData],
  props: ["modal"],
  components: { AcordionCMS },
  computed: {
    ...mapGetters([
      "getScroll",
      "getIsMobilResolution",
      "getMenuMobilOpen",
      "getThemelight",
      "getBonosTerminosCondiciones",
      "getParametrosCMS"
    ])
  },
  data() {
    return {};
  },
  methods: {
    isMobilResolution() {
      return this.getIsMobilResolution;
    },
    async getAyuda() {
      await this.$store.dispatch("getData", {
        url: "/api/v1/getAyuda?pagina_id=5&perPage=",
        set: "setBonosTerminosCondiciones",
        hander: this.evaluaRespuestaErrorRequestMultiplesCasos,
        handerName: "Bonus Terminos y Condiciones"
      });
    },
    async getParametros() {
      await this.$store.dispatch("getData", { url: "/api/v1/getParametros", set: "setParametrosCMS" });
    }
  },
  watch: {
    getThemelight(t) {
      if (t && this.getIsMobilResolution) {
        document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
      } else {
        document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
      }
    }
  },
  async mounted() {
    let instancia = this;

    JQ(document.getElementById("container-bonus")).on("scroll", function() {
      Scrolled(instancia);
    });

    this.$store.commit("showLoader");
    await this.$store.dispatch("searchBonusTermsConditions");
    this.getAyuda();
    this.getParametros();
    this.$store.commit("hideLoader");
  }
};

function Scrolled(instancia) {
  var winheight = JQ(document.getElementById("container-bonus")).height();
  var docheight = JQ(document).height();
  var scrollTop = JQ(document.getElementById("container-bonus")).scrollTop();
  var trackLength = docheight - winheight;
  var pctScrolled = Math.floor((scrollTop / trackLength) * 100);

  if (scrollTop > 100) {
    $("#scroll").fadeIn();
  } else {
    $("#scroll").fadeOut();
  }

  if (pctScrolled >= 5) {
    instancia.$emit("barSolid", true);
  } else {
    instancia.$emit("barSolid", false);
  }

  /*
      si no es resolucion mobil actualizar porcentaje 
      para oscurecer barra de menu web
  */
  if (!instancia.isMobilResolution()) {
    instancia.$parent.updateScroll(pctScrolled);
  }
}
</script>
<style>
.container-html p,
#container-bonus div.text-justify.font5.container-html p {
  color: #fff;
}
#container-bonus.light-theme > div > div > div.col-12 > div > p:nth-child(1) > span:nth-child(3) {
  color: #232323 !important;
}

.container-bonus {
  padding-top: 100px;
  height: auto !important;
  min-height: 650px !important;
}
.modal-form {
  min-height: 350px !important;
}

/* @media screen and (min-width: 720px) {
  #container-bonus {
    overflow-y: hidden;
    padding-bottom: 50px;
  }
  .container-bonus {
    padding-top: 100px;
  }
  .animate__animated.animate__backInUp {
    min-height: 1245px;
  }
} */
</style>
<style scoped>
body {
  background-color: #232323;
}
.container {
  margin-top: 60px;
}
h1,
.container-html p,
p {
}

.container-bonus {
  padding-top: 100px;
  min-height: 80vh;
}
/*color: #fff;*/

.shade {
  font-size: 20px;
  background-color: rgba(233, 227, 151, 0.7);
}
.fixed {
  position: sticky;
  bottom: 20%;
  left: 10px;
}

.title-orange-web {
  font-size: 2em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
}
.container {
  height: 100% !important;
}

.accordion-container {
  width: 100% !important;
}
.card-customer {
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
}
.soporte-movil {
  margin-top: 10%;
}
</style>
