<template>
  <!-- <section class="animate__animated animate__backInUp"> -->
  <div class="card mx-2 contenedor" v-if="getUser" id="yey">
    <form>
      <div class="card-body">
        <div v-if="this.monedaExtranjera && !this.getIsMobilResolution">
          <div class="row bg-primary mb-2 text-center">
            <div class="col-1 my-2"></div>
            <div class="col-3 my-2"><b>FECHA</b></div>
            <div class="col-2 my-2"><b>ESTADO</b></div>
            <div class="col-3 my-2"><b>PASARELA</b></div>
            <div class="col-3 my-2"><b>MONTO</b></div>
          </div>
          <!-- style="height: 350px; max-height: 350px; overflow-y: auto" -->
          <div>
            <details v-for="charge in this.getCriptoCharges" :key="charge.index">
              <summary class="my-1" @click="cambiarIcono(charge._id)">
                <div class="row">
                  <div class="col-1 text-center">
                    <i class="fas fa-plus text-primary" :id="'list' + charge._id"></i>
                  </div>
                  <div class="col-3 text-center">
                    {{ dateFormat(charge.created_at) }}
                  </div>
                  <div class="col-2 text-center">
                    <div v-if="charge.status == 0"><b>Pendiente</b></div>
                    <div v-if="charge.status == 1"><b>Aprobado</b></div>
                    <div v-if="charge.status == 2"><b>Acreditado</b></div>
                    <div v-if="charge.status == 3"><b>Completado</b></div>
                    <div v-if="charge.status == 4"><b>Rechazado</b></div>
                  </div>
                  <div class="col-3 text-center">
                    {{ charge.gateway }}
                  </div>
                  <div class="col-3 text-center">{{ getSimboloMoneda }}{{ charge.amount }}</div>
                </div>
              </summary>
              <div class="row">
                <div class="col-12 text-center" v-if="charge.name"><b>Cuenta:</b> {{ charge.name }}</div>
                <div class="col-12 text-center" v-if="charge.ref"><b>Referencia:</b> {{ charge.ref }}</div>
              </div>
            </details>
          </div>
        </div>

        <div
          class="callout callout-danger mt-3 bg-light"
          v-if="this.contenido && this.contenido[0] && this.contenido[0].contenido"
        >
          <!-- <div v-if="this.getRetiroCMS && this.getRetiroCMS[0]" v-html="this.getRetiroCMS[0].contenido"></div> -->
          <div v-html="this.contenido[0].contenido"></div>
        </div>
        <div class="mb-4 mt-4 text-center">
          <button
            v-show="!this.registroBancario"
            type="button"
            class="btn btn-primary btn-lg btn-round"
            v-on:click="addAccountBank"
          >
            <i class="fa fa-plus mr-2"></i>
            Agregar cuenta bancaria
          </button>

          <button
            v-show="this.registroBancario"
            type="button"
            class="btn btn-danger btn-lg btn-round float-right btn-exit-custom"
            v-on:click="addAccountBank"
          >
            <i class="fas fa-times"></i>
          </button>

          <div class="callout pt-2 pb-2" v-show="this.registroBancario" style="height: auto !important">
            <div class="row">
              <div class="col-12">
                <h4>REGISTRO DE CUENTA BANCARIA</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6" :class="{ 'my-2': this.getIsMobilResolution }" v-if="this.monedaExtranjera">
                <v-select
                  class="style-chooser"
                  placeholder="Seleccione la pasarela"
                  :options="getCriptoGateways"
                  style="width: 100%; color: black"
                  :reduce="gateway => gateway.id"
                  v-model="gatewayId"
                  label="name"
                  :searchable="false"
                >
                </v-select>
              </div>
              <div class="col-6" v-if="!this.monedaExtranjera && getCenterBanks">
                <v-select
                  class="style-chooser"
                  placeholder="Seleccione el Banco"
                  :options="getCenterBanks"
                  style="width: 100%; color: black; z-index: 3"
                  :reduce="center => center.id"
                  v-model="bankId"
                  label="nombre"
                  :searchable="false"
                >
                </v-select>
              </div>
              <div class="col-6" v-if="!this.monedaExtranjera">
                <v-select
                  class="style-chooser"
                  placeholder="Seleccione el tipo de cuenta"
                  :options="tipoCuentas"
                  style="width: 100%; color: black"
                  :reduce="tipo => tipo.id"
                  v-model="tipoAccountId"
                  label="tipo"
                  :searchable="false"
                >
                </v-select>
              </div>
              <div class="col-12 col-md-6" v-else>
                <input type="text" class="form-control" placeholder="Cuenta" v-model="accountCripto" />
              </div>
            </div>
            <div class="row" v-if="!this.monedaExtranjera">
              <div class="col-12 pt-2 pb-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Numero de cuenta"
                  v-model="accountNumber"
                  maxlength="20"
                  v-on:keypress="isNumber($event)"
                />
              </div>
            </div>
            <div class="row pb-2 pt-2">
              <div class="col-sm-8 col-lg-12">
                <button type="button" class="btn btn-primary boton-color-fondo btn-bank" v-on:click="saveAccountBank">
                  <i class="fa fa-save mr-2"></i>
                  Guardar cuenta bancaria
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-sm-8 col-lg-6" v-if="!this.monedaExtranjera">
            <div class="form-group">
              <label for="tipoTransaccion">Tipo de transacción</label>
              <v-select
                class="style-chooser"
                placeholder="Seleccione..."
                :options="operations"
                style="width: 100%; color: black"
                :reduce="operation => operation.type"
                v-model="chargeOperationId"
                label="label"
                :searchable="false"
                @change="cambioTipoTransaaccion"
              >
              </v-select>
            </div>
          </div>
          <div class="col-sm-8 col-lg-6" v-else>
            <div class="form-group">
              <label for="tipoTransaccion">Pasarela</label>
              <v-select
                v-if="getCriptoAccounts"
                class="style-chooser"
                placeholder="Seleccione pasarela"
                :options="getCriptoAccountsFiltered"
                style="width: 100%; color: black"
                :reduce="criptoAccount => criptoAccount.gateway"
                v-model="criptoAccountClient"
                label="gateway"
                :searchable="false"
              >
                <template #option="{ gateway, account }">
                  <h6 style="margin: 0">{{ gateway }}</h6>
                  <h6>{{ account }}</h6>
                </template>
              </v-select>
            </div>
          </div>
          <div class="col-3"></div>
        </div>
        <div class="row" v-if="!this.monedaExtranjera">
          <div class="col-3"></div>
          <div class="col-sm-8 col-lg-6">
            <div class="form-group">
              <label for="bancoDestino">Seleccione su cuenta bancaria</label>
              <v-select
                v-if="getAccountsBancos"
                class="style-chooser"
                placeholder="Seleccione..."
                :options="getAccountsBancos"
                style="width: 100%; color: black"
                :reduce="account => account.id"
                v-model="clientAccountId"
                label="banco"
                :searchable="false"
              >
                <template #option="{ banco, account }">
                  <h6 style="margin: 0">{{ banco }}</h6>
                  <h6>{{ account }}</h6>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-sm-8 col-lg-6">
            <div class="form-group">
              <label for="monto">Monto a retirar</label>
              <input type="number" class="form-control rounded-0" id="monto" v-model="monto" placeholder="0.00" />
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="text-center contenedor">
      <button
        type="submit"
        class="btn btn-primary boton-color-fondo mb-4"
        style="width: 50% !important"
        @click="validarForm()"
      >
        REGISTRAR
      </button>
    </div>
  </div>
  <!-- </section>  -->
</template>

<script>
import { essentialsData } from "../mixins/essentialsDataMixin.js";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import moment from "moment";
import "vue-select/dist/vue-select.css";

export default {
  mixins: [essentialsData],
  data() {
    return {
      chargeOperationId: null,
      clientAccountId: null,
      accountCripto: null,
      criptoAccountClient: null,
      registroBancario: false,
      bankId: null,
      gatewayId: null,
      tipoAccountId: null,
      accountNumber: null,
      accountDataRegister: null,
      tipoCuentas: [
        // { tipo: "seleccione", id: 0 },
        { tipo: "corriente", id: 1 },
        { tipo: "ahorro", id: 2 }
      ],
      monto: "",
      operations: [{ type: "1", label: "Transferencia" }],
      monedaExtranjera: false,
      contenido: null
    };
  },
  watch: {
    async chargeOperationId() {
      if (!this.getCenterBanks || !this.getCenterAccounts) {
        await this.$store.dispatch("getCenterAccountsBanksAsync");
      }
    }
  },
  computed: {
    ...mapGetters([
      "getAccounts",
      "getUser",
      "getCenterBanks",
      "getAccountsBancos",
      "getRetiroCMS",
      "getIsMobilResolution",
      "getCuentasBancosCMS",
      "getRecargaCMS",
      "getRecargaPagoMovilCMS",
      "getCenterAccounts",
      "getCriptoCharges",
      "getCriptoAccounts",
      "getCriptoGateways",
      "getSimboloMoneda"
    ]),
    getCriptoAccountsFiltered() {
      return this.getCriptoAccounts.filter(item => item.enable == "1");
    }
  },
  components: {
    vSelect
  },
  methods: {
    cambiarIcono(chargeId) {
      $("#list" + chargeId)
        .toggleClass("fa-minus")
        .toggleClass("fa-plus");
    },
    dateFormat(value) {
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY HH:mm:ss A");
      }
    },
    async cambioTipoTransaaccion() {
      console.log("cambioTipoTransaaccion", this.chargeOperationId);
      if (!this.getCenterBanks || !this.getCenterAccounts) {
        await this.$store.dispatch("getCenterAccountsBanksAsync");
      }
    },
    addAccountBank: async function() {
      $("html, #yey").animate({ scrollTop: 500 }, 500);
      this.registroBancario = !this.registroBancario;
    },
    saveAccountBank: async function() {
      if (this.monedaExtranjera) {
        if (!this.gatewayId || this.gatewayId == "") {
          this.Swal.fire("Lo sentimos", "No puede dejar número de cuenta vacio", "error");
          return;
        }
        if (!this.accountCripto || this.accountCripto == "") {
          this.Swal.fire("Lo sentimos", "No puede dejar la cuenta vacia", "error");
          return;
        }
      } else {
        if (!this.bankId || this.bankId == "") {
          this.Swal.fire("Lo sentimos", "No puede dejar el banco sin seleccionar", "error");
          return;
        }

        if (!this.tipoAccountId || this.tipoAccountId == "") {
          this.Swal.fire("Lo sentimos", "No puede dejar el tipo de cuenta sin seleccionar", "error");
          return;
        }
      }

      //this.$store.commit("showLoader");

      let reg = "";
      let texto = "Su cuenta ha sido registrada!!!";

      if (this.monedaExtranjera) {
        this.accountDataRegister = {
          gateway: this.gatewayId,
          account: this.accountCripto,
          name: this.getUser.name
          //account: this.accountNumber,
        };

        reg = await this.$userService.registerUserCriptoAccount(this.accountDataRegister);

        if (reg.account) {
          this.Swal.fire("Excelente!", texto, "success");
        } else {
          this.Swal.fire("Lo sentimos ha ocurrido un Error", reg.error, "error");
        }
        this.$store.commit("hideLoader");
      } else {
        this.accountDataRegister = {
          type_account_id: this.tipoAccountId,
          typeAccountId: this.tipoAccountId,
          name: this.getUser.name,
          account: this.accountNumber,
          bankId: this.bankId
        };

        reg = await this.$userService.registerUserBankAccount(this.accountDataRegister);

        if (reg.error && reg.error == "ok" && reg.result) {
          this.accountNumber = null;
          this.tipoAccountId = null;
          this.bankId = null;
          this.accountDataRegister = null;
          this.refreshData();

          this.Swal.fire("Excelente!", texto, "success").then(async result => {
            if (result.value) {
              await this.$store.dispatch("searchUserAccountData");
              this.registroBancario = false;
            }
          });
        } else {
          this.Swal.fire("Lo sentimos ha ocurrido un Error", reg.error, "error");
        }
      }
    },
    toPage: function(page) {
      this.$emit("activar", page);
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validarForm() {
      if (!this.monedaExtranjera) {
        if (!this.chargeOperationId || this.chargeOperationId == 0) {
          this.Swal.fire("Por favor", "Seleccione un tipo de transacción", "error");
          return false;
        }
        if (!this.clientAccountId || this.clientAccountId == 0) {
          this.Swal.fire("Por favor", "Seleccione su cuenta bancaria", "error");
          return false;
        }
        if (this.monto == 0) {
          this.Swal.fire("Por favor", "Debe incluir monto a retirar", "error");
          return false;
        }

        this.registrarRetiro();
      } else {
        if (!this.criptoAccountClient) {
          this.Swal.fire("Por favor", "Seleccione pasarela", "error");
          return false;
        }

        if (this.monto == 0) {
          this.Swal.fire("Por favor", "Debe incluir monto a retirar", "error");
          return false;
        }

        this.registrarRetiroCripto();
      }
    },
    async registrarRetiro() {
      const withdrawData = {
        chargeOperationId: this.chargeOperationId,
        clientAccountId: this.clientAccountId,
        monto: this.monto
      };

      let texto = "";

      if ([5, 7, 8].includes(this.getUser.center_id)) {
        texto = "<p class='text-justify'>Retiro registrado exitosamente.</p>";
      } else {
        texto =
          "<p class='text-justify'>Si los datos de su solicitud estan conformes según nuestros registros, su retiro lo verá reflejado en su cuenta bancaria en un lapso aproximado de 24 horas hábiles bancarios (No cuentan sábados, domingos, días feriados bancarios), los retiros solicitados a banesco se procesan con mas prontitud. </p>";
      }

      this.$store.commit("showLoader");
      const reg = await this.$userService.registerWithdraw(withdrawData);
      this.$store.commit("hideLoader");

      if (reg.error && reg.error !== "ok") {
        this.Swal.fire("Lo sentimos", reg.error, "error");
      }

      if (reg.error && reg.error == "ok" && reg.result) {
        this.chargeOperationId = null;
        this.clientAccountId = null;
        this.monto = null;

        this.refreshData();

        await this.$store.dispatch("getChargesUserAsync");

        this.Swal.fire("Excelente!", texto, "success");

        this.$emit("changePage", "PaymentsTable");
      }
    },
    async registrarRetiroCripto() {
      const withdrawData = {
        gateway: this.criptoAccountClient,
        amount: this.monto
      };

      const texto = "<p class='text-justify'>Retiro registrado exitosamente.</p>";

      this.$store.commit("showLoader");
      const reg = await this.$userService.registerWithdrawCripto(withdrawData);
      this.$store.commit("hideLoader");

      if (reg.error) {
        this.Swal.fire("Lo sentimos", reg.error, "error");
      } else {
        this.Swal.fire("Excelente!", texto, "success");
      }
    }
  },
  async mounted() {
    this.$emit("barSolid", true);

    if (this.getUser.center_id == 6) {
      this.monedaExtranjera = true;

      await this.$store.dispatch("getCriptoAsync", {
        url: "api/crypto?charges",
        set: "setCriptoCharges",
        name: "charges"
      });
    }

    await this.$store.dispatch("getCriptoAsync", {
      url: "api/crypto?accounts",
      set: "setCriptoAccounts",
      name: "accounts"
    });

    if (this.getIsMobilResolution) await this.$store.dispatch("searchUserAccountData");

    if (!this.getRetiroCMS) {
      await this.$store.dispatch("getData", {
        url: "/api/v1/getNotas?modulo=2",
        set: "setRetiroCMS"
      });
    }

    if (this.getUser.Coin.id == 1) {
      this.contenido = this.getRetiroCMS.filter(element => element.moneda == "VES");
    } else {
      this.contenido = this.getRetiroCMS.filter(element => element.moneda == "USD");
    }

    if (!this.getCuentasBancosCMS) {
      await this.$store.dispatch("getData", {
        url: "/api/v1/getCuentaBanco",
        set: "setCuentasBancosCMS"
      });
    }

    if (!this.getCenterBanks) {
      await this.$store.dispatch("getCenterAccountsBanksAsync");
    }

    if (!this.getRecargaCMS) {
      await this.$store.dispatch("getData", {
        url: "/api/v1/getNotas?modulo=1",
        set: "setRecargaCMS"
      });
    }

    if (!this.getRecargaPagoMovilCMS) {
      await this.$store.dispatch("getData", {
        url: "/api/v1/getNotas?modulo=3",
        set: "setRecargaPagoMovilCMS"
      });
    }

    if (!this.getRecargaPagoMovilCMS) {
      await this.$store.dispatch("getData", {
        url: "/api/v1/getNotas?modulo=3",
        set: "setRecargaPagoMovilCMS"
      });
    }
  }
};
</script>
<style>
.btn-exit-custom {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  align-self: center;
  padding: 0;
}
.btn {
  font-size: 1em;
}
@media screen and (max-width: 320px) {
  .btn {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 321px) and (max-width: 480px) {
  .select-bank {
    font-size: 0.8em;
  }
}

/* .style-chooser {
  z-index: 1 !important;
} */

.style-chooser .vs__dropdown-toggle {
  border: 1px solid #d6dbdb;
}
.style-chooser .vs__dropdown-menu {
  background: white;
  color: gray;
  text-transform: lowercase;
  font-variant: small-caps;
  z-index: 1 !important;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: black;
}

.style-chooser .vs__dropdown-option {
  color: black !important;
  font-weight: 800;
}

.style-chooser .vs__dropdown-option--highlight {
  background-color: #e6eaea;
}
</style>

<style scoped>
.contenedor {
  margin-bottom: 80px;
}
.btn-bank {
  width: 30% !important;
}
@media (max-width: 480px) {
  .btn-bank {
    width: 100% !important;
  }
}
</style>
