<template>
  <div
    class="modal fade"
    id="modalBonosTerminosCondiciones"
    tabindex="-1"
    aria-labelledby="modalBonosTerminosCondiciones"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content border-redondo">
        <div class="modal-header modal-header-customer">
          <!-- <h5 class="modal-title" id="modalTerminosLabel">Modal title</h5> -->
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          :class="{
            'modal-top': !this.getIsMobilResolution,
            'modal-top-movil': this.getIsMobilResolution
          }"
        >
          <BonusTermsConditions :modal="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BonusTermsConditions from "../BonusTermsConditions.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    BonusTermsConditions
  },
  computed: {
    ...mapGetters(["getIsMobilResolution"])
  }
};
</script>

<style></style>

<style scoped>
.border-redondo {
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
}
.modal-top {
  margin-top: -150px;
}
.modal-top-movil {
  margin-top: -80px;
}
</style>
