<template>
  <ul class="navbar-nav" @click="validar">
    <li class="nav-item dropdown">
      <a
        class="nav-link"
        href="#"
        id="NotificacionesDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style="text-decoration: none"
      >
        <i v-if="this.getCampana" style="font-size: 24px; color: white" class="fas fa-solid fa-bell"></i>
        <i v-else style="font-size: 24px; color: #ffac4f" class="fas fa-solid fa-bell"></i>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right animate__animated animate__fadeIn notificaciones-item"
        aria-labelledby="NotificacionesDropdown"
      >
        <!-- <h4 class="ml-2">Notificaciones</h4> -->
        <!-- <hr> -->
        <div style="overflow-y: auto; max-height: 500px">
          <div v-for="n in this.getNotificaciones" :key="n.id">
            <NotificacionesItem :titulo="n.titulo" :obj="n" />
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapGetters } from "vuex";
import NotificacionesItem from "../components/NotificacionesItem.vue";

export default {
  components: {
    NotificacionesItem
  },
  computed: {
    ...mapGetters(["getNotificaciones", "getCampana"])
  },
  async mounted() {
    await this.$store.dispatch("getMensajeCRON");
    if (this.getNotificaciones.filter(x => x.leido == false).length == 0) {
      this.$store.commit("setCampana", true);
    }
  },
  methods: {
    validar() {
      this.$store.dispatch("checkSession");
    }
  }
};
</script>

<style>
.notificaciones-item {
  filter: opacity(90%);
}
</style>
