<template>
  <div :class="{ 'contenedor-grid': true, 'light-theme': this.getThemelight }">
    <div class="Descriptions">
      <div class="date">{{ this.formatoFecha(this.gameDetails.time) }}</div>
      <div class="Team1">{{ this.gameDetails.teams[0].name }}</div>
      <div class="Team2">{{ this.gameDetails.teams[1].name }}</div>
      <div
        class="empate-title"
        v-if="isNotEmpty(gameDetails.teams[0]['EMPATE']) || isNotEmpty(gameDetails.teams[1]['EMPATE'])"
      >
        Empate
      </div>
    </div>
    <div class="Jugadas">
      <!--  1X2 FT  HDP FT  TOTALES FT  1X2 HT  HDP HT  TOTALES HT -->
      <div class="jugada" v-if="isNotEmpty(gameDetails.teams[0]['1X2FT'])">
        <span>1X2 FT</span>
        <div
          :class="{
            team1Value: true,
            'update-bg-2': fueActualizada('FTX1X'),
            'apuesta-seleccionada': existeApuesta('FTX1X')
          }"
          v-on:click="clickApuesta('FTX1X', gameDetails.teams[0]['1X2FT'])"
        >
          {{ this.gameDetails.teams[0]["1X2FT"] }}
        </div>
        <div
          :class="{
            team2Value: true,
            'update-bg-2': fueActualizada('FTX2X'),
            'apuesta-seleccionada': existeApuesta('FTX2X')
          }"
          v-on:click="clickApuesta('FTX2X', gameDetails.teams[1]['1X2FT'])"
        >
          {{ this.gameDetails.teams[1]["1X2FT"] }}
        </div>
        <div
          v-if="isNotEmpty(gameDetails.teams[1]['EMPATE'])"
          :class="{
            team1Value: true,
            'update-bg-2': fueActualizada('FTX7X'),
            'apuesta-seleccionada': existeApuesta('FTX7X')
          }"
          v-on:click="clickApuesta('FTX7X', gameDetails.teams[1]['EMPATE'])"
        >
          {{ this.gameDetails.teams[1]["EMPATE"] }}
        </div>

        <!-- condicional para rugbi-->
        <div
          v-if="!isNotEmpty(gameDetails.teams[1]['EMPATE']) && isNotEmpty(gameDetails.teams[0]['EMPATE'])"
          :class="{
            team1Value: true,
            'update-bg-2': fueActualizada('FTX7X'),
            'apuesta-seleccionada': existeApuesta('FTX7X')
          }"
          v-on:click="clickApuesta('FTX7X', gameDetails.teams[0]['EMPATE'])"
        >
          {{ this.gameDetails.teams[0]["EMPATE"] }}
        </div>
      </div>
      <div class="jugada" v-if="isNotEmpty(gameDetails.teams[0]['HDPFT'])">
        <span> HDP FT </span>
        <div
          :class="{
            team1Value: true,
            'update-bg-2': fueActualizada('FTX3X'),
            'apuesta-seleccionada': existeApuesta('FTX3X')
          }"
          v-on:click="clickApuesta('FTX3X', gameDetails.teams[0]['HDPFT'])"
        >
          {{ this.gameDetails.teams[0]["HDPFT"] }}
        </div>
        <div
          :class="{
            team2Value: true,
            'update-bg-2': fueActualizada('FTX4X'),
            'apuesta-seleccionada': existeApuesta('FTX4X')
          }"
          v-on:click="clickApuesta('FTX4X', gameDetails.teams[1]['HDPFT'])"
        >
          {{ this.gameDetails.teams[1]["HDPFT"] }}
        </div>
        <div
          v-if="isNotEmpty(gameDetails.teams[0]['EMPATE'])"
          :class="{
            team2Value: true,
            'update-bg-2': fueActualizada('FTX115X'),
            'apuesta-seleccionada': existeApuesta('FTX115X')
          }"
          v-on:click="clickApuesta('FTX115X', gameDetails.teams[0]['EMPATE'])"
        >
          {{ this.gameDetails.teams[0]["EMPATE"] }}
        </div>
      </div>
      <div class="jugada" v-if="isNotEmpty(gameDetails.teams[0]['TOTALESFT'])">
        <span> TOTALES FT </span>
        <div
          :class="{
            team1Value: true,
            'update-bg-2': fueActualizada('FTX5X'),
            'apuesta-seleccionada': existeApuesta('FTX5X')
          }"
          v-on:click="clickApuesta('FTX5X', gameDetails.teams[0]['TOTALESFT'])"
        >
          {{ this.gameDetails.teams[0]["TOTALESFT"] }}
        </div>
        <div
          :class="{
            team2Value: true,
            'update-bg-2': fueActualizada('FTX6X'),
            'apuesta-seleccionada': existeApuesta('FTX6X')
          }"
          v-on:click="clickApuesta('FTX6X', gameDetails.teams[1]['TOTALESFT'])"
        >
          {{ this.gameDetails.teams[1]["TOTALESFT"] }}
        </div>
        <div v-if="isNotEmpty(gameDetails.teams[0]['EMPATE']) || isNotEmpty(gameDetails.teams[1]['EMPATE'])"></div>
      </div>
      <div class="jugada" v-if="isNotEmpty(gameDetails.teams[0]['1X2HT'])">
        <span>1X2 HT</span>
        <div
          :class="{
            team1Value: true,
            'update-bg-2': fueActualizada('HTX1X'),
            'apuesta-seleccionada': existeApuesta('HTX1X')
          }"
          v-on:click="clickApuesta('HTX1X', gameDetails.teams[0]['1X2HT'])"
        >
          {{ this.gameDetails.teams[0]["1X2HT"] }}
        </div>
        <div
          :class="{
            team2Value: true,
            'update-bg-2': fueActualizada('HTX2X'),
            'apuesta-seleccionada': existeApuesta('HTX2X')
          }"
          v-on:click="clickApuesta('HTX2X', gameDetails.teams[1]['1X2HT'])"
        >
          {{ this.gameDetails.teams[1]["1X2HT"] }}
        </div>
      </div>
      <div class="jugada" v-if="isNotEmpty(gameDetails.teams[0]['HDPHT'])">
        <span> HDP HT </span>
        <div
          :class="{
            team1Value: true,
            'update-bg-2': fueActualizada('HTX3X'),
            'apuesta-seleccionada': existeApuesta('HTX3X')
          }"
          v-on:click="clickApuesta('HTX3X', gameDetails.teams[0]['HDPHT'])"
        >
          {{ this.gameDetails.teams[0]["HDPHT"] }}
        </div>
        <div
          :class="{
            team2Value: true,
            'update-bg-2': fueActualizada('HTX4X'),
            'apuesta-seleccionada': existeApuesta('HTX4X')
          }"
          v-on:click="clickApuesta('HTX4X', gameDetails.teams[1]['HDPHT'])"
        >
          {{ this.gameDetails.teams[1]["HDPHT"] }}
        </div>
      </div>

      <div class="jugada" v-if="isNotEmpty(gameDetails.teams[0]['TOTALESHT'])">
        <span> TOTALES HT </span>
        <div
          :class="{
            team1Value: true,
            'update-bg-2': fueActualizada('HTX5X'),
            'apuesta-seleccionada': existeApuesta('HTX5X')
          }"
          v-on:click="clickApuesta('HTX5X', gameDetails.teams[0]['TOTALESHT'])"
        >
          {{ gameDetails.teams[0]["TOTALESHT"] }}
        </div>
        <div
          :class="{
            team2Value: true,
            'update-bg-2': fueActualizada('HTX6X'),
            'apuesta-seleccionada': existeApuesta('HTX6X')
          }"
          v-on:click="clickApuesta('HTX6X', gameDetails.teams[1]['TOTALESHT'])"
        >
          {{ gameDetails.teams[1]["TOTALESHT"] }}
        </div>
      </div>

      <!-- DOBLE CHANCE -->
      <div class="jugada-ambos-anotan" v-if="isNotEmpty(gameDetails.teams[0]['SI'])">
        <span> DOBLE CHANCE FT </span>
        <div class="continer-doble-chance" style="background-color:transparent!important;">
          <div style="background-color:transparent!important;">
            <div class="tilte-lbl-ambos-anotan">X1</div>
            <div
              :class="{
                'team2Value value-ambos-anotan': true,
                'update-bg-2': fueActualizada('FTX62X'),
                'apuesta-seleccionada': existeApuesta('FTX62X')
              }"
              v-on:click="clickApuesta('FTX62X', gameDetails.teams[0]['1X'])"
            >
              {{ gameDetails.teams[0]["1X"] }}
            </div>
          </div>
          <div style="background-color:transparent!important;">
            <div class="tilte-lbl-ambos-anotan">12</div>
            <div
              :class="{
                'team2Value value-ambos-anotan': true,
                'update-bg-2': fueActualizada('FTX63X'),
                'apuesta-seleccionada': existeApuesta('FTX63X')
              }"
              v-on:click="clickApuesta('FTX63X', gameDetails.teams[0]['12'])"
            >
              {{ gameDetails.teams[0]["12"] }}
            </div>
          </div>

          <div style="background-color:transparent!important;">
            <div class="tilte-lbl-ambos-anotan">X3</div>
            <div
              :class="{
                'team2Value value-ambos-anotan': true,
                'update-bg-2': fueActualizada('FTX64X'),
                'apuesta-seleccionada': existeApuesta('FTX64X')
              }"
              v-on:click="clickApuesta('FTX64X', gameDetails.teams[0]['X2'])"
            >
              {{ gameDetails.teams[0]["X2"] }}
            </div>
          </div>
        </div>
        <div class="no-bg-hover" v-if="isNotEmpty(gameDetails.teams[0]['EMPATE'])"></div>
      </div>

      <!-- AMBOS ANOTAN FT -->
      <div class="jugada-ambos-anotan" v-if="isNotEmpty(gameDetails.teams[0]['SI'])">
        <span> AMBOS ANOTAN FT </span>
        <div class="continer-ambos-anotan" style="background-color:transparent!important;">
          <div style="background-color:transparent!important;">
            <div class="tilte-lbl-ambos-anotan">SI(GG)</div>
            <div
              :class="{
                'team2Value value-ambos-anotan': true,
                'update-bg-2': fueActualizada('FTX49X'),
                'apuesta-seleccionada': existeApuesta('FTX49X')
              }"
              v-on:click="clickApuesta('FTX49X', gameDetails.teams[0]['SI'])"
            >
              {{ this.gameDetails.teams[0]["SI"] }}
            </div>
          </div>
          <div style="background-color:transparent!important;">
            <div class="tilte-lbl-ambos-anotan">NO(NG)</div>
            <div
              :class="{
                'team2Value value-ambos-anotan': true,
                'update-bg-2': fueActualizada('FTX50X'),
                'apuesta-seleccionada': existeApuesta('FTX50X')
              }"
              v-on:click="clickApuesta('FTX50X', gameDetails.teams[0]['NO'])"
            >
              {{ this.gameDetails.teams[0]["NO"] }}
            </div>
          </div>
        </div>
        <div class="no-bg-hover" v-if="isNotEmpty(gameDetails.teams[0]['EMPATE'])"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { juegosDetalles } from "../../../mixins/juegosDetallesMixin.js";
import { mapGetters } from "vuex";
export default {
  name: "gridNoTable",
  computed: {
    ...mapGetters(["getApuestas", "getFormatoTaquilla", "getThemelight"])
  },
  mixins: [juegosDetalles],
  props: {
    gameDetails: Object
  },
  methods: {
    isNotEmpty(v) {
      return v && v != undefined && v.toString().trim() != "";
    }
  }
};
</script>
<style scope>
.value-ambos-anotan {
  cursor: pointer;
}
.tilte-lbl-ambos-anotan {
  color: #fff;
  margin: 0;
}

.tilte-lbl-ambos-anotan:hover {
  /* color: #fff !important;
  text-shadow: none !important; */
}

.continer-ambos-anotan {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  grid-gap: 0px;
  min-height: 48px !important;
}

.continer-doble-chance {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  grid-gap: 0px;
  min-height: 48px !important;
}

.update-bg-2 {
  background-color: #546517 !important;
}

.contenedor-grid {
  display: grid;
  grid-template-columns: 1fr 0fr 4fr auto;
  grid-template-rows: 1fr;
  gap: 0px;
  padding: 0.5em 0;
  grid-auto-flow: row;
  grid-template-areas:
    "Title Title Title Title"
    "Descriptions Descriptions Jugadas Jugadas"
    "Descriptions Descriptions Jugadas Jugadas";
}

.title-league {
  grid-area: Title;
  text-align: center;
}

.Descriptions {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "date"
    "Team1"
    "Team2";
  grid-area: Descriptions;
  min-width: 170px;
}

/* Fondo */
.Descriptions,
.Jugadas {
  background-color: #32383e;
}

/*background: #007bff linear-gradient(180deg, #268ffd, #006ee2, #0f376e) repeat-x !important;*/
div.contenedor-grid.light-theme > div.Descriptions,
div.contenedor-grid.light-theme > div.Jugadas {
  background: var(--bg-cristal);
  color: var(--color-font1);
  font-family: var(--font1);
}

.date {
  grid-area: date;
}

.Team1 {
  grid-area: Team1;
}

.Team2 {
  grid-area: Team2;
}

.Jugadas {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  grid-template-rows: 1fr;
  grid-auto-rows: auto;
  gap: 0px;
  grid-auto-flow: row;
  grid-area: Jugadas;
}

.Descriptions .date {
  text-align: left;
  padding-left: 1em;
  font-size: 11px;
  height: 24px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
/*para light*/
div.contenedor-grid.light-theme > div.Descriptions .date {
  background-color: var(--color-main-menu);
  color: var(--color-txt-light);
}

.Descriptions .Team1,
.Descriptions .Team2,
.Descriptions .empate-title {
  text-align: left;
  padding: 0px 1em !important;
  font-size: 12px;
  min-height: 22px;
}

.Jugadas .jugada {
  display: grid;
  font-size: 12px;
}

.jugada span {
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  align-self: center;
  height: 24px;
  display: flex;
}

/*para light*/
div.contenedor-grid.light-theme > div.Jugadas .jugada span,
div.contenedor-grid.light-theme > div.Jugadas .jugada-ambos-anotan span,
div.contenedor-grid.light-theme > div.Jugadas .jugada-ambos-anotan div.tilte-lbl-ambos-anotan {
  background-color: var(--color-main-menu);
  color: var(--color-txt-light);
}

div.contenedor-grid.light-theme > div.Jugadas .team1Value.value-ambos-anotan,
div.contenedor-grid.light-theme > div.Jugadas .team2Value.value-ambos-anotan {
  color: var(--color-font1);
}

.jugada div {
  text-align: center;
  font-family: sans-serif;
  padding: 3px 0px;
  cursor: pointer;
  transition: all 0.25s;
  min-height: 25px;
}

.jugada div:nth-child(2n) {
  color: azure;
  text-shadow: 1px 1px 2px black;
  font-family: sans-serif;
  font-weight: 400;
}

.jugada div:nth-child(3n) {
  color: azure;
  text-shadow: 1px 1px 2px black;
  font-family: sans-serif;
  font-weight: 400;
}

.jugada div:hover {
  background-color: #f4f4f4;
  color: #343a40;
  text-shadow: 1px 1px 1px #fff;
}

/* size titles */
.jugada-ambos-anotan span,
.jugada-ambos-anotan div,
.team2Value {
  font-size: 12px;
}

/*.tilte-lbl-ambos-anotan:hover,*/
/* sin hover estos elementos */

div.continer-ambos-anotan:hover,
div.continer-ambos-anotan div.team1Value:hover,
div.continer-ambos-anotan div.team2Value:hover,
.continer-ambos-anotan > div:hover,
.continer-ambos-anotan div:nth-child(1):hover,
.continer-ambos-anotan div:nth-child(2):hover,
.no-bg-hover,
div.no-bg-hover {
  background-color: transparent !important;
}

div.jugada-ambos-anotan {
  background-color: transparent !important;
  color: #fff;
}

div.jugada-ambos-anotan > span {
  width: 100%;
  color: #fff;
  display: block;
  text-align: center;
}
</style>
