<template>
  <div class="container-game">
    <details class="card text-center details" @click="label('details')">
      <summary class="my-2">
        <div class="titleGame text-center" v-if="game.showTitle">
          <p>{{ game.League.name }} ({{ game.League.Sport.name }}) ({{ getFechaFormat(game.date) }})</p>
        </div>
        <div class="row text-center" style="font-size: 0.9em">
          <div class="col-4 border-right">{{ getFechaHoraFormat(game.time) }}</div>
          <div class="col-4 border-right">
            <span> {{ game.Team.home.name }} (1)</span>
          </div>
          <div class="col-4">
            <span> {{ game.Team.away.name }} (2)</span>
          </div>
        </div>
      </summary>

      <div v-for="item in arrayFT" :key="item">
        <div v-if="game.Odds && game.Odds.FT && game.Odds.FT[item]">
          <div v-for="(odd, key) in Object.values(game.Odds.FT[item].main)" :key="key">
            <div
              class="row text-center align-items-center refOdd"
              v-if="Object.values(odd)[0]"
              style="border-bottom: 5px solid #32383e; min-height: 3em"
            >
              <div class="col-4">{{ game.Odds.FT[item]["name"] }}</div>
              <div
                class="col-4 p-2 border-left"
                @click="processId(game, 'FT', item, Object.values(odd)[0])"
                :class="{ 'btn-odd-selected p-2': existOdd(game, 'FT', item, Object.values(odd)[0]) }"
              >
                {{ Object.values(odd)[0][getFormat] }}
              </div>
              <div
                class="col-4 p-2"
                @click="processId(game, 'FT', item, Object.values(odd)[1])"
                :class="{ 'btn-odd-selected p-2': existOdd(game, 'FT', item, Object.values(odd)[1]) }"
              >
                {{ Object.values(odd)[1][getFormat] }}
              </div>
            </div>
            <div
              class="row align-items-center text-center refOdd"
              v-if="Object.values(odd)[2]"
              style="border-bottom: 5px solid #32383e; min-height: 3em"
            >
              <div class="col-4 align-items-center">{{ game.Odds.FT[item]["name"] }}</div>
              <div
                class="col-8 p-2 border-left"
                @click="processId(game, 'FT', item, Object.values(odd)[2])"
                :class="{ 'btn-odd-selected p-2': existOdd(game, 'FT', item, Object.values(odd)[2]) }"
              >
                {{ Object.values(odd)[2][getFormat] }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-for="item in arrayHT" :key="item">
        <div v-if="game.Odds && game.Odds.HT && game.Odds.HT[item]">
          <div v-for="(odd, key) in Object.values(game.Odds.HT[item].main)" :key="key">
            <div
              class="row text-center align-items-center refOdd"
              v-if="Object.values(odd)[0]"
              style="border-bottom: 5px solid #32383e; min-height: 3em"
            >
              <div class="col-4">{{ game.Odds.HT[item]["name"] }}</div>
              <div
                class="col-4 p-2 border-left"
                @click="processId(game, 'HT', item, Object.values(odd)[0])"
                :class="{ 'btn-odd-selected p-2': existOdd(game, 'HT', item, Object.values(odd)[0]) }"
              >
                {{ Object.values(odd)[0][getFormat] }}
              </div>
              <div
                class="col-4 p-2"
                @click="processId(game, 'HT', item, Object.values(odd)[1])"
                :class="{ 'btn-odd-selected p-2': existOdd(game, 'HT', item, Object.values(odd)[1]) }"
              >
                {{ Object.values(odd)[1][getFormat] }}
              </div>
            </div>
            <div
              class="row align-items-center text-center refOdd"
              v-if="Object.values(odd)[2]"
              style="border-bottom: 5px solid #32383e; min-height: 3em"
            >
              <div class="col-4 align-items-center">{{ game.Odds.HT[item]["name"] }}</div>
              <div
                class="col-8 p-2 border-left"
                @click="processId(game, 'HT', item, Object.values(odd)[2])"
                :class="{ 'btn-odd-selected p-2': existOdd(game, 'HT', item, Object.values(odd)[2]) }"
              >
                {{ Object.values(odd)[2][getFormat] }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="my-2">
        <button class="btn boton-dark" @click="openDetail(game)" :class="{ 'boton-light': this.getThemelight }">
          <i class="fa fa-plus"></i> Jugadas
        </button>
      </div>

      <div class="mb-1" v-if="isOpen(game.id)">
        <div v-for="period in arrayPeriods" :key="period">
          <div
            class="title card mt-3"
            :class="{ 'title-light': getThemelight }"
            v-if="game.Odds[period] && period == 'FT'"
          >
            JUEGO COMPLETO (FT)
          </div>
          <div
            class="title card mt-3"
            :class="{ 'title-light': getThemelight }"
            v-if="game.Odds[period] && period == 'HT'"
          >
            1ERA MITAD (HT)
          </div>
          <div
            class="title card mt-3"
            :class="{ 'title-light': getThemelight }"
            v-if="game.Odds[period] && period == 'ST'"
          >
            2DA MITAD (ST)
          </div>
          <div
            class="title card mt-3"
            :class="{ 'title-light': getThemelight }"
            v-if="game.Odds[period] && period == 'FQ'"
          >
            1er Periodo (1st Prd)
          </div>
          <div
            class="title card mt-3"
            :class="{ 'title-light': getThemelight }"
            v-if="game.Odds[period] && period == 'SQ'"
          >
            2do Periodo (2nd Prd)
          </div>
          <div
            class="title card mt-3"
            :class="{ 'title-light': getThemelight }"
            v-if="game.Odds[period] && period == 'TQ'"
          >
            3er Periodo (3rd Prd)
          </div>
          <div
            class="title card mt-3"
            :class="{ 'title-light': getThemelight }"
            v-if="game.Odds[period] && period == 'QQ'"
          >
            4to Periodo (4th Prd)
          </div>

          <div v-if="game.Odds[period]">
            <div class="text-center" v-for="(odd, key) in Object.entries(game.Odds[period])" :key="key">
              <div class="titleOdd mt-2" :class="{ 'titleOdd-light': getThemelight }">{{ odd[1].name }}</div>

              <div v-if="odd[1].main">
                <div v-for="(other, key2) in Object.values(odd[1].main)" :key="key2">
                  <div
                    v-if="Object.values(other).length == 2"
                    class="row box-odds text-center mt-2 text-dark"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div v-for="(data, key3) in Object.values(other)" :key="key3" class="col-6 sel-odd">
                      <button
                        class="btn btn-block btnOdd"
                        @click="processId(game, period, odd[0], data)"
                        :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      >
                        <b>{{ data.outcome }}</b>
                        <template v-if="showSign(getFormat, data[getFormat])">+</template>
                        {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(other).length == 3"
                    class="row box-odds text-center mt-2"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div v-for="(data, key3) in Object.values(other)" :key="key3" class="col-4 sel-odd p-1">
                      <button
                        class="btn btn-block btnOdd"
                        @click="processId(game, period, odd[0], data)"
                        :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      >
                        <b>{{ data.outcome }}</b>
                        <template v-if="showSign(getFormat, data[getFormat])">+</template>
                        {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(other).length == 4"
                    class="row box-odds text-center mt-2"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div v-for="(data, key3) in Object.values(other)" :key="key3" class="col-4 sel-odd p-1">
                      <button
                        class="btn btn-block btnOdd p-1"
                        @click="processId(game, period, odd[0], data)"
                        :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      >
                        <b>{{ data.outcome }}</b>
                        <template v-if="showSign(getFormat, data[getFormat])">+</template>
                        {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(other).length > 4"
                    class="row box-odds text-center mt-2"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div v-for="(data, key3) in Object.values(other)" :key="key3" class="col-4 sel-odd p-1">
                      <button
                        class="btn btn-block btnOdd p-1"
                        @click="processId(game, period, odd[0], data)"
                        :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      >
                        <b>{{ data.outcome }}</b>
                        <template v-if="showSign(getFormat, data[getFormat])">+</template>
                        {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="odd[1].other">
                <div v-for="(other, key2) in Object.values(odd[1].other)" :key="key2">
                  <div
                    v-if="Object.values(other).length == 2"
                    class="row box-odds text-center mt-2"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div v-for="(data, key3) in Object.values(other)" :key="key3" class="col-6 sel-odd">
                      <button
                        class="btn btn-block btnOdd"
                        @click="processId(game, period, odd[0], data)"
                        :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      >
                        <b>{{ data.outcome }}</b>
                        <template v-if="showSign(getFormat, data[getFormat])">+</template>
                        {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(other).length == 3"
                    class="row box-odds text-center mt-2"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div v-for="(data, key3) in Object.values(other)" :key="key3" class="col-4 sel-odd p-1">
                      <button
                        class="btn btn-block btnOdd"
                        @click="processId(game, period, odd[0], data)"
                        :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      >
                        <b>{{ data.outcome }}</b>
                        <template v-if="showSign(getFormat, data[getFormat])">+</template>
                        {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(other).length == 4"
                    class="row box-odds text-center mt-2"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div v-for="(data, key3) in Object.values(other)" :key="key3" class="col-4 sel-odd p-1">
                      <button
                        class="btn btn-block btnOdd p-1"
                        @click="processId(game, period, odd[0], data)"
                        :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      >
                        <b>{{ data.outcome }}</b>
                        <template v-if="showSign(getFormat, data[getFormat])">+</template>
                        {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="Object.values(other).length > 4"
                    class="row box-odds text-center mt-2"
                    :class="{ 'bg-light': getThemelight }"
                  >
                    <div v-for="(data, key3) in Object.values(other)" :key="key3" class="col-4 sel-odd p-1">
                      <button
                        class="btn btn-block btnOdd p-1"
                        @click="processId(game, period, odd[0], data)"
                        :class="{ 'btn-odd-selected': existOdd(game, period, odd[0], data) }"
                      >
                        <b>{{ data.outcome }}</b>
                        <template v-if="showSign(getFormat, data[getFormat])">+</template>
                        {{ data[getFormat] }}
                        <template v-if="data.factor">({{ data.factor }})</template>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </details>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      arrayFT: ["1", "14", "18"],
      arrayHT: ["274", "275", "276", "60"],
      arrayPeriods: ["FT", "HT", "ST", "FQ", "SQ", "TQ", "QQ"],
      detailsOpened: []
    };
  },
  props: {
    game: Object
  },
  computed: {
    ...mapGetters(["getFormat", "getThemelight", "getOdds", "getOddsRestricteds", "getBetType"])
  },
  methods: {
    ...mapActions(["actionGames", "processOdd", "updatePrize"]),
    getFechaFormat(f) {
      return moment(f).format("DD/MM/YYYY");
    },
    getFechaHoraFormat(f) {
      return moment(f).format("DD/MM/YYYY hh:mm A");
    },
    existOdd(game, type, item, odd) {
      let key = game.Odds[type][item].other
        ? Object.keys(game.Odds[type][item].other)
        : Object.keys(game.Odds[type][item].main);
      let id = game.id + "X" + type + "X" + item + "X" + key + "X" + odd.outcome;

      return this.getOdds.find(element => element.id == id);
    },
    processId(game, type, idOdd, odd) {
      let key = game.Odds[type][idOdd].other
        ? Object.keys(game.Odds[type][idOdd].other)
        : Object.keys(game.Odds[type][idOdd].main);
      let id = game.id + "X" + type + "X" + idOdd + "X" + key + "X" + odd.outcome;

      let oddObject = {
        id,
        home: game.Team.home.name,
        away: game.Team.away.name,
        type,
        odds: odd.odds,
        odds_a: odd.odds_a,
        factor: odd.factor,
        outcome: odd.outcome,
        sport_id: game.League.Sport.id,
        idOdd,
        game_id: game.id,
        time: game.time
      };

      if (this.getBetType == "Directa") {
        let oddExisted = this.getOddsRestricteds.includes(id);

        if (oddExisted) {
          this.$store.commit("setOddsRestricteds", id);
        }
      }

      this.processOdd(oddObject);
      this.updatePrize();
    },
    showSign(format, value) {
      return format == "odds" && value > 0;
    },
    openDetail(game) {
      let index = this.detailsOpened.indexOf(game.id);

      if (index > -1) {
        this.detailsOpened.splice(index, 1);
      } else {
        this.detailsOpened = [];
        this.detailsOpened.push(game.id);
      }
    },
    isOpen(id) {
      return this.detailsOpened.includes(id);
    },
    label: function(label) {
      //se inicializa la decha actual para evitar conflictos en los titulos de los deportes
      this.fechaAnt = null;
      $(label).on("click", function() {
        $(label + "[open]")
          .not(this)
          .removeAttr("open");
        //posiciona la pantalla en el juego seleccionado
        if ($(this).attr("open") == undefined) {
          $("html,body, .container, .container-game").animate(
            {
              scrollTop: $(this).offset().top - 150
            },
            50
          );
        }
      });
    }
  }
};
</script>

<style scoped>
.titleGame {
  font-size: 1.2em;
  background-color: #212529;
  color: #ffffff;
}
.titleGame-light {
  font-size: 1.2em;
  background-color: #5d75cb;
  color: #ffffff;
}

details > summary {
  list-style: none;
  outline: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

.details {
  font-size: 12px;
  font-weight: 500;
  background-color: #32383e;
  color: #ffffff;
  width: 100% !important;
}

.details-light {
  font-size: 12px;
  font-weight: 500;
  background-color: #ebeefd;
  color: #000000;
}

.btn-logro {
  background-color: #212529;
  font-weight: 600;
  width: 90%;
  color: #c7c7c7 !important;
}

.align-odd {
  padding: auto;
  vertical-align: middle;
}

.btn-odd-selected {
  font-weight: 500;
  text-shadow: 1px 1px 3px black !important;
  color: #ffffff !important;
  background-color: #3cb202;
}
</style>
