var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'light-theme bg-light': this.getThemelight,
    'animate__animated animate__backInUp': !this.modal,
    'container-bonus': !_vm.getIsMobilResolution,
    'modal-form': this.modal
  },attrs:{"id":"container-bonus"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mt-5"},[(!_vm.isMobilResolution())?_c('div',{staticClass:"col-3"}):_vm._e(),_c('div',{staticClass:"col-12"},[(!this.modal)?_c('h1',{class:{
            'title-orange-web': !_vm.getIsMobilResolution,
            'title-ayudas mt-5': _vm.getIsMobilResolution,
            'mb-4': true,
            letra: true,
            'text-center': true,
            'dark-h1': !this.getThemelight,
            'light-h1': this.getThemelight
          },staticStyle:{"color":"white","font-weight":"700"}},[_vm._v("\n          "+_vm._s(_vm.$t("bonos_terminos_y_condiciones"))+"\n        ")]):_vm._e(),(this.modal)?_c('h1',{class:{
            'title-orange-web py-3': true,
            'mb-4': true,
            letra: true,
            'text-center': true,
            'light-h1': true
          },staticStyle:{"color":"white","font-weight":"700"}},[_vm._v("\n          "+_vm._s(_vm.$t("bonos_terminos_y_condiciones"))+"\n        ")]):_vm._e(),_c('div',{staticClass:"text-justify font5 container-html",class:{ 'mx-2': this.getIsMobilResolution }},[_c('AcordionCMS',{attrs:{"data":this.getBonosTerminosCondiciones}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }