<template>
  <div class="container">
    <div class="row text-light">
      <div class="col-2">
        <i class="fas fa-chevron-left fa-lg" v-on:click="back()"></i>
      </div>
      <div class="col-8 text-center text-title">
        <span> Tickets jugados </span>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="form-inline">
      <div class="mt-2 w-50">
        <datetime
          v-model="fechaDesde"
          value-zone="America/Caracas"
          input-class="rounded text-center"
          input-style="height: 2.2em;"
          :phrases="textoCalendario"
        ></datetime>
      </div>
      <div class="mt-2 w-50">
        <datetime
          v-model="fechaHasta"
          value-zone="America/Caracas"
          input-class="rounded text-center"
          input-style="height: 2.2em;"
          :phrases="textoCalendario"
        ></datetime>
      </div>
    </div>

    <div class="row mb-2 mt-3 text-center">
      <v-select
        class="style-chooser"
        :options="getEstado"
        placeholder="Estatus de ticket"
        style="width: 100%; color: black"
        :reduce="estado => estado.id"
        v-model="estado"
        label="label"
      >
      </v-select>
    </div>
    <div class="row mb-2 mt-3 text-center">
      <input
        type="number"
        class="form-control"
        placeholder="Número de ticket"
        style="width: 70%; height: 2.5em"
        v-model="numero"
      />
      <button class="btn boton-dark ml-3" v-on:click="filter()">Filtrar</button>
    </div>
    <div class="row mb-3 mt-3 text-center">
      <v-select
        class="style-chooser"
        :options="getCuotas"
        style="width: 100%; color: black"
        :reduce="cuota => cuota.id"
        v-model="cuota"
        label="label"
        :searchable="false"
      >
      </v-select>
    </div>

    <div class="card" v-for="ticket of results.data" v-bind:key="ticket.id" :ref="`${ticket.id}`">
      <div class="row border text-center bg-title">
        <div class="col-12">
          <b>Número</b>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-12">
          {{ ticket.number }}
        </div>
      </div>
      <div class="row border text-center bg-title">
        <div class="col-6">
          <b>Fecha</b>
        </div>
        <div class="col-6">
          <b>Monto</b>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-6">
          {{ ticket.procesed }}
        </div>
        <div class="col-6">{{ getUser.Coin.moneda }}{{ formatoMonto(ticket.amount) }}</div>
      </div>
      <div class="row border text-center bg-title">
        <div class="col-6">
          <b>Premio</b>
        </div>
        <div class="col-6">
          <b>Estado</b>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-6">{{ getUser.Coin.moneda }}{{ formatoMonto(ticket.prize) }}</div>
        <div class="col-6">
          {{ ticket.status }}
        </div>
      </div>
      <div class="row text-center boton-dark border">
        <div class="text-center my-2" v-on:click="toPage('TicketDetailMovil', ticket)">
          <i class="fa fa-search"></i>
        </div>
      </div>
    </div>

    <div class="text-light mt-5 text-center" v-if="results.total == 0">
      <div>
        <h6>No se encontraron registros</h6>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      name: "TicketsMovil",
      fechaDesde: moment()
        .format()
        .toString(),
      fechaHasta: moment()
        .format()
        .toString(),
      textoCalendario: { ok: "Ok", cancel: "Cancelar" },
      //language: "es", // the language I need
      //languages: lang,
      estado: "",
      cuota: 1,
      numero: "",
      estados: [],
      estadosOpt: [],
      getEstado: [
        { id: 0, label: "Todos" },
        { id: 1, label: "Pendiente" },
        { id: 2, label: "Ganador" },
        { id: 3, label: "Perdedor" },
        { id: 4, label: "Devuelto" },
        { id: 5, label: "Anulado" }
      ],
      getCuotas: [
        { id: 1, label: "Americano" },
        { id: 2, label: "Decimal" }
      ],
      results: [],
      resultsFilter: [],
      page: 0,
      dataResults: null
    };
  },
  components: {
    datetime: Datetime,
    vSelect
  },
  computed: {
    ...mapGetters(["getTickets", "getUser"])
  },
  methods: {
    toPage: function(page, ticket) {
      this.$emit("changePage", page);

      if (ticket.id != localStorage.getItem("idTicket")) {
        localStorage.setItem("idTicket", ticket.id);
        localStorage.setItem("dataResults", JSON.stringify(this.dataResults));
      }

      this.$store.commit("setTicket", ticket);
    },
    back() {
      this.$emit("changePage", "DeportesMovil");
      localStorage.removeItem("dataResults");
      localStorage.removeItem("idTicket");
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async filter() {
      var fechaDesde = "";
      var fechaHasta = "";

      /* Si el campo numero tiene valor asignado se inicializan 
      todos los demas campos para que la busqueda se haga solo por el nùmero de ticket */
      if (this.numero != "") {
        this.estado = 0;
      }

      //Solo se hace la busqueda por fecha si estan establecidos los valores de fechaDesde y fechaHasta
      if (this.fechaDesde != "" && this.fechaHasta != "" && this.numero == "") {
        fechaDesde = moment(this.fechaDesde).format("YYYY-MM-DD");
        fechaHasta = moment(this.fechaHasta).format("YYYY-MM-DD");
      }

      this.dataResults = {
        page: this.page,
        status: this.estado,
        number: this.numero,
        since: fechaDesde,
        until: fechaHasta
      };

      this.$store.commit("showLoader");
      await this.$store.dispatch("getTicketsClientAsync", this.dataResults);

      this.results = this.getTickets;

      //Mantiene los datos de la consulta
      //localStorage.setItem("dataResults", JSON.stringify(this.dataResults));

      this.$store.commit("hideLoader");
    }
  },
  async mounted() {
    this.$store.dispatch("checkLogin");
    this.$store.dispatch("checkSession");

    if (localStorage.getItem("dataResults")) {
      var res = JSON.parse(localStorage.getItem("dataResults"));
      var idTicket = localStorage.getItem("idTicket");

      await this.$store.dispatch("getTicketsClientAsync", res);

      this.results = this.getTickets;

      const refName = `${idTicket}`;

      this.$nextTick(() => {
        this.$refs[refName][0].scrollIntoView({ behavior: "smooth", block: "center" });
      });
    } else {
      this.filter();
    }
  }
};
</script>

<style scoped>
.container {
  padding-top: 3em;
  min-height: 500px;
}
.title-orange {
  font-size: 1.5em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
  color: rgb(243, 156, 18);
}
.style-chooser .vs__dropdown-toggle {
  border: 1px solid #d6dbdb;
}
.style-chooser .vs__dropdown-menu {
  background: white;
  color: gray;
  text-transform: lowercase;
  font-variant: small-caps;
}
.boton-dark {
  color: #f9f9f9;
  background-color: rgb(49 54 70);
}
.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: black;
}

.style-chooser .vs__dropdown-option {
  color: black !important;
  font-weight: 800;
}

.style-chooser .vs__dropdown-option--highlight {
  background-color: #e6eaea;
}
</style>
