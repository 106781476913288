var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'soporte-movil': this.getIsMobilResolution,
    'container-preguntas': !this.getIsMobilResolution,
    'light-theme bg-light': this.getThemelight,
    'animate__animated animate__backInUp': true
  },attrs:{"id":"container-preguntas"}},[_c('div',{staticClass:"container"},[(this.getIsMobilResolution)?_c('div',{staticClass:"text-center mb-2"},[_c('span',{class:{ 'text-dark': this.getThemelight, 'title-ayudas': true, 'text-light': !this.getThemelight }},[_vm._v("\n        "+_vm._s(_vm.titulo)+"\n      ")])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(!this.getIsMobilResolution)?_c('h1',{class:{
            'title-orange-web pt-5': true,
            'mb-4': true,
            letra: true,
            'text-center': true,
            'dark-h1': !this.getThemelight,
            'light-h1': this.getThemelight
          },staticStyle:{"color":"white","font-weight":"700"}},[_vm._v("\n          "+_vm._s(_vm.titulo)+"\n        ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('AcordionCMS',{attrs:{"data":this.data}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }