<template>
  <section
    id="container-mensajeria"
    :class="{
      'animate__animated animate__backInUp': true,
      'light-theme bg-light': this.getThemelight,
      'bg-dark': !this.getThemelight
    }"
    v-if="this.getUser"
  >
    <div class="container" v-if="!this.getIsMobilResolution">
      <div class="row">
        <div
          :class="{
            'col-12': isMobilResolution(),
            'col-12': !isMobilResolution()
          }"
        >
          <div
            :class="{
              'card card-primary card-outline': true,
              'light-theme': this.getThemelight,
              'bg-dark': !this.getThemelight
            }"
          >
            <div class="card-header">
              <h3 v-if="!isMobilResolution()" class="card-title capitalize">
                {{ $t("mensajeria") }}
              </h3>
              <div class="text-justify font5 container-html" v-if="!isMobilResolution()">
                <div class="mailbox-controls">
                  <div class="float-left">
                    {{ $t("limite") }}:
                    <targetSelector
                      :class="{ 'bg-dark': !this.getThemelight }"
                      @changeSelection="updateSizeLimit"
                      :selectTarget="limitResults"
                    />
                    {{ $t("pagina") }}: {{ this.getCurrentPage() }} de {{ this.getTotalPages() }} {{ $t("paginas") }}
                  </div>
                  <div class="float-right">
                    {{ $t("total_de_registros") }} {{ this.totalResults }}
                    <div class="btn-group">
                      <button
                        type="button"
                        :class="{
                          'btn btn-default btn-sm': true,
                          disabled: this.getTotalPages() == 1 || this.pageResults == 1
                        }"
                        @click="downPageResults"
                      >
                        <i class="fas fa-chevron-left"></i>
                      </button>
                      <button
                        type="button"
                        :class="{
                          'btn btn-default btn-sm': true,
                          disabled: this.getMensajeria.page >= this.getMensajeria.pages
                        }"
                        v-if="this.getMensajeria && this.getMensajeria.page && this.getMensajeria.pages"
                        @click="upPageResults"
                      >
                        <i class="fas fa-chevron-right"></i>
                      </button>
                    </div>
                    <!-- /.btn-group -->
                  </div>
                  <!-- /.float-right -->
                </div>
              </div>
            </div>
            <div class="card-body">
              <MensajeriaTable @changeTarget="changeTarget" />

              <b-pagination
                size="sm"
                :per-page="this.limitResults"
                :limit="3"
                :total-rows="parseInt(this.totalResults)"
                v-model="pageResults"
                v-if="this.totalResults != null && this.pageResults != null && this.limitResults != null"
                custom="size"
                :table-variant="this.getThemelight ? 'light' : 'dark'"
                :class="{
                  'customPagination float-right mt-2': true,
                  light: this.getThemelight,
                  dark: !this.getThemelight
                }"
                first-number
                last-number
                @input="changePage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <MensajeriaMovil @changeTarget="changeTarget" />
    </div>
  </section>
</template>
<script>
import JQ from "jquery";
import MensajeriaTable from "../components/tables/MensajeriaTable.vue";
import targetSelector from "../components/tables/tools/targetSelector.vue";
import { mapGetters } from "vuex";

//Componente Movil
import MensajeriaMovil from "../components/movil/MensajeriaMovil.vue";
export default {
  name: "Mensajeria",
  components: { MensajeriaTable, targetSelector, MensajeriaMovil },
  computed: {
    ...mapGetters([
      "getUser",
      "getScroll",
      "getMensajeria",
      "getIsMobilResolution",
      "getMenuMobilOpen",
      "getThemelight"
    ])
  },
  data() {
    return {
      targetModule: "mensajeria",
      limitResults: 15,
      totalResults: 0,
      pageResults: 1
    };
  },
  methods: {
    async changePage(pag) {
      this.$store.commit("showLoader");

      if (this.targetModule == "balances") {
        await this.$store.dispatch("getBalacesUserAsync", {
          page: pag,
          perpage: this.limitResults
        });
      }

      if (this.targetModule == "recargas") {
        await this.$store.dispatch("getChargesUserAsync", {
          page: pag,
          perpage: this.limitResults
        });
      }

      if (this.targetModule == "mensajeria") {
        await this.$store.dispatch("getMessengerUserAsync", {
          page: pag,
          perpage: this.limitResults
        });
      }

      setTimeout(() => {
        this.$store.commit("hideLoader");
      }, 500);
    },
    updatePageResults(p) {
      this.pageResults = p;
      console.log("updatePageResults", this.pageResults, p);
    },
    updateTotalResults(t) {
      this.totalResults = t;
      console.log("updateTotalResults", this.totalResults, t);
    },
    async downPageResults() {
      if (this.pageResults > 1) {
        this.pageResults--;
        this.$store.commit("showLoader");
        await this.$store.dispatch("getMessengerUserAsync", {
          page: this.pageResults,
          perpage: this.limitResults
        });
        setTimeout(() => {
          this.$store.commit("hideLoader");
        }, 500);
      }
    },
    changeTarget(nameComponent) {
      this.$emit("changePage", nameComponent);
    },
    async upPageResults() {
      if (this.getCurrentPage() < this.getTotalPages()) {
        this.pageResults++;
        this.$store.commit("showLoader");
        await this.$store.dispatch("getMessengerUserAsync", {
          page: this.pageResults
        });
        setTimeout(() => {
          this.$store.commit("hideLoader");
        }, 500);
      }
    },
    getCurrentPage() {
      let pag = 1;
      if (this.targetModule == "mensajeria" && this.getMensajeria && this.getMensajeria.page > 0) {
        pag = parseInt(this.getMensajeria.page);
      }
      return pag;
    },
    getTotalPages() {
      if (!this.getMensajeria || !this.getMensajeria.total || !this.getMensajeria.per_page) {
        return 0;
      }
      const cantidadPag = this.getMensajeria.total / this.getMensajeria.per_page;
      const parteEntera = parseInt(cantidadPag);

      const totalPaginas = cantidadPag - parteEntera > 0 ? parteEntera + 1 : parteEntera;

      return this.getMensajeria && this.getMensajeria.total ? totalPaginas : 0;
    },
    isMobilResolution() {
      return this.getIsMobilResolution;
    },
    toPage: function(page) {
      this.$emit("changePage", page);
    },
    async updateSizeLimit(limit) {
      this.$store.commit("showLoader");
      this.limitResults = parseInt(limit);
      let pag = 1;
      let dispatchRequest = "getMessengerUserAsync"; // SERVICIO DE MENSAJERIA
      /*
      if (
        this.targetModule == "Mensajeria" &&
        this.getMensajeria &&
        this.getMensajeria.page
      ) {
        pag = parseInt(this.getMensajeria.page);
      }
      */

      await this.$store.dispatch(dispatchRequest, {
        page: pag,
        perpage: this.limitResults
      });

      setTimeout(() => {
        this.$store.commit("hideLoader");
      }, 500);
    }
  },
  async mounted() {
    if (!this.getUser) {
      this.$emit("changePage", "Home");
      return;
    }
    let instancia = this;
    this.$store.commit("showLoader");
    await this.$store.dispatch("getMessengerUserAsync");
    this.totalResults = this.getMensajeria && this.getMensajeria.total ? this.getMensajeria.total : 0;

    JQ(document.getElementById("container-preguntas")).on("scroll", function() {
      Scrolled(instancia);
    });

    instancia.$emit("barSolid", true);
    this.$store.commit("hideLoader");
  }
};

function Scrolled(instancia) {
  var winheight = JQ(document.getElementById("container-preguntas")).height();
  var docheight = JQ(document).height();
  var scrollTop = JQ(document.getElementById("container-preguntas")).scrollTop();
  var trackLength = docheight - winheight;
  var pctScrolled = Math.floor((scrollTop / trackLength) * 100);

  if (scrollTop > 100) {
    $("#scroll").fadeIn();
  } else {
    $("#scroll").fadeOut();
  }

  if (pctScrolled >= 5) {
    instancia.$emit("barSolid", true);
  } else {
    instancia.$emit("barSolid", false);
  }

  /*
      si no es resolucion mobil actualizar porcentaje 
      para oscurecer barra de menu web
  */
  if (!instancia.isMobilResolution()) {
    instancia.$parent.updateScroll(pctScrolled);
  }
}
</script>
<style>
.container-html p,
#container-preguntas div.text-justify.font5.container-html p {
  color: #fff;
}
</style>
<style scoped>
body {
  background-color: #232323;
}

section {
  display: block;
  height: 100vh;
  overflow-y: auto;
}
.container {
  margin-top: 60px;
}
h1,
.container-html p,
p {
  color: #fff;
}

.shade {
  font-size: 20px;
  background-color: rgba(233, 227, 151, 0.7);
}
.fixed {
  position: sticky;
  bottom: 20%;
  left: 10px;
}

.title-orange-web {
  font-size: 2em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
  color: rgb(243, 156, 18);
}
</style>
