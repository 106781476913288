<template>
  <section
    :class="{
      light: this.getThemelight,
      'sin-rango-visible seleccion-flotante-capa': !this.getIsMobilResolution,
      'sin-rango-visible-movil': this.getIsMobilResolution
    }"
    v-if="renderComponent"
  >
    <div class="contenedor-detalles animate__animated animate__backInRight" v-if="detailsActive">
      <section class="cabecera-seleccion-apuestas">
        <div class="fc-toolbar-chunk contenedor-activadores-parley-directas">
          <div class="btn-group">
            <button
              :class="{
                'fc-prev-button btn btn-dark activadores-parley-directas': true,
                activo: getFormatoTaquilla == 'odd'
              }"
              type="button"
              aria-label="prev"
              @click="changeFormat('odd')"
            >
              <span class="text-option">{{ $t("americano") }}</span>
            </button>
            <button
              :class="{
                'fc-prev-button btn btn-dark activadores-parley-directas': true,
                activo: getFormatoTaquilla == 'odd_a'
              }"
              type="button"
              aria-label="next"
              @click="changeFormat('odd_a')"
            >
              <span class="text-option">{{ $t("decimal") }}</span>
            </button>
          </div>
        </div>
        <label
          class="label-title-header"
          :class="{
            'label-title-header-web': !this.getIsMobilResolution,
            'label-title-header-movil': this.getIsMobilResolution
          }"
          >{{ $t("seleccion_de_apuestas") }}</label
        >
      </section>
      <section class="cantidad-jugadas">
        <div class="formato-montos">
          <div class="fc-toolbar-chunk contenedor-activadores-formatos">
            <div class="btn-group">
              <button
                type="button"
                @click="changeTipoParley(true)"
                :class="{
                  'fc-prev-button btn btn-dark activadores-formato': true,
                  activo: this.getTipoParley
                }"
              >
                <span class="text-option">Parley</span>
              </button>
              <button
                type="button"
                @click="changeTipoParley(false)"
                :class="{
                  'fc-prev-button btn btn-dark activadores-formato': true,
                  activo: !this.getTipoParley
                }"
              >
                <span class="text-option">{{ $t("directas") }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="jugadas">
          <a class="btn pl-1 pr-1">
            <i class="fas fa-inbox" aria-hidden="true"></i> Apuestas
            <span class="badge bg-white" v-show="this.getApuestas">{{ this.getApuestas.length }}</span>
          </a>
        </div>
        <div class="opcion">
          <button
            :class="{
              'btn btn-block bg-gradient-primary btn-xs btn-generar': true,
              'btn-generar-web': !this.getIsMobilResolution,
              'btn-generar-movil': this.getIsMobilResolution,
              disabled: errorMonto
            }"
            @click="generarTicket"
          >
            {{ $t("generar_apuesta") }}
          </button>
        </div>
      </section>
      <section class="detalles-calculos">
        <form :name="getNameInputAmount + '-flotante'" :autocomplete="getNameInputAmount + '-flotante'">
          <div class="cantidad-section">
            <label class="label-monto" for="input-cantidad"
              ><b style="width: 100%; font-size: 0.8em">{{ $t("monto_de_la_apuesta") }}</b></label
            >
            <input
              type="number"
              :class="{ 'input-cantidad': true, 'is-invalid': errorMonto }"
              v-model="monto"
              @keypress="isNumber"
              :name="getNameInputAmount"
              :id="getNameInputAmount"
              :autocomplete="getNameInputAmount + '-flotante'"
            />
            <button
              class="btn btn-app bg-danger icon-trash"
              v-b-tooltip.hover.left="'Limpiar Todas las Apuestas'"
              @click="limpiarApuestas"
            >
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </form>
        <!-- autocomplete="off" -->
      </section>
      <span v-if="errorMonto" class="error invalid-feedback txt-error">
        {{ $t("error_en_monto_por_favor_ingrese_un_monto_valido") }}
      </span>
      <section class="cantidad-premio" v-show="this.getPremio && getTipoParley">
        <label class="label-title-premio">PREMIO</label>
        <label class="label-cantidad-premio">{{ formatoMonto(this.getPremio) }}</label>
      </section>
      <vuescroll :ops="this.ops">
        <dl v-if="this.getApuestas" class="dd-lista">
          <dd class="contenedor-cards-apuestas flotantes" v-for="(apuesta, index) in this.getApuestas" :key="index">
            <div
              :class="{
                'callout-apuestas callout': true,
                'callout-success': !hayAlgunaRestrincionParaparley(apuesta.id),
                'bg-dark': !apuesta.update,
                'update-bg': apuesta.update,
                'callout-danger bg-restringida': hayAlgunaRestrincionParaparley(apuesta.id)
              }"
              style="padding: 0.5em 0.2em; font-size: 14px"
            >
              <div class="row-fluid">
                <div class="col-12" style="font-size: 0.9em">
                  {{ maskNameTitle(apuesta.gameName) }}
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap">
                <div class="col-vs">VS</div>
                <div class="col-names">
                  <div class="txt-teams">{{ apuesta.teams[0].name }}</div>
                  <div class="txt-teams">{{ apuesta.teams[1].name }}</div>
                  <div
                    class="txt-teams bg-premio"
                    v-show="!getTipoParley && getPremiosDirectas && getPremiosDirectas[index]"
                  >
                    {{ $t("premio") }}: {{ formatoMonto(getPremiosDirectas[index]) }}
                  </div>
                </div>
                <div class="col-details mr-2">
                  <p class="txt-momento">
                    {{ apuesta.texto }} ( {{ apuesta.respuesta.odd.Odd.finalit }} )
                    {{ apuesta.respuesta.odd.Odd[getFormatoTaquilla] }}
                    <span v-show="apuesta.respuesta.odd.Odd.factor"
                      >({{ parseInt(apuesta.respuesta.odd.Odd.factor) }})</span
                    >
                  </p>
                  <p class="txt-momento">{{ apuesta.momento }}</p>
                </div>
                <button
                  class="btn btn-app bg-danger icon-trash icon-trash-item"
                  v-on:click="clickApuestaTrash(apuesta)"
                >
                  <i class="fas fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </dd>
        </dl>
      </vuescroll>
    </div>
    <button
      v-show="getVisualWidth > 1280"
      @click="$store.dispatch('togleTaquillaFlotante')"
      :class="{
        'btn btn-dark activador-fijar': true,
        'is-open': detailsActive
      }"
    >
      <i class="fas fa-compress-arrows-alt"></i>
    </button>
    <div
      :class="{
        'activador-detalles': true,
        activo: detailsActive
      }"
      @click="activador"
    >
      <i v-show="detailsActive" class="fas fa-angle-down" style="font-size: 40px"></i>
      <i v-show="!detailsActive" class="fas fa-angle-up" style="font-size: 20px; line-height: 1.7em"></i>
      <label v-show="!detailsActive"
        >{{ $t("seleccion_de_apuestas") }}
        <span class="badge bg-white mt-2 ml-1" style="font-size: 12px !important" v-show="this.getApuestas">{{
          this.getApuestas.length
        }}</span></label
      >
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { chatMixin } from "../mixins/chatMixin.js";
import * as u from "j899";
import vuescroll from "vuescroll";
import moment from "moment";
import { essentialsData } from "../mixins/essentialsDataMixin.js";

import { juegosDetalles } from "../mixins/juegosDetallesMixin.js";

export default {
  name: "ActivadorDetallesFlotante",
  mixins: [chatMixin, essentialsData, juegosDetalles],
  components: { vuescroll },
  computed: {
    ...mapGetters([
      "getUser",
      "getApuestas",
      "getDetailsFloating",
      "getFormatoTaquilla",
      "getTipoParley",
      "getPremiosDirectas",
      "getPremiosParley",
      "getMonto",
      "getBetInit",
      "getPremio",
      "getThemelight",
      "getIsMobilResolution",
      "getVisualWidth",
      "getRestriccionesParley"
    ]),
    tieneApuestas() {
      return this.getApuestas && this.getApuestas.length && this.getApuestas.length > 0;
    },
    getNameInputAmount() {
      const timeStamp = new Date().getTime();
      return `cantidad-selector-${timeStamp}`;
    }
  },
  watch: {
    monto(m) {
      if (typeof parseFloat(m) == "number" && !isNaN(m)) {
        this.errorMonto = false;
        let montoFormato = parseFloat(parseFloat(m).toFixed(2));

        if (isNaN(montoFormato)) {
          montoFormato = 0;
        }

        this.$store.commit("setMonto", montoFormato);
      } else if (m != "") {
        this.errorMonto = true;
        this.$store.commit("setMonto", 0);
        this.$store.commit("setPremio", 0);
        this.$store.commit("setTotalEnPremios", 0);
      }

      if (String(m).trim() == "") {
        // valores manejados internamente en el modulo de taquilla
        this.$store.commit("setPremio", 0);
        this.$store.commit("setTotalEnPremios", 0);
        this.$store.commit("setMonto", 0);
      }

      this.$store.dispatch("calculatePrize");
    }
  },
  data() {
    return {
      monto: 0,
      errorMonto: false,
      detailsActive: false,
      renderComponent: true,
      ops: {
        vuescroll: {
          mode: "native",
          sizeStrategy: "percent",
          detectResize: true,
          locking: true
        },
        scrollPanel: {
          initialScrollY: true,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: "right"
        },
        rail: {
          background: "#01a99a",
          opacity: 0,
          size: "3px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "2px",
          keepShow: false
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: "#c1c1c1",
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0.2,
          size: "60px",
          disable: false
        }
      }
    };
  },
  methods: {
    isNumber(val) {
      if (val.key != "." && isNaN(Number(val.key))) {
        return val.preventDefault();
      }
    },
    limpiarApuestas() {
      this.Swal.fire({
        title: "¿Desea eliminar las apuestas seleccionadas?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.commit("setMonto", 0);
          this.$store.commit("setPremio", 0);
          this.$store.commit("setApuestas", []);
          this.$store.commit("setRestriccionParley", []);
          this.$store.commit("setExcepcionParley", false);
          this.Swal.fire("La selección de apuestas ha sido limpiada.", "", "success");
        } else if (result.isDenied) {
          this.Swal.fire("Acción cancelada", "", "info");
        }
      });
    },
    async generarTicket() {
      // caso cuando es parley y tiene 1 sola apuesta
      // cambiarlo a directa
      if (this.getApuestas.length == 1 && this.getTipoParley) {
        await this.changeTipoParley(false);
      }

      let validaciones = await this.validacionesTickets();

      if (validaciones.error && validaciones.error == true) {
        return this.Swal.fire("Ha ocurrido un error", "<p class='text-center'>" + validaciones.msj + "</p>", "error");
      }

      let oddsData = {};

      if (this.getApuestas && this.getApuestas.length > 0) {
        this.getApuestas.forEach(apuesta => {
          oddsData[apuesta.id] = {
            game_id: apuesta.respuesta.odd.Game.id,
            type: apuesta.respuesta.odd.Odd.finalit,
            odd_type: apuesta.respuesta.odd.Odd.odd_type_id,
            odd: apuesta.respuesta.odd.Odd.odd,
            odd_a: apuesta.respuesta.odd.Odd.odd_a,
            factor: apuesta.respuesta.odd.Odd.factor ? apuesta.respuesta.odd.Odd.factor : "",
            status: 1, // debe estar en 1 que es pendiente
            code: apuesta.respuesta.odd.Game.code
          };
        });
      }

      const cantidad_amount_ind = this.getTipoParley ? this.monto : this.monto * this.getApuestas.length;

      let confirmo = false;
      let bonus = false;

      // Confirmacion antes de enviar el ticket

      const dataPremiosParley = JSON.parse(JSON.stringify(this.getPremiosParley));
      const dataPremiosDirectas = JSON.parse(JSON.stringify(this.getPremiosDirectas));

      // eslint-disable-next-line no-unused-vars
      const { value: accept } = await this.Swal.fire({
        customClass: {
          content: "contentCustomPosition",
          footer: "contentFinal"
        },
        width: "700px",
        title: "¿Está seguro que desea generar el Ticket?",
        input: "checkbox",
        inputValue: 0,
        inputPlaceholder: `Usar Bonus`,
        text: "(Al sellar con bonus NO se devuelve el monto apostado)",
        confirmButtonText: 'Confirmar <i class="fa fa-arrow-right"></i>',
        footer: `NOTA: Una vez oprima <strong>[Confirmar]</strong>, su apuesta será registrada! <br> Oprima Cancelar para agregar/actualizar apuestas.`,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar"
      }).then(result => {
        /*
                                  validar cierre de modal
                                  { 
                                    "isConfirmed": false, 
                                    "isDenied": false, 
                                    "isDismissed": true, 
                                    "dismiss": "cancel"
                                    }
                                */
        confirmo = result.isConfirmed;

        bonus = result.value;

        return confirmo;
      });

      const dataTicket = {
        Ticket: {
          amount: this.monto,
          amount_ind: cantidad_amount_ind,
          modalidad: this.getTipoParley,
          bonus,
          code: parseInt(this.getBetInit.code),
          procesed: moment(new Date()).format("YYYY-MM-DD"),
          Odds: oddsData
        }
      };

      /*
                          2) los campos amount y amount_ind no pueden ir vacíos.
                          [5:35 PM, 9/2/2021] +58 414-7425353: Cuando la jugada es tipo Parley el amount = amount_ind = es el monto de la jugada.
                          Y cuando es tipo directa
                          Amount_ind es el monto que introduce el cliente y el amount = amount_ind * cantidad_jugadas
                        */

      if (confirmo) {
        this.$store.commit("showLoader");

        let respuesta = await this.$store.dispatch("registerTicketAsync", dataTicket);

        if (respuesta.Type_error && respuesta.Type_error != 0) {
          // caso si cambio valor durante la apuesta
          if (respuesta.Type_error == 9) {
            await this.$store.dispatch("actualizarApuesta", respuesta.odds);
            this.renderComponent = false;
            this.$nextTick(() => {
              // generando que el componente se renderice con las clases al cambiar de valor
              this.renderComponent = true;
            });
          }

          this.Swal.fire(
            respuesta.Type_error != 9 ? "Ha ocurrido un error" : "Lo sentimos verifiqué",
            "<p class='text-center'>" + respuesta.Error + "</p>",
            respuesta.Type_error != 9 ? "error" : "warning"
          );
        } else {
          // caso satisfactorio
          if (respuesta.NewCode) {
            //this.$store.commit("setBetInit", respuesta.NewCode);
            this.$store.commit("setCodeBetInit", respuesta.NewCode);

            respuesta.Ticket.Monto = parseFloat(this.getMonto);
            respuesta.Ticket.Premio = parseFloat(this.getPremio);
            respuesta.Ticket.Apuestas = JSON.parse(JSON.stringify(this.getApuestas));

            respuesta.Ticket.PremiosParley = dataPremiosParley;
            respuesta.Ticket.PremiosDirectas = dataPremiosDirectas;
            respuesta.Ticket.Moneda = this.getUser.Coin.moneda;

            this.$store.commit("setTicketTarget", respuesta.Ticket);
          }

          if (respuesta.Center && respuesta.Center.note) {
            //this.Swal.fire("Exito", respuesta.Center.note, "success");
            this.$bvModal.show("details-ticket-target");

            const Toast = this.Swal.mixin({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: toast => {
                toast.addEventListener("mouseenter", this.Swal.stopTimer);
                toast.addEventListener("mouseleave", this.Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "success",
              title: "Ticket generado Satisfactoriamente"
            });
          }
          this.monto = "";
          this.$store.commit("setMonto", 0);
          this.$store.commit("setPremio", 0);
          this.$store.commit("setMonto", 0);
          this.$store.commit("setApuestas", []);
          this.$store.commit("setRestriccionParley", []);
          this.$store.commit("setExcepcionParley", false);
        }
        this.$store.commit("hideLoader");
      }
    },
    validacionesTickets() {
      let error = false;
      let msj = "";

      if (this.getTipoParley && this.getApuestas.length < this.getBetInit.minimo) {
        error = true;
        msj = "El mínimo de jugadas parley debe ser " + this.getBetInit.minimo;
      }

      if (this.getApuestas.length > 1 && this.getApuestas.length > this.getBetInit.maximo) {
        error = true;
        msj = "El maximo de jugadas parley debe ser " + this.getBetInit.maximo;
      }

      if (parseFloat(this.monto) < parseFloat(this.getBetInit.min_tck)) {
        error = true;
        msj = "La jugada por parley minima debe ser " + u.inNumeration("LA", parseFloat(this.getBetInit.min_tck));
      }

      if (parseInt(this.monto) <= 0 || String(this.monto).trim() == "") {
        error = true;
        msj = "<p class='text-center'>Monto inválido por favor verifique</p>";
        this.errorMonto = true;
      }

      if (this.getApuestas && this.getApuestas.length == 0 && !error) {
        error = true;
        msj = "debe tener apuestas seleccionadas";
      }

      return { error, msj };
    },
    changeFormat(v) {
      this.$store.commit("setFormatoTaquilla", v);
    },
    activador() {
      this.detailsActive = !this.detailsActive;
      // if (this.detailsActive) {
      //   this.hideChat();
      // } else {
      //   this.showChat();
      // }
    },
    changeTipoParley(isParley) {
      this.$store.commit("setTipoParley", isParley);

      // si va a cambiar de directas a parley y tiene apuestas seleccionadas
      // y con restrinciones
      const ids_apuestas_seleccion_activa = this.getApuestas.flatMap(ap => ap.id.substr(-7, 7));
      const siExistenDuplicados = ids_apuestas_seleccion_activa =>
        ids_apuestas_seleccion_activa.filter((item, index) => ids_apuestas_seleccion_activa.indexOf(item) !== index);

      const casoDeDuplicados = siExistenDuplicados(ids_apuestas_seleccion_activa);

      if (this.getApuestas.length > 1 && isParley) {
        if (casoDeDuplicados.length > 0) {
          this.Swal.fire(
            "Ha ocurrido un error",
            "<p class='text-center'>Existen combinaciones de jugadas no permitidas para la modalidad PARLEY. Debe eliminar alguna de la jugadas seleccionadas[Resaltadas].</p>",
            "error"
          );
          this.$store.commit("setTipoParley", false);
          this.$store.commit("setExcepcionParley", true); // IMPORTANTE
          return;
        }

        // caso que no tenga duplicados  se permite
        this.$store.commit("setTipoParley", true);

        return;
      }

      this.$store.commit("setPremio", 0);
      this.$store.commit("setTotalEnPremios", 0);
      this.$store.commit("setExcepcionParley", false); // IMPORTANTE
      this.$store.dispatch("calculatePrize");
    },
    clickApuestaTrash(a) {
      // los que no contienen este ID en ninguna posicionde la dupleta, que sera removido porque se esta eliminando.
      const filtrado = this.getRestriccionesParley.filter(obj => {
        return obj.indexOf(a.id) === -1;
      });

      this.$store.commit("setRestriccionParley", filtrado);

      this.$store.dispatch("clickApuesta", a);
      this.$store.dispatch("calculatePrize");
    },
    maskNameTitle(name) {
      return String(name).split("(")[0];
    },
    formatoMonto(m) {
      let montoFormato = parseFloat(parseFloat(m).toFixed(2));
      return u.inNumeration("LA", montoFormato);
    }
  },
  mounted() {
    if (this.getMonto) {
      this.monto = parseFloat(this.getMonto);
    }
  }
};
</script>
<style>
/*
div.swal2-content.contentCustomPosition {
  display: flex !important;
  flex-direction: column-reverse !important;
}
*/

.update-bg {
  background-color: rgb(0 141 70 / 54%) !important;
}

/* Estilo Scroll contenedor lista */
.dd-lista {
  padding-bottom: 250px;
}

.dd-lista::-webkit-scrollbar {
  width: 0.5em;
}

.dd-lista::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.dd-lista::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  opacity: 0.2;
  border-radius: 7px;
}

div.swal2-content.contentCustomPosition > div.swal2-html-container {
  text-align: center !important;
}

div.swal2-footer.contentFinal {
  display: flow-root;
  text-align: center;
}
</style>
<style scoped="scoped">
.hasVBar {
  height: 720px !important;
  /*    padding-bottom: 10em !important; */
}

.error.invalid-feedback.txt-error {
  display: block;
  padding-bottom: 0.5em;
}

div.jugadas > a.btn {
  padding: 0;
  margin: 0;
  color: #fff;
}

.bg-premio {
  background-image: linear-gradient(-90deg, #6bff00, #0d6403);
  text-align: center;
  font-size: 0.95em;
  text-shadow: #000 1px 1px 3px;
}

/*background: linear-gradient(to right, #f09819, #ff512f);*/
.cabecera-seleccion-apuestas {
  width: 100% !important;
  height: 3em;
  display: flex;
  padding: 0.3em;
  overflow-y: hidden;
  background-color: #6c6c6c;
}

.fc-toolbar-chunk.contenedor-activadores-parley-directas .btn-group {
  width: 100%;
}

.sin-rango-visible {
  right: -1000em;
  position: fixed;
}

.sin-rango-visible-movil {
  right: 0em;
  z-index: 100;
  position: fixed;
}

.callout-apuestas {
  width: 100%;
}

.col-vs {
  width: 10%;
}

.col-names {
  width: 40%;
}

.col-details {
  width: 37%;
}

.btn.float-right.close.btn-secondary.btn-destroy {
  right: 0px;
  padding: 0.5em !important;
}

.seleccion-flotante-capa {
  display: block;
  position: absolute;
  top: 0;
}

.contenedor-detalles {
  display: block;
  position: fixed;
  /*background-color: #31373d;*/
  background: rgba(49, 55, 61, 0.8);
  color: #fff;
  z-index: 100;
  /*width: 30vw;*/
  width: 360px;
  height: 100vh;
  /*top: 65px;*/
  top: 96px;
  right: 0%;
}

.activador-fijar {
  z-index: 101;
  display: block;
  position: fixed;
  bottom: 0%;
  right: 202px;
  border: solid 2px #ffffff40;
}

.activador-fijar.is-open {
  right: 300px;
}

.activador-detalles {
  display: block;
  position: fixed;
  bottom: 0%;
  right: 0%;
  z-index: 100;
  width: 200px;
  height: 3em;
  color: #fff;
  /*background-color: #31373d;*/
  background: linear-gradient(to right, #f09819, #ff512f);
  text-align: center;
  margin: auto 0;
  padding: 0 1em;
  font-size: 14px;
  line-height: 2.5;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-left: none;
  border-radius: 0.25rem;
  transition: 200ms linear;

  display: flex;
  padding: 0px;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-evenly;
}

.activador-detalles label {
  cursor: pointer;
}

.circulo-marcador {
  width: 20px;
  height: 20px;
  background-color: #8f8f8f;
  border-radius: 50px;
  opacity: 0.4;
  margin: auto;
}

.circulo-marcador::before {
  border-radius: 50%;
  background-color: #fff;
  bottom: 14px;
  content: "";
  height: 12px;
  left: 16px;
  position: absolute;
  transition: 400ms;
  width: 12px;
}

.circulo-marcador.activo::before {
  bottom: 15px !important;
  background-color: white;
}

.activador-detalles.activo {
  bottom: 0%;
}

.activador-detalles.activo > .circulo-marcador {
  opacity: 1;
}

.activador-detalles.activo > .circulo-marcador::before {
  bottom: 14px;
  left: 16px;
}

.activador-detalles:hover,
.activador-detalles.activo {
  cursor: pointer;
  background: linear-gradient(to right, #f09819, #ff512f);
  border: none;
  text-shadow: #000 1px 1px 3px;
}

.activador-detalles.activo {
  width: 3.25em;
}

.contenedor-cards-apuestas {
  margin-left: 0em;
}

.contenedor-cards-apuestas:last-child {
  padding-bottom: 150px;
}

/*
.contenedor-cards-apuestas>div.bg-dark.callout-apuestas.callout.callout-success:nth-child(1) {
    border-top-left-radius: 0px;
}
*/

.activadores-formato {
  font-size: 10px;
  text-transform: uppercase;
  background-color: #31373de8;
  font-weight: 600;
}

.activadores-parley-directas {
  font-size: 10px;
  text-transform: uppercase;
  background-color: #31373de8;
  font-weight: 600;
}

.contenedor-activadores-parley-directas {
  flex: content;
  margin: auto 0;
  text-align: center;
}

/*
.activadores-parley-directas.activo {
    background-color: #fe562e;
    border-left-color: #fe562e;
    border-top-color: #fe562e;
    border-bottom-color: #fe562e;
    border-right-color: #fe562e;
    text-shadow: #464646 1px 1px 2px;
}
*/
.activadores-parley-directas.activo {
  background-color: #fff;
  border-left-color: #423e3a;
  border-top-color: #423e3a;
  border-bottom-color: #423e3a;
  border-right-color: #423e3a;
  color: #423e3a;
}

.label-title-header {
  text-transform: uppercase;
  font-weight: 300;
  flex: content;
  margin: auto 0;
  text-align: center;
  font-weight: 500;
}

.label-title-header-web {
  font-size: 0.9em;
}

.label-title-header-movil {
  font-size: 1em;
}

.detalles-calculos {
  display: flex;
  flex-direction: row;
  height: 3.65em;
  margin: 0;
  padding: 0;
}

.cantidad-jugadas {
  display: flex;
  flex-direction: row;
  height: 2.5em;
  margin: 0;
  padding: 0;
  padding-top: 3px;
  align-content: center;
  justify-content: space-between;
}

.cantidad-section {
  margin: 0;
  flex-wrap: wrap;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.txt-error {
  color: #ff522f;
  text-shadow: #000 1px 1px 4px;
  text-transform: uppercase;
  text-align: center;
}

.label-monto {
  text-align: center;
  text-transform: uppercase;
  font-weight: 400 !important;
  font-size: 0.9em;
  width: 45%;
  height: 50px;
  padding: 0;
  margin: 0;

  align-items: center;
  align-content: flex-start;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}

.label-title-header {
  width: 100%;
}

.jugadas {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.jugadas > a {
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 0.8em;
}

.jugadas > a > span.badge.bg-teal {
  font-size: 0.95em;
}

.icon-trash-item {
  margin: 0px;
  width: 10%;
  display: inline-flex;
  max-height: 35px;
  flex-basis: content;
  min-width: 35px;
  align-content: center;
  align-items: center;
}

.icon-trash-item > i {
  /*color: #110f0f !important;*/
  color: #999 !important;
  width: 100%;
}

.icon-trash-item > i:hover {
  color: #eb6060 !important;
}

.btn.btn-app.bg-danger.icon-trash > i.fas.fa-trash:hover {
  color: #eb6060 !important;
}

/*
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #007bff linear-gradient(180deg, #268ffd, #011f62) repeat-x !important;
  background: #0e6503 linear-gradient(180deg, #28a745, #6afd00) repeat-x !important;
  */
.btn-generar {
  text-transform: uppercase;
  font-weight: 500;
  background: #007bff linear-gradient(260deg, #268ffd, #011f62) repeat-x !important;
  border: none;
  color: #fff;
  opacity: 1;
  height: 100%;
  border-radius: 7px;
  padding: 0 5px;
  font-size: 0.7em;
}

/* background: #0e6503 linear-gradient(180deg, #28a745, #6afd00) repeat-x !important; */
.btn-generar:hover {
  background: #007bff linear-gradient(260deg, #268ffd, #011f62) repeat-x !important;
}

.btn-generar-web {
  font-size: 13px;
}

.btn-generar-movil {
  font-size: 0.7em;
  padding: 0;
}

.fc-prev-button.btn.btn-dark.activadores-formato.activo:hover {
  color: #fff;
  text-shadow: 1px 1px 4px #000;
}

.fc-prev-button.btn.btn-dark.activadores-formato:hover {
  background-color: #fff;
  color: #423e3a;
}

text-option .icon-trash {
  width: 10%;
  text-align: center;
}

.icon-trash {
  color: #212529;
}

.icon-trash:hover {
  /*
    color: #e10001;
    text-shadow: #b5a7ab 1px 1px 3px;*/
  color: #fff;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input.input-cantidad::-webkit-outer-spin-button,
input.input-cantidad::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.input-cantidad[type="number"] {
  -moz-appearance: textfield;
}

.input-cantidad {
  background-color: #212529;
  border: 1px #b9b4b4 solid;
  color: #fff;
  border: none;
  text-align: right;
  padding-right: 10px;
  border-radius: 7px;
  font-weight: 500;
  width: 30%;
  height: 40px;
  font-size: 1.5em;
}

.input-cantidad:active,
.input-cantidad:focus,
.input-cantidad:focus-visible {
  background-color: #424a53;
  /*
      background: linear-gradient(to right, #212529, #343a40)
    */
  border: none;
  outline: none;
}

/*
  color: #110f0f !important;
  background-color: #212529 !important;
*/
.btn.btn-app.bg-danger.icon-trash {
  background-color: transparent !important;
  color: #999999 !important;
  max-width: 10px;
  margin: 0px;
  padding: 0px;
  font-size: 0.5em;
  font-weight: 100;
  height: 40px;
  border: none;
}

.btn.btn-app.bg-danger.icon-trash:hover {
  color: #fff !important;
  border-color: #fff;
}

.btn.btn-app.bg-danger.icon-trash > i {
  margin: 0px;
  padding: 0px;
  border: 0px;
  color: #999;
}

.tiene-apuestas {
  opacity: 1 !important;
  background-color: #fff;
}

.tiene-apuestas:before {
  background-color: #3cb202 !important;
}

.contenedor-activadores-formatos,
.formato-montos {
  flex: content;
  margin: auto 0;
  text-align: center;
  height: 100%;
}

.formato-montos {
  width: 45%;
}

.jugadas a.btn > span.badge {
  font-size: 0.9em;
}

.contenedor-activadores-formatos > div.btn-group {
  /* height: 20px; */
  height: 100%;
  width: 100%;
}

.contenedor-activadores-formatos > div.btn-group button {
  padding: 0 1em;
  border: none;
  background-origin: none;
}

.contenedor-activadores-formatos > div.btn-group button.activo {
  background: #007bff linear-gradient(180deg, #268ffd, #011f62) repeat-x !important;
  color: #fff;
  border: none;
  border-radius: 0px;
  font-size: 0.7em;
  padding: 0.7em;
}

.cantidad-premio {
  margin-bottom: 0px;
  height: 30px;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px;
  background-image: linear-gradient(to right, #0e6503, #6afd00);
}

.label-title-premio {
  width: 50%;
  text-align: right;
  font-weight: 300;
  height: 100%;
  margin: 0px;
  font-size: 1.2em;
}

.label-cantidad-premio {
  margin: 0px;
  width: 50%;
  text-align: center;
  text-shadow: #000 1px 1px 3px;
  font-size: 1.3em;
  line-height: 1.2;
  font-weight: 800;
}

/* Responsive */
@media screen and (max-width: 900px) {
  .contenedor-detalles {
    width: 100vw;
    /*padding-top: 7em!important;*/
    top: 0%;
  }

  .hasVBar {
    padding: 1.2em !important;
  }

  .btn.float-right.close.btn-secondary.btn-destroy {
    right: 0.1em;
    /*1.4em;*/
  }

  .contenedor-cards-apuestas {
    margin-left: 0.8em;
  }

  .cantidad-section {
    margin-right: 0.8em;
  }

  .detalles-calculos {
    margin-left: 0.8em;
  }
}
</style>
