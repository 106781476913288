<template>
  <div v-if="this.getCriptoWallet">
    <b-modal id="modalCriptoWallet" size="md" hide-footer @show="recargarCripto(getCriptoWallet.id)" @hide="restart()">
      <!-- <div class="text-center" v-if="this.mensajeUser">
        <dir>
          <img :src="this.getCriptoWallet.msg" width="300" height="200" v-if="this.getCriptoWallet.msg" />
          <img :src="this.getCriptoWallet.url" width="300" height="300" v-else />
        </dir>
        <div class="mt-5 text-right">
          <b-button class="bg-primary" v-if="this.getCriptoWallet.msg" @click="$bvModal.hide('modalCriptoWallet')"
            >Ok</b-button
          >
          <div v-else>
            <b-button class="bg-danger mr-2" @click="$bvModal.hide('modalCriptoWallet')">Cancelar</b-button>
            <b-button class="bg-primary" @click="recargarCripto(getCriptoWallet.id)">Recarga</b-button>
          </div>
        </div>
      </div> -->
      <div>
        <center v-html="this.getDataWallet.codigo"></center>
        <div v-if="this.idWallet == 'BINANCE'">
          <div class="text-justify" style="text-indent: 1em">
            <P
              >Los depositos en binance deben efectuarse en USDT, sin fracciones el id para enviar el deposito es:
              <b>280255809</b>
            </P>
          </div>

          <div class="d-flex flex-column input-group my-3">
            <div class="my-2">
              <input class="form-control" type="text" v-model="montoDivisa" placeholder="Monto" />
            </div>
            <div class="my-2">
              <input class="form-control" type="text" v-model="ref" placeholder="Número de Órden" />
            </div>
            <div class="my-2">
              <!-- <input class="form-control" type="text" :value="this.fecha" placeholder="Fecha y Hora" /> -->
              <datetime
                v-model="fechaHora"
                value-zone="America/Caracas"
                input-class="text-center"
                input-style="height: 2.2em; border: 1px solid #DFDDDD"
                type="datetime"
                placeholder="Fecha"
                :phrases="textoCalendario"
              ></datetime>
            </div>

            <div class="text-center my-2">
              <button type="button" v-on:click="submit" class="btn btn-primary boton-color-fondo">
                NOTIFICAR DEPOSITO
              </button>
            </div>
          </div>
        </div>
        <div v-else class="input-group mt-4">
          <input class="form-control" type="text" :value="this.codigo" />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"><i class="fa fa-copy"></i></span>
          </div>
          <div class="input-group my-3">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input class="form-control" type="text" @input="calcularCripto($event)" />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
            <div class="input-group-prepend">
              <span class="input-group-text">Bitcoin</span>
            </div>
            <input class="form-control" type="text" v-model="this.montoEquivalente" disabled />
          </div>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">TXID</span>
            </div>
            <input class="form-control" type="text" v-model="ref" />
          </div>

          <div class="row text-center my-5">
            <div class="col-6">
              <button type="button" v-on:click="submit" class="btn btn-primary boton-color-fondo">Notificar</button>
            </div>
            <div class="col-6">
              <button type="button" class="btn btn-danger btn-cancelar" @click="$bvModal.hide('modalCriptoWallet')">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Datetime } from "vue-datetime";
import Swal from "sweetalert2";

export default {
  name: "ModalCriptoWallet",
  data() {
    return {
      mensajeUser: true,
      codigo: "",
      ref: "",
      montoEquivalente: 0,
      montoDivisa: null,
      idWallet: null,
      fechaHora: null,
      textoCalendario: { ok: "Ok", cancel: "Cancelar" }
    };
  },
  computed: {
    ...mapGetters(["getCriptoWallet", "getDataWallet", "getUser"])
  },
  components: {
    datetime: Datetime
  },
  methods: {
    restart() {
      this.mensajeUser = true;
      this.montoEquivalente = 0;
      this.idWallet = "";
      this.montoDivisa = null;
      this.fechaHora = null;
      this.ref = "";
    },
    calcularCripto(e) {
      this.montoEquivalente = e.target.value / this.getDataWallet.rate;
    },
    copyText() {
      navigator.clipboard.writeText(this.codigo);
    },
    async recargarCripto(id) {
      this.$store.commit("showLoader");

      this.idWallet = id;

      await this.$store.dispatch("getCriptoAsync", {
        url: `api/crypto?wallet=${id}`,
        set: "setDataWallet",
        name: "wallet"
      });

      this.$store.commit("hideLoader");
      this.mensajeUser = false;
      this.codigo = this.getDataWallet.address;
    },
    async submit() {
      if (this.ref == "") {
        Swal.fire("Error!", "Debe incluir la referencia", "error");
        return false;
      }
      let data = {
        coinid: this.idWallet,
        address: "234234",
        ref: this.ref
      };

      const fechaHora = new Date(this.fechaHora);

      let fechaTime = fechaHora.getTime() / 1000;

      if (this.getCriptoWallet.type == 2) {
        if (!this.montoDivisa || this.montoDivisa == 0) {
          Swal.fire("Error!", "Debe incluir monto", "error");
          return false;
        }
        if (!this.fechaHora) {
          Swal.fire("Error!", "Debe incluir la fecha", "error");
          return false;
        }

        data.usd_amount = this.montoDivisa;
        data.timestamp = fechaTime;
      } else {
        if (!this.montoEquivalente || this.montoEquivalente == 0) {
          Swal.fire("Error!", "Debe incluir monto", "error");
          return false;
        }
      }

      await this.$store.dispatch("createPost", {
        url: `api/crypto/deposit`,
        data
      });
    }
  }
};
</script>
<style scoped>
table {
  margin: 0 auto;
}
.btn-cancelar {
  width: 80%;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}
</style>
