<template>
  <div
    style="
      background-image: url('../img/Sorteos/tradicionales_background.jpg');
      background-repeat: no-repeat;
      background-size: cover;
    "
  >
    <section
      class="animate__animated animate__backInUp"
      :class="{
        // 'light-theme bg-light': this.getThemelight,
        'container-sorteos-tradicionales': !this.getIsMobilResolution,
        'container-sorteos-tradicionales-movil': this.getIsMobilResolution
      }"
    >
      <FullLoader :cargando="!this.isLoad"></FullLoader>
      <div
        :class="{
          degrado4: true,
          tabla: this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          movil: this.getVisualWidth < 500
        }"
        v-if="!urlIframe"
      >
        <span class="titulo">LOTERIA ANIMALITOS TRADICIONALES</span>
      </div>

      <div
        :class="{
          degrado4: true,
          tabla: this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          movil: this.getVisualWidth < 500
        }"
        v-if="urlIframe"
      >
        <button class="btn-red btn-regresar" @click="back()">
          <span><i class="fa fa-angle-double-left"></i> {{ $t("regresar") }}</span>
        </button>
      </div>

      <div
        :class="{
          'flex-container': this.getVisualWidth > 1280,
          'flex-container-tabla': this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          'flex-container-movil': this.getVisualWidth < 500
        }"
        v-if="!urlIframe"
      >
        <!-- <div>
        <img
          src="/img/Sorteos/dupleta_millonaria.png"
          class="img-fluid boder-img"
          alt=""
          @click="openIframe(1)"
          style="cursor: pointer"
        />
      </div> -->

        <div class="row text-center mt-5">
          <!--  antes src="/img/Sorteos/lotto_activo.png" -->
          <div class="col-sm-12 col-lg-4">
            <img
              data-toggle="modal"
              data-target="#exampleModal"
              v-lazy="'/img/Sorteos/lotto_activo.png'"
              class="img-fluid border-img"
              alt=""
              :class="{ 'img-size': this.getIsMobilResolution }"
              @click="openIframe(2)"
              style="cursor: pointer"
            />
          </div>
          <div class="col-lg-4">
            <!-- src="/img/Sorteos/Logo_TrioActivo.png" -->
            <img
              v-lazy="'/img/Sorteos/Logo_TrioActivon.png'"
              class="img-fluid border-img"
              alt=""
              :class="{ 'img-size': this.getIsMobilResolution }"
              @click="openIframe(3)"
              style="cursor: pointer"
            />
          </div>
          <div class="col-lg-4">
            <!-- src="/img/Sorteos/Logo_TrioActivo.png" -->
            <img
              v-lazy="'/img/Sorteos/lottoactivord.png'"
              class="img-fluid border-img"
              alt=""
              :class="{ 'img-size': this.getIsMobilResolution }"
              @click="openIframe(43)"
              style="cursor: pointer"
            />
          </div>
        </div>
      </div>

      <div
        v-if="this.validar"
        class="text-center bg-light"
        :class="{ 'w-25': !this.getIsMobilResolution }"
        style="margin-top: 20% !important"
      >
        <div>
          <img v-lazy="'/img/mantenimiento/mantenimiento.png'" width="350" height="250" />
          <div class="form-inline text-danger">
            <!-- <i class="fa fa-angle-double-left fa-lg mx-3"></i> -->
            <!-- <h3><b>Volver</b></h3> -->
          </div>
        </div>
      </div>

      <!-- LOTTO ACTIVO -->
      <iframe
        v-if="iframe == 2"
        :src="this.getUrliframe.url"
        width="100%"
        frameborder="0"
        :class="{ styleIframe: !this.getIsMobilResolution, 'top-movil': this.getIsMobilResolution }"
        @load="cargoIframe"
      >
      </iframe>

      <!-- TRIOACTIVO -->
      <iframe
        v-if="iframe == 3"
        :src="this.getUrliframe.url"
        width="100%"
        frameborder="0"
        :class="{ styleIframe: !this.getIsMobilResolution, 'top-movil': this.getIsMobilResolution }"
        @load="cargoIframe"
      >
      </iframe>

      <!-- TRIOACTIVO RD -->
      <iframe
        v-if="iframe == 43"
        :src="this.getUrliframe.url"
        width="100%"
        frameborder="0"
        :class="{ styleIframe: !this.getIsMobilResolution, 'top-movil': this.getIsMobilResolution }"
        @load="cargoIframe"
      >
      </iframe>
      <AvisoIframe v-if="!this.validar" :urlnotice="this.urlnotice" />
    </section>
    <!-- <span class="label-btn-iframe">Pantalla completa <i class="fa fa-arrow-right"></i></span> -->
    <button
      data-v-70a0ee36=""
      data-toggle="tooltip"
      title="Pantalla completa"
      class="btn-base border-green btn-iframe"
      v-if="this.iframe != 0"
      @click="abriIframeNuevaPagina"
    >
      <i data-v-70a0ee36="" class="fas fa-expand-arrows-alt" aria-hidden="true"></i>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FullLoader from "../components/FullLoader.vue";
import AvisoIframe from "../components/AvisoIframe.vue";
import openIframen from "../helpers/openIframen";
export default {
  name: "SorteosTradicionales",
  components: {
    FullLoader,
    AvisoIframe
  },
  data() {
    return {
      urlIframe: false,
      iframe: 0,
      isLoad: true,
      validar: false,
      urlnotice: null
    };
  },
  computed: {
    ...mapGetters(["getIsMobilResolution", "getThemelight", "getVisualWidth", "getUrliframe"])
  },
  methods: {
    async openIframe(valor) {
      localStorage.setItem("url_iframe", null);
      this.urlIframe = true;
      // this.isLoad = false;
      if (valor == 1) {
        this.iframe = 1;
      }
      if (valor == 2) {
        await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=43&provider=triples" });
        this.validar = this.getUrliframe.url ? false : true;
        this.iframe = 2;
        localStorage.setItem("url_iframe", this.getUrliframe.url);
      }
      if (valor == 3) {
        await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=45&provider=triples" });
        this.validar = this.getUrliframe.url ? false : true;
        this.iframe = 3;
        localStorage.setItem("url_iframe", this.getUrliframe.url);
      }
      if (valor == 43) {
        await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=43&provider=triples" });
        this.validar = this.getUrliframe.url ? false : true;
        this.iframe = 43;
        localStorage.setItem("url_iframe", this.getUrliframe.url);
      }
      if (this.getUrliframe.notice) {
        this.urlnotice = this.getUrliframe.notice;
        this.$bvModal.show("modal-aviso");
      }
    },
    cargoIframe() {
      this.isLoad = true;
    },
    back() {
      this.urlIframe = false;
      this.iframe = 0;
      this.validar = false;
    },
    abriIframeNuevaPagina() {
      openIframen();
      this.back();
    }
  },
  watch: {
    getThemelight(t) {
      if (t && this.getIsMobilResolution) {
        document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
      } else {
        document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
      }
    }
  }
};
</script>
<style scoped>
.container-interno-contenido {
  padding-top: 5em;
}
.container-sorteos-tradicionales {
  display: flex;
  flex-flow: column;
  padding: 0;
  min-height: 130vh;
}

.container-sorteos-tradicionales-movil {
  min-height: 10vh;
}
.styleIframe {
  border: 0;
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 0px;
  overflow: hidden;
}

.top-movil {
  /* top: 300px; */
}
section {
  /*margin-top: 30%;*/
  height: 95vh;
}

.degrado4 {
  border: 1px solid #333333;
  font-family: Poppins, sans-serif !important;
  /* border-radius: 5px; */
  color: #fffefd;
  font-size: 22px;
  font-weight: bold;
  padding: 20px;
  text-align: center;
  background: #101010;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 4.3em;
}

/* .subtitulo_ {
  color: #cacaca;
  font-size: 14px;
  color: #fff;
  font-size: 16px;
  font-family: Poppins, sans-serif !important;
} */

/* .col-centrado {
  float: none;
  margin: 0 auto;
} */
/* .subtitulo_light {
  color: #202020;
} */

.border-img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  transition: 1s;
  /* border: 3px solid #b32505; */
}
.border-img:hover {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  box-shadow: 0px 0px 15px 15px #ec731e;
  -webkit-box-shadow: 0px 0px 15px 15px #ec731e;
}
.img-size {
  width: 40vh;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
/* .flex-container > div {
  width: 300px;
  margin: 10px;
  text-align: center;
} */

.flex-container-movil {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-container-movil > div {
  width: 10em !important;
  margin: 10px !important;
  text-align: center !important;
}

.tabla {
  margin-top: 120px;
  font-size: 1.5em;
}

.movil {
  margin-top: 1em;
  font-size: 1.5em;
}
.flex-container-tabla {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.flex-container-tabla > div {
  width: 15em !important;
  margin: 10px !important;
  text-align: center !important;
}
.flex-container-movil {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
/* .flex-container-movil > div {
  width: 10em;
  margin: 10px;
  text-align: center;
} */
.titulo {
  font-size: 18px;
  font-family: Poppins, sans-serif !important;
}

@media screen and (max-width: 359px) {
  .flex-container-movil > div {
    width: 8em !important;
  }
}
@media screen and (max-width: 1100px) {
  .styleIframe {
    top: 190px;
  }
}

@media screen and (max-width: 359px) {
  .styleIframe {
    top: 160px;
  }
}
</style>
