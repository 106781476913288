<template>
  <div class="text-center animate__animated animate__backInUp top-banca">
    <div class="row">
      <div class="col-1 text-left">
        <i
          class="fas fa-chevron-left fa-lg text-light ml-2"
          :class="{ 'text-dark': this.getThemelight }"
          v-on:click="back()"
        ></i>
      </div>
      <div class="col-10 text-center">
        <div class="text-title" :class="{ 'text-light': !getThemelight, 'light-h1': getThemelight }">
          Cuentas bancarias
        </div>
      </div>
    </div>

    <div v-if="this.results && this.results.length > 0" class="mt-2">
      <div class="card" v-for="cuenta of this.results" v-bind:key="cuenta.id">
        <div class="row border text-center bg-title">
          <div class="text-center">
            <b>Banco</b>
          </div>
        </div>
        <div class="row">
          <div class="text-center" v-if="cuenta.banco">
            {{ cuenta.banco }}
          </div>
          <div class="text-center" v-else>
            {{ cuenta.gateway }}
          </div>
        </div>
        <div class="row border text-center bg-title">
          <div class="text-center">
            <b>Cuenta</b>
          </div>
        </div>
        <div class="row">
          <div class="text-center">
            {{ cuenta.account }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card my-3">
        <div class="card-title">
          <h5 class="text-center mt-4">No hay cuentas agregadas</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CuentasBancariasMovil",
  data() {
    return {
      results: null
    };
  },
  computed: {
    ...mapGetters(["getAccounts", "getThemelight", "getUser", "getCriptoAccounts"])
  },
  methods: {
    back() {
      this.$bvModal.show("modal-cuenta");
    }
  },
  async mounted() {
    if (this.getUser.Coin.id > 1) {
      await this.$store.dispatch("getCriptoAsync", {
        url: "api/crypto?accounts",
        set: "setCriptoAccounts",
        name: "accounts"
      });
      this.results = this.getCriptoAccounts.filter(element => element.enable == 1);
    } else {
      await this.$store.dispatch("getAccountsClientAsync");
      this.results = this.getAccounts;
    }

    console.log("Results: ", this.results);
  }
};
</script>
<style scoped>
.top-banca {
  margin-top: 150px !important;
  height: auto;
  min-height: 50vh;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
</style>
