var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'animate__animated animate__backInUp': !this.modal,
    'light-theme bg-light': this.getThemelight,
    'soporte-movil': this.getIsMobilResolution,
    'container-terminos': !this.getIsMobilResolution && !this.modal
  }},[_c('div',{class:{ container: !_vm.getIsMobilResolution, 'container-movil': _vm.getIsMobilResolution }},[_c('div',{staticClass:"row mt-5"},[(!_vm.isMobilResolution())?_c('div',{staticClass:"col-3"}):_vm._e(),_c('div',{staticClass:"col-12"},[(!_vm.modal)?_c('h1',{class:{
            'title-orange-web': !_vm.getIsMobilResolution,
            'title-ayudas pt-2': _vm.getIsMobilResolution,
            'mb-4': true,
            letra: true,
            'text-center': true,
            'dark-h1': !this.getThemelight,
            'light-h1': this.getThemelight
          },staticStyle:{"color":"white","font-weight":"700"}},[_vm._v("\n          "+_vm._s(_vm.$t("terminos_y_condiciones"))+"\n        ")]):_vm._e(),(_vm.modal)?_c('h1',{class:{
            'title-orange-web py-3': true,
            'mb-4': true,
            letra: true,
            'text-center': true,
            'light-h1': true
          },staticStyle:{"color":"white","font-weight":"700"}},[_vm._v("\n          "+_vm._s(_vm.$t("terminos_y_condiciones"))+"\n        ")]):_vm._e(),_c('div',{staticClass:"text-justify font5 container-html",class:{ 'mx-2': _vm.getIsMobilResolution }},[_c('AcordionCMS',{attrs:{"data":this.getTerminosCondiciones}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }