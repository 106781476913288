<template>
  <div :class="{ 'container-game-details': true, light: this.getThemelight }">
    <button
      type="button"
      :class="{ 'btn-base border-green btn-jugadas': true, isOpen: isOpen, 'btn-light-blue': getThemelight }"
      v-on:click="clickOpen"
    >
      <span>
        <i v-show="!isOpen" class="fa fa-plus float-left ml-2" style="line-height: 1.5em;"></i>
        <i v-show="isOpen" class="fa fa-minus float-left ml-2" style="line-height: 1.5em;"></i> JUGADAS</span
      >
    </button>
    <div
      v-show="isOpen"
      :class="{
        'row center-block mt-2 color-bg-container-table animate__animated animate__fadeIn pb-4': true,
        light: getThemelight
      }"
    >
      <div class="col-md-4 padding1">
        <table
          id="taq"
          :class="{
            'table-logros table mt-4 table-striped': true,
            'table-light': getThemelight,
            'table-dark': !getThemelight
          }"
          style="overflow: hidden;"
        >
          <thead>
            <tr>
              <th colspan="6" class="th-title">Juegos Completo (FT)</th>
            </tr>
          </thead>
          <tbody>
            <!-- baseball -->
            <template v-if="this.gameDetails.sport_id == 1 && this.gameDetails.odds && this.gameDetails.odds.FT">
              <tr v-if="this.existPositionIn('FT', 10)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  Totales Local (1)
                </th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 10)" class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX10X') }"
                  v-on:click="clickApuesta('FTX10X')"
                >
                  <span class="i1">&nbsp;Alta&nbsp;</span>{{ this.getPosition("FT", 10) }} ({{
                    this.getPosition("FT", 10, "factor")
                  }})
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX11X') }"
                  v-on:click="clickApuesta('FTX11X')"
                >
                  <span class="i1">&nbsp;Baja&nbsp;</span>{{ this.getPosition("FT", 11) }} ({{
                    this.getPosition("FT", 11, "factor")
                  }})
                </td>
              </tr>
              <tr v-if="this.existPositionIn('FT', 12)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  Totales Local (2)
                </th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 12)" class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX12X') }"
                  v-on:click="clickApuesta('FTX12X')"
                >
                  <span class="i1">&nbsp;Alta&nbsp;</span>{{ this.getPosition("FT", 12) }} ({{
                    this.getPosition("FT", 12, "factor")
                  }})
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX13X') }"
                  v-on:click="clickApuesta('FTX13X')"
                >
                  <span class="i1">&nbsp;Baja&nbsp;</span>{{ this.getPosition("FT", 13) }} ({{
                    this.getPosition("FT", 13, "factor")
                  }})
                </td>
              </tr>
              <tr v-if="this.existPositionIn('FT', 14)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  Carrera 1er ining
                </th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 14)" class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX14X') }"
                  v-on:click="clickApuesta('FTX14X')"
                >
                  <span class="i1">&nbsp;Si&nbsp;</span>{{ this.getPosition("FT", 14) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX15X') }"
                  v-on:click="clickApuesta('FTX15X')"
                >
                  <span class="i1">&nbsp;No&nbsp;</span>{{ this.getPosition("FT", 15) }}
                </td>
              </tr>
              <tr v-if="this.existPositionIn('FT', 17)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  Anota primero
                </th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 17)" class="encabezado">
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX16X') }"
                  v-on:click="clickApuesta('FTX16X')"
                >
                  <span class="i1">&nbsp;Vis (1)&nbsp;</span>{{ this.getPosition("FT", 16) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX17X') }"
                  v-on:click="clickApuesta('FTX17X')"
                >
                  <span class="i1">&nbsp;Loc (2)&nbsp;</span>{{ this.getPosition("FT", 17) }}
                </td>
              </tr>
              <tr v-if="this.existPositionIn('FT', 18)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  Totales CHE
                </th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 18)" class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX18X') }"
                  v-on:click="clickApuesta('FTX18X')"
                >
                  <span class="i1">&nbsp;Alta&nbsp;</span>{{ this.getPosition("FT", 18) }} ({{
                    this.getPosition("FT", 18, "factor")
                  }})
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX19X') }"
                  v-on:click="clickApuesta('FTX19X')"
                >
                  <span class="i1">&nbsp;Baja&nbsp;</span>{{ this.getPosition("FT", 19) }} ({{
                    this.getPosition("FT", 19, "factor")
                  }})
                </td>
              </tr>
              <tr v-if="this.existPositionIn('FT', 119)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  Extraining
                </th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 119)" class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX119X') }"
                  v-on:click="clickApuesta('FTX119X')"
                >
                  <span class="i1">&nbsp;Si&nbsp;</span>{{ this.getPosition("FT", 119) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX120X') }"
                  v-on:click="clickApuesta('FTX120X')"
                >
                  <span class="i1">&nbsp;No&nbsp;</span>{{ this.getPosition("FT", 120) }}
                </td>
              </tr>
              <tr v-if="this.existPositionIn('FT', 132)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  Marcador Par-Impar
                </th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 132)" class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX132X') }"
                  v-on:click="clickApuesta('FTX132X')"
                >
                  <span class="i1">&nbsp;Par&nbsp;</span>{{ this.getPosition("FT", 132) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX133X') }"
                  v-on:click="clickApuesta('FTX133X')"
                >
                  <span class="i1">&nbsp;Impar&nbsp;</span>{{ this.getPosition("FT", 133) }}
                </td>
              </tr>
            </template>
            <!-- fubol -->
            <template v-if="this.gameDetails.sport_id == 2">
              <tr v-if="this.existPositionIn('FT', 12)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  Totales Local (1)
                </th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 12)" class="encabezado">
                <td
                  colspan="3"
                  id="FTX12X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX12X') }"
                  v-on:click="clickApuesta('FTX12X')"
                >
                  <span class="i1">&nbsp;Alta&nbsp;</span>{{ this.getPosition("FT", 12) }} ({{
                    this.getPosition("FT", 12, "factor")
                  }})
                </td>
                <td
                  colspan="3"
                  id="FTX13X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX13X') }"
                  v-on:click="clickApuesta('FTX13X')"
                >
                  <span class="i1">&nbsp;Baja&nbsp;</span>{{ this.getPosition("FT", 13) }} ({{
                    this.getPosition("FT", 13, "factor")
                  }})
                </td>
              </tr>
              <tr v-if="this.existPositionIn('FT', 10)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales Visitante (2)</th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 10)" class="encabezado">
                <td
                  colspan="3"
                  id="FTX10X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX10X') }"
                  v-on:click="clickApuesta('FTX10X')"
                >
                  <span class="i1">&nbsp;Alta&nbsp;</span>{{ this.getPosition("FT", 10) }} ({{
                    this.getPosition("FT", 10, "factor")
                  }})
                </td>
                <td
                  colspan="3"
                  id="FTX11X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX11X') }"
                  v-on:click="clickApuesta('FTX11X')"
                >
                  <span class="i1">&nbsp;Baja&nbsp;</span>{{ this.getPosition("FT", 11) }} ({{
                    this.getPosition("FT", 11, "factor")
                  }})
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Anota primero</th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 17)" class="encabezado">
                <td
                  colspan="2"
                  id="FTX17X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX17X') }"
                  v-on:click="clickApuesta('FTX17X')"
                >
                  <span class="i1">&nbsp;Loc (1)&nbsp;</span>
                  {{ this.getPosition("FT", 17) }}
                </td>
                <td
                  colspan="2"
                  id="FTX114X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX114X') }"
                  v-on:click="clickApuesta('FTX114X')"
                >
                  <span class="i1">&nbsp;Ning&nbsp;</span>
                  {{ this.getPosition("FT", 114) }}
                </td>
                <td
                  colspan="2"
                  id="FTX16X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX16X') }"
                  v-on:click="clickApuesta('FTX16X')"
                >
                  <span class="i1">&nbsp;Vis (2)&nbsp;</span>
                  {{ this.getPosition("FT", 16) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Marcador correcto</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX32X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX32X') }"
                  v-on:click="clickApuesta('FTX32X')"
                >
                  <span class="i1">&nbsp;0:0&nbsp;</span>
                  {{ this.getPosition("FT", 32) }}
                </td>
                <td
                  colspan="2"
                  id="FTX43X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX43X') }"
                  v-on:click="clickApuesta('FTX43X')"
                >
                  <span class="i1">&nbsp;1:4&nbsp;</span>
                  {{ this.getPosition("FT", 43) }}
                </td>
                <td
                  colspan="2"
                  id="FTX35X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX35X') }"
                  v-on:click="clickApuesta('FTX35X')"
                >
                  <span class="i1">&nbsp;3:3&nbsp;</span>
                  {{ this.getPosition("FT", 35) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX36X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX36X') }"
                  v-on:click="clickApuesta('FTX36X')"
                >
                  <span class="i1">&nbsp;0:1&nbsp;</span>
                  {{ this.getPosition("FT", 36) }}
                </td>
                <td
                  colspan="2"
                  id="FTX21X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX21X') }"
                  v-on:click="clickApuesta('FTX21X')"
                >
                  <span class="i1">&nbsp;2:0&nbsp;</span>
                  {{ this.getPosition("FT", 21) }}
                </td>
                <td
                  colspan="2"
                  id="FTX29X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX29X') }"
                  v-on:click="clickApuesta('FTX29X')"
                >
                  <span class="i1">&nbsp;3:4&nbsp;</span>
                  {{ this.getPosition("FT", 29) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX37X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX37X') }"
                  v-on:click="clickApuesta('FTX37X')"
                >
                  <span class="i1">&nbsp;0:2&nbsp;</span>
                  {{ this.getPosition("FT", 37) }}
                </td>
                <td
                  colspan="2"
                  id="FTX22X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX22X') }"
                  v-on:click="clickApuesta('FTX22X')"
                >
                  <span class="i1">&nbsp;2:1&nbsp;</span>
                  {{ this.getPosition("FT", 22) }}
                </td>
                <td
                  colspan="2"
                  id="FTX26X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX26X') }"
                  v-on:click="clickApuesta('FTX26X')"
                >
                  <span class="i1">&nbsp;4:0&nbsp;</span>
                  {{ this.getPosition("FT", 26) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX39X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX39X') }"
                  v-on:click="clickApuesta('FTX39X')"
                >
                  <span class="i1">&nbsp;0:3&nbsp;</span>
                  {{ this.getPosition("FT", 39) }}
                </td>
                <td
                  colspan="2"
                  id="FTX34X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX34X') }"
                  v-on:click="clickApuesta('FTX34X')"
                >
                  <span class="i1">&nbsp;2:2&nbsp;</span>
                  {{ this.getPosition("FT", 34) }}
                </td>
                <td
                  colspan="2"
                  id="FTX27X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX27X') }"
                  v-on:click="clickApuesta('FTX27X')"
                >
                  <span class="i1">&nbsp;4:1&nbsp;</span>
                  {{ this.getPosition("FT", 27) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX42X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX42X') }"
                  v-on:click="clickApuesta('FTX42X')"
                >
                  <span class="i1">&nbsp;0:4&nbsp;</span>
                  {{ this.getPosition("FT", 42) }}
                </td>
                <td
                  colspan="2"
                  id="FTX41X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX41X') }"
                  v-on:click="clickApuesta('FTX41X')"
                >
                  <span class="i1">&nbsp;2:3&nbsp;</span>
                  {{ this.getPosition("FT", 41) }}
                </td>
                <td
                  colspan="2"
                  id="FTX28X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX28X') }"
                  v-on:click="clickApuesta('FTX28X')"
                >
                  <span class="i1">&nbsp;4:2&nbsp;</span>
                  {{ this.getPosition("FT", 28) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX20X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX20X') }"
                  v-on:click="clickApuesta('FTX20X')"
                >
                  <span class="i1">&nbsp;1:0&nbsp;</span>
                  {{ this.getPosition("FT", 20) }}
                </td>
                <td
                  colspan="2"
                  id="FTX44X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX44X') }"
                  v-on:click="clickApuesta('FTX44X')"
                >
                  <span class="i1">&nbsp;2:4&nbsp;</span>
                  {{ this.getPosition("FT", 44) }}
                </td>
                <td
                  colspan="2"
                  id="FTX30X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX30X') }"
                  v-on:click="clickApuesta('FTX30X')"
                >
                  <span class="i1">&nbsp;4:3&nbsp;</span>
                  {{ this.getPosition("FT", 30) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX33X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX33X') }"
                  v-on:click="clickApuesta('FTX33X')"
                >
                  <span class="i1">&nbsp;1:1&nbsp;</span>
                  {{ this.getPosition("FT", 33) }}
                </td>
                <td
                  colspan="2"
                  id="FTX23X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX23X') }"
                  v-on:click="clickApuesta('FTX23X')"
                >
                  <span class="i1">&nbsp;3:0&nbsp;</span>
                  {{ this.getPosition("FT", 23) }}
                </td>
                <td
                  colspan="2"
                  id="FTX31X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX31X') }"
                  v-on:click="clickApuesta('FTX31X')"
                >
                  <span class="i1">&nbsp;4:4&nbsp;</span>
                  {{ this.getPosition("FT", 31) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX38X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX38X') }"
                  v-on:click="clickApuesta('FTX38X')"
                >
                  <span class="i1">&nbsp;1:2&nbsp;</span>
                  {{ this.getPosition("FT", 38) }}
                </td>
                <td
                  colspan="2"
                  id="FTX24X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX24X') }"
                  v-on:click="clickApuesta('FTX24X')"
                >
                  <span class="i1">&nbsp;3:1&nbsp;</span>
                  {{ this.getPosition("FT", 24) }}
                </td>
                <td
                  colspan="2"
                  id="FTX48X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX48X') }"
                  v-on:click="clickApuesta('FTX48X')"
                >
                  <span class="i1">&nbsp;Otro&nbsp;</span>
                  {{ this.getPosition("FT", 48) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX40X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX40X') }"
                  v-on:click="clickApuesta('FTX40X')"
                >
                  <span class="i1">&nbsp;1:3&nbsp;</span>
                  {{ this.getPosition("FT", 40) }}
                </td>
                <td
                  colspan="2"
                  id="FTX25X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX25X') }"
                  v-on:click="clickApuesta('FTX25X')"
                >
                  <span class="i1">&nbsp;3:2&nbsp;</span>
                  {{ this.getPosition("FT", 25) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Gana en HT/FT</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX51X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX51X') }"
                  v-on:click="clickApuesta('FTX51X')"
                >
                  <span class="i1">&nbsp;1/1&nbsp;</span>
                  {{ this.getPosition("FT", 51) }}
                </td>
                <td
                  colspan="2"
                  id="FTX52X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX52X') }"
                  v-on:click="clickApuesta('FTX52X')"
                >
                  <span class="i1">&nbsp;X/1&nbsp;</span>
                  {{ this.getPosition("FT", 52) }}
                </td>
                <td
                  colspan="2"
                  id="FTX53X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX53X') }"
                  v-on:click="clickApuesta('FTX53X')"
                >
                  <span class="i1">&nbsp;2/1&nbsp;</span>
                  {{ this.getPosition("FT", 53) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX54X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX54X') }"
                  v-on:click="clickApuesta('FTX54X')"
                >
                  <span class="i1">&nbsp;1/X&nbsp;</span>
                  {{ this.getPosition("FT", 54) }}
                </td>
                <td
                  colspan="2"
                  id="FTX55X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX55X') }"
                  v-on:click="clickApuesta('FTX55X')"
                >
                  <span class="i1">&nbsp;X/X&nbsp;</span>
                  {{ this.getPosition("FT", 55) }}
                </td>
                <td
                  colspan="2"
                  id="FTX56X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX56X') }"
                  v-on:click="clickApuesta('FTX56X')"
                >
                  <span class="i1">&nbsp;2/X&nbsp;</span>
                  {{ this.getPosition("FT", 56) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX57X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX57X') }"
                  v-on:click="clickApuesta('FTX57X')"
                >
                  <span class="i1">&nbsp;1/2&nbsp;</span>
                  {{ this.getPosition("FT", 57) }}
                </td>
                <td
                  colspan="2"
                  id="FTX58X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX58X') }"
                  v-on:click="clickApuesta('FTX58X')"
                >
                  <span class="i1">&nbsp;X/2&nbsp;</span>
                  {{ this.getPosition("FT", 58) }}
                </td>
                <td
                  colspan="2"
                  id="FTX59X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX59X') }"
                  v-on:click="clickApuesta('FTX59X')"
                >
                  <span class="i1">&nbsp;2/2&nbsp;</span>
                  {{ this.getPosition("FT", 59) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Goles Local (1)</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX65X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX65X') }"
                  v-on:click="clickApuesta('FTX65X')"
                >
                  <span class="i1">&nbsp;0&nbsp;</span>
                  {{ this.getPosition("FT", 65) }}
                </td>
                <td
                  colspan="2"
                  id="FTX66X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX66X') }"
                  v-on:click="clickApuesta('FTX66X')"
                >
                  <span class="i1">&nbsp;1&nbsp;</span>
                  {{ this.getPosition("FT", 66) }}
                </td>
                <td
                  colspan="2"
                  id="FTX67X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX67X') }"
                  v-on:click="clickApuesta('FTX67X')"
                >
                  <span class="i1">&nbsp;2&nbsp;</span>
                  {{ this.getPosition("FT", 67) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX68X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX68X') }"
                  v-on:click="clickApuesta('FTX68X')"
                >
                  <span class="i1">&nbsp;3+&nbsp;</span>
                  {{ this.getPosition("FT", 68) }}
                </td>
                <th colspan="4"></th>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Goles Visitante (2)</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX69X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX69X') }"
                  v-on:click="clickApuesta('FTX69X')"
                >
                  <span class="i1">&nbsp;0&nbsp;</span>
                  {{ this.getPosition("FT", 69) }}
                </td>
                <td
                  colspan="2"
                  id="FTX70X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX70X') }"
                  v-on:click="clickApuesta('FTX70X')"
                >
                  <span class="i1">&nbsp;1&nbsp;</span>
                  {{ this.getPosition("FT", 70) }}
                </td>
                <td
                  colspan="2"
                  id="FTX71X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX71X') }"
                  v-on:click="clickApuesta('FTX71X')"
                >
                  <span class="i1">&nbsp;2&nbsp;</span>
                  {{ this.getPosition("FT", 71) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX72X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX72X') }"
                  v-on:click="clickApuesta('FTX72X')"
                >
                  <span class="i1">&nbsp;3+&nbsp;</span>
                  {{ this.getPosition("FT", 72) }}
                </td>
                <th colspan="4"></th>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Total exacto de goles</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX77X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX77X') }"
                  v-on:click="clickApuesta('FTX77X')"
                >
                  <span class="i1">&nbsp;0&nbsp;</span>
                  {{ this.getPosition("FT", 77) }}
                </td>
                <td
                  colspan="2"
                  id="FTX78X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX78X') }"
                  v-on:click="clickApuesta('FTX78X')"
                >
                  <span class="i1">&nbsp;1&nbsp;</span>
                  {{ this.getPosition("FT", 78) }}
                </td>
                <td
                  colspan="2"
                  id="FTX79X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX79X') }"
                  v-on:click="clickApuesta('FTX79X')"
                >
                  <span class="i1">&nbsp;2&nbsp;</span>
                  {{ this.getPosition("FT", 79) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX80X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX80X') }"
                  v-on:click="clickApuesta('FTX80X')"
                >
                  <span class="i1">&nbsp;3&nbsp;</span>
                  {{ this.getPosition("FT", 80) }}
                </td>
                <td
                  colspan="2"
                  id="FTX81X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX81X') }"
                  v-on:click="clickApuesta('FTX81X')"
                >
                  <span class="i1">&nbsp;4&nbsp;</span>
                  {{ this.getPosition("FT", 81) }}
                </td>
                <td
                  colspan="2"
                  id="FTX82X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX82X') }"
                  v-on:click="clickApuesta('FTX82X')"
                >
                  <span class="i1">&nbsp;5&nbsp;</span>
                  {{ this.getPosition("FT", 82) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX83X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX83X') }"
                  v-on:click="clickApuesta('FTX83X')"
                >
                  <span class="i1">&nbsp;6+&nbsp;</span>
                  {{ this.getPosition("FT", 83) }}
                </td>
                <th colspan="4"></th>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  1er gol en intervalos de 10 min
                </th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX87X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX87X') }"
                  v-on:click="clickApuesta('FTX87X')"
                >
                  <span class="i1">&nbsp;Ning.&nbsp;</span>
                  {{ this.getPosition("FT", 87) }}
                </td>
                <td
                  colspan="2"
                  id="FTX88X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX88X') }"
                  v-on:click="clickApuesta('FTX88X')"
                >
                  <span class="i1">&nbsp;1-10&nbsp;</span>
                  {{ this.getPosition("FT", 88) }}
                </td>
                <td
                  colspan="2"
                  id="FTX89X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX85X') }"
                  v-on:click="clickApuesta('FTX85X')"
                >
                  <span class="i1">&nbsp;11-20&nbsp;</span>
                  {{ this.getPosition("FT", 85) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX90X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX90X') }"
                  v-on:click="clickApuesta('FTX90X')"
                >
                  <span class="i1">&nbsp;21-30&nbsp;</span>
                  {{ this.getPosition("FT", 90) }}
                </td>
                <td
                  colspan="2"
                  id="FTX91X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX91X') }"
                  v-on:click="clickApuesta('FTX91X')"
                >
                  <span class="i1">&nbsp;31-40&nbsp;</span>
                  {{ this.getPosition("FT", 91) }}
                </td>
                <td
                  colspan="2"
                  id="FTX92X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX92X') }"
                  v-on:click="clickApuesta('FTX92X')"
                >
                  <span class="i1">&nbsp;41-50&nbsp;</span>
                  {{ this.getPosition("FT", 92) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX93X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX93X') }"
                  v-on:click="clickApuesta('FTX93X')"
                >
                  <span class="i1">&nbsp;51-60&nbsp;</span>
                  {{ this.getPosition("FT", 93) }}
                </td>
                <td
                  colspan="2"
                  id="FTX94X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX94X') }"
                  v-on:click="clickApuesta('FTX94X')"
                >
                  <span class="i1">&nbsp;61-70&nbsp;</span>
                  {{ this.getPosition("FT", 94) }}
                </td>
                <td
                  colspan="2"
                  id="FTX95X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX95X') }"
                  v-on:click="clickApuesta('FTX95X')"
                >
                  <span class="i1">&nbsp;71-80&nbsp;</span>
                  {{ this.getPosition("FT", 95) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="FTX96X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX96X') }"
                  v-on:click="clickApuesta('FTX96X')"
                >
                  <span class="i1">&nbsp;81-90&nbsp;</span>
                  {{ this.getPosition("FT", 96) }}
                </td>
                <th colspan="2"></th>
                <th colspan="2"></th>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales/A ganar 3 Way</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX152X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX152X') }"
                  v-on:click="clickApuesta('FTX152X')"
                >
                  <span class="i1">&nbsp;Baja 1.5/Loc&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 152) }}
                </td>
                <td
                  colspan="3"
                  id="FTX155X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX93X') }"
                  v-on:click="clickApuesta('FTX155X')"
                >
                  <span class="i1">&nbsp;Alta 1.5/Loc&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 155) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX153X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX153X') }"
                  v-on:click="clickApuesta('FTX153X')"
                >
                  <span class="i1">&nbsp;Baja 1.5/Emp&nbsp;</span>{{ this.getPosition("FT", 153) }}
                </td>
                <td
                  colspan="3"
                  id="FTX156X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX156X') }"
                  v-on:click="clickApuesta('FTX156X')"
                >
                  <span class="i1">&nbsp;Alta 1.5/Emp&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 156) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX154X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX154X') }"
                  v-on:click="clickApuesta('FTX154X')"
                >
                  <span class="i1">&nbsp;Baja 1.5/Vis&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 154) }}
                </td>
                <td
                  colspan="3"
                  id="FTX157X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX157X') }"
                  v-on:click="clickApuesta('FTX157X')"
                >
                  <span class="i1">&nbsp;Alta 1.5/Vis&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 157) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX158X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX158X') }"
                  v-on:click="clickApuesta('FTX158X')"
                >
                  <span class="i1">&nbsp;Baja 2.5/Loc&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 158) }}
                </td>
                <td
                  colspan="3"
                  id="FTX161X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX161X') }"
                  v-on:click="clickApuesta('FTX161X')"
                >
                  <span class="i1">&nbsp;Alta 2.5/Loc&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 161) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX159X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX159X') }"
                  v-on:click="clickApuesta('FTX159X')"
                >
                  <span class="i1">&nbsp;Baja 2.5/Emp&nbsp;</span>
                  {{ this.getPosition("FT", 159) }}
                </td>
                <td
                  colspan="3"
                  id="FTX162X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX162X') }"
                  v-on:click="clickApuesta('FTX162X')"
                >
                  <span class="i1">&nbsp;Alta 2.5/Emp&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 162) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX160X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX160X') }"
                  v-on:click="clickApuesta('FTX160X')"
                >
                  <span class="i1">&nbsp;Baja 2.5/Vis&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 160) }}
                </td>
                <td
                  colspan="3"
                  id="FTX163X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX162X') }"
                  v-on:click="clickApuesta('FTX162X')"
                >
                  <span class="i1">&nbsp;Alta 2.5/Vis&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 162) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX164X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX164X') }"
                  v-on:click="clickApuesta('FTX164X')"
                >
                  <span class="i1">&nbsp;Baja 3.5/Loc&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 164) }}
                </td>
                <td
                  colspan="3"
                  id="FTX167X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX167X') }"
                  v-on:click="clickApuesta('FTX167X')"
                >
                  <span class="i1">&nbsp;Alta 3.5/Loc&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 167) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX165X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX165X') }"
                  v-on:click="clickApuesta('FTX165X')"
                >
                  <span class="i1">&nbsp;Baja 3.5/Emp&nbsp;</span>
                  {{ this.getPosition("FT", 165) }}
                </td>
                <td
                  colspan="3"
                  id="FTX168X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX168X') }"
                  v-on:click="clickApuesta('FTX168X')"
                >
                  <span class="i1">&nbsp;Alta 3.5/Emp&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 168) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX166X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX166X') }"
                  v-on:click="clickApuesta('FTX166X')"
                >
                  <span class="i1">&nbsp;Baja 3.5/Vis&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 166) }}
                </td>
                <td
                  colspan="3"
                  id="FTX169X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX169X') }"
                  v-on:click="clickApuesta('FTX169X')"
                >
                  <span class="i1">&nbsp;Alta 3.5/Vis&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 169) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX170X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX170X') }"
                  v-on:click="clickApuesta('FTX170X')"
                >
                  <span class="i1">&nbsp;Baja 4.5/Loc&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 170) }}
                </td>
                <td
                  colspan="3"
                  id="FTX173X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX173X') }"
                  v-on:click="clickApuesta('FTX173X')"
                >
                  <span class="i1">&nbsp;Alta 4.5/Loc&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 173) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX171X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX171X') }"
                  v-on:click="clickApuesta('FTX171X')"
                >
                  <span class="i1">&nbsp;Baja 4.5/Emp&nbsp;</span>{{ this.getPosition("FT", 171) }}
                </td>
                <td
                  colspan="3"
                  id="FTX174X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX174X') }"
                  v-on:click="clickApuesta('FTX174X')"
                >
                  <span class="i1">&nbsp;Alta 4.5/Emp&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 174) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX172X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX172X') }"
                  v-on:click="clickApuesta('FTX172X')"
                >
                  <span class="i1">&nbsp;Baja 4.5/Vis&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 172) }}
                </td>
                <td
                  colspan="3"
                  id="FTX175X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX175X') }"
                  v-on:click="clickApuesta('FTX175X')"
                >
                  <span class="i1">&nbsp;Alta 4.5/Vis&nbsp;&nbsp;</span>
                  {{ this.getPosition("FT", 175) }}
                </td>
              </tr>
            </template>
            <!-- hokey -->
            <template v-if="this.gameDetails.sport_id == 5">
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Anota primero</th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 17)" class="encabezado">
                <td
                  colspan="2"
                  id="FTX17X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX17X') }"
                  v-on:click="clickApuesta('FTX17X')"
                >
                  <span class="i1">&nbsp;Vis (1)&nbsp;</span>
                  {{ this.getPosition("FT", 16) }}
                </td>
                <td
                  colspan="2"
                  id="FTX114X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX114X') }"
                  v-on:click="clickApuesta('FTX114X')"
                >
                  <span class="i1">&nbsp;Ning&nbsp;</span>
                  {{ this.getPosition("FT", 114) }}
                </td>
                <td
                  colspan="2"
                  id="FTX16X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX16X') }"
                  v-on:click="clickApuesta('FTX16X')"
                >
                  <span class="i1">&nbsp;Loc (2)&nbsp;</span>
                  {{ this.getPosition("FT", 17) }}
                </td>
              </tr>
              <tr v-if="this.existPositionIn('FT', 18)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales CHE</th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 18)" class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX18X') }"
                  v-on:click="clickApuesta('FTX18X')"
                >
                  <span class="i1">&nbsp;Vis (1)&nbsp;</span>
                  {{ this.getPosition("FT", 18) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX19X') }"
                  v-on:click="clickApuesta('FTX19X')"
                >
                  <span class="i1">&nbsp;Ning&nbsp;</span>
                  {{ this.getPosition("FT", 19) }}
                </td>
              </tr>
              <tr v-if="this.existPositionIn('FT', 62)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  Doble Chance
                </th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 62)" class="encabezado">
                <td
                  colspan="2"
                  id="FTX62X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX62X') }"
                  v-on:click="clickApuesta('FTX62X')"
                >
                  <span class="i1">&nbsp;(1X)&nbsp;</span>
                  {{ this.getPosition("FT", 62) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX63X') }"
                  v-on:click="clickApuesta('FTX63X')"
                >
                  <span class="i1">&nbsp;(12)&nbsp;</span>
                  {{ this.getPosition("FT", 63) }}
                </td>
                <td
                  colspan="2"
                  id="HTX64X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX64X') }"
                  v-on:click="clickApuesta('FTX64X')"
                >
                  <span class="i1">&nbsp;(X2)&nbsp;</span>
                  {{ this.getPosition("FT", 64) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Ambos anotan</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX49X') }"
                  v-on:click="clickApuesta('FTX49X')"
                >
                  <span class="i1">&nbsp;Si(GG)&nbsp;</span>
                  {{ this.getPosition("FT", 49) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX50X') }"
                  v-on:click="clickApuesta('FTX50X')"
                >
                  <span class="i1">&nbsp;No(NG)&nbsp;</span>
                  {{ this.getPosition("FT", 50) }}
                </td>
              </tr>
            </template>
            <!-- Global en Tabla 1 en taq -->
            <template v-if="this.existPositionIn('FT', 60)">
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Marcador Par/Impar</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="FTX60X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX60X') }"
                  v-on:click="clickApuesta('FTX60X')"
                >
                  <span class="i1">&nbsp;Par&nbsp;</span>
                  {{ this.getPosition("FT", 60) }}
                </td>
                <td
                  colspan="3"
                  id="FTX61X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX61X') }"
                  v-on:click="clickApuesta('FTX61X')"
                >
                  <span class="i1">&nbsp;Impar&nbsp;</span>
                  {{ this.getPosition("FT", 61) }}
                </td>
              </tr>
            </template>
            <template v-if="this.existPositionIn('FT', 129)">
              <tr v-if="this.existPositionIn('FT', 129)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  1 X 2 (Sin OT)
                </th>
              </tr>
              <tr v-if="this.existPositionIn('FT', 129)" class="encabezado">
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX129X') }"
                  v-on:click="clickApuesta('FTX129X')"
                >
                  <span class="i1">&nbsp;1&nbsp;</span>({{ this.getPosition("FT", 129) }})
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX130X') }"
                  v-on:click="clickApuesta('FTX130X')"
                >
                  <span class="i1">&nbsp;X&nbsp;</span>({{ this.getPosition("FT", 130) }})
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX131X') }"
                  v-on:click="clickApuesta('FTX131X')"
                >
                  <span class="i1">&nbsp;2&nbsp;</span>({{ this.getPosition("FT", 131) }})
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="col-md-4 padding1">
        <table
          id="taq2"
          :class="{
            'table-logros table mt-4 table-striped': true,
            'table-light': getThemelight,
            'table-dark': !getThemelight
          }"
        >
          <thead>
            <tr>
              <th colspan="6" class="th-title">1ra Mitad (HT)</th>
            </tr>
          </thead>
          <tbody>
            <!-- baseball -->
            <template v-if="this.gameDetails.sport_id == 1">
              <template
                v-if="this.gameDetails.odds && this.gameDetails.odds.HT && this.gameDetails.odds.HT[116] != undefined"
              >
                <tr>
                  <th colspan="6" class="th-exotics i2">1er Ining 1 X 2</th>
                </tr>
                <tr class="encabezado">
                  <td
                    colspan="2"
                    :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX116X') }"
                    v-on:click="clickApuesta('HTX116X')"
                    v-bind:id="'HTX116X' + this.gameDetails.id"
                  >
                    <span class="i1">&nbsp;1&nbsp;</span>
                    {{ this.getPosition("HT", 116) }}
                  </td>
                  <td
                    colspan="2"
                    :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX117X') }"
                    v-on:click="clickApuesta('HTX117X')"
                    v-bind:id="'HTX117X' + this.gameDetails.id"
                  >
                    <span class="i1">&nbsp;X&nbsp;</span>
                    {{ this.getPosition("HT", 117) }}
                  </td>
                  <td
                    colspan="2"
                    :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX118X') }"
                    v-on:click="clickApuesta('HTX118X')"
                    v-bind:id="'HTX118X' + this.gameDetails.id"
                  >
                    <span class="i1">&nbsp;2&nbsp;</span>
                    {{ this.getPosition("HT", 118) }}
                  </td>
                </tr>
              </template>
              <template
                v-if="this.gameDetails.odds && this.gameDetails.odds.HT && this.gameDetails.odds.HT[127] != undefined"
              >
                <tr>
                  <th colspan="6" class="th-exotics i2">Totales 1er Ining</th>
                </tr>
                <tr class="encabezado">
                  <td
                    colspan="3"
                    :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX127X'), td2col: true }"
                    v-on:click="clickApuesta('HTX127X')"
                    v-bind:id="'HTX127X' + this.gameDetails.id"
                  >
                    <span class="i1">&nbsp;1&nbsp;</span>
                    {{ this.getPosition("HT", 127) }}
                  </td>
                  <td
                    colspan="3"
                    :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX128X'), td2col: true }"
                    v-on:click="clickApuesta('HTX128X')"
                    v-bind:id="'HTX128X' + this.gameDetails.id"
                  >
                    <span class="i1">&nbsp;X&nbsp;</span>
                    {{ this.getPosition("HT", 128) }}
                  </td>
                </tr>
              </template>
            </template>
            <!-- fubol -->
            <template v-if="this.gameDetails.sport_id == 2">
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">1 X 2</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="HTX1X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX1X') }"
                  v-on:click="clickApuesta('HTX1X')"
                >
                  <span class="i1">&nbsp;1&nbsp;</span>
                  {{ this.getPosition("HT", 1) }}
                </td>
                <td
                  colspan="2"
                  id="HTX7X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX7X') }"
                  v-on:click="clickApuesta('HTX7X')"
                >
                  <span class="i1">&nbsp;X&nbsp;</span>
                  {{ this.getPosition("HT", 7) }}
                </td>
                <td
                  colspan="2"
                  id="HTX2X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX2X') }"
                  v-on:click="clickApuesta('HTX2X')"
                >
                  <span class="i1">&nbsp;2&nbsp;</span>
                  {{ this.getPosition("HT", 2) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">HDP</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="HTX3X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX3X') }"
                  v-on:click="clickApuesta('HTX3X')"
                >
                  <span class="i1">&nbsp;1&nbsp;</span> {{ this.getPosition("HT", 3) }} ({{
                    this.getPosition("HT", 3, "factor")
                  }})
                </td>
                <td
                  colspan="3"
                  id="HTX4X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX4X') }"
                  v-on:click="clickApuesta('HTX4X')"
                >
                  <span class="i1">&nbsp;2&nbsp;</span> {{ this.getPosition("HT", 4) }} ({{
                    this.getPosition("HT", 4, "factor")
                  }})
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="HTX5X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX5X') }"
                  v-on:click="clickApuesta('HTX5X')"
                >
                  <span class="i1">&nbsp;Alta&nbsp;</span> {{ this.getPosition("HT", 5) }} ({{
                    this.getPosition("HT", 5, "factor")
                  }})
                </td>
                <td
                  colspan="3"
                  id="HTX6X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX6X') }"
                  v-on:click="clickApuesta('HTX6X')"
                >
                  <span class="i1">&nbsp;Baja&nbsp;</span> {{ this.getPosition("HT", 6) }} ({{
                    this.getPosition("HT", 6, "factor")
                  }})
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales Local (1)</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="HTX12X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX12X') }"
                  v-on:click="clickApuesta('HTX12X')"
                >
                  <span class="i1">&nbsp;Alta&nbsp;</span> {{ this.getPosition("HT", 12) }} ({{
                    this.getPosition("HT", 12, "factor")
                  }})
                </td>
                <td
                  colspan="3"
                  id="HTX13X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX13X') }"
                  v-on:click="clickApuesta('HTX13X')"
                >
                  <span class="i1">&nbsp;Baja&nbsp;</span> {{ this.getPosition("HT", 13) }} ({{
                    this.getPosition("HT", 13, "factor")
                  }})
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales Visitante (2)</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="HTX10X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX10X') }"
                  v-on:click="clickApuesta('HTX10X')"
                >
                  <span class="i1">&nbsp;Alta&nbsp;</span> {{ this.getPosition("HT", 10) }} ({{
                    this.getPosition("HT", 10)
                  }})
                </td>
                <td
                  colspan="3"
                  id="HTX11X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX11X') }"
                  v-on:click="clickApuesta('HTX11X')"
                >
                  <span class="i1">&nbsp;Baja&nbsp;</span> {{ this.getPosition("HT", 11) }} ({{
                    this.getPosition("HT", 11, "factor")
                  }})
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Ambos anotan</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX49X') }"
                  v-on:click="clickApuesta('HTX49X')"
                >
                  <span class="i1">&nbsp;Si(GG)&nbsp;</span>
                  {{ this.getPosition("HT", 49) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX50X') }"
                  v-on:click="clickApuesta('HTX50X')"
                >
                  <span class="i1">&nbsp;No(NG)&nbsp;</span>
                  {{ this.getPosition("HT", 50) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Marcador Correcto</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="HTX32X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX32X') }"
                  v-on:click="clickApuesta('HTX32X')"
                >
                  <span class="i1">&nbsp;0:0&nbsp;</span>
                  {{ this.getPosition("HT", 32) }}
                </td>
                <td
                  colspan="2"
                  id="HTX20X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX20X') }"
                  v-on:click="clickApuesta('HTX20X')"
                >
                  <span class="i1">&nbsp;1:0&nbsp;</span>
                  {{ this.getPosition("HT", 20) }}
                </td>
                <td
                  colspan="2"
                  id="HTX21X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX21X') }"
                  v-on:click="clickApuesta('HTX21X')"
                >
                  <span class="i1">&nbsp;2:0&nbsp;</span>
                  {{ this.getPosition("HT", 21) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="HTX36X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX36X') }"
                  v-on:click="clickApuesta('HTX36X')"
                >
                  <span class="i1">&nbsp;0:1&nbsp;</span>
                  {{ this.getPosition("HT", 36) }}
                </td>
                <td
                  colspan="2"
                  id="HTX33X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX33X') }"
                  v-on:click="clickApuesta('HTX33X')"
                >
                  <span class="i1">&nbsp;1:1&nbsp;</span>
                  {{ this.getPosition("HT", 33) }}
                </td>
                <td
                  colspan="2"
                  id="HTX22X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX22X') }"
                  v-on:click="clickApuesta('HTX22X')"
                >
                  <span class="i1">&nbsp;2:1&nbsp;</span>
                  {{ this.getPosition("HT", 22) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="HTX37X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX37X') }"
                  v-on:click="clickApuesta('HTX37X')"
                >
                  <span class="i1">&nbsp;0:2&nbsp;</span>
                  {{ this.getPosition("HT", 37) }}
                </td>
                <td
                  colspan="2"
                  id="HTX38X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX38X') }"
                  v-on:click="clickApuesta('HTX38X')"
                >
                  <span class="i1">&nbsp;1:2&nbsp;</span>
                  {{ this.getPosition("HT", 38) }}
                </td>
                <td
                  colspan="2"
                  id="HTX34X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX34X') }"
                  v-on:click="clickApuesta('HTX34X')"
                >
                  <span class="i1">&nbsp;2:2&nbsp;</span>
                  {{ this.getPosition("HT", 34) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Total exacto de goles</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="HTX77X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX77X') }"
                  v-on:click="clickApuesta('HTX77X')"
                >
                  <span class="i1">&nbsp;0&nbsp;</span>
                  {{ this.getPosition("HT", 77) }}
                </td>
                <td
                  colspan="2"
                  id="HTX78X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX78X') }"
                  v-on:click="clickApuesta('HTX78X')"
                >
                  <span class="i1">&nbsp;1&nbsp;</span>
                  {{ this.getPosition("HT", 78) }}
                </td>
                <td
                  colspan="2"
                  id="HTX84X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX84X') }"
                  v-on:click="clickApuesta('HTX84X')"
                >
                  <span class="i1">&nbsp;2+&nbsp;</span>
                  {{ this.getPosition("HT", 84) }}
                </td>
              </tr>
              <tr>
                <th
                  colspan="6"
                  title=""
                  data-toggle="tooltip"
                  data-placement="top"
                  data-original-title="Gana ó Empata el Local(1X) / Gana algún equipo (12) / Gana ó Empata el Visitante(X2)"
                  class="th-exotics i2"
                  style="padding-top: 4px !important;"
                >
                  Doble Chance
                </th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="HTX62X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX62X') }"
                  v-on:click="clickApuesta('HTX62X')"
                >
                  <span class="i1">&nbsp;(1X)&nbsp;</span>
                  {{ this.getPosition("HT", 62) }}
                </td>
                <td
                  colspan="2"
                  id="HTX63X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX63X') }"
                  v-on:click="clickApuesta('HTX63X')"
                >
                  <span class="i1">&nbsp;(12)&nbsp;</span>
                  {{ this.getPosition("HT", 63) }}
                </td>
                <td
                  colspan="2"
                  id="HTX64X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX64X') }"
                  v-on:click="clickApuesta('HTX64X')"
                >
                  <span class="i1">&nbsp;(X2)&nbsp;</span>
                  {{ this.getPosition("HT", 64) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales/A ganar 3 Way</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="HTX152X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX152X') }"
                  v-on:click="clickApuesta('HTX152X')"
                >
                  <span class="i1">&nbsp;Baja 1.5/Loc&nbsp;</span>{{ this.getPosition("HT", 152) }}
                </td>
                <td
                  colspan="3"
                  id="HTX155X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX155X') }"
                  v-on:click="clickApuesta('HTX155X')"
                >
                  <span class="i1">&nbsp;Alta 1.5/Loc&nbsp;</span>{{ this.getPosition("HT", 155) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="HTX153X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX153X') }"
                  v-on:click="clickApuesta('HTX153X')"
                >
                  <span class="i1">&nbsp;Baja 1.5/Emp&nbsp;</span>{{ this.getPosition("HT", 153) }}
                </td>
                <td
                  colspan="3"
                  id="HTX156X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX156X') }"
                  v-on:click="clickApuesta('HTX156X')"
                >
                  <span class="i1">&nbsp;Alta 1.5/Emp&nbsp;</span>{{ this.getPosition("HT", 156) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="HTX154X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX154X') }"
                  v-on:click="clickApuesta('HTX154X')"
                >
                  <span class="i1">&nbsp;Baja 1.5/Vis&nbsp;</span>{{ this.getPosition("HT", 154) }}
                </td>
                <td
                  colspan="3"
                  id="HTX157X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX157X') }"
                  v-on:click="clickApuesta('HTX157X')"
                >
                  <span class="i1">&nbsp;Alta 1.5/Vis&nbsp;</span>{{ this.getPosition("HT", 157) }}
                </td>
              </tr>
            </template>
            <!-- hokey -->
            <template v-if="this.gameDetails.sport_id == 5">
              <tr v-if="this.existPositionIn('HT', 17)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Anota primero</th>
              </tr>
              <tr v-if="this.existPositionIn('HT', 17)" class="encabezado">
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX17X') }"
                  v-on:click="clickApuesta('HTX17X')"
                >
                  <span class="i1">&nbsp;Vis (1)&nbsp;</span>
                  {{ this.getPosition("HT", 16) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX114X') }"
                  v-on:click="clickApuesta('HTX114X')"
                >
                  <span class="i1">&nbsp;Ning&nbsp;</span>
                  {{ this.getPosition("HT", 114) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX16X') }"
                  v-on:click="clickApuesta('HTX16X')"
                >
                  <span class="i1">&nbsp;Loc (2)&nbsp;</span>
                  {{ this.getPosition("HT", 17) }}
                </td>
              </tr>
              <tr v-if="this.existPositionIn('HT', 18)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales CHE</th>
              </tr>
              <tr v-if="this.existPositionIn('HT', 18)" class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX18X') }"
                  v-on:click="clickApuesta('HTX18X')"
                >
                  <span class="i1">&nbsp;Vis (1)&nbsp;</span>
                  {{ this.getPosition("HT", 18) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX19X') }"
                  v-on:click="clickApuesta('HTX19X')"
                >
                  <span class="i1">&nbsp;Ning&nbsp;</span>
                  {{ this.getPosition("HT", 19) }}
                </td>
              </tr>
              <tr v-if="this.existPositionIn('HT', 63)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  Doble Chance
                </th>
              </tr>
              <tr v-if="this.existPositionIn('HT', 63)" class="encabezado">
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX62X') }"
                  v-on:click="clickApuesta('HTX62X')"
                >
                  <span class="i1">&nbsp;(1X)&nbsp;</span>
                  {{ this.getPosition("HT", 62) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX63X') }"
                  v-on:click="clickApuesta('HTX63X')"
                >
                  <span class="i1">&nbsp;(12)&nbsp;</span>
                  {{ this.getPosition("HT", 63) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX64X') }"
                  v-on:click="clickApuesta('HTX64X')"
                >
                  <span class="i1">&nbsp;(X2)&nbsp;</span>
                  {{ this.getPosition("HT", 64) }}
                </td>
              </tr>
              <tr v-if="this.existPositionIn('HT', 49)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Ambos anotan</th>
              </tr>
              <tr v-if="this.existPositionIn('HT', 49)" class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX49X') }"
                  v-on:click="clickApuesta('HTX49X')"
                >
                  <span class="i1">&nbsp;Si(GG)&nbsp;</span>
                  {{ this.getPosition("HT", 49) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX50X') }"
                  v-on:click="clickApuesta('HTX50X')"
                >
                  <span class="i1">&nbsp;No(NG)&nbsp;</span>
                  {{ this.getPosition("HT", 50) }}
                </td>
              </tr>
            </template>
            <!-- Global en Tabla 2 en taq -->
            <template v-if="this.existPositionIn('HT', 60)">
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Marcador Par/Impar</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX60X') }"
                  v-on:click="clickApuesta('HTX60X')"
                >
                  <span class="i1">&nbsp;Par&nbsp;</span>
                  {{ this.getPosition("HT", 60) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX61X') }"
                  v-on:click="clickApuesta('HTX61X')"
                >
                  <span class="i1">&nbsp;Impar&nbsp;</span>
                  {{ this.getPosition("HT", 61) }}
                </td>
              </tr>
            </template>
            <template v-if="this.existPositionIn('HT', 148)">
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">1 X 2</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX148X') }"
                  v-on:click="clickApuesta('HTX148X')"
                >
                  <span class="i1">&nbsp;1&nbsp;</span>
                  {{ this.getPosition("HT", 60) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX149X') }"
                  v-on:click="clickApuesta('HTX149X')"
                >
                  <span class="i1">&nbsp;X&nbsp;</span>
                  {{ this.getPosition("HT", 149) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('HTX150X') }"
                  v-on:click="clickApuesta('HTX150X')"
                >
                  <span class="i1">&nbsp;2&nbsp;</span>
                  {{ this.getPosition("HT", 150) }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="col-md-4 padding1">
        <table
          id="taq3"
          :class="{
            'table-logros table mt-4 table-striped': true,
            'table-light': getThemelight,
            'table-dark': !getThemelight
          }"
        >
          <thead>
            <tr>
              <th colspan="6" class="th-title">2da Mitad (ST)</th>
            </tr>
          </thead>
          <tbody>
            <!-- games with 3th col (futbol 2) -->
            <template v-if="this.gameDetails.sport_id == 2">
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">1 X 2</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="STX1X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX1X') }"
                  v-on:click="clickApuesta('STX1X')"
                >
                  <span class="i1">&nbsp;1&nbsp;</span>
                  {{ this.getPosition("ST", 1) }}
                </td>
                <td
                  colspan="2"
                  id="STX7X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX7X') }"
                  v-on:click="clickApuesta('STX7X')"
                >
                  <span class="i1">&nbsp;X&nbsp;</span>
                  {{ this.getPosition("ST", 7) }}
                </td>
                <td
                  colspan="2"
                  id="STX2X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX2X') }"
                  v-on:click="clickApuesta('STX2X')"
                >
                  <span class="i1">&nbsp;2&nbsp;</span>
                  {{ this.getPosition("ST", 2) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="STX5X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX5X') }"
                  v-on:click="clickApuesta('STX5X')"
                >
                  <span class="i1">&nbsp;Alta&nbsp;</span> {{ this.getPosition("ST", 5) }} ({{
                    this.getPosition("ST", 5, "factor")
                  }})
                </td>
                <td
                  colspan="3"
                  id="STX6X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX6X') }"
                  v-on:click="clickApuesta('STX6X')"
                >
                  <span class="i1">&nbsp;Baja&nbsp;</span>
                  {{ this.getPosition("ST", 6) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales Local (1)</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="STX12X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX12X') }"
                  v-on:click="clickApuesta('STX12X')"
                >
                  <span class="i1">&nbsp;Alta&nbsp;</span> {{ this.getPosition("ST", 12) }} ({{
                    this.getPosition("ST", 12, "factor")
                  }})
                </td>
                <td
                  colspan="3"
                  id="STX13X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX13X') }"
                  v-on:click="clickApuesta('STX13X')"
                >
                  <span class="i1">&nbsp;Baja&nbsp;</span> {{ this.getPosition("ST", 13) }} ({{
                    this.getPosition("ST", 13, "factor")
                  }})
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales Visitante (2)</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="STX10X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX10X') }"
                  v-on:click="clickApuesta('STX10X')"
                >
                  <span class="i1">&nbsp;Alta&nbsp;</span> {{ this.getPosition("ST", 10) }} ({{
                    this.getPosition("ST", 10, "factor")
                  }})
                </td>
                <td
                  colspan="3"
                  id="STX11X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX11X') }"
                  v-on:click="clickApuesta('STX11X')"
                >
                  <span class="i1">&nbsp;Baja&nbsp;</span> {{ this.getPosition("ST", 11) }} ({{
                    this.getPosition("ST", 11, "factor")
                  }})
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Ambos anotan</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="STX49X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX49X') }"
                  v-on:click="clickApuesta('STX49X')"
                >
                  <span class="i1">&nbsp;Si(GG)&nbsp;</span>
                  {{ this.getPosition("ST", 49) }}
                </td>
                <td
                  colspan="3"
                  id="STX50X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX50X') }"
                  v-on:click="clickApuesta('STX50X')"
                >
                  <span class="i1">&nbsp;No(NG)&nbsp;</span>
                  {{ this.getPosition("ST", 50) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Marcador correcto</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="STX32X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX32X') }"
                  v-on:click="clickApuesta('STX32X')"
                >
                  <span class="i1">&nbsp;0:0&nbsp;</span>
                  {{ this.getPosition("ST", 32) }}
                </td>
                <td
                  colspan="2"
                  id="STX20X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX20X') }"
                  v-on:click="clickApuesta('STX20X')"
                >
                  <span class="i1">&nbsp;1:0&nbsp;</span>
                  {{ this.getPosition("ST", 20) }}
                </td>
                <td
                  colspan="2"
                  id="STX21X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX21X') }"
                  v-on:click="clickApuesta('STX21X')"
                >
                  <span class="i1">&nbsp;2:0&nbsp;</span>
                  {{ this.getPosition("ST", 21) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="STX36X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX36X') }"
                  v-on:click="clickApuesta('STX36X')"
                >
                  <span class="i1">&nbsp;0:1&nbsp;</span>
                  {{ this.getPosition("ST", 36) }}
                </td>
                <td
                  colspan="2"
                  id="STX33X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX33X') }"
                  v-on:click="clickApuesta('STX33X')"
                >
                  <span class="i1">&nbsp;1:1&nbsp;</span>
                  {{ this.getPosition("ST", 33) }}
                </td>
                <td
                  colspan="2"
                  id="STX22X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX22X') }"
                  v-on:click="clickApuesta('STX22X')"
                >
                  <span class="i1">&nbsp;2:1&nbsp;</span>
                  {{ this.getPosition("ST", 22) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="STX37X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX37X') }"
                  v-on:click="clickApuesta('STX37X')"
                >
                  <span class="i1">&nbsp;0:2&nbsp;</span>
                  {{ this.getPosition("ST", 37) }}
                </td>
                <td
                  colspan="2"
                  id="STX38X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX38X') }"
                  v-on:click="clickApuesta('STX38X')"
                >
                  <span class="i1">&nbsp;1:2&nbsp;</span>
                  {{ this.getPosition("ST", 38) }}
                </td>
                <td
                  colspan="2"
                  id="STX34X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX34X') }"
                  v-on:click="clickApuesta('STX34X')"
                >
                  <span class="i1">&nbsp;2:2&nbsp;</span>
                  {{ this.getPosition("ST", 34) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Total exacto de goles</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  id="STX77X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX77X') }"
                  v-on:click="clickApuesta('STX77X')"
                >
                  <span class="i1">&nbsp;0&nbsp;</span>
                  {{ this.getPosition("ST", 77) }}
                </td>
                <td
                  colspan="2"
                  id="STX78X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX78X') }"
                  v-on:click="clickApuesta('STX78X')"
                >
                  <span class="i1">&nbsp;1&nbsp;</span>
                  {{ this.getPosition("ST", 78) }}
                </td>
                <td
                  colspan="2"
                  id="STX84X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX84X') }"
                  v-on:click="clickApuesta('STX84X')"
                >
                  <span class="i1">&nbsp;2+&nbsp;</span>
                  {{ this.getPosition("ST", 84) }}
                </td>
              </tr>
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales/A ganar 3 Way</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="STX152X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX152X') }"
                  v-on:click="clickApuesta('STX152X')"
                >
                  <span class="i1">&nbsp;Baja 1.5/Loc&nbsp;</span>
                  {{ this.getPosition("ST", 152) }}
                </td>
                <td
                  colspan="3"
                  id="STX155X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX155X') }"
                  v-on:click="clickApuesta('STX155X')"
                >
                  <span class="i1">&nbsp;Alta 1.5/Loc&nbsp;&nbsp;</span>
                  {{ this.getPosition("ST", 155) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="STX153X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX153X') }"
                  v-on:click="clickApuesta('STX153X')"
                >
                  <span class="i1">&nbsp;Baja 1.5/Emp&nbsp;</span>
                  {{ this.getPosition("ST", 153) }}
                </td>
                <td
                  colspan="3"
                  id="STX156X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX156X') }"
                  v-on:click="clickApuesta('STX156X')"
                >
                  <span class="i1">&nbsp;Alta 1.5/Emp&nbsp;&nbsp;</span>{{ this.getPosition("ST", 156) }}
                </td>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  id="STX154X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX154X') }"
                  v-on:click="clickApuesta('STX154X')"
                >
                  <span class="i1">&nbsp;Baja 1.5/Vis&nbsp;</span>
                  {{ this.getPosition("ST", 154) }}
                </td>
                <td
                  colspan="3"
                  id="STX157X1243585"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX157X') }"
                  v-on:click="clickApuesta('STX157X')"
                >
                  <span class="i1">&nbsp;Alta 1.5/Vis&nbsp;&nbsp;</span>
                  {{ this.getPosition("ST", 157) }}
                </td>
              </tr>
            </template>

            <!-- hokey -->
            <template v-if="this.gameDetails.sport_id == 5">
              <tr v-if="this.existPositionIn('ST', 17)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Anota primero</th>
              </tr>
              <tr v-if="this.existPositionIn('ST', 17)" class="encabezado">
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX17X') }"
                  v-on:click="clickApuesta('STX17X')"
                >
                  <span class="i1">&nbsp;Vis (1)&nbsp;</span>
                  {{ this.getPosition("ST", 16) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX114X') }"
                  v-on:click="clickApuesta('STX114X')"
                >
                  <span class="i1">&nbsp;Ning&nbsp;</span>
                  {{ this.getPosition("ST", 114) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX16X') }"
                  v-on:click="clickApuesta('STX16X')"
                >
                  <span class="i1">&nbsp;Loc (2)&nbsp;</span>
                  {{ this.getPosition("ST", 17) }}
                </td>
              </tr>
              <tr v-if="this.existPositionIn('ST', 18)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Totales CHE</th>
              </tr>
              <tr v-if="this.existPositionIn('ST', 18)" class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX18X') }"
                  v-on:click="clickApuesta('STX18X')"
                >
                  <span class="i1">&nbsp;Vis (1)&nbsp;</span>
                  {{ this.getPosition("ST", 18) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX19X') }"
                  v-on:click="clickApuesta('STX19X')"
                >
                  <span class="i1">&nbsp;Ning&nbsp;</span>
                  {{ this.getPosition("ST", 19) }}
                </td>
              </tr>
              <tr v-if="this.existPositionIn('ST', 63)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                  Doble Chance
                </th>
              </tr>
              <tr v-if="this.existPositionIn('ST', 63)" class="encabezado">
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX62X') }"
                  v-on:click="clickApuesta('STX62X')"
                >
                  <span class="i1">&nbsp;(1X)&nbsp;</span>
                  {{ this.getPosition("ST", 62) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX63X') }"
                  v-on:click="clickApuesta('STX63X')"
                >
                  <span class="i1">&nbsp;(12)&nbsp;</span>
                  {{ this.getPosition("ST", 63) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX64X') }"
                  v-on:click="clickApuesta('STX64X')"
                >
                  <span class="i1">&nbsp;(X2)&nbsp;</span>
                  {{ this.getPosition("ST", 64) }}
                </td>
              </tr>
              <tr v-if="this.existPositionIn('ST', 49)">
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Ambos anotan</th>
              </tr>
              <tr v-if="this.existPositionIn('ST', 49)" class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX49X') }"
                  v-on:click="clickApuesta('STX49X')"
                >
                  <span class="i1">&nbsp;Si(GG)&nbsp;</span>
                  {{ this.getPosition("ST", 49) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX50X') }"
                  v-on:click="clickApuesta('STX50X')"
                >
                  <span class="i1">&nbsp;No(NG)&nbsp;</span>
                  {{ this.getPosition("ST", 50) }}
                </td>
              </tr>
            </template>
            <!-- Global en Tabla 3 en taq -->
            <template v-if="this.existPositionIn('ST', 60)">
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">Marcador Par/Impar</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX60X') }"
                  v-on:click="clickApuesta('HTX60X')"
                >
                  <span class="i1">&nbsp;Par&nbsp;</span>
                  {{ this.getPosition("ST", 60) }}
                </td>
                <td
                  colspan="3"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX61X') }"
                  v-on:click="clickApuesta('HTX61X')"
                >
                  <span class="i1">&nbsp;Impar&nbsp;</span>
                  {{ this.getPosition("ST", 61) }}
                </td>
              </tr>
            </template>
            <template v-if="this.existPositionIn('ST', 148)">
              <tr>
                <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">1 X 2</th>
              </tr>
              <tr class="encabezado">
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX148X') }"
                  v-on:click="clickApuesta('STX148X')"
                >
                  <span class="i1">&nbsp;1&nbsp;</span>
                  {{ this.getPosition("ST", 148) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX149X') }"
                  v-on:click="clickApuesta('STX149X')"
                >
                  <span class="i1">&nbsp;X&nbsp;</span>
                  {{ this.getPosition("ST", 149) }}
                </td>
                <td
                  colspan="2"
                  :class="{ 'apuesta-seleccionada': this.existeApuesta('STX150X') }"
                  v-on:click="clickApuesta('STX150X')"
                >
                  <span class="i1">&nbsp;2&nbsp;</span>
                  {{ this.getPosition("ST", 150) }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- 4 Cells para estos juegos especificos -->
      <template
        v-if="this.gameDetails.sport_id == 3 || this.gameDetails.sport_id == 4 || this.gameDetails.sport_id == 5"
      >
        <div
          :class="{
            'row center-block mt-2 color-bg-container-table animate__animated animate__fadeIn pb-4': true,
            light: getThemelight
          }"
        >
          <div class="col-md-3 padding2">
            <table
              id="taq4-1"
              :class="{
                'table-logros table mt-4 table-striped': true,
                'table-light': getThemelight,
                'table-dark': !getThemelight
              }"
              style="overflow: hidden;"
            >
              <thead>
                <tr>
                  <th colspan="6" class="th-title">
                    1er {{ this.gameDetails.sport_id == 5 ? "Periodo" : "Cuarto" }} (1st Prd)
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="this.existPositionIn('FQ', 1)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">1 X 2</th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX1X') }"
                      v-on:click="clickApuesta('FQX1X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>
                      {{ this.getPosition("FQ", 1) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX7X') }"
                      v-on:click="clickApuesta('FQX7X')"
                    >
                      <span class="i1">&nbsp;X&nbsp;</span>
                      {{ this.getPosition("FQ", 7) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX2X') }"
                      v-on:click="clickApuesta('FQX2X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>
                      {{ this.getPosition("FQ", 2) }}
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('FQ', 3)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      P. Spread (HDP)
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX3X') }"
                      v-on:click="clickApuesta('FQX3X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>{{ this.getPosition("FQ", 3) }} ({{
                        this.getPosition("FQ", 3, "factor")
                      }})
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX4X') }"
                      v-on:click="clickApuesta('FQX4X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>{{ this.getPosition("FQ", 4) }} ({{
                        this.getPosition("FQ", 4, "factor")
                      }})
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('FQ', 5)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Totales
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX5X') }"
                      v-on:click="clickApuesta('FQX5X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>{{ this.getPosition("FQ", 5) }} ({{
                        this.getPosition("FQ", 5, "factor")
                      }})
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX6X') }"
                      v-on:click="clickApuesta('FQX6X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>{{ this.getPosition("FQ", 6) }} ({{
                        this.getPosition("FQ", 6, "factor")
                      }})
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('FQ', 60)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Marcador Par/Impar
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX60X') }"
                      v-on:click="clickApuesta('FQX60X')"
                    >
                      <span class="i1">&nbsp;Par&nbsp;</span>{{ this.getPosition("FQ", 60) }}
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX61X') }"
                      v-on:click="clickApuesta('FQX61X')"
                    >
                      <span class="i1">&nbsp;Impar&nbsp;</span>{{ this.getPosition("FQ", 61) }}
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('FQ', 62)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Doble Chance
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX62X') }"
                      v-on:click="clickApuesta('FQX62X')"
                    >
                      <span class="i1">&nbsp;1X&nbsp;</span>
                      {{ this.getPosition("FQ", 62) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX63X') }"
                      v-on:click="clickApuesta('FQX63X')"
                    >
                      <span class="i1">&nbsp;12&nbsp;</span>
                      {{ this.getPosition("FQ", 63) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX64X') }"
                      v-on:click="clickApuesta('FQX64X')"
                    >
                      <span class="i1">&nbsp;2X&nbsp;</span>
                      {{ this.getPosition("FQ", 64) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="col-md-3 padding2">
            <table
              id="taq4-2"
              :class="{
                'table-logros table mt-4 table-striped': true,
                'table-light': getThemelight,
                'table-dark': !getThemelight
              }"
              style="overflow: hidden;"
            >
              <thead>
                <tr>
                  <th colspan="6" class="th-title">
                    2do {{ this.gameDetails.sport_id == 5 ? "Periodo" : "Cuarto" }} (2nd Prd)
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="this.existPositionIn('SQ', 1)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">1 X 2</th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('SQX1X') }"
                      v-on:click="clickApuesta('SQX1X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>
                      {{ this.getPosition("SQ", 1) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('SQX7X') }"
                      v-on:click="clickApuesta('SQX7X')"
                    >
                      <span class="i1">&nbsp;X&nbsp;</span>
                      {{ this.getPosition("SQ", 7) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('SQX2X') }"
                      v-on:click="clickApuesta('SQX2X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>
                      {{ this.getPosition("SQ", 2) }}
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('SQ', 3)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      P. Spread (HDP)
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('SQX3X') }"
                      v-on:click="clickApuesta('SQX3X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>{{ this.getPosition("SQ", 3) }} ({{
                        this.getPosition("SQ", 3, "factor")
                      }})
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('SQX4X') }"
                      v-on:click="clickApuesta('SQX4X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>{{ this.getPosition("SQ", 4) }} ({{
                        this.getPosition("SQ", 4, "factor")
                      }})
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('SQ', 5)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Totales
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('SQX5X') }"
                      v-on:click="clickApuesta('SQX5X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>{{ this.getPosition("SQ", 5) }} ({{
                        this.getPosition("SQ", 5, "factor")
                      }})
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('SQX6X') }"
                      v-on:click="clickApuesta('SQX6X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>{{ this.getPosition("SQ", 6) }} ({{
                        this.getPosition("SQ", 6, "factor")
                      }})
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('SQ', 60)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Marcador Par/Impar
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('SQX60X') }"
                      v-on:click="clickApuesta('SQX60X')"
                    >
                      <span class="i1">&nbsp;Par&nbsp;</span>{{ this.getPosition("SQ", 60) }}
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('FQX61X') }"
                      v-on:click="clickApuesta('SQX61X')"
                    >
                      <span class="i1">&nbsp;Impar&nbsp;</span>{{ this.getPosition("SQ", 61) }}
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('SQ', 62)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Doble Chance
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('SQX62X') }"
                      v-on:click="clickApuesta('SQX62X')"
                    >
                      <span class="i1">&nbsp;1X&nbsp;</span>
                      {{ this.getPosition("SQ", 62) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('SQX63X') }"
                      v-on:click="clickApuesta('SQX63X')"
                    >
                      <span class="i1">&nbsp;12&nbsp;</span>
                      {{ this.getPosition("FQ", 63) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('SQX64X') }"
                      v-on:click="clickApuesta('SQX64X')"
                    >
                      <span class="i1">&nbsp;2X&nbsp;</span>
                      {{ this.getPosition("SQ", 64) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="col-md-3 padding2">
            <table
              id="taq2-3"
              :class="{
                'table-logros table mt-4 table-striped': true,
                light: getThemelight,
                'table-dark': !getThemelight
              }"
              style="overflow: hidden;"
            >
              <thead>
                <tr>
                  <th colspan="6" class="th-title">
                    3er {{ this.gameDetails.sport_id == 5 ? "Periodo" : "Cuarto" }} (3rd Prd)
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="this.existPositionIn('TQ', 1)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">1 X 2</th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX1X') }"
                      v-on:click="clickApuesta('TQX1X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>
                      {{ this.getPosition("TQ", 1) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX7X') }"
                      v-on:click="clickApuesta('TQX7X')"
                    >
                      <span class="i1">&nbsp;X&nbsp;</span>
                      {{ this.getPosition("TQ", 7) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX2X') }"
                      v-on:click="clickApuesta('TQX2X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>
                      {{ this.getPosition("TQ", 2) }}
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('TQ', 3)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      P. Spread (HDP)
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX3X') }"
                      v-on:click="clickApuesta('TQX3X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>{{ this.getPosition("TQ", 3) }} ({{
                        this.getPosition("TQ", 3, "factor")
                      }})
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX4X') }"
                      v-on:click="clickApuesta('TQX4X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>{{ this.getPosition("TQ", 4) }} ({{
                        this.getPosition("TQ", 4, "factor")
                      }})
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('TQ', 5)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Totales
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX5X') }"
                      v-on:click="clickApuesta('TQX5X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>{{ this.getPosition("TQ", 5) }} ({{
                        this.getPosition("TQ", 5, "factor")
                      }})
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX6X') }"
                      v-on:click="clickApuesta('TQX6X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>{{ this.getPosition("TQ", 6) }} ({{
                        this.getPosition("TQ", 6, "factor")
                      }})
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('TQ', 60)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Marcador Par/Impar
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX60X') }"
                      v-on:click="clickApuesta('TQX60X')"
                    >
                      <span class="i1">&nbsp;Par&nbsp;</span>{{ this.getPosition("TQ", 60) }}
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX61X') }"
                      v-on:click="clickApuesta('TQX61X')"
                    >
                      <span class="i1">&nbsp;Impar&nbsp;</span>{{ this.getPosition("SQ", 61) }}
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('TQ', 62)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Doble Chance
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX62X') }"
                      v-on:click="clickApuesta('TQX62X')"
                    >
                      <span class="i1">&nbsp;1X&nbsp;</span>
                      {{ this.getPosition("TQ", 62) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX63X') }"
                      v-on:click="clickApuesta('TQX63X')"
                    >
                      <span class="i1">&nbsp;12&nbsp;</span>
                      {{ this.getPosition("TQ", 63) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX64X') }"
                      v-on:click="clickApuesta('TQX64X')"
                    >
                      <span class="i1">&nbsp;2X&nbsp;</span>
                      {{ this.getPosition("TQ", 64) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="col-md-3 padding2">
            <table
              id="taq4-4"
              :class="{
                'table-logros table mt-4 table-striped': true,
                'table-dark': !getThemelight,
                'table-light': getThemelight
              }"
              style="overflow: hidden;"
            >
              <thead>
                <tr>
                  <th colspan="6" class="th-title">4to Cuarto (4th Prd)</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="this.existPositionIn('QQ', 1)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">1 X 2</th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('QQX1X') }"
                      v-on:click="clickApuesta('QQX1X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>
                      {{ this.getPosition("QQ", 1) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('QQX7X') }"
                      v-on:click="clickApuesta('QQX7X')"
                    >
                      <span class="i1">&nbsp;X&nbsp;</span>
                      {{ this.getPosition("QQ", 7) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('QQX2X') }"
                      v-on:click="clickApuesta('QQX2X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>
                      {{ this.getPosition("QQ", 2) }}
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('QQ', 3)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      P. Spread (HDP)
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('QQX3X') }"
                      v-on:click="clickApuesta('QQX3X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>{{ this.getPosition("QQ", 3) }} ({{
                        this.getPosition("QQ", 3, "factor")
                      }})
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('QQX4X') }"
                      v-on:click="clickApuesta('QQX4X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>{{ this.getPosition("QQ", 4) }} ({{
                        this.getPosition("QQ", 4, "factor")
                      }})
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('QQ', 5)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Totales
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX5X') }"
                      v-on:click="clickApuesta('QQX5X')"
                    >
                      <span class="i1">&nbsp;1&nbsp;</span>{{ this.getPosition("QQ", 5) }} ({{
                        this.getPosition("QQ", 5, "factor")
                      }})
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('TQX6X') }"
                      v-on:click="clickApuesta('QQX6X')"
                    >
                      <span class="i1">&nbsp;2&nbsp;</span>{{ this.getPosition("QQ", 6) }} ({{
                        this.getPosition("QQ", 6, "factor")
                      }})
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('QQ', 60)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Marcador Par/Impar
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('QQX60X') }"
                      v-on:click="clickApuesta('QQX60X')"
                    >
                      <span class="i1">&nbsp;Par&nbsp;</span>{{ this.getPosition("QQ", 60) }}
                    </td>
                    <td
                      colspan="3"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('QQX61X') }"
                      v-on:click="clickApuesta('QQX61X')"
                    >
                      <span class="i1">&nbsp;Impar&nbsp;</span>{{ this.getPosition("QQ", 61) }}
                    </td>
                  </tr>
                </template>
                <template v-if="this.existPositionIn('QQ', 62)">
                  <tr>
                    <th colspan="6" class="th-exotics i2" style="padding-top: 4px !important;">
                      Doble Chance
                    </th>
                  </tr>
                  <tr class="encabezado">
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('QQX62X') }"
                      v-on:click="clickApuesta('QQX62X')"
                    >
                      <span class="i1">&nbsp;1X&nbsp;</span>
                      {{ this.getPosition("QQ", 62) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('QQX63X') }"
                      v-on:click="clickApuesta('QQX63X')"
                    >
                      <span class="i1">&nbsp;12&nbsp;</span>
                      {{ this.getPosition("QQ", 63) }}
                    </td>
                    <td
                      colspan="2"
                      :class="{ 'apuesta-seleccionada': this.existeApuesta('QQX64X') }"
                      v-on:click="clickApuesta('QQX64X')"
                    >
                      <span class="i1">&nbsp;2X&nbsp;</span>
                      {{ this.getPosition("QQ", 64) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "JugadasDetailsComponent",
  props: {
    gameDetails: Object
  },
  computed: {
    ...mapGetters(["getApuestas", "getFormatoTaquilla", "getThemelight"]),
    isOpen() {
      return this.open;
    }
  },
  methods: {
    existeApuesta(v) {
      const busquedaApueta = this.getApuestas.flatMap(a => {
        return a.id == `${v}${this.gameDetails.id}` ? a : [];
      });
      return busquedaApueta.length > 0;
    },
    async clickApuesta(v) {
      this.$store.commit("showLoader");
      await this.$store.dispatch("clickApuesta", {
        id: `${v}${this.gameDetails.id}`,
        teams: this.gameDetails.teams,
        gameName: this.gameDetails.gameName,
        momento: moment(String(new Date())).format("DD-MM-YYYY hh:mm:ss A")
      });

      this.$store.commit("hideLoader");
    },
    clickOpen() {
      this.open = !this.open;
    },
    existPositionIn(indice, position) {
      return this.gameDetails &&
        this.gameDetails.odds &&
        this.gameDetails.odds[indice] &&
        this.gameDetails.odds[indice][position] &&
        this.gameDetails.odds[indice][position] != ""
        ? true
        : false;
    },
    getPosition(indice, position, mandatory = null) {
      if (
        this.gameDetails.odds &&
        this.gameDetails.odds[indice] != undefined &&
        this.gameDetails.odds[indice][position] != undefined
      ) {
        return !mandatory
          ? this.addSimbol(this.gameDetails.odds[indice][position][this.getFormatoTaquilla])
          : this.addSimbol(this.gameDetails.odds[indice][position][mandatory]);
      } else {
        return null;
      }
    },
    addSimbol(v) {
      const simbolo = this.getFormatoTaquilla == "odd" && parseFloat(v) > 0 ? "+" : "";

      return `${simbolo}${v}`;

      // const txt = String(v);
      // const valor = txt.split(" ").length > 1 ? txt.split(" ")[1] : v;
      // return parseFloat(valor) > 0 ? `+${valor}` : valor;
    }
  },
  data() {
    return {
      open: false
    };
  }
};
</script>
<style>
.padding2 {
  padding: 2px !important;
}

.i1 {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

table.table-logros {
  border: 0;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
}

.td3col {
  width: 33.33% !important;
}

.td2col {
  width: 50% !important;
}

.sub-title {
  font-weight: 200;
}

.container-game-details {
  text-align: center;
}

button.btn-base.border-green.btn-jugadas {
  padding: 3px !important;
  margin: 0;
  width: 100px;
}

button.btn-base.border-green.btn-jugadas > span {
  padding: 0.2em !important;
}

button.btn-base.border-green.btn-jugadas > span:hover {
  text-shadow: 2px 1px 3px black;
}

button.isOpen > span {
  background-color: transparent !important;
  text-shadow: 2px 1px -3px black;
}

.color-bg-container-table {
  background-color: #212529;
  width: 100%;
  text-align: center;
  padding: 0px 0.5%;
  margin: 0px;
}

/*nth-child(0)*/
table.table-logros:not(.table-light) thead tr {
  background-color: #12121294;
  /*#31373d*/
}

.th-title {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  padding: 0.3em 0 !important;
  border-color: transparent !important;
}

.th-exotics.i2 {
  font-size: 15px;
  font-weight: 300;
}

table.table-logros tbody tr th,
table.table-logros tbody tr td {
  padding: 0.3em !important;
  height: 0.5em;
  margin: 0px !important;
}

table.table-logros tbody tr.encabezado td {
  /*font-size: 15px;*/
  padding: 0px !important;
  margin: 0px !important;
  text-align: center !important;
}

.apuesta-seleccionada {
  /*
    2cd50d
    background-image: linear-gradient(-90deg, #6bff00,#0d6403)!important;
  */
  font-weight: 500;
  text-shadow: 1px 1px 3px black !important;
  color: #ffffff;
  background-color: #3cb202;
}

/*
table.table-logros tbody tr:nth-of-type(odd), .table-striped tbody tr.encabezado:nth-of-type(odd):hover{
  background-color: transparent;
}
  */

table.table-logros tbody tr.encabezado {
  background-color: transparent !important;
}

/*table.table-logros tbody tr th:hover,  fadein 1.5s;*/
table.table-logros tbody tr.encabezado td:hover {
  background: #ff512f;
  background: -webkit-linear-gradient(to right, #f09819, #ff512f);
  background: linear-gradient(to right, #f09819, #ff512f);

  -webkit-animation: fadein 0.2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.2s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.2s;
  /* Internet Explorer */
  -o-animation: fadein 0.2s;
  /* Opera < 12.1 */
  animation: fadein 0.2s;
}

/*Efecto Fade hover*/
@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
