<template>
  <section :class="{ 'container-games-listado': true, 'taquilla-flotante-activa': this.getTaquillaFlotante }">
    <h3
      :class="{
        'text-center pt-1 pb-1': true,
        'txt-color-theme-l': this.getThemelight
      }"
      v-if="this.destacado"
    >
      {{ $t("juegos_destacados") }}
    </h3>
    <div v-if="this.getGames && this.getGames.length == 0">
      <div class="card dark-bg text-light text-center my-3" :class="{ 'light-bg': this.getThemelight }">
        <h5 class="my-4">No hay juegos disponibles</h5>
      </div>
    </div>
    <div v-else>
      <div class="game" v-for="(game, index) in this.getGames" :key="index">
        <gridBaseGame :gameDetails="getDataGame(game)" />
      </div>
    </div>
    <button
      :class="{
        'btn float-right': true,
        'btn-dark': !this.getThemelight,
        'btn-light': this.getThemelight,
        'mr-3': this.getTaquillaFlotante
      }"
      v-b-tooltip.hover.left="'Subir al top'"
      @click="toTop"
    >
      <i class="fas fa-angle-up"></i>
    </button>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { essentialsData } from "../../mixins/essentialsDataMixin.js";

import gridBaseGame from "./games/gridBaseGame.vue";

/*
  - Anterior
import vuescroll from "vuescroll";
import basketball from "./games/basketball.vue";
import baseball from "./games/baseball.vue";
import futbol from "./games/futbol.vue";
import boxeo from "./games/boxeo.vue";
import tennis from "./games/tennis.vue";
import pingpong from "./games/pingpong.vue";
import dota from "./games/dota.vue";
import bolos from "./games/bolos.vue";
import footballAmericano from "./games/footballAmericano.vue";
import hockey from "./games/hockey.vue";
import rugby from "./games/rugby.vue";
import esport from "./games/esport.vue";
import badminton from "./games/badminton.vue";
import leagueOfLegend from "./games/leagueOfLegend.vue";
import balonmano from "./games/balonmano.vue";


    basketball,
    baseball,
    futbol,
    boxeo,
    tennis,
    pingpong,
    dota,
    bolos,
    footballAmericano,
    hockey,
    badminton,
    leagueOfLegend,
    balonmano,
    rugby,
    esport,
    vuescroll,
*/

export default {
  name: "GameDetailsTable",
  mixins: [essentialsData],
  props: {
    destacado: {
      type: Boolean,
      default: true
    }
  },
  components: {
    gridBaseGame
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getTickets",
      "getIsMobilResolution",
      "getLeagues",
      "getGames",
      "getAllLeagues",
      "getFormatoTaquilla",
      "getThemelight",
      "getMuestraTitulo",
      "getTaquillaFlotante"
    ])
  },
  methods: {
    toTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getDataGame(game) {
      var muestraTitulo = this.idAnt == game.league_id ? false : true;
      this.idAnt = game.league_id;
      // this.$store.commit("setMuestraTitulo", true);

      const HT1 =
        game.odds && game.odds.HT && game.odds.HT[1] ? this.formatSymbol(game.odds.HT[1][this.getFormatoTaquilla]) : "";
      const HT2 =
        game.odds && game.odds.HT && game.odds.HT[2] ? this.formatSymbol(game.odds.HT[2][this.getFormatoTaquilla]) : "";
      const HT3 =
        game.odds && game.odds.HT && game.odds.HT[3] ? this.formatSymbol(game.odds.HT[3][this.getFormatoTaquilla]) : "";
      const HT3F =
        game.odds && game.odds.HT && game.odds.HT[3] && game.odds.HT[3].factor && game.odds.HT[3].factor != ""
          ? game.odds.HT[3].factor
          : "";
      const HT4 =
        game.odds && game.odds.HT && game.odds.HT[4] ? this.formatSymbol(game.odds.HT[3][this.getFormatoTaquilla]) : "";
      const HT4F =
        game.odds && game.odds.HT && game.odds.HT[4] && game.odds.HT[4].factor && game.odds.HT[4].factor != ""
          ? game.odds.HT[4].factor
          : "";
      const HT5 =
        game.odds && game.odds.HT && game.odds.HT[5] ? this.formatSymbol(game.odds.HT[5][this.getFormatoTaquilla]) : "";
      const HT5F = game.odds && game.odds.HT && game.odds.HT[5] && game.odds.HT[5].factor ? game.odds.HT[5].factor : "";
      const HT6 =
        game.odds && game.odds.HT && game.odds.HT[6] ? this.formatSymbol(game.odds.HT[6][this.getFormatoTaquilla]) : "";
      const HT6F = game.odds && game.odds.HT && game.odds.HT[6] && game.odds.HT[6].factor ? game.odds.HT[6].factor : "";
      const FT1 =
        game.odds && game.odds.FT && game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "";
      const FT2 =
        game.odds && game.odds.FT && game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "";
      const FT3 =
        game.odds && game.odds.FT && game.odds.FT[3] ? this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla]) : "";
      const FT3F =
        game.odds && game.odds.FT && game.odds.FT[3] && game.odds.FT[3].factor && game.odds.FT[3].factor != ""
          ? `(${game.odds.FT[3].factor})`
          : "";
      const FT4 =
        game.odds && game.odds.FT && game.odds.FT[4] ? this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla]) : "";
      const FT4F =
        game.odds && game.odds.FT && game.odds.FT[4] && game.odds.FT[4].factor && game.odds.FT[4].factor != ""
          ? `(${game.odds.FT[4].factor})`
          : "";
      const FT5 =
        game.odds && game.odds.FT && game.odds.FT[5] ? this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla]) : "";
      const FT5F =
        game.odds && game.odds.FT && game.odds.FT[5] && game.odds.FT[5].factor && game.odds.FT[5].factor != ""
          ? game.odds.FT[5].factor
          : "";
      const FT6 =
        game.odds && game.odds.FT && game.odds.FT[6] ? this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla]) : "";
      const FT6F =
        game.odds && game.odds.FT && game.odds.FT[6] && game.odds.FT[6].factor && game.odds.FT[6].factor != ""
          ? game.odds.FT[6].factor
          : "";
      const FT7 =
        game.odds && game.odds.FT && game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : "";
      const FT8 =
        game.odds && game.odds.FT && game.odds.FT[8] ? this.formatSymbol(game.odds.FT[8][this.getFormatoTaquilla]) : "";
      const FT8F =
        game.odds && game.odds.FT && game.odds.FT[8] && game.odds.FT[8].factor && game.odds.FT[9].factor != ""
          ? `(${game.odds.FT[8].factor})`
          : "";
      const FT9 =
        game.odds && game.odds.FT && game.odds.FT[9] ? this.formatSymbol(game.odds.FT[9][this.getFormatoTaquilla]) : "";
      const FT9F =
        game.odds && game.odds.FT && game.odds.FT[9] && game.odds.FT[9].factor && game.odds.FT[9].factor != ""
          ? `(${game.odds.FT[9].factor})`
          : "";
      const FT49 =
        game.odds && game.odds.FT && game.odds.FT[49]
          ? this.formatSymbol(game.odds.FT[49][this.getFormatoTaquilla])
          : "";
      const FT50 =
        game.odds && game.odds.FT && game.odds.FT[50]
          ? this.formatSymbol(game.odds.FT[50][this.getFormatoTaquilla])
          : "";
      const FT62 =
        game.odds && game.odds.FT && game.odds.FT[62]
          ? this.formatSymbol(game.odds.FT[62][this.getFormatoTaquilla])
          : "";
      const FT63 =
        game.odds && game.odds.FT && game.odds.FT[63]
          ? this.formatSymbol(game.odds.FT[63][this.getFormatoTaquilla])
          : "";
      const FT64 =
        game.odds && game.odds.FT && game.odds.FT[64]
          ? this.formatSymbol(game.odds.FT[64][this.getFormatoTaquilla])
          : "";
      const FT115 =
        game.odds && game.odds.FT && game.odds.FT[115]
          ? this.formatSymbol(game.odds.FT[115][this.getFormatoTaquilla])
          : "";
      const fechaTime = game.time ? moment(String(game.time)).format("DD/MM/YYYY HH:mm a") : "";
      const created_at = game.created_at ? moment(String(game.created_at)).format("DD/MM/YYYY HH:mm a") : "";

      // balonmano
      if (game.sport_id == 16) {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at,
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[1].team_name,
              "1X2FT": FT1,
              HDPFT: `${FT3} ${FT3F}`,
              TOTALESFT: FT5F.trim() != "" ? `A ${FT5} ${FT5F}` : "",
              "1X2HT": `${HT1}`,
              HDPHT: `${HT3} ${HT3F}`,
              TOTALESHT: HT5.trim() ? `A ${HT5} ${HT5F}` : "",
              EMPATE: FT7
            },
            {
              name: game.Team[0].team_name,
              "1X2FT": FT2,
              HDPFT: `${FT4} ${FT4F}`,
              TOTALESFT: FT6.trim() != "" ? `B ${FT6} ${FT6F}` : "",
              "1X2HT": `${HT2}`,
              HDPHT: `${HT4} ${HT4F}`,
              TOTALESHT: HT6 != "" ? `B ${HT6} ${HT6F}` : "",
              EMPATE: FT7
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id) == "basketball") {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at,
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[1].team_name,
              "1X2FT": FT1,
              HDPFT: `${FT3} ${FT3F}`,
              TOTALESFT: `A ${FT5} ${FT5F}`,
              "1X2HT": `${HT1}`,
              HDPHT: `${HT3} ${HT3F}`,
              TOTALESHT: `A ${HT5} ${HT5F}`
            },
            {
              name: game.Team[0].team_name,
              "1X2FT": FT2,
              HDPFT: `${FT4} ${FT4F}`,
              TOTALESFT: `B ${FT6} ${FT6F}`,
              "1X2HT": `${HT2}`,
              HDPHT: `${HT4} ${HT4F}`,
              TOTALESHT: `B ${HT6} ${HT6F}`
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id) == "baseball") {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at,
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[1].team_name,
              "1X2FT": FT1,
              HDPFT: `${FT3} ${FT3F}`,
              SHDPFT: `${FT8} ${FT8F}`,
              TOTALESFT: FT5F != "" ? `A ${FT5} ${FT5F}` : "",
              "1X2HT": `${HT1}`,
              HDPHT: `${HT3} ${HT3F}`,
              TOTALESHT: HT5F != "" ? `A ${HT5} ${HT5F}` : ""
            },
            {
              name: game.Team[0].team_name,
              "1X2FT": FT2,
              HDPFT: `${FT4} ${FT4F}`,
              SHDPFT: `${FT9} ${FT9F}`,
              TOTALESFT: FT6F != "" ? `B ${FT6} ${FT6F}` : "",
              "1X2HT": `${HT2}`,
              HDPHT: `${HT4} ${HT4F}`,
              TOTALESHT: HT6F != "" ? `B ${HT6} ${HT6F}` : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id) == "hockey") {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at,
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[1].team_name,
              "1X2FT": FT1,
              HDPFT: `${FT3} ${FT3F}`,
              SHDPFT: `${FT8} ${FT8F}`,
              TOTALESFT: FT5F != "" ? `A ${FT5} ${FT5F}` : "",
              "1X2HT": `${HT1}`,
              HDPHT: `${HT3} ${HT3F}`,
              TOTALESHT: HT5F != "" ? `A ${HT5} ${HT5F}` : ""
            },
            {
              name: game.Team[0].team_name,
              "1X2FT": FT2,
              HDPFT: `${FT4} ${FT4F}`,
              SHDPFT: `${FT9} ${FT9F}`,
              TOTALESFT: FT6F != "" ? `B ${FT6} ${FT6F}` : "",
              "1X2HT": `${HT2}`,
              HDPHT: `${HT4} ${HT4F}`,
              TOTALESHT: HT6F != "" ? `B ${HT6} ${HT6F}` : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id) == "footballAmericano") {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at,
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[1].team_name,
              "1X2FT": FT1,
              HDPFT: `${FT3} ${FT3F}`,
              SHDPFT: `${FT8} ${FT8F}`,
              TOTALESFT: FT5F != "" ? `A ${FT5} ${FT5F}` : "",
              "1X2HT": `${HT1}`,
              HDPHT: `${HT3} ${HT3F}`,
              TOTALESHT: `A ${HT5} ${HT5F}`
            },
            {
              name: game.Team[0].team_name,
              "1X2FT": FT2,
              HDPFT: `${FT4} ${FT4F}`,
              SHDPFT: `${FT9} ${FT9F}`,
              TOTALESFT: FT6F != "" ? `B ${FT6} ${FT6F}` : "",
              "1X2HT": `${HT2}`,
              HDPHT: `${HT4} ${HT4F}`,
              TOTALESHT: `B ${HT6} ${HT6F}`
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "futbol") {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at: moment(String(game.created_at)).format("MM/DD/YYYY HH:mm a"),
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]),
              HDPFT: `${FT3} ${FT3F}`,
              TOTALESFT: FT5F != "" ? `A ${FT5} ${FT5F}` : "",
              EMPATE: FT115,
              "1X": FT62,
              12: FT63,
              X2: FT64,
              SI: FT49,
              NO: FT50
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": FT2,
              HDPFT: `${FT4} ${FT4F}`,
              TOTALESFT: FT6F != "" ? `B ${FT6} ${FT6F}` : "",
              EMPATE: FT7,
              "1X": FT62,
              12: FT63,
              X2: FT64,
              SI: FT49,
              NO: FT50
            }
          ]
        };
      }
      if (this.getNameGame(game.league_id) == "tennis") {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at,
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]),
              HDPFT: `${FT3} ${FT3F}`,
              TOTALESFT: FT5F != "" ? `A ${FT5} ${FT5F}` : "",
              EMPATE: FT115
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": FT2,
              HDPFT: `${FT4} ${FT4F}`,
              TOTALESFT: FT6F != "" ? `B ${FT6} ${FT6F}` : "",
              EMPATE: FT7
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id) == "bolos") {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at,
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]),
              HDPFT: `${FT3} ${FT3F}`,
              TOTALESFT: HT5F != "" ? `A ${HT5} ${HT5F}` : "",
              EMPATE: FT115
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": FT2,
              HDPFT: `${FT4} ${FT4F}`,
              TOTALESFT: FT6F != "" ? `B ${FT6} ${FT6F}` : "",
              EMPATE: FT7
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id) == "pingpong") {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at,
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]),
              HDPFT: `${FT3} ${FT3F}`,
              TOTALESFT: HT5F != "" ? `A ${HT5} ${HT5F}` : "",
              EMPATE: FT115
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": FT2,
              HDPFT: `${FT4} ${FT4F}`,
              TOTALESFT: FT6F != "" ? `B ${FT6} ${FT6F}` : "",
              EMPATE: FT7
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id) == "boxeo") {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at,
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]),
              // HDPFT: `${FT3} ${FT3F}`,
              // TOTALESFT: `A ${HT5} ${HT5F}`,
              EMPATE: FT115
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": FT2,
              // HDPFT: `${FT4} ${FT4F}`,
              // TOTALESFT: `B ${FT6} ${FT6F}`,
              EMPATE: FT7
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id) == "rugby") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              EMPATE: game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[3]
                ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
                : "",
              TOTALESFT: game.odds.FT[5]
                ? `A ${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[4]
                ? `${this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla])} (${game.odds.FT[4].factor})`
                : "",
              TOTALESFT: game.odds.FT[6]
                ? `B ${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`
                : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "esport") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team ? game.Team[0].team_name : "",
              "1X2FT": game.odds && game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              HDPFT:
                game.odds && game.odds.FT[3]
                  ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
                  : "",
              TOTALESFT:
                game.odds && game.odds.FT[5]
                  ? `A ${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                  : "",
              EMPATE: game.odds && game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team && game.Team[1].team_name,
              "1X2FT": game.odds && game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              HDPFT:
                game.odds && game.odds.FT[4]
                  ? `${this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla])} (${game.odds.FT[4].factor})`
                  : "",
              TOTALESFT:
                game.odds && game.odds.FT[6]
                  ? `B ${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`
                  : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id) == "badminton") {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at,
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]),
              // HDPFT: `${FT3} ${FT3F}`,
              // TOTALESFT: `A ${HT5} ${HT5F}`,
              EMPATE: FT115
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": FT2,
              // HDPFT: `${FT4} ${FT4F}`,
              // TOTALESFT: `B ${FT6} ${FT6F}`,
              EMPATE: FT7
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id) == "dota" || this.getNameGame(game.league_id) == "leagueOfLegend") {
        return {
          id: game.id,
          league_id: game.league_id,
          sport_id: game.sport_id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(game.date).format("DD-MM-YYYY"),
          created_at,
          odds: game.odds,
          time: fechaTime,
          muestraTitulo: muestraTitulo,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]),
              HDPFT: `${FT3} ${FT3F}`,
              TOTALESFT: HT5F != "" ? `A ${HT5} ${HT5F}` : "",
              EMPATE: FT115
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": FT2,
              HDPFT: `${FT4} ${FT4F}`,
              TOTALESFT: FT6F != "" ? `B ${FT6} ${FT6F}` : "",
              EMPATE: FT7
            }
          ]
        };
      }

      return {
        id: game.id,
        league_id: game.league_id,
        sport_id: game.sport_id,
        gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
        gameDate: moment(game.date).format("DD-MM-YYYY"),
        created_at,
        odds: game.odds,
        time: fechaTime,
        muestraTitulo: muestraTitulo,
        teams: [],
        FT: [
          this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]),
          this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla])
        ]
      };
    },
    getNameLeague(id_league) {
      return this.getAllLeagues
        ? this.getAllLeagues.flatMap(l => {
            return l.id == id_league ? l.name + " (" + l.sport + ")" : [];
          })[0]
        : "";
    },
    getNameGame(id_league, sport_id) {
      return this.getLeagues
        ? this.getLeagues.flatMap(l => {
            let txt = l.sport.toString().toLowerCase();

            if (sport_id && sport_id == 2) {
              return "futbol";
            }

            if (sport_id && sport_id == 1) {
              return "baseball";
            }

            if (sport_id && sport_id == 10) {
              return "rugby";
            }

            if (sport_id && sport_id == 16) {
              return "balonmano";
            }

            if (l.id == id_league && (txt.includes("hockey") || sport_id == 5)) {
              return "hockey";
            }

            if (l.id == id_league && txt.includes("badminton")) {
              return "badminton";
            }

            if ((l.id == id_league && txt.includes("bowls")) || txt.includes("bolos")) {
              return "bolos";
            }
            if (l.id == id_league && txt.includes("football americano")) {
              return "footballAmericano";
            }

            if (l.id == id_league && txt.includes("e-sport")) {
              return "esport";
            }

            // league dota
            if (l.id == id_league && (id_league == 1280 || txt.includes("dota"))) {
              return "dota";
            }

            if (l.id == id_league && (id_league == 1280 || txt.includes("league of legend"))) {
              return "leagueOfLegend";
            }

            if (l.id == id_league && txt.includes("ping pong")) {
              return "pingpong";
            }

            if (l.id == id_league && txt.includes("boxeo")) {
              return "boxeo";
            } else {
              if (txt == "ping pong") {
                return "pingpong";
              }

              if (txt == "e-sports dota" || txt == "starcraft") {
                return "dota";
              }

              return l.id == id_league ? txt : [];
            }
          })[0]
        : "";
    }
  },
  data() {
    return {
      ops: {
        vuescroll: {
          mode: "native",
          sizeStrategy: "percent",
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false
        },
        scrollPanel: {
          initialScrollY: true,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: "right"
        },
        rail: {
          background: "#01a99a",
          opacity: 0,
          size: "3px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "2px",
          keepShow: false
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: "#c1c1c1",
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0.2,
          size: "60px",
          disable: false
        }
      }
    };
  },
  beforeCreate() {
    this.idAnt = null;
  }
};
</script>
<style>
/*Estilos custom para contenido*/
.container-games-listado:not(.taquilla-flotante-activa) {
  width: 74% !important;
}
.container-games-listado.taquilla-flotante-activa {
  width: 100% !important;
}
</style>
<style scoped="scoped">
.btn.float-right.btn-light {
  border: solid 1px #c1c1c1b0;
}
.container-games-listado {
  height: auto;
  overflow: auto;
}
.hasVBar {
  height: 1500px !important;
  padding-bottom: 10em !important;
}

.container-games {
  overflow-y: scroll;
  position: absolute;
  height: 500px;
  padding-bottom: 4em;
}

.input-modal {
  max-width: 150px;
}

.input-date {
  max-width: 150px;
  margin-left: 0.5rem !important;
}

h1 div {
  color: #fff;
}

.sub-title {
  font-weight: 200;
}

.sidebar-container button:hover {
  background-color: #eee;
  color: #4e555b;
}

#container-juegos {
  overflow: hidden;
}

.btn-generar {
  background-color: #294058;
  border-color: #294058;
}

.btn-generar:hover {
  background-color: #325375;
  border-color: #325375;
}

.btn-generar:active,
.btn-generar:focus {
  box-shadow: 0 0 0 0.2rem #325375;
}
</style>
