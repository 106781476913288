<template>
  <div class="accordion accordion-container" id="accordionExample" v-show="this.data">
    <div class="card card-customer" v-for="c in this.data" v-bind:key="c.id">
      <div
        class="card-header"
        id="heading1"
        data-toggle="collapse"
        :data-target="'#collapse' + c.ayuda.id"
        aria-expanded="false"
        :aria-controls="'#collapse' + c.ayuda.id"
        style="cursor: pointer"
      >
        <a style="font-weight: 700; color: #001f62"
          >{{ c.ayuda.titulo }}<i style="float: right; color: black; font-size: 24px" class="fas fa-angle-down"></i>
        </a>
      </div>
      <div
        v-lazy-container="{ selector: 'div' }"
        :id="'collapse' + c.ayuda.id"
        class="collapse"
        aria-labelledby="heading1"
        data-parent="#accordionExample"
      >
        <div
          class="card-body card-body-como-apostar"
          v-if="c && c.ayuda && c.ayuda.contenido"
          v-html="getMontoParametro(c.ayuda.contenido)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["data"],
  computed: {
    ...mapGetters(["getParametrosCMS"])
  },
  methods: {
    getMontoParametro(contenido) {
      if (this.getParametrosCMS) {
        let usd = this.getParametrosCMS.find(x => x.tipo == "USD");
        let ves = this.getParametrosCMS.find(x => x.tipo == "VES");

        contenido = contenido.replaceAll("monto_min_recarga_bs", ves.monto_min_recarga);
        contenido = contenido.replaceAll("monto_max_recarga_bs", ves.monto_max_recarga);
        contenido = contenido.replaceAll("monto_min_apuesta_bs", ves.monto_min_apuesta);
        contenido = contenido.replaceAll("monto_max_apuesta_directa_bs", ves.monto_max_apuesta_directa);
        contenido = contenido.replaceAll("monto_min_retiro_bs", ves.monto_min_retiro);
        contenido = contenido.replaceAll("monto_max_retiro_bs", ves.monto_max_retiro);
        contenido = contenido.replaceAll("bonif_max_bs", ves.bonif_max);
        contenido = contenido.replaceAll("monto_max_retiro_pagomovil_bs", ves.monto_max_retiro_pagomovil);
        contenido = contenido.replaceAll("limite_pago_bs", ves.limite_pago);
        contenido = contenido.replaceAll("limite_apuesta_bs", ves.limite_apuesta);

        contenido = contenido.replaceAll("monto_min_recarga_usd", usd.monto_min_recarga);
        contenido = contenido.replaceAll("monto_max_recarga_usd", usd.monto_max_recarga);
        contenido = contenido.replaceAll("monto_min_apuesta_usd", usd.monto_min_apuesta);
        contenido = contenido.replaceAll("monto_max_apuesta_directa_usd", usd.monto_max_apuesta_directa);
        contenido = contenido.replaceAll("monto_min_retiro_usd", usd.monto_min_retiro);
        contenido = contenido.replaceAll("monto_max_retiro_usd", usd.monto_max_retiro);
        contenido = contenido.replaceAll("bonif_max_usd", usd.bonif_max);
        contenido = contenido.replaceAll("monto_max_retiro_pagomovil_usd", usd.monto_max_retiro_pagomovil);
        contenido = contenido.replaceAll("limite_pago_usd", usd.limite_pago);
        contenido = contenido.replaceAll("limite_apuesta_usd", usd.limite_apuesta);

        //no colocar este tipo de codigo en las imagenes
        contenido = contenido.replaceAll("img src=", 'img style="height:100%; width:100%"  src=');

        return contenido;
      }
    }
  }
};
</script>

<style scoped>
.accordion-container {
  width: 100% !important;
}
.card-customer {
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
}
img {
}
</style>
