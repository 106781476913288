<template>
  <section id="container-resultados" class="animate__animated animate__backInUp">
    <div class="container-fluid" v-if="!this.getIsMobilResolution">
      <h1
        :class="{
          '_h1 text-center title-h1-size': true
          //'light-h1': getThemelight
        }"
      >
        RESULTADOSS
      </h1>

      <!-- justify-content-center pt-2 py-2 -->
      <div class="row">
        <div class="col-md-12">
          <div
            :class="{
              'card pr-3 pl-3': true,
              'card-body-dark': !getThemelight,
              'card-body-Light': getThemelight
            }"
          >
            <div class="row">
              <div class="col-md-2">
                <vc-date-picker
                  v-if="fecha"
                  v-model="fecha"
                  :input-debounce="500"
                  class="w-100"
                  :is-dark="!getThemelight"
                  :masks="masksPicker"
                  @input="updateValuePicker"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      readonly
                      :class="{
                        'bg-dark': !getThemelight,
                        'bg-light': getThemelight,
                        border: true,
                        rounded: true,
                        'form-control': true,
                        'text-center': true,
                        'bg-input-date': true
                      }"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </vc-date-picker>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  :class="{
                    'form-control input-search-team': true,
                    impCustom: !getThemelight,
                    'impCustom-Light': getThemelight
                  }"
                  v-model="filterTerm"
                  placeholder="Buscar equipo"
                  name="filter_by_name_team"
                  autocomplete="new-text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <table
        :class="{
          'table table-striped mb-0': true,
          'table-dark': !getThemelight,
          'table-light': getThemelight
        }"
      >
        <thead>
          <tr>
            <th style="width: 10%;padding-left: 1em">ID</th>
            <th style="width: 15%;">HORA</th>
            <th style="width: 20%;">EQUIPO</th>
            <th class="text-center" style="width: 10%;">RESJC</th>
            <th style="width: 10%;">A/BJC</th>
            <th style="width: 12%;">RESMJ</th>
            <th style="width: 25%;">LIGA (DEPORTE)</th>
          </tr>
        </thead>
      </table>

      <vuescroll class="caja-tabla" :ops="opcionesScroll">
        <table
          :class="{
            'table table-striped': true,
            'table-dark': !this.getThemelight,
            'table-light': this.getThemelight
          }"
          style="font-size: 0.85em;"
        >
          <tbody>
            <tr v-for="(r, index) in resultadoData" :key="index">
              <td style="width: 10%; padding-left: 1em;">
                {{ r.id }}
              </td>
              <td style="width: 15%;">
                {{ formatDate(r.time) }}
              </td>
              <td style="width: 20%;">
                <div v-if="r.Team.length > 1 && r.Team[0].team_name">
                  {{ r.Team[0].team_name }}
                </div>

                <div v-if="r.Team.length > 1 && r.Team[1].team_name">
                  {{ r.Team[1].team_name }}
                </div>
              </td>
              <td style="width: 10%;">
                <div v-if="r.results && r.results.result && r.results.result.FT">
                  <label class="label text-center h50">{{ r.results.result.FT[1] }}</label>
                  <label class="label text-center h50">{{ r.results.result.FT[0] }}</label>
                </div>
              </td>
              <td class="text-left" style="width: 8%;">
                <div v-if="r.results && r.results.result && r.results.result.FT">
                  {{ getSumatory(r.results.result.FT) }}
                </div>
              </td>
              <td style="width: 10%;margin-left:10px;">
                <div v-if="r.results && r.results.result && r.results.result.FT">
                  <label class="lbl-full" v-if="getResmj(r.results.result.HT) != null">
                    {{ r.results.result.HT[1] }}
                  </label>
                  <label class="mt-1 lbl-full" v-if="getResmj(r.results.result.HT) != null">
                    {{ r.results.result.HT[0] }}</label
                  >
                </div>
              </td>
              <td style="width: 25%;">
                <label v-if="r.league_id" style="margin-left: 50px;">
                  {{ getNameLeague(r.league_id) }}
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </vuescroll>

      <div>
        <b-pagination
          v-if="totalRows"
          size="sm"
          :per-page="perPage"
          :limit="3"
          :total-rows="parseInt(totalRows) - 1"
          v-model="currentPage"
          custom="size"
          variant="dark"
          :class="{
            customPagination: !this.getThemelight,
            'customPagination-light': this.getThemelight,
            'pt-2 pb-4': true
          }"
          first-number
          last-number
          @input="changePage"
        />
      </div>
    </div>

    <!-- <ResultadosMovil v-else> </ResultadosMovil> -->
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import * as u from "j899";
import moment from "moment";
import * as lang from "vuejs-datepicker/src/locale";
import vuescroll from "vuescroll";

// import bTable from "bootstrap-vue/esm/components/table/table";
// import { BPagination, BTable } from "bootstrap-vue";
// import DataTableLength from "../components/DataTableLength";

//Componente Movil
//import ResultadosMovil from "../components/movil/ResultadosMovil.vue";

export default {
  name: "Resultados",
  components: {
    //ResultadosMovil,
    vuescroll
  },
  computed: {
    ...mapGetters(["getUser", "getResults", "getIsMobilResolution", "getThemelight"])
  },
  watch: {
    filterTerm(v, old) {
      if (v.trim().length > 1 || old.trim().length > 1) {
        this.currentPage = 1;
        // this.$refs.tabeResuts.refresh();
        this.itemsProvider();
      }
    },
    fecha() {
      this.apiUrl = `https://apipwa.sellatuparley.com/api/contenido/resultados?date=${this.getFechaFormat()}`;
      // this.$refs.tabeResuts.refresh();
      this.itemsProvider();
    },
    getThemelight(m) {
      if (m) {
        this.colorTable = "table-custom-header-Light";
        this.tableVariant = "light";
      } else {
        this.colorTable = "table-custom-header";
        this.tableVariant = "dark";
      }
    }
  },
  data() {
    return {
      fecha: new Date(),
      colorTable: this.getThemelight ? "table-custom-header-Light" : "table-custom-header",
      tableVariant: this.getThemelight ? "light" : "dark",
      resultadoData: null,
      apiUrl: `https://apipwa.sellatuparley.com/api/contenido/resultados?date=${this.getFechaFormat()}`,
      language: "es", // the language I need
      languages: lang,
      leagues: [],
      masksPicker: { input: "DD/MM/YYYY" },
      fields: [
        { key: "id", label: "Id", sortable: false, thClass: "th-100" },
        {
          key: "time",
          label: "Hora",
          thClass: "th-hora",
          sortable: false,
          formatter: value => {
            return moment(value).format("DD/MM/YYYY hh:mm:ss A");
          }
        },
        { key: "Team", label: "Equipo", sortable: false },
        { key: "results.result.FT", label: "RESJC", sortable: false },
        { key: "results.result", label: "A/BJC", sortable: false },
        { key: "results.result.HT", label: "RESMJ", sortable: false },
        { key: "Team.league_id", label: "Liga(DEPORTE)", sortable: false }
      ],
      opcionesScroll: {
        vuescroll: {
          mode: "native",
          sizeStrategy: "percent",
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false
        },
        scrollPanel: {
          initialScrollY: true,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: "right"
        },
        rail: {
          background: "#01a99a",
          opacity: 0,
          size: "4px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "2px",
          keepShow: false
        },
        bar: {
          showDelay: 100,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: "#c1c1c1",
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0.1,
          size: "20px",
          disable: false
        }
      },
      /*
                  RESJC getSumatory(game.results.result.FT)

                  fields: {
                    time: {
                      label: "Hora",
                      sortable: false,
                      //tdClass: "text-center nowrap",
                      //thClass: "th-style pt-1 pb-1 text-center",
                      formatter: value => {
                        return moment(value).format("DD-MM-YYYY hh:mm:ss A");
                      }
                    },
                    id: {
                      label: "Id",
                      sortable: false
                    },
                    Team: {
                      label: "Team",
                      sortable: false
                    }
                  },
                  */
      sortBy: "time",
      sortDesc: true,
      sortDirection: "desc",
      perPage: 50,
      currentPage: 1,
      busy: false,
      totalRows: 0,
      filter: null,
      filterTerm: ""
    };
  },
  methods: {
    getFechaFormat() {
      return moment(this.fecha).format("YYYY-MM-DD");
    },
    getMes() {
      return this.fecha.getMonth() > 9 ? this.fecha.getMonth() + 1 : "0" + (this.fecha.getMonth() + 1);
    },
    getDia() {
      return this.fecha.getDate() > 9 ? this.fecha.getDate() : "0" + this.fecha.getDate();
    },
    getYear() {
      return this.fecha.getFullYear();
    },
    getResmj(ht) {
      let dt = null;
      if (ht) {
        dt = JSON.parse(JSON.stringify(ht));
      }
      return dt;
    },
    getNameLeague(p) {
      return this.leagues[p] ? this.leagues[p] : "";
    },
    itemsProvider() {
      const requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      return fetch(this.apiUrl, requestOptions)
        .then(response => response.json())
        .then(response => {
          let resultadosFiltrados = [];
          let activeFilterTerm = String(this.filterTerm).trim().length > 1 ? true : false;
          if (response.leagues) {
            this.leagues = response.leagues;
          }

          // Actualizando el Total
          //if (response.games && response.games.length > 1) {
          //  console.log(response.games.length);
          //}

          let cantidadResultados = 0;

          response.games.map((i, position) => {
            if (i.results) {
              //  No borrar contriene logica para el paginador
              //  necesario position
              if (
                this.currentPage > 1 &&
                position >= (this.currentPage - 1) * this.perPage &&
                position < this.currentPage * this.perPage
              ) {
                if (activeFilterTerm && this.contieneTerm(i)) {
                  resultadosFiltrados.push(i);
                } else if (!activeFilterTerm) {
                  resultadosFiltrados.push(i);
                }
              } else if (this.currentPage == 1 && position < this.perPage) {
                if (activeFilterTerm && this.contieneTerm(i)) {
                  resultadosFiltrados.push(i);
                } else if (!activeFilterTerm) {
                  resultadosFiltrados.push(i);
                }
              } else {
                if (activeFilterTerm && this.contieneTerm(i)) {
                  resultadosFiltrados.push(i);
                }
              }

              cantidadResultados++;
            } else {
              if (activeFilterTerm && this.contieneTerm(i)) {
                resultadosFiltrados.push(i);
              }
            }
          });

          this.totalRows = activeFilterTerm ? resultadosFiltrados.length : cantidadResultados;
          this.resultadoData = resultadosFiltrados;
          return resultadosFiltrados;
        });
    },
    contieneTerm: function(i) {
      const term = String(this.filterTerm)
        .trim()
        .toLocaleUpperCase();

      return i.Team[0].team_name.includes(term) || i.Team[1].team_name.includes(term) ? true : false;
    },
    formatNumeric: function(n) {
      return u.inNumeration("LA", n);
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY hh:mm:ss A");
    },
    getSumatory(FT) {
      if (FT == undefined || FT.length < 2) {
        return "";
      }

      return parseInt(parseInt(FT[0]) + parseInt(FT[1]));
    },
    async updateValuePicker(v) {
      this.$store.commit("showLoader");
      this.fecha = moment(v).format("YYYY-MM-DD");
      setTimeout(() => {
        this.$store.commit("hideLoader");
      }, 1500);
    },
    async changePage() {
      this.$store.commit("showLoader");
      // await this.$store.dispatch("getTicketsClientAsync", { page: pag });
      this.itemsProvider();
      setTimeout(() => {
        this.$store.commit("hideLoader");
      }, 1000);
    }
  },
  async created() {
    await this.$store.dispatch("searchResults");
  },
  mounted() {
    this.$emit("barSolid", true);
    this.itemsProvider();
  }
};
</script>
<style>
#container-resultados {
  padding-top: 100px;
}
#bonos > div.container-bonus > table > tbody > tr:hover {
  cursor: auto;
}

input.input-search-team:not(.impCustom-Light)::-moz-placeholder {
  color: white;
}
input.input-search-team:not(.impCustom-Light):-moz-placeholder {
  color: white;
  font-size: 14px !important;
}
input.input-search-team:not(.impCustom-Light)::-webkit-input-placeholder {
  color: white;
  font-size: 14px !important;
}
input.input-search-team:not(.impCustom-Light):-ms-input-placeholder {
  color: white;
  font-size: 14px !important;
}
input.input-search-team:not(.impCustom-Light):-moz-placeholder {
  color: white;
  font-size: 14px !important;
}

input.input-search-team.impCustom-Light::-moz-placeholder {
  color: #232323 !important;
}

input.input-search-team.impCustom-Light::-webkit-input-placeholder,
input.input-search-team.impCustom-Light:-ms-input-placeholder,
input.input-search-team.impCustom-Light:-moz-placeholder {
  color: #232323 !important;
}

/*font-size: 23px;*/
._h1.text-center.title-h1-size,
.title-h1-size {
  font-weight: 300 !important;
  font-size: 2.5em;
}
div.caja-tabla > table > tbody tr td {
  vertical-align: middle;
}

div.caja-tabla > table.table.table-light > tbody td {
  height: 1em;
}

div.caja-tabla > table.table.table-light td,
div.caja-tabla > table.table.table-light th {
  padding: 0.11rem;
}

#container-resultados {
  min-height: 900px;
}

body {
  /* background-color: #232323; */
}

.th-hora {
  width: 200px;
  text-align: center;
  cursor: pointer;
}

.th-100 {
  width: 100px;
}

table#dinamic-results {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}

table#dinamic-results thead > tr th {
  position: sticky;
  top: 0;
  /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

ul.customPagination:not(.light) > li > a,
ul.customPagination:not(.light) > li > span.page-link,
ul.customPagination:not(.light) > li > button.page-link,
.customPagination:not(.light).page-link {
  background-color: #222529;
  border-color: #222529;
  color: #e5eaff;
  color: #e5eaff;
}

.customPagination:not(.light) > li.active > a,
.customPagination:not(.light) .page-item.active .page-link {
  color: #e5eaff;
  background-color: #222529 !important;
  border-color: #222529 !important;
}

.customPagination:not(.light) > li > a:hover {
  color: #e5eaff;
  background-color: #222430 !important;
}

.customPagination:not(.light) > li.page-item.disabled > span.page-link {
  background-color: rgba(0, 0, 0, 0.4);
  border-color: #222529;
  color: #c1c1c1;
}

.customPagination:not(.light) > li.page-item.active > button.page-link {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.lbl-full {
  width: 100%;
  margin-left: 30px;
}

.datepicker-custom input[type="text"] {
  border-color: #6c757d;
  color: #495057;
  border: 1px solid #ced4da;
  text-align: center;
  height: 38px;
  background-clip: padding-box;
  border-radius: 0.25rem;
}

/*padding-top: 100px;*/
@media screen and (min-width: 720px) {
  #container-resultados {
    overflow-y: hidden;
    height: 800px;
  }
}
</style>
<style scoped="scoped">
.impCustom {
  background-color: #495057;
  color: #fff;
  /* font-size: 1.5em; */
  opacity: 1;
  border: none;
}

section {
  display: block;
  height: 100vh;
}

.container {
  margin-top: 60px;
}

h1,
p {
  color: #fff;
}

span {
  color: rgb(243, 156, 18);
}

.container-user {
  background-color: #c1c1c1;
  font-weight: 100;
}

.user.card-title {
  text-align: justify;
  width: 100%;
  padding: 0;
  font-weight: 300;
}

.lbl-user {
  font-weight: 500;
}

.user-card {
  text-align: justify;
  color: #6e7999;
}

.card-subtitle {
  text-align: center;
  font-weight: 400;
}

.nowrap {
  white-space: nowrap;
}

.noborder {
  border: 0px;
  background-color: transparent !important;
}

.col-date {
  text-align: center;
  line-height: 5;
}

.full-content {
  width: 100%;
}

.title-orange {
  font-size: 2em;
  font-weight: 300;
  text-transform: uppercase;
}

span.badge {
  color: #6c757d !important;
}

.card-body p {
  color: #000 !important;
}
.card-body-dark {
  background: #212529 !important;
  height: 70px;
  display: flex;
  justify-content: center;
}

.card-body {
  /* background: #212529 !important; */
  /* color: #fff !important; */
}

.pagination.b-pagination.pagination-sm {
  float: right;
}

.customerToDark {
  background: #212529 !important;
}

.h50 {
  height: 50%;
}
.oncursor {
  cursor: pointer;
}
.table-customer {
  /* margin-top:-40px */

  border: 1px solid black;
  overflow-x: scroll;
  max-height: 350px;
}

table.table.table-dark td,
table.table.table-dark th {
  padding: 0.3rem;
}
table.table.table-dark > tbody td {
  height: 1.5em;
}
.caja-tabla {
  height: 500px !important;
  overflow: auto;
  margin-top: -2px;
}
</style>
