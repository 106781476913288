<template>
  <div>
    <div class="container contenedor-edit-perfil">
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label class="color-text">{{ $t("nombre_y_apellido") }}*</label>
            <input
              type="text"
              v-model="name"
              disabled
              :class="{
                'form-control': true
              }"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="usuario" class="label-formulario">{{ $t("usuario") }}</label>
            <input
              type="text"
              v-model="user"
              disabled
              :class="{
                'form-control': true
              }"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="cedula" class="label-formulario">{{ $t("cedula") }}</label>
            <input
              type="text"
              disabled
              v-model="cedula"
              :class="{
                'form-control': true
              }"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="fecha" class="label-formulario">{{ $t("fecha_de_nacimiento") }}</label>
            <Datepicker
              :language="languages[language]"
              v-model="fecha"
              placeholder="Seleccione fecha"
              class="datepicker-custom max100"
            ></Datepicker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label for="correo" class="label-formulario">{{ $t("correo_electrónico") }}</label>
            <input
              type="email"
              v-model="email"
              disabled
              :class="{
                'form-control': true
              }"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="pais" class="label-formulario">Pa&iacute;s(moneda)</label>
            <input type="text" class="form-control" id="pais" disabled />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="estado" class="label-formulario">Estado</label>
            <select name="cities" v-model="apartment_id" class="form-control">
              <option v-for="(cities, index) in getCities" :key="index" :value="cities.id" v-show="getCities">
                {{ cities.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="ciudad" class="label-formulario">{{ $t("ciudad") }}</label>
            <input type="text" v-model="ciudad" class="form-control" id="ciudad" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <div class="form-group">
            <label for="prefijo" class="label-formulario">{{ $t("prefijo_internacional") }}</label>
            <input type="text" v-model="pref_int" class="form-control" id="prefijo" disabled />
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <label for="codigo" class="label-formulario">{{ $t("codigo_de_area") }} *</label>
            <select name="countries" v-model="prefijoSelected" class="form-control">
              <option v-for="(prefijo, index) in prefMovil" :key="index" :value="prefijo.code">
                {{ prefijo.code }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="telefono" class="label-formulario">{{ $t("telefono_Fijo_celular") }} *</label>
            <input type="text" v-model="phone" class="form-control" id="telefono" />
          </div>
        </div>
        <div class="col-5">
          <br />
          <div class="form-group">
            <div class="form-check">
              <label class="form-check-label" for="gridCheck">
                {{ $t("notificaciones_via_sms") }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </label>
              <input class="form-check-input" type="checkbox" id="gridCheck" />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="row justify-content-center">
        <div class="col-1"></div>
        <div class="col-5">
          <div class="text-center">
            <button type="button" v-on:click="submit" class="btn btn-primary boton-color-fondo">
              {{ $t("guardar") }}
            </button>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";

export default {
  name: "EditPerfil",
  mixins: [validationMixin],
  computed: {
    isLoged() {
      return this.getUser && this.getUser != null && this.getUser != undefined ? true : false;
    },
    ...mapGetters(["getUser", "getIsMobilResolution", "getCities"])
  },
  components: {
    Datepicker
  },
  validations: {
    ciudad: {
      required,
      minLength: minLength(3)
    },
    phone: {
      required,
      minLength: minLength(7)
    },
    password: {
      required,
      minLength: minLength(8)
    }
  },
  data() {
    return {
      name: "",
      email: "",
      cedula: null,
      fecha: moment(new Date().getFullYear() - 18 + "0101", "YYYYMMDD").format("llll"),
      language: "es", // the language I need
      languages: lang,
      user: "",
      pref_int: "",
      prefMovil: [{ code: "412" }, { code: "424" }, { code: "414" }, { code: "416" }, { code: "426" }],
      prefijoSelected: "412",
      ciudad: "",
      apartment_id: null,
      cities: null
    };
  },
  methods: {
    async submit() {
      this.$store.commit("showLoader");

      const resp = await this.$userService.updateUserprofile({
        coin_id: this.getUser.coin_id,
        fecha_nac: moment(this.fecha, "YYYY-MM-DD").format("YYYY-MM-DD"),
        ciudad: this.ciudad,
        phone: this.phone,
        pref_movil: this.prefijoSelected,
        pref_int: this.pref_int,
        apartment_id: this.apartment_id
      });

      this.$store.commit("hideLoader");

      // actualizacion del User en el storage
      let UserModeladoUpdate = JSON.parse(JSON.stringify(this.getUser));

      UserModeladoUpdate.coin_id = this.coin_id;
      UserModeladoUpdate.fecha_nac = this.fecha_nac;
      UserModeladoUpdate.ciudad = this.ciudad;
      UserModeladoUpdate.phone = this.phone;
      UserModeladoUpdate.pref_movil = this.prefijoSelected;
      UserModeladoUpdate.pref_int = this.pref_int;
      UserModeladoUpdate.apartment_id = this.apartment_id;

      this.$store.commit("setUser", UserModeladoUpdate);

      console.log(resp, "updateUserprofile", UserModeladoUpdate);
      if (resp.error && resp.error != "" && resp.error.toUpperCase() != "OK") {
        this.Swal.fire("Error", resp.error, "error");
        return;
      }

      this.Swal.fire("Excelente", "Datos actualizados con éxito!", "success");
    },
    getForm() {
      this.name = this.getUser.name;
      this.email = this.getUser.email;
      this.user = this.getUser.username;
      this.cedula = this.getUser.cedula;
      this.pref_int = this.getUser.pref_int;
      this.phone = this.getUser.phone;
      this.prefijoSelected = this.getUser.pref_movil;
      this.ciudad = this.getUser.ciudad;
      this.fecha = this.getUser.fecha_nac;
      this.apartment_id = this.getUser.apartment_id;
    }
  },
  created() {
    this.getForm();
  }
};
</script>
<style scoped>
.contenedor-edit-perfil {
  height: 390px !important;
}
</style>
<style>
.vdp-datepicker__calendar {
  width: 16em !important;
}
</style>
