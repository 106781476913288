<template>
  <vuescroll :ops="ops" class="customBar">
    <nav>
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <!-- Funcional Previo -->
        <li v-for="(menu, indexM) in this.getMenuOptionsLeagues" class="nav-item contenedor-menu" :key="indexM">
          <template v-if="menu.data.length == 1">
            <a
              :data-toggle="`collapse-${indexM}`"
              :class="{
                'nav-link': true,
                'color-dark': !menu.open,
                'color-orange': menu.open
              }"
              v-on:click="openMenuOption(indexM, menu)"
            >
              <p>
                <img :src="renderIcono(menu.name)" width="20" /> {{ menu.name }}
                <i :class="{ 'right fas fa-angle-left': true, open: menu.open }"></i>
              </p>
            </a>
            <ul
              :class="{
                nav: true,
                'nav-open animate__animated animate__fadeIn dsp': menu.open,
                'no-dsp animate__animated animate__fadeOut': menu.open == false
              }"
              :data-toggle="`collapse-${indexM}`"
            >
              <li class="nav-item">
                <a
                  :href="`#${submenu.name}`"
                  class="nav-link"
                  v-for="(submenu, indexS) in menu.data"
                  :key="indexS"
                  v-on:click="changeGame(submenu.sport_id, submenu.id)"
                >
                  <p class="txt-submenu">{{ submenu.name }}</p>
                </a>
              </li>
            </ul>
          </template>
          <template v-else>
            <a
              :data-toggle="`collapse-${indexM}`"
              :class="{ 'nav-link': true, 'color-dark': !menu.open, 'color-orange': menu.open }"
              v-on:click="openMenuOption(indexM, menu)"
            >
              <p>
                <img :src="renderIcono(menu.name)" width="20" /> {{ menu.name }}
                <i :class="{ 'right fas fa-angle-left': true, open: menu.open }"></i>
              </p>
            </a>
            <ul
              :class="{
                nav: true,
                'nav-open animate__animated animate__fadeIn dsp': menu.open,
                'no-dsp animate__animated animate__fadeOut': menu.open == false
              }"
              :data-toggle="`collapse-${indexM}`"
            >
              <li class="nav-item">
                <a
                  :href="`#${submenu.name}`"
                  class="nav-link"
                  v-for="(submenu, indexS) in menu.data"
                  :key="indexS"
                  v-on:click="changeGame(submenu.sport_id, submenu.id)"
                >
                  <p class="txt-submenu">{{ submenu.name }}s</p>
                </a>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </nav>
  </vuescroll>
</template>
<script>
import { mapGetters } from "vuex";
import vuescroll from "vuescroll";
import deportes from "../store/data/deportes.json";
export default {
  name: "SidebarMenuJuegos",
  components: {
    vuescroll
  },
  computed: {
    ...mapGetters([
      "getGames",
      "getMenuOptionsLeagues",
      "getMenuOptionsLeaguesDestacados",
      "getThemelight",
      "getSportsLeagues"
    ])
  },
  methods: {
    openMenuOption(indexM, menuOption) {
      /* Nuevo modelado
            this.$store.dispatch("openOptionSidebarJuegosSportLeagues", {
                index: indexM,
                menu: menuOption
            });
            */

      this.$store.dispatch("openOptionSidebarJuegos", { index: indexM, menu: menuOption });
    },
    async changeGame(sport_id, league_id) {
      this.$store.commit("showLoader");
      if (league_id != this.idAnt) {
        this.idAnt = league_id;
        await this.$store.dispatch("getGamesAsync", {
          sport_id: sport_id,
          league_id: league_id,
          date: this.$parent.dateTarget
        });

        // scroll to top
        window.scrollTo({ top: 0, behavior: "smooth" });
      }

      this.$emit("destacado", false);
      this.$store.commit("hideLoader");
    },
    renderIcono(icono) {
      const result = deportes.find(x => x.deporte == icono);
      return result && result.path ? result.path : "img/logo/img2x.png";
    }
  },
  mounted() {
    if (this.firstLoad) {
      //this.$emit("updateOptions", "destacados");
      this.firstLoad = false;
    }
    // for (let index = 0; index < deportes.length; index++) {
    //  console.log("asd",deportes[index].deporte)

    // }
  },
  data() {
    return {
      firstLoad: true,
      idAnt: null,
      ops: {
        vuescroll: {
          mode: "native",
          sizeStrategy: "percent",
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false
        },
        scrollPanel: {
          initialScrollY: true,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: "right"
        },
        rail: {
          background: "#01a99a",
          opacity: 0,
          size: "4px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "2px",
          keepShow: false
        },
        bar: {
          showDelay: 100,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: "#c1c1c1",
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0.1,
          size: "20px",
          disable: false
        }
      }
    };
  }
};
</script>
<style scope>
nav > ul.nav.nav-pills.nav-sidebar.flex-column {
  position: initial !important;
}
#container-sidebar-col
  > div.btn-group-vertical.sidebar-container.tl
  > div.customBar.__vuescroll.hasVBar
  > div.__panel
  > div
  > nav
  > ul
  > li
  > a {
  padding: 5px;
  width: 100%;
}
.nav-sidebar .nav-link > p > .right:nth-child(2) {
  right: 0.5rem;
}

li.nav-item.contenedor-menu a.nav-link.color-orange {
  font-weight: 500 !important;
}

li.nav-item.contenedor-menu a.collapse-14 p {
  width: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
}

li.nav-item.contenedor-menu a.collapse-14 p > i {
  right: 0.7em;
  position: inherit;
}

.txt-submenu {
  font-size: 0.8em;
  margin-bottom: 0.1rem;
}

.nav-open,
.nav.nav-open > li.nav-item {
  width: 100%;
  background-color: #343a40 !important;
}

.color-dark {
  background-color: #212529;
  border-radius: 0px !important;
  margin-bottom: 0px !important;
}

/* .no-display {
  display: none !important;
}
 */
.dsp {
  animation-name: goDown;
  animation-duration: 0.2s;
  overflow: auto;
}

.no-dsp {
  animation-name: goUp;
  animation-duration: 0.2s;
}

@keyframes goDown {
  from {
    max-height: 0;
  }

  to {
    max-height: 1000vh;
    opacity: 1;
  }
}

@keyframes goUp {
  from {
    max-height: 50vh;
  }

  to {
    max-height: 0;
    opacity: 0;
  }
}

.right.fas.fa-angle-left {
  transition: transform ease-in-out 0.2s;
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}

.right.fas.fa-angle-left.open {
  transform: rotate(-90deg);
}

.customBar.__vuescroll,
.customBar.__vuescroll.hasVBar {
  height: auto !important;
  padding-bottom: 10em !important;
}

.contenedor-menu {
  width: 100%;
  z-index: 1;
  padding-right: 0px;
}

a.nav-link.color-orange,
a.nav-link.color-dark {
  margin-bottom: 0px !important;
  border-radius: 0px !important;
}

section,
.customBar.__vuescroll {
  width: 100% !important;
  padding: 0;
  margin: 0;
  padding-bottom: 180px;
}

.color-orange {
  /* test1
    background: #ffb347;
    background: -webkit-linear-gradient(to right, #ffcc33, #ffb347);
    background: linear-gradient(to right, #ffcc33, #ffb347);
     */

  /* Segundo tono */

  background: #232526;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #414345, #232526);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #414345, #232526);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);

  /* transition the background-color over 1s with a linear animation */
  transition: background 1s linear;
  -moz-transition: background 1s linear;
  -webkit-transition: background 1s linear;
  -ms-transition: background 1s linear;
}

.nav {
  cursor: pointer;
}

.color-orange:hover,
a.nav-link.color-dark:hover {
  background: #ff512f;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f09819, #ff512f);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f09819, #ff512f);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.btn.btn-primary.text-center.btn-generar.color-orange:hover {
  columns: #fff !important;
  font-weight: 1.5em !important;
}

.btn.color-submenu {
  cursor: pointer !important;
  border-radius: 0px;
  font-size: 0.82em !important;
}

.color-submenu {
  background-attachment: cadetblue;
  color: #fff;
}

.btn.color-submenu:hover {
  color: #ffbe3e;
}
</style>
