import jwt from "jsonwebtoken";
export const strict = false;
const userService = {
  tk: null,
  async getToken(userData) {
    // Servicio Custom para obtencion del token
    const urlApi = process.env.VUE_APP_URL_API + "oauth/token";
    let myHeaders = new Headers();
    let formdata = new FormData();

    formdata.append("grant_type", "password");
    formdata.append("client_id", process.env.VUE_APP_API_ID);
    formdata.append("client_secret", process.env.VUE_APP_API_SECRET);
    formdata.append("username", new String(userData.user).toUpperCase());
    formdata.append("password", userData.pass);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    console.log("URL:::::::", urlApi);
    console.log("DATOS:::::", requestOptions);

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        const dataToken = await response.clone().json();

        this.tk = dataToken.access_token ? dataToken.access_token : null;
        let refresh_token = dataToken.refresh_token;
        localStorage.setItem("api_tk", this.tk);
        localStorage.setItem("api_r_tk", refresh_token);

        let currentTimeMomentum = await (new Date().getTime() + 1000);
        // Captura del momento que hizo login
        localStorage.setItem("momentum", currentTimeMomentum);

        let tokenSerealized = await jwt.sign(
          {
            tk: this.tk,
            creation: new Date(),
            online: true
          },
          process.env.VUE_APP_API_SECRET,
          { expiresIn: parseInt((parseInt(dataToken.expires_in) * 3) / 60) + "m" }
          //{ expiresIn: parseInt(300) + "s" }
          // formato de tiempo minutos Viene en segundos del servicio
        );

        /*
          ejemplos para expiracion
          expiresIn:
          "120ms" - milisegundos
          "30m" - minutos
          "1h" - Horas
          "7d" - dias
        */

        if (localStorage) {
          await localStorage.setItem("sp_tk", tokenSerealized);
        }

        // Limpiando antigua data Temporal
        if (localStorage && localStorage.getItem("apuestas")) {
          await localStorage.removeItem("apuestas");
        }

        return dataToken;
      })
      .catch(error => console.log("error", error));
  },
  async registerAccount(userData) {
    // Servicio para registro de cuentas
    const urlApi = process.env.VUE_APP_URL_API + "api/user";
    let myHeaders = new Headers();
    let formdata = new FormData();

    formdata.append("name", userData.name);
    formdata.append("cedula", userData.cedula);
    formdata.append("email", userData.email);
    formdata.append("password", userData.password);
    formdata.append("phone", userData.phone);
    formdata.append("coin_id", userData.coin_id);
    formdata.append("fecha_nac", userData.fecha_nac);
    formdata.append("ciudad", userData.ciudad);
    formdata.append("pref_movil", userData.pref_movil.toString().substr(-3, 3));
    formdata.append("pref_int", userData.pref_int);
    formdata.append("bonus_col", userData.bonus_col);
    formdata.append("nacionalidad", userData.nacionalidad);
    if (userData.code_promotion) {
      formdata.append("code_promotion", userData.code_promotion);
    }

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.clone().json();
      })
      .catch(error => console.log("error", error));

    /*
      name:julio
      cedula:18266159
      email:julio899@gmail.com
      password:Vinachi89.
      coin_id:3
      fecha_nac:1989-01-17
      ciudad:Maracay
      pref_movil:0412
      phone:2716159
      pref_int:58
      bonus_col:1
      nacionalidad:V-
      //code_promotion:none
    */
  },

  async registerUserCriptoAccount(accountData) {
    const urlApi = process.env.VUE_APP_URL_API + "api/crypto/account";

    let accounts = {};

    accounts = {
      gateway: accountData.gateway,
      account: accountData.account,
      name: accountData.name
    };

    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("api_tk")}`
      },
      body: JSON.stringify(accounts),
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.clone().json();
      })
      .catch(error => console.log("error", error));
  },

  async registerUserBankAccount(accountData) {
    // Servicio para registro de cuenta bancaria de usuario
    const urlApi = process.env.VUE_APP_URL_API + "api/client/account";

    let accounts = {};

    accounts = {
      type_account_id: accountData.typeAccountId,
      typeAccountId: accountData.typeAccountId,
      account: accountData.account,
      bank_id: accountData.bankId
    };

    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("api_tk")}`
      },
      body: JSON.stringify(accounts),
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.clone().json();
      })
      .catch(error => console.log("error", error));
  },

  async deleteUserBankAccount(idAccount) {
    const urlApi = process.env.VUE_APP_URL_API + `api/client/account?id=${idAccount}`;
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("api_tk")}`);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.clone().json();
      })
      .catch(error => console.log("error", error));
  },

  async updateUserprofile(dataUser) {
    const urlApi = process.env.VUE_APP_URL_API + `api/user`;
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("api_tk")}`);
    myHeaders.append("Content-Type", `application/json`);

    /*
      data structura
      {
        "coin_id":1,
        "fecha_nac":"1986-11-09",
        "phone":"7425353",
        "ciudad":"Achaguas",
        "pref_movil":"424",
        "pref_int":"58",
        "apartment_id":36
      }
    */
    const data = JSON.stringify(dataUser);
    console.log(data);
    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
      body: data
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.clone().json();
      })
      .catch(error => console.log("error", error));
  },
  async registerPayment(paymentData) {
    // Servicio para registro de pago
    const urlApi = process.env.VUE_APP_URL_API + "api/client/payment";

    let dataPayment = {};

    dataPayment = {
      bank_id: paymentData.bankId,
      date: paymentData.date,
      number_operation: paymentData.operationNumber,
      pay_operation_id: paymentData.payOperationId,
      monto: paymentData.monto,
      account_id: paymentData.accountId
    };

    let requestOptions = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("api_tk")}`
      },
      method: "POST",
      body: JSON.stringify(dataPayment),
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.clone().json();
      })
      .catch(error => console.log("error", error));

    /*
      date:2021-03-02
      number_operation:4
      bank_id:1
      pay_operation_id:1
      monto:1000000
      account_id:1
    */
  },
  async registerPaymentMonnet(paymentData) {
    const urlApi = process.env.VUE_APP_URL_API + "api/client/payment_monnet";

    let requestOptions = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("api_tk")}`
      },
      method: "POST",
      body: JSON.stringify(paymentData),
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.clone().json();
      })
      .catch(error => console.log("error", error));
  },
  async registerMovil(movilData) {
    const urlApi = process.env.VUE_APP_URL_API + "api/client/account";

    let accounts = {};

    accounts = {
      type_account_id: movilData.typeAccountId,
      typeAccountId: movilData.typeAccountId,
      account: movilData.account,
      bank_id: movilData.bankId,
      code: movilData.code ? movilData.code : null // por ley requere el codigo de confirmacion no quitarlo para el registro de pago mobil
    };

    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("api_tk")}`
      },
      body: JSON.stringify(accounts),
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.clone().json();
      })
      .catch(error => console.log("error", error));
  },
  async userActivate(tk) {
    const urlApi = process.env.VUE_APP_URL_API + "api/user/activate";

    let requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`
      },
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        // console.log(response,response.clone().json())
        return await response.text();
      })
      .catch(error => console.log("error", error));
  },
  async recoveryPass(email) {
    const urlApi = process.env.VUE_APP_URL_API + "api/user/recovery_pass";

    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email }),
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.text();
      })
      .catch(error => console.log("error", error));
  },

  async changePass(password, confirm_password) {
    const urlApi = process.env.VUE_APP_URL_API + "api/client/change_pass";

    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("api_tk")}`
      },
      body: JSON.stringify({ password, confirm_password }),
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.text();
      })
      .catch(error => console.log("error", error));
  },
  async registerWithdrawCripto(withdrawData) {
    const urlApi = process.env.VUE_APP_URL_API + "api/crypto/withdraw";

    let payment = {};

    payment = {
      gateway: withdrawData.gateway,
      amount: Number(withdrawData.amount)
    };

    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("api_tk")}`
      },
      body: JSON.stringify(payment),
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.clone().json();
      })
      .catch(error => console.log("error", error));
  },
  async registerWithdraw(withdrawData) {
    // Servicio para registro solicitud de retiro
    const urlApi = process.env.VUE_APP_URL_API + "api/client/withdraw";

    let payment = {};

    payment = {
      charge_operation_id: withdrawData.chargeOperationId,
      client_account_id: withdrawData.clientAccountId,
      monto: withdrawData.monto
    };

    let requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("api_tk")}`
      },
      body: JSON.stringify(payment),
      redirect: "follow"
    };

    return await fetch(urlApi, requestOptions)
      .then(async response => {
        return await response.clone().json();
      })
      .catch(error => console.log("error", error));
  },
  async getFrequentlyQuestions(handerControllerPeticion) {
    const urlApi = process.env.VUE_APP_URL_API + "api/contenido/preguntas_frecuentes";
    const questions = await fetch(urlApi, { method: "GET" })
      .then(async response => {
        const responseC = response.clone();
        return await responseC.json().then(objJson => {
          return objJson;
        });
      })
      .then(async r => {
        const hasError = await handerControllerPeticion(r, "preguntas frecuentes");

        if (hasError != -1) {
          return r;
        }

        return [];
      })
      .catch(error => console.log("error", error));
    return questions;
  },
  async getTermsAndConditions(handerControllerPeticion) {
    const urlApi = process.env.VUE_APP_URL_API + "api/contenido/reglamentos";
    return await fetch(urlApi, { method: "GET" })
      .then(async response => {
        const responseC = response.clone();
        return await responseC.json();
      })
      .then(async r => {
        const hasError = await handerControllerPeticion(r, "termonos & condiciones");

        if (hasError != -1) {
          return r;
        }

        return {
          cont5: null,
          cont6: null,
          cont9: null,
          cont10: null,
          cont11: null
        };
      })
      .catch(error => console.log("error", error));
  },
  async login(objLogin) {
    return await this.getToken(objLogin);
  }
};

export { userService };
