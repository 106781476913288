<template>
  <div class="container-tickets" v-if="!this.getIsMobilResolution && getTickets && getTickets.data">
    <table
      :class="{
        'table table-hover table-striped mb-0': true,
        'table-dark': !this.getThemelight,
        'table-light': this.getThemelight
      }"
    >
      <thead>
        <tr class="text-center">
          <th style="max-width: 120px;width: 120px;">Id</th>
          <th style="max-width: 120px;width: 120px;">{{ $t("numero") }}</th>
          <th style="max-width: 200px;width: 200px;">{{ $t("fecha") }}</th>
          <th style="max-width: 150px;width: 150px; text-align: right;">{{ $t("numero") }}</th>
          <th style="max-width: 150px;width: 150px; text-align: right;">{{ $t("premio") }}</th>
          <th style="max-width: 150px;width: 150px;">Estado</th>
          <!-- <th>Detalles</th> -->
        </tr>
      </thead>
    </table>
    <div style="height: 25.5em; overflow: auto; margin-top: -2px;">
      <table
        :class="{
          table: true,
          'table-dark': !this.getThemelight,
          'table-light': this.getThemelight
        }"
        style="font-size: 0.85em;"
      >
        <tbody>
          <tr
            v-for="(ticket, index) in getTickets.data"
            :key="index"
            v-show="getTickets && getTickets.data"
            class="text-center tr-selector"
            @click="setTarget(ticket)"
            :data-index="index"
          >
            <td style="max-width: 120px;width: 120px;">
              <b>{{ ticket.id }}</b>
            </td>
            <td class="txt-center" style="max-width: 120px;width: 120px;">
              {{ ticket.number }}
            </td>
            <td class="txt-center" style="max-width: 200px;width: 200px;">
              {{ dateFormat(ticket.created_at) }}
            </td>
            <td class="text-right" style="max-width: 150px;width: 150px;">
              <b>{{ formatoMonto(ticket.amount) }}</b>
            </td>
            <td class="text-right" style="max-width: 150px;width: 150px;">
              {{ formatoMonto(ticket.amount_ind) }}
            </td>
            <td style="max-width: 150px;width: 150px;">{{ ticket.status }}</td>
          </tr>
        </tbody>
      </table>

      <b-modal
        id="details-ticket"
        size="lg"
        content-class="details-ticket container-tickets-interno"
        :header-bg-variant="!this.getThemelight ? 'dark' : 'light'"
        :body-bg-variant="!this.getThemelight ? 'dark' : 'light'"
        :footer-bg-variant="!this.getThemelight ? 'dark' : 'light'"
        centered
        scrollable
        title="DETALLE DEL TICKET"
        hide-footer
        ref="details-ticket"
      >
        <template>
          <table
            :class="{
              'table table-top-details': true,
              'table-dark': !this.getThemelight,
              'table-light': this.getThemelight
            }"
            cellpadding="0"
            cellspacing="0"
            style="width: 100%;"
          >
            <tbody v-if="this.ticketTarget">
              <tr>
                <td>
                  {{ $t("numero_ticket") }}: <b>{{ this.ticketTarget.number }}</b>
                </td>
                <td style="text-align: right;">
                  {{ $t("fecha") }}:
                  <b v-if="this.ticketTarget.created_at">{{ this.dateFormat(this.ticketTarget.created_at) }}</b>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("monto_total_apostado") }}:
                  <b class="lbl-total-apostado" v-if="this.ticketTarget.amount_ind"
                    >Bs {{ this.formatoMonto(this.ticketTarget.amount_ind) }}</b
                  >
                </td>
                <td style="text-align: right;">
                  {{ $t("premio_total_estimado") }} :
                  <b>Bs {{ formatoMonto(this.ticketTarget.prize) }}</b>
                </td>
              </tr>
              <tr>
                <td v-if="this.getTipeTicket(this.ticketTarget.modalidad) == 'Directas'">
                  {{ $t("monto_cada_jugada") }}:
                  <b>Bs {{ this.formatoMonto(this.ticketTarget.amount) }}</b>
                </td>
                <td
                  :style="
                    this.getTipeTicket(this.ticketTarget.modalidad) == 'Directas'
                      ? 'text-align: right;'
                      : 'text-align: left;'
                  "
                  :colspan="this.getTipeTicket(this.ticketTarget.modalidad) == 'Directas' ? 1 : 2"
                >
                  Ticket {{ $t("tipo") }}:
                  <b>{{ this.getTipeTicket(this.ticketTarget.modalidad) }}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            :class="{
              'table table-cuerpo-details': true,
              'table-dark': !this.getThemelight,
              'table-light': this.getThemelight
            }"
            cellpadding="0"
            cellspacing="0"
            id="details"
            style="width: 100%;"
          >
            <thead>
              <tr>
                <th style="text-align: center;">#</th>
                <th style="text-align: center;">{{ $t("fecha") }}</th>
                <th style="text-align: center;">{{ $t("equipo") }}</th>
                <th style="text-align: center;">{{ $t("apuestas") }}</th>
                <th style="text-align: center;">{{ $t("logro") }}</th>
                <th style="text-align: center;" v-if="getTipeTicket(ticketTarget.modalidad) == 'Directas'">
                  {{ $t("premio") }}
                </th>
                <th style="text-align: center;">ESTATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(odd, index) in this.ticketTarget.odds" :key="index">
                <th>{{ odd.n }}</th>
                <td>{{ dateFormat(odd.time) }}</td>
                <td style="text-align: left;">
                  <p>{{ odd.league_name }}: <b v-html="odd.teams"></b></p>
                </td>
                <td style="text-align: center;">
                  {{ odd.type }}
                  ({{ odd.final }})
                  <!-- {{ odd.odd_type }} -->
                  <!-- ticketTarget.code.odd_by_type[odd.type] -->
                </td>
                <td style="text-align: right;">
                  <!-- {{ formatoPrefijo(odd.odd) }} -->
                  <div
                    class="fraction-container"
                    v-html="`<div>${formatoPrefijo(odd.odd)} </div><div>${toFraction(odd.factor)} </div>`"
                  ></div>
                </td>
                <td style="text-align: right;" v-if="getTipeTicket(ticketTarget.modalidad) == 'Directas'">
                  {{ ticketTarget.prize }}
                </td>
                <td style="text-align: center;">{{ odd.status }}</td>
              </tr>
            </tbody>
          </table>
          <b-button class="mt-3 float-right btn-cerrar-modal-detail btn-secondary" @click="hideModal">{{
            $t("premio")
          }}</b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { paginatorTable } from "../../mixins/paginatorTableMixin.js";
import { essentialsData } from "../../mixins/essentialsDataMixin.js";

export default {
  name: "TicketsTable",
  props: {
    page: Number,
    perPage: Number
  },
  mixins: [paginatorTable, essentialsData],
  computed: {
    ...mapGetters(["getTickets", "getIsMobilResolution", "getLeagues", "getGames", "getThemelight"])
  },
  methods: {
    hideModal() {
      this.$refs["details-ticket"].hide();
    },
    getTipeTicket(m) {
      return m === "false" || m === false ? "Directas" : "Parley";
    },
    async setTarget(ticket) {
      /*
      await this.$store.dispatch("getGamesAsync",{
        game_id:ticket.odds[0].game_id,
        league_id:ticket.odds[0].league,
        // date:ticket.odds[0].time.split(' ')[0]
      });

      console.log(
        ticket.odds[0].game_id,
        ticket.odds[0].league,
        ticket.odds[0].time.split(' ')[0],
        ticket
        );
      //game_id:1188326
      //league:153
      // await this.$store.dispatch("getLeaguesAsync");
    */

      this.ticketTarget = new Object(ticket);
      this.$bvModal.show("details-ticket");
    },
    formatoMonto(value) {
      if (value === "0" || value === 0 || value === "" || value == null || value == undefined) {
        return "0,00";
      }

      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm A");
      }
    }
  },
  data() {
    return {
      ticketTarget: {
        number: null,
        id: null,
        code: null,
        odds: []
      },
      currentPage: 1,
      totalResults: 0
    };
  },
  mounted() {
    this.totalResults = this.getTickets ? parseInt(this.getTickets.total) : 0;
    this.currentPage = this.getTickets ? parseInt(this.getTickets.page) : 0;
    this.$emit("updateTotalResults", this.totalResults);
    this.$emit("updateLimitResults", this.perPage);
    this.$emit("updatePageResults", this.currentPage);
  }
};
</script>
<style>
.fraction-container,
.fraction-container div {
  text-align: center;
}

.btn-cerrar-modal-detail {
  width: 150px;
  text-transform: uppercase;
}
.table-top-details {
  background-color: #49535c;
}

.table-top-details tr,
.table-top-details tr td,
.table-top-details tr th {
  border: none;
  padding: 5px 10px;
  margin: 0;
  line-height: 1;
  height: 30px;
}

.table-cuerpo-details tr td p {
  margin: 0px;
  padding: 0px !important;
}

.table-cuerpo-details tr,
.table-cuerpo-details tr td,
.table-cuerpo-details tr th {
  padding: 0.5em;
  margin: 0;
  line-height: 1.8;
}

.tr-selector:hover {
  cursor: pointer;
}

.modal-dialog.modal-lg.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  border-color: #282a2c;
  border-radius: 12px;
  outline-color: transparent;
}
.modal-header.bg-dark {
  border-bottom-width: thin !important;
  border-bottom-style: dashed !important;
  border-bottom-color: rgba(255, 255, 255, 0.3) !important;
}

.modal-footer.bg-dark {
  border-top: none !important;
}

.modal-content {
  background: transparent;
}

div.modal-content.details-ticket.container-tickets-interno {
  width: 1300px !important;
  /*  max-width: 1300px !important;*/
  max-width: 95vw !important;
}

.fraction-txt {
  font-size: 0.75em;
  font-weight: 300;
}

.fraction-container {
  letter-spacing: 0px;
  font-family: "Lato";
}
</style>
<style scoped="scoped">
.card {
  margin-top: 100px;
  width: 95%;
}

.col-ticket {
  width: 20%;
}

.col-type {
  width: 28%;
}

.txt-center {
  text-align: center;
}

.col-ticket,
.col-ticket > b {
  word-wrap: break-word;
  max-width: 200px;
}

.table-responsive.mailbox-messages,
.table-responsive.mailbox-messages > div,
.table-tickets,
.no-margin {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.col-icon {
  width: 50px;
}

.col-type {
  width: 220px;
}

.container-tickets {
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
}

table > thead > tr > th {
  font-weight: 300;
}
</style>

<style scoped>
table > tbody > tr:hover {
  background-color: rgb(255, 255, 255);
  color: #343a40;
}
</style>
