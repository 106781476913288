// This is just an example,
// so you can safely delete all default props below

export default {
  inicio: "Home",
  Jugar: "Play",
  iniciar_sesion_registro: "LOG IN / REGISTER",
  iniciar_sesion: "LOG IN",
  contacto: "CONTACT US!",
  recuperar_contraseña: "Recover Password",
  usuario: "User",
  contraseña: "Password",
  recordar_datos_de_acceso: "Remember access data",
  olvide_mi_contraseña: "I forgot my password",
  correo_electrónico: "Email",
  enviar_correo: "Send mail",
  registrarse: "Check in",
  terminos_condi: "I accept the terms and conditions",
  terminos_condi_leer: "Read Bonuses: Terms and Conditions",
  deportes: "Sports",
  juegos_virtuales: "Virtual Games",
  sorteos_tradicionales: "Traditional Draws",
  mi_cuenta: "My account",
  preguntas_frecuentes: "Frequently Asked Questions",
  como_apostar: "How to Bet?",
  tipos_de_apuestas: "Bet Types",
  terminos_y_condiciones: "Terms and Conditions",
  bonos_terminos_y_condiciones: "Bonuses Terms and Conditions",
  calculadora: "Calculator",
  resultados: "Results",
  recargas: "Deposit",
  saldo: "Balance",
  bono: "Bonus",
  americano: "American",
  decimal: "Decimal",
  seleccion_de_apuestas: "Bet Selection",
  directas: "Direct",
  generar_apuesta: "Generate Bet",
  error_en_monto_por_favor_ingrese_un_monto_valido: "Error in amount Please enter a valid amount",
  premio: "Reward",
  cambiar_contraseña: "CHANGE PASSWORD",
  nueva_contraseña: "New Password",
  confirmar_contraseña: "Confirm Password",
  actualizar_contraseña: "Update Password",
  entradas: "Tickets",
  nombre_y_apellido: "Name and surname",
  cedula: "Identification card",
  ciudad: "City",
  prefijo_internacional: "International prefix",
  codigo_de_area: "Area code",
  telefono_Fijo_celular: "Phone",
  notificaciones_via_sms: "You want to receive notifications via SMS",
  notificaciones_via_sms_acepta: "Accept notifications via SMS?",
  guardar: "SAVE",
  footer1: "Sellatuparley.com is Regulated for the Bolivarian Republic of Venezuela by SUNAHIP and CONALOT License No.",
  footer2:
    "Sellatuparley.com operates under Games & More B.V Company No.149948, Fransche Bloemweg 4, Curacao, License Nr 1668/JAZ and Power Gaming Ltd (Abacus Business Centre, Level 1 Dun Karm Street,",
  footer3: "B'Kara Bypass Birkirkara - Company nr C77981) como Procesador de pagos",
  footer4: "All rights reserved",
  registro_de_usuario: "USER REGISTER",
  moneda: "Currency",
  nombre_requerido: "Name is required with a minimum of 3 characters",
  fecha_de_nacimiento: "Date of Birth",
  identificacion: "Id",
  cedula_validacion: "Just enter numeric characters between 1 and 100 million",
  ciudad_requerida: "You must enter the name of the city.",
  celular_validar: "You must enter at least 7 digits.",
  correo_validar: "It must be a valid e-mail format.",
  contraseña_validar1: "It must contain at least 8 characters.",
  contraseña_validar2: "Both Passwords must be the same.",
  repita_contraseña: "Repeat Password",
  codigo_registro: "Registration code (only if you have one)",
  verifique_datos: "Please check the following data:",
  nombre: "Name",
  registrarme: "REGISTER",
  agregar: "Add",
  calcular: "Calculate",
  resetear: "To reset",
  monto: "Amount",
  cuotas: "Dues",
  cerrar: "Close",
  bienvenido_1: "Welcome to our",
  bienvenido_2: "help center",
  telefono: "Phone",
  datos_del_cliente: "Client data",
  direccion: "Address",
  pais: "Country",
  codigo_postal: "Postal Code",
  tipo_de_transaccion: "Transaction Type",
  saldo_diferido: "Saldo Diferido",
  modulos: "Mudules",
  reglamento_general: "GENERAL REGULATIONS",
  lotto_activo: "ACTIVE LOTTO",
  rueda_de_la_fortuna: "WHEEL OF FORTUNE",
  apuestas: "Bets",
  monto_de_la_apuesta: "Bet Amount",
  error_monto: "Error in amount Please enter a valid amount",
  fecha: "Date",
  tipo: "Type",
  juegos_destacados: "FEATURED GAMES",
  asunto: "Affair",
  numero: "Number",
  numero_ticket: "Ticket number",
  monto_total_apostado: "Total Amount Bet",
  premio_total_estimado: "Estimated Total Award",
  equipo: "Team",
  logro: "ACHIEVEMENT",
  monto_cada_jugada: "Amount Each Play",
  estado_de_cuenta: "Account status",
  nota: "Note",
  mis_cuentas_bancarias: "My bank accounts",
  reporte_semanal: "Weekly report",
  editar_perfil: "Edit profile",
  cambio_clave: "Password change",
  banco: "Bank",
  cuentas_bancarias: "Bank accounts",
  tipo_de_cuenta: "Account type",
  numero_de_cuenta: "Account number",
  agregar_cuenta: "Add account",
  regresar: "To return",
  accion: "Action",
  hipismo_internacional_y_nacional: "INTERNATIONAL AND NATIONAL RACING",
  ocultar: "HIDE",
  ver_tickets_jugados: "See tickets played",
  hoy: "Today",
  todas: "All",
  videos_1: "You must have bets today to be able to watch the videos",
  minimizar: "Minimize",
  mensaje: "Message",
  respuesta: "Response",
  enviar: "Send",
  cancelar: "Cancel",
  mensajeria: "Messenger service",
  limite: "Limit",
  pagina: "Page",
  paginas: "Pages",
  total_de_registros: "Total records",
  codigo: "Code",
  banco_origen: "Origin Bank",
  banco_destino: "Banco destino",
  numero_de_referencia: "Reference number",
  fecha_de_la_transaccion: "transaction date",
  optar_por_bonos_de_recargas: "Opt for reload bonuses",
  ver: "Watch",
  codigo_de_confirmacion_enviado_a_su_telefono: "Confirmation code sent to your phone",
  pasarela: "Runway"
};
