<template>
  <section class="container animate__animated animate__backInUp">
    <div class="row mt-3" v-if="getUser" :class="{ 'top-dash': this.getVisualWidth > 500 }">
      <div class="col">
        <div class="card card-widget widget-user-2 shadow-sm mt-5">
          <!-- Add the bg color to the header using any of the bg-* classes -->
          <div class="widget-user-header bg-blue-theme">
            <div class="col-md-12" style="font-size: 16px">{{ getUser.name }}</div>
            <div class="col-md-12" style="font-size: 14px">{{ getUser.username }}</div>
          </div>
          <div class="card-footer p-0">
            <ul class="nav flex-column">
              <li class="nav-item">
                <a href="#" class="nav-link no-cursor">
                  {{ $t("saldo") }}
                  <span class="float-right badge badges-balances">{{ formatNumeric(getUser.credit) }}</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link no-cursor">
                  {{ $t("saldo_diferido") }}
                  <span class="float-right badge badges-balances">{{ formatNumeric(getUser.diferido) }}</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link no-cursor">
                  Bonus
                  <span class="float-right badge badges-balances">{{ getUser.bonus }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h3 class="card-title mb-0">{{ $t("modulos") }}</h3>
          </div>
          <div class="card-body p-0">
            <ul class="nav nav-pills flex-column">
              <li class="nav-item">
                <a href="#" class="nav-link" v-on:click="toPage('CuentasBancariasMovil')">
                  <i class="fas fa-cash-register"></i> {{ $t("mis_cuentas_bancarias") }}
                </a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link" v-on:click="toPage('PaymentsTable')">
                  <i class="fas fa-money-bill-wave"></i> Retiro
                  <span class="badge badges-balances float-right border" v-if="getPayments && getPayments.total"
                    >{{ getPayments.total }}
                    <i class="fa fa-chevron-right fa-sm ml-2"></i>
                  </span>
                </a>
              </li>
              <li class="nav-item active">
                <a href="#" class="nav-link" v-on:click="toPage('BalancesTableMovil')">
                  <i class="fas fa-inbox"></i> {{ $t("estado_de_cuenta") }}
                  <span class="badge badges-balances float-right" v-if="getBalaces && getBalaces.total"
                    >{{ getBalaces.total }}
                    <i class="fa fa-chevron-right fa-sm ml-2"></i>
                  </span>
                </a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link" v-on:click="toPage('BonusTableMovil')">
                  <i class="fas fa-hand-holding-usd mr-1"></i>Bonus
                  <span class="badge badges-balances float-right border" v-if="getBonus && getBonus.total">
                    {{ getBonus.total }}
                    <i class="fa fa-chevron-right fa-sm ml-2"></i>
                  </span>
                </a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link" v-on:click="toPage('ReporteSemanalMovil')">
                  <i class="fas fa-money-bill-wave"></i> {{ $t("reporte_semanal") }}
                </a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link" v-on:click="toPage('EditPerfilMovil')">
                  <i class="fas fa-user"></i> {{ $t("editar_perfil") }}
                </a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link" v-on:click="toPage('ChangePasswordMovil')">
                  <i class="fas fa-key"></i> {{ $t("cambio_clave") }}
                </a>
              </li>
            </ul>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>
    <!-- /.row -->
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { essentialsData } from "../mixins/essentialsDataMixin.js";

export default {
  name: "Cuenta",
  mixins: [essentialsData],
  computed: {
    ...mapGetters([
      "getScroll",
      "getUser",
      "getBalaces",
      "getBonus",
      "getCharges",
      "getPayments",
      "getAccounts",
      "getCenterAccounts",
      "getIsMobilResolution",
      "getVisualWidth",
      "getThemelight"
    ])
  },
  methods: {
    toPage: async function(page) {
      // if (page == "RecargasTable") {
      //   await this.$store.dispatch("getChargesUserAsync");
      // }

      if (page == "PaymentsTable") {
        await this.$store.dispatch("getPaymentsUserAsync");
      }

      if (page == "ReporteSemanalTable") {
        await this.$store.dispatch("searcheWeklyReport");
      }

      this.$emit("changePage", page);
    }
  },
  async mounted() {
    this.$emit("barSolid", true);

    if (this.getThemelight) {
      document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
    }
  },
  async created() {
    await this.$store.dispatch("enableSecurity");
  }
};
</script>
<style>
body {
  background-color: #232323;
}

.bg-blue-theme {
  background-color: #00387f !important;
  color: #fff !important;
}
.card.card-widget.widget-user-2.shadow-sm {
  border-radius: 0.5em;
}
.no-cursor {
  cursor: none;
  border-radius: 0px !important;
}
.table-hover tbody tr:hover {
  background-color: #fff !important;
}

.table-striped tbody tr:nth-of-type(odd),
.table-striped tbody tr:nth-of-type(odd):hover {
  /*background-color: #0135fe1a !important;*/
}

.customPagination > li > a,
.customPagination .page-link {
  color: #222529;
}

.customPagination > li.active > a,
.customPagination > li > a:hover,
.customPagination .page-item.active .page-link {
  color: #222529;
  background-color: #e5eaff !important;
  border-color: #e5eaff !important;
  /*#222529*/
}
</style>
<style scoped="scoped">
.top-cuenta {
  margin-top: 150px !important;
}
.badges-balances {
  font-size: 1em;
  font-weight: 300;
  padding: 5px 10px;
  color: #fff !important;
  background-color: #00387f !important;
}
.capitalize {
  text-transform: capitalize;
  font-size: 1.5em;
  font-weight: 200;
}
.card.card-widget.widget-user-2.shadow-sm {
  border: none;
}
.nav.nav-pills.flex-column li,
.nav.nav-pills.flex-column li > a,
.nav.flex-column li > a {
  color: #495057 !important;
}
.nav.nav-pills.flex-column li > a:hover,
.nav.flex-column li > a:hover {
  color: #fff !important;
}
.animate__animated.animate__backInUp {
  min-height: 100px;
}
section {
  height: 100vh;
}
.container {
  /* margin-top: 120px !important; */
  overflow-y: hidden;
}
h1,
p {
  color: #fff;
}
span {
  color: rgb(243, 156, 18);
}
.nav-item > a:hover {
  background-color: #1a5bd1 !important;
  font-weight: 700;
}
.user.card-title {
  text-align: justify;
  width: 100%;
  padding: 0;
  font-weight: 300;
}
.lbl-user {
  font-weight: 500;
}
.user-card {
  text-align: justify;
  color: #6e7999;
}
.card-subtitle {
  text-align: center;
  font-weight: 400;
}
.shade {
  font-size: 20px;
  background-color: rgba(233, 227, 151, 0.7);
}
.fijo {
  position: fixed;
  top: 80%;
  left: 85%;
}
.top-dash {
  margin-top: 120px !important;
}
</style>
