<template>
  <div id="container-recargas" class="animate__animated animate__backInUp top-recarga">
    <div class="row my-3 align-items-center">
      <div class="col-3">
        <i
          class="fas fa-chevron-left fa-lg text-light"
          :class="{ 'text-dark': this.getThemelight }"
          v-on:click="back()"
        ></i>
      </div>
      <div class="col-6 text-center">
        <div class="text-title" :class="{ 'text-light': !getThemelight, 'light-h1': getThemelight }">Retiros</div>
      </div>
      <div class="col-3">
        <button class="btn btn-light btn-sm float-right" v-on:click="irARetiro()">
          <b>Nuevo</b>
        </button>
      </div>
    </div>
    <div style="height: 550px; !important; overflow: scroll; padding-bottom: 100px">
      <div v-if="!this.monedaExtranjera">
        <div class="card" v-for="charge of results" v-bind:key="charge.id">
          <div class="row border text-center bg-title">
            <div class="text-center">
              <b>Cuenta</b>
            </div>
          </div>
          <div class="row">
            <div class="text-center">
              {{ charge.cuenta }}
            </div>
          </div>
          <div class="row text-center border bg-title">
            <div class="col-6 border-right">
              <b>Banco</b>
            </div>
            <div class="col-6">
              <b>Monto</b>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-6">
              {{ charge.banco }}
            </div>
            <div class="col-6">
              {{ formatoMonto(charge.monto) }}
            </div>
          </div>

          <div class="row text-center border bg-title">
            <div class="col-6 border-right">
              <b>Fecha</b>
            </div>
            <div class="col-6">
              <b>Estado</b>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-6">
              {{ dateFormat(charge.fecha) }}
            </div>
            <div class="col-6">
              {{ charge.estado }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card" v-for="charge of getCriptoCharges" v-bind:key="charge.id">
          <div class="row border text-center bg-title">
            <div class="col">
              <b>Fecha</b>
            </div>
          </div>
          <div class="row">
            <div class="text-center">
              {{ dateTimeFormat(charge.created_at) }}
            </div>
          </div>
          <div class="row text-center border bg-title">
            <div class="col-6 border-right">
              <b>Estado</b>
            </div>
            <div class="col-6 border-right">
              <b>Monto</b>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-6 border-right">
              {{ statusFormat(charge.status) }}
            </div>
            <div class="col-6 border-right">{{ getSimboloMoneda }}{{ formatoMonto(charge.amount) }}</div>
          </div>
          <div class="row text-center border bg-title">
            <div class="text-center">
              <b>Cuenta</b>
            </div>
          </div>
          <div class="row">
            <div class="col border-right">
              {{ charge.address }}
            </div>
          </div>
          <div class="row text-center border bg-title" v-if="charge.ref">
            <div class="text-center">
              <b>Referencia</b>
            </div>
          </div>
          <div class="row">
            <div class="col border-right">
              {{ charge.ref }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div>
      <div style="position: absolute; top: 90%; right: 0px; width: 100%; text-align: center">
        <button class="btn btn-success btn-lg">Nuevo Retiro</button>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      results: [],
      position: 1,
      disablePager: false,
      monedaExtranjera: false
    };
  },
  computed: {
    ...mapGetters(["getCharges", "getThemelight", "getUser", "getCriptoCharges", "getSimboloMoneda"])
  },
  methods: {
    back() {
      //this.$emit("toPage", "Cuenta");
      this.$bvModal.show("modal-cuenta");
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    dateTimeFormat(value) {
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY HH:mm:ss A");
      }
    },
    statusFormat(value) {
      if (value == 0) return "Pendiente";
      if (value == 1) return "Aprobado";
      if (value == 3) return "Completado";
      if (value == 4) return "Rechazado";
    },
    async handleScroll() {
      // Any code to be executed when the window is scrolled
      if ($(window).scrollTop() >= $(document).height() - $(window).height() && !this.disablePager) {
        this.position++;
        this.getResults();
      }
    },
    async getResults() {
      await this.$store.dispatch("getChargesUserAsync", {
        page: this.position
      });
      this.getCharges.data.forEach(element => {
        this.results.push(element);
      });
    },
    irARetiro() {
      //this.$emit("toPage", "Retiros");
      this.$emit("changePage", "Retiros");
    }
  },
  async mounted() {
    if (this.getUser.Coin.id > 1) {
      this.monedaExtranjera = true;

      await this.$store.dispatch("getCriptoAsync", {
        url: "api/crypto?charges",
        set: "setCriptoCharges",
        name: "charges"
      });

      await this.$store.dispatch("getCriptoAsync", {
        url: "api/crypto?gateways",
        set: "setCriptoGateways",
        name: "gateways"
      });
    }

    if (this.getThemelight) {
      document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
    }

    $("#container-recargas").animate({ scrollTop: 0 }, 600);
    this.getResults();
  }
};
</script>

<style>
.container {
  width: 95%;
}

.bg-title {
  background: #e8eaea;
}

.title-orange {
  font-size: 1.2em;
  font-weight: 600;
  margin-left: 4em;
  font-family: sans-serif;
  text-transform: uppercase;
  color: rgb(243, 156, 18);
}

.btn-custom {
  background-color: #ffffff;
  /* border-radius: 100%; */
}
</style>

<style scoped>
.top-recarga {
  margin-top: 130px !important;
  height: auto;
  max-height: 530px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  overflow: hidden;
}
</style>
