<template>
  <div>
    <div
      class="footer"
      :class="{
        'slide-top': selectOdds.length > 0 || !minimiza,
        'slide-min': minimiza
      }"
      style="
        position: fixed;
        z-index: 1000;
        bottom: 0em;
        left: 0;
        width: 100%;
        background-color: #545454;
        color: #e0e0e0;
      "
    >
      <div @touchstart="minimizar()" class="row" style="font-size: 1.5em">
        <div class="col text-center">
          <i class="fas fa-grip-lines" style="font-weight: bold"></i>
        </div>
      </div>

      <table
        width="100%"
        class="text-center"
        :class="{ 'game-row': selectOdds.length > 0 }"
        style="font-family: Arial"
        v-if="selectOdds.length > 0"
      >
        <!-- <thead> -->
        <tr
          class="text-dark"
          style="
            background-color: #b7b8b8;
            font-size: 0.9em;
            font-weight: 800;
            display: table;
            width: 100%;
            height: 2em;
          "
        >
          <th width="60%">Juego</th>
          <th width="30%">Apuesta</th>
          <th width="10%"></th>
        </tr>
        <!--  </thead> -->
        <tbody
          class="text-dark"
          style="
            background-color: #e1e8e8;
            font-size: 0.7em;
            font-weight: 600;
            max-height: 130px !important;
            overflow-y: auto !important;
            display: block;            
          "
        >
          <tr
            v-for="data in selectOdds"
            v-bind:key="data.id"
            class="text-center game-row"
            style="display: table"
            :id="data.respuesta.odd.Game.code"
          >
            <td width="60%" style="font-size: 1em !important">
              {{ data.match }} <br />
              {{ data.fecha_match }}
            </td>
            <td width="30%" style="font-size: 1em !important">
              {{ data.texto }} ({{ data.respuesta.odd.Odd.finalit }}) <br />
              {{ formatSymbol(data.respuesta.odd.Odd[getFormatoTaquilla]) }}
              {{ formatFactor(data.respuesta.odd.Odd) }}
            </td>
            <td width="10%"><i class="fas fa-trash fa-lg text-danger" v-on:click="delOdd(data)"></i></td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col form-inline">
          <button
            class="btn btn-primary w-75"
            :class="{ 'w-100': this.getApuestas.length == 0 }"
            style="height: 2.5em"
            ref="btnTicket"
            @click="validacionesTickets()"
          >
            Generar ticket - {{ selectOdds.length }} Jugadas
          </button>
          <button
            class="btn btn-danger w-25"
            style="height: 2.5em"
            @click="limpiarApuestas()"
            v-if="this.getApuestas.length > 0"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>

      <div>
        <!-- modal -->
        <b-modal id="my-modal" title="¿Seguro de generar el ticket?">
          <div class="form-inline mb-2">
            <b class="mr-3">USAR BONUS</b>
            <b-form-checkbox size="lg" @change="checkBonus = !checkBonus"></b-form-checkbox>
          </div>
          <div style="font-size: 0.9em">
            (Al sellar con bonus NO se deveuelve el monto apostado). <br />
            Una vez oprima [ACEPTAR] su apuesta sera registrada. <br />
            Oprima Cancelar para agregar/actualizar apuestas. <br />
          </div>
          <template #modal-footer>
            <div class="w-100 my-0">
              <b-button variant="danger" class="float-right" @click="$bvModal.hide('my-modal')">Cancelar </b-button>
              <b-button variant="success" class="float-right mr-2" @click="registrarTicket()">Aceptar </b-button>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { essentialsData } from "../../../mixins/essentialsDataMixin.js";
import * as u from "j899";

export default {
  data() {
    return {
      TicketAmount: "",
      monto: "",
      montoPremio: "",
      tipoParley: true,
      checkBonus: 0,
      premio2: "",
      errorMonto: false,
      datos: [
        {
          id: 1,
          numero: "123",
          tipo: "Parley",
          monto: 2000
        }
      ],
      minimiza: false,
      ver: false
    };
  },
  mixins: [essentialsData],
  props: ["selectOdds", "cart"],
  computed: {
    cartCount: function() {
      return this.selectOdds.length;
    },
    ...mapGetters(["getPremio", "getFormatoTaquilla", "getApuestas", "getBetInit", "getTipoParley", "getMonto"])
  },
  watch: {
    monto(m) {
      this.$store.commit("setMonto", m);
      this.$store.dispatch("calculatePrize");
    },
    checkBonus(c) {
      this.checkBonus = c ? 1 : 0;
    }
  },
  methods: {
    async delOdd(data) {
      this.$store.dispatch("clickApuesta", {
        id: `${data.respuesta.odd.Game.ref}`,
        momento: moment(String(new Date())).format("DD-MM-YYYY hh:mm:ss A")
      });

      if (this.getApuestas.length == 0) {
        this.$store.commit("setPremio", 0);
        this.$store.commit("setApuestas", []);
        this.$store.commit("setRestriccionParley", []);
        this.$store.commit("setExcepcionParley", false);
      }

      //this.$store.commit("hideLoader");
    },
    limpiarApuestas() {
      this.Swal.fire({
        position: "top",
        title: "¿Desea eliminar las apuestas seleccionadas?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //this.$store.commit("setMonto", 0);
          this.$store.commit("setPremio", 0);
          this.$store.commit("setApuestas", []);
          this.$store.commit("setRestriccionParley", []);
          this.$store.commit("setExcepcionParley", false);
          this.Swal.fire("La selección de apuestas ha sido limpiada.", "", "success");
        } else if (result.isDenied) {
          this.Swal.fire("Acción cancelada", "", "info");
        }
      });
    },
    minimizar() {
      this.minimiza = !this.minimiza;
    },
    formatFactor: function(value) {
      return value.factor ? "(" + value.factor + ")" : "";
    },
    validacionesTickets() {
      let error = false;
      let msj = "";
      let title = "";

      if (this.getTipoParley && this.getApuestas.length < this.getBetInit.minimo) {
        error = true;
        msj = "El mínimo de jugadas parley debe ser " + this.getBetInit.minimo;
      }

      if (this.getApuestas.length > 1 && this.getApuestas.length > this.getBetInit.maximo) {
        error = true;
        msj = "El maximo de jugadas parley debe ser " + this.getBetInit.maximo;
      }

      if (parseFloat(this.getMonto) < parseFloat(this.getBetInit.min_tck)) {
        error = true;
        msj = "La jugada por parley minima debe ser " + u.inNumeration("LA", parseFloat(this.getBetInit.min_tck));
      }

      if (parseInt(this.getMonto) <= 0 || String(this.getMonto).trim() == "" || this.getMonto == null) {
        error = true;
        (title = "Debe colocar el monto de la apuesta"), (msj = "por favor, verifique!");
        this.errorMonto = true;
      }

      if (this.getApuestas && this.getApuestas.length == 0 && !error) {
        error = true;
        msj = "debe tener apuestas seleccionadas";
      }

      if (error && error == true) {
        this.Swal.fire({
          position: "top",
          allowOutsideClick: false,
          html:
            '<div class="text-center mt-5"><i class="far fa-times-circle text-danger" style="font-size: 4em;"></i> </div>' +
            '<div class="text-center mb-0"><h3><b>' +
            title +
            "</b></h3></div>" +
            '<div class="text-center mb-0">' +
            msj +
            "</div> ",
          heightAuto: false,
          customClass: "swal-height",
          showClass: {
            popup: "animate__animated animate__fadeInDown"
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp"
          },
          onAfterClose: () => {
            if (this.errorMonto) {
              this.$emit("resetMonto", true);
              this.errorMonto = false;
            }
          }
        });
      } else {
        this.$bvModal.show("my-modal");
      }
    },

    registrarTicket() {
      let dataTicket = [];

      let objData = {};

      this.selectOdds.forEach(element => {
        objData[element.id] = {
          game_id: element.respuesta.odd.Game.id,
          type: element.respuesta.odd.Odd.finalit,
          odd_type: element.respuesta.odd.Odd.odd_type_id,
          odd: element.respuesta.odd.Odd.odd,
          odd_a: element.respuesta.odd.Odd.odd_a,
          factor: element.respuesta.odd.Odd.factor ? element.respuesta.odd.Odd.factor : "",
          status: 1,
          code: element.respuesta.odd.Game.code
        };
      });

      const cantidad_amount_id = this.getTipoParley ? this.getMonto : this.getMonto * this.getApuestas.length;

      dataTicket["Ticket"] = {
        amount: this.getMonto,
        amount_ind: cantidad_amount_id,
        modalidad: this.modalidad,
        bonus: this.checkBonus,
        code: this.getBetInit,
        // eslint-disable-next-line no-undef
        procesed: moment().format("YYYY-MM-DD"),
        Odds: objData
      };

      this.$bvModal.hide("my-modal");

      this.$emit("ticket", dataTicket);
    }
  },
  created() {
    this.$store.commit("setMonto", null);
    this.$store.dispatch("calculatePrize");
  }
};
</script>

<style>
.style-chooser {
  font-size: 0.9em;
  width: 100%;
  color: black;
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* .slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
} */

.slide-min {
  -webkit-animation: slide-min 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-min 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.game-row {
  -webkit-animation: game-row 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: game-row 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.tbl-apuestas {
  display: table;
  width: 100%;
  table-layout: auto;
}

.swal-height {
  height: 20em;
  z-index: 1001 !important;
}

@-webkit-keyframes game-row {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes game-row {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(calc(100% - 40px));
    transform: translateY(calc(100% - 40px));
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(calc(100% - 40px));
    transform: translateY(calc(100% - 40px));
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* @-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(300px);
    transform: translateY(300px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(300px);
    transform: translateY(300px);
  }
} */
/**
 * ----------------------------------------
 * animation slide-min
 * ----------------------------------------
 */
@-webkit-keyframes slide-min {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(calc(100% - 35px));
    transform: translateY(calc(100% - 35px));
  }
}
@keyframes slide-min {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(calc(100% - 35px));
    transform: translateY(calc(100% - 35px));
  }
}
</style>
