<template>
  <!-- modal calculadora movil -->
  <div v-if="this.getIsMobilResolution">
    <b-modal id="modal-calculadora" title="CALCULADORA" size="sm" hide-footer v-on:close="resetear()">
      <!-- <input type="text" class="form-control" placeholder="Monto a calcular 5" pattern="[0-9\-]+" v-model="monto" /> -->
      <input
        class="form-control"
        type="number"
        placeholder="Monto a calcular"
        v-model.number="monto"
        v-on:keypress="isNumber($event)"
      />

      <div class="my-3">
        <v-select
          class="style-chooser"
          placeholder="Cuotas"
          :options="cuotas"
          :reduce="cuota => cuota.type"
          v-model="cuota"
          label="label"
          @input="actualizaCuota()"
          :searchable="false"
          :clearable="false"
        >
        </v-select>
      </div>

      <div class="mt-4" v-if="premio > 0">
        <h3>{{ $t("premio") }}: {{ premio }}</h3>
      </div>

      <div class="mt-2 text-center">
        <button class="btn btn-primary btn-parley" @click="addParley()">
          <i class="fa fa-plus mr-2"></i>
          {{ $t("agregar") }} parley
        </button>
      </div>

      <div class="input-group my-3" v-for="parley in parleys" v-bind:key="parley.index">
        <input
          type="number"
          class="form-control"
          placeholder="0"
          aria-label="parley"
          v-on:keypress="isNumber($event)"
          v-model="parley.monto"
        />
        <div class="input-group-append" v-if="parleys.length > 1">
          <span class="input-group-text bg-danger" @click="removeParley(parley.index)">
            <i class="fa fa-trash"></i>
          </span>
        </div>
        <div class="text-center m-0" v-if="parley.error">
          <span
            class="error invalid-feedback"
            style="
              display: block !important;
              background-color: rgb(199 147 166 / 9%);
              font-size: 0.8em;
              text-transform: uppercase;
            "
          >
            {{ parley.mensajeError }}
          </span>
        </div>
      </div>

      <template>
        <div class="contenedor-boton-footer">
          <b-button class="btn btn-primary btn-calcular" @click="calcularParley()"> {{ $t("calcular") }} </b-button>

          <b-button size="sm" class="cerrar-btn" @click="resetear()"> {{ $t("resetear") }} </b-button>
        </div>
      </template>
    </b-modal>
  </div>

  <div
    v-else
    class="modal fade"
    id="calculadoraWeb"
    tabindex="-1"
    aria-labelledby="calculadoraModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content border-redondo">
        <div class="modal-header">
          <h3 class="modal-title text-center" style="color: #052460; font-weight: bold">{{ $t("calculadora") }}</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <div class="col-md-3">
              <label class="label-formulario">{{ $t("monto") }}</label>
              <input
                v-model="monto"
                type="number"
                :class="{
                  'form-control': true
                }"
              />
            </div>
            <div class="col-md-3">
              <label class="label-formulario">{{ $t("cuotas") }}</label>
              <select class="form-control" v-model="cuota" @change="actualizaCuota()">
                <option value="1">Americano</option>
                <option value="2">Decimal</option>
              </select>
            </div>
          </div>

          <div class="row justify-content-end pt-3">
            <div class="col-md-3">
              <label class="label-formulario">{{ $t("premio") }}</label>
              <input
                type="text"
                v-model="premio"
                disabled
                :class="{
                  'form-control': true
                }"
              />
            </div>
            <div class="col-md-3 pt-3">
              <button class="btn btn-primary boton-color-fondo" @click="addParley()">{{ $t("agregar") }} parley</button>
              <div class="input-group my-3" v-for="parley in parleys" v-bind:key="parley.index">
                <input
                  type="number"
                  :class="{
                    'form-control': true,
                    'is-invalid': parley.error
                  }"
                  :placeholder="place"
                  aria-label="parley"
                  v-model="parley.monto"
                  v-on:keypress="validarFormato"
                  :step="stepNumber"
                />

                <div class="input-group-append" v-if="parleys.length > 1">
                  <span class="input-group-text bg-danger" style="cursor: pointer" @click="removeParley(parley.index)">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </div>

                <div class="text-center m-0" v-if="parley.error">
                  <span
                    class="error invalid-feedback"
                    style="
                      display: block !important;
                      background-color: rgb(199 147 166 / 9%);
                      font-size: 0.8em;
                      text-transform: uppercase;
                    "
                  >
                    {{ parley.mensajeError }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>

          <div class="row justify-content-center pt-3">
            <div class="col-md-3">
              <button
                :class="{
                  'btn btn-primary boton-color-fondo': true
                }"
                @click="calcularParley()"
              >
                {{ $t("calcular") }}
              </button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary boton-color-fondo" @click="resetear()">{{ $t("resetear") }}</button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-cerrar btn-secondary" data-dismiss="modal">{{ $t("cerrar") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import { essentialsData } from "../mixins/essentialsDataMixin";

export default {
  data() {
    return {
      monto: "",
      premio: 0.0,
      cuota: "1",
      stepNumber: 1,
      place: 0,
      cuotas: [
        { type: "1", label: "Americano" },
        { type: "2", label: "Decimal" }
      ],
      parleys: [
        { index: 0, monto: "", error: false, mensajeError: "" },
        { index: 1, monto: "", error: false, mensajeError: "" },
        { index: 2, monto: "", error: false, mensajeError: "" }
      ]
    };
  },
  mixins: [essentialsData],
  components: {
    vSelect
  },
  computed: {
    ...mapGetters(["getIsMobilResolution"])
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validarFormato(val) {
      if (val.key == "-" && !this.esAmericano()) {
        return val.preventDefault();
      }

      if ((val.key == "." || val.key == ",") && this.esAmericano()) {
        return val.preventDefault();
      }
    },
    esAmericano() {
      return parseInt(this.cuota) == 1 ? true : false;
    },
    addParley() {
      var montoVacio = 0;

      this.parleys.forEach(element => {
        if (element.monto == 0 || element.monto == "") {
          montoVacio++;
        }
      });

      var parley = {
        index: this.parleys.length,
        monto: ""
      };

      if (montoVacio < 3) this.parleys.push(parley);
    },
    removeParley(index) {
      const foundIndex = this.parleys.findIndex(element => element.index == index);

      if (this.parleys.length > 1) this.parleys.splice(foundIndex, 1);

      if (this.monto > 0) this.calcularParley();
    },
    calcularParley() {
      var hayError = false;

      this.parleys.forEach(element => {
        var length = 3;
        element.error = false;
        element.mensajeError = "";

        let guion = element.monto.toString().includes("-");

        if (guion) length = 4;

        if (element.monto.length != length && element.monto != 0 && this.esAmericano()) {
          element.error = true;
          element.mensajeError = "Solo 3 dígitos";
          hayError = true;
        }

        if (!element.monto.includes(".") && !element.monto.includes(",") && element.monto > 0 && !this.esAmericano()) {
          element.error = true;
          element.mensajeError = "Debe ser número decimal";
          hayError = true;
        }
      });

      var premio = hayError ? 0 : this.monto;

      if (this.monto <= 0 || this.monto == "") {
        this.Swal.fire("Introduzca un monto valido.", "", "error");
      } else {
        this.parleys.forEach(element => {
          var monto = element.monto;
          if (monto != 0) {
            if (this.cuota == 2) monto = this.decimal_to_american(monto);

            //var signMonto = monto < 0 ? monto : Math.abs(monto);

            premio = premio * 1 + this.calc_parl(premio, monto);
          }
        });
      }

      this.premio = premio;
    },
    resetear() {
      this.parleys = [{ index: 0, monto: "" }];
      this.premio = "";
      this.monto = "";
    },
    actualizaCuota() {
      this.premio = 0;
      this.resetear();
    },
    calc_parl(monto, parlay) {
      var premio = monto;
      if (parlay == "") {
        premio = 0;
      } else {
        if (parlay < 0) {
          parlay = parlay * -1;
          parlay = parlay / 100;
          premio = monto / parlay;
        } else if (parlay > 0) {
          parlay = parlay / 100;
          premio = monto * parlay;
        }
      }
      return Math.round(premio * 100) / 100;
    },
    decimal_to_american(odds) {
      var odds2pre = 0;
      var odds2 = 0;

      if (odds >= 2) {
        odds2pre = odds * 100 - 100;
        odds2 = odds2pre.toFixed(0);
      } else {
        odds2pre = 100 / (odds - 1);
        odds2pre = odds2pre.toFixed(0);
        odds2 = odds2pre * -1;
      }

      return odds2;
    }
  }
};
</script>

<style>
.chip {
  display: inline-block;
  padding: 0 25px;
  height: 30px;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  background-color: red;
}

.chip i {
  float: left;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.btn-calcular {
  background: #001f62 !important;
  width: 40% !important;
  border-radius: 10px;
}

.btn-parley {
  background: #001f62 !important;
  width: 100% !important;
  border-radius: 10px;
  color: #ffffff;
}

.cerrar-btn {
  width: 40% !important;
  border-radius: 10px;
  font-family: "Poppins", sans-serif !important;
  background: #ebebeb !important;
  color: black !important;
  border-color: #c5c5c5 !important;
}
</style>
