var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-game"},[_c('details',{staticClass:"details card text-center",class:{ 'details-light text-dark': this.getThemelight, details: !this.getThemelight },on:{"click":function($event){return _vm.abrirDetalle(_vm.gameDetails.muestraTitulo)}}},[_c('summary',{staticClass:"my-2"},[(_vm.gameDetails.muestraTitulo || _vm.show)?_c('div',{staticClass:"titleGame text-center",class:{ 'titleGame-light': this.getThemelight }},[_c('p',[_vm._v(_vm._s(this.gameDetails.gameName)+" ("+_vm._s(this.gameDetails.gameDate)+")")])]):_vm._e(),_c('div',{staticClass:"row text-center",class:{ 'bg-header-light font-weight-bold': this.getThemelight },staticStyle:{"font-size":"0.9em"}},[_c('div',{staticClass:"col-4 border-right"},[_vm._v("\n          "+_vm._s(this.gameDetails.gameTime)+"\n        ")]),_c('div',{staticClass:"col-4 border-right"},[_c('span',[_vm._v(" "+_vm._s(this.gameDetails.teams[0].name)+" (1) ")])]),_c('div',{staticClass:"col-4"},[_c('span',[_vm._v(" "+_vm._s(this.gameDetails.teams[1].name)+" (2) ")])])])]),_c('table',{staticClass:"mb-1",attrs:{"width":"100%","id":"oddsTable"}},[_c('tbody',[(_vm.gameDetails.teams[0]['1X2FT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT1'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            1X2 FT "),_c('br'),_vm._v("\n            (A Ganar)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX1X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX1X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX1X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["1X2FT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX2X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX2X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX2X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["1X2FT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['HDPFT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['HDPFT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT2'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            HDP (FT) "),_c('br'),_vm._v("\n            (Runline)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX3X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX3X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["HDPFT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX4X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX4X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["HDPFT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESFT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESFT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT4'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            TOTALES FT "),_c('br')]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX5X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX5X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["TOTALESFT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX6X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX6X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["TOTALESFT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['1X2HT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['1X2HT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'HT1'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            1 y 2 HT "),_c('br'),_vm._v("\n            (A Ganar)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX1X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX1X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX1X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["1X2HT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX2X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX2X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX2X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["1X2HT"])+"\n          ")])]):_vm._e(),(this.gameDetails.teams[0]['HDPHT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(this.gameDetails.teams[0]['HDPHT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'HT2'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            HDP HT "),_c('br'),_vm._v("\n            (Runline)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX3X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX3X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["HDPHT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX4X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX4X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["HDPHT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESHT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESHT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'HT3'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            TOTALES HT\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX5X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX5X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["TOTALESHT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX6X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX6X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["TOTALESHT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['SI'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['SI'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT9'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            AMBOS "),_c('br'),_vm._v("ANOTAN "),_c('br'),_vm._v("\n            FT (GG/NG)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX49X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX49X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX49X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["SI"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX50X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX50X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX50X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["NO"])+"\n          ")])]):_vm._e()])]),(_vm.gameDetails.hayFT)?_c('div',{staticClass:"my-2"},[_c('button',{staticClass:"btn boton-dark",class:{ 'boton-light': this.getThemelight },attrs:{"id":'btnJugadas' + _vm.gameDetails.id},on:{"click":function($event){return _vm.detalle(_vm.gameDetails.id)}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Jugadas\n      ")])]):_vm._e(),(_vm.verDetalle)?_c('div',{staticClass:"mb-1"},[_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        JUEGO COMPLETO (FT)\n      ")]),(this.gameDetails.anotaLoc)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Anota Primero (FT)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX17X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX17X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX17X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.anotaLoc)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX114X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX114X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX114X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.anotaNin)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX16X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX16X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX16X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.anotaVis)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.anotaLocUlt)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Ultimo en anotar (FT)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX146X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX146X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX146X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.anotaLocUlt)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX147X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX147X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX147X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.anotaNinUlt)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX145X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX145X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX145X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.anotaVisUlt)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.parFT)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Marcador Par/Impar (FT)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX132X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FTX132X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX132X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.parFT)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX133X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FTX133X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX133X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.imparFT)+"\n            ")])])])]):_vm._e(),(this.gameDetails.dobleChance1X)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Doble Chance (FT)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX62X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX62X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX62X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChance1X)+"\n              ")])]),_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX63X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX63X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX63X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChance12)+"\n              ")])]),_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX64X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX64X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX64X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChanceX2)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.gana1FT)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          1 X 2 (FT) (Sin OT)\n        ")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX129X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX129X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX129X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1FT)+"\n              ")])]),_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX130X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX130X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX130X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXFT)+"\n              ")])]),_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX131X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FTX131X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX131X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2FT)+"\n              ")])])])])]):_vm._e(),(_vm.gameDetails.hayFQ)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        1er Periodo (1st Prd)\n      ")]):_vm._e(),(this.gameDetails.gana1FQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1 X 2 (1st Prd)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FQX129X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FQX129X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX129X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1FQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FQX130X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FQX130X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX130X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXFQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FQX131X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FQX131X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX131X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2FQ)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.spreadFQ1)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          P. Spread/HDP´(1st Prd)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FQX3X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FQX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX3X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadFQ1)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FQX4X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FQX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX4X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadFQ2)+"\n            ")])])])]):_vm._e(),(this.gameDetails.totAltaFQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Totales (1st Prd)")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FQX5X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FQX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX5X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totAltaFQ)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FQX6X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'FQX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX6X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totBajaFQ)+"\n            ")])])])]):_vm._e(),(this.gameDetails.dobleChance1XFQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Doble Chance (1st Prd)\n        ")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('FQX62X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FQX62X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX62X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChance1XFQ)+"\n              ")])]),_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('FQX63X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FQX63X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX63X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChance12FQ)+"\n              ")])]),_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('FQX64X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'FQX64X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FQX64X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChanceX2FQ)+"\n              ")])])])])]):_vm._e(),(_vm.gameDetails.haySQ)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        2do Periodo (2nd Prd)\n      ")]):_vm._e(),(this.gameDetails.gana1SQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1 X 2 (2nd Prd)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('SQX129X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'SQX129X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX129X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1SQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('SQX130X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'SQX130X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX130X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXSQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('SQX131X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'SQX131X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX131X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2SQ)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.totAltaSQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Totales (2do Prd)")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('SQX5X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'SQX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX5X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totAltaSQ)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('SQX6X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'SQX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX6X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totBajaSQ)+"\n            ")])])])]):_vm._e(),(this.gameDetails.spreadSQ1)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          P. Spread/HDP´(2do Prd)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('SQX3X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'SQX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX3X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadSQ1)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('SQX4X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'SQX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX4X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadSQ2)+"\n            ")])])])]):_vm._e(),(this.gameDetails.dobleChance1XSQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Doble Chance (2nd Prd)\n        ")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('SQX62X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'SQX62X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX62X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChance1XSQ)+"\n              ")])]),_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('SQX63X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'SQX63X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX63X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChance12SQ)+"\n              ")])]),_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('SQX64X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'SQX64X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('SQX64X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChanceX2SQ)+"\n              ")])])])])]):_vm._e(),(_vm.gameDetails.hayTQ)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        3er Periodo (3rd Prd)\n      ")]):_vm._e(),(this.gameDetails.gana1TQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1 X 2 (3rd Prd)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('TQX129X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'TQX129X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX129X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1TQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('TQX130X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'TQX130X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX130X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXTQ)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('TQX131X'),
                  'btnTableOdd-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'TQX131X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX131X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2TQ)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.spreadTQ1)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          P. Spread/HDP´(3rd Prd)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('TQX3X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'TQX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX3X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadTQ1)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('TQX4X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'TQX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX4X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.spreadTQ2)+"\n            ")])])])]):_vm._e(),(this.gameDetails.totAltaTQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Totales (3er Prd)")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('TQX5X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'TQX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX5X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totAltaTQ)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnDetailOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('TQX6X'),
                'btnDetailOdd-light  font-weight-bold': this.getThemelight
              },attrs:{"id":'TQX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX6X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totBajaTQ)+"\n            ")])])])]):_vm._e(),(this.gameDetails.dobleChance1XTQ)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Doble Chance (3rd Prd)\n        ")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('TQX62X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'TQX62X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX62X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChance1XTQ)+"\n              ")])]),_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('TQX63X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'TQX63X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX63X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChance12TQ)+"\n              ")])]),_c('td',{staticClass:"pb-2",attrs:{"width":"30"}},[_c('button',{staticClass:"btn btn-logro",class:{
                  'apuesta-seleccionada': this.existeApuesta('TQX64X'),
                  'btn-logro-light  font-weight-bold': this.getThemelight
                },attrs:{"id":'TQX64X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('TQX64X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.dobleChanceX2TQ)+"\n              ")])])])])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }