<template>
  <div class="card mt-3 bg-light">
    <div class="card-body ">
      <div class="text-center mt-2 label-formulario">
        <h4>{{ $t("datos_del_cliente") }}</h4>
      </div>

      <div class="row mb-2 mt-3">
        <div class="col-sm-12 col-lg-12">
          <div class="form-group" style="cursor: pointer">
            <label for="monto" class="label-formulario">{{ $t("direccion") }}</label>
            <input type="text" class="form-control rounded-0" id="direccion" v-model="direccion" />
          </div>
        </div>
      </div>

      <div class="row mb-2 mt-2">
        <div class="col-sm-12 col-lg-6">
          <div class="form-group" style="cursor: pointer">
            <label for="monto" class="label-formulario">{{ $t("ciudad") }}</label>
            <input type="text" class="form-control rounded-0" id="ciudad" v-model="ciudad" />
          </div>
        </div>
        <div class="col-sm-12 col-lg-6">
          <div class="form-group" style="cursor: pointer">
            <label for="monto" class="label-formulario">Región</label>
            <input type="text" class="form-control rounded-0" id="region" v-model="region" />
          </div>
        </div>
      </div>

      <div class="row mb-2 mt-2">
        <div class="col-sm-12 col-lg-6">
          <div class="form-group" style="cursor: pointer">
            <label for="monto" class="label-formulario">{{ $t("pais") }}</label>
            <input type="text" class="form-control rounded-0" id="pais" v-model="pais" />
          </div>
        </div>
        <div class="col-sm-12 col-lg-6">
          <div class="form-group" style="cursor: pointer">
            <label for="monto" class="label-formulario">{{ $t("codigo_postal") }}</label>
            <input type="text" class="form-control rounded-0" id="codigo" v-model="codigo" />
          </div>
        </div>
      </div>

      <div class="row mb-2 mt-2">
        <div class="col-sm-12 col-lg-6">
          <div class="form-group" style="cursor: pointer">
            <label for="tipoTransaccion" class="label-formulario">{{ $t("tipo_de_transaccion") }}</label>
            <v-select
              class="style-chooser"
              placeholder="Seleccione..."
              :options="typeTransactionMonnet"
              style="width: 100%; color: black; z-index: 3"
              :reduce="typeTransactionMonnet => typeTransactionMonnet.key"
              v-model="tipoTransaccion"
              label="value"
              :searchable="false"
              :clearable="false"
            >
            </v-select>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6" style="z-index: 2">
          <div class="form-group">
            <label for="monto" class="label-formulario">{{ $t("monto") }}</label>
            <input type="number" class="form-control rounded-0" id="monto" placeholder="0.00" v-model="monto" />
          </div>
        </div>
      </div>

      <div>
        <button class="btn btn-primary" @click="validar()">{{ $t("registrarse") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";

export default {
  data() {
    return {
      direccion: "",
      ciudad: "",
      pais: "",
      region: "",
      tipoTransaccion: null,
      url: "",
      codigo: null,
      typeTransactionMonnet: [],
      monto: ""
    };
  },
  computed: {
    ...mapGetters(["getPaymentMonnet"])
  },
  components: {
    vSelect
  },
  methods: {
    validar() {
      if (!this.direccion) {
        this.Swal.fire("Por favor", "Ingrese dirección", "error");
        return false;
      }
      if (!this.ciudad) {
        this.Swal.fire("Por favor", "Ingrese ciudad", "error");
        return false;
      }
      if (!this.region) {
        this.Swal.fire("Por favor", "Ingrese región", "error");
        return false;
      }
      if (!this.pais) {
        this.Swal.fire("Por favor", "Ingrese país", "error");
        return false;
      }
      if (!this.codigo) {
        this.Swal.fire("Por favor", "Ingrese código postal", "error");
        return false;
      }
      if (!this.tipoTransaccion) {
        this.Swal.fire("Por favor", "Ingrese tipo de transaccion", "error");
        return false;
      }
      if (!this.monto) {
        this.Swal.fire("Por favor", "Ingrese monto", "error");
        return false;
      }
      this.registrarMonnet();
    },
    async registrarMonnet() {
      let paymentData = {
        payinMethod: this.tipoTransaccion,
        monto: Number(this.monto),
        address: {
          id: this.getPaymentMonnet.direccion.id,
          zip: this.codigo,
          pais: this.pais,
          ciudad: this.ciudad,
          region: this.region,
          direccion: this.direccion
        }
      };

      this.$store.commit("showLoader");
      const reg = await this.$userService.registerPaymentMonnet(paymentData);
      this.$store.commit("hideLoader");
      this.$emit("url", reg.url);
    }
  },
  async mounted() {
    await this.$store.dispatch("getCriptoAsync", {
      url: "api/client?payment_monnet_init",
      set: "setPaymentMonnet",
      name: "payment_monnet_init"
    });

    let objTransactions = Object.entries(this.getPaymentMonnet.PayInMethod);

    Object.entries(objTransactions).forEach(([key, value]) => {
      let objTransaction = {
        id: key,
        key: value[0],
        value: value[1]
      };

      this.typeTransactionMonnet.push(objTransaction);
    });

    console.log("Transactions: ", this.typeTransactionMonnet);

    this.direccion = this.getPaymentMonnet.direccion.direccion;
    this.ciudad = this.getPaymentMonnet.direccion.ciudad;
    this.region = this.getPaymentMonnet.direccion.region;
    this.pais = this.getPaymentMonnet.direccion.pais;
    this.codigo = this.getPaymentMonnet.direccion.zip;
  }
};
</script>
