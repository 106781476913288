<template>
  <b-modal
    id="details-ticket-target"
    size="lg"
    :content-class="dinamicClassCss()"
    :header-bg-variant="!this.getThemelight ? 'dark' : 'light'"
    :body-bg-variant="!this.getThemelight ? 'dark' : 'light'"
    :footer-bg-variant="!this.getThemelight ? 'dark' : 'light'"
    centered
    scrollable
    title="DETALLES DEL TICKET"
    hide-footer
  >
    <table
      v-if="getTicketTarget"
      :class="{
        table: true,
        'table-dark': !this.getThemelight,
        'table-light': this.getThemelight
      }"
      cellpadding="0"
      cellspacing="0"
      style="width: 100%;"
    >
      <tbody>
        <tr>
          <td>
            {{ $t("numero_ticket") }}: <b>{{ getTicketTarget.number }}</b>
          </td>
          <td style="text-align: right;">
            {{ $t("fecha") }}:
            <b v-if="getTicketTarget.created_at">{{ this.dateFormat(getTicketTarget.created_at) }}</b>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t("monto_total_apostado") }}:
            <b class="lbl-total-apostado" v-if="getTicketTarget.amount"
              >{{ getTicketTarget.Moneda }} {{ this.formatoMonto(getTicketTarget.amount_ind) }}</b
            >
          </td>
          <td style="text-align: right;">
            {{ $t("premio_total_estimado") }} :
            <b>{{ getTicketTarget.Moneda }} {{ formatoMonto(getTicketTarget.prize) }}</b>
          </td>
        </tr>
        <tr>
          <td v-if="this.getTipeTicket(getTicketTarget.modalidad) == 'Directas'">
            {{ $t("monto_cada_jugada") }}:
            <b>{{ getTicketTarget.Moneda }} {{ this.formatoMonto(getTicketTarget.amount) }}</b>
          </td>
          <td
            :style="
              this.getTipeTicket(getTicketTarget.modalidad) == 'Directas' ? 'text-align: right;' : 'text-align: left;'
            "
            :colspan="this.getTipeTicket(getTicketTarget.modalidad) == 'Directas' ? 1 : 2"
          >
            Ticket {{ $t("tipo") }}:
            <b>{{ this.getTipeTicket(getTicketTarget.modalidad) }}</b>
          </td>
        </tr>
      </tbody>
    </table>

    <template v-if="getTicketTarget">
      <div v-for="(odd, index) in getTicketTarget.Odds" :key="index">
        <table
          v-if="getWidth() < 1300 && getTicketTarget"
          style="width: 100%;"
          :class="{
            'table mt-4': true,
            'table-dark': !getThemelight,
            'table-light': getThemelight
          }"
          cellspacing="0"
          cellpadding="0"
        >
          <tbody>
            <tr>
              <td>{{ $t("fecha") }}:</td>
              <td style="text-align: right;">
                <b>{{ dateFormat(odd.time) }}</b>
              </td>
            </tr>
            <tr>
              <td width="20%">{{ $t("fecha") }}:</td>
              <td style="text-align: right;">
                <p v-html="formatoEquipo(odd.equipos)"></p>
              </td>
            </tr>
            <tr>
              <td>{{ $t("apuestas") }}:</td>
              <td colspan="1" style="text-align: right;">({{ odd.type }} {{ odd.final }})</td>
            </tr>
            <tr>
              <td>{{ $t("logro") }}:</td>
              <td colspan="1" style="text-align: right;">
                {{ odd[getFormatoTaquilla] }}
                <div class="fraction-container" v-html="toFraction(odd.factor)"></div>
              </td>
            </tr>
            <tr>
              <td>ESTATUS:</td>
              <td colspan="1" style="text-align: right;">{{ odd.status }}</td>
            </tr>
            <tr v-if="getTipeTicket(getTicketTarget.modalidad) != 'Parley'">
              <td>{{ $t("premio") }}:</td>

              <td
                colspan="1"
                style="text-align: right;"
                v-show="getTipoParley && getTicketTarget.PremiosParley && getTicketTarget.PremiosParley[index]"
              >
                {{ formatoMonto(getTicketTarget.PremiosParley[index]) }}
              </td>

              <td
                colspan="1"
                style="text-align: right;"
                v-show="!getTipoParley && getTicketTarget.PremiosDirectas && getTicketTarget.PremiosDirectas[index]"
              >
                {{ formatoMonto(getTicketTarget.PremiosDirectas[index]) }}
              </td>
              <td v-show="!getTicketTarget.PremiosDirectas[index] && !getTicketTarget.PremiosParley[index]">
                ---
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <table
      v-if="getTicketTarget && this.getWidth() >= 1300"
      :class="{
        table: true,
        'table-dark': !this.getThemelight,
        'table-light': this.getThemelight
      }"
      cellpadding="0"
      cellspacing="0"
      id="details"
      style="width: 100%;"
    >
      <thead>
        <tr>
          <th style="text-align: center;">#</th>
          <th style="text-align: center;">FECHA</th>
          <th style="text-align: center;">EQUIPO</th>
          <th style="text-align: center;">APUESTA</th>
          <th style="text-align: center;">LOGRO</th>
          <th style="text-align: center;">ESTATUS</th>
          <th style="text-align: center;" v-if="this.getTipeTicket(getTicketTarget.modalidad) != 'Parley'">PREMIO</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(odd, index) in getTicketTarget.Odds" :key="index">
          <th>{{ index + 1 }}</th>
          <td>{{ dateFormat(odd.time) }}</td>
          <td style="text-align: left;">
            <!-- <p>{{ odd.league_name }}: <b v-html="odd.teams"></b></p> -->
            <p v-html="formatoEquipo(odd.equipos)"></p>
          </td>
          <td style="text-align: center;">({{ odd.type }} {{ odd.final }})</td>
          <td style="text-align: center;">
            {{ odd[getFormatoTaquilla] }}
            <br />
            <div class="fraction-container" v-html="toFraction(odd.factor)"></div>
          </td>
          <td style="text-align: center;">{{ odd.status }}</td>
          <td
            style="text-align: center;"
            v-show="
              getTipoParley &&
                getTicketTarget.PremiosParley &&
                getTicketTarget.PremiosParley[index] &&
                getTipeTicket(getTicketTarget.modalidad) != 'Parley'
            "
          >
            {{ formatoMonto(getTicketTarget.PremiosParley[index]) }}
          </td>
          <td
            style="text-align: center;"
            v-show="
              !getTipoParley &&
                getTicketTarget.PremiosDirectas &&
                getTicketTarget.PremiosDirectas[index] &&
                getTipeTicket(getTicketTarget.modalidad) != 'Parley'
            "
          >
            {{ formatoMonto(getTicketTarget.PremiosDirectas[index]) }}
          </td>
          <td
            style="text-align: center;"
            v-show="
              !getTicketTarget.PremiosParley &&
                !getTicketTarget.PremiosDirectas &&
                getTipeTicket(getTicketTarget.modalidad) != 'Parley'
            "
          >
            --
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pb-2">
      SU TICKET HA SIDO GENERADO Y GUARDADO PARA QUE PUEDA VISUALIZARLO EN SUS REPORTES DE TICKETS. AL GENERAR ESTE
      TICKET USTED HA ACEPTADO LAS REGLAS, TERMINOS Y CONDICIONES DE SELLATUPARLEY.COM
    </div>
    <div
      :class="{
        'footer-botonera pt-2': true,
        'bg-dark': !this.getThemelight,
        'bg-light': this.getThemelight
      }"
    >
      <button class="btn mt-3 float-right btn-cerrar-modal-detail btn-secondary btn-secondary" @click="cerrarModal">
        Cerrar
      </button>
    </div>
  </b-modal>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { essentialsData } from "../../mixins/essentialsDataMixin.js";
import * as u from "j899";

export default {
  name: "TicketTarget",
  mixins: [essentialsData],
  props: {
    ticketTarget: Object
  },
  computed: {
    ...mapGetters(["getTicketTarget", "getFormatoTaquilla", "getTipoParley", "getThemelight"])
  },
  methods: {
    formatoEquipo(equiposString) {
      return equiposString.toString().replaceAll("color:green", "color:#ffc107");
    },
    getWidth() {
      return parseInt(window.visualViewport.width);
    },
    dinamicClassCss() {
      const classCss = this.getWidth() > 1300 ? "details-ticket-target container-tickets-interno" : "";
      return classCss;
    },
    cerrarModal() {
      this.$bvModal.hide("details-ticket-target");
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm A");
      }
    },
    formatoMonto(m) {
      let montoFormato = parseFloat(parseFloat(m).toFixed(2));
      return u.inNumeration("LA", montoFormato);
    },
    getTipeTicket(m) {
      return m === "false" || m === false ? "Directas" : "Parley";
    }
  },
  mounted() {
    //this.ticketTarget = new Object(ticket);
    //this.$bvModal.show("details-ticket-target");
  }
};
</script>
<style>
.details-ticket-target table:nth-child(1) tbody tr td {
  padding: 0;
  margin: 0;
  border: none;
  height: 1.8em;
}

.details-ticket-target.bg-light table:nth-child(1) tbody {
  background-color: #e8ebed !important;
}
.details-ticket-target.bg-dark table:nth-child(1) tbody {
  background-color: #49535c;
}

.details-ticket-target table:nth-child(1) tbody > tr > td {
  padding-right: 15px;
  padding-left: 15px;
}

.details-ticket-target table:nth-child(1) tbody > tr:nth-child(1) > td:nth-child(1),
.details-ticket-target table:nth-child(1) tbody > tr:nth-child(1) > td:nth-child(2) {
  padding-top: 15px;
}

.details-ticket-target table:nth-child(1) tbody > tr:nth-child(3) > td:nth-child(1),
.details-ticket-target table:nth-child(1) tbody > tr:nth-child(3) > td:nth-child(2) {
  padding-bottom: 15px;
}

/*segunda tabla interna*/
table#details tbody > tr > th p,
table#details tbody > tr > td p {
  margin: 0px;
  padding: 0px;
}

table#details tbody > tr > th,
table#details tbody > tr > td {
  padding: 0em 0.5em !important;
  height: 1.8em !important;
  line-height: 1.5;
}

table.table-top-details > tbody > tr:nth-child(1) > td:nth-child(1),
table.table-top-details > tbody > tr:nth-child(1) > td:nth-child(2) {
  padding-top: 15px;
}

table.table-top-details > tbody > tr:nth-child(3) > td:nth-child(1),
table.table-top-details > tbody > tr:nth-child(3) > td:nth-child(2) {
  padding-bottom: 15px;
}

div.modal-content.details-ticket-target.container-tickets-interno {
  width: 1300px !important;
  /*max-width: 1300px !important;*/
  max-width: 95vw !important;
}

.footer-botonera.bg-dark {
  border-top-width: thin !important;
  border-top-style: dashed !important;
  border-top-color: rgba(255, 255, 255, 0.3) !important;
}
</style>
<style scoped="scoped">
.card {
  margin-top: 100px;
  width: 95%;
}

.col-ticket {
  width: 20%;
}

.col-type {
  width: 28%;
}

.txt-center {
  text-align: center;
}

.col-ticket,
.col-ticket > b {
  word-wrap: break-word;
  max-width: 200px;
}

.table-responsive.mailbox-messages,
.table-responsive.mailbox-messages > div,
.table-tickets,
.no-margin {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.col-icon {
  width: 50px;
}

.col-type {
  width: 220px;
}

.container-tickets {
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
}

table > thead > tr > th {
  font-weight: 300;
}

/* table > tbody > tr:hover {
  background-color: rgb(255, 255, 255);
  color: #343a40;
} */
</style>
