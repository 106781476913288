<template>
  <div>
    <div class="card w-100">
      <div class="card-body">
        <div class="text-right form-inline mt-4" v-if="getIsMobilResolution">
          <i class="fas fa-chevron-left fa-lg text-dark ml-2" v-on:click="back()"></i>
        </div>
        <div
          class="row"
          :class="{ 'dinamyc-top': this.getVisualWidth < 1200 }"
          v-if="!this.divisa && !this.monedaExtranjera"
        >
          <div class="col-6 text-center">
            <a style="cursor: pointer" @click="view(2)">
              <img v-lazy="'/img/pagomovil.png'" alt="" :class="{ 'img-option-mobile mt-3': getIsMobilResolution }" />
              <!-- <i class="fa fa-mobile" style="font-size: 1.2em"></i>Pago móvil -->
            </a>
          </div>
          <div class="col-6 text-center">
            <a style="cursor: pointer" @click="view(1)" href="#transBanco">
              <img v-lazy="'/img/transfe.png'" alt="" :class="{ 'img-option-mobile mt-3': getIsMobilResolution }" />
              <!-- <i class="fa fa-bank" style="font-size: 1.2em"></i>Transferencia -->
            </a>
          </div>
        </div>

        <div v-else-if="this.divisa">
          <!-- <h1>Lista de criptos</h1> -->
          <div class="row">
            <div class="col-md col-6" v-for="gateway in this.getCriptoGateways" v-bind:key="gateway.id">
              <div
                class="p-2 mb-4 rounded text-center sel-effect"
                @click="recargarCripto(gateway)"
                style="cursor: pointer"
              >
                <img v-lazy="gateway.url" width="75" height="75" />
                <br />
                {{ gateway.name }}
              </div>
            </div>
          </div>
          <div class="row bg-primary mb-2 text-center">
            <div class="col-1 my-2"></div>
            <div class="col-2 my-2">
              <b>{{ $t("fecha") }}</b>
            </div>
            <div class="col-3 my-2"><b>ESTADO</b></div>
            <div class="col-3 my-2">
              <b>{{ $t("pasarela") }}</b>
            </div>
            <div class="col-3 my-2">
              <b>{{ $t("monto") }}</b>
            </div>
          </div>

          <details v-for="payment in this.getCriptoPayments" :key="payment.index">
            <summary class="my-1" @click="cambiarIcono(payment._id)">
              <div class="row">
                <div class="col-1 text-center">
                  <i class="fas fa-plus text-primary" :id="'list' + payment._id"></i>
                </div>
                <div class="col-2 text-center">
                  {{ dateFormat(payment.created_at) }}
                </div>
                <div class="col-3 text-center">
                  <div v-if="payment.status == 0"><b>Pendiente</b></div>
                  <div v-if="payment.status == 2"><b>Acreditado</b></div>
                  <div v-if="payment.status == 3"><b>Completado</b></div>
                </div>
                <div class="col-3 text-center">
                  {{ payment.gateway }}
                </div>
                <div class="col-3 text-center">{{ getSimboloMoneda }}{{ payment.amount }}</div>
              </div>
            </summary>
            <div class="row">
              <div class="col-12 text-center" v-if="payment.payer"><b>Cuenta:</b> {{ payment.payer }}</div>
              <div class="col-12 text-center" v-if="payment.ref"><b>Referencia:</b> {{ payment.ref }}</div>
            </div>
          </details>
        </div>

        <br />
        <div v-if="this.getIsMobilResolution" class="text-center" style="font-size: 0.8em">
          <button class="btn btn-success text-center mb-3" @click="verCuentas()">
            <i class="fa fa-eye"></i> Ver cuentas bancarias y datos de pago movil
          </button>

          <div v-if="this.verCuentasBancarias">
            <div class="text-white py-1" style="background-color: #001f62; border-radius: 10px">
              <h4><b>Pago móvil</b></h4>
            </div>
            <ul>
              <li class="text-dark">
                <div class="row border-bottom">
                  <div class="col-4 my-1">
                    <b>{{ $t("banco") }}</b>
                  </div>
                  <div class="col-3 my-1">
                    <b>{{ $t("codigo") }}</b>
                  </div>
                  <div class="col-5 my-1">
                    <b>{{ $t("telefono") }}</b>
                  </div>
                </div>
              </li>
              <li class="text-dark" v-for="cuenta in this.getCuentasBancosCMS" v-bind:key="cuenta.id">
                <div class="row" v-if="cuenta.tipo == 'm'" id="list">
                  <div class="col-4 p-0 text-left">
                    {{ cuenta.banco }}
                  </div>
                  <div class="col-3">
                    {{ cuenta.codigo }}
                  </div>
                  <div class="col-5">
                    {{ cuenta.telefono }}
                  </div>
                </div>
              </li>
            </ul>
            <div class="text-white py-1" style="background-color: #001f62; border-radius: 10px">
              <h4><b>Cuentas Bancarias</b></h4>
            </div>
            <ul>
              <li class="text-dark">
                <div class="row border-bottom">
                  <div class="col-3 my-1">
                    <b>{{ $t("banco") }}</b>
                  </div>
                  <div class="col-4 my-1">
                    <b>Rif.</b>
                  </div>
                  <div class="col-5 my-1">
                    <b>{{ $t("numero_de_cuenta") }}</b>
                  </div>
                </div>
              </li>
              <li class="text-dark" v-for="cuenta in this.getCuentasBancosCMS" v-bind:key="cuenta.id">
                <div class="row box" v-if="cuenta.tipo == 'c'" id="list">
                  <div class="col-3 p-0 text-left">
                    {{ cuenta.banco }}
                  </div>
                  <div class="col-4">
                    {{ cuenta.documento }}
                  </div>
                  <div class="col-5 p-0">
                    {{ cuenta.nro_cuenta.replaceAll("-", "") }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="transferView == 1">
          <div v-if="this.getRecargaCMS[0]" v-html="this.getRecargaCMS[0].contenido"></div>

          <div class="text-center">
            <div class="callout callout-warning my-3 bg-light text-center">
              <h4 id="transBanco">Transferencia bancaria</h4>
              <div style="font-size: 0.9em">
                <div>A NOMBRE DE: CORPORACIÓN TECNODATA 2015 C.A.</div>
                <div>RIF: J404297286</div>
                <div>EMAIL: info@sellatuparley.com</div>
              </div>
            </div>
          </div>

          <div class="row mb-2 mt-2">
            <div class="col-sm-12 col-lg-6">
              <div class="form-group" style="cursor: pointer">
                <label for="tipoTransaccion">Tipo de transacción</label>
                <v-select
                  @input="muestraBancoOrigen()"
                  class="style-chooser"
                  placeholder="Seleccione..."
                  :options="banks"
                  style="width: 100%; color: black; z-index: 3"
                  :reduce="bank => bank.banco"
                  v-model="payOperationId"
                  label="label"
                  :searchable="false"
                  :clearable="false"
                >
                </v-select>
              </div>
            </div>
            <div class="col-sm-12 col-lg-6" v-if="transactionType == 2" style="z-index: 2">
              <div class="form-group">
                <label for="bancoOrigen">{{ $t("banco_origen") }}</label>
                <v-select
                  class="style-chooser"
                  placeholder="Seleccione..."
                  :options="getCenterBanks"
                  style="width: 100%; color: black"
                  :reduce="center => center.id"
                  v-model="bankId"
                  label="nombre"
                  :searchable="false"
                >
                </v-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-lg-6">
              <div class="form-group">
                <label for="bancoDestino">{{ $t("banco_destino") }}</label>
                <v-select
                  class="style-chooser"
                  placeholder="Seleccione..."
                  :options="getCenterAccounts"
                  style="width: 100%; color: black"
                  :reduce="account => account.id"
                  v-model="accountId"
                  label="banco"
                  :searchable="false"
                >
                </v-select>
              </div>
            </div>
            <div class="col-sm-12 col-lg-6">
              <div class="form-group">
                <label for="monto">{{ $t("monto") }}</label>
                <input type="number" class="form-control rounded-0" id="monto" placeholder="0.00" v-model="monto" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-lg-6">
              <div class="form-group">
                <label for="referencia">{{ $t("numero_de_referencia") }}</label>
                <input
                  type="number"
                  v-model="operationNumber"
                  class="form-control rounded-0"
                  id="referencia"
                  placeholder="Número de referencia"
                />
              </div>
            </div>
            <div class="col-sm-12 col-lg-6">
              <div>
                <label>{{ $t("fecha_de_la_transaccion") }}:</label>
                <Datepicker
                  :language="languages[language]"
                  v-model="date"
                  placeholder="Seleccione fecha"
                  class="datepicker-custom"
                  calendar-class="calendar"
                ></Datepicker>
              </div>
            </div>
          </div>
          <div class="row checks mt-3">
            <div class="col-sm-12 col-lg-6 text-center">
              <input type="checkbox" id="bonos" v-model="bonos" class="mr-3" />
              <label for="bonos"
                >{{ $t("optar_por_bonos_de_recargas") }}
                <a
                  href="#"
                  style="color: #001f62; font-weight: 300"
                  data-toggle="modal"
                  data-target="#modalBonosTerminosCondiciones"
                  class="text-primary"
                  >({{ $t("ver") }})</a
                >
              </label>
            </div>
          </div>
          <div class="row checks">
            <div class="col-sm-12 col-lg-6 text-center">
              <input type="checkbox" id="terminosCondiciones" v-model="terms" class="mr-2" />
              <label for="terminosCondiciones">
                {{ $t("terminos_condi") }}

                <a
                  href="#"
                  style="color: #001f62; font-weight: 300"
                  data-toggle="modal"
                  data-target="#modalTerminosCondiciones"
                  class="text-primary"
                  >({{ $t("ver") }})</a
                >
              </label>
            </div>
          </div>
        </div>
        <div v-if="transferView == 2 && !tienePagoMobilRegistrado()">
          <!--
            Jesus probando puso una carita feliz por aca (-.-) 
            <div v-if="this.getRecargaPagoMovilCMS[0]" v-html="this.getRecargaPagoMovilCMS[0].contenido"></div> 
          -->

          <div class="row mb-2 mt-2" v-if="this.codigoConfirmationSend">
            <div class="col-lg-12 mb-4 message alert alert-success text-center">
              {{ $t("codigo_de_confirmacion_enviado_a_su_telefono") }}
            </div>
            <div class="col-lg-6 col-6 text-right">Ingreses su Código de confirmaci&oacute;n</div>
            <div class="col-lg-2 col-4">
              <input type="text" v-model="codigoConfirmation" class="form-control" />
            </div>
          </div>

          <div class="text-center" v-show="!this.codigoConfirmationSend">
            <div id="content-help-pagomobil" class="callout callout-secondary my-3 bg-light text-center">
              <h4>Pago Móvil</h4>
              <div style="font-size: 1em">
                <div>Registro de número de teléfono con el pago móvil de tu banco</div>
                <div>Las recargas y retiros serán procesados con el número telefónico y banco seleccionado</div>
              </div>
            </div>
          </div>
          <div class="row" v-show="!this.codigoConfirmationSend">
            <div class="col-lg-3"></div>
            <div class="col-sm-11 col-lg-2">
              <div class="form-group">
                <label for="codigo">Código de área</label>
                <v-select
                  class="style-chooser"
                  placeholder="Seleccione..."
                  :options="codes"
                  style="width: 100%; color: black"
                  :reduce="code => code.code"
                  v-model="codigo"
                  label="label"
                  :searchable="false"
                  :clearable="false"
                >
                </v-select>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4">
              <div class="form-group">
                <label for="telefono">Número de teléfono</label>
                <input
                  type="number"
                  class="form-control rounded-0"
                  id="telefono"
                  v-model="numero"
                  placeholder="Número de teléfono"
                />
              </div>
            </div>
          </div>
          <div class="row mb-2 mt-2" v-show="!this.codigoConfirmationSend">
            <!-- <div class="col-lg-3"><label>Banco</label></div> -->
            <div class="col-lg-3"></div>
            <div class="col-sm-12 col-lg-6">
              <label for="telefono">Banco</label>
              <v-select
                class="style-chooser"
                placeholder="Seleccione..."
                :options="getCenterBanks"
                style="width: 100%; color: black; z-index: 0"
                :reduce="center => center.id"
                v-model="bankMovil"
                label="nombre"
                :searchable="false"
              >
              </v-select>
            </div>
            <div class="col-lg-3"></div>
          </div>
        </div>

        <div v-if="transferView == 2 && tienePagoMobilRegistrado()">
          <!-- Jesus dejando caritas felices bien bueno
            <div v-if="this.getRecargaPagoMovilCMS[0]" v-html="this.getRecargaPagoMovilCMS[0].contenido"></div> -->
          <div class="callout callout-secondary my-3 bg-light text-center">
            <p>Lo sentimos no puede registrar mas numeros moviles</p>
            <h4 v-if="this.getIsMobilResolution">Ya usted posee un Pago Móvil Registrado</h4>
            <h4 v-else>Usted ya registró un número de celular para realizar pagos móviles</h4>
            <div style="font-size: 1em">
              <div style="font-weight: 700">
                {{ this.getAccountsPagoMobil[0].banco }}
              </div>
              <div>
                {{ this.getAccountsPagoMobil[0].account.substr(0, 5) }} - ***
                {{ this.getAccountsPagoMobil[0].account.substr(-4, 4) }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="transferView == 3">
          <div class="text-center bg-light mt-2"><h2>Datos del Cliente</h2></div>

          <div class="row mb-2 mt-2">
            <div class="col-sm-12 col-lg-12">
              <div class="form-group" style="cursor: pointer">
                <label for="monto">Dirección</label>
                <input type="text" class="form-control rounded-0" id="direccion" v-model="direccion" />
              </div>
            </div>
          </div>

          <div class="row mb-2 mt-2">
            <div class="col-sm-12 col-lg-6">
              <div class="form-group" style="cursor: pointer">
                <label for="monto">Ciudad</label>
                <input type="text" class="form-control rounded-0" id="ciudad" v-model="ciudad" />
              </div>
            </div>
            <div class="col-sm-12 col-lg-6">
              <div class="form-group" style="cursor: pointer">
                <label for="monto">Región</label>
                <input type="text" class="form-control rounded-0" id="region" v-model="region" />
              </div>
            </div>
          </div>

          <div class="row mb-2 mt-2">
            <div class="col-sm-12 col-lg-6">
              <div class="form-group" style="cursor: pointer">
                <label for="monto">País</label>
                <input type="text" class="form-control rounded-0" id="pais" v-model="pais" />
              </div>
            </div>
            <div class="col-sm-12 col-lg-6">
              <div class="form-group" style="cursor: pointer">
                <label for="monto">Código Postal</label>
                <input type="text" class="form-control rounded-0" id="codigo" v-model="codigo" />
              </div>
            </div>
          </div>

          <div class="row mb-2 mt-2">
            <div class="col-sm-12 col-lg-6">
              <div class="form-group" style="cursor: pointer">
                <label for="tipoTransaccion">Tipo de transacción</label>
                <v-select
                  class="style-chooser"
                  placeholder="Seleccione..."
                  :options="typeTransactionMonnet"
                  style="width: 100%; color: black; z-index: 3"
                  :reduce="typeTransactionMonnet => typeTransactionMonnet.key"
                  v-model="tipoTransaccion"
                  label="value"
                  :searchable="false"
                  :clearable="false"
                >
                </v-select>
              </div>
            </div>
            <div class="col-sm-12 col-lg-6" style="z-index: 2">
              <div class="form-group">
                <label for="monto">Monto</label>
                <input type="number" class="form-control rounded-0" id="monto" placeholder="0.00" v-model="monto" />
              </div>
            </div>
          </div>

          <!-- <div class="row checks mt-3">
            <div class="col-sm-12 col-lg-6 text-center">
              <input type="checkbox" id="bonos" v-model="bonos" class="mr-3" />
              <label for="bonos"
                >Optar por bonos de recargas                
                <a
                  href="#"
                  style="color: #001f62; font-weight: 300"
                  data-toggle="modal"
                  data-target="#modalBonosTerminosCondiciones"
                  class="text-primary"
                  >(Ver)</a
                >
              </label>
            </div>
          </div> -->
          <div class="row checks">
            <div class="col-sm-12 col-lg-6 text-center">
              <input type="checkbox" id="terminosCondiciones" v-model="terms" class="mr-2" />
              <label for="terminosCondiciones">
                Acepta los términos y condiciones
                <!-- <a v-on:click="$emit('changePage', 'Terminos')" class="text-primary">(Ver)11</a> -->
                <a
                  href="#"
                  style="color: #001f62; font-weight: 300"
                  data-toggle="modal"
                  data-target="#modalTerminosCondiciones"
                  class="text-primary"
                  >(Ver)</a
                >
              </label>
            </div>
          </div>
        </div>
      </div>

      <div v-if="this.url">
        <iframe class="text-center" :src="this.url" width="800" height="1080"></iframe>
      </div>

      <div class="row justify-content-center text-center">
        <div class="col-3"></div>
        <div class="col-6 pb-4">
          <div
            class="text-center"
            v-show="transferView == 1 || transferView == 3 || (transferView == 2 && !tienePagoMobilRegistrado())"
          >
            <button
              type="submit"
              class="btn btn-primary"
              :class="{
                'btn-recarga-mobile btn-sm mb-2': getIsMobilResolution,
                'boton-color-fondo': !getIsMobilResolution
              }"
              @click="validarForm()"
              v-if="(transferView == 1 || transferView == 2 || transferView == 3) && !this.codigoConfirmationSend"
            >
              REGISTRAR
            </button>

            <button
              type="submit"
              class="btn btn-primary"
              :class="{
                'btn-recarga-mobile btn-sm mb-2': getIsMobilResolution,
                'boton-color-fondo': !getIsMobilResolution
              }"
              @click="confirmarRegistroPagoMovil()"
              v-if="(transferView == 1 || transferView == 2) && this.codigoConfirmationSend"
            >
              CONFIRMAR
            </button>
          </div>
        </div>
        <div class="col-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { essentialsData } from "../mixins/essentialsDataMixin.js";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import { mapGetters } from "vuex";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  mixins: [essentialsData],
  data() {
    return {
      date: new Date(),
      language: "es", // the language I need
      languages: lang,
      transferView: 0,
      //Form data
      payOperationId: null,
      bankId: null,
      operationNumber: "",
      accountId: null,
      monto: "",
      bankMovil: null,
      codigo: null,
      movilData: null,
      codigoConfirmationSend: false,
      codigoConfirmation: null,
      numero: "",
      terms: false,
      bonos: false,
      transactionType: null,
      banks: [
        { banco: 1, label: "Deposito" },
        { banco: 2, label: "Transferencia" }
      ],
      codes: [
        { code: "0416", label: "0416" },
        { code: "0426", label: "0426" },
        { code: "0414", label: "0414" },
        { code: "0424", label: "0424" },
        { code: "0412", label: "0412" }
      ],
      verCuentasBancarias: false,
      divisa: false,
      monedaExtranjera: false,
      monnet: false,
      typeTransactionMonnet: [],
      direccion: "",
      ciudad: "",
      pais: "",
      region: "",
      tipoTransaccion: "Cash",
      url: ""
    };
  },
  components: {
    Datepicker,
    vSelect
  },
  props: {
    module: String
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getCriptoGateways",
      "getCriptoPayments",
      "getCriptoWallet",
      "getImgCripto",
      "getCenterBanks",
      "getCenterAccounts",
      "getIsMobilResolution",
      "getAccountsPagoMobil",
      "getCuentasBancosCMS",
      "getVisualWidth",
      "getRecargaCMS",
      "getRecargaPagoMovilCMS",
      "getSimboloMoneda",
      "getPaymentMonnet"
    ])
  },
  watch: {
    async payOperationId(value) {
      if (parseInt(value) == 2) {
        if (!this.getCenterBanks || !this.getCenterAccounts) {
          await this.$store.dispatch("getCenterAccountsBanksAsync");
        }
      }
    }
  },
  methods: {
    cambiarIcono(paymentId) {
      $("#list" + paymentId)
        .toggleClass("fa-minus")
        .toggleClass("fa-plus");
    },
    dateFormat(value) {
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY HH:mm:ss A");
      }
    },
    confirmarRegistroPagoMovil: async function() {
      if (!this.codigoConfirmation || this.codigoConfirmation == "") {
        this.Swal.fire("Lo sentimos", "No puede dejar el codigo vacio", "error");
        return;
      }

      this.movilData.code = parseInt(this.codigoConfirmation);

      this.$store.commit("showLoader");

      const reg = await this.$userService.registerMovil(this.movilData);

      this.$store.commit("hideLoader");

      if (reg.error && reg.error !== "ok") {
        this.Swal.fire("Lo sentimos", reg.error, "error");
      }

      if (reg.error && reg.error == "ok" && reg.result) {
        this.bankMovil = null;
        this.numero = "";
        this.codigo = null;

        this.$emit("resgistroPagoMobil", "success");
        this.$emit("changeTarget", "RecargasTableMovil");
      }
    },
    irAterminos: function() {
      this.$emit("changeTarget", "BonusTermsConditions");
    },
    back() {
      this.$emit("changePage", "RecargasTableMovil");
    },
    async verCuentas() {
      let ObjCuentaBancoRequest = { url: "/api/v1/getCuentaBanco", set: "setCuentasBancosCMS" };
      await this.$store.dispatch("getData", ObjCuentaBancoRequest);
      this.verCuentasBancarias = !this.verCuentasBancarias;
    },
    tienePagoMobilRegistrado() {
      return this.getAccountsPagoMobil && this.getAccountsPagoMobil.length > 0 ? true : false;
    },
    validarForm() {
      if (this.transferView == 1) {
        if (!this.payOperationId || this.payOperationId == 0) {
          this.Swal.fire("Por favor", "Seleccione un tipo de transacción", "error");
          return false;
        }
        if (!this.bankId && this.payOperationId == 2) {
          this.Swal.fire("Por favor", "Seleccione un banco de origen", "error");
          return false;
        }
        if (!this.accountId || this.accountId == 0) {
          this.Swal.fire("Por favor", "Seleccione un banco de destino", "error");
          return false;
        }
        if (this.monto == 0) {
          this.Swal.fire("Por favor", "Debe incluir monto de transacción", "error");
          return false;
        }
        if (this.operationNumber == "") {
          this.Swal.fire("Por favor", " Debe incluir número de referencia", "error");
          return false;
        }
        if (!this.terms) {
          this.Swal.fire("Por favor", "Debe aceptar los términos y condiciones", "error");
          return false;
        }

        this.registrarRecarga();
      } else if (this.transferView == 2) {
        if (!this.codigo || this.codigo == 0) {
          this.Swal.fire("Por favor", "Seleccione un código de área", "error");
          return false;
        }
        if (this.numero == "") {
          this.Swal.fire("Por favor", "Ingrese número de teléfono", "error");
          return false;
        }
        if (!this.bankMovil || this.bankMovil == 0) {
          this.Swal.fire("Por favor", "Seleccione banco", "error");
          return false;
        }

        this.registrarPagoMovil();
      } else {
        if (!this.direccion) {
          this.Swal.fire("Por favor", "Ingrese dirección", "error");
          return false;
        }
        if (!this.ciudad) {
          this.Swal.fire("Por favor", "Ingrese ciudad", "error");
          return false;
        }
        if (!this.region) {
          this.Swal.fire("Por favor", "Ingrese región", "error");
          return false;
        }
        if (!this.pais) {
          this.Swal.fire("Por favor", "Ingrese país", "error");
          return false;
        }
        if (!this.codigo) {
          this.Swal.fire("Por favor", "Ingrese código postal", "error");
          return false;
        }
        if (!this.tipoTransaccion) {
          this.Swal.fire("Por favor", "Ingrese tipo de transaccion", "error");
          return false;
        }
        if (!this.monto) {
          this.Swal.fire("Por favor", "Ingrese monto", "error");
          return false;
        }
        if (!this.terms) {
          this.Swal.fire("Por favor", "Debe aceptar los términos y condiciones", "error");
          return false;
        }
        this.registrarMonnet();
      }
    },
    async view(index) {
      this.$store.commit("showLoader");
      if (!this.getRecargaCMS) {
        await this.$store.dispatch("getData", {
          url: "/api/v1/getNotas?modulo=1",
          set: "setRecargaCMS"
        });
      }
      this.transferView = index;

      setTimeout(() => {
        // scroll al seleccionar pagomobil
        let containerAyudaPagomobil = document.getElementById("content-help-pagomobil");
        if (index == 2 && containerAyudaPagomobil) {
          containerAyudaPagomobil.scrollIntoView();
        }
        this.$store.commit("hideLoader");
      }, 250);
    },
    async registrarRecarga() {
      const paymentData = {
        payOperationId: this.payOperationId,
        bankId: this.bankId,
        accountId: this.accountId,
        monto: this.monto,
        operationNumber: this.operationNumber,
        date: moment(this.date).format("YYYY-MM-DD")
      };

      const texto1 = "Su recarga ha sido registrada satisfactoriamente. <br>";
      const texto2 = "En este momento está siendo procesada. <br> <br>";
      const texto3 =
        "Si su recarga fue realizada desde el mismo banco será procesada en menos de 30 minutos, si la realizó desde otro banco será procesada al siguiente día hábil bancario.";

      this.$store.commit("showLoader");
      const reg = await this.$userService.registerPayment(paymentData);
      this.$store.commit("hideLoader");

      if (reg.error && reg.error !== "ok") {
        this.Swal.fire("Lo sentimos", reg.error, "error");
      }

      if (reg.error && reg.error == "ok" && reg.result) {
        this.payOperationId = null;
        this.bankId = null;
        this.accountId = null;
        this.monto = null;
        this.operationNumber = "";
        this.terms = false;
        this.date = new Date();
        //this.$v.$reset();
        this.refreshData();

        this.Swal.fire("Excelente!", texto1 + texto2 + texto3, "success").then(async result => {
          if (result.value) {
            await this.$store.dispatch("getPaymentsUserAsync", {
              page: 1,
              perpage: 15
            });
            this.$emit("changePage", "RecargasTableMovil");
          }
        });
      }
    },
    async registrarPagoMovil() {
      let numeroMovilFormato = this.codigo.replaceAll("0", "58") + this.numero;

      this.movilData = {
        type_account_id: 4,
        typeAccountId: 4,
        bankId: this.bankMovil,
        account: numeroMovilFormato
      };

      this.$store.commit("showLoader");

      const reg = await this.$store.dispatch("validatePhone", numeroMovilFormato);

      if (reg.valid_phone && reg.valid_phone.status && reg.valid_phone.status == "1") {
        this.codigoConfirmationSend = true;
      }

      this.$store.commit("hideLoader");
      return;
    },
    async registrarMonnet() {
      let paymentData = {
        payinMethod: this.tipoTransaccion,
        monto: Number(this.monto),
        address: {
          id: this.getPaymentMonnet.direccion.id,
          zip: this.codigo,
          pais: this.pais,
          ciudad: this.ciudad,
          region: this.region,
          direccion: this.direccion
        }
      };

      this.$store.commit("showLoader");
      const reg = await this.$userService.registerPaymentMonnet(paymentData);
      this.$store.commit("hideLoader");

      if (reg.error) {
        this.Swal.fire("Disculpe", reg.error, "warning");
      } else {
        this.transferView = 0;
        this.url = reg.url;
      }
    },
    muestraBancoOrigen() {
      this.transactionType = this.payOperationId;
    },
    async recargarCripto(gateway) {
      if (gateway.id == "BANCOS") this.divisa = false;
      else {
        await this.$store.commit("setCriptoWallet", gateway);

        console.log("cripto: ", gateway);

        this.$bvModal.show("modalCriptoWallet");
      }
    }
  },
  async mounted() {
    this.$emit("barSolid", true);

    if (this.getUser.center_id == 6) {
      this.divisa = true;
    } else if ([5, 7, 8].includes(this.getUser.center_id)) {
      this.monedaExtranjera = true;
      this.monnet = true;
      await this.$store.dispatch("getCriptoAsync", {
        url: "api/client?payment_monnet_init",
        set: "setPaymentMonnet",
        name: "payment_monnet_init"
      });
      this.view(3);

      let objTransactions = Object.entries(this.getPaymentMonnet.PayInMethod);

      Object.entries(objTransactions).forEach(([key, value]) => {
        let objTransaction = {
          id: key,
          key: value[0],
          value: value[1]
        };

        this.typeTransactionMonnet.push(objTransaction);
      });

      this.direccion = this.getPaymentMonnet.direccion.direccion;
      this.ciudad = this.getPaymentMonnet.direccion.ciudad;
      this.region = this.getPaymentMonnet.direccion.region;
      this.pais = this.getPaymentMonnet.direccion.pais;
      this.codigo = this.getPaymentMonnet.direccion.zip;
    }

    if (this.divisa) {
      await this.$store.dispatch("getCriptoAsync", {
        url: "api/crypto?gateways",
        set: "setCriptoGateways",
        name: "gateways"
      });

      await this.$store.dispatch("getCriptoAsync", {
        url: "api/crypto?payments",
        set: "setCriptoPayments",
        name: "payments"
      });
    }

    $(document).ready(function() {
      if (window.innerWidth < 768) {
        $(".btn").addClass("btn-sm");
        $(".callout-warning").removeClass("w-50");
      }

      // Medida por defecto (Sin ningún nombre de clase)
      else if (window.innerWidth < 900) {
        $(".btn").removeClass("btn-sm");
        $(".callout-warning").addClass("w-50");
      }

      // Si el ancho del navegador es mayor a 900px
      else if (window.innerWidth > 900) {
        $(".btn").addClass("btn-lg");
        $(".callout-warning").addClass("w-50");
      }
    });
  }
};
</script>
<style>
/* .dinamyc-top {
  margin-top: 5% !important;
} */
.card-title {
  text-align: justify;
  width: 100%;
  padding: 0;
  font-weight: 300;
}
.capitalize {
  text-transform: capitalize;
  font-size: 1.5em;
  font-weight: 200;
}
.calendar {
  width: 50% !important;
}
.datepicker-custom input[type="text"] {
  border-color: #6c757d;
  color: #495057;
  border: 1px solid #ced4da;
  text-align: center;
  display: inline-block;
  background-clip: padding-box;
  border-radius: 0.25rem;
  width: 50%;
}
.checks {
  font-size: 1em !important;
}
.margin-check {
  margin-right: 8px;
}
.opt {
  font-size: 1em;
}
.callout-secondary {
  width: 50%;
}

.btn-recarga-mobile {
  background: #001f62 !important;
  width: 80% !important;
  border-radius: 10px;
  font-family: "Poppins", sans-serif !important;
}

.img-option-mobile {
  width: 9em;
}

@media screen and (min-width: 720px) {
  .container {
    margin-top: 0 !important;
    height: 1000px !important;
    overflow-y: hidden;
  }
}
@media screen and (max-width: 320px) {
  .checks {
    font-size: 0.7em !important;
  }
}

@media screen and (min-width: 321px) and (max-width: 480px) {
  .datepicker-custom input[type="text"] {
    width: 100%;
  }
  .margin-check {
    margin-left: 0.5em;
  }
  .opt {
    font-size: 0.8em;
  }
  .calendar {
    width: 100% !important;
  }
  .callout-secondary {
    width: 100%;
  }
  .checks {
    font-size: 0.8em !important;
  }
}

.title-list-banks {
  background-color: #001f62;
  border-radius: 10px;
}

.style-chooser .vs__dropdown-toggle {
  border: 1px solid #ced4da !important;
  cursor: pointer !important;
}
.style-chooser .vs__dropdown-menu {
  background: white;
  color: gray;
  text-transform: lowercase;
  font-variant: small-caps;
  cursor: pointer !important;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: black;
  cursor: pointer !important;
}

.style-chooser .vs__dropdown-option {
  color: black !important;
  font-weight: 800;
  cursor: pointer !important;
}

.style-chooser .vs__dropdown-option--highlight {
  background-color: #e6eaea;
  cursor: pointer !important;
}

li:nth-child(odd) #list {
  background-color: #eaecf0;
}

.sel-effect:active {
  background-color: #eaecf0;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.sel-effect:hover {
  background-color: #eaecf0;
}
details:nth-child(even) {
  background-color: #f7f3f1;
}
</style>
