<template>
  <div
    :class="{ container: this.getVisualWidth < 500, 'mx-5': this.getVisualWidth > 500 }"
    style="margin-top: 8em !important; margin-bottom: 8em"
    id="contenedor-taquilla"
  >
    <!-- <div class="text-center text-light">
      <button class="btn btn-success" @click="taquillaWeb()"><i class="fa fa-tv mr-1"></i> Ir a Taquilla Web</button>
    </div> -->
    <div class="text-right form-inline">
      <i class="fas fa-chevron-left fa-lg text-light ml-2" v-on:click="back()"></i>
    </div>
    <div class="text-center mb-2">
      <span class="title-sports text-light"> Deportes </span>
    </div>

    <div class="row">
      <div class="col-6 float-left">
        <button
          class="boton-green rounded pulsar-btn w-100"
          :class="{ 'boton-blue': this.getThemelight }"
          v-on:click="toPage('TicketsMovil')"
        >
          Tickets jugados
        </button>
      </div>
      <div class="col-6 float-left">
        <!-- <button class="boton-green rounded pulsar-btn">Streaming</button> -->
        <button
          class="boton-green rounded pulsar-btn text-center w-100"
          :class="{ 'boton-blue': this.getThemelight }"
          v-b-modal.modal-1
        >
          Streaming
        </button>
      </div>
    </div>

    <div class="card mt-3 btn-bg" :class="{ 'btn-bg-light': this.getThemelight }">
      <div class="row my-2 text-center" id="div-lapso">
        <div class="col">
          <button
            class="btn boton-dark mr-2 act pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnHoy"
            v-on:click="listarJuegos($event)"
          >
            Hoy
          </button>

          <button
            class="btn boton-dark pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnTodas"
            v-on:click="listarJuegos($event)"
          >
            Todas
          </button>

          <button
            class="btn boton-dark ml-2 pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnFecha"
            v-on:click="listarJuegos($event)"
          >
            Fecha
          </button>
        </div>
      </div>
    </div>

    <div class="card mt-3 btn-bg" :class="{ 'btn-bg-light': this.getThemelight }">
      <div
        class="row text-center my-1 font-weight-bold"
        :class="{ 'card-header-light': this.getThemelight, 'text-light': !this.getThemelight }"
      >
        <div class="col-6">Modalidad</div>
        <div class="col-6">Cuota</div>
      </div>
      <form>
        <div class="row text-center mt-0" id="div-tipos">
          <div class="col-6">
            <v-select
              class="style-chooser"
              placeholder="Modalidad"
              :options="modalidades"
              :reduce="modalidad => modalidad.type"
              v-model="modalidad"
              label="label"
              @input="changeTipoParley()"
              :searchable="false"
              :clearable="false"
            >
            </v-select>
          </div>
          <div class="col-6 text-left">
            <div class="mb-2">
              <v-select
                class="style-chooser"
                placeholder="Cuotas"
                :options="cuotas"
                :reduce="cuota => cuota.type"
                v-model="cuota"
                label="label"
                @input="cambiarCuota(cuota)"
                :searchable="false"
                :clearable="false"
              >
              </v-select>
            </div>
          </div>
          <div class="row text-center mb-2">
            <div class="col-6">
              <input
                type="number"
                id="amount"
                ref="amount2"
                placeholder="Monto Apuesta"
                size="15"
                style="height: 2.2em"
                class="form-control text-center"
                v-model.number="monto"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="col-6">
              <div class="bg-light rounded" style="height: 2.2em">
                <p style="padding-top: 0.4em !important" v-if="this.getPremio > 0">
                  {{ formatoMonto(this.getPremio) }}
                </p>
                <p style="padding-top: 0.4em !important; color: #6c757d" v-else>Monto Premio</p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row" v-if="calendario">
      <div class="col">
        <Datepicker
          :language="languages[language]"
          v-model="fecha"
          placeholder="Seleccione fecha"
          class="datepicker-custom"
          readonly="readonly"
          @input="filtrarJuegos()"
        ></Datepicker>
      </div>
    </div>
    <div
      class="card btn-bg mt-2"
      style="font-size: 0.8em"
      id="div-deportes"
      :class="{ 'btn-bg-light': this.getThemelight }"
    >
      <div class="row my-2 text-center">
        <div class="col-4">
          <button
            value="1"
            id="btnBeisbol"
            class="btn-sport boton-dark rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            v-on:click="seleccionarLigas($event)"
          >
            Beisbol
          </button>
        </div>
        <div class="col-4">
          <button
            value="2"
            id="btnFutbol"
            class="btn-sport boton-dark rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            v-on:click="seleccionarLigas($event)"
          >
            Fútbol
          </button>
        </div>
        <div class="col-4">
          <button
            value="3"
            class="btn-sport boton-dark rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnBasquet"
            v-on:click="seleccionarLigas($event)"
          >
            Basquet
          </button>
        </div>
      </div>
      <div class="row my-2 text-center">
        <div class="col-4">
          <button
            value="4"
            class="btn-sport boton-dark rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnFutame"
            v-on:click="seleccionarLigas($event)"
          >
            Fut Amer
          </button>
        </div>
        <div class="col-4">
          <button
            value="5"
            class="btn-sport boton-dark rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnHockey"
            v-on:click="seleccionarLigas($event)"
          >
            Hockey
          </button>
        </div>
        <div class="col-4">
          <button
            value="6"
            class="btn-sport boton-dark btn-block rounded pulsar-btn"
            :class="{ 'boton-light': this.getThemelight }"
            id="btnOtros"
            v-on:click="seleccionarLigas($event)"
          >
            Otros
          </button>
        </div>
      </div>
    </div>
    <div v-if="this.verOtrosDeportes" class="mb-3">
      <div class="row">
        <div class="col">
          <v-select
            @input="listarLigas()"
            class="style-chooser"
            placeholder="Seleccione deporte"
            :options="otros"
            style="width: 100%; color: black; z-index: 2"
            v-model="otro"
            label="id"
            :searchable="false"
          >
          </v-select>
        </div>
      </div>
    </div>

    <dir v-if="this.activaSelLiga">
      <div class="row my-2" v-if="this.ligas.length > 0">
        <div class="col">
          <v-select
            @input="filtrarJuegos()"
            class="style-chooser"
            placeholder="Seleccione ligas"
            :options="ligas"
            style="width: 100%; color: black"
            :reduce="liga => liga.id"
            v-model="liga"
            label="sportName"
            :searchable="false"
          >
          </v-select>
        </div>
      </div>
      <div class="card dark-bg text-light text-center my-3" :class="{ 'light-bg': this.getThemelight }" v-else>
        <h5 class="my-4">No hay juegos disponibles</h5>
      </div>
    </dir>

    <div class="card btn-bg" v-if="this.getUser" :class="{ 'btn-bg-light font-weight-bold': this.getThemelight }">
      <div class="m-2 text-sub" :class="{ 'bg-light': this.getThemelight }">
        <div class="row mt-2">
          <div class="col-6">
            <span class="ml-2"> Saldo: </span>
          </div>
          <div class="col-6 text-right">
            <span class="mr-2"> {{ getSimboloMoneda }}{{ formatNumeric(Number(this.getUser.credit)) }} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <span class="ml-2"> Saldo diferido: </span>
          </div>
          <div class="col-6 text-right">
            <span class="mr-2"> {{ getSimboloMoneda }}{{ formatNumeric(Number(this.getUser.diferido)) }} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <span class="ml-2"> Crédito: </span>
          </div>
          <div class="col-6 text-right">
            <span class="mr-2"> {{ getSimboloMoneda }}{{ formatNumeric(Number(this.getUser.credito)) }} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <span class="ml-2"> Total disponible: </span>
          </div>
          <div class="col-6 text-right">
            <span class="mr-2">
              {{ getSimboloMoneda
              }}{{
                formatNumeric(
                  Number(this.getUser.credit) + Number(this.getUser.credito) - Number(this.getUser.diferido)
                )
              }}
            </span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            <span class="ml-2"> Bonus: </span>
          </div>
          <div class="col-6 text-right">
            <span class="mr-2">{{ getSimboloMoneda }}{{ formatNumeric(this.getUser.bonus) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card dark-bg goBottom" v-if="verDestacados" :class="{ 'bg-header-light': this.getThemelight }">
      <div class="card-header bg-header" :class="{ 'card-header-light': this.getThemelight }" v-if="esDestacado">
        <div class="text-center" :class="{ 'font-weight-bold': this.getThemelight }">
          <h5>Juegos destacados</h5>
        </div>
      </div>

      <div class="dark-bg my-5" v-if="this.gamesEmpty" :class="{ 'light-bg': this.getThemelight }">
        <div class="text-center" style="color: #ffffff">
          <h5>No se encontaron registros</h5>
        </div>
      </div>

      <div class="game" v-for="(game, index) in this.getGames" :key="index">
        <!-- Componente central -->
        <component
          v-if="
            getNameGame(game.league_id, game.sport_id) == 'bandy' ||
              getNameGame(game.league_id, game.sport_id) == 'basketball' ||
              getNameGame(game.league_id, game.sport_id) == 'baseball' ||
              getNameGame(game.league_id, game.sport_id) == 'futbol' ||
              getNameGame(game.league_id, game.sport_id) == 'futbolsala' ||
              getNameGame(game.league_id, game.sport_id) == 'tennis' ||
              getNameGame(game.league_id, game.sport_id) == 'dardos' ||
              getNameGame(game.league_id, game.sport_id) == 'balonmano' ||
              getNameGame(game.league_id, game.sport_id) == 'pingpong' ||
              getNameGame(game.league_id, game.sport_id) == 'badminton' ||
              getNameGame(game.league_id, game.sport_id) == 'voleiball' ||
              getNameGame(game.league_id, game.sport_id) == 'pesapallo' ||
              getNameGame(game.league_id, game.sport_id) == 'hockey' ||
              getNameGame(game.league_id, game.sport_id) == 'footballAmericano' ||
              getNameGame(game.league_id, game.sport_id) == 'boxeo' ||
              getNameGame(game.league_id, game.sport_id) == 'bolos' ||
              getNameGame(game.league_id, game.sport_id) == 'rugby' ||
              getNameGame(game.league_id, game.sport_id) == 'cricket' ||
              getNameGame(game.league_id, game.sport_id) == 'esport' ||
              getNameGame(game.league_id, game.sport_id) == 'waterpolo'
          "
          :is="getNameGame(game.league_id, game.sport_id)"
          :gameDetails="getDataGame(game)"
          @label="label"
        ></component>
        <!--<h4 v-else class="text-center mt-2 sub-title">
          # {{ game.Team[0].team_name }} - {{ game.Team[1].team_name }}
          {{ getNameGame(game.league_id, game.sport_id) }}#
        </h4> -->
      </div>
    </div>
    <div class="card dark-bg text-light text-center my-3" :class="{ 'light-bg': this.getThemelight }" v-else>
      <h5 class="my-4">Debe seleccionar la liga de su preferencia</h5>
    </div>
    <action-sheet v-bind:selectOdds="getApuestas" @ticket="ticket" @id="id" @resetMonto="resetMonto"></action-sheet>
    <div class="text-right mt-4 mb-4 fixed">
      <div class="badge shade" id="scroll" @click="top()">
        <i class="fas fa-chevron-up fa-lg"></i>
      </div>
    </div>

    <b-modal id="modal-ticket" hide-header ok-only>
      <div class="text-center">
        <img src="/img/logo/sellatuparley.png" width="80" />
      </div>

      <div class="text-center">
        <h6>Número de ticket: {{ respuesta.NewCode }}</h6>
      </div>

      <div style="font-size: 0.8em">
        <div class="row" v-if="respuesta.Ticket">
          <div class="col-6 text-right">Monto total apostado:</div>
          <div class="col-4 text-left">{{ respuesta.Ticket.moneda }}{{ formatoMonto(respuesta.Ticket.amount) }}</div>
        </div>

        <div class="row" v-if="respuesta.Ticket">
          <div class="col-6 text-right">Premio total estimado:</div>
          <div class="col-4 text-left">{{ respuesta.Ticket.moneda }}{{ formatoMonto(respuesta.Ticket.prize) }}</div>
        </div>

        <div class="row">
          <div class="col-6 text-right">Fecha/Hora:</div>
          <div class="col-6">{{ this.getFechaFormat(this.fecha) }}</div>
        </div>

        <div class="row" v-if="respuesta.Ticket">
          <div class="col-6 text-right">Tipo de ticket:</div>
          <div class="col-6 text-left">{{ getTipeTicket(respuesta.Ticket.modalidad) }}</div>
        </div>

        <div class="row bg-dark mt-2 border text-center">
          <div class="col-4">
            <b>Fecha</b>
          </div>
          <div class="col-5">
            <b>Juego</b>
          </div>
          <div class="col-3">
            <b>Logro</b>
          </div>
        </div>

        <div
          class="row text-center mb-2"
          style="font-size: 0.8em; font-weight: 600"
          v-for="data in apuestas"
          v-bind:key="data.id"
        >
          <div class="col-4" v-html="getFechaFormat(data.time)"></div>
          <div class="col-5 text-justify" v-html="data.equipos"></div>
          <div class="col-3">{{ data.type }} ({{ data.final }}) {{ formatSymbol(data.odd) }}</div>
          <hr />
        </div>
        <div class="row" v-if="respuesta.Ticket">
          <div class="col-1"></div>
          <div class="col-10 bg-success">
            <b>Premio Total Estimado: {{ respuesta.Ticket.moneda }} {{ formatoMonto(respuesta.Ticket.prize) }}</b>
          </div>
        </div>

        <div class="mt-2 ml-2" style="font-size: 0.8em" v-if="respuesta.Center">
          <p style="text-indent: 2em" class="text-justify">
            {{ respuesta.Center.note }}
          </p>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-1"
      title="Video Streaming"
      name="video-streaming"
      size="md"
      ok-title="Minimizar"
      ok-variant="warning"
      cancel-title="Cerrar"
      cancel-variant="default"
      no-close-on-backdrop
      draggable=".contenedor-modal-streaming"
      scrollable
      v-on:show="mostrandoModal"
      v-on:close="closeVideo()"
      v-on:cancel="closeVideo()"
    >
      <div class="contenedor-modal-streaming">
        <b-tabs nav-wrapper-class="mt-3" v-model="tabIndex">
          <b-tab title="En vivo" title-link-class="color-tabs">
            <ul v-if="getVideo && getVideo != null">
              <li v-for="item of getVideo.online" v-bind:key="item.id" class="li-custom" @click="watchVideo(item)">
                {{ item.sport }} <br />
                {{ item.name }} <br />
                {{ item.date }}
                <hr />
              </li>
            </ul>
          </b-tab>
          <b-tab title="Proximos" title-link-class="color-tabs">
            <ul v-if="getVideo && getVideo != null">
              <li v-for="item of getVideo.next" v-bind:key="item.id" class="li-custom">
                {{ item.sport }} <br />
                {{ item.name }} <br />
                {{ item.date }}
                <hr />
              </li>
            </ul>
          </b-tab>
          <b-tab title="Video" title-link-class="color-tabs">
            <h3 class="text-center">{{ nameGame }}</h3>
            <iframe :src="urlgame" width="100%" height="360"></iframe>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div class="contenedor-boton-footer">
          <b-button size="sm" class="boton-color-modal cerrar-btn" @click="cancel()"> Cerrar </b-button>

          <b-button size="sm" class="boton-color-modal min-btn" @click="ok()"> Minimizar </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
function modalDraggableAction() {
  setTimeout(() => {
    $("#modal-1").draggable();
  }, 200);
}
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import moment from "moment";
import { essentialsData } from "../../../mixins/essentialsDataMixin.js";
import { chatMixin } from "../../../mixins/chatMixin.js";
import * as u from "j899";
import LiveBetting from "../../../pages/LiveBetting.vue";

import bandy from "./detalle/BandyMovil.vue";
import baseball from "./detalle/BaseballMovil.vue";
import basketball from "./detalle/BasketballMovil.vue";
import futbol from "./detalle/FutbolMovil.vue";
import futbolsala from "./detalle/FutbolsalaMovil.vue";
import boxeo from "./detalle/BoxeoMovil.vue";
import tennis from "./detalle/TennisMovil.vue";
import dardos from "./detalle/DardosMovil.vue";
import balonmano from "./detalle/BalonmanoMovil.vue";
import pingpong from "./detalle/PingpongMovil.vue";
import badminton from "./detalle/BadmintonMovil.vue";
import voleiball from "./detalle/VoleiballMovil.vue";
import pesapallo from "./detalle/pesapalloMovil.vue";
import hockey from "./detalle/HockeyMovil.vue";
import footballAmericano from "./detalle/FutbolAmericanoMovil.vue";
import bolos from "./detalle/BolosMovil.vue";
import rugby from "./detalle/RugbyMovil.vue";
import cricket from "./detalle/CricketMovil.vue";
import esport from "./detalle/EsportMovil.vue";
import waterpolo from "./detalle/WaterpoloMovil.vue";

import ActionSheet from "./ActionSheet.vue";

export default {
  mixins: [essentialsData, chatMixin],
  data() {
    return {
      name: "DeportesMovil",
      language: "es", // the language I need
      languages: lang,
      fecha: moment().format(),
      calendario: false,
      cuota: "1",
      cuotas: [
        { type: "1", label: "Americano" },
        { type: "2", label: "Decimal" }
      ],
      modalidad: true,
      modalidades: [
        { type: true, label: "Parley" },
        { type: false, label: "Directas" }
      ],
      monto: "",
      liga: "",
      ligas: [],
      deportes: [],
      otros: [],
      deporteActivo: 0,
      juegos: [],
      respuesta: [],
      apuestas: [],
      verDetalle: false,
      esDestacado: true,
      verDestacados: true,
      activaSelLiga: false,
      verOtrosDeportes: false,
      ver: false,
      btnFechaActivo: "btnHoy",
      selectOdds: [],
      gamesEmpty: false,
      jugadas: [],
      cart: [],
      restriccionesParley: [],
      tipoParley: true,
      hayRestriccion: false,
      num: 0,
      tabIndex: 0,
      urlgame: null,
      nameGame: null,
      videoR: false,
      btnVideo: "Streaming",
      otro: ""
    };
  },
  components: {
    Datepicker,
    vSelect,
    ActionSheet,
    bandy,
    basketball,
    baseball,
    footballAmericano,
    boxeo,
    futbol,
    futbolsala,
    tennis,
    dardos,
    balonmano,
    pingpong,
    badminton,
    voleiball,
    pesapallo,
    bolos,
    hockey,
    rugby,
    esport,
    cricket,
    waterpolo,
    LiveBetting
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getTheme",
      "getLeagues",
      "getGames",
      "getUser",
      "getBetInit",
      "getApuestas",
      "getFormatoTaquilla",
      "getVideo",
      "getThemelight",
      "getPremio",
      "getVisualWidth",
      "getSimboloMoneda"
    ])
    // cartCount: function() {
    //   return this.jugadas.length;
    // }
  },
  watch: {
    monto(m) {
      this.$store.commit("setMonto", m);
      this.$store.dispatch("calculatePrize");
    }
  },
  methods: {
    taquillaWeb() {
      this.$emit("changePage", "juegos");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatoMonto(m) {
      let montoFormato = parseFloat(parseFloat(m).toFixed(2));
      return u.inNumeration("LA", montoFormato);
    },
    getFechaFormat(f) {
      return moment(f).format("DD/MM/YYYY hh:mm A");
    },
    cambiarCuota(cuota) {
      var formato = cuota == 1 ? "odd" : "odd_a";
      this.$store.commit("setFormatoTaquilla", formato);
    },
    changeTipoParley() {
      this.$store.commit("setPremio", 0);
      this.$store.commit("setTotalEnPremios", 0);
      this.$store.commit("setTipoParley", this.modalidad);
      this.$store.dispatch("calculatePrize");
    },
    mostrandoModal() {
      this.tabIndex = 0;
      if (this.videoR) {
        this.tabIndex = 2;
      }
      modalDraggableAction();
    },
    closeModal() {
      this.$modal.hide("contenedor-modal-streaming");
    },
    resetMonto: function() {
      this.monto = null;
      this.$refs.amount2.focus();

      $("html,body, .container, .container-game").animate(
        {
          scrollTop: $("#contenedor-taquilla").offset().top - 150
        },
        550
      );
    },
    watchVideo(e) {
      this.tabIndex = 2;
      this.urlgame = e.link;
      this.nameGame = e.name;
      this.videoR = true;
      this.btnVideo = "Reproduciendo";
    },
    closeVideo() {
      this.videoR = false;
      this.btnVideo = "Streaming";
    },
    toPage: function(page) {
      localStorage.removeItem("dataResults");
      localStorage.removeItem("idTicket");
      this.$emit("changePage", page);
    },
    id: function(id) {
      this.cartRemove(id);
    },
    label: function(label) {
      //se inicializa la decha actual para evitar conflictos en los titulos de los deportes
      this.fechaAnt = null;

      console.log("Label: ", label);

      $(label).on("click", function() {
        $(label + "[open]")
          .not(this)
          .removeAttr("open");
        //posiciona la pantalla en el juego seleccionado
        if ($(this).attr("open") == undefined) {
          $("html,body, .container, .container-game").animate(
            {
              scrollTop: $(this).offset().top - 150
            },
            50
          );
        }
      });
    },
    getTipeTicket(m) {
      return m === "false" || m === false ? "Directas" : "Parley";
    },
    async ticket(...args) {
      args[0]["Ticket"]["modalidad"] = this.modalidad;
      args[0]["Ticket"]["moneda"] = this.getUser.Coin.moneda;
      const dataTicketFormat = { Ticket: args[0]["Ticket"] };

      this.$store.commit("showLoader");
      const respuesta = await this.$store.dispatch("registerTicketAsync", dataTicketFormat);

      if (respuesta.Type_error && respuesta.Type_error != 0) {
        this.Swal.fire({
          position: "top",
          title: "Error",
          text: respuesta.Error,
          icon: "error"
        });
      } else {
        // caso satisfactorio
        if (respuesta.NewCode) {
          this.$store.commit("setBetInit", respuesta.NewCode);
          this.$store.commit("setTicketTarget", respuesta.Ticket);
        }

        if (respuesta.Center && respuesta.Center.note) {
          //this.Swal.fire("Exito", respuesta.Center.note, "success");
          this.respuesta = respuesta;

          if (this.respuesta.Ticket) {
            this.apuestas = this.respuesta.Ticket.Odds;
            console.log("Apuestas: ", this.apuestas);
          }

          this.$bvModal.show("modal-ticket");

          const Toast = this.Swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
              toast.addEventListener("mouseenter", this.Swal.stopTimer);
              toast.addEventListener("mouseleave", this.Swal.resumeTimer);
            }
          });

          Toast.fire({
            icon: "success",
            title: "Ticket generado Satisfactoriamente"
          });
        }
        this.monto = null;
        //this.$store.commit("setMonto", 0);
        this.$store.commit("setPremio", 0);
        this.$store.commit("setApuestas", []);
        this.$store.commit("setRestriccionParley", []);
        this.$store.commit("setExcepcionParley", false);
      }
      this.$store.commit("hideLoader");
    },
    /*async cartAdd(id) {
      var get_jugada = await this.$store.dispatch("consultarApuesta", id);
      var tipo_jugada = this.getBetInit.odd_type[get_jugada.odd.Odd.odd_type_id];

      get_jugada["texto"] = tipo_jugada;

      if (get_jugada != null) {
        var jugada = get_jugada.odd;
        if (jugada.Error !== "") {
          Swal.fire("Error", jugada.Error, "error");
          this.cartRemove(id);
        } else {
          jugada.Game["resaltar"] = false;
          jugada.Game["resaltar_change"] = false;
          // validar jugada
          var validar = await this.$store.dispatch("valid_final", jugada);
          if (validar) {
            this.jugadas = this.jugadas.filter(jugada => jugada.Game.ref !== jugada.Game.ref);
            this.$set(this.cartId, id, id);
            this.cart.push(id);
            this.jugadas.push(jugada);

            //Inclusion de jugada en objeto que lista las jugadas seleccionadas en el componente actionSheet
            this.selectOdds.push(get_jugada);
          }
          this.num = this.cartCount;
          // this.resetResaltar();
        }
      }
    },
    cartRemove(id) {
      this.$delete(this.cartId, id);
      var index = this.cart.indexOf(id);

      if (index > -1) {
        this.cart.splice(index, 1);
      }

      var i = this.selectOdds.findIndex(element => element.odd.Game.ref == id);
      this.selectOdds.splice(i, 1);

      this.jugadas = this.jugadas.filter(jugada => jugada.Game.ref !== id);
      this.restriccionesParley = this.restriccionesParley.filter(restriccion => restriccion.indexOf(id) < 0);
      this.num = this.cartCount;
      // this.resetResaltar(); OJO PENDIENTE POR PROGRAMAR
    }, */
    getNameGame(id_league, sport_id) {
      return this.getLeagues
        ? this.getLeagues.flatMap(l => {
            let txt = l.sport.toString().toLowerCase();

            if (l.id == id_league && txt.includes("bandy")) {
              return "bandy";
            }

            if (l.id == id_league && txt.includes("voleibol")) {
              return "voleiball";
            }

            if (sport_id && sport_id == 2) {
              return "futbol";
            }

            if (sport_id && sport_id == 3) {
              return "basketball";
            }

            if (sport_id && sport_id == 5) {
              return "hockey";
            }

            if (sport_id && sport_id == 6) {
              return "tennis";
            }

            if (sport_id && sport_id == 9) {
              return "futbolsala";
            }

            if (sport_id && sport_id == 10) {
              return "rugby";
            }

            if (sport_id && sport_id == 15) {
              return "dardos";
            }

            if (sport_id && sport_id == 16) {
              return "balonmano";
            }

            if (l.id == id_league && txt.includes("ping pong")) {
              return "pingpong";
            }

            if (l.id == id_league && txt.includes("badminton")) {
              return "badminton";
            }

            if (txt.includes("bowls") || txt.includes("bolos")) {
              return "bolos";
            }

            if (l.id == id_league && txt.includes("football americano")) {
              return "footballAmericano";
            }

            if (l.id == id_league && txt.includes("e-sport")) {
              return "esport";
            }

            if (l.id == id_league && txt.includes("waterpolo")) {
              return "waterpolo";
            }

            // if (txt == "e-sports") {
            //   return "dota";
            // }

            if (txt.includes("boxeo")) {
              return "boxeo";
            } else {
              return l.id == id_league ? txt : [];
            }
          })[0]
        : "";
    },
    getDataGame(game) {
      var muestraTitulo =
        this.idAnt == game.league_id && this.fechaAnt == moment(String(game.date)).format("DD/MM/YYYY") ? false : true;

      this.idAnt = game.league_id;
      this.fechaAnt = moment(String(game.date)).format("DD/MM/YYYY");

      if (this.getNameGame(game.league_id, game.sport_id) == "basketball") {
        const HT1 = game.odds.HT && game.odds.HT[1] ? this.formatSymbol(game.odds.HT[1][this.getFormatoTaquilla]) : "";
        const HT2 = game.odds.HT && game.odds.HT[2] ? this.formatSymbol(game.odds.HT[2][this.getFormatoTaquilla]) : "";

        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[3]
                ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
                : "",
              TOTALESFT: game.odds.FT[5]
                ? `A${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                : "",
              "1X2HT": `${HT1}`,
              HDPHT:
                game.odds.HT && game.odds.HT[3]
                  ? `${this.formatSymbol(game.odds.HT[3][this.getFormatoTaquilla])} (${game.odds.HT[3].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[5]
                  ? `A${this.formatSymbol(game.odds.HT[5][this.getFormatoTaquilla])} (${game.odds.HT[5].factor})`
                  : ""
            },
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[4]
                ? this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla]) + " (" + game.odds.FT[4].factor + ")"
                : "",
              TOTALESFT: `B${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`,
              "1X2HT": `${HT2}`,
              HDPHT:
                game.odds.HT && game.odds.HT[4]
                  ? `${this.formatSymbol(game.odds.HT[4][this.getFormatoTaquilla])} (${game.odds.HT[4].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[6]
                  ? `B${this.formatSymbol(game.odds.HT[6][this.getFormatoTaquilla])} (${game.odds.HT[6].factor})`
                  : ""
            }
          ],

          //Valida si lista algun logro de FT adicional
          hayFT: game.odds.FT && (game.odds.FT[60] || game.odds.FT[129]) ? true : false,

          //logros de detalle
          //Marcador Par/impar
          par: game.odds.FT[60] ? `(Par) ${this.formatSymbol(game.odds.FT[60][this.getFormatoTaquilla])}` : "",
          impar: game.odds.FT[61] ? `(Impar) ${this.formatSymbol(game.odds.FT[61][this.getFormatoTaquilla])}` : "",

          //1 X 2 (SIN OT)
          gana1FT: game.odds.FT[129] ? `(1) ${this.formatSymbol(game.odds.FT[129][this.getFormatoTaquilla])}` : "",
          ganaXFT: game.odds.FT[130] ? `(X) ${this.formatSymbol(game.odds.FT[130][this.getFormatoTaquilla])}` : "",
          gana2FT: game.odds.FT[130] ? `(2) ${this.formatSymbol(game.odds.FT[131][this.getFormatoTaquilla])}` : "",

          //Valida si lista algun logro de HT
          hayHT: game.odds.HT && (game.odds.HT[60] || game.odds.HT[148]) ? true : false,

          ////Marcador Par/impar HT
          parHT:
            game.odds.HT && game.odds.HT[60]
              ? `(Par) ${this.formatSymbol(game.odds.HT[60][this.getFormatoTaquilla])}`
              : "",
          imparHT:
            game.odds.HT && game.odds.HT[61]
              ? `(Impar) ${this.formatSymbol(game.odds.HT[61][this.getFormatoTaquilla])}`
              : "",

          //1 X 2 HT
          gana1HT:
            game.odds.HT && game.odds.HT[148]
              ? `(1) ${this.formatSymbol(game.odds.HT[148][this.getFormatoTaquilla])}`
              : "",
          ganaXHT:
            game.odds.HT && game.odds.HT[149]
              ? `(X) ${this.formatSymbol(game.odds.HT[149][this.getFormatoTaquilla])}`
              : "",
          gana2HT:
            game.odds.HT && game.odds.HT[150]
              ? `(2) ${this.formatSymbol(game.odds.HT[150][this.getFormatoTaquilla])}`
              : "",

          hayST: game.odds.ST ? true : false,

          ////Marcador Par/impar ST
          parST:
            game.odds.ST && game.odds.ST[60]
              ? `(Par) ${this.formatSymbol(game.odds.ST[60][this.getFormatoTaquilla])}`
              : "",
          imparST:
            game.odds.ST && game.odds.ST[61]
              ? `(Impar) ${this.formatSymbol(game.odds.ST[61][this.getFormatoTaquilla])}`
              : "",

          //1 X 2 ST
          gana1ST:
            game.odds.ST && game.odds.ST[148]
              ? `(1) ${this.formatSymbol(game.odds.ST[148][this.getFormatoTaquilla])}`
              : "",
          ganaXST:
            game.odds.ST && game.odds.ST[149]
              ? `(X) ${this.formatSymbol(game.odds.ST[149][this.getFormatoTaquilla])}`
              : "",
          gana2ST:
            game.odds.ST && game.odds.ST[150]
              ? `(2) ${this.formatSymbol(game.odds.ST[150][this.getFormatoTaquilla])}`
              : "",

          hayFQ: game.odds.FQ ? true : false,

          //1 X 2 FQ
          gana1FQ:
            game.odds.FQ && game.odds.FQ[1] ? `(1) ${this.formatSymbol(game.odds.FQ[1][this.getFormatoTaquilla])}` : "",
          ganaXFQ:
            game.odds.FQ && game.odds.FQ[7] ? `(X) ${this.formatSymbol(game.odds.FQ[7][this.getFormatoTaquilla])}` : "",
          gana2FQ:
            game.odds.FQ && game.odds.FQ[2] ? `(2) ${this.formatSymbol(game.odds.FQ[2][this.getFormatoTaquilla])}` : "",

          //Spread/HDP
          spreadFQ1:
            game.odds.FQ && game.odds.FQ[3]
              ? `(1) ${this.formatSymbol(game.odds.FQ[3][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.FQ[3].factor +
                ")"
              : "",
          spreadFQ2:
            game.odds.FQ && game.odds.FQ[4]
              ? `(2) ${this.formatSymbol(game.odds.FQ[4][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.FQ[4].factor +
                ")"
              : "",

          //Totales FQ
          totAltaFQ:
            game.odds.FQ && game.odds.FQ[5]
              ? `(A) ${this.formatSymbol(game.odds.FQ[5][this.getFormatoTaquilla])} (${game.odds.FQ[5].factor})`
              : "",
          totBajaFQ:
            game.odds.FQ && game.odds.FQ[6]
              ? `(B) ${this.formatSymbol(game.odds.FQ[6][this.getFormatoTaquilla])} (${game.odds.FQ[6].factor})`
              : "",

          //Marcador Par/Impar FQ
          parFQ:
            game.odds.FQ && game.odds.FQ[60]
              ? `(Par) ${this.formatSymbol(game.odds.FQ[60][this.getFormatoTaquilla])}`
              : "",
          imparFQ:
            game.odds.FQ && game.odds.FQ[61]
              ? `(Impar) ${this.formatSymbol(game.odds.FQ[61][this.getFormatoTaquilla])}`
              : "",

          haySQ: game.odds.SQ ? true : false,

          //1 X 2 SQ
          gana1SQ:
            game.odds.SQ && game.odds.SQ[1] ? `(1) ${this.formatSymbol(game.odds.SQ[1][this.getFormatoTaquilla])}` : "",
          ganaXSQ:
            game.odds.SQ && game.odds.SQ[7] ? `(X) ${this.formatSymbol(game.odds.SQ[7][this.getFormatoTaquilla])}` : "",
          gana2SQ:
            game.odds.SQ && game.odds.SQ[2] ? `(2) ${this.formatSymbol(game.odds.SQ[2][this.getFormatoTaquilla])}` : "",

          //Spread/HDP SQ
          spreadSQ1:
            game.odds.SQ && game.odds.SQ[3]
              ? `(1) ${this.formatSymbol(game.odds.SQ[3][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.SQ[3].factor +
                ")"
              : "",
          spreadSQ2:
            game.odds.SQ && game.odds.SQ[4]
              ? `(2) ${this.formatSymbol(game.odds.SQ[4][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.SQ[4].factor +
                ")"
              : "",

          //Totales SQ
          totAltaSQ:
            game.odds.SQ && game.odds.SQ[5]
              ? `(A) ${this.formatSymbol(game.odds.SQ[5][this.getFormatoTaquilla])} (${game.odds.SQ[5].factor})`
              : "",
          totBajaSQ:
            game.odds.SQ && game.odds.SQ[6]
              ? `(B) ${this.formatSymbol(game.odds.SQ[6][this.getFormatoTaquilla])} (${game.odds.SQ[6].factor})`
              : "",

          //Marcador Par/Impar SQ
          parSQ:
            game.odds.SQ && game.odds.SQ[60]
              ? `(Par) ${this.formatSymbol(game.odds.SQ[60][this.getFormatoTaquilla])}`
              : "",
          imparSQ:
            game.odds.SQ && game.odds.SQ[61]
              ? `(Impar) ${this.formatSymbol(game.odds.SQ[61][this.getFormatoTaquilla])}`
              : "",

          hayTQ: game.odds.TQ ? true : false,

          //1 X 2 TQ
          gana1TQ:
            game.odds.TQ && game.odds.TQ[1] ? `(1) ${this.formatSymbol(game.odds.TQ[1][this.getFormatoTaquilla])}` : "",
          ganaXTQ:
            game.odds.TQ && game.odds.TQ[7] ? `(X) ${this.formatSymbol(game.odds.TQ[7][this.getFormatoTaquilla])}` : "",
          gana2TQ:
            game.odds.TQ && game.odds.TQ[2] ? `(2) ${this.formatSymbol(game.odds.TQ[2][this.getFormatoTaquilla])}` : "",

          //Spread/HDP TQ
          spreadTQ1:
            game.odds.TQ && game.odds.TQ[3]
              ? `(1) ${this.formatSymbol(game.odds.TQ[3][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.TQ[3].factor +
                ")"
              : "",
          spreadTQ2:
            game.odds.TQ && game.odds.TQ[4]
              ? `(2) ${this.formatSymbol(game.odds.TQ[4][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.TQ[4].factor +
                ")"
              : "",

          //Marcador Par/Impar TQ
          parTQ:
            game.odds.TQ && game.odds.TQ[60]
              ? `(Par) ${this.formatSymbol(game.odds.TQ[60][this.getFormatoTaquilla])}`
              : "",
          imparTQ:
            game.odds.TQ && game.odds.TQ[61]
              ? `(Impar) ${this.formatSymbol(game.odds.TQ[61][this.getFormatoTaquilla])}`
              : "",

          //Totales TQ
          totAltaTQ:
            game.odds.TQ && game.odds.TQ[5]
              ? `(A) ${this.formatSymbol(game.odds.TQ[5][this.getFormatoTaquilla])} (${game.odds.TQ[5].factor})`
              : "",
          totBajaTQ:
            game.odds.TQ && game.odds.TQ[6]
              ? `(B) ${this.formatSymbol(game.odds.TQ[6][this.getFormatoTaquilla])} (${game.odds.TQ[6].factor})`
              : "",

          hayQQ: game.odds.QQ ? true : false,

          //1 X 2 QQ
          gana1QQ:
            game.odds.QQ && game.odds.QQ[1] ? `(1) ${this.formatSymbol(game.odds.QQ[1][this.getFormatoTaquilla])}` : "",
          ganaXQQ:
            game.odds.QQ && game.odds.QQ[7] ? `(X) ${this.formatSymbol(game.odds.QQ[7][this.getFormatoTaquilla])}` : "",
          gana2QQ:
            game.odds.QQ && game.odds.QQ[2] ? `(2) ${this.formatSymbol(game.odds.QQ[2][this.getFormatoTaquilla])}` : "",

          //Spread/HDP QQ
          spreadQQ1:
            game.odds.QQ && game.odds.QQ[3]
              ? `(1) ${this.formatSymbol(game.odds.QQ[3][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.QQ[3].factor +
                ")"
              : "",
          spreadQQ2:
            game.odds.QQ && game.odds.QQ[4]
              ? `(2) ${this.formatSymbol(game.odds.QQ[4][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.QQ[4].factor +
                ")"
              : "",

          //Totales QQ
          totAltaQQ:
            game.odds.QQ && game.odds.QQ[5]
              ? `(A) ${this.formatSymbol(game.odds.QQ[5][this.getFormatoTaquilla])} (${game.odds.QQ[5].factor})`
              : "",
          totBajaQQ:
            game.odds.QQ && game.odds.QQ[6]
              ? `(B) ${this.formatSymbol(game.odds.QQ[6][this.getFormatoTaquilla])} (${game.odds.QQ[6].factor})`
              : "",

          //Marcador Par/Impar QQ
          parQQ:
            game.odds.QQ && game.odds.QQ[60]
              ? `(Par) ${this.formatSymbol(game.odds.QQ[60][this.getFormatoTaquilla])}`
              : "",
          imparQQ:
            game.odds.QQ && game.odds.QQ[61]
              ? `(Impar) ${this.formatSymbol(game.odds.QQ[61][this.getFormatoTaquilla])}`
              : ""
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "baseball") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[3]
                ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
                : "",
              SHDPFT: game.odds.FT[8]
                ? `${this.formatSymbol(game.odds.FT[8][this.getFormatoTaquilla])} (${game.odds.FT[8].factor})`
                : "",
              TOTALESFT: game.odds.FT[5]
                ? `A${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                : "",
              "1X2HT":
                game.odds.HT && game.odds.HT[1] ? `${this.formatSymbol(game.odds.HT[1][this.getFormatoTaquilla])}` : "",
              HDPHT:
                game.odds.HT && game.odds.HT[3]
                  ? `${this.formatSymbol(game.odds.HT[3][this.getFormatoTaquilla])} (${game.odds.HT[3].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[5]
                  ? `A${this.formatSymbol(game.odds.HT[5][this.getFormatoTaquilla])} (${game.odds.HT[5].factor})`
                  : ""
            },
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[4]
                ? `${this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla])} (${game.odds.FT[4].factor})`
                : "",
              SHDPFT: game.odds.FT[9]
                ? `${this.formatSymbol(game.odds.FT[9][this.getFormatoTaquilla])} (${game.odds.FT[9].factor})`
                : "",
              TOTALESFT: game.odds.FT[6]
                ? `B${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`
                : "",
              "1X2HT":
                game.odds.HT && game.odds.HT[2] ? `${this.formatSymbol(game.odds.HT[2][this.getFormatoTaquilla])}` : "",
              HDPHT:
                game.odds.HT && game.odds.HT[4]
                  ? `${this.formatSymbol(game.odds.HT[4][this.getFormatoTaquilla])} (${game.odds.HT[4].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[6]
                  ? `B${this.formatSymbol(game.odds.HT[6][this.getFormatoTaquilla])} (${game.odds.HT[6].factor})`
                  : ""
            }
          ],
          //Detalle

          //Valida si hay logros de FT adicionales
          hayFT:
            game.odds.FT[10] ||
            game.odds.FT[12] ||
            game.odds.FT[14] ||
            game.odds.FT[18] ||
            game.odds.FT[119] ||
            game.odds.FT[129] ||
            game.odds.FT[132]
              ? true
              : false,

          //Totales Vistante
          totVisAlta: game.odds.FT[10]
            ? `Alta ${this.formatSymbol(game.odds.FT[10][this.getFormatoTaquilla])} (${game.odds.FT[10].factor})`
            : "",
          totVisBaja: game.odds.FT[11]
            ? `Baja ${this.formatSymbol(game.odds.FT[11][this.getFormatoTaquilla])} (${game.odds.FT[11].factor})`
            : "",

          //Totales Local
          totLocAlta: game.odds.FT[12]
            ? `Alta ${this.formatSymbol(game.odds.FT[12][this.getFormatoTaquilla])} (${game.odds.FT[12].factor})`
            : "",
          totLocBaja: game.odds.FT[13]
            ? `Baja ${this.formatSymbol(game.odds.FT[13][this.getFormatoTaquilla])} (${game.odds.FT[13].factor})`
            : "",

          //Carrera 1er Inning
          car1InnSi: game.odds.FT[14] ? `Si ${this.formatSymbol(game.odds.FT[14][this.getFormatoTaquilla])}` : "",
          car1InnNo: game.odds.FT[15] ? `No ${this.formatSymbol(game.odds.FT[15][this.getFormatoTaquilla])}` : "",

          //Totales CHE
          totCheAlta: game.odds.FT[18]
            ? `Alta ${this.formatSymbol(game.odds.FT[18][this.getFormatoTaquilla])} (${game.odds.FT[18].factor})`
            : "",
          totCheBaja: game.odds.FT[19]
            ? `Baja ${this.formatSymbol(game.odds.FT[19][this.getFormatoTaquilla])} (${game.odds.FT[19].factor})`
            : "",

          //Extrainning
          extraSi: game.odds.FT[119] ? `Si ${this.formatSymbol(game.odds.FT[119][this.getFormatoTaquilla])}` : "",
          extraNo: game.odds.FT[120] ? `No ${this.formatSymbol(game.odds.FT[120][this.getFormatoTaquilla])}` : "",

          //1 X 2 (9 Innigns)
          gana1FT: game.odds.FT[129] ? `(1) ${this.formatSymbol(game.odds.FT[129][this.getFormatoTaquilla])}` : "",
          ganaXFT: game.odds.FT[130] ? `(X) ${this.formatSymbol(game.odds.FT[130][this.getFormatoTaquilla])}` : "",
          gana2FT: game.odds.FT[131] ? `(2) ${this.formatSymbol(game.odds.FT[131][this.getFormatoTaquilla])}` : "",

          //Marcador Par/Impar
          parFT: game.odds.FT[132] ? `(Par) ${this.formatSymbol(game.odds.FT[132][this.getFormatoTaquilla])}` : "",
          imparFT: game.odds.FT[133] ? `(Impar) ${this.formatSymbol(game.odds.FT[133][this.getFormatoTaquilla])}` : "",

          //Valida si lista algun logro de HT
          hayHT: game.odds.HT && (game.odds.HT[116] || game.odds.HT[127]) ? true : false,

          //1 X 2 1er Inning
          gana1HT:
            game.odds.HT && game.odds.HT[116]
              ? `(1) ${this.formatSymbol(game.odds.HT[116][this.getFormatoTaquilla])}`
              : "",
          ganaXHT:
            game.odds.HT && game.odds.HT[117]
              ? `(X) ${this.formatSymbol(game.odds.HT[117][this.getFormatoTaquilla])}`
              : "",
          gana2HT:
            game.odds.HT && game.odds.HT[118]
              ? `(2) ${this.formatSymbol(game.odds.HT[118][this.getFormatoTaquilla])}`
              : "",

          //Totales 1er Inning
          tot1inningAlta:
            game.odds.HT && game.odds.HT[127]
              ? `Alta ${this.formatSymbol(game.odds.HT[127][this.getFormatoTaquilla])} (${game.odds.HT[127].factor})`
              : "",
          tot1InningBaja:
            game.odds.HT && game.odds.HT[128]
              ? `Baja ${this.formatSymbol(game.odds.HT[128][this.getFormatoTaquilla])} (${game.odds.HT[128].factor})`
              : "",

          //Anota primero
          anotaVis: game.odds.FT[16]
            ? `(1)Visitante ${this.formatSymbol(game.odds.FT[16][this.getFormatoTaquilla])}`
            : "",
          anotaLoc: game.odds.FT[17] ? `(2)Local ${this.formatSymbol(game.odds.FT[17][this.getFormatoTaquilla])}` : ""
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "futbol") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[3]
                ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
                : "",
              TOTALESFT: game.odds.FT[5]
                ? `A${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                : "",
              EMPATE: game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : "",
              EMPATERUN: game.odds.FT[115] ? this.formatSymbol(game.odds.FT[115][this.getFormatoTaquilla]) : "",
              HDPHT:
                game.odds.HT && game.odds.HT[3]
                  ? `${this.formatSymbol(game.odds.HT[3][this.getFormatoTaquilla])} (${game.odds.HT[3].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[5]
                  ? `A${this.formatSymbol(game.odds.HT[5][this.getFormatoTaquilla])} (${game.odds.HT[5].factor})`
                  : "",
              "1X": game.odds.FT[62] ? this.formatSymbol(game.odds.FT[62][this.getFormatoTaquilla]) : "",
              12: game.odds.FT[63] ? this.formatSymbol(game.odds.FT[63][this.getFormatoTaquilla]) : "",
              X2: game.odds.FT[64] ? this.formatSymbol(game.odds.FT[64][this.getFormatoTaquilla]) : "",
              SI: game.odds.FT[49] ? this.formatSymbol(game.odds.FT[49][this.getFormatoTaquilla]) : "",
              NO: game.odds.FT[50] ? this.formatSymbol(game.odds.FT[50][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[4]
                ? `${this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla])} (${game.odds.FT[4].factor})`
                : "",
              TOTALESFT:
                game.odds.FT && game.odds.FT[6]
                  ? `B${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`
                  : "",
              EMPATE: game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : "",
              EMPATERUN: game.odds.FT[115] ? this.formatSymbol(game.odds.FT[115][this.getFormatoTaquilla]) : "",
              HDPHT:
                game.odds.HT && game.odds.HT[4]
                  ? `${this.formatSymbol(game.odds.HT[4][this.getFormatoTaquilla])} (${game.odds.HT[4].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[6]
                  ? `B${this.formatSymbol(game.odds.HT[6][this.getFormatoTaquilla])} (${game.odds.HT[6].factor})`
                  : "",
              "1X": game.odds.FT[62] ? this.formatSymbol(game.odds.FT[62][this.getFormatoTaquilla]) : "",
              12: game.odds.FT[63] ? this.formatSymbol(game.odds.FT[63][this.getFormatoTaquilla]) : "",
              X2: game.odds.FT[64] ? this.formatSymbol(game.odds.FT[64][this.getFormatoTaquilla]) : "",
              SI: game.odds.FT[49] ? this.formatSymbol(game.odds.FT[49][this.getFormatoTaquilla]) : "",
              NO: game.odds.FT[50] ? this.formatSymbol(game.odds.FT[50][this.getFormatoTaquilla]) : ""
            }
          ],

          //Valida si hay logros de FT adicionales
          hayFT:
            game.odds.FT[12] ||
            game.odds.FT[10] ||
            game.odds.FT[17] ||
            game.odds.FT[60] ||
            game.odds.FT[32] ||
            game.odds.FT[51] ||
            game.odds.FT[65] ||
            game.odds.FT[69] ||
            game.odds.FT[77] ||
            game.odds.FT[87] ||
            game.odds.FT[152]
              ? true
              : false,

          //Totales Local
          totLocAlta: game.odds.FT[12]
            ? `Alta ${this.formatSymbol(game.odds.FT[12][this.getFormatoTaquilla])} (${game.odds.FT[12].factor})`
            : "",
          totLocBaja: game.odds.FT[13]
            ? `Baja ${this.formatSymbol(game.odds.FT[13][this.getFormatoTaquilla])} (${game.odds.FT[13].factor})`
            : "",

          //Totales Vistante
          totVisAlta: game.odds.FT[10]
            ? `Alta ${this.formatSymbol(game.odds.FT[10][this.getFormatoTaquilla])} (${game.odds.FT[10].factor})`
            : "",
          totVisBaja: game.odds.FT[11]
            ? `Baja ${this.formatSymbol(game.odds.FT[11][this.getFormatoTaquilla])} (${game.odds.FT[11].factor})`
            : "",

          //Anota Primero
          anotaLoc: game.odds.FT[17] ? `Loc (1) ${this.formatSymbol(game.odds.FT[17][this.getFormatoTaquilla])}` : "",
          anotaNin: game.odds.FT[114] ? `ning. ${this.formatSymbol(game.odds.FT[114][this.getFormatoTaquilla])}` : "",
          anotaVis: game.odds.FT[16] ? `Vis. (2) ${this.formatSymbol(game.odds.FT[16][this.getFormatoTaquilla])}` : "",

          //Marcador Par/Impar
          parFT: game.odds.FT[60] ? `(Par) ${this.formatSymbol(game.odds.FT[60][this.getFormatoTaquilla])}` : "",
          imparFT: game.odds.FT[61] ? `(Impar) ${this.formatSymbol(game.odds.FT[61][this.getFormatoTaquilla])}` : "",

          //Marcador Correcto
          MC0x0: game.odds.FT[32] ? ` (0:0)${this.formatSymbol(game.odds.FT[32][this.getFormatoTaquilla])}` : "",
          MC1x4: game.odds.FT[43] ? ` (1:4)${this.formatSymbol(game.odds.FT[43][this.getFormatoTaquilla])}` : "",
          MC3x3: game.odds.FT[35] ? ` (3:3)${this.formatSymbol(game.odds.FT[35][this.getFormatoTaquilla])}` : "",

          MC0x1: game.odds.FT[36] ? ` (0:1)${this.formatSymbol(game.odds.FT[36][this.getFormatoTaquilla])}` : "",
          MC2x0: game.odds.FT[21] ? ` (2:0)${this.formatSymbol(game.odds.FT[21][this.getFormatoTaquilla])}` : "",
          MC3x4: game.odds.FT[29] ? ` (3:4)${this.formatSymbol(game.odds.FT[29][this.getFormatoTaquilla])}` : "",

          MC0x2: game.odds.FT[37] ? ` (0:2)${this.formatSymbol(game.odds.FT[37][this.getFormatoTaquilla])}` : "",
          MC2x1: game.odds.FT[22] ? ` (2:1)${this.formatSymbol(game.odds.FT[22][this.getFormatoTaquilla])}` : "",
          MC4x0: game.odds.FT[26] ? ` (4:0)${this.formatSymbol(game.odds.FT[26][this.getFormatoTaquilla])}` : "",

          MC0x3: game.odds.FT[39] ? ` (0:3)${this.formatSymbol(game.odds.FT[39][this.getFormatoTaquilla])}` : "",
          MC2x2: game.odds.FT[34] ? ` (2:2)${this.formatSymbol(game.odds.FT[34][this.getFormatoTaquilla])}` : "",
          MC4x1: game.odds.FT[27] ? ` (4:1)${this.formatSymbol(game.odds.FT[27][this.getFormatoTaquilla])}` : "",

          MC0x4: game.odds.FT[42] ? ` (0:4)${this.formatSymbol(game.odds.FT[42][this.getFormatoTaquilla])}` : "",
          MC2x3: game.odds.FT[41] ? ` (2:3)${this.formatSymbol(game.odds.FT[41][this.getFormatoTaquilla])}` : "",
          MC4x2: game.odds.FT[28] ? ` (4:2)${this.formatSymbol(game.odds.FT[28][this.getFormatoTaquilla])}` : "",

          MC1x0: game.odds.FT[20] ? ` (1:0)${this.formatSymbol(game.odds.FT[20][this.getFormatoTaquilla])}` : "",
          MC2x4: game.odds.FT[44] ? ` (2:4)${this.formatSymbol(game.odds.FT[44][this.getFormatoTaquilla])}` : "",
          MC4x3: game.odds.FT[30] ? ` (4:3)${this.formatSymbol(game.odds.FT[30][this.getFormatoTaquilla])}` : "",

          MC1x1: game.odds.FT[33] ? ` (1:1)${this.formatSymbol(game.odds.FT[33][this.getFormatoTaquilla])}` : "",
          MC3x0: game.odds.FT[23] ? ` (3:0)${this.formatSymbol(game.odds.FT[23][this.getFormatoTaquilla])}` : "",
          MC4x4: game.odds.FT[31] ? ` (4:4)${this.formatSymbol(game.odds.FT[31][this.getFormatoTaquilla])}` : "",

          MC1x2: game.odds.FT[38] ? ` (1:2)${this.formatSymbol(game.odds.FT[38][this.getFormatoTaquilla])}` : "",
          MC3x1: game.odds.FT[24] ? ` (3:1)${this.formatSymbol(game.odds.FT[24][this.getFormatoTaquilla])}` : "",
          MCOtro: game.odds.FT[48] ? ` (Otro)${this.formatSymbol(game.odds.FT[48][this.getFormatoTaquilla])}` : "",

          MC1x3: game.odds.FT[40] ? ` (1:3)${this.formatSymbol(game.odds.FT[40][this.getFormatoTaquilla])}` : "",
          MC3x2: game.odds.FT[25] ? ` (3:2)${this.formatSymbol(game.odds.FT[25][this.getFormatoTaquilla])}` : "",

          //Gana en HT/FT
          ganaFTHT11: game.odds.FT[51] ? `(1/1)${this.formatSymbol(game.odds.FT[51][this.getFormatoTaquilla])}` : "",
          ganaFTHTX1: game.odds.FT[52] ? ` (X/1)${this.formatSymbol(game.odds.FT[52][this.getFormatoTaquilla])}` : "",
          ganaFTHT21: game.odds.FT[53] ? ` (2/1)${this.formatSymbol(game.odds.FT[53][this.getFormatoTaquilla])}` : "",

          ganaFTHT1X: game.odds.FT[54] ? `(1/X)${this.formatSymbol(game.odds.FT[54][this.getFormatoTaquilla])}` : "",
          ganaFTHTXX: game.odds.FT[55] ? ` (X/X)${this.formatSymbol(game.odds.FT[55][this.getFormatoTaquilla])}` : "",
          ganaFTHT2X: game.odds.FT[56] ? ` (2/X)${this.formatSymbol(game.odds.FT[56][this.getFormatoTaquilla])}` : "",

          ganaFTHT12: game.odds.FT[57] ? `(1/2)${this.formatSymbol(game.odds.FT[57][this.getFormatoTaquilla])}` : "",
          ganaFTHTX2: game.odds.FT[58] ? ` (X/2)${this.formatSymbol(game.odds.FT[58][this.getFormatoTaquilla])}` : "",
          ganaFTHT22: game.odds.FT[59] ? ` (2/2)${this.formatSymbol(game.odds.FT[59][this.getFormatoTaquilla])}` : "",

          //Goles Local FT
          golesLocal0: game.odds.FT[65] ? `(0)${this.formatSymbol(game.odds.FT[65][this.getFormatoTaquilla])}` : "",
          golesLocal1: game.odds.FT[66] ? `(1)${this.formatSymbol(game.odds.FT[66][this.getFormatoTaquilla])}` : "",
          golesLocal2: game.odds.FT[67] ? `(2)${this.formatSymbol(game.odds.FT[67][this.getFormatoTaquilla])}` : "",
          golesLocal3: game.odds.FT[68] ? `(3+)${this.formatSymbol(game.odds.FT[68][this.getFormatoTaquilla])}` : "",

          //Goles Visitante FT
          golesVis0: game.odds.FT[69] ? `(0)${this.formatSymbol(game.odds.FT[69][this.getFormatoTaquilla])}` : "",
          golesVis1: game.odds.FT[70] ? `(1)${this.formatSymbol(game.odds.FT[70][this.getFormatoTaquilla])}` : "",
          golesVis2: game.odds.FT[71] ? `(2)${this.formatSymbol(game.odds.FT[71][this.getFormatoTaquilla])}` : "",
          golesVis3: game.odds.FT[72] ? `(3+)${this.formatSymbol(game.odds.FT[72][this.getFormatoTaquilla])}` : "",

          //Totales exactos de goles
          golesExa0: game.odds.FT[77] ? `(0)${this.formatSymbol(game.odds.FT[77][this.getFormatoTaquilla])}` : "",
          golesExa1: game.odds.FT[78] ? `(1)${this.formatSymbol(game.odds.FT[78][this.getFormatoTaquilla])}` : "",
          golesExa2: game.odds.FT[79] ? `(2)${this.formatSymbol(game.odds.FT[79][this.getFormatoTaquilla])}` : "",
          golesExa3: game.odds.FT[80] ? `(3)${this.formatSymbol(game.odds.FT[80][this.getFormatoTaquilla])}` : "",
          golesExa4: game.odds.FT[81] ? `(4)${this.formatSymbol(game.odds.FT[81][this.getFormatoTaquilla])}` : "",
          golesExa5: game.odds.FT[82] ? `(5)${this.formatSymbol(game.odds.FT[82][this.getFormatoTaquilla])}` : "",
          golesExa6: game.odds.FT[83] ? `(6+)${this.formatSymbol(game.odds.FT[83][this.getFormatoTaquilla])}` : "",

          //!er Gol intervalo 10 Min
          golIntNing: game.odds.FT[87] ? `(Ning.)${this.formatSymbol(game.odds.FT[87][this.getFormatoTaquilla])}` : "",
          golInt1X10: game.odds.FT[88] ? `(1-10)${this.formatSymbol(game.odds.FT[88][this.getFormatoTaquilla])}` : "",
          golInt11X20: game.odds.FT[89] ? `(11-20)${this.formatSymbol(game.odds.FT[89][this.getFormatoTaquilla])}` : "",
          golInt21X30: game.odds.FT[90] ? `(21-30)${this.formatSymbol(game.odds.FT[90][this.getFormatoTaquilla])}` : "",
          golInt31X40: game.odds.FT[91] ? `(31-40)${this.formatSymbol(game.odds.FT[91][this.getFormatoTaquilla])}` : "",
          golInt41X50: game.odds.FT[92] ? `(41-50)${this.formatSymbol(game.odds.FT[92][this.getFormatoTaquilla])}` : "",
          golInt51X60: game.odds.FT[93] ? `(51-60)${this.formatSymbol(game.odds.FT[93][this.getFormatoTaquilla])}` : "",
          golInt61X70: game.odds.FT[94] ? `(61-70)${this.formatSymbol(game.odds.FT[94][this.getFormatoTaquilla])}` : "",
          golInt71X80: game.odds.FT[95] ? `(71-80)${this.formatSymbol(game.odds.FT[95][this.getFormatoTaquilla])}` : "",
          golInt81X90: game.odds.FT[96] ? `(81-90)${this.formatSymbol(game.odds.FT[96][this.getFormatoTaquilla])}` : "",

          //Totales a ganar/ 3 Way
          locBaja15: game.odds.FT[152]
            ? `Baja 1.5/Loc.${this.formatSymbol(game.odds.FT[152][this.getFormatoTaquilla])}`
            : "",
          locAlta15: game.odds.FT[155]
            ? `Alta 1.5/Loc.${this.formatSymbol(game.odds.FT[155][this.getFormatoTaquilla])}`
            : "",
          empBaja15: game.odds.FT[153]
            ? `Baja 1.5/Emp.${this.formatSymbol(game.odds.FT[153][this.getFormatoTaquilla])}`
            : "",
          empAlta15: game.odds.FT[156]
            ? `Alta 1.5/Emp.${this.formatSymbol(game.odds.FT[156][this.getFormatoTaquilla])}`
            : "",
          visBaja15: game.odds.FT[154]
            ? `Baja 1.5/Vis.${this.formatSymbol(game.odds.FT[154][this.getFormatoTaquilla])}`
            : "",
          visAlta15: game.odds.FT[157]
            ? `Alta 1.5/Vis.${this.formatSymbol(game.odds.FT[157][this.getFormatoTaquilla])}`
            : "",

          locBaja25: game.odds.FT[158]
            ? `Baja 2.5/Loc.${this.formatSymbol(game.odds.FT[158][this.getFormatoTaquilla])}`
            : "",
          locAlta25: game.odds.FT[161]
            ? `Alta 2.5/Loc.${this.formatSymbol(game.odds.FT[161][this.getFormatoTaquilla])}`
            : "",
          empBaja25: game.odds.FT[159]
            ? `Baja 2.5/Emp.${this.formatSymbol(game.odds.FT[159][this.getFormatoTaquilla])}`
            : "",
          empAlta25: game.odds.FT[162]
            ? `Alta 2.5/Emp.${this.formatSymbol(game.odds.FT[162][this.getFormatoTaquilla])}`
            : "",
          visBaja25: game.odds.FT[160]
            ? `Baja 2.5/Vis.${this.formatSymbol(game.odds.FT[160][this.getFormatoTaquilla])}`
            : "",
          visAlta25: game.odds.FT[163]
            ? `Alta 2.5/Vis.${this.formatSymbol(game.odds.FT[163][this.getFormatoTaquilla])}`
            : "",

          locBaja35: game.odds.FT[164]
            ? `Baja 3.5/Loc.${this.formatSymbol(game.odds.FT[164][this.getFormatoTaquilla])}`
            : "",
          locAlta35: game.odds.FT[167]
            ? `Alta 3.5/Loc.${this.formatSymbol(game.odds.FT[167][this.getFormatoTaquilla])}`
            : "",
          empBaja35: game.odds.FT[165]
            ? `Baja 3.5/Emp.${this.formatSymbol(game.odds.FT[165][this.getFormatoTaquilla])}`
            : "",
          empAlta35: game.odds.FT[168]
            ? `Alta 3.5/Emp.${this.formatSymbol(game.odds.FT[168][this.getFormatoTaquilla])}`
            : "",
          visBaja35: game.odds.FT[166]
            ? `Baja 3.5/Vis.${this.formatSymbol(game.odds.FT[166][this.getFormatoTaquilla])}`
            : "",
          visAlta35: game.odds.FT[169]
            ? `Alta 3.5/Vis.${this.formatSymbol(game.odds.FT[169][this.getFormatoTaquilla])}`
            : "",

          locBaja45: game.odds.FT[170]
            ? `Baja 4.5/Loc.${this.formatSymbol(game.odds.FT[170][this.getFormatoTaquilla])}`
            : "",
          locAlta45: game.odds.FT[173]
            ? `Alta 4.5/Loc.${this.formatSymbol(game.odds.FT[173][this.getFormatoTaquilla])}`
            : "",
          empBaja45: game.odds.FT[171]
            ? `Baja 4.5/Emp.${this.formatSymbol(game.odds.FT[171][this.getFormatoTaquilla])}`
            : "",
          empAlta45: game.odds.FT[174]
            ? `Alta 4.5/Emp.${this.formatSymbol(game.odds.FT[174][this.getFormatoTaquilla])}`
            : "",
          visBaja45: game.odds.FT[172]
            ? `Baja 4.5/Vis.${this.formatSymbol(game.odds.FT[172][this.getFormatoTaquilla])}`
            : "",
          visAlta45: game.odds.FT[175]
            ? `Alta 4.5/Vis.${this.formatSymbol(game.odds.FT[175][this.getFormatoTaquilla])}`
            : "",

          //1ERA MITAD
          //Valida si existe logros de la primera mitad
          hayHT:
            game.odds.HT &&
            (game.odds.HT[1] ||
              game.odds.HT[3] ||
              game.odds.HT[10] ||
              game.odds.HT[12] ||
              game.odds.HT[62] ||
              game.odds.HT[49] ||
              game.odds.HT[60] ||
              game.odds.HT[20] ||
              game.odds.HT[77] ||
              game.odds.HT[152])
              ? true
              : false,

          //1 X 2 (HT)
          gana1HT:
            game.odds.HT && game.odds.HT[1] ? `(1)${this.formatSymbol(game.odds.HT[1][this.getFormatoTaquilla])}` : "",
          ganaXHT:
            game.odds.HT && game.odds.HT[7] ? `(X)${this.formatSymbol(game.odds.HT[7][this.getFormatoTaquilla])}` : "",
          gana2HT:
            game.odds.HT && game.odds.HT[2] ? `(2)${this.formatSymbol(game.odds.HT[2][this.getFormatoTaquilla])}` : "",

          //HDP (HT)
          HDPHT1:
            game.odds.HT && game.odds.HT[3]
              ? `(1)${this.formatSymbol(game.odds.HT[3][this.getFormatoTaquilla])} (${game.odds.HT[3].factor})`
              : "",
          HDPHT2:
            game.odds.HT && game.odds.HT[4]
              ? `(2)${this.formatSymbol(game.odds.HT[4][this.getFormatoTaquilla])} (${game.odds.HT[4].factor})`
              : "",

          //Totales Vistante (HT)
          totVisAltaHT:
            game.odds.HT && game.odds.HT[10]
              ? `Alta ${this.formatSymbol(game.odds.HT[10][this.getFormatoTaquilla])} (${game.odds.HT[10].factor})`
              : "",
          totVisBajaHT:
            game.odds.HT && game.odds.HT[11]
              ? `Baja ${this.formatSymbol(game.odds.HT[11][this.getFormatoTaquilla])} (${game.odds.HT[11].factor})`
              : "",

          //Totales Local
          totLocAltaHT:
            game.odds.HT && game.odds.HT[12]
              ? `Alta ${this.formatSymbol(game.odds.HT[12][this.getFormatoTaquilla])} (${game.odds.HT[12].factor})`
              : "",
          totLocBajaHT:
            game.odds.HT && game.odds.HT[13]
              ? `Baja ${this.formatSymbol(game.odds.HT[13][this.getFormatoTaquilla])} (${game.odds.HT[13].factor})`
              : "",

          //Doble chance
          dobleChance1X:
            game.odds.HT && game.odds.HT[62]
              ? `(1X)${this.formatSymbol(game.odds.HT[62][this.getFormatoTaquilla])}`
              : "",
          dobleChance12:
            game.odds.HT && game.odds.HT[63]
              ? `(12)${this.formatSymbol(game.odds.HT[63][this.getFormatoTaquilla])}`
              : "",
          dobleChanceX2:
            game.odds.HT && game.odds.HT[64]
              ? `(X2)${this.formatSymbol(game.odds.HT[64][this.getFormatoTaquilla])}`
              : "",

          //Ambos anota HT
          ambosSiHT:
            game.odds.HT && game.odds.HT[49]
              ? `Si(GG)${this.formatSymbol(game.odds.HT[49][this.getFormatoTaquilla])}`
              : "",
          ambosNoHT:
            game.odds.HT && game.odds.HT[50]
              ? `No(NG)${this.formatSymbol(game.odds.HT[50][this.getFormatoTaquilla])}`
              : "",

          //Marcador Par/Impar (HT)
          parHT:
            game.odds.HT && game.odds.HT[60]
              ? `(Par) ${this.formatSymbol(game.odds.HT[60][this.getFormatoTaquilla])}`
              : "",
          imparHT:
            game.odds.HT && game.odds.HT[61]
              ? `(Impar) ${this.formatSymbol(game.odds.HT[61][this.getFormatoTaquilla])}`
              : "",

          //Marcador Correcto (HT)
          MC1x0HT:
            game.odds.HT && game.odds.HT[20]
              ? ` (1:0)${this.formatSymbol(game.odds.HT[20][this.getFormatoTaquilla])}`
              : "",
          MC2x0HT:
            game.odds.HT && game.odds.HT[21]
              ? ` (2:0)${this.formatSymbol(game.odds.HT[21][this.getFormatoTaquilla])}`
              : "",
          MC2x1HT:
            game.odds.HT && game.odds.HT[22]
              ? ` (2:1)${this.formatSymbol(game.odds.HT[22][this.getFormatoTaquilla])}`
              : "",

          MC0x0HT:
            game.odds.HT && game.odds.HT[32]
              ? ` (0:0)${this.formatSymbol(game.odds.HT[32][this.getFormatoTaquilla])}`
              : "",
          MC1x1HT:
            game.odds.HT && game.odds.HT[33]
              ? ` (1:1)${this.formatSymbol(game.odds.HT[33][this.getFormatoTaquilla])}`
              : "",
          MC2x2HT:
            game.odds.HT && game.odds.HT[34]
              ? ` (2:2)${this.formatSymbol(game.odds.HT[34][this.getFormatoTaquilla])}`
              : "",

          MC0x1HT:
            game.odds.HT && game.odds.HT[36]
              ? ` (0:1)${this.formatSymbol(game.odds.HT[36][this.getFormatoTaquilla])}`
              : "",
          MC0x2HT:
            game.odds.HT && game.odds.HT[37]
              ? ` (0:2)${this.formatSymbol(game.odds.HT[37][this.getFormatoTaquilla])}`
              : "",
          MC1x2HT:
            game.odds.HT && game.odds.HT[38]
              ? ` (1:2)${this.formatSymbol(game.odds.HT[38][this.getFormatoTaquilla])}`
              : "",

          //Totales exactos de goles (HT)
          golesExa0HT:
            game.odds.HT && game.odds.HT[77]
              ? `(0)${this.formatSymbol(game.odds.HT[77][this.getFormatoTaquilla])}`
              : "",
          golesExa1HT:
            game.odds.HT && game.odds.HT[78]
              ? `(1)${this.formatSymbol(game.odds.HT[78][this.getFormatoTaquilla])}`
              : "",
          golesExa2HT:
            game.odds.HT && game.odds.HT[84]
              ? `(2+)${this.formatSymbol(game.odds.HT[84][this.getFormatoTaquilla])}`
              : "",

          //Totales a ganar/ 3 Way
          locBaja15HT:
            game.odds.HT && game.odds.HT[152]
              ? `Baja 1.5/Loc.${this.formatSymbol(game.odds.HT[152][this.getFormatoTaquilla])}`
              : "",
          locAlta15HT:
            game.odds.HT && game.odds.HT[155]
              ? `Alta 1.5/Loc.${this.formatSymbol(game.odds.HT[155][this.getFormatoTaquilla])}`
              : "",
          empBaja15HT:
            game.odds.HT && game.odds.HT[153]
              ? `Baja 1.5/Emp.${this.formatSymbol(game.odds.HT[153][this.getFormatoTaquilla])}`
              : "",
          empAlta15HT:
            game.odds.HT && game.odds.HT[156]
              ? `Alta 1.5/Emp.${this.formatSymbol(game.odds.HT[156][this.getFormatoTaquilla])}`
              : "",
          visBaja15HT:
            game.odds.HT && game.odds.HT[154]
              ? `Baja 1.5/Vis.${this.formatSymbol(game.odds.HT[154][this.getFormatoTaquilla])}`
              : "",
          visAlta15HT:
            game.odds.HT && game.odds.HT[157]
              ? `Alta 1.5/Vis.${this.formatSymbol(game.odds.HT[157][this.getFormatoTaquilla])}`
              : "",

          //2DA MITAD
          //Valida si existe logros de la primera mitad
          hayST:
            game.odds.ST &&
            (game.odds.ST[1] ||
              game.odds.ST[10] ||
              game.odds.ST[12] ||
              game.odds.ST[49] ||
              game.odds.ST[20] ||
              game.odds.ST[77] ||
              game.odds.ST[60] ||
              game.odds.ST[152])
              ? true
              : false,

          //1 X 2 (ST)
          gana1ST:
            game.odds.ST && game.odds.ST[1] ? `(1)${this.formatSymbol(game.odds.ST[1][this.getFormatoTaquilla])}` : "",
          ganaXST:
            game.odds.ST && game.odds.ST[7] ? `(X)${this.formatSymbol(game.odds.ST[7][this.getFormatoTaquilla])}` : "",
          gana2ST:
            game.odds.ST && game.odds.ST[2] ? `(2)${this.formatSymbol(game.odds.ST[2][this.getFormatoTaquilla])}` : "",

          //Spread/HDP ST
          spreadST1:
            game.odds.ST && game.odds.ST[3]
              ? `${this.formatSymbol(game.odds.ST[3][this.getFormatoTaquilla])}` + " (" + game.odds.ST[3].factor + ")"
              : "",
          spreadST2:
            game.odds.ST && game.odds.ST[4]
              ? `${this.formatSymbol(game.odds.ST[4][this.getFormatoTaquilla])}` + " (" + game.odds.ST[4].factor + ")"
              : "",

          //Totales Vistante (ST)
          totVisAltaST:
            game.odds.ST && game.odds.ST[10]
              ? `Alta ${this.formatSymbol(game.odds.ST[10][this.getFormatoTaquilla])} (${game.odds.ST[10].factor})`
              : "",
          totVisBajaST:
            game.odds.ST && game.odds.ST[11]
              ? `Baja ${this.formatSymbol(game.odds.ST[11][this.getFormatoTaquilla])} (${game.odds.ST[11].factor})`
              : "",

          //Totales Local (ST)
          totLocAltaST:
            game.odds.ST && game.odds.ST[12]
              ? `Alta ${this.formatSymbol(game.odds.ST[12][this.getFormatoTaquilla])} (${game.odds.ST[12].factor})`
              : "",
          totLocBajaST:
            game.odds.ST && game.odds.ST[13]
              ? `Baja ${this.formatSymbol(game.odds.ST[13][this.getFormatoTaquilla])} (${game.odds.ST[13].factor})`
              : "",

          //Ambos anota ST
          ambosSiST:
            game.odds.ST && game.odds.ST[49]
              ? `Si(GG)${this.formatSymbol(game.odds.ST[49][this.getFormatoTaquilla])}`
              : "",
          ambosNoST:
            game.odds.ST && game.odds.ST[50]
              ? `No(NG)${this.formatSymbol(game.odds.ST[50][this.getFormatoTaquilla])}`
              : "",

          //Marcador Correcto (ST)
          MC1x0ST:
            game.odds.ST && game.odds.ST[20]
              ? ` (1:0)${this.formatSymbol(game.odds.ST[20][this.getFormatoTaquilla])}`
              : "",
          MC2x0ST:
            game.odds.ST && game.odds.ST[21]
              ? ` (2:0)${this.formatSymbol(game.odds.ST[21][this.getFormatoTaquilla])}`
              : "",
          MC2x1ST:
            game.odds.ST && game.odds.ST[22]
              ? ` (2:1)${this.formatSymbol(game.odds.ST[22][this.getFormatoTaquilla])}`
              : "",

          MC0x0ST:
            game.odds.ST && game.odds.ST[32]
              ? ` (0:0)${this.formatSymbol(game.odds.ST[32][this.getFormatoTaquilla])}`
              : "",
          MC1x1ST:
            game.odds.ST && game.odds.ST[33]
              ? ` (1:1)${this.formatSymbol(game.odds.ST[33][this.getFormatoTaquilla])}`
              : "",
          MC2x2ST:
            game.odds.ST && game.odds.ST[34]
              ? ` (2:2)${this.formatSymbol(game.odds.ST[34][this.getFormatoTaquilla])}`
              : "",

          MC0x1ST:
            game.odds.ST && game.odds.ST[36]
              ? ` (0:1)${this.formatSymbol(game.odds.ST[36][this.getFormatoTaquilla])}`
              : "",
          MC0x2ST:
            game.odds.ST && game.odds.ST[37]
              ? ` (0:2)${this.formatSymbol(game.odds.ST[37][this.getFormatoTaquilla])}`
              : "",
          MC1x2ST:
            game.odds.ST && game.odds.ST[38]
              ? ` (1:2)${this.formatSymbol(game.odds.ST[38][this.getFormatoTaquilla])}`
              : "",

          //Totales exactos de goles (ST)
          golesExa0ST:
            game.odds.ST && game.odds.ST[77]
              ? `(0)${this.formatSymbol(game.odds.ST[77][this.getFormatoTaquilla])}`
              : "",
          golesExa1ST:
            game.odds.ST && game.odds.ST[78]
              ? `(1)${this.formatSymbol(game.odds.ST[78][this.getFormatoTaquilla])}`
              : "",
          golesExa2ST:
            game.odds.ST && game.odds.ST[84]
              ? `(2+)${this.formatSymbol(game.odds.ST[84][this.getFormatoTaquilla])}`
              : "",

          //Marcador Par/Impar (ST)
          parST:
            game.odds.ST && game.odds.ST[60]
              ? `(Par) ${this.formatSymbol(game.odds.ST[60][this.getFormatoTaquilla])}`
              : "",
          imparST:
            game.odds.ST && game.odds.ST[61]
              ? `(Impar) ${this.formatSymbol(game.odds.ST[61][this.getFormatoTaquilla])}`
              : "",

          //Totales a ganar/ 3 Way (ST)
          locBaja15ST:
            game.odds.ST && game.odds.ST[152]
              ? `Baja 1.5/Loc.${this.formatSymbol(game.odds.ST[152][this.getFormatoTaquilla])}`
              : "",
          locAlta15ST:
            game.odds.ST && game.odds.ST[155]
              ? `Alta 1.5/Loc.${this.formatSymbol(game.odds.ST[155][this.getFormatoTaquilla])}`
              : "",
          empBaja15ST:
            game.odds.ST && game.odds.ST[153]
              ? `Baja 1.5/Emp.${this.formatSymbol(game.odds.ST[153][this.getFormatoTaquilla])}`
              : "",
          empAlta15ST:
            game.odds.ST && game.odds.ST[156]
              ? `Alta 1.5/Emp.${this.formatSymbol(game.odds.ST[156][this.getFormatoTaquilla])}`
              : "",
          visBaja15ST:
            game.odds.ST && game.odds.ST[154]
              ? `Baja 1.5/Vis.${this.formatSymbol(game.odds.ST[154][this.getFormatoTaquilla])}`
              : "",
          visAlta15ST:
            game.odds.ST && game.odds.ST[157]
              ? `Alta 1.5/Vis.${this.formatSymbol(game.odds.ST[157][this.getFormatoTaquilla])}`
              : ""
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "footballAmericano") {
        const HT1 = game.odds.HT && game.odds.HT[1] ? this.formatSymbol(game.odds.HT[1][this.getFormatoTaquilla]) : "";
        const HT2 = game.odds.HT && game.odds.HT[2] ? this.formatSymbol(game.odds.HT[2][this.getFormatoTaquilla]) : "";

        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[3]
                ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
                : "",
              SI: game.odds.FT[49] ? `${this.formatSymbol(game.odds.FT[49][this.getFormatoTaquilla])}` : "",
              TOTALESFT: game.odds.FT[5]
                ? `A${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                : "",
              "1X2HT": `${HT1}`,
              HDPHT:
                game.odds.HT && game.odds.HT[3]
                  ? `${this.formatSymbol(game.odds.HT[3][this.getFormatoTaquilla])} (${game.odds.HT[3].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[5]
                  ? `A${this.formatSymbol(game.odds.HT[5][this.getFormatoTaquilla])} (${game.odds.HT[5].factor})`
                  : ""
            },
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[4]
                ? this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla]) + " (" + game.odds.FT[4].factor + ")"
                : "",
              NO: game.odds.FT[50] ? `${this.formatSymbol(game.odds.FT[50][this.getFormatoTaquilla])}` : "",
              TOTALESFT: `B${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`,
              "1X2HT": `${HT2}`,
              HDPHT:
                game.odds.HT && game.odds.HT[4]
                  ? `${this.formatSymbol(game.odds.HT[4][this.getFormatoTaquilla])} (${game.odds.HT[4].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[6]
                  ? `B${this.formatSymbol(game.odds.HT[6][this.getFormatoTaquilla])} (${game.odds.HT[6].factor})`
                  : ""
            }
          ],

          //Valida si hay logros de FT adicionales
          hayFT: game.odds.FT[60] || game.odds.FT[129] ? true : false,

          //Marcador Par/Impar
          parFT: game.odds.FT[60] ? `(Par) ${this.formatSymbol(game.odds.FT[60][this.getFormatoTaquilla])}` : "",
          imparFT: game.odds.FT[61] ? `(Impar) ${this.formatSymbol(game.odds.FT[61][this.getFormatoTaquilla])}` : "",

          //1 X 2 (SIN OT)
          gana1FT: game.odds.FT[129] ? `(1) ${this.formatSymbol(game.odds.FT[129][this.getFormatoTaquilla])}` : "",
          ganaXFT: game.odds.FT[130] ? `(X) ${this.formatSymbol(game.odds.FT[130][this.getFormatoTaquilla])}` : "",
          gana2FT: game.odds.FT[130] ? `(2) ${this.formatSymbol(game.odds.FT[131][this.getFormatoTaquilla])}` : "",

          //Valida si lista algun logro de HT
          hayHT: game.odds.HT && (game.odds.HT[60] || game.odds.HT[148]) ? true : false,

          ////Marcador Par/impar HT
          parHT:
            game.odds.HT && game.odds.HT[60]
              ? `(Par) ${this.formatSymbol(game.odds.HT[60][this.getFormatoTaquilla])}`
              : "",
          imparHT:
            game.odds.HT && game.odds.HT[61]
              ? `(Impar) ${this.formatSymbol(game.odds.HT[61][this.getFormatoTaquilla])}`
              : "",

          //1 X 2 HT
          gana1HT:
            game.odds.HT && game.odds.HT[148]
              ? `(1) ${this.formatSymbol(game.odds.HT[148][this.getFormatoTaquilla])}`
              : "",
          ganaXHT:
            game.odds.HT && game.odds.HT[149]
              ? `(X) ${this.formatSymbol(game.odds.HT[149][this.getFormatoTaquilla])}`
              : "",
          gana2HT:
            game.odds.HT && game.odds.HT[150]
              ? `(2) ${this.formatSymbol(game.odds.HT[150][this.getFormatoTaquilla])}`
              : "",

          hayFQ: game.odds.FQ ? true : false,

          //1 X 2 FQ
          gana1FQ:
            game.odds.FQ && game.odds.FQ[1] ? `(1) ${this.formatSymbol(game.odds.FQ[1][this.getFormatoTaquilla])}` : "",
          ganaXFQ:
            game.odds.FQ && game.odds.FQ[7] ? `(X) ${this.formatSymbol(game.odds.FQ[7][this.getFormatoTaquilla])}` : "",
          gana2FQ:
            game.odds.FQ && game.odds.FQ[2] ? `(2) ${this.formatSymbol(game.odds.FQ[2][this.getFormatoTaquilla])}` : "",

          //Spread/HDP
          spreadFQ1:
            game.odds.FQ && game.odds.FQ[3]
              ? `(1) ${this.formatSymbol(game.odds.FQ[3][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.FQ[3].factor +
                ")"
              : "",
          spreadFQ2:
            game.odds.FQ && game.odds.FQ[4]
              ? `(2) ${this.formatSymbol(game.odds.FQ[4][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.FQ[4].factor +
                ")"
              : "",

          //Totales FQ
          totAltaFQ:
            game.odds.FQ && game.odds.FQ[5]
              ? `(A) ${this.formatSymbol(game.odds.FQ[5][this.getFormatoTaquilla])} (${game.odds.FQ[5].factor})`
              : "",
          totBajaFQ:
            game.odds.FQ && game.odds.FQ[6]
              ? `(B) ${this.formatSymbol(game.odds.FQ[6][this.getFormatoTaquilla])} (${game.odds.FQ[6].factor})`
              : "",

          haySQ: game.odds.SQ ? true : false,

          //1 X 2 SQ
          gana1SQ:
            game.odds.SQ && game.odds.SQ[1] ? `(1) ${this.formatSymbol(game.odds.SQ[1][this.getFormatoTaquilla])}` : "",
          ganaXSQ:
            game.odds.SQ && game.odds.SQ[7] ? `(X) ${this.formatSymbol(game.odds.SQ[7][this.getFormatoTaquilla])}` : "",
          gana2SQ:
            game.odds.SQ && game.odds.SQ[2] ? `(2) ${this.formatSymbol(game.odds.SQ[2][this.getFormatoTaquilla])}` : "",

          spreadSQ1:
            game.odds.SQ && game.odds.SQ[3]
              ? `(1) ${this.formatSymbol(game.odds.SQ[3][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.SQ[3].factor +
                ")"
              : "",
          spreadSQ2:
            game.odds.SQ && game.odds.SQ[4]
              ? `(2) ${this.formatSymbol(game.odds.SQ[4][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.SQ[4].factor +
                ")"
              : "",

          //Totales SQ
          totAltaSQ:
            game.odds.SQ && game.odds.SQ[5]
              ? `(A) ${this.formatSymbol(game.odds.SQ[5][this.getFormatoTaquilla])} (${game.odds.SQ[5].factor})`
              : "",
          totBajaSQ:
            game.odds.SQ && game.odds.SQ[6]
              ? `(B) ${this.formatSymbol(game.odds.SQ[6][this.getFormatoTaquilla])} (${game.odds.SQ[6].factor})`
              : "",

          hayTQ: game.odds.TQ ? true : false,

          //1 X 2 TQ
          gana1TQ:
            game.odds.TQ && game.odds.TQ[1] ? `(1) ${this.formatSymbol(game.odds.TQ[1][this.getFormatoTaquilla])}` : "",
          ganaXTQ:
            game.odds.TQ && game.odds.TQ[7] ? `(X) ${this.formatSymbol(game.odds.TQ[7][this.getFormatoTaquilla])}` : "",
          gana2TQ:
            game.odds.TQ && game.odds.TQ[2] ? `(2) ${this.formatSymbol(game.odds.TQ[2][this.getFormatoTaquilla])}` : "",

          //Spread/HDP TQ
          spreadTQ1:
            game.odds.TQ && game.odds.TQ[3]
              ? `(1) ${this.formatSymbol(game.odds.TQ[3][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.TQ[3].factor +
                ")"
              : "",
          spreadTQ2:
            game.odds.TQ && game.odds.TQ[4]
              ? `(2) ${this.formatSymbol(game.odds.TQ[4][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.TQ[4].factor +
                ")"
              : "",

          //Totales TQ
          totAltaTQ:
            game.odds.TQ && game.odds.TQ[5]
              ? `(A) ${this.formatSymbol(game.odds.TQ[5][this.getFormatoTaquilla])} (${game.odds.TQ[5].factor})`
              : "",
          totBajaTQ:
            game.odds.TQ && game.odds.TQ[6]
              ? `(B) ${this.formatSymbol(game.odds.TQ[6][this.getFormatoTaquilla])} (${game.odds.TQ[6].factor})`
              : "",

          hayQQ: game.odds.QQ ? true : false,

          //1 X 2 QQ
          gana1QQ:
            game.odds.QQ && game.odds.QQ[1] ? `(1) ${this.formatSymbol(game.odds.QQ[1][this.getFormatoTaquilla])}` : "",
          ganaXQQ:
            game.odds.QQ && game.odds.QQ[7] ? `(X) ${this.formatSymbol(game.odds.QQ[7][this.getFormatoTaquilla])}` : "",
          gana2QQ:
            game.odds.QQ && game.odds.QQ[2] ? `(2) ${this.formatSymbol(game.odds.QQ[2][this.getFormatoTaquilla])}` : "",

          //Spread/HDP QQ
          spreadQQ1:
            game.odds.QQ && game.odds.QQ[3]
              ? `(1) ${this.formatSymbol(game.odds.QQ[3][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.QQ[3].factor +
                ")"
              : "",
          spreadQQ2:
            game.odds.QQ && game.odds.QQ[4]
              ? `(2) ${this.formatSymbol(game.odds.QQ[4][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.QQ[4].factor +
                ")"
              : "",

          //Totales QQ
          totAltaQQ:
            game.odds.QQ && game.odds.QQ[5]
              ? `(A) ${this.formatSymbol(game.odds.QQ[5][this.getFormatoTaquilla])} (${game.odds.QQ[5].factor})`
              : "",
          totBajaQQ:
            game.odds.QQ && game.odds.QQ[6]
              ? `(B) ${this.formatSymbol(game.odds.QQ[6][this.getFormatoTaquilla])} (${game.odds.QQ[6].factor})`
              : ""
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "hockey") {
        const HT1 = game.odds.HT && game.odds.HT[1] ? this.formatSymbol(game.odds.HT[1][this.getFormatoTaquilla]) : "";
        const HT2 = game.odds.HT && game.odds.HT[2] ? this.formatSymbol(game.odds.HT[2][this.getFormatoTaquilla]) : "";

        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[3]
                ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
                : "",
              SI: game.odds.FT[49] ? `${this.formatSymbol(game.odds.FT[49][this.getFormatoTaquilla])}` : "",
              TOTALESFT: game.odds.FT[5]
                ? `A${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                : "",
              "1X2HT": `${HT1}`,
              HDPHT:
                game.odds.HT && game.odds.HT[3]
                  ? `${this.formatSymbol(game.odds.HT[3][this.getFormatoTaquilla])} (${game.odds.HT[3].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[5]
                  ? `A${this.formatSymbol(game.odds.HT[5][this.getFormatoTaquilla])} (${game.odds.HT[5].factor})`
                  : ""
            },
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[4]
                ? this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla]) + " (" + game.odds.FT[4].factor + ")"
                : "",
              NO: game.odds.FT[50] ? `${this.formatSymbol(game.odds.FT[50][this.getFormatoTaquilla])}` : "",
              TOTALESFT:
                game.odds && game.odds.FT[6]
                  ? `B${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`
                  : "",
              "1X2HT": `${HT2}`,
              HDPHT:
                game.odds.HT && game.odds.HT[4]
                  ? `${this.formatSymbol(game.odds.HT[4][this.getFormatoTaquilla])} (${game.odds.HT[4].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[6]
                  ? `B${this.formatSymbol(game.odds.HT[6][this.getFormatoTaquilla])} (${game.odds.HT[6].factor})`
                  : ""
            }
          ],

          //Anota Primero
          anotaLoc: game.odds.FT[16] ? `Loc (1) ${this.formatSymbol(game.odds.FT[16][this.getFormatoTaquilla])}` : "",
          anotaNin: game.odds.FT[114]
            ? `ning. (1) ${this.formatSymbol(game.odds.FT[114][this.getFormatoTaquilla])}`
            : "",
          anotaVis: game.odds.FT[17] ? `Vis. (2) ${this.formatSymbol(game.odds.FT[17][this.getFormatoTaquilla])}` : "",

          //Ultimo en anotar
          anotaLocUlt: game.odds.FT[146]
            ? `Loc (1) ${this.formatSymbol(game.odds.FT[146][this.getFormatoTaquilla])}`
            : "",
          anotaNinUlt: game.odds.FT[147]
            ? `ning. (1) ${this.formatSymbol(game.odds.FT[147][this.getFormatoTaquilla])}`
            : "",
          anotaVisUlt: game.odds.FT[145]
            ? `Vis. (2) ${this.formatSymbol(game.odds.FT[145][this.getFormatoTaquilla])}`
            : "",

          //Valida si lista algun logro de FT adicional
          hayFT: game.odds.FT && (game.odds.FT[60] || game.odds.FT[62] || game.odds.FT[129]) ? true : false,

          //Marcador Par/Impar
          parFT:
            game.odds.FT && game.odds.FT[60]
              ? `(Par) ${this.formatSymbol(game.odds.FT[60][this.getFormatoTaquilla])}`
              : "",
          imparFT:
            game.odds.FT && game.odds.FT[61]
              ? `(Impar) ${this.formatSymbol(game.odds.FT[61][this.getFormatoTaquilla])}`
              : "",

          //Doble chance
          dobleChance1X:
            game.odds.FT && game.odds.FT[62]
              ? `(1X)${this.formatSymbol(game.odds.FT[62][this.getFormatoTaquilla])}`
              : "",
          dobleChance12:
            game.odds.FT && game.odds.FT[63]
              ? `(12)${this.formatSymbol(game.odds.FT[63][this.getFormatoTaquilla])}`
              : "",
          dobleChanceX2:
            game.odds.FT && game.odds.FT[64]
              ? `(X2)${this.formatSymbol(game.odds.FT[64][this.getFormatoTaquilla])}`
              : "",

          //1 X 2 (FT)
          gana1FT:
            game.odds.FT && game.odds.FT[129]
              ? `(1)${this.formatSymbol(game.odds.FT[129][this.getFormatoTaquilla])}`
              : "",
          ganaXFT:
            game.odds.FT && game.odds.FT[130]
              ? `(X)${this.formatSymbol(game.odds.FT[130][this.getFormatoTaquilla])}`
              : "",
          gana2FT:
            game.odds.FT && game.odds.FT[131]
              ? `(2)${this.formatSymbol(game.odds.FT[131][this.getFormatoTaquilla])}`
              : "",

          hayFQ: game.odds.FQ ? true : false,

          //1 X 2 (FQ)
          gana1FQ:
            game.odds.FQ && game.odds.FQ[1] ? `(1)${this.formatSymbol(game.odds.FQ[1][this.getFormatoTaquilla])}` : "",
          ganaXFQ:
            game.odds.FQ && game.odds.FQ[7] ? `(X)${this.formatSymbol(game.odds.FQ[7][this.getFormatoTaquilla])}` : "",
          gana2FQ:
            game.odds.FQ && game.odds.FQ[2] ? `(2)${this.formatSymbol(game.odds.FQ[2][this.getFormatoTaquilla])}` : "",

          //Spread/HDP
          spreadFQ1:
            game.odds.FQ && game.odds.FQ[3]
              ? `(1) ${this.formatSymbol(game.odds.FQ[3][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.FQ[3].factor +
                ")"
              : "",
          spreadFQ2:
            game.odds.FQ && game.odds.FQ[4]
              ? `(2) ${this.formatSymbol(game.odds.FQ[4][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.FQ[4].factor +
                ")"
              : "",

          //Totales FQ
          totAltaFQ:
            game.odds.FQ && game.odds.FQ[5]
              ? `(A) ${this.formatSymbol(game.odds.FQ[5][this.getFormatoTaquilla])} (${game.odds.FQ[5].factor})`
              : "",
          totBajaFQ:
            game.odds.FQ && game.odds.FQ[6]
              ? `(B) ${this.formatSymbol(game.odds.FQ[6][this.getFormatoTaquilla])} (${game.odds.FQ[6].factor})`
              : "",

          //Doble chance
          dobleChance1XFQ:
            game.odds.FQ && game.odds.FQ[62]
              ? `(1X)${this.formatSymbol(game.odds.FQ[62][this.getFormatoTaquilla])}`
              : "",
          dobleChance12FQ:
            game.odds.FQ && game.odds.FQ[63]
              ? `(12)${this.formatSymbol(game.odds.FQ[63][this.getFormatoTaquilla])}`
              : "",
          dobleChanceX2FQ:
            game.odds.FQ && game.odds.FQ[64]
              ? `(X2)${this.formatSymbol(game.odds.FQ[64][this.getFormatoTaquilla])}`
              : "",

          haySQ: game.odds.SQ ? true : false,

          //1 X 2 (SQ)
          gana1SQ:
            game.odds.SQ && game.odds.SQ[1] ? `(1)${this.formatSymbol(game.odds.SQ[1][this.getFormatoTaquilla])}` : "",
          ganaXSQ:
            game.odds.SQ && game.odds.SQ[7] ? `(X)${this.formatSymbol(game.odds.SQ[7][this.getFormatoTaquilla])}` : "",
          gana2SQ:
            game.odds.SQ && game.odds.SQ[2] ? `(2)${this.formatSymbol(game.odds.SQ[2][this.getFormatoTaquilla])}` : "",

          //Totales FQ
          totAltaSQ:
            game.odds.SQ && game.odds.SQ[5]
              ? `(A) ${this.formatSymbol(game.odds.SQ[5][this.getFormatoTaquilla])} (${game.odds.SQ[5].factor})`
              : "",
          totBajaSQ:
            game.odds.SQ && game.odds.SQ[6]
              ? `(B) ${this.formatSymbol(game.odds.SQ[6][this.getFormatoTaquilla])} (${game.odds.SQ[6].factor})`
              : "",

          //Spread/HDP
          spreadSQ1:
            game.odds.SQ && game.odds.SQ[3]
              ? `(1) ${this.formatSymbol(game.odds.SQ[3][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.SQ[3].factor +
                ")"
              : "",
          spreadSQ2:
            game.odds.SQ && game.odds.SQ[4]
              ? `(2) ${this.formatSymbol(game.odds.SQ[4][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.SQ[4].factor +
                ")"
              : "",

          //Doble chance
          dobleChance1XSQ:
            game.odds.SQ && game.odds.SQ[62]
              ? `(1X)${this.formatSymbol(game.odds.SQ[62][this.getFormatoTaquilla])}`
              : "",
          dobleChance12SQ:
            game.odds.SQ && game.odds.SQ[63]
              ? `(12)${this.formatSymbol(game.odds.SQ[63][this.getFormatoTaquilla])}`
              : "",
          dobleChanceX2SQ:
            game.odds.SQ && game.odds.SQ[64]
              ? `(X2)${this.formatSymbol(game.odds.SQ[64][this.getFormatoTaquilla])}`
              : "",

          hayTQ: game.odds.TQ ? true : false,

          //1 X 2 (TQ)
          gana1TQ:
            game.odds.TQ && game.odds.TQ[1] ? `(1)${this.formatSymbol(game.odds.TQ[1][this.getFormatoTaquilla])}` : "",
          ganaXTQ:
            game.odds.TQ && game.odds.TQ[7] ? `(X)${this.formatSymbol(game.odds.TQ[7][this.getFormatoTaquilla])}` : "",
          gana2TQ:
            game.odds.TQ && game.odds.TQ[2] ? `(2)${this.formatSymbol(game.odds.TQ[2][this.getFormatoTaquilla])}` : "",

          //Totales FQ
          totAltaTQ:
            game.odds.TQ && game.odds.TQ[5]
              ? `(A) ${this.formatSymbol(game.odds.TQ[5][this.getFormatoTaquilla])} (${game.odds.TQ[5].factor})`
              : "",
          totBajaTQ:
            game.odds.TQ && game.odds.TQ[6]
              ? `(B) ${this.formatSymbol(game.odds.TQ[6][this.getFormatoTaquilla])} (${game.odds.TQ[6].factor})`
              : "",

          //Spread/HDP
          spreadTQ1:
            game.odds.TQ && game.odds.TQ[3]
              ? `(1) ${this.formatSymbol(game.odds.TQ[3][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.TQ[3].factor +
                ")"
              : "",
          spreadTQ2:
            game.odds.TQ && game.odds.TQ[4]
              ? `(2) ${this.formatSymbol(game.odds.TQ[4][this.getFormatoTaquilla])}` +
                " (" +
                game.odds.TQ[4].factor +
                ")"
              : "",

          //Doble chance
          dobleChance1XTQ:
            game.odds.TQ && game.odds.TQ[62]
              ? `(1X)${this.formatSymbol(game.odds.TQ[62][this.getFormatoTaquilla])}`
              : "",
          dobleChance12TQ:
            game.odds.TQ && game.odds.TQ[63]
              ? `(12)${this.formatSymbol(game.odds.TQ[63][this.getFormatoTaquilla])}`
              : "",
          dobleChanceX2TQ:
            game.odds.TQ && game.odds.TQ[64]
              ? `(X2)${this.formatSymbol(game.odds.TQ[64][this.getFormatoTaquilla])}`
              : ""
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "tennis") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[3]
                ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
                : "",
              TOTALESFT: game.odds.FT[5]
                ? `A${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[4]
                ? `${this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla])} (${game.odds.FT[4].factor})`
                : "",
              TOTALESFT: game.odds.FT[6]
                ? `B${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`
                : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "esport") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team ? game.Team[0].team_name : "",
              "1X2FT": game.odds && game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              HDPFT:
                game.odds && game.odds.FT[3]
                  ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
                  : "",
              TOTALESFT:
                game.odds && game.odds.FT[5]
                  ? `A${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                  : "",
              EMPATE: game.odds && game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team && game.Team[1].team_name,
              "1X2FT": game.odds && game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              HDPFT:
                game.odds && game.odds.FT[4]
                  ? `${this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla])} (${game.odds.FT[4].factor})`
                  : "",
              TOTALESFT:
                game.odds && game.odds.FT[6]
                  ? `B${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`
                  : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "rugby") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              EMPATE: game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[3]
                ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
                : "",
              TOTALESFT: game.odds.FT[5]
                ? `A${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[4]
                ? `${this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla])} (${game.odds.FT[4].factor})`
                : "",
              TOTALESFT: game.odds.FT[6]
                ? `B${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`
                : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "boxeo") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "cricket") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              EMPATE: game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "dardos") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "balonmano") {
        const HT1 = game.odds.HT && game.odds.HT[1] ? this.formatSymbol(game.odds.HT[1][this.getFormatoTaquilla]) : "";
        const HT2 = game.odds.HT && game.odds.HT[2] ? this.formatSymbol(game.odds.HT[2][this.getFormatoTaquilla]) : "";

        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              EMPATE: game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[3]
                ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
                : "",
              TOTALESFT: game.odds.FT[5]
                ? `A${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                : "",
              "1X2HT": `${HT1}`,
              HDPHT:
                game.odds.HT && game.odds.HT[3]
                  ? `${this.formatSymbol(game.odds.HT[3][this.getFormatoTaquilla])} (${game.odds.HT[3].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[5]
                  ? `A${this.formatSymbol(game.odds.HT[5][this.getFormatoTaquilla])} (${game.odds.HT[5].factor})`
                  : ""
            },
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              HDPFT: game.odds.FT[4]
                ? this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla]) + " (" + game.odds.FT[4].factor + ")"
                : "",
              TOTALESFT: game.odds.FT[5]
                ? `B${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`
                : "",
              "1X2HT": `${HT2}`,
              HDPHT:
                game.odds.HT && game.odds.HT[4]
                  ? `${this.formatSymbol(game.odds.HT[4][this.getFormatoTaquilla])} (${game.odds.HT[4].factor})`
                  : "",
              TOTALESHT:
                game.odds.HT && game.odds.HT[6]
                  ? `B${this.formatSymbol(game.odds.HT[6][this.getFormatoTaquilla])} (${game.odds.HT[6].factor})`
                  : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "pingpong") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "badminton") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "pesapallo") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              EMPATE: game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "waterpolo") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              EMPATE: game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "voleiball") {
        //const HT1 = game.odds.HT && game.odds.HT[1] ? this.formatSymbol(game.odds.HT[1][this.getFormatoTaquilla]) : "";
        //const HT2 = game.odds.HT && game.odds.HT[2] ? this.formatSymbol(game.odds.HT[2][this.getFormatoTaquilla]) : "";

        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : ""
              // HDPFT: game.odds.FT[3]
              //   ? `${this.formatSymbol(game.odds.FT[3][this.getFormatoTaquilla])} (${game.odds.FT[3].factor})`
              //   : "",
              // TOTALESFT: game.odds.FT[5]
              //   ? `A${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
              //   : "",
              // "1X2HT": `${HT1}`,
              // HDPHT:
              //   game.odds.HT && game.odds.HT[3]
              //     ? `${this.formatSymbol(game.odds.HT[3][this.getFormatoTaquilla])} (${game.odds.HT[3].factor})`
              //     : "",
              // TOTALESHT:
              //   game.odds.HT && game.odds.HT[5]
              //     ? `A${this.formatSymbol(game.odds.HT[5][this.getFormatoTaquilla])} (${game.odds.HT[5].factor})`
              //     : "",
            },
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : ""
              // HDPFT: game.odds.FT[4]
              //   ? this.formatSymbol(game.odds.FT[4][this.getFormatoTaquilla]) + " (" + game.odds.FT[4].factor + ")"
              //   : "",
              // TOTALESFT: `B${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`,
              // "1X2HT": `${HT2}`,
              // HDPHT:
              //   game.odds.HT && game.odds.HT[4]
              //     ? `${this.formatSymbol(game.odds.HT[4][this.getFormatoTaquilla])} (${game.odds.HT[4].factor})`
              //     : "",
              // TOTALESHT:
              //   game.odds.HT && game.odds.HT[6]
              //     ? `B${this.formatSymbol(game.odds.HT[6][this.getFormatoTaquilla])} (${game.odds.HT[6].factor})`
              //     : "",
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "bolos") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "bandy") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              EMPATE: game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : ""
            }
          ]
        };
      }

      if (this.getNameGame(game.league_id, game.sport_id) == "futbolsala") {
        return {
          id: game.id,
          gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
          gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
          gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
          created_at: game.created_at,
          muestraTitulo: muestraTitulo,
          odds: game.odds,
          teams: [
            {
              name: game.Team[0].team_name,
              "1X2FT": game.odds.FT[1] ? this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]) : "",
              EMPATE: game.odds.FT[7] ? this.formatSymbol(game.odds.FT[7][this.getFormatoTaquilla]) : "",
              TOTALESFT: game.odds.FT[5]
                ? `A${this.formatSymbol(game.odds.FT[5][this.getFormatoTaquilla])} (${game.odds.FT[5].factor})`
                : ""
            },
            {
              name: game.Team[1].team_name,
              "1X2FT": game.odds.FT[2] ? this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla]) : "",
              TOTALESFT: game.odds.FT[5]
                ? `B${this.formatSymbol(game.odds.FT[6][this.getFormatoTaquilla])} (${game.odds.FT[6].factor})`
                : ""
            }
          ]
        };
      }

      return {
        gameName: game.league_id ? this.getNameLeague(game.league_id) : "",
        gameDate: moment(String(game.date)).format("DD/MM/YYYY"),
        gameTime: moment(String(game.time)).format("DD/MM/YYYY hh:mm A"),
        created_at: game.created_at,
        muestraTitulo: muestraTitulo,
        odds: game.odds,
        teams: [],
        FT: [
          this.formatSymbol(game.odds.FT[1][this.getFormatoTaquilla]),
          this.formatSymbol(game.odds.FT[2][this.getFormatoTaquilla])
        ]
      };
    },
    getNameLeague(id_league) {
      return this.getLeagues
        ? this.getLeagues.flatMap(l => {
            return l.id == id_league ? l.name + " (" + l.sport + ")" : [];
          })[0]
        : "";
    },
    async listarJuegos(evt) {
      this.btnFechaActivo = evt.target.id;

      if (this.btnFechaActivo == "btnFecha") {
        this.calendario = true;
      } else if (this.btnFechaActivo == "btnTodas") {
        this.calendario = false;
      } else {
        this.calendario = false;
      }

      this.asignarLigas();

      this.filtrarJuegos();

      $("#div-lapso .col .btn").each(function() {
        if (evt.target.id == this.id) {
          $("#" + this.id).addClass("act");
        } else {
          $("#" + this.id).removeClass("act");
        }
      });
    },
    async filtrarJuegos() {
      var fecha = moment().format("YYYY-MM-DD");

      if (this.btnFechaActivo == "btnTodas") fecha = null;
      if (this.btnFechaActivo == "btnFecha") fecha = moment(this.fecha).format("YYYY-MM-DD");

      this.$store.commit("showLoader");

      if (this.liga) {
        await this.$store.dispatch("getGamesAsync", {
          league_id: this.liga,
          date: fecha
        });
        this.verDestacados = true;
      } else {
        this.verDestacados = false;
      }

      this.fechaAnt = null;

      this.gamesEmpty = this.getGames.length == 0 ? true : false;
      this.$store.commit("hideLoader");
    },
    async seleccionarLigas(evt) {
      this.esDestacado = false;
      $("#div-deportes .row .col-4 .btn-sport").each(function() {
        if (evt.target.id == this.id) {
          $("#" + this.id).addClass("act");
        } else {
          $("#" + this.id).removeClass("act");
        }
      });

      this.deporteActivo = Number(evt.target.value);

      this.asignarLigas();
    },
    async asignarLigas() {
      var fecha = null;

      if (this.btnFechaActivo == "btnHoy") fecha = moment().format("YYYY-MM-DD");
      else if (this.btnFechaActivo == "btnFecha") fecha = moment(this.fecha).format("YYYY-MM-DD");

      this.liga = "";

      this.$store.commit("showLoader");
      await this.$store.dispatch("getLeaguesAsync", { date: fecha });

      this.verDestacados = false;

      if (this.deporteActivo <= 5) {
        this.activaSelLiga = true;
        this.verOtrosDeportes = false;
        this.ligas = this.getLeagues.filter(element => element.sport_id == this.deporteActivo);
        this.ligas.forEach(element => (element.sportName = element.name));
      } else {
        this.verOtrosDeportes = true;
        this.ligas = this.getLeagues.filter(element => element.sport_id > 5);
        this.otros = [...new Set(this.ligas.map(item => item.sport))];
      }

      this.ligas.sort((a, b) => (a.sportName > b.sportName ? 1 : b.sportName > a.sportName ? -1 : 0));
      this.$store.commit("hideLoader");
    },
    listarLigas() {
      this.activaSelLiga = true;
      this.liga = null;

      this.ligas = this.getLeagues.filter(element => element.sport == this.otro);

      this.ligas.forEach(element => (element.sportName = element.name));
      this.ligas.sort((a, b) => (a.sportName > b.sportName ? 1 : b.sportName > a.sportName ? -1 : 0));
    },
    back() {
      this.$emit("changePage", "Home");
    },
    top() {
      $("html, #container-resultados").animate({ scrollTop: 0 }, 600);
    },
    detalleJugada() {
      this.verDetalle = !this.verDetalle;
    }
  },
  async created() {
    this.$store.commit("showLoader");

    if (this.getGames && this.getGames.length == 0) {
      this.gamesEmpty = false;
    }
    this.$store.commit("hideLoader");
  },
  async mounted() {
    if (this.getThemelight) {
      document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
    }

    this.hideChat();
  },
  beforeCreate() {
    this.idAnt = null;
    this.fechaAnt = null;
  },
  async beforeDestroy() {
    //await this.$store.dispatch("getGamesAsync");
    this.$store.commit("setPremio", 0);
    this.$store.commit("setApuestas", []);
    this.$store.commit("setRestriccionParley", []);
    this.$store.commit("setExcepcionParley", false);
    this.showChat();
  }
};
</script>

<style>
details > summary {
  list-style: none;
  outline: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

.fixed {
  position: fixed;
  top: 80%;
  left: 85%;
  z-index: 100;
}

.shade {
  font-size: 20px;
  background-color: rgba(217, 243, 252, 0.7);
}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-dark {
  background-color: #d4d4d4;
}
.text-sub {
  font-size: 0.9em !important;
  background-color: #545454;
  color: #e0e0e0;
}
.title-sports {
  font-size: 1.5em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
}
.boton-green {
  background-image: linear-gradient(-90deg, #6bff00, #0d6403);
  border: none;
  color: #ffffff !important;
  font-size: 1em !important;
  font-weight: 600;
  width: 9em;
  height: 2.5em;
  line-height: 1em;
}

.boton-blue {
  background-image: linear-gradient(-90deg, #728cfa, #2d4dd7);
  border: 0;
  color: #ffffff !important;
  font-size: 0.9em;
  font-weight: 600;
}
.boton-dark {
  color: #ffffff !important;
  background-color: rgb(90 90 90);
}

.btn-sport {
  width: 6em;
  height: 3em;
  border: none;
}

.boton-light {
  background-color: #5d75cb;
}
.act {
  background: linear-gradient(90deg, #f09819, #ff512f);
  color: #fff !important;
  font-weight: normal;
}

@media screen and (max-width: 359px) {
  .text-sub {
    font-size: 0.8em !important;
  }
}
@keyframes details-show {
  from {
    opacity: 0;
    transform: var(--details-translate, translateY(-0.5em));
  }
}
/* details[open] > *:not(summary) {
  animation: details-show 1000ms ease-in-out;
} */
.dark-bg {
  background-color: #404040;
}
.light-bg {
  background-color: #5d75cb;
}
.btn-bg {
  background-color: #32383e;
}
.btn-bg-light {
  /*background-color: #ebeefd;*/
  background-color: #ffffff;
}
.bg-header {
  background-color: #32383e;
  color: #ffffff;
}
.bg-header-light {
  background-color: #ebeefd;
  color: rgb(0, 31, 98);
}
.card-header-light {
  background-color: #ffffff;
  color: rgb(0, 31, 98);
}
.datepicker-custom input[type="text"] {
  border-color: #6c757d;
  color: #495057;
  border: 1px solid #ced4da;
  text-align: center;
  height: 25px;
  background-clip: padding-box;
  border-radius: 0.25rem;
}
.style-chooser .vs__dropdown-toggle {
  border: 1px solid #d6dbdb;
}
.style-chooser .vs__dropdown-menu {
  background: white;
  color: gray;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: black;
}
.v-select .vs__selected-options {
  flex-wrap: nowrap;
}
.style-chooser .vs__dropdown-option {
  color: black !important;
  font-weight: 500 !important;
}

.style-chooser .vs__select__autocomplete {
  background-color: #e6eaea;
}

.style-chooser .vs__dropdown-option--highlight {
  background-color: #e6eaea;
}
.details {
  font-size: 12px;
  font-weight: 500;
  background-color: #32383e;
  color: #ffffff;
  width: 100% !important;
}

.details-light {
  font-size: 12px;
  font-weight: 500;
  background-color: #ebeefd;
  color: #000000;
}

.titleGame {
  font-size: 1.2em;
  background-color: #212529;
  color: #ffffff;
}
.titleGame-light {
  font-size: 1.2em;
  background-color: #5d75cb;
  color: #ffffff;
}
.title {
  color: white;
  /* background-color: #212529; */
  background-color: #515a5a;
  font-size: 1.5em;
}
.title-light {
  color: white;
  background-color: #5d75cb;
  font-size: 1.5em;
}
.titleOdd {
  color: white;
  background-color: #212529;
  font-size: 15px;
}
.titleOdd-light {
  color: white;
  background-color: #5d75cb;
  font-size: 15px;
}
.btnOdd {
  background-color: #212529;
  color: #c7c7c7 !important;
}
.btnOdd-light {
  background-color: #f6f7ff;
  font-weight: 700;
  color: #000 !important;
}
.refOdd {
  background-color: #212529;
  color: #ffffff;
}
.refOdd-light {
  background-color: #f6f7ff;
  color: #000;
}
.btnDetailOdd {
  background-color: #212529;
  color: #c7c7c7 !important;
  height: 3em;
  font-size: 1.2em;
  font-weight: 600;
}
.btnDetailOdd-light {
  background-color: #5d75cb;
  color: #ffffff !important;
  height: 3em;
  font-size: 1.2em;
  font-weight: 600;
}
.btnTableOdd {
  height: 3em;
  background-color: #212529;
  font-weight: 600;
  color: #c7c7c7 !important;
}
.btnTableOdd-light {
  height: 3em;
  background-color: #5d75cb;
  font-weight: 700;
  color: #ffffff !important;
}
.btn-logro {
  background-color: #212529;
  font-weight: 600;
  width: 90%;
  color: #c7c7c7 !important;
}
.btn-logro-light {
  background-color: #5d75cb;
  font-weight: 600;
  width: 90%;
  color: #ffffff !important;
}

/*oddSel {
  color: white !important;
  background-color: #ff5500 !important;
  font-weight: bold !important;
}*/
button {
  box-shadow: none !important;
}
button.btn-base.border-green.btn-jugadas {
  padding: 2px !important;
  margin: 0;
  width: 100px;
}
button.btn-base.border-green.btn-jugadas > span {
  padding: 0.2em !important;
  font-size: 11px;
}
button.btn-base.border-green.btn-jugadas > span:hover {
  text-shadow: 2px 1px 3px black;
}
.apuesta-seleccionada {
  font-weight: 500 !important;
  text-shadow: 1px 1px 3px black !important;
  color: #ffffff !important;
  background-color: #3cb202 !important;
}

.goBottom {
  margin-bottom: 17em;
}
</style>
