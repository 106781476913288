var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!this.getIsMobilResolution && _vm.getTickets && _vm.getTickets.data)?_c('div',{staticClass:"container-tickets"},[_c('table',{class:{
      'table table-hover table-striped mb-0': true,
      'table-dark': !this.getThemelight,
      'table-light': this.getThemelight
    }},[_c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',{staticStyle:{"max-width":"120px","width":"120px"}},[_vm._v("Id")]),_c('th',{staticStyle:{"max-width":"120px","width":"120px"}},[_vm._v(_vm._s(_vm.$t("numero")))]),_c('th',{staticStyle:{"max-width":"200px","width":"200px"}},[_vm._v(_vm._s(_vm.$t("fecha")))]),_c('th',{staticStyle:{"max-width":"150px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t("numero")))]),_c('th',{staticStyle:{"max-width":"150px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t("premio")))]),_c('th',{staticStyle:{"max-width":"150px","width":"150px"}},[_vm._v("Estado")])])])]),_c('div',{staticStyle:{"height":"25.5em","overflow":"auto","margin-top":"-2px"}},[_c('table',{class:{
        table: true,
        'table-dark': !this.getThemelight,
        'table-light': this.getThemelight
      },staticStyle:{"font-size":"0.85em"}},[_c('tbody',_vm._l((_vm.getTickets.data),function(ticket,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.getTickets && _vm.getTickets.data),expression:"getTickets && getTickets.data"}],key:index,staticClass:"text-center tr-selector",attrs:{"data-index":index},on:{"click":function($event){return _vm.setTarget(ticket)}}},[_c('td',{staticStyle:{"max-width":"120px","width":"120px"}},[_c('b',[_vm._v(_vm._s(ticket.id))])]),_c('td',{staticClass:"txt-center",staticStyle:{"max-width":"120px","width":"120px"}},[_vm._v("\n            "+_vm._s(ticket.number)+"\n          ")]),_c('td',{staticClass:"txt-center",staticStyle:{"max-width":"200px","width":"200px"}},[_vm._v("\n            "+_vm._s(_vm.dateFormat(ticket.created_at))+"\n          ")]),_c('td',{staticClass:"text-right",staticStyle:{"max-width":"150px","width":"150px"}},[_c('b',[_vm._v(_vm._s(_vm.formatoMonto(ticket.amount)))])]),_c('td',{staticClass:"text-right",staticStyle:{"max-width":"150px","width":"150px"}},[_vm._v("\n            "+_vm._s(_vm.formatoMonto(ticket.amount_ind))+"\n          ")]),_c('td',{staticStyle:{"max-width":"150px","width":"150px"}},[_vm._v(_vm._s(ticket.status))])])}),0)]),_c('b-modal',{ref:"details-ticket",attrs:{"id":"details-ticket","size":"lg","content-class":"details-ticket container-tickets-interno","header-bg-variant":!this.getThemelight ? 'dark' : 'light',"body-bg-variant":!this.getThemelight ? 'dark' : 'light',"footer-bg-variant":!this.getThemelight ? 'dark' : 'light',"centered":"","scrollable":"","title":"DETALLE DEL TICKET","hide-footer":""}},[[_c('table',{class:{
            'table table-top-details': true,
            'table-dark': !this.getThemelight,
            'table-light': this.getThemelight
          },staticStyle:{"width":"100%"},attrs:{"cellpadding":"0","cellspacing":"0"}},[(this.ticketTarget)?_c('tbody',[_c('tr',[_c('td',[_vm._v("\n                "+_vm._s(_vm.$t("numero_ticket"))+": "),_c('b',[_vm._v(_vm._s(this.ticketTarget.number))])]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v("\n                "+_vm._s(_vm.$t("fecha"))+":\n                "),(this.ticketTarget.created_at)?_c('b',[_vm._v(_vm._s(this.dateFormat(this.ticketTarget.created_at)))]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("\n                "+_vm._s(_vm.$t("monto_total_apostado"))+":\n                "),(this.ticketTarget.amount_ind)?_c('b',{staticClass:"lbl-total-apostado"},[_vm._v("Bs "+_vm._s(this.formatoMonto(this.ticketTarget.amount_ind)))]):_vm._e()]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v("\n                "+_vm._s(_vm.$t("premio_total_estimado"))+" :\n                "),_c('b',[_vm._v("Bs "+_vm._s(_vm.formatoMonto(this.ticketTarget.prize)))])])]),_c('tr',[(this.getTipeTicket(this.ticketTarget.modalidad) == 'Directas')?_c('td',[_vm._v("\n                "+_vm._s(_vm.$t("monto_cada_jugada"))+":\n                "),_c('b',[_vm._v("Bs "+_vm._s(this.formatoMonto(this.ticketTarget.amount)))])]):_vm._e(),_c('td',{style:(this.getTipeTicket(this.ticketTarget.modalidad) == 'Directas'
                    ? 'text-align: right;'
                    : 'text-align: left;'),attrs:{"colspan":this.getTipeTicket(this.ticketTarget.modalidad) == 'Directas' ? 1 : 2}},[_vm._v("\n                Ticket "+_vm._s(_vm.$t("tipo"))+":\n                "),_c('b',[_vm._v(_vm._s(this.getTipeTicket(this.ticketTarget.modalidad)))])])])]):_vm._e()]),_c('table',{class:{
            'table table-cuerpo-details': true,
            'table-dark': !this.getThemelight,
            'table-light': this.getThemelight
          },staticStyle:{"width":"100%"},attrs:{"cellpadding":"0","cellspacing":"0","id":"details"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("#")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t("fecha")))]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t("equipo")))]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t("apuestas")))]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t("logro")))]),(_vm.getTipeTicket(_vm.ticketTarget.modalidad) == 'Directas')?_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("\n                "+_vm._s(_vm.$t("premio"))+"\n              ")]):_vm._e(),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("ESTATUS")])])]),_c('tbody',_vm._l((this.ticketTarget.odds),function(odd,index){return _c('tr',{key:index},[_c('th',[_vm._v(_vm._s(odd.n))]),_c('td',[_vm._v(_vm._s(_vm.dateFormat(odd.time)))]),_c('td',{staticStyle:{"text-align":"left"}},[_c('p',[_vm._v(_vm._s(odd.league_name)+": "),_c('b',{domProps:{"innerHTML":_vm._s(odd.teams)}})])]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v("\n                "+_vm._s(odd.type)+"\n                ("+_vm._s(odd.final)+")\n                ")]),_c('td',{staticStyle:{"text-align":"right"}},[_c('div',{staticClass:"fraction-container",domProps:{"innerHTML":_vm._s(("<div>" + (_vm.formatoPrefijo(odd.odd)) + " </div><div>" + (_vm.toFraction(odd.factor)) + " </div>"))}})]),(_vm.getTipeTicket(_vm.ticketTarget.modalidad) == 'Directas')?_c('td',{staticStyle:{"text-align":"right"}},[_vm._v("\n                "+_vm._s(_vm.ticketTarget.prize)+"\n              ")]):_vm._e(),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(odd.status))])])}),0)]),_c('b-button',{staticClass:"mt-3 float-right btn-cerrar-modal-detail btn-secondary",on:{"click":_vm.hideModal}},[_vm._v(_vm._s(_vm.$t("premio")))])]],2)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }