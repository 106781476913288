<template>
  <div class="container-estado-cuenta">
    <table class="table table-hover table-striped table-light" v-if="!this.getIsMobilResolution">
      <thead>
        <tr class="text-center">
          <!-- <th width="5%" class="mailbox-star"></th> -->
          <th width="20%">{{ $t("fecha") }}</th>
          <th width="15%">TICKET</th>
          <th width="20%">{{ $t("tipo") }}</th>
          <th>{{ $t("monto") }}</th>
          <th>{{ $t("saldo") }}</th>
        </tr>
      </thead>
      <tbody v-if="this.getBalaces && this.getBalaces.data">
        <tr
          v-for="(balance, index) in getBalaces.data"
          :key="index"
          v-show="getBalaces && getBalaces.data && isInPage(index)"
          class="text-center"
        >
          <td width="20%">{{ dateFormat(balance.fecha) }}</td>
          <td width="15%">
            <b>{{ balance.ticket }}</b>
          </td>
          <td width="20%">{{ balance.tipo }}</td>
          <td>{{ getSimboloMoneda }}{{ formatoMonto(balance.monto) }}</td>
          <td>{{ getSimboloMoneda }}{{ formatoMonto(balance.saldo) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import BalancesTableMovil from "../movil/BalancesTableMovil.vue";
import moment from "moment";
import { paginatorTable } from "../../mixins/paginatorTableMixin.js";

export default {
  name: "EstadoCuenta",
  // components: {
  //   BalancesTableMovil
  // },
  props: {
    page: Number
  },
  mixins: [paginatorTable],
  computed: {
    ...mapGetters(["getThemelight", "getBalaces", "getIsMobilResolution", "getSimboloMoneda"])
  },
  methods: {
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm a");
      }
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  data() {
    return {
      totalResults: 0,
      perPage: 10
    };
  }
};
</script>

<style scoped>
#cuenta > div > table > tbody > tr:hover,
.table.table-light.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: #3c507c !important;
  /* cursor: pointer; */
}

.table.table-light.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(229 229 229 / 58%);
}

#cuenta > div > table > tbody > tr:hover td,
#cuenta > div > table > tbody > tr:hover td a {
  color: #fff;
}
</style>
