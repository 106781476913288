var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.getUser)?_c('section',{class:{
    'animate__animated animate__backInUp': true,
    'light-theme bg-light': this.getThemelight,
    'bg-dark': !this.getThemelight
  },attrs:{"id":"container-mensajeria"}},[(!this.getIsMobilResolution)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{class:{
          'col-12': _vm.isMobilResolution(),
          'col-12': !_vm.isMobilResolution()
        }},[_c('div',{class:{
            'card card-primary card-outline': true,
            'light-theme': this.getThemelight,
            'bg-dark': !this.getThemelight
          }},[_c('div',{staticClass:"card-header"},[(!_vm.isMobilResolution())?_c('h3',{staticClass:"card-title capitalize"},[_vm._v("\n              "+_vm._s(_vm.$t("mensajeria"))+"\n            ")]):_vm._e(),(!_vm.isMobilResolution())?_c('div',{staticClass:"text-justify font5 container-html"},[_c('div',{staticClass:"mailbox-controls"},[_c('div',{staticClass:"float-left"},[_vm._v("\n                  "+_vm._s(_vm.$t("limite"))+":\n                  "),_c('targetSelector',{class:{ 'bg-dark': !this.getThemelight },attrs:{"selectTarget":_vm.limitResults},on:{"changeSelection":_vm.updateSizeLimit}}),_vm._v("\n                  "+_vm._s(_vm.$t("pagina"))+": "+_vm._s(this.getCurrentPage())+" de "+_vm._s(this.getTotalPages())+" "+_vm._s(_vm.$t("paginas"))+"\n                ")],1),_c('div',{staticClass:"float-right"},[_vm._v("\n                  "+_vm._s(_vm.$t("total_de_registros"))+" "+_vm._s(this.totalResults)+"\n                  "),_c('div',{staticClass:"btn-group"},[_c('button',{class:{
                        'btn btn-default btn-sm': true,
                        disabled: this.getTotalPages() == 1 || this.pageResults == 1
                      },attrs:{"type":"button"},on:{"click":_vm.downPageResults}},[_c('i',{staticClass:"fas fa-chevron-left"})]),(this.getMensajeria && this.getMensajeria.page && this.getMensajeria.pages)?_c('button',{class:{
                        'btn btn-default btn-sm': true,
                        disabled: this.getMensajeria.page >= this.getMensajeria.pages
                      },attrs:{"type":"button"},on:{"click":_vm.upPageResults}},[_c('i',{staticClass:"fas fa-chevron-right"})]):_vm._e()])])])]):_vm._e()]),_c('div',{staticClass:"card-body"},[_c('MensajeriaTable',{on:{"changeTarget":_vm.changeTarget}}),(this.totalResults != null && this.pageResults != null && this.limitResults != null)?_c('b-pagination',{class:{
                'customPagination float-right mt-2': true,
                light: this.getThemelight,
                dark: !this.getThemelight
              },attrs:{"size":"sm","per-page":this.limitResults,"limit":3,"total-rows":parseInt(this.totalResults),"custom":"size","table-variant":this.getThemelight ? 'light' : 'dark',"first-number":"","last-number":""},on:{"input":_vm.changePage},model:{value:(_vm.pageResults),callback:function ($$v) {_vm.pageResults=$$v},expression:"pageResults"}}):_vm._e()],1)])])])]):_c('div',[_c('MensajeriaMovil',{on:{"changeTarget":_vm.changeTarget}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }