// This is just an example,
// so you can safely delete all default props below

export default {
  inicio: "Inicio",
  Jugar: "Jugar",
  iniciar_sesion_registro: "INICIAR SESIÓN / REGISTRO",
  iniciar_sesion: "INICIAR SESIÓN",
  contacto: "¡CONTÁCTANOS!",
  recuperar_contraseña: "Recuperar Contraseña",
  usuario: "Usuario",
  contraseña: "Contraseña",
  recordar_datos_de_acceso: "Recordar datos de acceso",
  olvide_mi_contraseña: "Olvidé mi contraseña",
  correo_electrónico: "Correo electrónico",
  enviar_correo: "Enviar correo",
  registrarse: "Registrarse",
  terminos_condi: "Acepto los térrminos y condiciones",
  terminos_condi_leer: "Leer Bonos: Términos y Condiciones",
  deportes: "Deportes",
  juegos_virtuales: "Juegos Virtuales",
  sorteos_tradicionales: "Sorteos Tradicionales",
  mi_cuenta: "Mi Cuenta",
  preguntas_frecuentes: "Preguntas Fecuentes",
  como_apostar: "¿Cómo Apostar?",
  tipos_de_apuestas: "Tipos de apuestas",
  terminos_y_condiciones: "Términos y Condiciones",
  bonos_terminos_y_condiciones: "Bonos Términos y Condiciones",
  calculadora: "Calculadora",
  resultados: "Resultados",
  recargas: "Recargas",
  saldo: "Saldo",
  bono: "Bono",
  americano: "Americano",
  decimal: "Decimal",
  seleccion_de_apuestas: "Selección de Apuestas",
  directas: "Directa",
  generar_apuesta: "Generar Apuesta",
  monto_de_la_apuesta: "Monto de la Apuesta",
  error_en_monto_por_favor_ingrese_un_monto_valido: "Error en monto Por favor ingrese un monto valido",
  premio: "Premio",
  cambiar_contraseña: "CAMBIAR CONTRASEÑA",
  nueva_contraseña: "Nueva Contraseña",
  confirmar_contraseña: "Confirmar contraseña",
  actualizar_contraseña: "Actualizar Contraseña",
  entradas: "Entradas",
  nombre_y_apellido: "Nombre y Apellido",
  cedula: "Cédula",
  ciudad: "Ciudad",
  prefijo_internacional: "Pref Internacional",
  codigo_de_area: "Código de Área",
  telefono_Fijo_celular: "Teléfono Fijo / Celular",
  notificaciones_via_sms: "Desea recibir notificaciones via SMS",
  notificaciones_via_sms_acepta: "Acepta notificaciones via SMS?",
  guardar: "GUARDAR",
  footer1:
    "Sellatuparley.com es Regulado para la Republica Bolivariana de Venezuela por SUNAHIP y CONALOT Nro. de licencia.",
  footer2:
    "Sellatuparley.com opera bajo Games & More B.V Company No.149948, Fransche Bloemweg 4, Curacao, Licence Nr 1668/JAZ and Power Gaming Ltd (Abacus Business Centre, Level 1Dun Karm Street,",
  footer3: "B'Kara Bypass Birkirkara - Company nr C77981) as Payment Processor",
  footer4: "Todos los derechos reservados",
  registro_de_usuario: "REGISTRO DE USUARIO",
  moneda: "Moneda",
  nombre_requerido: "Nombre es requerido con mínimo de 3 caracteres",
  fecha_de_nacimiento: "Fecha de Nacimiento",
  identificacion: "Cédula de identidad",
  cedula_validacion: "Solo ingrese caracteres numericos entre 1 y 100 millones",
  ciudad_requerida: "Debe ingresar el nombre de la ciudad.",
  celular_validar: "Debe ingresar almenos 7 digitos.",
  correo_validar: "Debe ser un formato de e-mail valido.",
  contraseña_validar1: "Debe contener almenos 8 caracteres.",
  contraseña_validar2: "Ambas Contraseña deben ser iguales.",
  repita_contraseña: "Repita Contraseña",
  codigo_registro: "Código para registro (solo en caso de poseer)",
  verifique_datos: "Por favor, verifique los siguientes datos:",
  nombre: "Nombre",
  registrarme: "REGISTRARME",
  agregar: "Agregar",
  calcular: "Calcular",
  resetear: "Resetear",
  monto: "Monto",
  cuotas: "Cuotas",
  cerrar: "Cerrar",
  bienvenido_1: "Bienvenido a nuestro",
  bienvenido_2: "centro de ayuda",
  telefono: "Teléfonos",
  datos_del_cliente: "Datos del Cliente",
  direccion: "Dirección",
  pais: "País",
  codigo_postal: "Código Postal",
  tipo_de_transaccion: "Tipo de transacción",
  saldo_diferido: "Saldo Diferido",
  modulos: "Modulos",
  reglamento_general: "REGLAMENTO GENERAL",
  lotto_activo: "LOTTO ACTIVO",
  rueda_de_la_fortuna: "RUEDA DE LA FORTUNA",
  apuestas: "Apuestas",
  error_monto: "Error en monto Por favor ingrese un monto valido",
  fecha: "Fecha",
  tipo: "Tipo",
  juegos_destacados: "JUEGOS DESTACADOS",
  asunto: "Asunto",
  numero: "Numero",
  numero_ticket: "Numero del ticket",
  monto_total_apostado: "Monto Total Apostado",
  premio_total_estimado: "Premio Total Estimado",
  equipo: "Equipo",
  logro: "Logro",
  monto_cada_jugada: "Monto Cada Jugada",
  estado_de_cuenta: "Estado de cuenta",
  nota: "Nota",
  mis_cuentas_bancarias: "Mis cuentas bancarias",
  reporte_semanal: "Reporte Semanal",
  editar_perfil: "Editar perfil",
  cambio_clave: "Cambio de clave",
  banco: "Banco",
  cuentas_bancarias: "Cuentas bancarias",
  tipo_de_cuenta: "Tipo de cuenta",
  numero_de_cuenta: "Número de cuenta",
  agregar_cuenta: "Agregar cuenta",
  regresar: "Regresar",
  accion: "Acción",
  hipismo_internacional_y_nacional: "HIPISMO INTERNACIONAL Y NACIONAL",
  ocultar: "OCULTAR",
  ver_tickets_jugados: "Ver tickets jugados",
  hoy: "Hoy",
  todas: "Todas",
  videos_1: "Debe tener apuestas el día de hoy para poder ver los videos",
  minimizar: "Minimizar",
  mensaje: "Mensaje",
  respuesta: "Respuesta",
  enviar: "Enviar",
  cancelar: "Cancelar",
  mensajeria: "Mensajeria",
  limite: "Limite",
  pagina: "Pagina",
  paginas: "Paginas",
  total_de_registros: "Total de Registros",
  codigo: "Código",
  banco_origen: "Banco órigen",
  banco_destino: "Banco destino",
  numero_de_referencia: "Número de referencia",
  fecha_de_la_transaccion: "Fecha de la transacción",
  optar_por_bonos_de_recargas: "Optar por bonos de recargas",
  ver: "Ver",
  codigo_de_confirmacion_enviado_a_su_telefono: "Codigo de confirmacion enviado a su telefono",
  pasarela: "Pasarela"
};
