<template>
  <nav
    id="menu_id_web"
    class="navbar animate__animated navbar-expand-lg navbar-light bar-solid"
    style="z-index: 10; height: 6em; padding: 0 5px"
  >
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarToggler"
      aria-controls="navbarToggler"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarToggler">
      <img
        src="/img/logo/img2x.png"
        style="cursor: pointer"
        v-on:click="toPage('Home')"
        alt="logo x2"
        width="96px"
        height="96px"
      />
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0 bar-options-jugar">
        <li v-if="isLoged" class="saldo-container saldos">
          <div class="item-box">
            {{ $t("saldo") }}: <span class="saldo-text"> {{ getUser.Coin.moneda }} </span>
            <b class="saldo-monto" style="color: black">{{ formatNumeric(getUser.credit) }}</b>
          </div>
          <div class="item-box">
            {{ $t("bono") }}: <span class="saldo-text"> {{ getUser.Coin.moneda }} </span>
            <b class="saldo-monto" style="color: black">{{ formatNumeric(getUser.bonus) }}</b>
          </div>
        </li>
        <li class="saldo-container refresh-btn" @click="actSaldo()" v-if="isLoged">
          <i class="fas fa-sync ref" id="reload" style="color: black"></i>
        </li>

        <li
          v-if="isLoged"
          :class="{
            'nav-item': true,
            dropdown: true
          }"
        >
          <a
            class="nav-link dropdown-toggle opacity-bg color-boton"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            id="btn-jugar"
            style="text-decoration: none"
            >{{ $t("Jugar") }}</a
          >
          <div class="dropdown-menu menu-opciones-jugar">
            <a class="dropdown-item" href="#/Deportes" v-on:click="toPage('Juegos')">{{ $t("deportes") }}</a>
            <a class="dropdown-item" href="#/LiveBetting" v-on:click="toPage('LiveBetting')">Live Betting</a>
            <a class="dropdown-item" href="#" v-on:click="toPage('PropsBetting')">Props Betting</a>
            <a class="dropdown-item" href="#/Hipismo" v-on:click="toPage('Hipismo')">Hipismo</a>
            <a class="dropdown-item" href="#" v-on:click="toPage('Slots')">Slots</a>
            <a class="dropdown-item" href="#/CasinoLive" v-on:click="toPage('CasinoLive')">Casino Live</a>
            <a class="dropdown-item" href="#/Poker" v-on:click="toPage('Poker')">Poker Online</a>
            <a class="dropdown-item" href="#/JuegosVirtuales" v-on:click="toPage('JuegosVirtuales')">{{
              $t("juegos_virtuales")
            }}</a>
            <a
              class="dropdown-item"
              href="#/Spin500"
              v-on:click="toPage('Spin500')"
              v-if="!contieneUSD(getUser.Coin.moneda)"
              >Spin 500 Live</a
            >
            <a
              class="dropdown-item"
              href="#/SorteosTradicionales"
              v-on:click="toPage('SorteosTradicionales')"
              v-if="!contieneUSD(getUser.Coin.moneda)"
              >{{ $t("sorteos_tradicionales") }}</a
            >
            <a class="dropdown-item" href="#/SorteosTradicionales" v-on:click="toPage('TaquillaLayout')"
              >Taquilla test</a
            >
          </div>
        </li>

        <li v-if="isLoged">
          <a class="nav-link btn-recargas" href="#/" @click="openModalMyAccount"
            ><i class="fa fa-plus-circle"></i> {{ $t("recargas") }}</a
          >
        </li>
      </ul>
      <ul class="navbar-nav ml-auto barra-mi-cuenta-salir">
        <li
          :class="{
            'nav-item': true
          }"
        >
          <a
            v-if="isLoged"
            class="nav-link link-my-account"
            href="#"
            style="text-decoration: none"
            @click="openTab('EditPerfil')"
          >
            <!--
          data-toggle="modal"
          data-target="#dashboardModal" 
          -->
            <i class="far fa-user"> </i> {{ $t("mi_cuenta") }} ({{ getUser.username }})
          </a>
        </li>

        <!-- &#10067; -->
        <li v-if="isLoged" style="position: relative">
          <span
            v-if="this.getMensajeria"
            :class="{
              badge: true,
              'badge-success': true,
              'navbar-badge': true,
              'text-light': true,
              'mail-sin-leer': this.getMensajeria.not_read
            }"
            style="position: absolute; top: -0.2em; right: -1em; z-index: 100"
            >{{ this.getMensajeria.not_read }}</span
          >
          <a
            href="#mensajeria"
            @mouseover="hoverMessage = true"
            @mouseleave="hoverMessage = false"
            v-on:click="toPage('Mensajeria')"
            ><i
              :class="{
                'fa fa-envelope mail animate__animated': true,
                'hoverMessage animate__headShake': hoverMessage
              }"
            ></i
          ></a>
        </li>

        <li
          :class="{
            'nav-item': true
          }"
        >
          <a
            v-if="!isLoged"
            class="nav-link"
            id="btn-login"
            href="#"
            style="text-decoration: none"
            data-toggle="modal"
            data-target="#loginModal"
            @click="iniSession"
          >
            <i class="far fa-user"> </i>{{ $t("iniciar_sesion_registro") }}
          </a>
        </li>

        <li
          :class="{
            'nav-item': true
          }"
          v-if="!isLoged"
        >
          <a
            class="nav-link opacity-bg color-boton"
            href="#/"
            style="text-decoration: none"
            data-toggle="modal"
            data-target="#loginModal"
            id="btn-jugar-2"
            @click="iniSession"
            ><i class="fas fa-play mr-2"></i>{{ $t("Jugar") }}</a
          >
        </li>

        <li
          :class="{
            'nav-item': true,
            'margen-lef-caja': true
          }"
        >
          <a
            class="nav-link margen-lef"
            style="text-decoration: none"
            href="#"
            data-toggle="modal"
            data-target="#contactodModal"
            ><i class="fas fa-phone-alt color-contacto"></i
          ></a>
        </li>
        <Idioma />

        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#"
            id="ayudaDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style="text-decoration: none"
          >
            <i style="font-size: 24px" class="far fa-question-circle"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right opciones-ayuda animate__animated animate__fadeIn"
            aria-labelledby="ayudaDropdown"
          >
            <a
              :class="{
                'dropdown-item': true,
                'opt-active': getPage == 'Preguntas'
              }"
              href="#"
              v-on:click="toPage('Preguntas')"
              >{{ $t("preguntas_frecuentes") }}</a
            >
            <a
              :class="{
                'dropdown-item': true,
                'opt-active': getPage == 'ComoApostar'
              }"
              v-on:click="toPage('ComoApostar')"
              href="#"
              >{{ $t("como_apostar") }}</a
            >
            <a
              :class="{
                'dropdown-item': true,
                'opt-active': getPage == 'TiposApuestas'
              }"
              href="#"
              v-on:click="toPage('TiposApuestas')"
              >{{ $t("tipos_de_apuestas") }}</a
            >
            <div class="dropdown-divider"></div>
            <a
              :class="{
                'dropdown-item': true,
                'opt-active': getPage == 'Terminos'
              }"
              href="#"
              v-on:click="toPage('Terminos')"
              >{{ $t("terminos_y_condiciones") }}</a
            >
            <a
              class="dropdown-item"
              :class="{
                'dropdown-item': true,
                'opt-active': getPage == 'BonusTermsConswitch-themeditions'
              }"
              href="#"
              v-on:click="toPage('BonusTermsConditions')"
              >{{ $t("bonos_terminos_y_condiciones") }}</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              :class="{
                'dropdown-item': true
              }"
              href="#"
              data-toggle="modal"
              data-target="#calculadoraWeb"
              >{{ $t("calculadora") }}</a
            >

            <a
              class="dropdown-item"
              :class="{
                'dropdown-item': true,
                'opt-active': getPage == 'BonusTermsConswitch-themeditions'
              }"
              href="#"
              v-on:click="toPage('resultados')"
              >{{ $t("resultados") }}</a
            >
          </div>
        </li>
      </ul>

      <div class="container-socaial">
        <b-form-checkbox v-model="cambioTema" name="check-button" switch class="switch-theme">
          <i class="fas fa-sun" style="cursor: pointer" v-if="cambioTema"></i>
          <i class="fas fa-moon" style="cursor: pointer" v-else></i>
        </b-form-checkbox>
      </div>
      <Notificaciones v-if="isLoged" />
    </div>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import { essentialsData } from "../mixins/essentialsDataMixin.js";
import Notificaciones from "../pages/Notificaciones.vue";
import JQ from "jquery";
import moment from "moment";
import Idioma from "./Idioma.vue";
export default {
  name: "MenuWeb",
  mixins: [essentialsData],
  components: {
    Notificaciones,
    Idioma
  },
  props: {
    appdata: Object,
    account: Object
  },
  computed: {
    isLoged() {
      return this.getUser && this.getUser != null && this.getUser != undefined ? true : false;
    },
    ...mapGetters([
      "getUser",
      "getPage",
      "getMensajeria",
      "getCuentasBancosCMS",
      "getCenterBanks",
      "getCenterAccounts",
      "getThemelight"
    ])
  },
  mounted() {
    // this.$i18n.locale = this.lang;

    if (this.getThemelight) {
      document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
      this.cambioTema = true;
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
      this.cambioTema = false;
    }
  },
  watch: {
    cambioTema(v) {
      this.$store.commit("setThemelight", v);
      if (this.getThemelight) {
        document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
        this.cambioTema = true;
      } else {
        document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
        this.cambioTema = false;
      }
    }
  },
  methods: {
    async openModalMyAccount() {
      this.$store.commit("showLoader");

      if (!this.getCenterBanks || !this.getCenterAccounts) {
        await this.$store.dispatch("getCenterAccountsBanksAsync");
      }

      if (!this.getCuentasBancosCMS) {
        await this.$store.dispatch("getData", {
          url: "/api/v1/getCuentaBanco",
          set: "setCuentasBancosCMS"
        });
      }

      await this.$store.dispatch("searchUserAccountData");

      this.$store.commit("hideLoader");
      this.$bvModal.show("DetailsAccountsModal");
      this.$store.dispatch("checkSession");
      return;
    },
    actSaldo() {
      JQ("#reload").toggleClass("ref-focus");
      this.$store.dispatch("targetUserAsync");
    },
    iniSession: function() {
      setTimeout(() => {
        if (document.getElementById("inputUser")) {
          document.getElementById("inputUser").focus();
        }
      }, 500);
    },
    toPage: async function(page) {
      if (page == "Juegos") {
        this.$store.commit("showLoader");
        await this.$store.dispatch("enableSecurity");

        if ([5, 7, 8].includes(this.getUser.center_id)) {
          page = "LiveBetting";
        }

        if (this.$store.state.user && this.$store.state.token && this.$store.state.token.access_token) {
          await this.$store.dispatch("getLeaguesAsync");
          await this.$store.dispatch("getVideoAsync");
          // que por defecto cargue en la fecha de hoy requerimiento
          const opciones = { date: moment().format("YYYY-MM-DD"), customDate: true };
          await this.$store.dispatch("searchSportsLeaguesAsync", opciones);
          await this.$store.dispatch("getGamesAsync");
        }

        this.$store.commit("hideLoader");
      }

      this.$emit("changePage", page);
    },
    openTab(val) {
      this.$store.dispatch("checkSession");
      this.$store.commit("setPestana", val);

      this.openModalMyAccount();
    },
    logOut: function() {
      this.$emit("changePage", "Home");
      this.$store.dispatch("logOut");
    }
  },
  data() {
    return {
      hoverQuestion: false,
      hoverMessage: false,
      cambioTema: true
    };
  }
};
</script>

<style>
#btn-jugar {
  width: 110px;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  padding-right: 15px;
  padding-left: 15px;
}
.menu-opciones-jugar {
  width: 220px;
  position: absolute;
  margin-top: 10px;
  position: absolute !important;
}
.container-btn-salir {
  display: flex;
  flex-direction: row-reverse;
}
.btns-recarga-retiro a {
  text-decoration: none;
  padding: 3px 10px;
  margin: 0;
  min-width: 120px;
}
.bar-options-jugar {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  margin-block: unset;
  flex-direction: row;
}

.barra-mi-cuenta-salir {
  margin: 0px;
  padding: 0px;
  border: none;
  display: flex;
  flex-wrap: nowrap;
}

.container-socaial {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  align-items: center;
}
.container-socaial ul {
  min-width: 100%;
}
#btn-jugar:hover {
  background: linear-gradient(0deg, #f09819, #ff512f) !important;
}
#btn-jugar-2 {
  border-radius: 3px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 7px;
}
#btn-jugar-2:hover {
  background: linear-gradient(0deg, #f09819, #ff512f) !important;
}
#btn-jugar:focus-visible,
#ayudaDropdown:focus-visible {
  outline: none;
}
/* Estylos del menu jugar por si lo piden
.menu-opciones-jugar{  
    background-color: rgba(0,0,0,0.77);
    color: #fff;
}
.menu-opciones-jugar a{ 
    color: #fff;
}
.menu-opciones-jugar a:active{
  background: linear-gradient(90deg, #f09819, #ff512f)!important;
}
*/
#ayudaDropdown > i {
  margin: 0px !important;
  padding: 0px !important;
}
/*
  top: 90px !important;
  right: 250px !important;
  right: 255px !important;
*/
.opciones-ayuda {
  top: 75px !important;
}
.link-my-account {
  text-align: center;
  max-width: 150px;
}
.bar-solid {
  /* background-color: #000 !important; */
  background-color: #001f62 !important;
}

.btn-recargas {
  background: #007eff;
  color: #fff !important;
  border-radius: 7px;
  font-size: 0.85em;
  font-weight: 600;
  animation-duration: 0.3s;
  transition-duration: 0.3s;
}
a.nav-link.btn-recargas:hover {
  /* background: linear-gradient(0deg, #2070c0, #0080ff88) !important; */
}
/*
  line-height: 3em;
  margin: 2em 1.3em;
  padding: 2em 1.5em;
  text-align: center;  
  */
.saldo-container.saldos {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 7px;
  font-weight: 300;
  background: #fff;
  display: flex;
  color: #000;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 50% !important;
  margin: 1em;
  padding: 0;
  min-width: 150px;
  justify-content: space-evenly;
  cursor: pointer;
}
.saldo-container.saldos div.item-box {
  margin: 0;
  padding: 0 0.5em;
  max-height: 50%;
  width: 100%;
  text-align: left;
  align-items: center;
}
.saldo-container.refresh-btn {
  background-color: #fff;
  border-radius: 3px;
  padding: 7px;
}
.saldo-container.refresh-btn:hover {
  background-color: #ebf1ffcf;
}
.refresh-btn:active {
  background: linear-gradient(-90deg, #6bff00, #0d6403) !important;
  cursor: pointer;
  transform: scale(1);
  border-radius: 7px;
  margin: 0;
  border: none;
}
.ref {
  cursor: pointer;
  transition: transform 1s ease-in-out;
}
.ref-focus {
  transform: rotate(360deg);
}
.recarga-container {
  text-align: center;
  line-height: 3em;
  padding: 0 1.5em;
  background-color: rgba(255, 255, 255, 0.05);
  margin: 0 1.3em;
  border-radius: 7px;
  font-weight: 300;
  background: #0068ff;
  margin-left: -20px;
  cursor: pointer;
}
.nav.col-md-1.justify-content-end.list-unstyled.d-flex {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-evenly !important;
  width: 100%;
  align-items: center;
  margin: 0;
  padding: 0;
}
.nav.col-md-1.justify-content-end.list-unstyled.d-flex li > a > i {
  font-size: 1.2em;
  transition: 200ms linear;
}
.nav.col-md-1.justify-content-end.list-unstyled.d-flex li a:hover,
.nav.col-md-1.justify-content-end.list-unstyled.d-flex li a:hover i {
  color: #fff !important;
}
div#navbarToggler {
  height: 100%;
  display: flex !important;
  align-items: center;
}

/*div#navbarToggler img,*/
div#navbarToggler ul {
  height: 100%;
  align-items: center;
}
.custom-switch .custom-control-label::before {
  cursor: pointer !important;
}
</style>

<style scoped="scoped">
div#navbarToggler .nav-link {
  display: flex;
  align-items: baseline;
}
div#navbarToggler .nav-link > i {
  margin-right: 5px;
}
.recarga-container {
  height: 35px !important;
  padding: 0.01em 0.5em;
  margin: 0px 0.2em;
  display: flex;
  align-items: center;
}
div#navbarToggler a.nav-link.dropdown-toggle {
  height: 35px;
  align-items: center;
  border-radius: 7px;
}
.navbarToggler img {
  max-height: 80px;
}
.mail {
  color: #fff;
  font-size: 1.5em;
  line-height: 1.5;
}
.mail-sin-leer {
  background: red !important;
}
.badge-success {
  background: blue;
}
/* .btn-base {
  min-width: 80px; 
} */
.opacity-bg {
  /*
  background-color: rgba(0, 0, 0, 0.7) !important;
  */
  background-color: #dc1507e3 !important;
  line-height: initial;
  font-weight: 600;
}
.custom-question {
  background-color: transparent !important;
  min-width: 1.75em;
  color: #fff !important;
  text-align: center;
  font-weight: 500;
  font-size: 1.5em;
  margin: 0;
  padding: 0;
  border-radius: 50% !important;
}
.custom-question:hover {
  text-decoration: none !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 50% !important;
  background: rgb(238, 175, 10);
  background: linear-gradient(90deg, rgba(238, 175, 10, 1) 0%, rgba(193, 134, 40, 1) 14%, rgba(255, 225, 89, 1) 100%);
}
.opt-active {
  background-color: #007bff;
  color: #fff !important;
}
.li-margen {
  width: 1px;
}
.li-margen > a:hover {
  background-color: transparent !important;
}
.li-twitter > a:hover {
  background-color: transparent !important;
}
.marquesina {
  background: black;
  height: 100px;
}
@media (max-width: 600px) {
  .marquesina {
    display: none;
  }
}
.saldo-text {
  color: #001f62;
  font-family: arial;
  font-size: 12px;
  margin: 0px;
  padding-right: 2px;
  font-weight: 700;
}
.recarga-text {
  color: #fff;
  font-family: arial;
  font-size: 16px;
  margin: 0px;
  font-weight: 700;
}
@media (max-width: 1470px) {
  .recarga-text {
    font-size: 14px;
  }
  .recarga-container {
    height: 50px;
  }
  .saldo-container.refresh-btn {
    width: auto;
  }
  .saldo-text {
    font-size: 12px;
  }
  .saldo-monto {
    font-size: 1em;
    color: #333030 !important;
    margin: 0 0.2em 0.1em;
  }
}
@media (max-width: 1300px) {
  .recarga-container {
    display: none;
  }
  .saldo-container {
    /*display: none;*/
  }
}
.color-redes {
  color: #03fff6 !important;
  padding: 2px;
  font-size: 16px !important;
}
.color-boton {
  background: linear-gradient(90deg, #f09819, #ff512f);
  border-radius: 3px;
}
.color-boton:hover {
  background: linear-gradient(0deg, #f09819, #ff512f);
}
.container-socaial ul li a {
  padding: 2px !important;
  border-radius: 7px;
}
.container-socaial ul li a:hover {
  background-color: transparent;
}
</style>

<style scoped>
.nav-link:hover {
  background: #345298 !important;
}
.nav-link > a {
  text-decoration: none;
}
.color-contacto {
  margin-top: 3px;
  font-size: 18px !important;
}
.margen-lef {
  margin-right: -40px !important;
}
.margen-lef-caja {
  background: transparent !important;
}
</style>
