import { mapGetters } from "vuex";
import moment from "moment";
// import Swal from "sweetalert2";
export const juegosDetalles = {
  computed: {
    ...mapGetters([
      "getApuestas",
      "getFormatoTaquilla",
      "getIsMobilResolution",
      "getRestriccionesParley",
      "getTipoParley",
      "getExcepcionParley"
    ])
  },
  methods: {
    existenDuplicadosParley() {
      // si va a cambiar de directas a parley y tiene apuestas seleccionadas
      // y con restrinciones
      const ids_apuestas_seleccion_activa = this.getApuestas.flatMap(ap => ap.id.substr(-7, 7));
      const siExistenDuplicados = ids_apuestas_seleccion_activa =>
        ids_apuestas_seleccion_activa.filter((item, index) => ids_apuestas_seleccion_activa.indexOf(item) !== index);

      const casoDeDuplicados = siExistenDuplicados(ids_apuestas_seleccion_activa);
      return this.getTipoParley && casoDeDuplicados.length > 0;
    },
    cantidadRestrincionesEnParley() {
      if (!this.getTipoParley) {
        return 0;
      }

      let todos_los_ids_target_activo_apuestas = this.getApuestas.flatMap(ap => ap.id);
      let dataRestringida = JSON.parse(JSON.stringify(this.getRestriccionesParley));

      return dataRestringida.filter(r => r.includes(...todos_los_ids_target_activo_apuestas)).length;
    },
    hayAlgunaRestrincionParaparley(id) {
      let dataRestringida = JSON.parse(JSON.stringify(this.getRestriccionesParley));
      return (
        dataRestringida.filter(r => r.includes(id)).length > 0 && this.getApuestas.length > 1 && this.getExcepcionParley
      );
    },
    existeApuesta(v) {
      const busquedaApueta = this.getApuestas.flatMap(a => {
        return a.id == `${v}${this.gameDetails.id}` ? a : [];
      });
      return busquedaApueta.length > 0;
    },
    fueActualizada(v) {
      const busquedaApueta = this.getApuestas.flatMap(a => {
        return a.id == `${v}${this.gameDetails.id}` && a.update ? a : [];
      });
      return busquedaApueta.length > 0;
    },
    getDataContent(obj) {
      let txt = obj ? new String(obj).trim() : "";
      return txt;
    },
    async clickApuesta(v) {
      const apuesta = {
        id: `${v}${this.gameDetails.id}`,
        teams: this.gameDetails.teams,
        gameName: this.gameDetails.gameName,
        momento: moment(String(new Date())).format("DD-MM-YYYY hh:mm:ss A")
      };

      await this.$store.dispatch("clickApuesta", apuesta);

      // if (this.existenDuplicadosParley()) {
      //   const apuetasConElTargetEliminando = this.getApuestas.flatMap(a => {
      //     return a.id != apuesta.id ? a : [];
      //   });

      //   this.$store.commit("setApuestas", apuetasConElTargetEliminando);

      //   Swal.fire({
      //     position: "top",
      //     icon: "error",
      //     text: `Combinación no permitida para la modalidad PARLEY. Debe cambiar a Modalidad Directas.`
      //   });
      // }

      this.$store.dispatch("calculatePrize");
      //this.$store.commit("hideLoader");
    },
    clickOpen() {
      this.open = !this.open;
    },
    existPositionIn(indice, position) {
      return this.gameDetails &&
        this.gameDetails.odds &&
        this.gameDetails.odds[indice] &&
        this.gameDetails.odds[indice][position] &&
        this.gameDetails.odds[indice][position] != ""
        ? true
        : false;
    },
    getPosition(indice, position, mandatory = null) {
      if (
        this.gameDetails.odds &&
        this.gameDetails.odds[indice] != undefined &&
        this.gameDetails.odds[indice][position] != undefined
      ) {
        return !mandatory
          ? this.addSimbol(this.gameDetails.odds[indice][position][this.getFormatoTaquilla])
          : this.addSimbol(this.gameDetails.odds[indice][position][mandatory]);
      } else {
        return null;
      }
    },
    addSimbol(v) {
      const txt = String(v);
      const valor = txt.split(" ").length > 1 ? txt.split(" ")[1] : v;
      return parseFloat(valor) > 0 ? `+${valor}` : valor;
    },
    formatoFecha(f) {
      return moment(f, "DD/MM/YYYY HH:mm a").format("DD/MM/YYYY hh:mm A");
    }
  }
};
