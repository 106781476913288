<template>
  <div class="container-game">
    <details
      class="details card text-center"
      v-on:click="abrirDetalle(gameDetails.muestraTitulo)"
      :class="{ 'details-light text-dark': this.getThemelight, details: !this.getThemelight }"
    >
      <summary class="my-2">
        <div
          class="titleGame text-center"
          v-if="gameDetails.muestraTitulo || show"
          :class="{ 'titleGame-light': this.getThemelight }"
        >
          <p>{{ this.gameDetails.gameName }} ({{ this.gameDetails.gameDate }})</p>
        </div>
        <div
          class="row text-center"
          style="font-size: 0.9em"
          :class="{ 'bg-header-light font-weight-bold': this.getThemelight }"
        >
          <div class="col-4 border-right">
            {{ this.gameDetails.gameTime }}
          </div>
          <div class="col-4 border-right">
            <span> {{ this.gameDetails.teams[0].name }} (1) </span>
          </div>
          <div class="col-4">
            <span> {{ this.gameDetails.teams[1].name }} (2) </span>
          </div>
        </div>
      </summary>
      <table width="100%" id="oddsTable" class="mb-1">
        <tbody>
          <tr :id="this.gameDetails.id + 'FT1'" v-if="gameDetails.teams[0]['1X2FT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              1X2 FT <br />
              (A Ganar)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX1X' + this.gameDetails.id"
              @click="clickApuesta('FTX1X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX1X'),
                'btnOdd-light font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["1X2FT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX2X' + this.gameDetails.id"
              @click="clickApuesta('FTX2X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX2X'),
                'btnOdd-light font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["1X2FT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['EMPATE']"
          />
          <tr :id="this.gameDetails.id + 'FT2'" v-if="gameDetails.teams[0]['EMPATE']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              X (FT) <br />Empate
            </td>
            <td
              width="60%"
              colspan="2"
              class="btnOdd"
              :id="'FTX7X' + this.gameDetails.id"
              @click="clickApuesta('FTX7X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX7X'),
                'btnOdd-light font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["EMPATE"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['HDPFT']"
          />
          <tr :id="this.gameDetails.id + 'FT3'" v-if="gameDetails.teams[0]['HDPFT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              HDP (FT) <br />
              (Runline)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX3X' + this.gameDetails.id"
              @click="clickApuesta('FTX3X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX3X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["HDPFT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX4X' + this.gameDetails.id"
              @click="clickApuesta('FTX4X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX4X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["HDPFT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['EMPATERUN']"
          />
          <tr :id="this.gameDetails.id + 'FT4'" v-if="gameDetails.teams[0]['EMPATERUN']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              X HDP (FT) <br />(Empate Runline)
            </td>
            <td
              width="60%"
              colspan="2"
              class="btnOdd"
              :id="'FTX115X' + this.gameDetails.id"
              @click="clickApuesta('FTX115X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX115X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["EMPATERUN"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['TOTALESFT']"
          />
          <tr :id="this.gameDetails.id + 'FT5'" v-if="gameDetails.teams[0]['TOTALESFT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              TOTALES FT <br />
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX5X' + this.gameDetails.id"
              @click="clickApuesta('FTX5X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX5X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["TOTALESFT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX6X' + this.gameDetails.id"
              @click="clickApuesta('FTX6X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX6X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["TOTALESFT"] }}
            </td>
          </tr>
          <hr :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }" v-if="gameDetails.teams[0]['1X']" />
          <tr :id="this.gameDetails.id + 'FT6'" v-if="gameDetails.teams[0]['1X']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              DOBLE CHANCE FT (1X)
            </td>
            <td
              width="60%"
              colspan="2"
              class="btnOdd"
              :id="'FTX62X' + this.gameDetails.id"
              @click="clickApuesta('FTX62X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX62X'),
                'btnOdd-light font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["1X"] }}
            </td>
          </tr>
          <hr :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }" v-if="gameDetails.teams[0]['12']" />
          <tr :id="this.gameDetails.id + 'FT7'" v-if="gameDetails.teams[0]['12']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              DOBLE CHANCE FT (12)
            </td>
            <td
              width="60%"
              colspan="2"
              class="btnOdd"
              :id="'FTX63X' + this.gameDetails.id"
              @click="clickApuesta('FTX63X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX63X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["12"] }}
            </td>
          </tr>
          <hr :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }" v-if="gameDetails.teams[0]['X2']" />
          <tr :id="this.gameDetails.id + 'FT8'" v-if="gameDetails.teams[0]['X2']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              DOBLE CHANCE FT (X2)
            </td>
            <td
              width="60%"
              colspan="2"
              class="btnOdd"
              :id="'FTX64X' + this.gameDetails.id"
              @click="clickApuesta('FTX64X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX64X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["X2"] }}
            </td>
          </tr>
          <hr :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }" v-if="gameDetails.teams[0]['SI']" />
          <tr :id="this.gameDetails.id + 'FT9'" v-if="gameDetails.teams[0]['SI']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              AMBOS <br />ANOTAN <br />
              FT (GG/NG)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX49X' + this.gameDetails.id"
              @click="clickApuesta('FTX49X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX49X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["SI"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX50X' + this.gameDetails.id"
              @click="clickApuesta('FTX50X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX50X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["NO"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['HDPHT']"
          />
          <tr :id="this.gameDetails.id + 'HT1'" v-if="gameDetails.teams[0]['HDPHT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              HDP HT <br />
              (Runline)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX3X' + this.gameDetails.id"
              @click="clickApuesta('HTX3X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX3X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["HDPHT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX4X' + this.gameDetails.id"
              @click="clickApuesta('HTX4X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX4X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["HDPHT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['TOTALESHT']"
          />
          <tr :id="this.gameDetails.id + 'HT2'" v-if="gameDetails.teams[0]['TOTALESHT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              TOTALES HT
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX5X' + this.gameDetails.id"
              @click="clickApuesta('HTX5X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX5X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["TOTALESHT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX6X' + this.gameDetails.id"
              @click="clickApuesta('HTX6X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX6X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["TOTALESHT"] }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="my-2" v-if="gameDetails.hayFT && gameDetails.hayHT">
        <button
          class="btn boton-dark"
          v-on:click="detalle(gameDetails.id)"
          :id="'btnJugadas' + gameDetails.id"
          :class="{ 'boton-light': this.getThemelight }"
        >
          <i class="fa fa-plus"></i> Jugadas
        </button>
      </div>

      <div v-if="verDetalle" class="mb-1">
        <div class="card title mt-3" v-if="gameDetails.hayFT" :class="{ 'title-light': this.getThemelight }">
          JUEGO COMPLETO (FT)
        </div>

        <div v-if="this.gameDetails.totLocAlta">
          <div class="mt-2 titleOdd" :class="{ 'titleOdd-light': this.getThemelight }">Totales Local (1) (FT)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FTX12X' + this.gameDetails.id"
                @click="clickApuesta('FTX12X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX12X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totLocAlta }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FTX13X' + this.gameDetails.id"
                @click="clickApuesta('FTX13X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX13X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totLocBaja }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totVisAlta">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales Vistante (2) (FT)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FTX10X' + this.gameDetails.id"
                @click="clickApuesta('FTX10X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX10X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totVisAlta }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FTX11X' + this.gameDetails.id"
                @click="clickApuesta('FTX11X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX11X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totVisBaja }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.anotaLoc">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Anota Primero (FT)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX17X' + this.gameDetails.id"
                  @click="clickApuesta('FTX17X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX17X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.anotaLoc }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX114X' + this.gameDetails.id"
                  @click="clickApuesta('FTX114X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX114X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.anotaNin }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX16X' + this.gameDetails.id"
                  @click="clickApuesta('FTX16X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX16X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.anotaVis }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.parFT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Marcador Par/Impar (FT)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FTX132X' + this.gameDetails.id"
                @click="clickApuesta('FTX132X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX132X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.parFT }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FTX133X' + this.gameDetails.id"
                @click="clickApuesta('FTX133X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX133X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.imparFT }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.MC0x0">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Marcador Correcto (FT)</div>

          <table width="100%" class="mt-2" id="tblMarCor">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX32X' + this.gameDetails.id"
                  @click="clickApuesta('FTX32X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX32X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC0x0 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX43X' + this.gameDetails.id"
                  @click="clickApuesta('FTX43X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX43X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC1x4 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX35X' + this.gameDetails.id"
                  @click="clickApuesta('FTX35X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX35X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC3x3 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX36X' + this.gameDetails.id"
                  @click="clickApuesta('FTX36X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX36X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC0x1 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX21X' + this.gameDetails.id"
                  @click="clickApuesta('FTX21X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX21X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC2x0 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX29X' + this.gameDetails.id"
                  @click="clickApuesta('FTX29X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX29X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC3x4 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX37X' + this.gameDetails.id"
                  @click="clickApuesta('FTX37X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX37X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC0x2 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX22X' + this.gameDetails.id"
                  @click="clickApuesta('FTX22X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX22X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC2x1 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX26X' + this.gameDetails.id"
                  @click="clickApuesta('FTX26X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX26X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC4x0 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX39X' + this.gameDetails.id"
                  @click="clickApuesta('FTX39X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX39X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC0x3 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX34X' + this.gameDetails.id"
                  @click="clickApuesta('FTX34X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX34X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC2x2 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX27X' + this.gameDetails.id"
                  @click="clickApuesta('FTX27X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX27X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC4x1 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX42X' + this.gameDetails.id"
                  @click="clickApuesta('FTX42X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX42X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC0x4 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX41X' + this.gameDetails.id"
                  @click="clickApuesta('FTX41X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX41X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC2x3 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX28X' + this.gameDetails.id"
                  @click="clickApuesta('FTX28X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX28X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC4x2 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX20X' + this.gameDetails.id"
                  @click="clickApuesta('FTX20X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX20X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC1x0 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX44X' + this.gameDetails.id"
                  @click="clickApuesta('FTX44X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX44X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC2x4 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX30X' + this.gameDetails.id"
                  @click="clickApuesta('FTX30X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX30X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC4x3 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX33X' + this.gameDetails.id"
                  @click="clickApuesta('FTX33X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX33X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC1x1 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX23X' + this.gameDetails.id"
                  @click="clickApuesta('FTX23X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX23X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC3x0 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX31X' + this.gameDetails.id"
                  @click="clickApuesta('FTX31X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX31X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC4x4 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX38X' + this.gameDetails.id"
                  @click="clickApuesta('FTX38X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX38X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC1x2 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX24X' + this.gameDetails.id"
                  @click="clickApuesta('FTX24X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX24X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC3x1 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX48X' + this.gameDetails.id"
                  @click="clickApuesta('FTX48X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX48X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MCOtro }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX40X' + this.gameDetails.id"
                  @click="clickApuesta('FTX40X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX40X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC1x3 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX25X' + this.gameDetails.id"
                  @click="clickApuesta('FTX25X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX25X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC3x2 }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.ganaFTHT11">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Gana en HT/FT</div>

          <table width="100%" class="mt-2" id="tblGanaHtFt">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX51X' + this.gameDetails.id"
                  @click="clickApuesta('FTX51X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX51X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaFTHT11 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX52X' + this.gameDetails.id"
                  @click="clickApuesta('FTX52X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX52X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaFTHTX1 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX53X' + this.gameDetails.id"
                  @click="clickApuesta('FTX53X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX53X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaFTHT21 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX54X' + this.gameDetails.id"
                  @click="clickApuesta('FTX54X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX54X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaFTHT1X }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX55X' + this.gameDetails.id"
                  @click="clickApuesta('FTX55X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX55X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaFTHTXX }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX56X' + this.gameDetails.id"
                  @click="clickApuesta('FTX56X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX56X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaFTHT2X }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX57X' + this.gameDetails.id"
                  @click="clickApuesta('FTX57X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX57X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaFTHT12 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX58X' + this.gameDetails.id"
                  @click="clickApuesta('FTX58X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX58X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaFTHTX2 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX59X' + this.gameDetails.id"
                  @click="clickApuesta('FTX59X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX59X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaFTHT22 }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.golesLocal0">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Goles Local (1) FT</div>
          <table width="100%" class="mt-2" id="tblGolesLocal">
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX65X' + this.gameDetails.id"
                  @click="clickApuesta('FTX65X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX65X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesLocal0 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX66X' + this.gameDetails.id"
                  @click="clickApuesta('FTX66X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX66X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesLocal1 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX67X' + this.gameDetails.id"
                  @click="clickApuesta('FTX67X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX67X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesLocal2 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX68X' + this.gameDetails.id"
                  @click="clickApuesta('FTX68X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX68X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesLocal3 }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.golesVis0">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Goles Visitante (2) FT</div>
          <table width="100%" class="mt-2" id="tblGolesVisita">
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX69X' + this.gameDetails.id"
                  @click="clickApuesta('FTX69X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX69X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesVis0 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX70X' + this.gameDetails.id"
                  @click="clickApuesta('FTX70X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX70X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesVis1 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX71X' + this.gameDetails.id"
                  @click="clickApuesta('FTX71X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX71X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesVis2 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX72X' + this.gameDetails.id"
                  @click="clickApuesta('FTX72X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX72X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesVis3 }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.golesExa0">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales exacto de goles FT</div>
          <table width="100%" class="mt-2" id="totalExacto">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX77X' + this.gameDetails.id"
                  @click="clickApuesta('FTX77X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX77X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa0 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX78X' + this.gameDetails.id"
                  @click="clickApuesta('FTX78X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX78X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa1 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX79X' + this.gameDetails.id"
                  @click="clickApuesta('FTX79X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX79X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa2 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX80X' + this.gameDetails.id"
                  @click="clickApuesta('FTX80X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX80X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa3 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX81X' + this.gameDetails.id"
                  @click="clickApuesta('FTX81X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX81X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa4 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX82X' + this.gameDetails.id"
                  @click="clickApuesta('FTX82X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX82X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa5 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX83X' + this.gameDetails.id"
                  @click="clickApuesta('FTX83X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX83X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa6 }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.golIntNing">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1er Gol en intervalo 10 Min</div>

          <table width="100%" class="mt-2" id="tblMin">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro p-1"
                  :id="'FTX87X' + this.gameDetails.id"
                  @click="clickApuesta('FTX87X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX87X'),
                    'btn-logro-light': this.getThemelight
                  }"
                  style="font-size: 1em"
                >
                  {{ this.gameDetails.golIntNing }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro p-1"
                  :id="'FTX88X' + this.gameDetails.id"
                  @click="clickApuesta('FTX88X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX88X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golInt1X10 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro p-1"
                  :id="'FTX89X' + this.gameDetails.id"
                  @click="clickApuesta('FTX89X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX89X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golInt11X20 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro p-1"
                  :id="'FTX90X' + this.gameDetails.id"
                  @click="clickApuesta('FTX90X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX90X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golInt21X30 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro p-1"
                  :id="'FTX91X' + this.gameDetails.id"
                  @click="clickApuesta('FTX91X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX91X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golInt31X40 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro p-1"
                  :id="'FTX92X' + this.gameDetails.id"
                  @click="clickApuesta('FTX92X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX92X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golInt41X50 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro p-1"
                  :id="'FTX93X' + this.gameDetails.id"
                  @click="clickApuesta('FTX93X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX93X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golInt51X60 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro p-1"
                  :id="'FTX94X' + this.gameDetails.id"
                  @click="clickApuesta('FTX94X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX94X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golInt61X70 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro p-1"
                  :id="'FTX95X' + this.gameDetails.id"
                  @click="clickApuesta('FTX95X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX95X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golInt71X80 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro p-1"
                  :id="'FTX96X' + this.gameDetails.id"
                  @click="clickApuesta('FTX96X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX96X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golInt81X90 }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.locBaja15">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales/A ganar 3 Way (FT)</div>
          <table width="100%" class="mt-2" id="tblTot3Way">
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX152X' + this.gameDetails.id"
                  @click="clickApuesta('FTX152X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX152X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locBaja15 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX155X' + this.gameDetails.id"
                  @click="clickApuesta('FTX155X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX155X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locAlta15 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX153X' + this.gameDetails.id"
                  @click="clickApuesta('FTX153X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX153X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empBaja15 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX156X' + this.gameDetails.id"
                  @click="clickApuesta('FTX156X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX156X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empAlta15 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX154X' + this.gameDetails.id"
                  @click="clickApuesta('FTX154X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX154X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visBaja15 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX157X' + this.gameDetails.id"
                  @click="clickApuesta('FTX157X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX157X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visAlta15 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX158X' + this.gameDetails.id"
                  @click="clickApuesta('FTX158X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX158X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locBaja25 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX161X' + this.gameDetails.id"
                  @click="clickApuesta('FTX161X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX161X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locAlta25 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX159X' + this.gameDetails.id"
                  @click="clickApuesta('FTX159X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX159X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empBaja25 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX162X' + this.gameDetails.id"
                  @click="clickApuesta('FTX162X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX162X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empAlta25 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX160X' + this.gameDetails.id"
                  @click="clickApuesta('FTX160X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX160X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visBaja25 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX163X' + this.gameDetails.id"
                  @click="clickApuesta('FTX163X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX163X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visAlta25 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX164X' + this.gameDetails.id"
                  @click="clickApuesta('FTX164X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX164X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locBaja35 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX167X' + this.gameDetails.id"
                  @click="clickApuesta('FTX167X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX167X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locAlta35 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX165X' + this.gameDetails.id"
                  @click="clickApuesta('FTX165X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX165X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empBaja35 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX168X' + this.gameDetails.id"
                  @click="clickApuesta('FTX168X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX168X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empAlta35 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX166X' + this.gameDetails.id"
                  @click="clickApuesta('FTX166X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX166X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visBaja35 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX169X' + this.gameDetails.id"
                  @click="clickApuesta('FTX169X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX169X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visAlta35 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX170X' + this.gameDetails.id"
                  @click="clickApuesta('FTX170X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX170X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locBaja45 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX173X' + this.gameDetails.id"
                  @click="clickApuesta('FTX173X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX173X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locAlta45 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX171X' + this.gameDetails.id"
                  @click="clickApuesta('FTX171X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX171X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empBaja45 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX174X' + this.gameDetails.id"
                  @click="clickApuesta('FTX174X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX174X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empAlta45 }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX172X' + this.gameDetails.id"
                  @click="clickApuesta('FTX172X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX172X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visBaja45 }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX175X' + this.gameDetails.id"
                  @click="clickApuesta('FTX175X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX175X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visAlta45 }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div class="title card mt-3" v-if="gameDetails.hayHT" :class="{ 'title-light': this.getThemelight }">
          1ERA MITAD (HT)
        </div>

        <div v-if="this.gameDetails.gana1HT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 (HT)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX1X' + this.gameDetails.id"
                  @click="clickApuesta('HTX1X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX1X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1HT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX7X' + this.gameDetails.id"
                  @click="clickApuesta('HTX7X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX7X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXHT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX2X' + this.gameDetails.id"
                  @click="clickApuesta('HTX2X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX2X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2HT }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <!--<div v-if="this.gameDetails.HDPHT1">
          <div class="mt-2" style="color: white; background-color: #6e655d; font-size: 15px">HDP (HT)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block"
                style="background-color: #d1d0ce; height: 3em; font-size: 1.2em; font-weight: 600"
                :id="'HTX3X' + this.gameDetails.id"
                v-on:click="addDetail($event, gameDetails, gameDetails.HDPHT1)"
              >
                {{ this.gameDetails.HDPHT1 }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block"
                style="background-color: #d1d0ce; height: 3em; font-size: 1.2em; font-weight: 600"
                :id="'HTX4X' + this.gameDetails.id"
                v-on:click="addDetail($event, gameDetails, gameDetails.HDPHT2)"
              >
                {{ this.gameDetails.HDPHT2 }}
              </button>
            </div>
          </div>
        </div> -->

        <div v-if="this.gameDetails.totVisAltaHT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales Visitante (HT)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'HTX10X' + this.gameDetails.id"
                @click="clickApuesta('HTX10X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX10X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totVisAltaHT }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'HTX11X' + this.gameDetails.id"
                @click="clickApuesta('HTX11X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX11X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totVisBajaHT }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totVisAltaHT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales Local (HT)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'HTX12X' + this.gameDetails.id"
                @click="clickApuesta('HTX12X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX12X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totLocAltaHT }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'HTX13X' + this.gameDetails.id"
                @click="clickApuesta('HTX13X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX13X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totLocBajaHT }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.dobleChance1X">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Doble Chance (HT)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX62X' + this.gameDetails.id"
                  @click="clickApuesta('HTX62X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX62X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChance1X }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX63X' + this.gameDetails.id"
                  @click="clickApuesta('HTX63X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX63X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChance12 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX64X' + this.gameDetails.id"
                  @click="clickApuesta('HTX64X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX64X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChanceX2 }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.ambosSiHT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Ambos Anotan (HT)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'HTX49X' + this.gameDetails.id"
                @click="clickApuesta('HTX49X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX49X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.ambosSiHT }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'HTX50X' + this.gameDetails.id"
                @click="clickApuesta('HTX50X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX50X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.ambosNoHT }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.parHT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Marcador Par/Impar (HT)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'HTX60X' + this.gameDetails.id"
                @click="clickApuesta('HTX60X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX60X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.parHT }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'HTX61X' + this.gameDetails.id"
                @click="clickApuesta('HTX61X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX61X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.imparHT }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.MC1x0HT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Marcador Correcto (HT)</div>

          <table width="100%" class="mt-2" id="tblMarCorHT">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX20X' + this.gameDetails.id"
                  @click="clickApuesta('HTX20X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX20X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC1x0HT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX21X' + this.gameDetails.id"
                  @click="clickApuesta('HTX21X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX21X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC2x0HT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX22X' + this.gameDetails.id"
                  @click="clickApuesta('HTX22X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX22X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC2x1HT }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX32X' + this.gameDetails.id"
                  @click="clickApuesta('HTX32X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX32X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC0x0HT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX33X' + this.gameDetails.id"
                  @click="clickApuesta('HTX33X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX33X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC1x1HT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX34X' + this.gameDetails.id"
                  @click="clickApuesta('HTX34X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX34X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC2x2HT }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX36X' + this.gameDetails.id"
                  @click="clickApuesta('HTX36X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX36X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC0x1HT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX37X' + this.gameDetails.id"
                  @click="clickApuesta('HTX37X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX37X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC0x2HT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX38X' + this.gameDetails.id"
                  @click="clickApuesta('HTX38X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX38X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC1x2HT }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.golesExa0HT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            Totales exacto de goles (HT)
          </div>
          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX77X' + this.gameDetails.id"
                  @click="clickApuesta('HTX77X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX77X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa0HT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX78X' + this.gameDetails.id"
                  @click="clickApuesta('HTX78X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX78X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa1HT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX84X' + this.gameDetails.id"
                  @click="clickApuesta('HTX84X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX84X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa2HT }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.locBaja15HT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales/A ganar 3 Way (HT)</div>
          <table width="100%" class="mt-2" id="tblTot3WayHT">
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX152X' + this.gameDetails.id"
                  @click="clickApuesta('HTX152X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX152X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locBaja15HT }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX155X' + this.gameDetails.id"
                  @click="clickApuesta('HTX155X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX155X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locAlta15HT }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX153X' + this.gameDetails.id"
                  @click="clickApuesta('HTX153X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX153X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empBaja15HT }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX156X' + this.gameDetails.id"
                  @click="clickApuesta('HTX156X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX156X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empAlta15HT }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX154X' + this.gameDetails.id"
                  @click="clickApuesta('HTX154X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX154X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visBaja15HT }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'HTX157X' + this.gameDetails.id"
                  @click="clickApuesta('HTX157X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX157X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visAlta15HT }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div class="title card mt-3" v-if="gameDetails.hayST" :class="{ 'title-light': this.getThemelight }">
          2DA MITAD (ST)
        </div>

        <div v-if="this.gameDetails.gana1ST">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 (ST)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX1X' + this.gameDetails.id"
                  @click="clickApuesta('STX1X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX1X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1ST }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX7X' + this.gameDetails.id"
                  @click="clickApuesta('STX7X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX7X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXST }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX2X' + this.gameDetails.id"
                  @click="clickApuesta('STX2X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX2X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2ST }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.spreadST1">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">HDP (ST)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'STX3X' + this.gameDetails.id"
                @click="clickApuesta('STX3X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('STX3X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadST1 }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'STX4X' + this.gameDetails.id"
                @click="clickApuesta('STX4X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('STX4X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadST2 }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totVisAltaST">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales Visitante (ST)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'STX10X' + this.gameDetails.id"
                @click="clickApuesta('STX10X')"
                :class="{ 'apuesta-seleccionada': this.existeApuesta('STX10X'), 'btnOdd-light': this.getThemelight }"
              >
                {{ this.gameDetails.totVisAltaST }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'STX11X' + this.gameDetails.id"
                @click="clickApuesta('STX11X')"
                :class="{ 'apuesta-seleccionada': this.existeApuesta('STX11X'), 'btnOdd-light': this.getThemelight }"
              >
                {{ this.gameDetails.totVisBajaST }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totVisAltaST">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales Visitante (ST)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'STX10X' + this.gameDetails.id"
                @click="clickApuesta('STX10X')"
                :class="{ 'apuesta-seleccionada': this.existeApuesta('STX10X'), 'btnOdd-light': this.getThemelight }"
              >
                {{ this.gameDetails.totVisAltaST }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'STX11X' + this.gameDetails.id"
                @click="clickApuesta('STX11X')"
                :class="{ 'apuesta-seleccionada': this.existeApuesta('STX11X'), 'btnOdd-light': this.getThemelight }"
              >
                {{ this.gameDetails.totVisBajaST }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totLocAltaST">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales Local (ST)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'STX12X' + this.gameDetails.id"
                @click="clickApuesta('STX12X')"
                :class="{ 'apuesta-seleccionada': this.existeApuesta('STX12X'), 'btnOdd-light': this.getThemelight }"
              >
                {{ this.gameDetails.totLocAltaST }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'STX13X' + this.gameDetails.id"
                @click="clickApuesta('STX13X')"
                :class="{ 'apuesta-seleccionada': this.existeApuesta('STX13X'), 'btnOdd-light': this.getThemelight }"
              >
                {{ this.gameDetails.totLocBajaST }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.ambosSiST">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Ambos Anotan (ST)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'STX49X' + this.gameDetails.id"
                @click="clickApuesta('STX49X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('STX49X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.ambosSiST }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'STX50X' + this.gameDetails.id"
                @click="clickApuesta('STX50X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('STX50X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.ambosNoST }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.MC1x0ST">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Marcador Correcto (ST)</div>

          <table width="100%" class="mt-2" id="tblMarCorST">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX20X' + this.gameDetails.id"
                  @click="clickApuesta('STX20X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX20X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC1x0ST }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX21X' + this.gameDetails.id"
                  @click="clickApuesta('STX21X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX21X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC2x0ST }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX22X' + this.gameDetails.id"
                  @click="clickApuesta('STX22X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX22X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC2x1ST }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX32X' + this.gameDetails.id"
                  @click="clickApuesta('STX32X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX32X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC0x0ST }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX33X' + this.gameDetails.id"
                  @click="clickApuesta('STX33X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX33X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC1x1ST }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX34X' + this.gameDetails.id"
                  @click="clickApuesta('STX34X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX34X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC2x2ST }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX36X' + this.gameDetails.id"
                  @click="clickApuesta('STX36X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX36X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC0x1ST }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX37X' + this.gameDetails.id"
                  @click="clickApuesta('STX37X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX37X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC0x2ST }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX38X' + this.gameDetails.id"
                  @click="clickApuesta('STX38X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX38X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.MC1x2ST }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.golesExa0ST">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            Totales exacto de goles (ST)
          </div>
          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX77X' + this.gameDetails.id"
                  @click="clickApuesta('STX77X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX77X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa0ST }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX78X' + this.gameDetails.id"
                  @click="clickApuesta('STX78X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX78X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa1ST }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX84X' + this.gameDetails.id"
                  @click="clickApuesta('STX84X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX84X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.golesExa2ST }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.parST">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Marcador Par/Impar (ST)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'STX60X' + this.gameDetails.id"
                @click="clickApuesta('STX60X')"
                :class="{ 'apuesta-seleccionada': this.existeApuesta('STX60X'), 'btn-logro-light': this.getThemelight }"
              >
                {{ this.gameDetails.parST }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'STX61X' + this.gameDetails.id"
                @click="clickApuesta('STX61X')"
                :class="{ 'apuesta-seleccionada': this.existeApuesta('STX61X'), 'btn-logro-light': this.getThemelight }"
              >
                {{ this.gameDetails.imparST }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.locBaja15ST">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales/A ganar 3 Way (ST)</div>
          <table width="100%" class="mt-2" id="tblTot3WayST">
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX152X' + this.gameDetails.id"
                  @click="clickApuesta('STX152X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX152X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locBaja15ST }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX155X' + this.gameDetails.id"
                  @click="clickApuesta('STX155X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX155X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.locAlta15ST }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX153X' + this.gameDetails.id"
                  @click="clickApuesta('STX153X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX153X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empBaja15ST }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX156X' + this.gameDetails.id"
                  @click="clickApuesta('STX156X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX156X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.empAlta15ST }}
                </button>
              </td>
            </tr>
            <tr class="text-center">
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX154X' + this.gameDetails.id"
                  @click="clickApuesta('STX154X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX154X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visBaja15ST }}
                </button>
              </td>
              <td width="50%" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'STX157X' + this.gameDetails.id"
                  @click="clickApuesta('STX157X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('STX157X'),
                    'btn-logro-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.visAlta15ST }}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </details>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { juegosDetalles } from "../../../../mixins/juegosDetallesMixin.js";
export default {
  data() {
    return {
      verDetalle: false,
      show: false
    };
  },
  name: "futbolMovil",
  mixins: [juegosDetalles],
  props: {
    gameDetails: Object
    // cartId: [],
    // oddId: String
  },
  computed: {
    ...mapGetters(["getThemelight"])
  },
  methods: {
    abrirDetalle(muestraTitulo) {
      console.log("Muestra detalle");
      if (muestraTitulo) this.show = true;
      this.$emit("label", "details");
    },
    detalle(id) {
      $("#btnJugadas" + id).addClass("apuesta-seleccionada");
      this.verDetalle = !this.verDetalle;
    }

    /*addBet(evt, odd, logro) {
      let me = this;
      console.log("Datos de odd : ", odd);

      var 'apuesta-seleccionada' = {
        fecha: odd.gameDate,
        hora: moment(odd.gameTime).format("hh:mm A"),
        equipo1: odd.teams[0].name,
        equipo2: odd.teams[1].name,
        tipo: evt.path[1].childNodes[0].innerHTML.trim(),
        logro: logro,
        id: evt.target.id
      };
      const parentEl = evt.target.parentNode;

      $("tbody #" + parentEl.id + " td").each(function() {
        if (this.id == evt.target.id && !$("#" + this.id).hasClass("'apuesta-seleccionada'")) {
          $("#" + this.id).addClass("'apuesta-seleccionada'");
          me.selectOdds.push('apuesta-seleccionada');
        } else if (this.id) {
          if ($("#" + this.id).hasClass("'apuesta-seleccionada'")) {
            var i = me.selectOdds.findIndex(element => element.id == this.id);
            me.selectOdds.splice(i, 1);
            $("#" + this.id).removeClass("'apuesta-seleccionada'");
          }
        }
      });

      me.selectOdds.sort();
    },
    addDetail(evt, odd, logro) {
      let me = this;
      var 'apuesta-seleccionada' = {
        fecha: odd.gameDate,
        hora: moment(odd.gameTime).format("hh:mm A"),
        equipo1: odd.teams[0].name,
        equipo2: odd.teams[1].name,
        tipo: evt.path[1].childNodes[0].innerHTML.trim(),
        logro: logro,
        id: evt.target.id
      };

      //Evalua si l boton actualmente presionado esta ya activo o viceversa
      if (!$(evt.target).hasClass("'apuesta-seleccionada'")) {
        me.selectOdds.push('apuesta-seleccionada');
        $(evt.target).addClass("'apuesta-seleccionada'");
      } else {
        var i = me.selectOdds.findIndex(element => element.id == evt.target.id);
        me.selectOdds.splice(i, 1);
        $("#" + evt.target.id).removeClass("'apuesta-seleccionada'");
      }

      //Evalua el resto de botones del logro
      $(evt.target.parentNode)
        .siblings()
        .children()
        .each(function() {
          if ($("#" + this.id).hasClass("'apuesta-seleccionada'")) {
            var i = me.selectOdds.findIndex(element => element.id == this.id);
            me.selectOdds.splice(i, 1);
            $("#" + this.id).removeClass("'apuesta-seleccionada'");
          }
        });
    },
    addDetailTable(tblID, evt, odd, logro) {
      let me = this;
      var 'apuesta-seleccionada' = {
        fecha: odd.gameDate,
        hora: moment(odd.gameTime).format("hh:mm A"),
        equipo1: odd.teams[0].name,
        equipo2: odd.teams[1].name,
        tipo: evt.path[1].childNodes[0].innerHTML.trim(),
        logro: logro,
        id: evt.target.id
      };

      $("#" + tblID + " tr td").each(function() {
        $(this)
          .children()
          .each(function() {
            if (evt.target.id == this.id && !$("#" + this.id).hasClass("'apuesta-seleccionada'")) {
              console.log("Contenido del odd agregar: ", 'apuesta-seleccionada');
              me.selectOdds.push('apuesta-seleccionada');
              $(evt.target).addClass("'apuesta-seleccionada'");
            } else if ($("#" + this.id).hasClass("'apuesta-seleccionada'")) {
              var i = me.selectOdds.findIndex(element => element.id == this.id);
              me.selectOdds.splice(i, 1);
              $("#" + this.id).removeClass("'apuesta-seleccionada'");
            }
          });
      });
      me.selectOdds.sort();
    },*/
  }
};
</script>
<style>
.sub-title {
  font-weight: 200;
}
</style>
