<template>
  <section
    id="container-juegos"
    :class="{
      'animate__animated animate__backInRight': true,
      'light-theme bg-light': this.getThemelight,
      'bg-dark': !this.getThemelight
    }"
  >
    <div :class="{ 'row pt-4 section-central-distribucion': true, 'is-bar-mobil': getVisualWidth < 1200 }">
      <div
        v-if="isWeb"
        id="container-sidebar-col"
        :class="{
          'col-lg-3 col-sm-3': sidebarOpen,
          'col-lg-1 col-sm-3': !sidebarOpen
        }"
      >
        <div
          :class="{ 'btn-group-vertical sidebar-container tl': true, 'light-theme': this.getThemelight }"
          role="group"
          aria-label="Vertical button group"
          v-show="sidebarOpen || (this.condicionalExtra && sidebarOpen) || this.getIsMobilResolution"
        >
          <button
            type="button"
            class="btn btn-secondary"
            v-on:click="sidebarOpen = !sidebarOpen"
            v-if="!this.getIsMobilResolution"
            style="text-align: center; font-weight: 500; border-top-left-radius: 0px"
          >
            <i class="fa fa-angle-double-left"></i> {{ $t("ocultar") }}
          </button>

          <div class="container-2-btns">
            <button type="button" v-b-modal.modal-streaming class="btn-base border-green btn-jugadas btn-streaming">
              <span> <img class="img-steaming" v-lazy="'/img/livestream.webp'" /> Streaming </span>
            </button>
            <button
              type="button"
              class="btn-ver-tickets sin-bordes btn-bordes-orange"
              v-b-modal.modal-tickets
              @click="filter"
            >
              <span>{{ $t("ver_tickets_jugados") }}</span>
            </button>
          </div>

          <div
            class="btn-group btn-group-sm"
            role="group"
            aria-label="Small button group mt-2 mp-2 selector-range-date"
          >
            <button
              type="button"
              :class="{ 'btn btn-secondary text-center': true }"
              v-on:click="changeTargetDate({ all: false })"
            >
              {{ $t("hoy") }}
            </button>
            <button
              type="button"
              :class="{
                'btn btn-secondary text-center': true,
                'active-option': !this.targetToDateCurrent && !fechaTargetActive
              }"
              v-on:click="changeTargetDate({ all: true })"
            >
              {{ $t("todas") }}
            </button>
            <button
              type="button"
              :class="{ 'btn btn-secondary text-center': true, 'active-option': fechaTargetActive }"
              v-on:click="targetCustomDatePicker"
            >
              {{ $t("fecha") }}
            </button>
          </div>
          <vc-date-picker
            v-if="fechaTargetActive"
            v-model="dateTargetPicker"
            :input-debounce="500"
            :min-date="new Date()"
            :class="{
              'w-100': true
            }"
            :masks="masksPicker"
            @input="updateValuePicker"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <form action="">
                <input
                  :class="{
                    'border rounded form-control text-center bg-input-date': true,
                    'bg-dark': !getThemelight,
                    'bg-light': getThemelight
                  }"
                  :value="inputValue"
                  v-on="inputEvents"
                  autocomplete="off"
                />
              </form>
            </template>
          </vc-date-picker>
          <SidebarMenuJuegos @updateOptions="updateOptions" @destacado="destacado"></SidebarMenuJuegos>
        </div>
        <!-- Panel de Sub Opciones -->
        <div
          :class="{ 'sub-menu-mini animate__animated animate__fadeInLeft': true, light: getThemelight }"
          v-show="displayOpcionesMini"
        >
          <div
            v-for="(menu, indexM) in this.getMenuOptionsLeagues"
            :key="indexM"
            v-show="menuOptionMini && String(menuOptionMini).toLowerCase() == String(menu.name).toLowerCase()"
          >
            <h4 class="text-center pt2 pb2 title-mini-menu">{{ menu.name }}</h4>
            <ul
              :class="{
                nav: true,
                'nav-open animate__animated animate__fadeIn': true
              }"
              :data-toggle="`collapse-1`"
            >
              <li class="nav-item">
                <a
                  :href="`#${submenu.name}`"
                  class="nav-link mini"
                  v-for="(submenu, indexS) in menu.data"
                  :key="indexS"
                  v-on:click="changeGame(submenu.sport_id, submenu.id)"
                >
                  <p class="txt-submenu">{{ submenu.name }}</p>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <SidebarMenuReducido
          :sidebarOpen="sidebarOpen"
          @cerrarMiniMenu="cerrarMiniMenu"
          @filter="filter"
          @activadorSubmenu="activadorSubmenu"
          v-if="!this.getIsMobilResolution"
        ></SidebarMenuReducido>
      </div>
      <div id="contenedor-taquilla-main" class="col-lg col-sm">
        <GameDetailsTable :destacado="this.esDestacado"></GameDetailsTable>
        <TaquillaLateralFijo v-if="!this.getTaquillaFlotante"></TaquillaLateralFijo>
      </div>
    </div>
    <!-- Modal Live -->
    <b-modal
      id="modal-streaming"
      title="Video Streaming"
      name="video-streaming"
      size="lg"
      ok-title="Minimizar"
      ok-variant="warning"
      cancel-title="Cerrar"
      cancel-variant="default"
      no-close-on-backdrop
      draggable=".contenedor-modal-streaming"
      scrollable
      v-on:show="mostrandoModalStreaming"
      v-on:close="closeVideo()"
      v-on:cancel="closeVideo()"
    >
      <div class="contenedor-modal-streaming">
        <b-tabs nav-wrapper-class="mt-3" v-model="tabIndex">
          <b-tab title="En vivo" title-link-class="color-tabs">
            <ul v-if="getVideo && getVideo != null">
              <li v-for="item of getVideo.online" v-bind:key="item.id" class="li-custom" @click="watchVideo(item)">
                {{ item.sport }} <br />
                {{ item.name }} <br />
                {{ item.date }}
                <hr />
              </li>
            </ul>
          </b-tab>
          <b-tab title="Proximos" title-link-class="color-tabs">
            <ul v-if="getVideo && getVideo != null">
              <li v-for="item of getVideo.next" v-bind:key="item.id" class="li-custom">
                {{ item.sport }} <br />
                {{ item.name }} <br />
                {{ item.date }}
                <hr />
              </li>
            </ul>
          </b-tab>
          <b-tab title="Video" title-link-class="color-tabs">
            <h3 class="text-center">{{ nameGame }}</h3>
            <iframe :src="urlgame" width="100%" height="500px"></iframe>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
    <b-modal
      id="modal-tickets"
      size="lg"
      :header-bg-variant="!this.getThemelight ? 'dark' : 'light'"
      :body-bg-variant="!this.getThemelight ? 'dark' : 'light'"
      :footer-bg-variant="!this.getThemelight ? 'dark' : 'light'"
      :class="{
        dark: !getThemelight,
        light: getThemelight
      }"
      centered
      scrollable
      title="REPORTE DE TICKETS JUGADOS"
      hide-footer
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title mr-4">REPORTE DE TICKETS JUGADOS</h5>
        <vc-date-picker
          v-model="fechaFiltroTicket"
          :input-debounce="500"
          :class="{
            'fecha-ticket ml-2 mr-2': true,
            'bg-dark': !getThemelight,
            'bg-light': getThemelight
          }"
          :masks="masksPicker"
          :max-date="new Date()"
          @input="updateValuePickerTickets"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              :class="{
                'border rounded form-control text-center bg-input-date': true,
                'bg-dark': !getThemelight,
                'bg-light': getThemelight
              }"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </vc-date-picker>
        <b-form-select
          class="input-modal"
          v-model="selectedStatus"
          @change="changeStatusFilter"
          :options="optionsStatus"
        ></b-form-select>
        <input
          placeholder="Numero"
          v-model="numberFilter"
          class="form-control input-modal ml-2 mr-2"
          v-debounce:1s="filter"
        />
        <b-form-select class="input-modal" v-model="selectedCuotas" :options="optionsCuotas"></b-form-select>
        <b-button class="float-right ml-2 mr-2 btn-azul-rey-1" variant="success" @click="filter">Filtrar</b-button>
        <b-button class="float-right ml-2 mr-2" variant="secondary" @click="clearFilters">Limpiar</b-button>
        <b-button class="float-right close" @click="close()">X</b-button>
      </template>
      <tickets-table :page="this.pageResults" :perPage="this.limitResults"></tickets-table>
      <b-pagination
        v-if="this.getTickets && this.getTickets.total"
        size="sm"
        :per-page="this.limitResults"
        :limit="3"
        :total-rows="parseInt(this.getTickets.total)"
        v-model="page"
        custom="size"
        :class="{
          'customPagination float-left mt-1': true,
          'bg-dark': !getThemelight,
          'bg-light': getThemelight
        }"
        first-number
        last-number
        @input="changePage"
      />
      <b-button class="mt-1 float-right" sm @click="$bvModal.hide('modal-tickets')">Cerrar</b-button>
    </b-modal>
    <ticket-target
      :ticketTarget="{
        number: 123,
        id: 456,
        code: 7890,
        odds: []
      }"
    ></ticket-target>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import TicketsTable from "../components/tables/TicketsTable.vue";
import TicketTarget from "../components/tables/TicketTarget.vue";
import GameDetailsTable from "../components/tables/GameDetailsTable.vue";
import SidebarMenuJuegos from "../components/SidebarMenuJuegos.vue";
import moment from "moment";
import * as lang from "vuejs-datepicker/src/locale";
import * as u from "j899";
import { essentialsData } from "../mixins/essentialsDataMixin.js";
import { chatMixin } from "../mixins/chatMixin.js";
import SidebarMenuReducido from "../components/SidebarMenuReducido.vue";
import TaquillaLateralFijo from "../components/TaquillaLateralFijo.vue";
export default {
  name: "Juegos",
  mixins: [essentialsData, chatMixin],
  computed: {
    ...mapGetters([
      "getUser",
      "getTickets",
      "getIsMobilResolution",
      "getLeagues",
      "getGames",
      "getMenuOptionsLeagues",
      "getApuestas",
      "getMenuTargetAll",
      "getFormatoTaquilla",
      "getTipoParley",
      "getPremio",
      "getMonto",
      "getPremiosParley",
      "getPremiosDirectas",
      "getTicketTarget",
      "getVideo",
      "getThemelight",
      "getVisualWidth",
      "getTaquillaFlotante"
    ]),
    isWeb() {
      return true; //document.body.clientWidth >= 1300;
    }
  },
  components: {
    TicketsTable,
    GameDetailsTable,
    SidebarMenuJuegos,
    TicketTarget,
    SidebarMenuReducido,
    TaquillaLateralFijo
  },
  data() {
    return {
      condicionalExtra: false,
      displayOpcionesMini: false,
      menuOptionMini: null,
      videoR: false,
      btnSteamingideo: "Streaming",
      tabIndex: 0,
      urlgame: null,
      nameGame: null,
      language: "es", // the language I need
      languages: lang,
      leagues: [],
      monto: "",
      errorMonto: false,
      fechaFiltroTicket: null,
      fechaTickets: null,
      fechaTarget: null,
      dataMenuOptions: [],
      targetModule: "Deportes",
      fechaTargetActive: false,
      sidebarOpen: true,
      pageResults: 1,
      limitResults: 15,
      selectedStatus: 0,
      since: null,
      sinceFormat: "",
      until: null,
      untilFormat: null,
      numberFilter: null,
      esDestacado: true,
      targetToDateCurrent: true,
      dateTarget: moment(String(new Date())).format("YYYY-MM-DD"),
      dateTargetPicker: moment(String(new Date())).format("YYYY-MM-DD"),
      masksPicker: { input: "DD-MM-YYYY" },
      optionsStatus: [
        { value: 0, text: "TODOS" },
        { value: 1, text: "PENDIENTE" },
        { value: 2, text: "GANADOR" },
        { value: 3, text: "PERDEDOR" },
        { value: 5, text: "ANULADO" }
      ],

      selectedCuotas: "odd",
      optionsCuotas: [
        { value: "odd", text: "Americano" },
        { value: "odd_a", text: "Decimal" }
      ],
      page: 1
    };
  },
  watch: {
    getVisualWidth(v) {
      if (v <= 1280) {
        this.sidebarOpen = false;
        this.displayOpcionesMini = false;
        this.sidebarOpen = false; // referencia a menu grande oculto
        this.$store.commit("setTaquillaFlotante", true);
      }
    },
    monto(m) {
      if (typeof parseFloat(m) == "number" && !isNaN(m)) {
        this.errorMonto = false;
        let montoFormato = parseFloat(parseFloat(m).toFixed(2));

        if (isNaN(montoFormato)) {
          montoFormato = 0;
        }

        this.$store.commit("setMonto", montoFormato);
      } else if (m != "") {
        this.errorMonto = true;
        this.$store.commit("setMonto", 0);
        this.$store.commit("setPremio", 0);
        this.$store.commit("setTotalEnPremios", 0);
      }

      if (String(m).trim() == "") {
        // valores manejados internamente en el modulo de taquilla
        this.$store.commit("setPremio", 0);
        this.$store.commit("setTotalEnPremios", 0);
        this.$store.commit("setMonto", 0);
      }

      this.$store.dispatch("calculatePrize");
    },
    getApuestas(ap) {
      let cantidadMonto = parseFloat(this.monto);
      if (ap.length == 0) {
        this.$store.commit("setPremio", 0);
        this.$store.commit("setTotalEnPremios", 0);
      }
      if (!isNaN(cantidadMonto)) {
        this.$store.commit("setMonto", cantidadMonto.toFixed(2));
        this.$store.dispatch("calculatePrize");
      }
    }
  },
  methods: {
    destacado(h) {
      this.esDestacado = h;
    },
    cerrarMiniMenu(...args) {
      this.sidebarOpen = !this.sidebarOpen;
      this.displayOpcionesMini = false;
      if (args && args[0] && args[0] == true) {
        this.condicionalExtra = true;
      }
    },
    activadorSubmenu(menuOptionMini) {
      this.menuOptionMini = menuOptionMini;

      let ancho = document.getElementById("container-sidebar-col").childNodes[2].offsetWidth;
      this.displayOpcionesMini = true;
      document.getElementsByClassName("sub-menu-mini")[0].style.marginLeft = `${ancho}px`;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    watchVideo(e) {
      this.tabIndex = 2;
      this.urlgame = e.link;
      this.nameGame = e.name;
      this.videoR = true;
      this.btnSteamingideo = "Reproduciendo";
    },
    closeVideo() {
      this.videoR = false;
      this.btnSteamingideo = "Streaming";
      console.log("Cerrar video");
    },
    mostrandoModalStreaming() {
      this.tabIndex = 0;
      if (this.videoR) {
        this.tabIndex = 2;
      }
      modalDraggableActionStreaming();
    },
    async updateOptions(v) {
      if (v == "destacados") {
        this.changeTargetDate({ all: false });
      }
    },
    formatoMonto(m) {
      let montoFormato = parseFloat(parseFloat(m).toFixed(2));
      return u.inNumeration("LA", montoFormato);
    },
    changeTipoParley(isParley) {
      this.$store.commit("setPremio", 0);
      this.$store.commit("setTotalEnPremios", 0);
      this.$store.commit("setTipoParley", isParley);
      this.$store.dispatch("calculatePrize");
    },
    limpiarApuestas() {
      this.Swal.fire({
        title: "¿Desea eliminar las apuestas seleccionadas?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.commit("setMonto", 0);
          this.$store.commit("setPremio", 0);
          this.$store.commit("setApuestas", []);
          this.$store.commit("setRestriccionParley", []);
          this.$store.commit("setExcepcionParley", false);
          this.Swal.fire("La selección de apuestas ha sido limpiada.", "", "success");
        } else if (result.isDenied) {
          this.Swal.fire("Acción cancelada", "", "info");
        }
      });
    },
    maskNameTitle(name) {
      return String(name).split("(")[0];
    },
    changeFormat(v) {
      this.$store.commit("setFormatoTaquilla", v.target.value);
    },
    clickApuesta(a) {
      this.$store.dispatch("clickApuesta", a);
      this.$store.dispatch("calculatePrize");
    },
    validacionesTickets() {
      let error = false;
      let msj = "";

      if (this.getTipoParley && this.getApuestas.length < this.getBetInit.minimo) {
        error = true;
        msj = "El mínimo de jugadas parley debe ser " + this.getBetInit.minimo;
      }

      if (this.getApuestas.length > 1 && this.getApuestas.length > this.getBetInit.maximo) {
        error = true;
        msj = "El maximo de jugadas parley debe ser " + this.getBetInit.maximo;
      }

      if (parseFloat(this.monto) < parseFloat(this.getBetInit.min_tck)) {
        error = true;
        msj = "La jugada por parley minima debe ser " + u.inNumeration("LA", parseFloat(this.getBetInit.min_tck));
      }

      if (parseInt(this.monto) <= 0 || String(this.monto).trim() == "") {
        error = true;
        msj = "monto invalido porfavor verifique";
        this.errorMonto = true;
      }

      if (this.getApuestas && this.getApuestas.length == 0 && !error) {
        error = true;
        msj = "debe tener apuestas seleccionadas";
      }

      return { error, msj };
    },
    async generarTicket() {
      let validaciones = this.validacionesTickets();

      if (validaciones.error && validaciones.error == true) {
        return this.Swal.fire("Ha ocurrido un error", validaciones.msj, "error");
      }

      let oddsData = {};

      if (this.getApuestas && this.getApuestas.length > 0) {
        this.getApuestas.forEach(apuesta => {
          oddsData[apuesta.id] = {
            game_id: apuesta.respuesta.odd.Game.id,
            type: apuesta.respuesta.odd.Odd.finalit,
            odd_type: apuesta.respuesta.odd.Odd.odd_type_id,
            odd: apuesta.respuesta.odd.Odd.odd,
            odd_a: apuesta.respuesta.odd.Odd.odd_a,
            factor: apuesta.respuesta.odd.Odd.factor,
            status: 1, // el 1 es pendiente debe ser asi cada vez que se manda
            code: apuesta.respuesta.odd.Game.code
          };
        });
      }

      const cantidad_amount_ind = this.getTipoParley ? this.monto : this.monto * this.getApuestas.length;

      let confirmo = false;
      let bonus = false;

      // Confirmacion antes de enviar el ticket

      const dataPremiosParley = JSON.parse(JSON.stringify(this.getPremiosParley));
      const dataPremiosDirectas = JSON.parse(JSON.stringify(this.getPremiosDirectas));

      // eslint-disable-next-line no-unused-vars
      const { value: accept } = await this.Swal.fire({
        customClass: {
          content: "contentCustomPosition",
          footer: "contentFinal"
        },
        width: "700px",
        title: "¿Está seguro que desea generar el Ticket?",
        input: "checkbox",
        inputValue: 0,
        inputPlaceholder: `Usar Bonus`,
        text: "(Al sellar con bonus NO se devuelve el monto apostado)",
        confirmButtonText: 'Confirmar <i class="fa fa-arrow-right"></i>',
        footer: `NOTA: Una vez oprima <strong>[Confirmar]</strong>, su apuesta será registrada! Oprima Cancelar para agregar/actualizar apuestas.`,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar"
      }).then(result => {
        /*
                  validar cierre de modal
                  { 
                    "isConfirmed": false, 
                    "isDenied": false, 
                    "isDismissed": true, 
                    "dismiss": "cancel"
                    }
                */
        confirmo = result.isConfirmed;

        bonus = result.value;

        return confirmo;
      });

      const dataTicket = {
        Ticket: {
          amount: this.monto,
          amount_ind: cantidad_amount_ind,
          modalidad: this.getTipoParley,
          bonus,
          code: this.getBetInit,
          procesed: moment(new Date()).format("YYYY-MM-DD"),
          Odds: oddsData
        }
      };

      /*
              2) los campos amount y amount_ind no pueden ir vacíos.
              [5:35 PM, 9/2/2021] +58 414-7425353: Cuando la jugada es tipo Parley el amount = amount_ind = es el monto de la jugada.
              Y cuando es tipo directa
              Amount_ind es el monto que introduce el cliente y el amount = amount_ind * cantidad_jugadas
            */

      if (confirmo) {
        this.$store.commit("showLoader");
        let respuesta = await this.$store.dispatch("registerTicketAsync", dataTicket);

        if (respuesta.Type_error && respuesta.Type_error != 0) {
          this.Swal.fire("Ha ocurrido un error", respuesta.Error, "error");
        } else {
          // caso satisfactorio
          if (respuesta.NewCode) {
            this.$store.commit("setBetInit", respuesta.NewCode);

            respuesta.Ticket.Monto = parseFloat(this.getMonto);
            respuesta.Ticket.Premio = parseFloat(this.getPremio);
            respuesta.Ticket.Apuestas = JSON.parse(JSON.stringify(this.getApuestas));

            respuesta.Ticket.PremiosParley = dataPremiosParley;
            respuesta.Ticket.PremiosDirectas = dataPremiosDirectas;

            this.$store.commit("setTicketTarget", respuesta.Ticket);
          }

          if (respuesta.Center && respuesta.Center.note) {
            //this.Swal.fire("Exito", respuesta.Center.note, "success");
            this.$bvModal.show("details-ticket-target");

            const Toast = this.Swal.mixin({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: toast => {
                toast.addEventListener("mouseenter", this.Swal.stopTimer);
                toast.addEventListener("mouseleave", this.Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "success",
              title: "Ticket generado Satisfactoriamente"
            });
          }
          this.monto = "";
          this.$store.commit("setMonto", 0);
          this.$store.commit("setPremio", 0);
          this.$store.commit("setMonto", 0);
          this.$store.commit("setApuestas", []);
          this.$store.commit("setRestriccionParley", []);
          this.$store.commit("setExcepcionParley", false);
        }
        this.$store.commit("hideLoader");
      }
    },

    async updateValuePickerTickets(v) {
      this.$store.commit("showLoader");

      await this.$store.dispatch("getTicketsClientAsync", {
        page: this.page,
        status: this.selectedStatus,
        number: this.numberFilter,
        since: moment(v).format("YYYY-MM-DD"),
        until: moment(v).format("YYYY-MM-DD")
      });

      this.$store.commit("hideLoader");
    },
    async updateValuePicker(v) {
      this.$store.commit("showLoader");
      // Para la busqueda en el endpoind es en formato [YYYY-MM-DD]
      const opciones = { date: moment(v).format("YYYY-MM-DD"), customDate: true };

      this.dateTarget = moment(v).format("YYYY-MM-DD");

      this.$store.commit("setGames", null);

      await this.$store.dispatch("searchSportsLeaguesAsync", opciones);
      await this.$store.dispatch("getLeaguesAsync", opciones);
      await this.$store.dispatch("getGamesAsync", opciones);

      setTimeout(() => {
        this.$store.commit("hideLoader");
      }, 1500);
    },
    targetCustomDatePicker() {
      this.fechaTargetActive = !this.fechaTargetActive;
      this.targetToDateCurrent = false;
      this.dateTargetPicker = new Date();
    },
    async changeTargetDate(options) {
      this.$store.commit("showLoader");
      this.fechaTargetActive = false;
      let fecha = null;

      if (options.all && options.all === true) {
        await this.$store.dispatch("clickMenuTargetAll");
        this.targetToDateCurrent = false;
      } else {
        this.$store.commit("setMenuTargetAll", false);
        this.dateTarget = moment(String(new Date())).format("YYYY-MM-DD");
        this.dateTargetPicker = new Date();
        this.targetToDateCurrent = true;
        fecha = moment(String(new Date())).format("YYYY-MM-DD");
      }
      const all = options && options.all && options.all === true;

      const opciones = !fecha ? { all } : { date: fecha, all };

      await this.$store.dispatch("searchSportsLeaguesAsync", opciones);
      await this.$store.dispatch("getLeaguesAsync", opciones);
      await this.$store.dispatch("getGamesAsync");

      setTimeout(() => {
        this.$store.commit("hideLoader");
      }, 1000);
    },
    async changeGame(sport_id, league_id) {
      this.$store.commit("showLoader");
      if (this.fechaTargetActive) {
        let fecha = moment(this.dateTargetPicke).format("YYYY-MM-DD");
        console.log("f", fecha);
        await this.$store.dispatch("getGamesAsync", {
          sport_id: sport_id,
          league_id: league_id,
          customDate: this.fechaTargetActive,
          date: fecha
        });
      } else {
        await this.$store.dispatch("getGamesAsync", { sport_id: sport_id, league_id: league_id });
      }

      this.displayOpcionesMini = false;
      this.$store.commit("hideLoader");
    },
    async clearFilters() {
      this.page = 1;
      this.selectedStatus = 0;
      this.numberFilter = null;
      this.inputValue = "";
      this.dateTarget = null;
      this.dateTargetPicker = null;
      this.fechaFiltroTicket = null;
      this.$store.commit("showLoader");
      await this.$store.dispatch("getTicketsClientAsync");
      this.$store.commit("hideLoader");
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      //this.sinceFormat = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.sinceFormat = ctx.selectedYMD;
    },
    async filter() {
      this.$store.commit("setTickets", []);
      this.$store.commit("showLoader");
      await this.$store.dispatch("getTicketsClientAsync", {
        page: this.page,
        status: this.selectedStatus,
        number: this.numberFilter
      });
      this.$store.commit("hideLoader");
    },
    async changeStatusFilter(evt) {
      this.$store.commit("showLoader");
      await this.$store.dispatch("getTicketsClientAsync", {
        page: this.page,
        status: evt
      });
      this.$store.commit("hideLoader");
    },
    async changePage(pag) {
      this.$store.commit("showLoader");
      await this.$store.dispatch("getTicketsClientAsync", { page: pag });
      this.$store.commit("hideLoader");
    }
  },
  async mounted() {
    this.hideChat();

    this.$store.commit("showLoader");

    await this.$store.dispatch("checkLogin");
    this.$store.dispatch("checkSession");
    //await this.changeTargetDate({ all: false });

    this.$emit("barSolid", true);

    // Get reference to the date picker component
    //const datepicker = this.$refs.calendar;
    // Call method of the component
    //datepicker.move(new Date());
    // move doc -> https://vcalendar.io/navigation.html

    // en caso que refresque se restaura el ultimo target en las apuestas
    if (localStorage && localStorage.getItem("apuestas") && localStorage.getItem("apuestas") != "undefined") {
      const apuestasTarget = JSON.parse(localStorage.getItem("apuestas"));
      this.$store.commit("setApuestas", apuestasTarget);
    }

    this.$store.commit("hideLoader");
  },
  async created() {
    if (this.getVisualWidth <= 1280) {
      this.sidebarOpen = false;
      this.displayOpcionesMini = false;
      this.sidebarOpen = false; // referencia a menu grande oculto
    }
  }
};

function modalDraggableActionStreaming() {
  setTimeout(() => {
    $("#modal-streaming").draggable();
  }, 200);
}
</script>
<style>
/*
div.swal2-content.contentCustomPosition {
  display: flex !important;
  flex-direction: column-reverse !important;
}
.lbl-total-apostado {
  background-color: #9cffde;
  padding: 2px 10px;
  border-radius: 7px;
  color: green;
}
  */
/*max-width: 255px;*/

.section-central-distribucion {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.btn-ver-tickets.sin-bordes.btn-bordes-orange > span {
  width: 100%;
  background-color: transparent;
  padding: 0;
  display: block;
  font-size: 0.85em;
  font-weight: 500;
}
.is-bar-mobil {
  padding-top: 3rem !important;
}

.nav-pills .nav-link.color-dark:not(.active):hover,
.nav-pills .nav-link:not(.active):hover {
  color: #fff !important;
}

div#container-sidebar-col {
  margin: 0px;
  padding: 0 0 0 15px;
  font-size: 0.9em;
  max-width: fit-content !important;
}

div#contenedor-taquilla-main {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  justify-content: space-evenly;
}

button.btn-base.border-green.btn-jugadas.btn-streaming > span {
  font-weight: 400;
  font-size: 13px;
}
div.swal2-content.contentCustomPosition > div.swal2-html-container {
  text-align: center !important;
}
div.swal2-footer.contentFinal {
  display: flow-root;
  text-align: center;
}

#container-juegos.bg-dark {
  background-color: #41474e !important;
}

.container-2-btns {
  width: 100%;
  padding-top: 0px !important;
  outline-offset: 0em;
  display: flex;
}

/*padding: 2px !important;*/
button.btn-base.border-green.btn-jugadas.btn-streaming > span {
  outline-offset: 0em;
}

.btn-bordes-orange {
  background: linear-gradient(to right, #f09819, #ff512f) !important;
}

button.btn-bordes-orange > span {
  width: 100%;
  background-color: #222;
  padding: 5px 8px;
  border-radius: 2px;
}
button.btn-bordes-orange > span:hover {
  background-color: transparent !important;
}

/*font-size: 0.7em;*/
button.btn-ver-tickets.sin-bordes {
  width: 100%;
  border: none;
  margin: 0;
  padding: 0.3em;
  outline-offset: 0em;
  outline: none;
  box-sizing: border-box;
  width: 50%;
  height: 33px;
}

.border-blue {
  background-image: linear-gradient(-90deg, #062276, #1b80b4);
}

.btn-base.border-green.btn-jugadas.btn-streaming,
.btn-base.border-blue.btn-jugadas.btn-streaming {
  width: 50%;
  text-align: center;
  border-radius: 0;
  /*height: 33px;*/
}

button.btn-base.border-green.btn-jugadas.btn-streaming:hover {
  background: linear-gradient(-90deg, #6bff00, #0d6403) !important;
  cursor: pointer;
  transform: scale(1);
  border-radius: 0;
  margin: 0;
  border: none;
}

#container-juegos
  > div
  > div.col-lg-10.col-sm-11
  > section
  > div
  > div
  > table
  > tbody
  > tr
  > td:nth-child(1)
  > table
  > thead
  > tr
  > th:nth-child(1) {
  padding-left: 1em !important;
}

.sub-menu-mini {
  background-color: rgba(0, 0, 0, 0.8);
  min-width: 250px;
  min-height: 40px;
  position: fixed;
  /*margin-left: 97px;*/
  z-index: 10;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.title-mini-menu {
  font-weight: 300;
  padding: 5px 0;
}

li.nav-item > a.nav-link.mini:hover {
  background-color: #011f62;
  color: #fff !important;
  border-radius: 0px !important;
}

.fecha-ticket {
  max-width: 150px;
  max-height: 38px;
  padding: 0px;
  margin: 0px;
}

.fecha-ticket > input {
  max-height: 40px;
}

/*background-image: linear-gradient(178deg, #32383e, #95949478);*/
#modal-streaming button.btn.btn-default {
  font-size: 1em;
  font-weight: 400;
  color: #ffffff;
}

#modal-streaming button.btn.btn-default {
  background-color: #ddd;
  background-image: none;
  color: #636363 !important;
  text-shadow: none;
  border-color: #dee2e6;
  border: solid 1px;
  font-weight: 400;
}

#modal-streaming button.btn.btn-default:hover {
  background-color: transparent !important;
  color: #636363 !important;
  font-weight: 500;
}

#modal-streaming footer {
  background-color: #fff !important;
}

#modal-streaming footer button {
  border: 0;
  background-image: linear-gradient(178deg, #3e76f3, #001f62);
  color: #eee !important;
  text-transform: uppercase;
  font-size: 15px;
  text-shadow: 1px 1px 3px black;
}

.img-steaming {
  height: 20px;
}

#btnSteaming {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

#btnSteaming:hover {
  background-image: linear-gradient(127deg, #32383e, #00000078);
  /*font-size: 1em;*/
  color: rgb(255 255 255);
  font-weight: 400;
}

.btn-ver-tickets {
  margin: 2%;
  margin-bottom: 2.3%;
  border: 0px;
  width: 96%;
  background-color: transparent;
  text-align: center;
  color: #fff;
  padding: 0.2em 0px;
  text-transform: uppercase;
  font-size: 0.8em;
  /*outline: 2px solid #fff;*/

  outline: 2px solid #b2b2b2;
  text-align: center !important;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  padding-bottom: 0px;
  margin-bottom: 3px;
}

.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: 2px;
}

.btn-ver-tickets:hover {
  /*
  outline-color: transparent;
  outline-color: #ffffff2e;
  */
  outline-color: #b2b2b2;
  background-image: linear-gradient(45deg, #767676, transparent);
  color: #fff !important;
  text-shadow: #000 1px 1px 3px;
}

.gold-baged {
  /*
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(ellipse farthest-corner at left top, #fff 0%, #fedb37 8%, #d1b464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  */

  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #6afe00 0%,
      #54ba0a 0%,
      #187603 5%,
      #0d6403 50%,
      transparent 80%
    ),
    radial-gradient(ellipse farthest-corner at left top, #69fc00 0%, #6afe00 0%, #54ba0a 5%, #15490f 80%, #0d6403 100%);
  color: #fff !important;
  font-weight: 500;
  font-size: 1em;
  width: 100% !important;
  text-shadow: #000 1px 1px 5px;
  cursor: pointer;
  transition: color 0.8s linear 0.2s;
}

.gold-baged:hover {
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #6afe00 0%,
      #54ba0a 8%,
      #187603 30%,
      #0d6403 50%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #69fc00 0%,
      #6afe00 8%,
      #54ba0a 25%,
      #15490f 62.5%,
      #0d6403 100%
    );
  background-color: rgba(0, 0, 0, 0);
}

#swal2-content {
  display: block;
  text-align: justify;
  margin-top: 1em;
}

.swal2-label {
  display: inline-block;
  text-align: justify;
  font-size: 0.85em;
}

.swal2-footer {
  display: block !important;
}

.txt-error {
  color: #d0d0d0;
  font-size: 13px;
}

.col-vs {
  max-width: 20%;
  left: 8%;
  font-size: 1.2em;
  display: block;
  padding: 0px;
  line-height: 2;
  font-weight: 200;
}

.col-names {
  max-width: 80%;
  right: 7%;
  text-align: right;
  width: 100%;
  padding: 5px 0px;
}

div.container-game {
  padding-bottom: -1em;
}

div.col-md-4.padding1 {
  padding: 0 0.3em;
}

#taq td,
#taq2 td,
#taq3 td {
  font-size: 15px;
  font-weight: 300;
  padding: 3px 0 !important;
}

.p-sidebar {
  padding: 0px;
  padding-right: 1em;
}

div.container-game > h5.text-center.mt-2.sub-title {
  margin-top: 1em !important;
  border-top: solid 1px #ffffff7d;
  padding-top: 0.5em;
  border-top-style: dashed;
  border-top-width: thin;
}

.btn-destroy {
  padding: 0.3em !important;
  position: absolute;
  right: 0.8em;
  font-weight: 300;
  transform: translate(0px, -0.5em);
  z-index: 3;
}

.title-card-apuestas {
  font-size: 0.95em;
  margin-bottom: 0px;
  width: 100%;
}

.btn.float-right.close.btn-secondary:hover {
  /*
    box-shadow: #000 0px 0px 2px;
  */
  background-image: linear-gradient(-90deg, rgba(255, 0, 0, 0.8), rgba(253, 15, 4, 1));
  color: #fff;
}

.callout.callout-success {
  border-left-color: #64f100 !important;
}

.txt-momento {
  font-size: 0.8em;
  width: 100%;
  display: block;
  text-align: center;
  padding-left: 5px;
  margin-bottom: 0px;
}

.txt-code {
  font-size: 0.8em;
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 0px;
}

/*display: block;*/
.txt-teams {
  font-size: 0.8em;
  width: 100%;
  text-align: left;
  padding-left: 5px;
  display: flex;
  justify-content: flex-start;
  min-width: 100%;
}

.callout-apuestas {
  width: 98%;
  margin-bottom: 0.5rem !important;
}

.vc-pane-layout {
  width: 238px !important;
}

.vc-weeks,
.vc-header {
  min-width: 235px !important;
  max-width: 235px;
}

table.table-internal thead tr th {
  font-size: 14px;
  font-weight: 400;
}

table.table-internal tbody tr td,
.table.table-dark.mt-4.table-destacados,
.container-game table {
  font-size: 12px;
  font-weight: 400;
  height: 1em;
  cursor: pointer;
}

table.table-internal tbody tr td {
  padding: 0.3em;
}

table.table-internal tbody tr td:not(td:nth-child(1)) {
  text-align: center;
}

table.table-internal thead tr:nth-child(2) {
  background-color: #31373d;
  text-align: center;
}

table.table-internal tbody tr td:hover {
  background-color: #fff;
  color: #303030;

  -webkit-animation: fadein 1.5s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.5s;
  /* Firefox < 16 */
  -ms-animation: fadein 1.5s;
  /* Internet Explorer */
  -o-animation: fadein 1.5s;
  /* Opera < 12.1 */
  animation: fadein 1.5s;
}

/*Efecto Fade hover*/
@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
<style scoped="scoped">
.bg-input-date {
  background-color: rgb(33 36 40) !important;
  font-family: unset;
  font-weight: 200;
  font-size: 1.5em;
  border-style: dotted !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.vdp-datepicker.datepicker-custom-dark,
.vdp-datepicker.datepicker-custom-dark > div,
.vdp-datepicker.datepicker-custom-dark > div input,
.datepicker-custom-dark input[type="text"] {
  width: 100% !important;
}

.vdp-datepicker__calendar {
  background-color: #000 !important;
}

.no-pr {
  padding-right: 0 !important;
}

.active-option {
  background-color: #eee !important;
  color: #4e555b !important;
}

.active-option:hover {
  color: #eee !important;
}

.color-orange {
  background: #ffb347;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffcc33, #ffb347);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffcc33, #ffb347);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.btn.btn-primary.text-center.btn-generar.color-orange:hover {
  columns: #fff !important;
  font-weight: 1.5em !important;
}

.btn.color-submenu {
  cursor: pointer !important;
}

.color-submenu {
  background-attachment: cadetblue;
  color: #fff;
}

.input-modal {
  max-width: 150px;
}

.input-date {
  max-width: 150px;
  margin-left: 0.5rem !important;
}

h1 div {
  color: #fff;
}

.sub-title {
  font-weight: 200;
}

.sidebar-container button:hover {
  background-color: #eee;
  background-image: linear-gradient(127deg, #32383e, #00000078);
  /*color: #4e555b;*/
}

#container-juegos {
  overflow: hidden;
}

.btn-generar {
  background-color: #294058;
  border-color: #294058;
}

.btn-generar:hover {
  background-color: #325375;
  border-color: #325375;
}

.btn-generar:active,
.btn-generar:focus {
  box-shadow: 0 0 0 0.2rem #325375;
}
</style>
<style>
/*100px !important;*/
#container-juegos {
  padding-top: 70px !important; /*110 old*/
  height: auto;
  page-break-after: 1em !important;
}

#container-juegos {
  color: #fff;
}

.sidebar-container {
  width: 100%;
  word-break: break-word;
  z-index: 100;
  padding-right: 0px;
}

.sidebar-container button {
  word-break: break-word;
}

.btn-group-vertical.sidebar-container.tl button {
  text-align: left;
}

.selector-range-date {
  padding: 0.5em;
  background-color: #6c757d;
}

.table-internal {
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
}

.table-internal thead tr th,
.table-internal tbody tr td {
  border: 0;
}

.table-internal thead > tr:nth-child(1) {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  border-color: #32383e;
}

.table-destacados thead tr th {
  font-size: 12px;
  font-weight: 400;
}

/*size font contenido de la tabla*/
#container-juegos > div > div.col-sm > table > tbody > tr > td > table > tbody > tr > td {
  font-size: 0.8rem;
  font-weight: 400;
  height: 1em;
  padding: 0.5em !important;
  line-height: 1 !important;
}

#container-juegos > div > div.col-sm > table > tbody > tr > td > table > tbody > tr > td,
.table-destacados thead tr th {
  font-size: 1rem;
  font-weight: 400;
  padding: 0.2rem !important;
}

#container-juegos > div > div.col-sm > table > tbody > tr > td > table > thead > tr:nth-child(1) > th,
.line-b {
  /*
    vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
      border-color: #32383e;
      */
}

.btn-group-vertical.sidebar-container button {
  background-color: #32383e;
  border-color: #32383e;
}

.full-size {
  width: 100%;
}

.no-padding {
  padding: 0%;
}

.cuotas-container {
  margin: 1em 0px !important;
  height: auto !important;
}

#modal-tickets > div.modal-dialog {
  widows: 100%;
  max-width: 1200px !important;
}

#modal-tickets ul.customPagination > li > a,
#modal-tickets ul.customPagination > li > span.page-link,
#modal-tickets ul.customPagination > li > button.page-link,
#modal-tickets .customPagination.page-link {
  background-color: #222529;
  border-color: #222529;
  color: #e5eaff;
}

#modal-tickets .customPagination > li.active > a,
#modal-tickets .customPagination .page-item.active .page-link {
  color: #e5eaff;
  background-color: #222529 !important;
  border-color: #222529 !important;
}

#modal-tickets .customPagination > li > a:hover {
  color: #e5eaff;
  background-color: #222430 !important;
}

#modal-tickets .customPagination > li.page-item.disabled > span.page-link {
  background-color: rgba(0, 0, 0, 0.4);
  border-color: #222529;
  color: #c1c1c1;
}

#modal-tickets .customPagination > li.page-item.active > button.page-link {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
