<template>
  <div class="card p-0 no-margin text-center pt-2" v-if="!this.getIsMobilResolution && getBalaces && getBalaces.data">
    <div class="table-responsive mailbox-messages">
      <div class="table-balances">
        <table class="table table-hover table-striped mb-0">
          <thead>
            <tr class="text-center">
              <th width="5%"></th>
              <th width="15%">{{ $t("fecha") }}</th>
              <th width="15%">Ticket</th>
              <th width="15%">{{ $t("tipo") }}</th>
              <th width="25%">{{ $t("monto") }}</th>
              <th width="25%">{{ $t("saldo") }}</th>
            </tr>
          </thead>
        </table>
        <div style="height: 25.5em; overflow: auto">
          <table class="table table-hover table-striped">
            <tbody>
              <tr
                v-for="(balance, index) in getBalaces.data"
                :key="index"
                v-show="getBalaces && getBalaces.data"
                class="text-center"
              >
                <td width="5%" class="mailbox-star">
                  <i class="fas fa-donate text-warning"></i>
                </td>
                <td width="15%">{{ dateFormat(balance.fecha) }}</td>
                <td width="15%">
                  <b>{{ balance.ticket }}</b>
                </td>
                <td width="15%">{{ balance.tipo }}</td>
                <td width="25%">
                  <b>{{ formatoMonto(balance.monto) }}</b>
                </td>
                <td width="25%">{{ formatoMonto(balance.saldo) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <BalancesTableMovil @toPage="toPage" v-else></BalancesTableMovil>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import * as u from "j899";
import { paginatorTable } from "../../mixins/paginatorTableMixin.js";

import BalancesTableMovil from "../../components/movil/BalancesTableMovil.vue";

export default {
  name: "BalancesTable",
  props: {
    page: Number,
    perPage: Number
  },
  mixins: [paginatorTable],
  computed: {
    ...mapGetters(["getBalaces", "getIsMobilResolution"])
  },
  components: {
    BalancesTableMovil
  },
  methods: {
    toPage: function(page) {
      this.$emit("changePage", page);
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm a");
      }
    }
  },
  data() {
    return {
      currentPage: 1,
      totalResults: 0
    };
  },
  mounted() {
    this.totalResults = this.getBalaces ? parseInt(this.getBalaces.total) : 0;
    this.currentPage = this.getBalaces ? parseInt(this.getBalaces.page) : 0;

    u.tag("Render", "success", "table balances");

    this.$emit("updateTotalResults", this.totalResults);
    this.$emit("updateLimitResults", this.perPage);
    this.$emit("updatePageResults", this.currentPage);
  }
};
</script>
<style scoped="scoped">
.card {
  margin-top: 100px;
  width: 95%;
}
.col-ticket {
  width: 20%;
}
.col-type {
  width: 28%;
}
.txt-center {
  text-align: center;
}
.col-ticket,
.col-ticket > b {
  word-wrap: break-word;
  max-width: 200px;
}

.table-responsive.mailbox-messages,
.table-responsive.mailbox-messages > div,
.table-balances,
.no-margin {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.col-icon {
  width: 50px;
}
.col-type {
  width: 220px;
}
</style>
