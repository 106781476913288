var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{ 'container-games-listado': true, 'taquilla-flotante-activa': this.getTaquillaFlotante }},[(this.destacado)?_c('h3',{class:{
      'text-center pt-1 pb-1': true,
      'txt-color-theme-l': this.getThemelight
    }},[_vm._v("\n    "+_vm._s(_vm.$t("juegos_destacados"))+"\n  ")]):_vm._e(),(this.getGames && this.getGames.length == 0)?_c('div',[_c('div',{staticClass:"card dark-bg text-light text-center my-3",class:{ 'light-bg': this.getThemelight }},[_c('h5',{staticClass:"my-4"},[_vm._v("No hay juegos disponibles")])])]):_c('div',_vm._l((this.getGames),function(game,index){return _c('div',{key:index,staticClass:"game"},[_c('gridBaseGame',{attrs:{"gameDetails":_vm.getDataGame(game)}})],1)}),0),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Subir al top'),expression:"'Subir al top'",modifiers:{"hover":true,"left":true}}],class:{
      'btn float-right': true,
      'btn-dark': !this.getThemelight,
      'btn-light': this.getThemelight,
      'mr-3': this.getTaquillaFlotante
    },on:{"click":_vm.toTop}},[_c('i',{staticClass:"fas fa-angle-up"})])])}
var staticRenderFns = []

export { render, staticRenderFns }