import Vue from "vue";
import Swal from "sweetalert2";
import VueLazyload from "vue-lazyload";
import VCalendar from "v-calendar";

import App from "./App.vue";
import Layout from "./Layout.vue";

import { userService } from "./services/userService";
import store from "./store/index.js";
import CKEditor from "ckeditor4-vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vueDebounce from "vue-debounce";
import moment from "moment";
import vmodal from "vue-js-modal";
import vuescroll from "vuescroll";
import i18n from "./i18n";

import ActivationUser from "./pages/ActivationUser";
// import $ from "jquery";
require("jquery");
require("jquery-ui-bundle");

Vue.prototype.$vuescrollConfig = {
  bar: {
    background: "#232323"
  }
};

Vue.use(vuescroll);
Vue.use(vmodal);

Vue.use(VCalendar, {
  componentPrefix: "vc",
  title: "MMMM YYYY",
  weekdays: "W",
  navMonths: "MMM",
  input: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
  dayPopover: "WWW, MMM D, YYYY",
  data: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
  sm: "200px",
  md: "200px",
  lg: "200px",
  xl: "1280px",
  screens: {
    tablet: "576px",
    laptop: "992px",
    desktop: "1200px"
  }
}); // Use <vc-calendar />

Vue.prototype.Swal = Swal;

// options
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "./img/noimg.png",
  loading: "./img/loading.gif",
  attempt: 1
});

Vue.use(vueDebounce, {
  lock: true,
  listenTo: ["input", "keyup"],
  defaultTime: "700ms"
});

import "./registerServiceWorker";
window.$ = window.jQuery = require("jquery");
Vue.config.productionTip = false;

Vue.prototype.$userService = userService;
// Vue.prototype.$store = store;

import Loader from "./components/Loader.vue";
import NotFound from "./pages/NotFound.vue";
import frmRegistro from "./pages/frmRegistro";
import FlutterPreguntas from "./pages/FlutterPreguntas";

import iframePage from "./pages/iframePage.vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap-vue/dist/bootstrap-vue.css";

// Vue.component('b-form-select', require('bootstrap-vue').default);
import { BTable, BPagination, BThead, BFormSelect } from "bootstrap-vue";

import router from "./router";

Vue.component("b-form-select", BFormSelect);
Vue.component("b-table", BTable);
Vue.component("b-pagination", BPagination);
Vue.component("b-thead", BThead);

Vue.use(CKEditor);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

window.moment = require("moment");

// Routes
const routes = {
  "/": App,
  "/Home": App,
  "/Contacto": App,
  "/about": Loader,
  "/user/activate": ActivationUser,
  "/modelado-flexible": Layout,
  "/iframe-page": iframePage,
  "/formulario-registro": frmRegistro,
  "/flutter-preguntas": FlutterPreguntas
};

new Vue({
  el: "#app",

  components: {
    VCalendar
  },

  computed: {
    ViewComponent() {
      return routes[this.currentRoute] || NotFound;
    }
  },

  data() {
    return {
      currentRoute: window.location.pathname,
      clean: false
    };
  },

  created() {
    let __self = this;

    window.ononline = function() {
      __self.$store.commit("setHasConexion", true);
      console.log({ getHasConexion: __self.$store.getters.getHasConexion });
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
        // didOpen: (toast) => {
        //   toast.addEventListener('mouseenter', Swal.stopTimer)
        //   toast.addEventListener('mouseleave', Swal.resumeTimer)
        // }
      });

      Toast.fire({
        icon: "success",
        title: "Se ha recobrado conexion a Internet"
      });
    };

    window.onoffline = function() {
      __self.$store.commit("setHasConexion", false);
      console.log({ getHasConexion: __self.$store.getters.getHasConexion });
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
        // didOpen: (toast) => {
        //   toast.addEventListener('mouseenter', Swal.stopTimer)
        //   toast.addEventListener('mouseleave', Swal.resumeTimer)
        // }
      });

      Toast.fire({
        icon: "warning",
        title: "Se ha perdido la conexion de internet"
      });
    };
    setInterval(async () => {
      // Verificacion y validacion de token
      let tk = __self.$store.getters.getToken ? __self.$store.getters.getToken.access_token : null;
      const validationTk = await __self.$store.dispatch("isValidToken");

      // console.log(`control expiracion validationTk: ${validationTk}`, tk);

      if (
        __self.$store.getters.getSecurity === true &&
        (!__self.$store.getters.getToken || !validationTk || tk == null)
      ) {
        await __self.$store.dispatch("clearAndRedirect");

        // logOut ya se encuentra dentro de (clearAndRedirect) await __self.$store.dispatch("logOut");
      }
      // if (__self.$store.getters.getToken) {
      //   console.log("control 60s Local", validationTk, __self.$store.getters.getToken.access_token);
      // }
      // 300000  milisegundos - son -> 300  seg  equivale cada 5 min que cheque expiracion
      // 60000   milisegundos - son -> 60   seg  equivale cada 1 min que cheque expiracion
    }, 60000);

    // va descostando 1 segundo a la session activa
    setInterval(async () => {
      if (__self.$store.getters.getControlTime) {
        // momento exacto en que se logueo y se calcula contra la diferencia
        let a = moment(parseInt(__self.$store.getters.getControlTime));
        let b = moment(new Date().getTime() + 2000);
        const diferenciaSegundos = b.diff(a, "seconds");

        if (__self.$store.getters.getExpire > 0 && __self.$store.getters.getUser != null) {
          let primeraExtension = __self.$store.getters.getExpire - 250;
          let segundaExtension = __self.$store.getters.getExpire - 70;

          // debug y modelado
          // __self.$bvModal.show("extenderSessionModal");

          if (diferenciaSegundos == primeraExtension || diferenciaSegundos == segundaExtension) {
            __self.$bvModal.show("extenderSessionModal");
          }

          if (diferenciaSegundos >= __self.$store.getters.getExpire) {
            __self.$bvModal.hide("extenderSessionModal");
            await __self.$store.dispatch("clearAndRedirect", { force: true });
            this.clean = true;
          }
        }
      }

      // const cantidad = __self.$store.getters.getExpire ? parseInt(__self.$store.getters.getExpire) - 1 : 0;
      // if (__self.$store.getters.getExpire > 0 && __self.$store.getters.getUser != null) {
      //   await __self.$store.commit("setExpire", cantidad);
      //   if (cantidad == 250) {
      //     __self.$bvModal.show("extenderSessionModal");
      //   }
      //   if (cantidad == 70) {
      //     __self.$bvModal.show("extenderSessionModal");
      //   }
      //   if (cantidad == 0) {
      //     __self.$bvModal.hide("extenderSessionModal");
      //     if (!this.clean) {
      //       await __self.$store.dispatch("clearAndRedirect");
      //       this.clean = true;
      //     }
      //   }
      // }

      if (__self.$store.getters.getUser != null) {
        // var startDate = new Date(__self.$store.getters.getControlTime);
        // var endDate = new Date();
        // var diffTime = window.moment(endDate).diff(startDate);
        //  a.diff(b)//To get the difference in milliseconds
        //  a.diff(b,'seconds')//To get the difference in seconds
        //  a.diff(b,'minutes')//To get the difference in minutes
        //  a.zone()//Get the timezone offset in minutes
        //  const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
        // // Example
        // let diferencia = diffDays(new Date(parseInt(__self.$store.getters.getControlTime)), new Date());
      }
    }, 1000);
  },

  render(h) {
    return h(this.ViewComponent);
  },

  //render: h => h(App),
  store,
  i18n,
  router,
  routes
});
//.$mount("#app");
