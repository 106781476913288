import baseApiPWA from "@/api/baseApiPWA";
import Swal from "sweetalert2";
import * as u from "j899";

export const actionLeagues = async ({ commit }, period = "") => {
  const { data } = await baseApiPWA.get(`api/deportes?leagues${period}`);

  let leagues = Object.entries(data.leagues);

  leagues.sort(function(a, b) {
    return Object.keys(b[1]).length - Object.keys(a[1]).length;
  });

  const objLeagues = {};

  leagues.forEach(element => {
    objLeagues[element[0]] = element[1];
  });

  commit("setLeaguesStore", objLeagues);
};

export const actionGames = async ({ commit }, params) => {
  let dataFiltro = `destacados`;

  if (params) {
    dataFiltro = `games`;
    dataFiltro += params.id ? `&league_id=${params.id}` : ``;
    dataFiltro += params.fecha ? `&date=${params.fecha}` : ``;
  }

  const { data } = await baseApiPWA.get(`api/deportes?${dataFiltro}`);

  let gamesType = "destacados";

  let dataGames = [];

  if (data.games) {
    dataGames = Object.values(data.games);
    dataGames.pop();
    gamesType = "games";
  } else {
    dataGames = data.destacados;
  }

  commit("setGamesStore", dataGames);
  commit("setGamesType", gamesType);
};

export const actionRestrictions = async ({ commit }) => {
  const { data } = await baseApiPWA.get(`api/sport?bet_init_new`);

  commit("setBetInitData", data.bet_init_new);
};

export const verifyOdds = async ({ state, commit }) => {
  if (state.oddsRestricteds.length > 0) {
    Swal.fire({
      position: "top",
      icon: "error",
      title: "Ha ocurrido un error",
      text:
        "Existen combinaciones de jugadas no permitidas para la modalidad PARLEY. Debe eliminar alguna de la jugadas seleccionadas[Resaltadas]."
    });
    commit("setMarkRestricted", true);
  } else {
    commit("setMarkRestricted", false);
    commit("setBetType", "Parley");
  }
};

export const processOdd = ({ state, commit }, odd) => {
  let game_selected = state.odds.filter(element => element.game_id === odd.game_id);
  let odd_selected = state.odds.find(element => element.id == odd.id);

  if (game_selected.length == 0 || odd_selected) {
    commit("setOdds", odd);
    return true;
  }

  let restrictions = state.betInitData.restr[odd.sport_id];

  const restrFitered = restrictions.filter(element => Object.values(element).includes(Number(odd.idOdd)));

  let valid = false;
  let exist = false;

  restrFitered.forEach(element => {
    game_selected.forEach(game => {
      exist = Object.values(element).includes(Number(game.idOdd)) && game.idOdd != odd.idOdd;
      if (exist) {
        valid = true;
        commit("setOdds", odd);
      }
    });
  });

  if (!valid) {
    if (state.betType == "Directa") {
      commit("setOdds", odd);
      commit("setOddsRestricteds", odd.id);
    } else {
      Swal.fire({
        position: "top",
        icon: "error",
        text: "Combinacion no permitida para la modalidad PARLEY"
      });
    }
  }
};

export const deleteRestrictednOdd = ({ state, commit }, odd) => {
  let oddExisted = state.oddsRestricteds.includes(odd.id);

  if (oddExisted) {
    commit("setOddsRestricteds", odd.id);
  }
};

export const cleanOdds = ({ commit }) => {
  Swal.fire({
    title: "¿Desea eliminar las apuestas seleccionadas?",
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: `Confirmar`,
    denyButtonText: `Cancelar`
  }).then(result => {
    if (result.isConfirmed) {
      commit("setOdds", []);
      commit("setPrize", 0);
      commit("setAmount", 0);
      commit("setCleanOddsRestrictions");
      commit("setMarkRestricted", false);
    }
  });
};

export const updatePrize = ({ commit, state }) => {
  let base = 0;
  let premio = 0;
  let premioInd = 0;

  state.odds.forEach(element => {
    base = element.odds > 0 ? state.amount * (element.odds / 100) : state.amount / (element.odds / -100);
    premio += base + Number(state.amount);
    premioInd = base + Number(state.amount);

    return (element.prize = parseFloat(premioInd).toFixed(2));
  });

  commit("setPrize", parseFloat(premio).toFixed(2));
};

export const validTicket = ({ commit, state }) => {
  let msj = null;

  if (state.betType == "Parley" && state.odds.length < state.betInitData.minimo) {
    msj = "El mínimo de jugadas parley debe ser " + state.betInitData.minimo;
  }

  if (state.odds.length > 1 && state.odds.length > state.betInitData.maximo) {
    msj = "El máximo de jugadas parley debe ser " + state.betInitData.maximo;
  }

  if (parseFloat(state.amount) < parseFloat(state.betInitData.min_tck)) {
    msj = "La jugada por parley minima debe ser " + u.inNumeration("LA", parseFloat(state.betInitData.min_tck));
  }

  if (parseInt(state.amount) <= 0 || String(state.amount).trim() == "") {
    msj = "<p class='text-center'>Monto inválido por favor verifiqué</p>";
  }

  commit("setMsjError", msj);
};
