<template>
  <div id="loader" class="container-loader" v-if="cargando">
    <div class="loader"></div>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: {
    cargando: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style scoped="scoped">
div#loader.container-loader {
  width: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  height: auto;
  text-align: center;
  align-content: center;
  position: fixed;
}
div#loader > div.loader {
  align-items: center;
  justify-content: center;
  border-width: 5px;
}
</style>
