var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-game"},[_c('details',{staticClass:"details card text-center",class:{ 'details-light text-dark': this.getThemelight, details: !this.getThemelight },on:{"click":function($event){return _vm.abrirDetalle(_vm.gameDetails.muestraTitulo)}}},[_c('summary',{staticClass:"my-2",staticStyle:{"color":"#ffffff"}},[(_vm.gameDetails.muestraTitulo || _vm.show)?_c('div',{staticClass:"titleGame text-center",class:{ 'titleGame-light': this.getThemelight }},[_c('p',[_vm._v(_vm._s(this.gameDetails.gameName)+" ("+_vm._s(this.gameDetails.gameDate)+")")])]):_vm._e(),_c('div',{staticClass:"row text-center",class:{ 'bg-header-light font-weight-bold': this.getThemelight },staticStyle:{"font-size":"0.9em"}},[_c('div',{staticClass:"col-4 border-right"},[_vm._v("\n          "+_vm._s(this.gameDetails.gameTime)+"\n        ")]),_c('div',{staticClass:"col-4 border-right"},[_c('span',[_vm._v(" "+_vm._s(this.gameDetails.teams[0].name)+" (1) ")])]),_c('div',{staticClass:"col-4"},[_c('span',[_vm._v(" "+_vm._s(this.gameDetails.teams[1].name)+" (2) ")])])])]),_c('table',{staticClass:"mb-1",attrs:{"width":"100%","id":"oddsTable"}},[_c('tbody',[(_vm.gameDetails.teams[0]['1X2FT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT1'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            1X2 FT "),_c('br'),_vm._v("\n            (A Ganar)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX1X'),
              'btnOdd-light font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX1X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX1X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["1X2FT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX2X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX2X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX2X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["1X2FT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['EMPATE'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['EMPATE'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT2'}},[_c('td',{staticClass:"refeOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            X (FT) "),_c('br'),_vm._v("Empate\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX7X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"60%","colspan":"2","id":'FTX7X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX7X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["EMPATE"])+"\n          ")])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }