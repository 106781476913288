var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'contenedor-grid': true, 'light-theme': this.getThemelight }},[_c('div',{staticClass:"Descriptions"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(this.formatoFecha(this.gameDetails.time)))]),_c('div',{staticClass:"Team1"},[_vm._v(_vm._s(this.gameDetails.teams[0].name))]),_c('div',{staticClass:"Team2"},[_vm._v(_vm._s(this.gameDetails.teams[1].name))]),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['EMPATE']) || _vm.isNotEmpty(_vm.gameDetails.teams[1]['EMPATE']))?_c('div',{staticClass:"empate-title"},[_vm._v("\n      Empate\n    ")]):_vm._e()]),_c('div',{staticClass:"Jugadas"},[(_vm.isNotEmpty(_vm.gameDetails.teams[0]['1X2FT']))?_c('div',{staticClass:"jugada"},[_c('span',[_vm._v("1X2 FT")]),_c('div',{class:{
          team1Value: true,
          'update-bg-2': _vm.fueActualizada('FTX1X'),
          'apuesta-seleccionada': _vm.existeApuesta('FTX1X')
        },on:{"click":function($event){return _vm.clickApuesta('FTX1X', _vm.gameDetails.teams[0]['1X2FT'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[0]["1X2FT"])+"\n      ")]),_c('div',{class:{
          team2Value: true,
          'update-bg-2': _vm.fueActualizada('FTX2X'),
          'apuesta-seleccionada': _vm.existeApuesta('FTX2X')
        },on:{"click":function($event){return _vm.clickApuesta('FTX2X', _vm.gameDetails.teams[1]['1X2FT'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[1]["1X2FT"])+"\n      ")]),(_vm.isNotEmpty(_vm.gameDetails.teams[1]['EMPATE']))?_c('div',{class:{
          team1Value: true,
          'update-bg-2': _vm.fueActualizada('FTX7X'),
          'apuesta-seleccionada': _vm.existeApuesta('FTX7X')
        },on:{"click":function($event){return _vm.clickApuesta('FTX7X', _vm.gameDetails.teams[1]['EMPATE'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[1]["EMPATE"])+"\n      ")]):_vm._e(),(!_vm.isNotEmpty(_vm.gameDetails.teams[1]['EMPATE']) && _vm.isNotEmpty(_vm.gameDetails.teams[0]['EMPATE']))?_c('div',{class:{
          team1Value: true,
          'update-bg-2': _vm.fueActualizada('FTX7X'),
          'apuesta-seleccionada': _vm.existeApuesta('FTX7X')
        },on:{"click":function($event){return _vm.clickApuesta('FTX7X', _vm.gameDetails.teams[0]['EMPATE'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[0]["EMPATE"])+"\n      ")]):_vm._e()]):_vm._e(),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['HDPFT']))?_c('div',{staticClass:"jugada"},[_c('span',[_vm._v(" HDP FT ")]),_c('div',{class:{
          team1Value: true,
          'update-bg-2': _vm.fueActualizada('FTX3X'),
          'apuesta-seleccionada': _vm.existeApuesta('FTX3X')
        },on:{"click":function($event){return _vm.clickApuesta('FTX3X', _vm.gameDetails.teams[0]['HDPFT'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[0]["HDPFT"])+"\n      ")]),_c('div',{class:{
          team2Value: true,
          'update-bg-2': _vm.fueActualizada('FTX4X'),
          'apuesta-seleccionada': _vm.existeApuesta('FTX4X')
        },on:{"click":function($event){return _vm.clickApuesta('FTX4X', _vm.gameDetails.teams[1]['HDPFT'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[1]["HDPFT"])+"\n      ")]),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['EMPATE']))?_c('div',{class:{
          team2Value: true,
          'update-bg-2': _vm.fueActualizada('FTX115X'),
          'apuesta-seleccionada': _vm.existeApuesta('FTX115X')
        },on:{"click":function($event){return _vm.clickApuesta('FTX115X', _vm.gameDetails.teams[0]['EMPATE'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[0]["EMPATE"])+"\n      ")]):_vm._e()]):_vm._e(),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['TOTALESFT']))?_c('div',{staticClass:"jugada"},[_c('span',[_vm._v(" TOTALES FT ")]),_c('div',{class:{
          team1Value: true,
          'update-bg-2': _vm.fueActualizada('FTX5X'),
          'apuesta-seleccionada': _vm.existeApuesta('FTX5X')
        },on:{"click":function($event){return _vm.clickApuesta('FTX5X', _vm.gameDetails.teams[0]['TOTALESFT'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[0]["TOTALESFT"])+"\n      ")]),_c('div',{class:{
          team2Value: true,
          'update-bg-2': _vm.fueActualizada('FTX6X'),
          'apuesta-seleccionada': _vm.existeApuesta('FTX6X')
        },on:{"click":function($event){return _vm.clickApuesta('FTX6X', _vm.gameDetails.teams[1]['TOTALESFT'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[1]["TOTALESFT"])+"\n      ")]),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['EMPATE']) || _vm.isNotEmpty(_vm.gameDetails.teams[1]['EMPATE']))?_c('div'):_vm._e()]):_vm._e(),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['1X2HT']))?_c('div',{staticClass:"jugada"},[_c('span',[_vm._v("1X2 HT")]),_c('div',{class:{
          team1Value: true,
          'update-bg-2': _vm.fueActualizada('HTX1X'),
          'apuesta-seleccionada': _vm.existeApuesta('HTX1X')
        },on:{"click":function($event){return _vm.clickApuesta('HTX1X', _vm.gameDetails.teams[0]['1X2HT'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[0]["1X2HT"])+"\n      ")]),_c('div',{class:{
          team2Value: true,
          'update-bg-2': _vm.fueActualizada('HTX2X'),
          'apuesta-seleccionada': _vm.existeApuesta('HTX2X')
        },on:{"click":function($event){return _vm.clickApuesta('HTX2X', _vm.gameDetails.teams[1]['1X2HT'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[1]["1X2HT"])+"\n      ")])]):_vm._e(),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['HDPHT']))?_c('div',{staticClass:"jugada"},[_c('span',[_vm._v(" HDP HT ")]),_c('div',{class:{
          team1Value: true,
          'update-bg-2': _vm.fueActualizada('HTX3X'),
          'apuesta-seleccionada': _vm.existeApuesta('HTX3X')
        },on:{"click":function($event){return _vm.clickApuesta('HTX3X', _vm.gameDetails.teams[0]['HDPHT'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[0]["HDPHT"])+"\n      ")]),_c('div',{class:{
          team2Value: true,
          'update-bg-2': _vm.fueActualizada('HTX4X'),
          'apuesta-seleccionada': _vm.existeApuesta('HTX4X')
        },on:{"click":function($event){return _vm.clickApuesta('HTX4X', _vm.gameDetails.teams[1]['HDPHT'])}}},[_vm._v("\n        "+_vm._s(this.gameDetails.teams[1]["HDPHT"])+"\n      ")])]):_vm._e(),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['TOTALESHT']))?_c('div',{staticClass:"jugada"},[_c('span',[_vm._v(" TOTALES HT ")]),_c('div',{class:{
          team1Value: true,
          'update-bg-2': _vm.fueActualizada('HTX5X'),
          'apuesta-seleccionada': _vm.existeApuesta('HTX5X')
        },on:{"click":function($event){return _vm.clickApuesta('HTX5X', _vm.gameDetails.teams[0]['TOTALESHT'])}}},[_vm._v("\n        "+_vm._s(_vm.gameDetails.teams[0]["TOTALESHT"])+"\n      ")]),_c('div',{class:{
          team2Value: true,
          'update-bg-2': _vm.fueActualizada('HTX6X'),
          'apuesta-seleccionada': _vm.existeApuesta('HTX6X')
        },on:{"click":function($event){return _vm.clickApuesta('HTX6X', _vm.gameDetails.teams[1]['TOTALESHT'])}}},[_vm._v("\n        "+_vm._s(_vm.gameDetails.teams[1]["TOTALESHT"])+"\n      ")])]):_vm._e(),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['SI']))?_c('div',{staticClass:"jugada-ambos-anotan"},[_c('span',[_vm._v(" DOBLE CHANCE FT ")]),_c('div',{staticClass:"continer-doble-chance",staticStyle:{"background-color":"transparent!important"}},[_c('div',{staticStyle:{"background-color":"transparent!important"}},[_c('div',{staticClass:"tilte-lbl-ambos-anotan"},[_vm._v("X1")]),_c('div',{class:{
              'team2Value value-ambos-anotan': true,
              'update-bg-2': _vm.fueActualizada('FTX62X'),
              'apuesta-seleccionada': _vm.existeApuesta('FTX62X')
            },on:{"click":function($event){return _vm.clickApuesta('FTX62X', _vm.gameDetails.teams[0]['1X'])}}},[_vm._v("\n            "+_vm._s(_vm.gameDetails.teams[0]["1X"])+"\n          ")])]),_c('div',{staticStyle:{"background-color":"transparent!important"}},[_c('div',{staticClass:"tilte-lbl-ambos-anotan"},[_vm._v("12")]),_c('div',{class:{
              'team2Value value-ambos-anotan': true,
              'update-bg-2': _vm.fueActualizada('FTX63X'),
              'apuesta-seleccionada': _vm.existeApuesta('FTX63X')
            },on:{"click":function($event){return _vm.clickApuesta('FTX63X', _vm.gameDetails.teams[0]['12'])}}},[_vm._v("\n            "+_vm._s(_vm.gameDetails.teams[0]["12"])+"\n          ")])]),_c('div',{staticStyle:{"background-color":"transparent!important"}},[_c('div',{staticClass:"tilte-lbl-ambos-anotan"},[_vm._v("X3")]),_c('div',{class:{
              'team2Value value-ambos-anotan': true,
              'update-bg-2': _vm.fueActualizada('FTX64X'),
              'apuesta-seleccionada': _vm.existeApuesta('FTX64X')
            },on:{"click":function($event){return _vm.clickApuesta('FTX64X', _vm.gameDetails.teams[0]['X2'])}}},[_vm._v("\n            "+_vm._s(_vm.gameDetails.teams[0]["X2"])+"\n          ")])])]),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['EMPATE']))?_c('div',{staticClass:"no-bg-hover"}):_vm._e()]):_vm._e(),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['SI']))?_c('div',{staticClass:"jugada-ambos-anotan"},[_c('span',[_vm._v(" AMBOS ANOTAN FT ")]),_c('div',{staticClass:"continer-ambos-anotan",staticStyle:{"background-color":"transparent!important"}},[_c('div',{staticStyle:{"background-color":"transparent!important"}},[_c('div',{staticClass:"tilte-lbl-ambos-anotan"},[_vm._v("SI(GG)")]),_c('div',{class:{
              'team2Value value-ambos-anotan': true,
              'update-bg-2': _vm.fueActualizada('FTX49X'),
              'apuesta-seleccionada': _vm.existeApuesta('FTX49X')
            },on:{"click":function($event){return _vm.clickApuesta('FTX49X', _vm.gameDetails.teams[0]['SI'])}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["SI"])+"\n          ")])]),_c('div',{staticStyle:{"background-color":"transparent!important"}},[_c('div',{staticClass:"tilte-lbl-ambos-anotan"},[_vm._v("NO(NG)")]),_c('div',{class:{
              'team2Value value-ambos-anotan': true,
              'update-bg-2': _vm.fueActualizada('FTX50X'),
              'apuesta-seleccionada': _vm.existeApuesta('FTX50X')
            },on:{"click":function($event){return _vm.clickApuesta('FTX50X', _vm.gameDetails.teams[0]['NO'])}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["NO"])+"\n          ")])])]),(_vm.isNotEmpty(_vm.gameDetails.teams[0]['EMPATE']))?_c('div',{staticClass:"no-bg-hover"}):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }