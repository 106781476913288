<template>
  <div
    class="text-center animate__animated animate__backInUp top-pagos"
    :class="{ 'top-tablet': this.getVisualWidth < 1200 && this.getVisualWidth > 500 }"
  >
    <div class="row align-items-center my-2">
      <div class="col-3">
        <i
          class="fas fa-chevron-left fa-lg text-light"
          :class="{ 'text-dark': this.getThemelight }"
          v-on:click="back()"
        ></i>
      </div>
      <div class="col-6">
        <div class="text-title" :class="{ 'text-light': !getThemelight, 'light-h1': getThemelight }">Recargas</div>
      </div>
      <div class="col-3">
        <button class="btn btn-light btn-sm float-right" v-on:click="irARecarga()" v-if="!divisa && !monedaExtranjera">
          <b>Nueva</b>
        </button>
      </div>
    </div>

    <div style="height: 550px; !important; overflow: scroll; padding-bottom: 100px">
      <div v-if="this.divisa">
        <div class="flexbox">
          <div v-for="gateway in this.getCriptoGateways" v-bind:key="gateway.id" class="p-2 w-50">
            <div class="bg-light p-2 mb-2 rounded text-center sel-effect" @click="recargarCripto(gateway)">
              <img v-lazy="gateway.url" width="75" height="75" />
              <div>
                {{ gateway.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-for="payment in this.getCriptoPayments" v-bind:key="payment.id">
          <div class="row text-center border bg-title">
            <div class="col">
              <b>Fecha</b>
            </div>
          </div>
          <div class="row text-center">
            <div class="col">
              {{ dateTimeFormat(payment.created_at) }}
            </div>
          </div>
          <div class="text-center border bg-title">
            <b>Estado</b>
          </div>
          <div class="row text-center">
            <div class="col" v-if="payment.status == 0">Pendiente</div>
            <div class="col" v-if="payment.status == 2">Acreditado</div>
            <div class="col" v-if="payment.status == 3">Completado</div>
          </div>
          <div class="row text-center border bg-title">
            <div class="col-6">
              <b>Pasarela</b>
            </div>
            <div class="col-6">
              <b>Monto</b>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-6">
              {{ payment.gateway }}
            </div>
            <div class="col-6">{{ getSimboloMoneda }}{{ formatoMonto(payment.amount) }}</div>
          </div>
          <div class="row text-center border bg-title" v-if="payment.payer">
            <div class="col-12 border-right">
              <b>Cuenta</b>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-12">
              {{ payment.payer }}
            </div>
          </div>
          <div class="row text-center border bg-title" v-if="payment.ref">
            <div class="col-12 border-right">
              <b>Referencia</b>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-12">
              {{ payment.ref }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="monedaExtranjera">
        <div v-if="urlIframe">
          <iframe class="text-center" :src="urlIframe" width="800" height="1080"></iframe>
        </div>
        <RecargaMonnet @url="url" v-else />
      </div>
      <div class="card" v-for="payment of results" v-bind:key="payment.id" v-else>
        <div class="row border text-center bg-title">
          <div class="text-center">
            <b>Cuenta</b>
          </div>
        </div>
        <div class="row">
          <div class="text-center">
            {{ payment.cuenta }}
          </div>
        </div>
        <div class="row border text-center bg-title">
          <div class="text-center">
            <b>Número</b>
          </div>
        </div>
        <div class="row">
          <div class="text-center">
            {{ payment.numero }}
          </div>
        </div>
        <div class="row text-center border bg-title">
          <div class="col-6 border-right">
            <b>Banco</b>
          </div>
          <div class="col-6">
            <b>Monto</b>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-6">
            {{ payment.banco }}
          </div>
          <div class="col-6">
            {{ formatoMonto(payment.monto) }}
          </div>
        </div>

        <div class="row text-center border bg-title">
          <div class="col-6 border-right">
            <b>Fecha</b>
          </div>
          <div class="col-6">
            <b>Estado</b>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-6">
            {{ dateFormat(payment.fecha) }}
          </div>
          <div class="col-6">
            {{ payment.estado }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import RecargaMonnet from "../recargaMonnet.vue";

export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      results: [],
      position: 1,
      disablePager: false,
      divisa: 0,
      monedaExtranjera: false,
      urlIframe: null
    };
  },
  components: {
    RecargaMonnet
  },
  computed: {
    ...mapGetters([
      "getPayments",
      "getThemelight",
      "getVisualWidth",
      "getUser",
      "getCriptoGateways",
      "getCriptoPayments",
      "getIsMobilResolution",
      "getSimboloMoneda"
    ])
  },
  methods: {
    url: async function(url) {
      this.urlIframe = url;
    },
    back() {
      this.$bvModal.show("modal-cuenta");
    },
    formatoMonto(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateTimeFormat(value) {
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY HH:mm:ss A");
      }
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    irARecarga() {
      this.$emit("changePage", "Recargas");
    },
    async handleScroll() {
      if ($(window).scrollTop() >= $(document).height() - $(window).height() && !this.disablePager) {
        this.position++;
        await this.$store.dispatch("getPaymentsUserAsync", { page: this.position });

        this.getPayments.data.forEach(element => {
          this.results.push(element);
        });
      }
    },
    async getResults() {
      await this.$store.dispatch("getPaymentsUserAsync");
      this.results = this.getPayments.data;
    },
    async recargarCripto(gateway) {
      if (gateway.id == "BANCOS") {
        this.irARecarga();
      } else {
        await this.$store.commit("setCriptoWallet", gateway);

        this.$bvModal.show("modalCriptoWallet");
      }
    }
  },
  async mounted() {
    if (this.getThemelight) {
      document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
    }

    if (this.getUser.center_id == 6) {
      this.divisa = true;
    } else if ([5, 7, 8].includes(this.getUser.center_id)) {
      this.monedaExtranjera = true;
    }

    if (this.divisa) {
      await this.$store.dispatch("getCriptoAsync", {
        url: "api/crypto?gateways",
        set: "setCriptoGateways",
        name: "gateways"
      });

      await this.$store.dispatch("getCriptoAsync", {
        url: "api/crypto?payments",
        set: "setCriptoPayments",
        name: "payments"
      });
    }

    $("#container-preguntas").animate({ scrollTop: 550 }, 600);
    this.getResults();
  }
};
</script>

<style>
.container {
  width: 100%;
}

.top-tablet {
  margin-top: 20% !important;
}

.bg-title {
  background: #e8eaea;
}

.title-orange {
  /* font-size: 1.2em;
  font-weight: 600;
  font-family: sans-serif; */
  /* text-transform: uppercase; */
  /* color: rgb(243, 156, 18); */
}

.btn-custom {
  background-color: #ffffff;
  border-radius: 100%;
}
</style>
<style scoped>
.top-pagos {
  /* margin-top: 150px !important;
  height: auto;
  min-height: 300px;
  padding-left: 20px !important;
  padding-right: 20px !important; */
  margin-top: 130px !important;
  height: auto;
  max-height: 530px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  overflow: hidden;
}

.flexbox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.text-title {
  font-size: 1.2em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
}
</style>
