<template>
  <Ayudas :titulo="'¿Como Apostar?'" :data="this.getComoApostarCMS" />
</template>
<script>
import Ayudas from "../components/Ayudas.vue";
import { mapGetters } from "vuex";
import { essentialsData } from "../mixins/essentialsDataMixin.js";

export default {
  name: "ComoApostar",
  mixins: [essentialsData],
  components: { Ayudas },
  computed: {
    ...mapGetters(["getComoApostarCMS"])
  },
  methods: {
    async getAyuda() {
      await this.$store.dispatch("getData", {
        url: `/api/v1/getAyuda?pagina_id=2&perPage=`,
        set: "setComoApostarCMS",
        hander: this.evaluaRespuestaErrorRequestMultiplesCasos,
        handerName: "Como Apostar"
      });
    }
  },
  async mounted() {
    this.getAyuda();
  }
};
</script>
