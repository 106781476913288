<template>
  <div id="app" :class="{ 'overflow-hidden': this.getIsMobilResolution }">
    <FullLoader :cargando="this.$store.state.loader"></FullLoader>

    <MenuMovil
      v-if="this.existSlider && this.getVisualWidth < 1100 && this.showHeader"
      :appdata="this.$data"
      @updateMenuMobilStatus="updateMenuMobilStatus"
      @changePage="changePage"
    ></MenuMovil>

    <MenuWeb
      v-if="this.existSlider && this.getVisualWidth >= 1100"
      @changePage="changePage"
      :appdata="this.$data"
      :account="getUser"
    ></MenuWeb>

    <FormLogin @login="login($event)" @changePage="changePage"></FormLogin>

    <!-- Pages -->
    <component
      v-if="this.existSlider"
      :is="getPage"
      :appdata="this.$data"
      @changePage="changePage"
      @barSolid="barSolid"
      @login="login($event)"
      :account="getUser"
      :class="{ 'movil-body': this.getIsMobilResolution }"
    ></component>

    <div
      class="text-right fijo"
      v-if="
        this.getIsMobilResolution &&
          (this.activePage == 'Preguntas' ||
            this.activePage == 'ComoApostar' ||
            this.activePage == 'Resultados' ||
            this.activePage == 'Terminos' ||
            this.activePage == 'Juegos' ||
            this.activePage == 'BonusTermsConditions' ||
            this.activePage == 'TiposApuestas' ||
            this.activePage == 'Cuenta')
      "
      v-on:click="top()"
      style="z-index: 100"
    >
      <div class="badge shade">
        <i class="fas fa-chevron-up fa-lg"></i>
      </div>
    </div>
    <div
      class="text-right fijo-web"
      v-if="!this.getIsMobilResolution && this.activePage == 'Slots'"
      v-on:click="top()"
      style="z-index: 100"
    >
      <div class="badge shade">
        <i class="fas fa-chevron-up fa-lg"></i>
      </div>
    </div>

    <div id="contenedor-contacto-flotante" style="position: fixed; bottom: 0px; right: 14px; z-index: 999">
      <table width="5%" style="float: right">
        <tbody>
          <tr>
            <td class="text-center">
              <a href="#" data-toggle="modal" data-target="#chatModal" @click="zompinClose()">
                <img
                  width="50px"
                  height="50px"
                  v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/icon-chats_naranja.png'"
                  class="img-responsive"
                  alt="img chat"
                  id="imgchat"
                  style="cursor: pointer"
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Slider Web -->
    <!-- <div
      class="carusel2"
      v-if="
        this.getPage == 'Home' &&
          this.getMiniBannersPromociones &&
          this.getMiniBannersPromociones.length > 0 &&
          !this.getIsMobilResolution
      "
    >
      <SliderBaner></SliderBaner>
    </div> -->

    <div
      :class="{
        'caja-promo mt-3': true,
        'bg-light': this.getThemelight
      }"
      v-if="
        this.getPage == 'Home' &&
          this.getMiniBannersPromociones &&
          this.getMiniBannersPromociones.length > 0 &&
          !this.getIsMobilResolution
      "
    >
      <div class="mb-3" v-for="p in getMiniBannersPromociones" :key="p.id">
        <a :href="p.enlace" target="_blank">
          <img :src="p.url" alt="" />
        </a>
      </div>
    </div>

    <div
      class="row justify-content-center mt-3 mb-3"
      v-if="
        this.getPage == 'Home' &&
          this.getMiniBannersPromociones &&
          this.getMiniBannersPromociones.length > 0 &&
          this.getIsMobilResolution
      "
    >
      <div class="mt-2" v-for="p in getMiniBannersPromociones" :key="p.id">
        <a :href="p.enlace" target="_blank">
          <img :src="p.url" alt="" />
        </a>
      </div>
    </div>

    <footer
      v-if="
        this.existSlider &&
          this.activePage != 'Hipismo' &&
          this.activePage != 'CasinoLive' &&
          this.activePage != 'JuegosVirtuales' &&
          this.activePage != 'SorteosTradicionales' &&
          this.activePage != 'Spin500' &&
          this.activePage != 'PropsBetting' &&
          this.activePage != 'LiveBetting' &&
          this.activePage != 'Poker'
      "
      style="display: block; background-color: #fff"
      :class="{
        footer: true,
        'footer-width-table':
          this.activePage == 'Cuenta' || this.activePage == 'Recargas' || this.activePage == 'Retiros',
        desplazo16: this.getPage == 'Home'
      }"
    >
      <div class="container-fluid pt-4" v-if="this.getVisualWidth > 1048">
        <div class="row justify-content-center pb-4">
          <div class="col-md-3">
            <div class="row">
              <div class="col text-right">
                <img
                  style="height: 39px; width: 93px"
                  v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/gaminglab_certified.jpg'"
                  border="0"
                />
              </div>
              <div class="col text-center">
                <img
                  style="height: 50px; width: 50px"
                  v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/iTech-Labs-logo_new.png'"
                  border="0"
                />
              </div>
              <div class="col text-left">
                <img
                  style="height: 38px; width: 38px"
                  v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/18_mas.png'"
                  border="0"
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <ul class="nav" id="redes-footer">
              <li class="ms-3">
                <a class="text-muted" target="_blank" href="https://www.instagram.com/sellatuparleyoficial/"
                  ><i class="fab fa-instagram color-redes"></i
                ></a>
              </li>
              <li class="ms-3">
                <a class="text-muted" target="_blank" href="https://www.facebook.com/sellatuparleydeportes"
                  ><i class="fab fa-facebook-f color-redes"></i
                ></a>
              </li>
              <li class="ms-3">
                <a class="text-muted" target="_blank" href="https://www.tiktok.com/@sellatuparley.com"
                  ><i class="fab fa-tiktok color-redes"></i
                ></a>
              </li>
              <li class="ms-3">
                <a class="text-muted" target="_blank" href="https://www.youtube.com/user/sellatuparley"
                  ><i class="fab fa-youtube color-redes"></i
                ></a>
              </li>
              <li class="ms-3">
                <a class="text-muted" target="_blank" href="https://twitter.com/sellatuparley"
                  ><i class="fab fa-twitter color-redes"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>

        <p class="text-center txt-blanco">
          Sellatuparley.com es Regulado para la Republica Bolivariana de Venezuela por SUNAHIP y CONALOT Nro. de
          licencia : 03-LC-004-PW-2019
        </p>
        <p class="text-center txt-blanco">
          Sellatuparley.com opera bajo Games & More B.V Company No.149948, Fransche Bloemweg 4, Curacao, Licence Nr
          1668/JAZ and Power Gaming Ltd (Abacus Business Centre, Level 1Dun Karm Street,
        </p>

        <p class="text-center txt-blanco">B'Kara Bypass Birkirkara - Company nr C77981) como Procesador de pagos</p>

        <p class="text-center txt-blanco" style="padding-bottom: 10px">
          Copyright © 2021 Sellatuparley.com Todos los derechos reservados.
        </p>
      </div>
    </footer>

    <footer class="footer-movil" v-if="this.existSlider && !this.$store.state.loader">
      <div
        v-if="
          this.getVisualWidth < 1048 &&
            this.getPage != 'Poker' &&
            this.getPage != 'JuegosVirtuales' &&
            this.getPage != 'PropsBetting' &&
            this.getPage != 'SlotsMovil' &&
            this.getPage != 'DeportesMovil' &&
            this.getPage != 'TaquillaMovil' &&
            this.getPage != 'Hipismo'
        "
      >
        <div class="row justify-content-center">
          <div class="col-sm text-center">
            <li
              class="li-margen"
              :class="{
                'nav-item': true,
                pullright: true
              }"
            >
              <a href="https://www.instagram.com/sellatuparleyoficial/" target="_blank" class="nav-link"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>

            <li
              class="li-margen"
              :class="{
                'nav-item': true,
                pullright: true
              }"
            >
              <a href="https://www.facebook.com/sellatuparleydeportes" target="_blank" class="nav-link"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>

            <li
              class="li-margen"
              :class="{
                'nav-item': true,
                pullright: true
              }"
            >
              <a href="https://www.tiktok.com/@sellatuparley.com" target="_blank" class="nav-link"
                ><i class="fab fa-tiktok"></i
              ></a>
            </li>

            <li
              class="li-margen"
              :class="{
                'nav-item': true,
                pullright: true
              }"
            >
              <a href="https://twitter.com/sellatuparley" class="nav-link" target="_blank"
                ><i class="fab fa-twitter"></i
              ></a>
            </li>

            <li
              class="li-margen"
              :class="{
                'nav-item': true,
                pullright: true
              }"
            >
              <a href="https://www.youtube.com/user/sellatuparley" target="_blank" class="nav-link"
                ><i class="fab fa-youtube"></i
              ></a>
            </li>
            <hr class="w-75 text-center" style="height: 1px; color: blue; background-color: blue" />
          </div>

          <div class="w-100">
            <table width="100%" height="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr width="100%">
                  <td align="right" valign="middle" width="40%">
                    <img
                      style="height: 39px; width: 93px"
                      v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/gaminglab_certified.jpg'"
                      border="0"
                    />
                  </td>
                  <td align="center" valign="middle" width="30%">
                    <img
                      style="height: 50px; width: 50px"
                      v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/iTech-Labs-logo_new.png'"
                      border="0"
                    />
                  </td>
                  <td align="left" valign="middle" width="30%">
                    <img
                      style="height: 38px; width: 38px"
                      v-lazy="'https://images.weserv.nl/?url=https://www.sellatuparley.com/img/18_mas.png'"
                      border="0"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row">
            <div class="col-sm">
              <p class="text-center txt-blanco txt-movil pb-2">Sellatuparley.com</p>
              <p class="text-center txt-blanco txt-movil w-75">
                es Regulado para la Republica Bolivariana de Venezuela por SUNAHIP y CONALOT Nro. de licencia :
                03-LC-004-PW-2019
              </p>
              <p class="text-center txt-blanco txt-movil w-75">
                Sellatuparley.com opera bajo Games & More B.V Company No.149948, Fransche Bloemweg 4, Curacao, Licence
                Nr 1668/JAZ and Power Gaming Ltd (Abacus Business Centre, Level 1Dun Karm Street,
              </p>
              <p class="text-center txt-blanco txt-movil w-75">
                B'Kara Bypass Birkirkara - Company nr C77981) como Procesador de pagos
              </p>
              <p class="text-center txt-blanco txt-movil w-75 py-4">
                Copyright © 2021 Sellatuparley.com Todos los derechos reservados.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <FormRegister @changePage="changePage"></FormRegister>

    <ModalContacto></ModalContacto>
    <ContactosMovil></ContactosMovil>
    <ActivadorDetallesFlotante
      v-if="this.activePage && this.activePage == 'Juegos' && this.getPage == 'Juegos' && this.getTaquillaFlotante"
    ></ActivadorDetallesFlotante>

    <!-- Modales -->
    <!-- <ModalCalculadoraWeb></ModalCalculadoraWeb> -->
    <ModalCalculadora></ModalCalculadora>
    <ModalRegistroMobilDetails></ModalRegistroMobilDetails>
    <ModalMiCuenta></ModalMiCuenta>
    <CuentaMovil @changePage="changePage"></CuentaMovil>
    <ModalTerminosCondiciones />
    <ModalBonosTerminosCondiciones />
    <ModalExtenderSession></ModalExtenderSession>
    <ModalCriptoWallet></ModalCriptoWallet>

    <ModalChat />
  </div>
</template>

<script>
import { essentialsData } from "./mixins/essentialsDataMixin.js";
import { chatMixin } from "./mixins/chatMixin.js";
import Loader from "./components/Loader.vue";
import BonusTable from "./components/tables/BonusTable.vue";
import FullLoader from "./components/FullLoader.vue";
import MenuMovil from "./components/MenuMovil.vue";
import MenuWeb from "./components/MenuWeb.vue";
import FormRegister from "./components/FormRegister.vue";
import FormLogin from "./components/FormLogin.vue";

import Home from "./pages/Home.vue";
import Preguntas from "./pages/Preguntas.vue";
import Terminos from "./pages/Terminos.vue";
import ContactosMovil from "./components/movil/ContactosMovil.vue";
import Cuenta from "./pages/Cuenta.vue";
import CuentaBancaria from "./pages/CuentaBancaria.vue";
import DeportesMovil from "./components/movil/juegos/DeportesMovil.vue";

//Taquilla deportiva movil
import TaquillaMovil from "./components/movil/juegos/TaquillaDeportiva.vue";

//Taquilla deportiva
import TaquillaLayout from "./modules/taquilla/layouts/TaquillaLayout.vue";

import Mensaje from "./pages/Mensaje.vue";
import Recargas from "./pages/Recargas.vue";

import RecargasTableMovil from "./components/movil/RecargasTableMovil.vue";
import Retiros from "./pages/Retiros.vue";
import PaymentsTable from "./components/movil/PagosTableMovil.vue";
import Resultados from "./pages/Resultados.vue";
import TicketsMovil from "./components/movil/juegos/TicketsMovil.vue";
import ActionSheet from "./components/movil/juegos/ActionSheet.vue";
import ActionSheetTest from "./components/movil/juegos/ActionSheetTest.vue";
import TicketDetailMovil from "./components/movil/juegos/TicketDetailMovil.vue";
import TiposApuestas from "./pages/TiposApuestas.vue";
import BonusTermsConditions from "./pages/BonusTermsConditions.vue";
import ReporteSemanalTable from "./components/tables/ReporteSemanalTable.vue";
import ReporteSemanalMovil from "./components/movil/ReporteSemanalMovil.vue";
import Juegos from "./pages/Juegos.vue";
import ComoApostar from "./pages/ComoApostar.vue";
import NotFound from "./pages/NotFound.vue";
import Mensajeria from "./pages/Mensajeria.vue";
import LiveBetting from "./pages/LiveBetting.vue";
import Hipismo from "./pages/Hipismo.vue";
import CasinoLive from "./pages/CasinoLive.vue";
import Poker from "./pages/Poker.vue";
import JuegosVirtuales from "./pages/JuegosVirtuales.vue";
import PropsBetting from "./pages/PropsBetting.vue";
import Spin500 from "./pages/Spin500.vue";
import SorteosTradicionales from "./pages/SorteosTradicionales.vue";
import ModalContacto from "./components/ModalContacto.vue";
//import ModalCalculadoraWeb from "./components/CalculadoraWeb.vue";
import ModalCalculadora from "./components/ModalCalculadora.vue";
import CuentaMovil from "./components/movil/CuentaMovil.vue";
import SliderBaner from "./components/SliderBaner.vue";
import SlotsMovil from "./components/movil/SlotsMovil.vue";
import Slots from "./pages/Slots.vue";
import ModalChat from "./components/ModalChat.vue";
import ModalRegistroMobilDetails from "./pages/modals/ModalRegistroMobilDetails.vue";
import ModalMiCuenta from "./pages/modals/ModalMiCuenta.vue";
import ModalTerminosCondiciones from "./pages/modals/ModalTerminosCondiciones.vue";
import ModalBonosTerminosCondiciones from "./pages/modals/ModalBonosTerminosCondiciones.vue";
import ModalExtenderSession from "./pages/modals/ModalExtenderSession.vue";
import ModalCriptoWallet from "./pages/modals/ModalCriptoWallet.vue";
/* Tables */
import BalancesTable from "./components/tables/BalancesTable.vue";
import BalancesTableMovil from "./components/movil/BalancesTableMovil.vue";
import BonusTableMovil from "./components/movil/BonusTableMovil.vue";
import ActivadorDetallesFlotante from "./components/ActivadorDetallesFlotante.vue";
import CuentasBancariasMovil from "./components/movil/CuentasBancariasMovil.vue";
import ResultadosMovil from "./components/movil/ResultadosMovil.vue";
import EditPerfilMovil from "./components/movil/EditPerfilMovil.vue";
import ChangePasswordMovil from "./components/movil/ChangePasswordMovil.vue";

/* eslint-disable */
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import carousel from "vue-owl-carousel";
import JQ from "jquery";

import { mapGetters, mapActions } from "vuex";
import { Base64 } from "js-base64";
import Push from "push.js";

export default {
  mixins: [essentialsData, chatMixin],
  components: {
    ActionSheet,
    ActionSheetTest,
    BonusTable,
    carousel,
    Loader,
    MenuMovil,
    MenuWeb,
    CuentaMovil,
    FormLogin,
    Home,
    Preguntas,
    Terminos,
    ContactosMovil,
    Cuenta,
    CuentaBancaria,
    DeportesMovil,
    Mensaje,
    Recargas,    
    RecargasTableMovil,
    Retiros,
    PaymentsTable,
    Resultados,
    SlotsMovil,
    NotFound,
    FullLoader,
    TiposApuestas,
    BonusTermsConditions,
    ReporteSemanalMovil,
    ReporteSemanalTable,
    TicketsMovil,
    TicketDetailMovil,
    ComoApostar,
    FormRegister,
    Mensajeria,
    BalancesTable,
    BalancesTableMovil,
    BonusTableMovil,
    Juegos,
    PropsBetting,
    LiveBetting,
    Hipismo,
    CasinoLive,
    Poker,
    JuegosVirtuales,
    Spin500,
    SorteosTradicionales,
    ActivadorDetallesFlotante,

    ModalContacto,
    //ModalCalculadoraWeb,
    ModalCalculadora,
    SliderBaner,
    Slots,
    ModalChat,
    ModalRegistroMobilDetails,
    ModalMiCuenta,
    CuentasBancariasMovil,
    ResultadosMovil,
    EditPerfilMovil,
    ModalTerminosCondiciones,
    ModalBonosTerminosCondiciones,
    ModalExtenderSession,
    ModalCriptoWallet,
    ChangePasswordMovil,

    //Taquilla deportiva
    TaquillaLayout,
    TaquillaMovil
  },
  name: "App",
  computed: {
    ...mapGetters([
      "getBetInit",
      "getCountries",
      "getBonusTermsConditions",
      "getUser",
      "getPage",
      "getSliders",
      "getExistMinSlidersInCache",
      "getIsMobilResolution",
      "getVisualWidth",
      "getMiniBannersPromociones",
      "getAccounts",
      "getIsIOS",
      "getExpire",
      "getTaquillaFlotante",
      "getControlTime",
      "getVisualHeight",
      "getIframeActive",
      "getCenterBanks",
      "getCenterAccounts",
      "getCountry",
      "getThemelight",
    ]),
    isLoged() {
      return this.getUser && this.getUser != null && this.getUser != undefined ? true : false;
    },
    existSlider() {
      /*const cargados = this.getSliders.filter((l) => {       
        return l.load == true ? true : null;
      }); */

      // cuando ha carhado almenos 5 imagenes
      // ó en casos que sean menos imagenes que las haya cargado todas
      //return cargados.length > 4 || (cargados.length && this.getSliders.length) ? true : false;

      return true;
    },
  },
  watch: {
    getVisualWidth(v) {
      if (this.getPage == "SlotsMovil" && v > this.getVisualHeight && this.getIframeActive) {
        this.showHeader = false;
      } else this.showHeader = true;
      if (v <= 1100) {
        this.menumobil = true;
      }
    },
    getUser(v) {
      this.account = JSON.parse(JSON.stringify(v));
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      cargando: true,
      activePage: "Home",
      navbarSolid: false,
      menumobil: false,
      scroll: 0,
      account: this.getUser,
      showHeader: true,
    };
  },
  async created() {
    // si no se tiene el permiso para lanzar  notificaciones se solicita
    if (!Push.Permission.has()) {
      Push.Permission.get();

      Push.create("Sellatuparley.com", {
        body: "Bienvenido ya posees Notificaciones Activas",
        icon: "/img/logo/img2x.png",
        timeout: 4000,
        onClick: function () {
          window.focus();
          this.close();
        },
      });
    }

    if (!this.getBetInit) {
      await this.$store.dispatch("getBetInitAsync");
    }

    if (!this.getCountries) {      
      await this.$store.dispatch("searchCountriesAsync");
    }

    if (this.getUser && !this.getAccounts) {
      await this.$store.dispatch("getAccountsClientAsync");
    }

    await this.$store.dispatch("searchMiniBannersPromociones");
    // this.makeImportChat();
    if (this.getPage == null) {
      this.$store.commit("setPage", "Home");
    }

    const targetPage =
      localStorage.getItem("page") == localStorage.getItem("lastPage")
        ? localStorage.getItem("page")
        : localStorage.getItem("lastPage");

    /*
     * Importante verificacion de la ultima pagina en caso de refresh
     */
    localStorage.setItem("page", targetPage);
    this.$store.commit("setPage", targetPage);
    this.changePage(targetPage);

    if (this.isLoged) {
      setInterval(async () => {
        await this.$store.dispatch("getMessengerUserAsync");
      }, 180000);
    }
  },
  async mounted() {
    let self_ = this;
    console.log("Sliders: ", this.getSliders)
    /* Validacion y limpiado en el storage */
    if ((localStorage && localStorage.getItem("page") == null) || localStorage.getItem("page") == "null") {
      localStorage.setItem("page", "Home");
      this.activePage = "Home";
    }

    if ((localStorage && localStorage.getItem("lastPage") == null) || localStorage.getItem("lastPage") == "null") {
      //localStorage.removeItem("lastPage");
      localStorage.setItem("lastPage", "Home");
    }

    this.$store.dispatch("getCurrentCountry");
    
    // Logica para cuando Cierre en la [X]
    if (localStorage && localStorage.getItem("hasClose")) {
      let currentTime = new Date().getTime();
      let previusTime = localStorage.getItem("closeTime") ? parseInt(localStorage.getItem("closeTime")) : 0;
      let diferecia = currentTime - previusTime;
      let msTime = 5000;

      // si el tiempo es menor se limpia
      // o si es menor a 1 seg es que recargo y se omite
      if (previusTime == 0 || diferecia < msTime) {
        await localStorage.removeItem("hasClose");
        await localStorage.removeItem("closeTime");
      }

      if (localStorage.getItem("hasClose")) {
        await localStorage.removeItem("hasClose");
        await localStorage.removeItem("closeTime");
        await self_.$store.dispatch("logOut");
      }
    }

    // cerrar ventana window.close();

    // caso si cierra el browser en la [X] event ->  beforeunload   / no se activa cuando permanece ->unload
    // window.addEventListener('beforeunload', async (e)=> {
    //         console.log(e);
    //         e.preventDefault();
    //         e.returnValue = 'seguro de salir?';

    //         if(localStorage && e.target.onclose){
    //           localStorage.setItem("hasClose", "X");
    //         }
    //         //await self_.$store.dispatch("logOut");
    // });

    // window.beforeunload = closingCode;

    window.addEventListener("beforeunload", async () => {
      closingCode();
    });

    function closingCode() {
      if (localStorage) {
        localStorage.setItem("hasClose", "X");
        localStorage.setItem("closeTime", new Date().getTime());
      }
    }

    this.$store.dispatch("checkSession");
    const sliders = await this.searchSliders();
    this.$store.commit("setSliders", sliders);

    JQ(window).on("scroll", function () {
      amountscrolled(self_);
    });

    // this.importLibChat();

    //JQ(document.getElementById("scroll")).on("click", function () {

    //});

    // this.$store.dispatch("searchBetsTypes");

    // ##########################################################
    // Detectando cuando Wilmer cambia o juega con la resolucion
    // ##########################################################
    window.addEventListener("resize", (e) => {
      self_.$store.commit("setVisualWidth", e.target.visualViewport.width);
      self_.$store.commit("setVisualHeight", e.target.visualViewport.height);
    });
  },
  methods: {
    // makeImportChat(){
    //   this.importLibChat();
    // },
    openChat() {
      // this.showChat();
      // this.generateChat();
      // this.$bvModal.hide('bv-modal-example');
    },
    updateMenuMobilStatus: function (status) {
      this.menumobil = status;
    },
    barSolid: function (activacion) {
      this.navbarSolid = activacion;
    },
    changePage: async function (page) {
      // # cuando aya un cambio de pagina que se vacien las retrinciones
      // para un corecto recalculo y filtrado
      this.$store.commit("setRestriccionParley", []);
      this.$store.commit("setExcepcionParley", false);

      // await this.$store.dispatch("resetStatusApuestas");

      // --------------------------------------------------
      // Validacion necesaria para selectores de Recargas |
      // --------------------------------------------------

      if (page == "Recargas" && (!this.getCenterBanks || !this.getCenterAccounts)) {
        await this.$store.dispatch("getCenterAccountsBanksAsync");
        console.log("*** changePage", page);
      }

      this.activePage = page;
      this.$store.dispatch("changePage", page);
    },
    top() {
      $("html, #container-preguntas").animate({ scrollTop: 0 }, 600);
      return false;
    },
    /*
    getPage:function () {
      return this.activePage;
    },
    */
    async login(objLogin) {
      this.$store.commit("showLoader");
      this.$store.commit("setApuestas", []);
      this.$store.commit("setRestriccionParley", []);
      this.$store.commit("setExcepcionParley", false);
      this.$store.commit("setExpire", 0);

      let self_ = this;
      const objToken = await this.$userService.getToken(objLogin);

      console.log("Obj token: ", objToken)
      const momentum = localStorage && localStorage.getItem("momentum") ? localStorage.getItem("momentum") : null;
        
      if (!objToken) {
        this.$store.commit("setControlTime", null);
        //this.$store.commit("hideLoader");
        this.Swal.fire("Lo sentimos", "<span class='text-justify'>Combinación de usuario y contraseña incorrecta</span>", "error");
      } else if (!objToken.error) {
        this.$store.commit("setControlTime", momentum);
        this.$store.commit("setExpire", parseInt(objToken.expires_in));
        this.$store.commit("setToken", objToken);
        await this.$store.dispatch("targetUserAsync", objToken);
        await this.refreshData();
        await this.$store.dispatch("getMessengerUserAsync");

        if (objLogin.chkPass) {
          const psd = Base64.encode(objLogin.pass);
          // this.setCookie("psd", psd, 30);
          // this.setCookie("username", objLogin.user, 30);

          this.$store.commit("setUserRec", objLogin.user);
          this.$store.commit("setPassRec", psd);
        } else {
          document.cookie = "username" + "=;expires=" + new Date();
          document.cookie = "psd" + "=;expires=" + new Date();
        }

        const Toast = this.Swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 1500,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", this.Swal.stopTimer);
            toast.addEventListener("mouseleave", this.Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Autenticado Satisfactoriamente",
        });
      } else if (objToken.error && objToken.error == "invalid_grant") {
        this.Swal.fire("Lo sentimos", "Porfavor verifique, ha ingresado datos inválidos", "error");
      }
      this.$store.commit("hideLoader");
    },
    updateScroll: function (porcentaje) {
      this.scroll = parseInt(porcentaje);
      this.$store.commit("setScroll", this.scroll);
    },
    zompinClose() {
      if (window.$zopim && window.$zopim.livechat) {
        window.$zopim.livechat.hideAll();
      }
    },
    // establecer cookie
    setCookie(cName, value, expireDays) {
      let exDate = new Date();
      exDate = exDate.setDate(exDate.getDate() + expireDays);
      document.cookie =
        cName + "=" + value + (expireDays === undefined ? "" : ";expires=" + new Date(exDate).toUTCString());
    },
  },
};

function amountscrolled(instancia) {
  var winheight = JQ(window).height();
  var docheight = JQ(document).height();
  var scrollTop = JQ(window).scrollTop();
  var trackLength = docheight - winheight;
  var pctScrolled = Math.floor((scrollTop / trackLength) * 100);

  if (
    (instancia.activePage == "Resultados" ||
      instancia.activePage == "Terminos" ||
      instancia.activePage == "Contacto" ||
      instancia.activePage == "Home") &&
    pctScrolled >= 1
  ) {
    instancia.navbarSolid = true;
  } else if (
    (instancia.activePage == "Resultados" ||
      instancia.activePage == "Terminos" ||
      instancia.activePage == "Contacto" ||
      instancia.activePage == "Home") &&
    pctScrolled < 1
  ) {
    instancia.navbarSolid = false;
  }

  instancia.updateScroll(pctScrolled);
}
</script>

<style scoped>
#app,
#app > div.caja-promo.mt-3,
body {
  background-color: #2c498b;
}
</style>


<style>
#redes-footer {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
}

#redes-footer li a,
#redes-footer li a > i {
  color: #fff !important;
  transition-duration: 0.25s;
}

#redes-footer li a:hover,
#redes-footer li a > i:hover {
  color: #00bcd4 !important;
}
.zopim {
  left: 76%;
}

@media (max-width: 1545px) {
  .zopim {
    left: 75% !important;
  }
}
@media (max-width: 1480px) {
  .zopim {
    left: 74% !important;
  }
}
@media (max-width: 1434px) {
  .zopim {
    left: 73% !important;
  }
}
@media (max-width: 1384px) {
  .zopim {
    left: 72% !important;
  }
}
@media (max-width: 1366px) and (orientation: landscape) {
  .zopim {
    left: 65% !important; /*antes estaba en 85 y se metia demaciado y chacaba con el wasap*/
  }
}
@media (max-width: 1325px) {
  .zopim {
    left: 71% !important;
  }
}
@media (max-width: 1280px) {
  .zopim {
    left: 70% !important;
  }
}
@media (max-width: 1240px) {
  .zopim {
    left: 69% !important;
  }
}
@media (max-width: 1200px) {
  .zopim {
    left: 68% !important;
  }
}
@media (max-width: 1168px) {
  .zopim {
    left: 67% !important;
  }
}
@media (max-width: 1123px) {
  .zopim {
    left: 66% !important;
  }
}
@media (max-width: 1080px) {
  .zopim {
    left: 65% !important;
  }
}
@media (max-width: 1058px) {
  .zopim {
    left: 63% !important;
  }
}
@media (max-width: 1024px) {
  .zopim {
    left: 80% !important;
  }
}
@media (max-width: 823px) and (orientation: landscape) {
  .zopim {
    left: 77% !important;
  }
}
@media (max-width: 768px) {
  .zopim {
    left: 75% !important;
  }
}
@media (max-width: 540px) {
  .zopim {
    left: 65% !important;
  }
}
@media (max-width: 414px) {
  .zopim {
    left: 55% !important;
  }
}
@media (max-width: 411px) {
  .zopim {
    left: 55% !important;
  }
}
@media (max-width: 375px) {
  .zopim {
    left: 50% !important;
  }
}
@media (max-width: 360px) {
  .zopim {
    left: 45% !important;
  }
}
@media (max-width: 320px) {
  .zopim {
    left: 40% !important;
  }
}
@media (max-width: 280px) {
  .zopim {
    left: 35% !important;
  }
}
@media (min-width: 481px) and (max-width: 800px) and (orientation: landscape) {
  .zopim {
    left: 70% !important;
  }
}

.plus-18 {
  width: 1.5em;
  height: auto;
}
div.mini-carousel {
  display: inline-grid;
  max-width: 259px;
  margin: auto;
}

div.redes > div {
  max-width: 33.33%;
}

.mini-carousel > div:not(:first-child) {
  display: none;
}

.fullSize {
  position: fixed;
}

.owl-nav {
  left: 15% !important;
}

.show {
  display: block;
}

.xclosed {
  right: 2%;
  font-size: 5em;
  top: 0%;
  padding: 0px;
  margin: 0px;
  border: 0px;
  position: fixed;
  z-index: 10000;
  line-height: 1.5;
  color: rgb(168, 170, 173);
  cursor: pointer;
}

.xclosed:hover {
  color: rgb(0, 0, 0);
}

form.UserLoginForm > div.row {
  margin-right: 0px;
  margin-left: 0px;
}

.logo-container {
  text-align: center;
  max-width: 25%;
  position: absolute;
  top: 0;
  /*z-index: 2500;*/
  left: 10.5%;
  margin-top: 10%;
}
.mobil-50 {
  width: 50%;
}
@media only screen and (max-width: 600px) {
  .xclosed {
    right: 4%;
    font-size: 2.5em;
    top: 0%;
  }
}

/* para resoluciones grandes */
@media only screen and (min-width: 1100px) {
  .logo-container {
    width: 300px;
    max-width: 300px;
    margin: 0 auto;
  }

  div.owl-slide-text > div.row:first-child > a {
    padding: 0 4em;
  }

  div.owl-slide-text > div.row:first-child > a > img {
    padding-top: 0em !important;
    margin: 0px;
  }
  .navbar {
    z-index: 10;
  }
  .only-mobil {
    display: none !important;
  }
}

/* para resoluciones menores a 1100px */
@media only screen and (max-width: 1100px) {
  div.owl-slide-text > div.row:first-child > a > img {
    padding: 0px !important;
    /*margin-top: 24%;*/
  }
  /* div.carousel-main > div.owl-stage-outer > div.owl-stage > div.owl-item > div.owl-slide {
    background-image: url(/img/slider/fondo-mobil.jpg)!important;
  } */

  section.only-mobil > div.owl-stage-outer > div.owl-stage > div.owl-item > div.owl-slide {
    background-size: 180% 100%;
    background-position: 80%;
    background-repeat: no-repeat;
  }
  .owl-dots {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .usuario-nologeado {
    width: 100%;
  }

  .logo-container {
    max-width: 100%;
    width: 100%;
    left: 0%;
    margin: 0 auto !important;
    padding-top: 0%;
  }

  .owl-dots {
    top: 90% !important;
  }

  div.accordion {
    width: 100% !important;
  }

  div.owl-slide-text > div.row:first-child {
    width: 100% !important;
    padding-top: 0em !important;
    position: static;
    display: flex;
    margin: 0;
  }

  div.owl-slide-text > div.row:first-child > a {
    padding-top: 0px !important;
  }

  div.owl-slide-text > div.row:first-child > a > img {
    padding: 5%;
  }
}

@media only screen and (min-width: 1100px) {
  .owl-dots {
    top: 95% !important;
  }

  .owl-nav {
    top: 5% !important;
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-156%) !important;
  }

  div.accordion {
    width: 65% !important;
  }
}

div.row.justify-content-center.justify-content-md-start,
.row .center-block .text-center {
  margin-right: 0px;
  margin-left: 0px;
}

div.owl-slide-text > div.row {
  padding: 0 15px;
}

div.accordion.space-logo {
  align-content: center;
  align-items: center;
  border: 0;
  margin: 0px;
  padding: 0px;
  /* padding-top: 200px; */
}

div.flotante {
  background-color: transparent;
  position: fixed;
  width: 30vh;
  height: 31px;
  /*z-index: 2500;*/
  right: 20%;
}

div.flotante > ul {
  position: fixed;
}

div.flotante > ul > li {
  list-style: none;
}

div.flotante > ul > li:hover {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10%;
}

div.flotante > ul > li > a {
  padding: 0 10px;
  color: #fff;
  font-size: 2.5em;
}

div.flotante > ul > li > a:hover {
  text-decoration: none;
  color: #ff5300;
  text-shadow: #fff 1px 2px 1px;
}

.owl-nav {
  display: none;
}

/*para mantener posicion para otros slider*/
.owl-slide-text {
  display: -webkit-box;
}
div.row.center-block.text-center > a {
  width: 100%;
}
.txt-gray {
  color: #253137;
}
.txt-blanco {
  color: #ffffff;
  font-size: 12px;
}
footer.footer-width-table {
  padding: 1em 0;
}
footer > div.container {
  margin: 0em;
  width: 100%;
  display: contents;
}

footer > div.container > div {
  padding: 2em 0;
}
footer {
  background: #001f62 !important;
  /*margin-top: 10em !important;*/
}
.movil-body {
  margin-top: 6em;
}
.footer-movil {
  bottom: 0px;
  overflow: hidden;
  z-index: 1000;
}
.shade {
  font-size: 20px;
  background-color: rgba(217, 243, 252, 0.7);
}
.fijo {
  position: fixed;
  top: 80%;
  left: 85%;
}
.fijo-web {
  position: fixed;
  top: 95%;
  left: 97%;
  cursor: pointer;
}

.titulo {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  margin: 0px;
}
.letra {
  font-family: "Poppins", sans-serif !important;
}

.l1 {
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
  line-height: 24px;
  text-transform: none;
}

.boton2 {
  width: 100%;
  background: #2f2f2f;
  border: 0px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  text-transform: none;
}
.modal-content2 {
  background-color: #2d2d2d !important;
}
.boton:hover {
  background: #4e4e4e;
  cursor: pointer;
}
.boton2:hover {
  background: #4e4e4e;
  cursor: pointer;
}

.btn-regresar {
  margin: auto !important;
  width: 125px !important;
}
.btn-regresar > span {
  font-size: 0.9rem !important;
}

.textochat {
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
  line-height: 24px;
  text-transform: none;
  background: #00caf1;
  border-radius: 5px;
  cursor: pointer;
}

.txt-movil {
  font-size: 9px;
}

.li-margen {
  display: inline-block;
}
.li-margen i {
  color: #03fff6 !important;
}
hr .linea {
  color: #fff !important;
  background: #ffffff !important;
}

.box-chat {
  background: #001f62 !important;
}

.label-formulario {
  color: #052460 !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}
.span-formulario {
  color: #052460 !important;
  font-family: "Poppins", sans-serif !important;
}

.boton-color-fondo {
  background: #001f62 !important;
  width: 80% !important;
  border-radius: 10px;
  font-family: "Poppins", sans-serif !important;
}
.btn-cerrar {
  width: 80% !important;
  border-radius: 10px;
  font-family: "Poppins", sans-serif !important;
  background: #ebebeb !important;
  color: black !important;
  border-color: #c5c5c5 !important;
}
.btn-cerrar:hover {
  background: #d9d9d9 !important;
}
.btn-cerrar-modal {
  border-radius: 10px;
  font-family: "Poppins", sans-serif !important;
  background: #ebebeb !important;
  color: black !important;
  border-color: #c5c5c5 !important;
}
.btn-cerrar-modal:hover {
  background: #d9d9d9 !important;
}

.boton-color-modal {
  background: #001f62 !important;
  width: 40% !important;
  border-radius: 10px;
  padding: 5px;
}

.titulo1 {
  color: #052460;
  font-family: "Poppins", sans-serif !important;
  font-size: 20px;
  margin: 0px;
  font-weight: 700;
}
.txt-red {
  color: #ec0c0c !important;
  font-family: "Poppins", sans-serif !important;
}
.error-input {
  border-color: #ec0c0c;
  font-family: "Poppins", sans-serif !important;
}
.color-text {
  color: #052460;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
}
a {
  font-family: "Poppins", sans-serif !important;
}

/*
    * Jesus no quites el centrado y alineado flexible
    ya que habia quedado chucuto y con las flechas desalineadas

*/
.carusel2 {
  display: flex;
  margin: 0px;
  position: absolute;
  padding: 20px 0px;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  justify-content: space-around;
  /* min-height: 130px; */
  align-items: center;
  align-content: center;
  margin-top: -9.8em;
}

.carusel2 > div {
  width: 100%;
}
/* necesario ya que cuando cambiaban el carousel peque#o cambiaba el tama#o de las imagenes */
.carusel2 img {
  /* min-height: 100px; 
  min-width: 190px; */

  /*la imagen de esos mini baners es de 114px de alto*/
  /*la imagen de esos mini baners es de 286px de ancho*/
  transition: 200ms linear;
}

@media (max-width: 1200px) {
  .carusel2 {
    margin-top: -7.1em;
    height: 6.6em;
  }
}
._h1 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
}

.pulsar-btn {
  transition: all ease 100ms;
}
.pulsar-btn:active {
  background: #052460;
}
.btn-iframe {
  top: 11% !important;
  margin-left: 97% !important;
  padding: 0px !important;
  position: fixed !important;
  z-index: 10 !important;
  background: #6bff00 !important;
  color: #08680c !important;
  border-radius: 2px !important;
  width: 25px !important;
  height: 25px !important;

  /* background:  transparent !important; */
}
/* .btn-iframe:hover{
  background: linear-gradient(-90deg, #6bff00,#0d6403) !important;
} */

@media (max-width: 1100px) {
  .btn-iframe {
    /* top: 15% !important; */
    margin-left: 96% !important;
    /* width: 3% !important; */
  }

  .label-btn-iframe {
    margin-left: 84% !important;
  }
}
@media (max-width: 900px) {
  .btn-iframe {
    margin-left: 95% !important;
    /* width: 4% !important; */
  }
  .label-btn-iframe {
    margin-left: 83% !important;
  }
}
@media (max-width: 500px) {
  .btn-iframe {
    margin-left: 90% !important;
    /* width: 6% !important; */
  }
  .label-btn-iframe {
    margin-left: 60% !important;
  }
}

@media (max-height: 650px) {
  .btn-iframe {
    top: 13% !important;
  }
}

.caja-promo {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border-top: 2px solid #f1911b !important;
  padding-left: 15%;
  padding-right: 15%;
  margin: 0px;
  padding-top: 40px;
  transform: translateY(-15px);
}
footer.footer.desplazo16 {
  margin-top: -15px;
}
.caja-promo > div {
  width: 300px;
}

.boder-img {
  border-radius: 10px;
  transition: 1s;
  opacity: none !important;
  z-index: 1000;
  filter: brightness(1.5);
  border: 3px solid #fe542e;
}

.boder-img:hover {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  box-shadow: 0px 0px 15px 15px #fe542e;
  -webkit-box-shadow: 0px 0px 15px 15px #fe542e;
  /* width: 300px; */
}
</style>
