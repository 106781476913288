import baseApi from "../../api/baseApi";
import moment from "moment";

export default {
  state: {
    notificaciones: [],
    campana: false
  },
  mutations: {
    setNotificaciones(state, payload) {
      state.notificaciones = payload;
    },
    setCampana(state, payload) {
      state.campana = payload;
    },
    setLeido(state, payload) {
      let arrNoti = [];
      state.notificaciones.forEach(element => {
        let obj = "";
        if (element.id == payload) {
          obj = {
            id: element.id,
            titulo: element.titulo,
            contenido: element.contenido,
            leido: true
          };
        } else {
          obj = {
            id: element.id,
            titulo: element.titulo,
            contenido: element.contenido,
            leido: element.leido
          };
        }
        arrNoti.push(obj);
      });
      state.notificaciones = arrNoti;
    }
  },
  getters: {
    getNotificaciones(state) {
      return state.notificaciones;
    },
    getCampana(state) {
      return state.campana;
    }
  },
  actions: {
    async getMensajeCRON({ commit, state }) {
      const fecha_ini = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const fecha_fin = moment()
        .endOf("month")
        .format("YYYY-MM-DD");

      const { data } = await baseApi.get("/api/v1/getMensajeCRON", {
        params: {
          fecha_ini,
          fecha_fin
        }
      });

      let arrNoti = [];

      data.data.forEach(element => {
        let obj = "";
        if (state.notificaciones.length == 0) {
          obj = {
            id: element.id,
            titulo: element.titulo,
            contenido: element.contenido,
            leido: false
          };
        } else {
          obj = {
            id: element.id,
            titulo: element.titulo,
            contenido: element.contenido,
            leido: state.notificaciones ? state.notificaciones.find(x => x.id == element.id).leido : false
          };
        }
        arrNoti.push(obj);
      });

      commit("setNotificaciones", arrNoti);
    }
  }
};
