var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vuescroll',{staticClass:"customBar",attrs:{"ops":_vm.ops}},[_c('nav',[_c('ul',{staticClass:"nav nav-pills nav-sidebar flex-column",attrs:{"data-widget":"treeview","role":"menu","data-accordion":"false"}},_vm._l((this.getMenuOptionsLeagues),function(menu,indexM){return _c('li',{key:indexM,staticClass:"nav-item contenedor-menu"},[(menu.data.length == 1)?[_c('a',{class:{
              'nav-link': true,
              'color-dark': !menu.open,
              'color-orange': menu.open
            },attrs:{"data-toggle":("collapse-" + indexM)},on:{"click":function($event){return _vm.openMenuOption(indexM, menu)}}},[_c('p',[_c('img',{attrs:{"src":_vm.renderIcono(menu.name),"width":"20"}}),_vm._v(" "+_vm._s(menu.name)+"\n              "),_c('i',{class:{ 'right fas fa-angle-left': true, open: menu.open }})])]),_c('ul',{class:{
              nav: true,
              'nav-open animate__animated animate__fadeIn dsp': menu.open,
              'no-dsp animate__animated animate__fadeOut': menu.open == false
            },attrs:{"data-toggle":("collapse-" + indexM)}},[_c('li',{staticClass:"nav-item"},_vm._l((menu.data),function(submenu,indexS){return _c('a',{key:indexS,staticClass:"nav-link",attrs:{"href":("#" + (submenu.name))},on:{"click":function($event){return _vm.changeGame(submenu.sport_id, submenu.id)}}},[_c('p',{staticClass:"txt-submenu"},[_vm._v(_vm._s(submenu.name))])])}),0)])]:[_c('a',{class:{ 'nav-link': true, 'color-dark': !menu.open, 'color-orange': menu.open },attrs:{"data-toggle":("collapse-" + indexM)},on:{"click":function($event){return _vm.openMenuOption(indexM, menu)}}},[_c('p',[_c('img',{attrs:{"src":_vm.renderIcono(menu.name),"width":"20"}}),_vm._v(" "+_vm._s(menu.name)+"\n              "),_c('i',{class:{ 'right fas fa-angle-left': true, open: menu.open }})])]),_c('ul',{class:{
              nav: true,
              'nav-open animate__animated animate__fadeIn dsp': menu.open,
              'no-dsp animate__animated animate__fadeOut': menu.open == false
            },attrs:{"data-toggle":("collapse-" + indexM)}},[_c('li',{staticClass:"nav-item"},_vm._l((menu.data),function(submenu,indexS){return _c('a',{key:indexS,staticClass:"nav-link",attrs:{"href":("#" + (submenu.name))},on:{"click":function($event){return _vm.changeGame(submenu.sport_id, submenu.id)}}},[_c('p',{staticClass:"txt-submenu"},[_vm._v(_vm._s(submenu.name)+"s")])])}),0)])]],2)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }