import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import jwt from "jsonwebtoken";
import taquilla from "./modules/taquilla";
import taquillaModule from "@/modules/taquilla/store";
import favoritos from "./modules/favoritos";
import notificaciones from "./modules/notificaciones";

import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";

Vue.use(Vuex);
// const vuexSession = new VuexPersistence({
//   storage: window.sessionStorage,
//   modules: [
//     // 'moduloUserCustom'
//   ]
// });

/*
[12:06 PM, 1/13/2022] +58 414-2707190: 3 endpoints adicionales
[12:07 PM, 1/13/2022] +58 414-2707190: virtual lotto: http://apipwa.sellatuparley.com/api/client?game_url=6&provider=triples
[12:08 PM, 1/13/2022] +58 414-2707190: trio activo: http://apipwa.sellatuparley.com/api/client?game_url=45&provider=triples
[12:08 PM, 1/13/2022] +58 414-2707190: spin500: http://apipwa.sellatuparley.com/api/client?game_url=1&provider=spin500
*/
const store = new Vuex.Store({
  plugins: [createPersistedState({})],
  strict: true,
  state: {
    hasConexion: true,
    expire: 0,
    userRec: "",
    passdRec: "",
    themelight: false,
    modulesWithLogin: ["Cuenta", "Juegos", "DeportesMovil"],
    loader: false,
    user: window.UserClient,
    token: null,
    page: getPageByDefault(),
    results: null,
    scroll: 0,
    menuMobilOpen: false,
    sidebar: false,
    sliders: [],
    slidersMinIsLoad: false,
    countSlidersLoads: 0,
    betsTypes: null,
    howToBet: null,
    bonusTermsConditions: null,
    balaces: null,
    bonus: null,
    charges: null,
    payments: null,
    accounts: null,
    tickets: null,
    ticket: null,
    products: null,
    centerAccounts: null,
    mensajeria: null,
    mensajeId: null,
    weeklyReport: null,
    security: false,
    theme: "light",
    leagues: null,
    sportsLeagues: null,
    games: null,
    menuOptionsLeagues: [],
    menuOptionsDestacados: [],
    miniBannersPromociones: [],
    allLeagues: null,
    menuTargetAll: false,
    video: null,
    betInit: null,
    detailsFloating: false,
    dataSlots: [],
    countries: null,
    cities: null,
    urlLiveBetting: null,
    Urliframe: {
      url: null,
      notice: null
    },
    JuegosVirtualesPragmatic: null,
    JuegosVirtualesBetradar: null,
    accountsPagoMobil: [],
    accountsBancos: [],
    visualWidth: window.visualViewport ? window.visualViewport.width : window.innerWidth,
    visualHeight: window.visualViewport ? window.visualViewport.height : window.innerHeight,
    ayudas: null,
    CuentasBancosCMS: null,
    ContactoCMS: null,
    terminosCondiciones: null,
    bonosTerminosCondiciones: null,
    RecargaCMS: null,
    RetiroCMS: null,
    RecargaPagoMovilCMS: null,
    ParametrosCMS: null,
    ComoApostarCMS: null,
    PreguntasCMS: null,
    TiposApuestaCMS: null,
    Pestana: null,
    muestraTitulo: true,
    taquillaFlotante: false,
    controlTime: null,
    iframeActive: false,
    CuentaBancos: [],
    centerBanks: null,
    criptoGateways: [],
    criptoPayments: [],
    criptoWallet: null,
    dataWallet: null,
    criptoCharges: [],
    criptoAccounts: [],
    simboloMoneda: null,
    country: null,
    paymentMonnet: null
  },
  mutations: {
    setHasConexion(state, posee) {
      state.hasConexion = posee;
    },
    setChangeStatusOpenMenuSportLeagues(state, option) {
      state.sportsLeagues[option.index] = option;
    },
    setSportsLeagues(state, sportsLeagues) {
      state.sportsLeagues = sportsLeagues;
    },
    setCuentaBancos(state, accountsBanks) {
      state.CuentaBancos = accountsBanks;
    },
    setControlTime(state, controlTime) {
      state.controlTime = parseInt(controlTime);
    },
    setExpire(state, expiration) {
      state.expire = expiration;
    },
    setTaquillaFlotante(state, flotante) {
      state.taquillaFlotante = flotante;
    },
    setUserRec(state, userRec) {
      state.userRec = userRec;
    },
    setPassRec(state, passRec) {
      state.passRec = passRec;
    },
    setJuegosVirtualesBetradar(state, data) {
      state.JuegosVirtualesBetradar = data;
    },
    setJuegosVirtualesPragmatic(state, data) {
      state.JuegosVirtualesPragmatic = data;
    },
    setAccountsPagoMobil(state, data) {
      state.accountsPagoMobil = data;
    },
    setAccountsBancos(state, data) {
      state.accountsBancos = data;
    },
    setUrliframe(state, data) {
      state.Urliframe = data;
    },
    setUrlLiveBetting(state, data) {
      state.urlLiveBetting = data;
    },
    setDataSlots(state, data) {
      state.dataSlots = data;
    },
    setCountries(state, data) {
      state.countries = data;
    },
    setCities(state, data) {
      state.cities = data;
    },
    addSliders(state, slider) {
      state.sliders.push(slider);
    },
    addMiniBannerPromocion(state, miniBanner) {
      state.miniBannersPromociones.push(miniBanner);
    },
    setThemelight(state, statusActivation) {
      state.themelight = statusActivation;
    },
    setVisualWidth(state, width) {
      state.visualWidth = width;
    },
    setVisualHeight(state, height) {
      state.visualHeight = height;
    },
    setDetailsFloating(state, statusActivation) {
      state.detailsFloating = statusActivation;
    },
    setMenuTargetAll(state, targetAll) {
      state.menuTargetAll = targetAll;
    },
    setMenuOptionsLeagues(state, optionsL) {
      state.menuOptionsLeagues = optionsL;
    },
    setMenuOptionsLeaguesDestacados(state, optionsL) {
      state.menuOptionsDestacados = optionsL;
    },
    setSecurity(state, security) {
      state.security = security;
    },
    setWeeklyReport(state, weeklyReport) {
      state.weeklyReport = weeklyReport;
    },
    setSliders(state, sliders) {
      state.sliders = sliders;
    },
    setMiniBannersPromociones(state, miniBaners) {
      state.miniBannersPromociones = miniBaners;
    },
    setBalaces(state, balaces) {
      state.balaces = balaces;
    },
    setCenterAccounts(state, centerAccounts) {
      state.centerAccounts = centerAccounts;
    },
    setCenterBanks(state, centerBanks) {
      state.centerBanks = centerBanks;
    },
    setBonus(state, bonus) {
      state.bonus = bonus;
    },
    setCharges(state, charges) {
      state.charges = charges;
    },
    setPayments(state, payments) {
      state.payments = payments;
    },
    setMensajeria(state, mensajeria) {
      state.mensajeria = mensajeria;
    },
    setAccounts(state, accounts) {
      state.accounts = accounts;
    },
    setTickets(state, tickets) {
      state.tickets = tickets;
    },
    setTicket(state, ticket) {
      state.ticket = ticket;
    },
    setMensajeId(state, mensajeId) {
      state.mensajeId = mensajeId;
    },
    setProducts(state, products) {
      state.products = products;
    },
    setLeagues(state, leagues) {
      state.leagues = leagues;
    },
    setAllLeagues(state, leagues) {
      state.allLeagues = leagues;
    },
    setGames(state, games) {
      state.games = games;
    },
    setBetsTypes(state, BetsTypes) {
      state.betsTypes = BetsTypes;
    },
    setHowToBet(state, howToBet) {
      state.howToBet = howToBet;
    },
    setBonusTermsConditions(state, BonusTermsConditions) {
      state.bonusTermsConditions = BonusTermsConditions;
    },
    setCountSlidersLoads(state, c) {
      state.countSlidersLoads = c;
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
    setToken(state, tk) {
      state.token = tk;
    },
    setScroll(state, scroll) {
      state.scroll = scroll;
    },
    setMenuMobilOpen(state, statusMenu) {
      state.menuMobilOpen = statusMenu;
    },
    setUser(state, userData) {
      state.user = userData;
    },
    setTheme(state, theme) {
      state.theme = theme;
    },
    setPage(state, page) {
      state.page = page;
      if (localStorage) {
        localStorage.setItem("page", state.page);
      }
    },
    setResults(state, results) {
      state.results = results;
    },
    setChangeStatusOpenMenu(state, openStatus) {
      state.menuOptionsLeagues.forEach(element => {
        if (element.open) element.open = false;
      });
      state.menuOptionsLeagues[openStatus.index].open = openStatus.open;
    },
    setExistMinSlidersInCache(state, isLoad) {
      state.slidersMinIsLoad = isLoad;
    },
    setVideo(state, video) {
      state.video = video;
    },
    updateStatusSliderLoad(state, img) {
      state.sliders.map((s, i) => {
        if (s.url == img) {
          state.sliders[i].load = true;
        }
      });
    },
    setBetInit(state, betInit) {
      state.betInit = betInit;
    },
    setCodeBetInit(state, newCode) {
      state.betInit.code = newCode;
    },
    setAyudas(state, payload) {
      state.ayudas = payload;
    },
    setCuentasBancosCMS(state, data) {
      state.CuentasBancosCMS = data;
    },
    setContactoCMS(state, payload) {
      state.ContactoCMS = payload;
    },
    setTerminosCondiciones(state, payload) {
      state.terminosCondiciones = payload;
    },
    setBonosTerminosCondiciones(state, payload) {
      state.bonosTerminosCondiciones = payload;
    },
    setRecargaCMS(state, payload) {
      state.RecargaCMS = payload;
    },
    setRetiroCMS(state, payload) {
      state.RetiroCMS = payload;
    },
    setRecargaPagoMovilCMS(state, payload) {
      state.RecargaPagoMovilCMS = payload;
    },
    setParametrosCMS(state, payload) {
      state.ParametrosCMS = payload;
    },
    setComoApostarCMS(state, payload) {
      state.ComoApostarCMS = payload;
    },
    setPreguntasCMS(state, payload) {
      state.PreguntasCMS = payload;
    },
    setTiposApuestaCMS(state, payload) {
      state.TiposApuestaCMS = payload;
    },
    setPestana(state, pestana) {
      state.Pestana = pestana;
    },
    setMuestraTitulo(state, muestraTitulo) {
      state.MuestraTitulo = muestraTitulo;
    },
    setIframeActive(state, iframeActive) {
      state.iframeActive = iframeActive;
    },
    setCriptoGateways(state, criptoGateways) {
      state.criptoGateways = criptoGateways;
    },
    setCriptoPayments(state, criptoPayments) {
      state.criptoPayments = criptoPayments;
    },
    setCriptoWallet(state, criptoWallet) {
      state.criptoWallet = criptoWallet;
    },
    setDataWallet(state, dataWallet) {
      state.dataWallet = dataWallet;
    },
    setCriptoCharges(state, criptoCharges) {
      state.criptoCharges = criptoCharges;
    },
    setCriptoAccounts(state, criptoAccounts) {
      state.criptoAccounts = criptoAccounts;
    },
    setSimboloMoneda(state, simboloMoneda) {
      state.simboloMoneda = simboloMoneda;
    },
    setCountry(state, country) {
      state.country = country;
    },
    setPaymentMonnet(state, paymentMonnet) {
      state.paymentMonnet = paymentMonnet;
    }
  },
  getters: {
    getHasConexion(state) {
      return state.hasConexion;
    },
    getOpcionesMenu(state) {
      return state.sportsLeagues ? Object.entries(state.sportsLeagues) : [];
    },
    getSportsLeagues(state) {
      return state.sportsLeagues;
    },
    getCuentaBancos(state) {
      return state.CuentaBancos;
    },
    getControlTime(state) {
      return parseInt(state.controlTime);
    },
    getTaquillaFlotante(state) {
      return state.taquillaFlotante;
    },
    getExpire(state) {
      return state.expire;
    },
    getUserRec(state) {
      return state.userRec;
    },
    getPassRec(state) {
      return state.passRec;
    },
    getIsIOS() {
      let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      let macStatus = false;

      let checkNavegador = navigator.vendor.toLocaleLowerCase();

      if (isIOS || checkNavegador.includes("apple")) {
        console.log("This is a IOS device");
        macStatus = true;
      }
      return macStatus;
    },
    getJuegosVirtualesBetradar(state) {
      return state.JuegosVirtualesBetradar;
    },
    getJuegosVirtualesPragmatic(state) {
      return state.JuegosVirtualesPragmatic;
    },
    getAccountsBancos(state) {
      return state.accountsBancos;
    },
    getAccountsPagoMobil(state) {
      return state.accountsPagoMobil;
    },
    getUrliframe(state) {
      return state.Urliframe;
    },
    getCountries(state) {
      return state.countries;
    },
    getUrlLiveBetting(state) {
      return state.urlLiveBetting;
    },
    getCities(state) {
      return state.cities;
    },
    getDataSlots(state) {
      return state.dataSlots;
    },
    getMiniBannersPromociones(state) {
      return state.miniBannersPromociones;
    },
    getThemelight(state) {
      return state.themelight;
    },
    getVisualWidth(state) {
      return state.visualWidth;
    },
    getVisualHeight(state) {
      return state.visualHeight;
    },
    getDetailsFloating(state) {
      return state.detailsFloating;
    },
    getMenuTargetAll(state) {
      return state.menuTargetAll;
    },
    getMenuOptionsLeagues(state) {
      return state.menuOptionsLeagues;
    },
    getMenuOptionsLeaguesDestacados(state) {
      return state.menuOptionsDestacados;
    },
    getSliders(state) {
      return state.sliders;
    },
    getSecurity(state) {
      return state.security;
    },
    getWeeklyReport(state) {
      return state.weeklyReport;
    },
    getSidebar(state) {
      return state.sidebar;
    },
    getBalaces(state) {
      return state.balaces;
    },
    getCenterAccounts(state) {
      return state.centerAccounts;
    },
    getCenterBanks(state) {
      return state.centerBanks;
    },
    getBonus(state) {
      return state.bonus;
    },
    getCharges(state) {
      return state.charges;
    },
    getGames(state) {
      return state.games;
    },
    getPayments(state) {
      return state.payments;
    },
    getMensajeria(state) {
      return state.mensajeria;
    },
    getAccounts(state) {
      return state.accounts;
    },
    getTickets(state) {
      return state.tickets;
    },
    getTicket(state) {
      return state.ticket;
    },
    getTheme(state) {
      return state.theme;
    },
    getProducts(state) {
      return state.products;
    },
    getLeagues(state) {
      return state.leagues;
    },
    getAllLeagues(state) {
      return state.allLeagues;
    },
    getBetsTypes(state) {
      return state.betsTypes;
    },
    getHowToBet(state) {
      return state.howToBet;
    },
    getBonusTermsConditions(state) {
      return state.bonusTermsConditions;
    },
    getVideo(state) {
      return state.video;
    },
    getIsMobilResolution() {
      const resolutionW = window.visualViewport ? window.visualViewport.width : window.innerWidth;
      return resolutionW < 1000 ? true : false;
    },
    getMenuMobilOpen(state) {
      return state.menuMobilOpen;
    },
    getMensajeId(state) {
      return state.mensajeId;
    },
    getScroll(state) {
      return state.scroll;
    },
    getPage(state) {
      return state.page;
    },
    getToken(state) {
      return state.token;
    },
    getUser(state) {
      return state.user;
    },
    getResults(state) {
      return state.results;
    },
    getExistMinSlidersInCache(state) {
      return state.countSlidersLoads > 4 ? true : false;
    },
    getBetInit(state) {
      return state.betInit;
    },
    getAyudas(state) {
      return state.ayudas;
    },
    getCuentasBancosCMS(state) {
      return state.CuentasBancosCMS;
    },
    getContactoCMS(state) {
      return state.ContactoCMS;
    },
    getTerminosCondiciones(state) {
      return state.terminosCondiciones;
    },
    getBonosTerminosCondiciones(state) {
      return state.bonosTerminosCondiciones;
    },
    getRecargaCMS(state) {
      return state.RecargaCMS;
    },
    getRetiroCMS(state) {
      return state.RetiroCMS;
    },
    getRecargaPagoMovilCMS(state) {
      return state.RecargaPagoMovilCMS;
    },
    getParametrosCMS(state) {
      return state.ParametrosCMS;
    },
    getComoApostarCMS(state) {
      return state.ComoApostarCMS;
    },
    getPreguntasCMS(state) {
      return state.PreguntasCMS;
    },
    getTiposApuestaCMS(state) {
      return state.TiposApuestaCMS;
    },
    getPestana(state) {
      return state.Pestana;
    },
    getMuestraTitulo(state) {
      return state.MuestraTitulo;
    },
    getIframeActive(state) {
      return state.iframeActive;
    },
    getCriptoGateways(state) {
      return state.criptoGateways;
    },
    getCriptoPayments(state) {
      return state.criptoPayments;
    },
    getCriptoWallet(state) {
      return state.criptoWallet;
    },
    getDataWallet(state) {
      return state.dataWallet;
    },
    getCriptoCharges(state) {
      return state.criptoCharges;
    },
    getCriptoAccounts(state) {
      return state.criptoAccounts;
    },
    getSimboloMoneda(state) {
      return state.simboloMoneda;
    },
    getCountry(state) {
      return state.country;
    },
    getPaymentMonnet(state) {
      return state.paymentMonnet;
    }
  },
  actions: {
    async searchSportsLeaguesAsync({ state, commit }, obj) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);
      myHeaders.append("Content-Type", `application/json`);

      let requestOptions = {
        method: "GET",
        headers: myHeaders
      };

      let fecha = "";

      if (obj && obj.date) {
        fecha = "&date=" + obj.date;
      }

      return await fetch(process.env.VUE_APP_URL_API + `api/sport?sportsleagues${fecha}`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          if (result.sportsleagues) {
            let resultado = Object.entries(result.sportsleagues);

            const datos = resultado.flatMap(d => {
              d[1].open = false;
              return d[1];
            });

            commit("setSportsLeagues", datos);
          }
          return result;
        })
        .catch(err => {
          commit("setControlTime", null);
          localStorage.setItem("momentum", null);
          console.log("err", err);
        });
    },
    togleTaquillaFlotante({ state, commit }) {
      commit("setTaquillaFlotante", !state.taquillaFlotante);
    },
    async extenderSession({ state, commit, dispatch }) {
      const tk = state.token.access_token;
      const refresh_tk = state.token.refresh_token; //localStorage.getItem("api_r_tk");

      console.log(
        refresh_tk != localStorage.getItem("api_r_tk"),
        "*** tk diff?",
        refresh_tk,
        localStorage.getItem("api_r_tk")
      );

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${tk}`);
      myHeaders.append("Content-Type", `application/json`);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          grant_type: "refresh_token",
          client_id: process.env.VUE_APP_API_ID,
          client_secret: process.env.VUE_APP_API_SECRET,
          refresh_token: refresh_tk,
          scope: ""
        })
      };

      return await fetch(process.env.VUE_APP_URL_API + "oauth/token", requestOptions)
        .then(response => response.json())
        .then(async result => {
          // caso de no obtener el token
          if (!result.access_token) {
            dispatch("clearAndRedirect", { force: true });
          }

          const momento = parseInt(new Date().getTime());
          commit("setControlTime", momento);
          localStorage.setItem("api_r_tk", result.refresh_token);

          commit("setToken", result);
          commit("setExpire", result.expires_in);
          commit("setControlTime", new Date().getTime());

          return result;
        })
        .catch(err => {
          console.log("err", err);
          commit("setControlTime", null);
          localStorage.setItem("momentum", null);
          dispatch("clearAndRedirect", { force: true });
        });
    },
    async searchJuegosVirtualesBetradar({ commit }) {
      // request simple por CDN
      let requestOptions = {
        method: "GET"
      };

      return await fetch(process.env.VUE_APP_CDN_SELLA + "virtuals/slotegrator.json", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log("searchJuegosVirtualesBetradar:::", result);
          commit("setJuegosVirtualesBetradar", result);
          return result;
        })
        .catch(err => {
          console.log("err", err);
        });
    },
    async searchJuegosVirtualesPragmatic({ commit }) {
      // request simple por CDN
      let requestOptions = {
        method: "GET"
      };

      // Listado de juegos -> https://cdn.sellatuparley.com/virtuals/pragmatic.json
      // (pragmatic) api/client?game_url=vppso4&provider=pragmatic

      return await fetch(process.env.VUE_APP_CDN_SELLA + "virtuals/pragmatic.json", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log("searchJuegosVirtualesPragmatic::::", result);
          commit("setJuegosVirtualesPragmatic", result);
          return result;
        })
        .catch(err => {
          console.log("error", err);
        });
    },
    async urlProveedorIframeAsync({ commit, state }, obj) {
      if (!state.token) {
        return null;
      }
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + obj.url, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log("urlProveedorIframeAsync:::", result.game_url);
          commit("setUrliframe", result.game_url);
        })
        .catch(err => {
          console.log("error", err);
        });
    },
    async activarSlotGetURL({ commit, state }, urlRequestIframe) {
      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      return await fetch(urlRequestIframe, requestOptions)
        .then(async resp => {
          console.log("respuesta: ", resp);
          return await resp.json();
        })
        .catch(err => {
          commit("hideLoader");
          console.log("err:::", err);
        });
    },
    async searchSlots({ commit }) {
      // Modelo de data temporal mientras aun no nos entregan el servicio
      // ./model/slotsModel.json
      return await fetch("https://cdn.sellatuparley.com/slots/games.json")
        .then(dt => {
          return dt.json();
        })
        .then(jsonData => {
          commit("setDataSlots", jsonData);
          return jsonData;
        });
    },
    async clickMenuTargetAll({ commit, state }) {
      await commit("setMenuTargetAll", !state.menuTargetAll);
    },
    enableSecurity({ commit }) {
      commit("setSecurity", true);
    },
    incrementCountSlidersLoads({ commit, state }) {
      commit("setCountSlidersLoads", parseInt(state.countSlidersLoads) + 1);
    },
    async searchHowToBet({ commit, dispatch }) {
      await dispatch("refresfToken");
      // Busqueda de los Tipos de Apuesta
      const URL_JSON_HOW_TO_BETS =
        process.env.VUE_APP_JSON_NO_CORS + process.env.VUE_APP_URL_API + "api/contenido/como_apostar";

      await fetch(URL_JSON_HOW_TO_BETS).then(async resp => {
        const obj_json = await resp.json();
        const cont = obj_json.contenido_esp ? obj_json : null;

        commit("setHowToBet", { cont });
      });
    },
    async searcheWeklyReport({ commit, dispatch, state }, config) {
      await dispatch("refresfToken");

      if (!state.token) {
        return null;
      }

      let sport = 0;
      if (config && config.sport) {
        sport = config.sport;
      }

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      // Busqueda de los REPORTES SEMANALES
      const URL_JSON_WEKLY_REPORT =
        process.env.VUE_APP_JSON_NO_CORS + process.env.VUE_APP_URL_API + `api/client/sales?sport=${sport}`;

      await fetch(URL_JSON_WEKLY_REPORT, requestOptions).then(async resp => {
        const obj_json = await resp.json();
        commit("setWeeklyReport", obj_json);
      });
    },
    async searchBetsTypes({ commit, dispatch }) {
      await dispatch("refresfToken");
      // Busqueda de los Tipos de Apuesta
      const URL_JSON_BETS_TYPES =
        process.env.VUE_APP_JSON_NO_CORS + process.env.VUE_APP_URL_API + "api/contenido/tipo_apuestas";

      await fetch(URL_JSON_BETS_TYPES).then(async resp => {
        const obj_json = await resp.json();
        const cont = obj_json.cont ? JSON.parse(obj_json.cont) : null;
        const promo = obj_json.promo ? JSON.parse(obj_json.promo) : null;

        commit("setBetsTypes", { cont, promo });
      });
    },
    async searchBonusTermsConditions({ commit, dispatch }) {
      await dispatch("refresfToken");
      // Busqueda de los bonos_terminos_condiciones
      const URL_JSON_BETS_TYPES =
        process.env.VUE_APP_JSON_NO_CORS + process.env.VUE_APP_URL_API + "api/contenido/bonos_terminos_condiciones";

      await fetch(URL_JSON_BETS_TYPES).then(async resp => {
        const obj_json = await resp.json();

        const r = await evaluaRespuestaErrorRequestMultiplesCasos(obj_json, "bonos terminos y condiciones");

        if (r != -1) {
          const cont = obj_json.cont ? JSON.parse(obj_json.cont) : null;
          const promo = obj_json.promo ? JSON.parse(obj_json.promo) : null;
          const param = obj_json.param ? JSON.parse(obj_json.param) : null;
          commit("setBonusTermsConditions", { cont, promo, param });
        }
      });
    },
    async searchMiniBannersPromociones({ commit }) {
      let promesas = [];
      let url_sliders = [];
      // const URL_JSON_SLIDERS = process.env.VUE_APP_JSON_NO_CORS + process.env.VUE_APP_URL_SLIDERS_PROMOCIONES_SERVICE;
      const URL_JSON_SLIDERS =
        process.env.VUE_APP_JSON_NO_CORS + process.env.VUE_APP_URL_API_CMS + "/api/v1/getPromociones";
      await fetch(URL_JSON_SLIDERS)
        .then(async resp => {
          let obj_json = await resp.json();
          let slider_activos = [];
          let btn = 0;
          obj_json.data.map(s => {
            if (s.mostrar) {
              s.url = s.img_btn_1;
              s.urlp = s.img_btn_1;
              s.urlOem = s.img_btn_1;
              btn = 1;

              if (moment(s.fecha_fin, "YYYY-MM-DD HH:mm:ss").toDate() >= new Date()) {
                s.url = s.img_btn_2;
                s.urlp = s.img_btn_2;
                s.urlOem = s.img_btn_2;
                btn = 2;
              }
              if (s.status == 2) {
                s.url = s.img_btn_2;
                s.urlp = s.img_btn_2;
                s.urlOem = s.img_btn_2;
                btn = 2;
              }
              if (s.status == 3) {
                s.url = s.img_btn_3;
                s.urlp = s.img_btn_3;
                s.urlOem = s.img_btn_3;
                btn = 3;
              }
              switch (btn) {
                case 1:
                  if (s.img_btn_1) {
                    s.load = false;
                    slider_activos.push(s);
                    promesas.push(fetch(s.url));
                    url_sliders.push(s.url);
                  }
                  break;
                case 2:
                  if (s.img_btn_2) {
                    s.load = false;
                    slider_activos.push(s);
                    promesas.push(fetch(s.url));
                    url_sliders.push(s.url);
                  }
                  break;
                case 3:
                  if (s.img_btn_3) {
                    s.load = false;
                    slider_activos.push(s);
                    promesas.push(fetch(s.url));
                    url_sliders.push(s.url);
                  }
                  break;
              }
            }
          });
          // cargar en memoria
          // await Promise.all(promesas);

          // Generacion de Cache para esta matriz de imagenes de los sliders
          await caches
            .open(process.env.VUE_APP_CACHE_STATIC_NAME)
            .then(async cache => {
              let llaves = await cache.keys();

              const unicosSinDuplicar = new Set(url_sliders);
              let result_url_sliders_unicos = [...unicosSinDuplicar];

              if (llaves.length == 0) {
                cache.addAll(result_url_sliders_unicos).catch(function(error) {
                  console.log("problema al Obtener cache en uno de los recursos:", error);
                });
              }
            })
            .catch(function(error) {
              console.log("Hubo un problema con la petición matriz de imagenes de los sliders Fetch:" + error.message);
            });

          await new Promise(resolve => {
            setTimeout(function() {
              commit("setMiniBannersPromociones", slider_activos);
              resolve("¡Éxito!"); // ¡Todo salió bien!
            }, 3000);
          });
        })
        .catch(function(error) {
          console.log("Hubo un problema con la petición matriz de imagenes de los sliders Fetch:" + error.message);
        });
    },
    async searchUserAccountData({ commit, state, dispatch }) {
      if (!state.token) {
        return null;
      }
      const urlApi = process.env.VUE_APP_URL_API + `api/client?accounts`;
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      return await fetch(urlApi, requestOptions)
        .then(async response => {
          let dt = await response.clone().json();
          let accountsPagoMobil = dt.accounts.filter(d => {
            return d.type_account_id == 4;
          });
          let accountsBancos = dt.accounts.filter(d => {
            return [1, 2, 5, 6, 7, 8].includes(d.type_account_id);
          });

          commit("setAccountsPagoMobil", accountsPagoMobil);
          commit("setAccountsBancos", accountsBancos);
          commit("setCuentaBancos", dt.accounts);

          return dt.accounts;
        })
        .catch(async error => {
          localStorage.removeItem("page");
          localStorage.removeItem("lastPage");
          dispatch("logOut");
          commit("setPage", "Home");

          const errorToken = error.toString().includes("Unexpected token < in JSON at position 0") ? true : false;

          if (errorToken) {
            await dispatch("clearAndRedirect");
          }

          console.log("error", error);
        });
    },
    togleMenuMobil({ commit, state }) {
      const statusPevious = state.menuMobilOpen;
      commit("setMenuMobilOpen", !statusPevious);
    },
    changePage({ commit, dispatch }, page) {
      taquilla.mutations.setRestriccionParley([]);
      //taquilla.mutations.setExcepcionParley(false);
      commit("setPage", page);
      localStorage.setItem("lastPage", page);
      dispatch("checkSession");
    },
    sidebar({ commit }, show) {
      commit("setSidebar", show);
    },
    logOut({ commit }) {
      localStorage.removeItem("sp_tk");
      localStorage.removeItem("moduleTarget");
      localStorage.removeItem("apuestas");
      taquilla.mutations.setApuestas([]);
      taquilla.mutations.setRestriccionParley([]);
      //taquilla.mutations.setExcepcionParley(false);
      localStorage.setItem("page", "Home");
      commit("setExpire", 0);
      commit("setUser", null);
      commit("setToken", null);
      commit("setPage", "Home");
      commit("setCuentaBancos", []);
      commit("setCenterBanks", null);
      commit("setCenterAccounts", null);
      commit("setControlTime", null);
      commit("setSimboloMoneda", null);
    },
    async sendTicketGenerate({ state, dispatch }, data) {
      await dispatch("refresfToken");

      if (!state.token) {
        return null;
      }

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);
      myHeaders.append("Content-Type", `application/json`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify(data)
      };

      return await fetch(process.env.VUE_APP_URL_API + "api/center?accounts", requestOptions)
        .then(response => response.json())
        .then(result => {
          return result;
        });
    },
    async getCenterAccountsAsync({ commit, state, dispatch }) {
      await dispatch("refresfToken");

      if (!state.token) {
        return null;
      }

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(process.env.VUE_APP_URL_API + "api/center?accounts", requestOptions)
        .then(response => response.json())
        .then(result => {
          commit("setCenterAccounts", result.accounts);
        });
    },
    async getBalacesUserAsync({ commit, state, dispatch }, config) {
      await dispatch("refresfToken");

      if (!state.token) {
        return null;
      }

      let page = 1;
      let perpage = 15;

      if (config && config.page) {
        page = config.page;
      }

      if (config && config.perpage) {
        perpage = config.perpage;
      }

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + `api/client?balances=${page}&perpage=${perpage}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.balances && result.balances.per_page) {
            result.balances.per_page = parseInt(result.balances.per_page);
          }
          commit("setBalaces", result.balances);
          return result.balances;
        });
    },
    async getPaymentsUserAsync({ commit, state, dispatch }, config) {
      await dispatch("refresfToken");

      if (!state.token) {
        return null;
      }

      let page = 1;
      let perpage = 15;

      if (config && config.page) {
        page = config.page;
      }

      if (config && config.perpage) {
        perpage = config.perpage;
      }

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + `api/client?payments=${page}&perpage=${perpage}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.payments && result.payments.per_page) {
            result.payments.per_page = parseInt(result.payments.per_page);
          }

          commit("setPayments", result.payments);
          return result.payments;
        });
    },
    async getBonusUserAsync({ commit, state, dispatch }, config) {
      if (!state.token) {
        return null;
      }

      let page = 1;
      let perpage = 15;
      let date = "&date=";

      if (config && config.page) {
        page = config.page;
      }

      if (config && config.perpage) {
        perpage = config.perpage;
      }
      if (config && config.date) {
        date = date + config.date;
      }

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + `api/client?bonus=${page}&perpage=${perpage}${date}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log("Bonus results: ", result);
          commit("setBonus", result.bonus);
        })
        .catch(async error => {
          const errorToken = error.toString().includes("Unexpected token < in JSON at position 0") ? true : false;

          if (errorToken) {
            localStorage.removeItem("page");
            localStorage.removeItem("lastPage");
            await dispatch("clearAndRedirect");
          }
        });
    },
    async getMessengerUserAsync({ commit, state, dispatch }, config) {
      await dispatch("refresfToken");

      if (!state.token) {
        return null;
      }

      let page = 1;
      let perpage = 5;

      if (config && config.page) {
        page = config.page;
      }

      if (config && config.perpage) {
        perpage = config.perpage;
      }
      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(process.env.VUE_APP_URL_API + `api/client?messages=${page}&perpage=${perpage}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.messages && result.messages.per_page) {
            result.messages.per_page = parseInt(result.messages.per_page);
          }
          commit("setMensajeria", result.messages);
          return result.messages;
        });
    },
    async getChargesUserAsync({ commit, state, dispatch }, config) {
      await dispatch("refresfToken");

      if (!state.token) {
        return null;
      }

      var myHeaders = new Headers();
      let page = 1;
      let perpage = 15;

      if (config && config.page) {
        page = config.page;
      }

      if (config && config.perpage) {
        perpage = config.perpage;
      }

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + `api/client?charges=${page}&perpage=${perpage}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.charges) {
            commit("setCharges", result.charges);
          }
        });
    },
    async getCurrentCountry({ commit }) {
      $.get("https://www.cloudflare.com/cdn-cgi/trace", function(data) {
        data = data
          .trim()
          .split("\n")
          .reduce(function(obj, pair) {
            pair = pair.split("=");
            return (obj[pair[0]] = pair[1]), obj;
          }, {});
        commit("setCountry", data.loc);
      });
    },
    async getProductsClientAsync({ commit, state, dispatch }) {
      await dispatch("refresfToken");

      if (!state.token) {
        return null;
      }

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + "api/client?products", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.products) {
            commit("setProducts", result.products);
          }
        });
    },
    async getAccountsClientAsync({ commit, state, dispatch }) {
      await dispatch("refresfToken");

      if (!state.token) {
        return null;
      }

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + "api/client?accounts", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.accounts) {
            commit("setAccounts", result.accounts);
          }
        })
        .catch(async error => {
          localStorage.removeItem("page");
          localStorage.removeItem("lastPage");
          dispatch("logOut");
          commit("setPage", "Home");

          const errorToken = error.toString().includes("Unexpected token < in JSON at position 0") ? true : false;

          if (errorToken) {
            await dispatch("clearAndRedirect");
          }

          console.log("error", error);
        });
    },
    async getTicketsClientAsync({ commit, state, dispatch }, config) {
      await dispatch("refresfToken");

      if (!state.token) {
        return null;
      }

      var myHeaders = new Headers();

      let page = 1;
      let status = 0;
      let number = "";
      let since = "";
      let until = "";

      if (config && config.page) {
        page = config.page;
      }

      if (config && config.status) {
        status = config.status;
      }

      if (config && config.number && config.number != "") {
        number = "&number=" + config.number;
      }
      // since <- fecha desde , until <- fecha hasta
      if (config && config.since && config.since != "") {
        since = "&since=" + config.since;
      }
      if (config && config.until && config.until != "") {
        until = "&until=" + config.until;
      }

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(
        process.env.VUE_APP_URL_API + `api/client?queries=&tickets=${page}&status=${status + number + since + until}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.tickets) {
            commit("setTickets", result.tickets);
            return result.tickets;
          }
          if (result && result.error && result.error != "" && result.message) {
            Swal.fire("Lo sentimos!", result.message, "error");
          }

          return result;
        });
    },
    async targetUserAsync({ commit, dispatch, state }) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + "api/user", requestOptions)
        .then(response => response.json())
        .then(result => {
          let simboloMoneda = result.center_id == 2 ? result.Coin.moneda : "$";
          commit("setSimboloMoneda", simboloMoneda);
          commit("setUser", result);
        })
        .catch(async error => {
          const r = await dispatch("isValidToken");
          const errorToken = error.toString().includes("Unexpected token < in JSON at position 0") ? true : false;
          console.log("catch -> targetUserAsync * error", error, r, errorToken);
        });
    },
    async getCenterAccountsBanksAsync({ commit, state, dispatch }) {
      await dispatch("refresfToken");
      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + "api/center?accounts&banks", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.banks) {
            commit("setCenterBanks", result.banks);
          }
          if (result.accounts) {
            commit("setCenterAccounts", result.accounts);
          }
        });
    },
    async getLeaguesAsync({ commit, state, dispatch }, config) {
      if (!state.token || !state.token.access_token) {
        return [];
      }
      await dispatch("refresfToken");

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      let date = config && config.date ? `&date=${config.date}` : ""; // (YYYY-MM-DD) 2021-06-23

      if (config && config.all != undefined && config.all == true) {
        date = "";
      }

      await fetch(process.env.VUE_APP_URL_API + `api/sport?leagues${date}`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          const hasError = await evaluaRespuestaErrorRequestMultiplesCasos(result, "leagues sport");

          if (hasError != -1 && result.leagues) {
            let dataMenuGroups = [];
            let dataMenuFinal = [];

            result.leagues.map(l => {
              if (dataMenuGroups[l.sport] == undefined) {
                dataMenuGroups[l.sport] = result.leagues.flatMap(s => (s.sport == l.sport ? s : []));

                // ordenamiento alfabetico de los itens del menu
                const ordenados = dataMenuGroups[l.sport].sort(function(a, b) {
                  if (a.sport_id > b.sport_id) {
                    return 1;
                  }
                  if (a.sport_id < b.sport_id) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });

                dataMenuFinal.push({ name: l.sport, sport_id: l.sport_id, data: ordenados, open: false });
              }
            });

            commit("setLeagues", result.leagues);
            if (date == "") {
              commit("setAllLeagues", result.leagues);
            }
            const dataMenu = JSON.parse(JSON.stringify(dataMenuFinal));

            // ordenamiento alfabetico de los itens del menu
            const ordenados = dataMenu.sort(function(a, b) {
              if (a.sport_id > b.sport_id) {
                return 1;
              }
              if (a.sport_id < b.sport_id) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });

            commit("setMenuOptionsLeagues", ordenados);

            if (state.menuOptionsDestacados && state.menuOptionsDestacados.length == 0) {
              commit("setMenuOptionsLeaguesDestacados", dataMenu);
            }
          }
        });
    },
    // async getLigas({ commit, state, dispatch }) {
    //   await dispatch("refresfToken");

    //   var myHeaders = new Headers();

    //   myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

    //   var requestOptions = {
    //     method: "GET",
    //     headers: myHeaders,
    //     redirect: "follow"
    //   };

    //   await fetch(
    //     process.env.VUE_APP_URL_API + `api/deportes?leagues`,
    //     requestOptions
    //   )
    //     .then(response => response.json())
    //     .then(async result => {
    //       console.log("Result: ", result)
    //     })
    //     .catch(error => console.log("error", error));
    // },
    async getGamesAsync({ commit, state, dispatch }, config) {
      await dispatch("refresfToken");

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      const league_id = config && config.league_id && config.league_id != "" ? `&league_id=${config.league_id}` : "";
      // const fechaHoy = moment(String(new Date())).format("YYYY-MM-DD");
      const date =
        config && config.date && (!state.menuTargetAll || (config.customDate != undefined && config.customDate == true))
          ? `&date=${config.date}`
          : ``; // (YYYY-MM-DD) 2021-06-23 &date=${fechaHoy}
      const game_id = config && config.game_id ? `&game_id=${config.game_id}` : "";
      const id = config && config.id ? `&id=${config.id}` : "";
      const sport_id = config && config.sport_id ? `&sport_id=${config.sport_id}` : "";

      await fetch(
        process.env.VUE_APP_URL_API + `api/sport?games${league_id}${date}${game_id}${id}${sport_id}`,
        requestOptions
      )
        .then(response => response.json())
        .then(async result => {
          const hasError = await evaluaRespuestaErrorRequestMultiplesCasos(result, "games sport");

          if (hasError != -1 && result.games) {
            commit("setGames", result.games);
            return result.games;
          }
        })
        .catch(error => console.log("error", error));
    },
    async getLiveBettingAsync({ commit, state }, config) {
      if (!state.token) {
        return null;
      }
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      const game_url =
        config && config.game_url && config.game_url != "" ? `game_url=${config.game_url}` : `game_url=1`;

      const mobile = config && config.game_url && config.game_url == 2 ? `mobile=1` : ``;

      await fetch(process.env.VUE_APP_URL_API + `api/client?${game_url}&provider=livebetting&${mobile}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          // commit("setUrlLiveBetting", result.game_url.url);
          commit("setUrliframe", result.game_url);
        })
        .catch(err => {
          console.log("err", err);
        });
    },

    async validatePhone({ state }, phoneNumber) {
      if (!state.token) {
        return null;
      }
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      return await fetch(process.env.VUE_APP_URL_API + "api/client?valid_phone=" + phoneNumber, requestOptions)
        .then(response => response.json())
        .then(result => {
          return result;
        });
    },

    async getVideoAsync({ commit, state, dispatch }) {
      await dispatch("refresfToken");

      if (!state.token) {
        return null;
      }

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + "api/sport?videos", requestOptions)
        .then(response => response.json())
        .then(async result => {
          const hasError = await evaluaRespuestaErrorRequestMultiplesCasos(result, "videos sport");

          if (hasError != -1) {
            commit("setVideo", result.videos);
          }
        });
    },
    async registerTicketAsync({ state, dispatch, commit }, data) {
      await dispatch("refresfToken");
      // DEBUG ERROR -> return { "Type_error": 9, "odds": [{ "odd": 360, "odd_a": 4.6, "outcome_id": "1712", "factor": 1, "ref": "FTX115X1600575" }], "Error": "Algunas apuestas han cambiado su cuota!" };

      // registro de respuesta

      if (!state.token) {
        return null;
      }

      console.log("Data de ticket: ", data);

      const resp = await fetch(process.env.VUE_APP_URL_API + "api/sport/valid_ticket", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token.access_token}`
        },
        method: "POST",
        body: JSON.stringify(data)
      })
        .then(async res => {
          const datos = await res.json();

          let myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

          let requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
          };

          // actualiza saldos del usuario
          await fetch(process.env.VUE_APP_URL_API + "api/user", requestOptions)
            .then(response => response.json())
            .then(result => {
              commit("setUser", result);
            });

          // actualiza tabla de balances
          await fetch(process.env.VUE_APP_URL_API + `api/client?balances=1&perpage=15`, requestOptions)
            .then(response => response.json())
            .then(result => {
              commit("setBalaces", result.balances);
            });

          return datos;
        })
        .catch(function() {
          return {
            Type_error: 7,
            Error: "Ha ocurrido un error en la conexion porfavor intente mas tarde."
          };
        });

      return resp;

      /*
                          {
                            "Ticket": {
                              "amount": "600000",
                              "amount_ind": "200000.00",
                              "modalidad": "true",
                              "bonus": "0",
                              "code": "151630414552",
                              "procesed": "2021-08-31",
                              "Odds": {
                                "FTX1X1270464": {
                                  "game_id": "1270464",
                                  "type": "FT",
                                  "odd_type": "1",
                                  "odd": "-115",
                                  "odd_a": "1.87",
                                  "factor": "",
                                  "status": "1",
                                  "code": "1270464XFTX1"
                                },
                                "FTX2X1270456": {
                                  "game_id": "1270456",
                                  "type": "FT",
                                  "odd_type": "2",
                                  "odd": "-175",
                                  "odd_a": "1.57",
                                  "factor": "",
                                  "status": "1",
                                  "code": "1270456XFTX2"
                                },
                                "FTX1X1270462": {
                                  "game_id": "1270462",
                                  "type": "FT",
                                  "odd_type": "1",
                                  "odd": "-160",
                                  "odd_a": "1.63",
                                  "factor": "",
                                  "status": "1",
                                  "code": "1270462XFTX1"
                                }
                              }
                            }
                            }
                        */
    },
    async searchResults({ commit }) {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      const fechaArray = new Date()
        .toLocaleDateString("nl", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        })
        .split("-");
      fetch(
        process.env.VUE_APP_URL_API +
          `api/contenido/resultados?date=${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`,
        requestOptions
      )
        .then(async response => {
          return await response.json();
        })
        .then(result => {
          commit("setResults", result);
        });

      // http://apipwa.sellatuparley.com/api/contenido/resultados?date=
    },
    async dalayFor(ms = 200) {
      return await new Promise(resolve => {
        setTimeout(() => {
          resolve("ok");
        }, ms);
      });
    },
    async ImgExistInCacheAsync({ commit }, img) {
      let existe = false;
      let prom = null;

      do {
        let reqImgExist = await caches.match(img);
        prom = await new Promise(resolve => {
          if (reqImgExist && reqImgExist.status && reqImgExist.status == 200 && reqImgExist.ok == true) {
            commit("updateStatusSliderLoad", img);
            existe = true;
            resolve("ImgLoad");
          }
        });
      } while (existe == false);

      return prom;
    },
    async isValidToken() {
      return true; // debug - logica trabajada en main
      // token local pwa -> localStorage.getItem("sp_tk") (encriptar data y serealizar)
      // state.token.access_token -> token interno de servicios comunicacion con backend
      //{ state }
      // if (!state.token || !localStorage.getItem("sp_tk")) {
      //   return false;
      // }

      // return jwt.verify(localStorage.getItem("sp_tk"), process.env.VUE_APP_API_SECRET, async function(err, decoded) {
      //   let status = true;
      //   var current_time = new Date().getTime() / 1000;

      //   if (err != null || !decoded.exp || (decoded.exp && current_time > decoded.exp)) {
      //     return false;
      //   }

      //   return status;
      // });

      // console.log(
      //   current_time,
      //   decoded.exp,
      //   (current_time > decoded.exp),
      //   decoded,
      //   state.token.access_token
      //    );

      // if (current_time > decoded.exp) {
      //   console.log('* expired *');
      // }

      // console.log(
      //   (current_time > decoded.exp),
      //   current_time , decoded.exp,
      //  ( err == null && (current_time > decoded.exp))
      // );

      // return !err && decoded.tk ? true : false;
    },
    async checkLogin({ commit }) {
      //  state,  || !state.user
      // si no esta logueado o no tiene token lo manda para el Home
      if (localStorage && !localStorage.getItem("page")) {
        localStorage.removeItem("moduleTarget");
        localStorage.setItem("page", "Home");
        localStorage.removeItem("apuestas");
        taquilla.mutations.setApuestas([]);
        taquilla.mutations.setRestriccionParley([]);
        //taquilla.mutations.setExcepcionParley(false);
        //commit("setExcepcionParley",false);
        commit("setPage", "Home");
        return false;
      } else {
        return true;
      }
    },
    async checkSession({ commit, dispatch, state }) {
      if (localStorage && localStorage.getItem("page") && !localStorage.getItem("sp_tk")) {
        // si la pagina seteada pertenece a los modulos con login
        // se setea a Home ya que se requiere login para acceder a estos modulos
        const resultado = state.modulesWithLogin.filter(v => {
          return v == localStorage.getItem("page");
        });

        if (resultado.length > 0) {
          localStorage.setItem("page", "Home");
          localStorage.setItem("moduleTarget", "recargas"); // Opcion por default si cae en cuenta

          commit("setPage", "Home");
          dispatch("logOut");
        }
      } else {
        // si tiene session que setee la pagina sin problemas
        localStorage.setItem("page", localStorage.getItem("page"));
      }

      if (localStorage && localStorage.getItem("sp_tk")) {
        const tkSerial = localStorage.getItem("sp_tk");

        jwt.verify(tkSerial, process.env.VUE_APP_API_SECRET, async function(err, decoded) {
          if (!err && decoded.tk) {
            await dispatch("targetUserAsync");
          }
          // else {
          // DEBUG
          //   // removiendo token expirado
          //   localStorage.removeItem("sp_tk");
          //   localStorage.removeItem("moduleTarget");
          //   localStorage.setItem("page", "Home");
          //   commit("setPage", "Home");
          // }
        });
      } else {
        dispatch("logOut");
      }
    },
    clearAndRedirect({ commit, dispatch }, data) {
      if (data && data.force && data.force === true) {
        // removiendo token expirado
        localStorage.removeItem("sp_tk");
        localStorage.removeItem("moduleTarget");
        localStorage.removeItem("page");
        localStorage.removeItem("lastPage");
        localStorage.removeItem("apuestas");
        taquilla.mutations.setApuestas([]);
        taquilla.mutations.setRestriccionParley([]);
        //taquilla.mutations.setExcepcionParley(false);

        commit("setExpire", 0);
        commit("setToken", null);
        commit("setUser", null);
        commit("setSecurity", false);
        commit("setPage", "Home");
        dispatch("logOut");

        if (window && window.location && window.location.href) {
          window.location.href = "/";
        }
      }
    },
    openOptionSidebarJuegos({ commit, state }, opt) {
      if (state.menuOptionsLeagues[opt.index]) {
        const isOpen = opt.menu.open == false ? true : false;
        commit("setChangeStatusOpenMenu", { index: opt.index, open: isOpen });
      }
    },
    openOptionSidebarJuegosSportLeagues({ commit, state }, opt) {
      console.log(opt.menu.open, !opt.menu.open, opt);
      let optionChange = {
        name: opt.menu.name,
        sport_id: opt.menu.sport_id,
        icon: opt.menu.icon,
        Leagues: [opt.menu.Leagues],
        open: !opt.menu.open,
        index: opt.index
      };

      // state.sportsLeagues[opt.index] = optionChange;
      // setChangeStatusOpenMenuSportLeagues(option,index)

      commit("setChangeStatusOpenMenuSportLeagues", optionChange, opt.index);

      console.log(state.sportsLeagues[opt.index], opt);
      // if (state.menuOptionsLeagues[opt.index]) {
      //   const isOpen = opt.menu.open == false ? true : false;
      //   commit("setChangeStatusOpenMenu", { index: opt.index, open: isOpen });
      // }
    },
    async refresfToken() {
      return;

      // { state }
      // if (localStorage && localStorage.getItem("sp_tk")) {
      //   const tkSerial = localStorage.getItem("sp_tk");

      //   await jwt.verify(tkSerial, process.env.VUE_APP_API_SECRET, function(err, decoded) {
      //     if (!err && decoded.tk) {
      //       /* Renovacion de TOKEN */
      //       const token = decoded.tk;

      //       const timeExpresion = parseInt(state.expire) + "s"; // tantos Segundos

      //       let tokenSerealized = jwt.sign(
      //         {
      //           tk: token,
      //           creation: new Date(),
      //           online: true
      //         },
      //         process.env.VUE_APP_API_SECRET,
      //         { expiresIn: timeExpresion }
      //       );

      //       console.log(parseInt(state.expire), state.expire, timeExpresion, tokenSerealized);
      //       // ANTES era fijo ya no { expiresIn: process.env.VUE_APP_SESSION_EXPIRATION }

      //       // Token Interno Javascript JWT (para encriptado interno)
      //       // diferente al de la API
      //       localStorage.setItem("sp_tk", tokenSerealized);
      //     }

      //     // else {
      //     //   // DEBUG hay nuevo control ligado al token del servicio
      //     //   // removiendo token expirado
      //     //   // localStorage.removeItem("sp_tk");
      //     //   // localStorage.removeItem("moduleTarget");
      //     //   // localStorage.setItem("page", "Home");
      //     //   // commit("setPage", "Home");
      //     // }
      //   });
      // }
    },
    async getBetInitAsync({ commit, state, dispatch }) {
      if (!state.token || !state.token.access_token) {
        return [];
      }
      await dispatch("refresfToken");

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      await fetch(process.env.VUE_APP_URL_API + `api/sport?bet_init`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          const r = await evaluaRespuestaErrorRequestMultiplesCasos(result, "bet_init");

          if (r != -1) {
            commit("setBetInit", result.bet_init);
            return result.bet_init;
          }
        });
    },
    async eliminarCuentaAsync({ state, dispatch }, account_id) {
      if (!state.token || !state.token.access_token) {
        return [];
      }
      await dispatch("refresfToken");

      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${state.token.access_token}`);

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify({
          id: account_id,
          account_id,
          accountId: account_id
        }),
        redirect: "follow"
      };

      return await fetch(process.env.VUE_APP_URL_API + `api/client/account?id=${account_id}`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          await dispatch("searchUserAccountData");
          return result;
        })
        .catch(error => console.log("error", error));
    },
    async searchCountriesAsync({ commit }) {
      await fetch(process.env.VUE_APP_URL_API + "api/content?cities=&countries=")
        .then(response => response.json())
        .then(result => {
          commit("setCountries", result.countries);
          commit("setCities", result.cities);
        });
    },
    async getCriptoImgAsync({ commit }, obj) {
      var gateways = [];

      await obj.data.gateways.forEach(element => {
        let msg = element.msg ? process.env.VUE_APP_CDN_SELLA + `payment/imgs/${element.msg}` : null;

        let gateway = {
          id: element.id,
          name: element.name,
          type: element.type,
          url: process.env.VUE_APP_CDN_SELLA + `payment/imgs/${element.img}`,
          msg: msg
        };

        gateways.push(gateway);
      });

      commit(`${obj.set}`, gateways);
    },
    async getCriptoAsync({ commit, dispatch, state }, obj) {
      let config = "";
      if (state.token) {
        config = {
          headers: { Authorization: `Bearer ${state.token.access_token}` }
        };
      }
      await axios
        .get(process.env.VUE_APP_URL_API + obj.url, config)
        .then(response => {
          console.log("Response: ", response);
          commit(`${obj.set}`, response.data[`${obj.name}`]);
          if (obj.name == "gateways") {
            //Funcioon extra para asignar las imagenes de los gateways
            dispatch("getCriptoImgAsync", { data: response.data, set: obj.set });
          }
        })
        .catch(err => {
          dispatch("clearAndRedirect");
          console.log("err:::", err);
        });
    },
    async getData({ commit, state }, obj) {
      let config = "";
      if (state.token) {
        config = {
          headers: { Authorization: `Bearer ${state.token.access_token}` }
        };
      }
      await axios.get(process.env.VUE_APP_URL_API_CMS + obj.url, config).then(async response => {
        if (obj.hander) {
          const dt = response.data.data ? response.data.data : response.data;
          const hasError = await obj.hander(dt, obj.handerName);

          if (hasError != -1) {
            commit(`${obj.set}`, response.data.data);
          }
        } else {
          // las que aun no son manejadas por el handler que continuen su curso
          commit(`${obj.set}`, response.data.data);
        }
      });
    },
    async createPost({ dispatch, state }, obj) {
      console.log("objeto post: ", obj);
      let config = "";
      if (state.token) {
        config = {
          headers: { Authorization: `Bearer ${state.token.access_token}` }
        };
      }

      await axios
        .post(process.env.VUE_APP_URL_API + obj.url, obj.data, config)
        .then(response => {
          if (response.data.error) {
            return Swal.fire("Error!", response.data.error, "error");
          } else {
            if (response.status == 200) {
              return Swal.fire("Excelente!", response.data.message, "success");
            }
            if (response.status == 204) {
              return Swal.fire("Excelente!", response.data.message, "success");
            }
            if (response.status == 400) {
              return Swal.fire("Error!", response.data.message, "error");
            }
            if (response.status == 500) {
              console.log("Error::::", response.data.exception);
              return Swal.fire("Error!", response.data.message, "error");
            }
          }
        })
        .catch(err => {
          dispatch("clearAndRedirect");
          console.log("error", err);
        });
    },
    async resetStatusApuestas({ state }) {
      taquilla.mutations.setRestriccionParley([]);
      //taquilla.mutations.setExcepcionParley(false);
      if (!state.taquilla.apuestas) {
        return;
      }
      //taquilla.mutations.setApuestas([]);
      let apuestasRestored = await state.taquilla.apuestas.flatMap(async ap => {
        let apuestaActualizada = await JSON.parse(JSON.stringify(ap));
        // Importante restauracion del estado
        apuestaActualizada.update = false;
        return apuestaActualizada;
      });

      Promise.all(apuestasRestored).then(valoresActualizados => {
        console.log("apuestasRestored:", valoresActualizados);
        taquilla.mutations.setApuestas(valoresActualizados);
      });
    },
    async actualizarApuesta({ state }, obj_odds) {
      let dataApuestasUpdated = await state.taquilla.apuestas.flatMap(async (ap, i) => {
        // busca en la data del servicio la data actualizada con ese id
        const searchOddById = async idref => {
          return await obj_odds.flatMap(odd => (odd.ref == idref ? odd : []));
        };

        let apuestaActualizada = await JSON.parse(JSON.stringify(ap));
        let odd_target = await searchOddById(ap.id);

        if (odd_target.length > 0) {
          // actualizando valores
          apuestaActualizada.respuesta.odd.Odd.odd_a = odd_target[0].odd_a;
          apuestaActualizada.respuesta.odd.Odd.odd = odd_target[0].odd;
          apuestaActualizada.update = true;

          // Importante actualizacion del estado
          state.taquilla.apuestas[i] = apuestaActualizada;
        }

        return apuestaActualizada;
      });

      Promise.all(dataApuestasUpdated).then(valoresActualizados => {
        taquilla.mutations.setApuestas(valoresActualizados);
      });
    }
  },
  modules: {
    taquilla,
    favoritos,
    notificaciones,
    taquillaModule
  }
  // plugins: [vuexSession.plugin]
});

function getPageByDefault() {
  let p = "NotFound";
  const url = window.location.pathname
    .toLocaleLowerCase()
    .trim()
    .replace("/", "");
  const hashPath = window.location.hash
    .toLocaleLowerCase()
    .trim()
    .replace("#/", "");

  if (url == "contacto" || hashPath == "contacto") {
    return "Contacto";
  }

  if (url == "" || hashPath == "home") {
    return "Home";
  }

  if (url == "cuenta" || hashPath == "cuenta") {
    return "Cuenta";
  }

  if (url == "resultados" || hashPath == "resultados") {
    return "Resultados";
  }

  if (url == "preguntas" || hashPath == "preguntas") {
    return "preguntas";
  }

  if (url == "terminos" || hashPath == "terminos") {
    return "Terminos";
  }

  return p;
}

function evaluaRespuestaErrorRequestMultiplesCasos(data, serviceName) {
  // BK if (data && (data.Error || data.error) && (data.code == 500 || data.code == 404)) {
  if (data && (data.Error || data.error)) {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 2500,
      onOpen: toast => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
    });

    Toast.fire({
      icon: "error",
      title: `El servicio ${serviceName} no esta disponible intente mas tarde`
    });

    return -1;
  }
  return;
}

export default store;

// export { store };
