<template>
  <div
    style="background-image: url('../img/Hipismo/hipismo2.jpg'); background-repeat: no-repeat; background-size: cover"
  >
    <section
      :class="{
        'top-movil': this.getIsMobilResolution,
        'overflow-auto': this.getUrliframe
      }"
    >
      <FullLoader :cargando="!this.isLoad && !this.getIsMobilResolution"></FullLoader>

      <div
        :class="{
          'degrado4 margin-title-1': true,
          tema: this.getThemelight,
          tabla: this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          movil: this.getVisualWidth < 500
        }"
        v-if="!urlIframe"
      >
        {{ $t("hipismo_internacional_y_nacional") }}
      </div>

      <div
        :class="{
          degrado4: true,
          tabla: this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          movil: this.getVisualWidth < 500
        }"
        v-if="urlIframe"
      >
        <button class="btn-red btn-regresar" @click="back()">
          <span><i class="fa fa-angle-double-left"></i> {{ $t("regresar") }}</span>
        </button>
      </div>
      <br />
      <div
        :class="{
          'flex-container': this.getVisualWidth > 1280,
          'flex-container-tabla': this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          'flex-container-movil': this.getVisualWidth < 500
        }"
        v-if="!urlIframe"
      >
        <div v-if="monedaExtranjera">
          <img
            v-lazy="'/img/Hipismo/boton-hipismo-nacional-internacional.png'"
            class="img-fluid boder-img"
            alt=""
            @click="openIframe(2)"
            style="cursor: pointer"
          />
        </div>
        <div v-else>
          <img
            v-lazy="'/img/Hipismo/boton-hipismo-internacional.png'"
            class="img-fluid boder-img"
            alt=""
            @click="openIframe(1)"
            style="cursor: pointer"
          />
        </div>
      </div>

      <div
        v-if="!urlIframe"
        :class="{
          'degrado4 margin-title-2': true,
          tema: this.getThemelight,
          titulo: this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          movil: this.getVisualWidth < 500
        }"
      >
        {{ $t("juegos_virtuales") }}
      </div>

      <div
        :class="{
          'flex-container': this.getVisualWidth > 1280,
          'flex-container-tabla': this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          'flex-container-movil': this.getVisualWidth < 500
        }"
        v-if="!urlIframe"
      >
        <div>
          <img
            v-lazy="'/img/Hipismo/caballos_virtuales.png'"
            class="img-fluid boder-img"
            alt=""
            @click="openIframe(3)"
            style="cursor: pointer"
          />
        </div>

        <div>
          <img
            v-lazy="'/img/Hipismo/galgos_virtuales.png'"
            class="img-fluid boder-img"
            alt=""
            @click="openIframe(4)"
            style="cursor: pointer"
          />
        </div>
      </div>

      <!-- iframes -->
      <div class="row" v-if="urlIframe">
        <div
          v-if="this.iframe == 0"
          class="text-center bg-light"
          :class="{ 'w-25': !this.getIsMobilResolution }"
          style="margin-top: 10% !important"
        >
          <div>
            <img v-lazy="'/img/mantenimiento/mantenimiento.png'" width="350" height="250" />
            <div @click="back()" class="form-inline text-danger">
              <i class="fa fa-angle-double-left fa-lg mx-3"></i>
              <h3>
                <b>{{ $t("volver") }}</b>
              </h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
        </div>
        <div class="row">
          <ViewIframe
            v-if="iframe != 0 && this.getUrliframe.url"
            :url="this.getUrliframe.url"
            style="top: 180px !important; bottom: 10px"
          />
        </div>
      </div>
      <AvisoIframe v-if="this.getUrliframe.url" :urlnotice="this.urlnotice" />
    </section>
    <button
      data-v-70a0ee36=""
      data-toggle="tooltip"
      title="Pantalla completa"
      class="btn-base border-green btn-iframe"
      v-if="this.iframe != 0"
      @click="abriIframeNuevaPagina"
    >
      <i data-v-70a0ee36="" class="fas fa-expand-arrows-alt" aria-hidden="true"></i>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FullLoader from "../components/FullLoader.vue";
import AvisoIframe from "../components/AvisoIframe.vue";
import ViewIframe from "../components/ViewIframe.vue";
import openIframen from "../helpers/openIframen";
export default {
  name: "Hipismo",
  components: {
    FullLoader,
    AvisoIframe,
    ViewIframe
  },
  data() {
    return {
      urlIframe: false,
      iframe: 0,
      IsLoadIframe: false,
      isLoad: true,
      urlnotice: null,
      monedaExtranjera: false
    };
  },
  computed: {
    ...mapGetters(["getIsMobilResolution", "getThemelight", "getVisualWidth", "getUrliframe", "getUser", "getCountry"])
  },
  methods: {
    backMenu() {
      this.urlIframe = false;
    },
    cargoIframe() {
      this.IsLoadIframe = true;
      this.isLoad = true;
    },
    async openIframe(valor) {
      this.isLoad = true;

      if (valor == 1) {
        await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=1&provider=centerhorses" });
        this.iframe = this.getUrliframe.url ? 1 : 0;
        localStorage.setItem("url_iframe", this.getUrliframe.url);
        this.urlIframe = true;
      }

      if (valor == 2) {
        await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=1&provider=nationalhorses" });
        this.iframe = this.getUrliframe.url ? 2 : 0;
        localStorage.setItem("url_iframe", this.getUrliframe.url);
        this.urlIframe = true;
      }

      if (valor == 3) {
        await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=vpfh3&provider=pragmatic" });
        this.iframe = this.getUrliframe.url ? 3 : 0;
        localStorage.setItem("url_iframe", this.getUrliframe.url);
        this.urlIframe = true;
      }

      if (valor == 4) {
        await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=vpdr7&provider=pragmatic" });
        this.iframe = this.getUrliframe.url ? 4 : 0;
        localStorage.setItem("url_iframe", this.getUrliframe.url);
        this.urlIframe = true;
      }

      if (this.iframe == 0) this.isLoad = true;
      if (this.getUrliframe.notice) {
        this.urlnotice = this.getUrliframe.notice;
        this.$bvModal.show("modal-aviso");
      }
    },
    back() {
      this.urlIframe = false;
      this.iframe = 0;
    },
    abriIframeNuevaPagina() {
      openIframen();
      this.back();
    }
  },
  created() {
    if (this.getUser.center_id == 2 || (this.getUser.center_id == 6 && this.getCountry == "VE")) {
      this.monedaExtranjera = true;
    }
  }
};
</script>
<style scoped>
.top-movil {
  height: auto;
}
.iframeStyleHipismo {
  border: 0;
  position: absolute;
  top: 180px !important;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 0px;
  height: 100%;
}
.iframeStyleHipismo-movil {
  top: 6% !important;
  border: 0;
  width: 100%;
  height: 100vh !important;
  position: absolute;
  right: 0px;
}
.degrado4 {
  border: 1px solid #333333;
  color: #fffefd;
  font-size: 22px;
  font-weight: bold;
  padding: 20px;
  text-align: center;
  background: #101010;
  padding-bottom: 10px;
  padding-top: 10px;
}
@media (max-width: 1384px) {
  .degrado4 {
    padding: 19px 0;
    border: 0;
    border-radius: 0;
  }
}

.margin-title-1 {
  margin-top: 4.3em;
}

.margin-title-2 {
  margin-top: 1.3em;
  margin-bottom: 1.3em;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-container > div {
  width: 300px;
  margin: 10px;
  text-align: center;
}

.flex-container-movil {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-container-movil > div {
  width: 10em !important;
  margin: 10px !important;
  text-align: center !important;
}

.tabla {
  margin-top: 0.9em !important;
  font-size: 1.5em;
}

.movil {
  margin-top: 0.5em !important;
  font-size: 1.5em;
}

.flex-container-tabla {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-container-tabla > div {
  width: 15em !important;
  margin: 10px !important;
  text-align: center !important;
}

.flex-container-movil {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-container-movil > div {
  width: 10em;
  margin: 10px;
  text-align: center;
}

.tema {
  background: #fff;
  color: #101010;
}
</style>
