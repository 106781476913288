export default {
  state: {
    favoritos: []
  },
  mutations: {
    setFavoritos(state, favoritos) {
      state.favoritos = favoritos;
      if (localStorage) {
        localStorage.setItem("favoritos", JSON.stringify(state.favoritos));
      }
    },
    addFavotito(state, favorito) {
      state.favoritos.push(favorito);
      if (localStorage) {
        localStorage.setItem("favoritos", JSON.stringify(state.favoritos));
      }
    }
  },
  getters: {
    getFavotitos(state) {
      return state.favoritos;
    }
  },
  actions: {
    existeFavotito({ state }, favotitoId) {
      const busquedaFavoritos = state.favoritos.flatMap(a => {
        return a.id == favotitoId ? a : [];
      });
      return busquedaFavoritos.length > 0;
    }
  }
};
