<template>
  <li
    @click="cambiarIdioma()"
    :class="{
      'nav-item': true
    }"
  >
    <a class="nav-link" href="#" style="text-decoration: none">
      <i class="fas fa-globe"> {{ lang }}</i>
    </a>
  </li>
</template>

<script>
export default {
  data() {
    return {
      lang: "ES"
    };
  },
  methods: {
    cambiarIdioma() {
      if (this.lang == "EN") {
        this.$i18n.locale = "es";
        this.lang = "ES";
      } else {
        this.$i18n.locale = "en";
        this.lang = "EN";
      }
    }
  }
};
</script>

<style scoped></style>
