<template>
  <b-modal id="modal-cuenta" title="Mi Cuenta" size="sm" hide-footer v-if="this.getUser">
    <div class="row">
      <div class="col">
        <div class="card card-widget widget-user-2 shadow-sm">
          <!-- Add the bg color to the header using any of the bg-* classes -->
          <div class="widget-user-header bg-blue-theme">
            <div class="row">
              <div class="col-8">
                <div class="row">
                  <div class="col-12" style="font-size: 16px">
                    {{ getUser.name }}
                  </div>
                  <div class="col-12" style="font-size: 16px">
                    {{ getUser.username }}
                  </div>
                </div>
              </div>
              <div class="col-4 text-center">
                <div class="col-12 pt-2" style="font-size: 1.3em; height: 2.5em">
                  <a class="rounded saldo-refresh p-1" @click="actSaldo()">
                    <i class="fas fa-sync text-dark ref" id="reload"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6 text-left">
                <div style="font-size: 16px; font-weight: 700">Saldo:</div>
              </div>
              <div class="col-6 text-right">
                <div style="font-size: 16px; font-weight: 700">{{ getSimboloMoneda }}{{ getUser.credit }}</div>
              </div>
              <div class="col-6 text-left">
                <div style="font-size: 16px; font-weight: 700">Saldo diferido:</div>
              </div>
              <div class="col-6 text-right">
                <div style="font-size: 16px; font-weight: 700">{{ getSimboloMoneda }}{{ getUser.diferido }}</div>
              </div>
              <div class="col-6 text-left">
                <div style="font-size: 16px; font-weight: 700">Credito:</div>
              </div>
              <div class="col-6 text-right">
                <div style="font-size: 16px; font-weight: 700">{{ getSimboloMoneda }}{{ getUser.credito }}</div>
              </div>
              <div class="col-6 text-left">
                <div style="font-size: 16px; font-weight: 700">Total disponible:</div>
              </div>
              <div class="col-6 text-right">
                <div style="font-size: 16px; font-weight: 700">
                  {{ getSimboloMoneda
                  }}{{
                    formatNumeric(
                      Number(this.getUser.credit) + Number(this.getUser.credito) - Number(this.getUser.diferido)
                    )
                  }}
                </div>
              </div>
              <div class="col-6 text-left">
                <div style="font-size: 16px; font-weight: 700">Bonus:</div>
              </div>
              <div class="col-6 text-right">
                <div style="font-size: 16px; font-weight: 700">{{ getSimboloMoneda }}{{ getUser.bonus }}</div>
              </div>

              <!-- <div class="col-12 text-left ml-3">
                <div style="font-size: 14px; font-weight: 700">Bonus: {{ this.getUser.bonus }}</div>
              </div>
              <div class="col-12 text-left ml-3">
                <div style="font-size: 14px; font-weight: 700">Bonus: {{ this.getUser.bonus }}</div>
              </div>
              <div class="col-12 text-left ml-3">
                <div style="font-size: 14px; font-weight: 700">Bonus: {{ this.getUser.bonus }}</div>
              </div> -->
            </div>
          </div>
          <div class="card-footer p-0">
            <div class="nav flex-column">
              <!-- <div class="nav-item border-bottom">
                <a href="#" class="nav-link no-cursor py-2 disabled">
                  Saldo
                  <span class="d-flex justify-content-between float-right badge badges-balances">
                    <div>{{ this.getUser.Coin.moneda }}</div>
                    <div class="ml-1">{{ formatNumeric(this.getUser.credit) }}</div>
                  </span>
                </a>
              </div> -->
              <div class="nav-item border-bottom">
                <!-- <a href="#" class="nav-link no-cursor py-2 disabled">
                  Saldo Diferido
                  <span class="d-flex justify-content-between float-right badge badges-balances">
                    <div>{{ this.getUser.Coin.moneda }}</div>
                    <div class="ml-1">{{ formatNumeric(this.getUser.diferido) }}</div>
                  </span>
                </a> -->
              </div>
              <div class="nav-item border-bottom">
                <!-- <a href="#" class="nav-link no-cursor py-2 disabled">
                  Bonus
                  <span class="d-flex justify-content-between float-right badge badges-balances">
                    <div>{{ this.getUser.Coin.moneda }}</div>
                    <div class="ml-1">{{ this.getUser.bonus }}</div>
                  </span>
                </a> -->
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h3 class="card-title mb-0">Modulos</h3>
          </div>
          <div class="card-body p-0">
            <ul class="nav nav-pills flex-column">
              <div class="nav-item border-bottom">
                <a href="#" class="nav-link" v-on:click="toPage('RecargasTableMovil')">
                  <i class="fas fa-cash-register"></i> Recargar
                </a>
              </div>
              <div class="nav-item border-bottom">
                <a href="#" class="nav-link" v-on:click="toPage('CuentasBancariasMovil')">
                  <i class="fas fa-wallet"></i> Mis cuentas bancarias
                </a>
              </div>
              <div class="nav-item border-bottom">
                <a href="#" class="nav-link" v-on:click="toPage('PaymentsTable')">
                  <i class="fas fa-money-bill-wave"></i> Retiro
                </a>
              </div>
              <div class="nav-item border-bottom">
                <a href="#" class="nav-link" v-on:click="toPage('BalancesTableMovil')">
                  <i class="fas fa-inbox"></i> Estado de Cuenta
                </a>
              </div>
              <div class="nav-item border-bottom">
                <a href="#" class="nav-link" v-on:click="toPage('BonusTableMovil')">
                  <i class="fas fa-hand-holding-usd mr-1"></i>Bonus
                </a>
              </div>
              <div class="nav-item border-bottom">
                <a href="#" class="nav-link" v-on:click="toPage('ReporteSemanalMovil')">
                  <i class="fas fa-th-list"></i> Reporte Semanal
                </a>
              </div>
              <div class="nav-item border-bottom">
                <a href="#" class="nav-link" v-on:click="toPage('EditPerfilMovil')">
                  <i class="fas fa-user"></i> Editar perfil
                </a>
              </div>
              <div class="nav-item border-bottom">
                <a href="#" class="nav-link" v-on:click="toPage('ChangePasswordMovil')">
                  <i class="fas fa-key"></i> Cambio de clave
                </a>
              </div>
            </ul>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { essentialsData } from "../../mixins/essentialsDataMixin.js";
import JQ from "jquery";

export default {
  name: "CuentaMovil",
  mixins: [essentialsData],
  computed: {
    ...mapGetters([
      "getUser",
      "getBalaces",
      "getBonus",
      "getCharges",
      "getIsMobilResolution",
      "getPayments",
      "getSimboloMoneda"
    ])
  },
  methods: {
    toPage: async function(page) {
      if (page == "RecargasTableMovil") {
        await this.$store.dispatch("getChargesUserAsync");
      }

      if (page == "PaymentsTable") {
        await this.$store.dispatch("getPaymentsUserAsync");
      }

      if (page == "ReporteSemanalTable") {
        await this.$store.dispatch("searcheWeklyReport");
      }

      this.$emit("changePage", page);
      this.$bvModal.hide("modal-cuenta");
    },
    actSaldo() {
      JQ("#reload").toggleClass("ref-focus");
      this.$store.dispatch("targetUserAsync");
    }
  }
};
</script>

<style scoped="scoped">
.bg-blue-theme {
  background-color: #00387f !important;
  color: #fff !important;
}
.no-cursor {
  cursor: none;
  border-radius: 0px !important;
}
.nav-link {
  color: #495057;
}
.badges-balances {
  min-width: 40%;
  font-size: 1em;
  font-weight: 300;
  padding: 5px 10px;
  color: #fff !important;
  background-color: #00387f !important;
}
.card.card-widget.widget-user-2.shadow-sm {
  border: none;
  border-radius: 0.5em;
}
.nav.nav-pills.flex-column li,
.nav.nav-pills.flex-column li > a,
.nav.flex-column li > a {
  color: #495057 !important;
}
.nav.nav-pills.flex-column li > a:hover,
.nav.flex-column li > a:hover {
  color: #fff !important;
}
.saldo-refresh {
  background-color: #fff;
}
.saldo-refresh:active {
  background: linear-gradient(-90deg, #6bff00, #0d6403) !important;
  cursor: pointer;
  transform: scale(1);
  border-radius: 7px;
  margin: 0;
  border: none;
}

.nav-link:focus {
  background-color: white !important;
  color: black !important;
}

.nav-link:active {
  background-color: white !important;
  color: black !important;
}

.nav-link:hover {
  background-color: gray !important;
  color: black !important;
}
</style>
