import { render, staticRenderFns } from "./AcordionCMS.vue?vue&type=template&id=7c380edc&scoped=true&"
import script from "./AcordionCMS.vue?vue&type=script&lang=js&"
export * from "./AcordionCMS.vue?vue&type=script&lang=js&"
import style0 from "./AcordionCMS.vue?vue&type=style&index=0&id=7c380edc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c380edc",
  null
  
)

export default component.exports