<template>
  <div>
    <div class="text-center">
      <h3 class="titulo1">{{ $t("cambiar_contraseña") }}</h3>
    </div>
    <div class="container contenedor-password">
      <form action="" name="ChangePassword" autocomplete="off">
        <div class="row pt-4">
          <div class="col text-right">
            <label for="new-pass" class="label-formulario">{{ $t("nueva_contraseña") }}</label>
          </div>
          <div class="input-group mb-3 col">
            <input
              :type="getTipo(showPass)"
              :class="{ 'form-control': true, 'is-invalid': errorNewPass }"
              v-model="newPass"
              autocomplete="off"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <!-- <i class="fas fa-certificate fa-fw"></i> -->
                <i v-show="!showPass" class="fas fa-eye" style="cursor: pointer" @click="showPass = !showPass"></i>
                <i v-show="showPass" class="fas fa-eye-slash" style="cursor: pointer" @click="showPass = !showPass"></i>
              </span>
            </div>
          </div>
          <div class="col"></div>

          <div class="w-100"></div>

          <div class="col text-right">
            <label for="confirm-pass" class="label-formulario">{{ $t("confirmar_contraseña") }}</label>
          </div>
          <div class="input-group mb-3 col">
            <input
              :type="getTipo(showConfirmPass)"
              :class="{ 'form-control': true, 'is-invalid': errorRepeatNewPass }"
              v-model="repeatNewPass"
              autocomplete="off"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <!-- <i class="fas fa-check"></i> -->

                <i
                  v-show="!showConfirmPass"
                  class="fas fa-eye"
                  style="cursor: pointer"
                  @click="showConfirmPass = !showConfirmPass"
                ></i>
                <i
                  v-show="showConfirmPass"
                  class="fas fa-eye-slash"
                  style="cursor: pointer"
                  @click="showConfirmPass = !showConfirmPass"
                ></i>
              </span>
            </div>
          </div>
          <div class="col"></div>
        </div>

        <div class="row justify-content-center">
          <div class="col-1"></div>
          <div class="col-5">
            <div class="text-center">
              <button type="button" @click="updatePassword" class="btn btn-primary boton-color-fondo">
                {{ $t("actualizar_contraseña") }}
              </button>
            </div>
          </div>

          <div class="col-1"></div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "ChangePassword",
  computed: {
    isLoged() {
      return this.getUser && this.getUser != null && this.getUser != undefined ? true : false;
    },
    ...mapGetters(["getUser", "getIsMobilResolution"])
  },
  methods: {
    getTipo(show) {
      return !show ? "password" : "text";
    },
    validacionCampos() {
      let status = true;

      if (!this.newPass || this.newPass.toString().trim() == "") {
        status = false;
        this.errorNewPass = true;
        Swal.fire("Lo sentimos!", "No puede dejar el campo Nueva Contraseña vacio", "error");
        return;
      }
      this.errorNewPass = false;

      if (!this.repeatNewPass || this.repeatNewPass.toString().trim() == "") {
        status = false;
        this.errorRepeatNewPass = true;
        Swal.fire("Lo sentimos!", "No puede dejar el campo Confirmar contraseña vacio", "error");
        return;
      }

      if (this.repeatNewPass !== this.newPass) {
        status = false;
        this.errorRepeatNewPass = true;
        Swal.fire("Lo sentimos!", "Confirmar contraseña no coincide", "error");
        return;
      }

      if (!(this.newPass.toString().length >= 8)) {
        status = false;
        this.errorNewPass = true;
        Swal.fire("Lo sentimos!", "La Nueva Contraseña debe tener un mínimo de 8 caracteres", "error");
        return;
      }

      if (!this.isUpper(this.newPass.toString())) {
        status = false;
        this.errorNewPass = true;
        Swal.fire("Lo sentimos!", "La Nueva Contraseña debe contener almenos un 1 caractere en Mayúscula", "error");
        return;
      }

      this.errorRepeatNewPass = false;

      return status;
    },
    isUpper(cadena) {
      let expresion = /[A-Z]/g;
      let array_emparejamientos = cadena.match(expresion);
      return array_emparejamientos && array_emparejamientos.length > 0;
    },
    async updatePassword() {
      if (this.validacionCampos()) {
        const respuestaActualizacion = await this.$userService.changePass(this.newPass, this.repeatNewPass);
        Swal.fire("Excelente!", respuestaActualizacion, "success");
        this.newPass = null;
        this.repeatNewPass = null;

        const Toast = this.Swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
          onOpen: toast => {
            toast.addEventListener("mouseenter", this.Swal.stopTimer);
            toast.addEventListener("mouseleave", this.Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "success",
          title: "sera redirigido para su nueva autenticacion."
        });

        setTimeout(() => {
          this.$emit("changePage", "Home");
          this.$store.dispatch("logOut");
        }, 2200);
      }
    }
  },
  data() {
    return {
      newPass: null,
      repeatNewPass: null,
      showPass: false,
      showConfirmPass: false,
      errorNewPass: false,
      errorRepeatNewPass: false
    };
  }
};
</script>
<style scoped>
.error-input {
  border: solid 2px #ff4e4e;
  background-color: rgb(228 77 73 / 17%);
}
.contenedor-password {
  height: 250px !important;
}
</style>
