/* eslint-disable no-console */
import { register } from "register-service-worker";
// const cacheName = process.env.CACHE_STATIC_NAME;
// const staticAssets = [
//   "./index.html",
//   "./js/bootstrap.min.js",
//   "./js/jquery-3.2.1.slim.min.js",
//   "./js/jquery.min.js",
//   "./js/owl.carousel.js",
//   "./js/popper.js",
//   "./js/script.js"
// ];

self.addEventListener("install", async e => {
  e.waiting.postMessage({ type: "SKIP_WAITING" });
  console.log("install*");
  return e;
});
self.addEventListener("activate", e => {
  console.log("sw activo.", e);
  e.waitUntil(self.clients.claim());
});
if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development") {
  // All Main Listeners in this function
  register(`${process.env.BASE_URL}sw.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB"
      );
    },
    install() {
      console.log("instalando **** install");
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
      //window.location.reload();
    },
    offline() {
      console.log("No internet connection found. App is running in offline mode.");
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
    waiting(w) {
      console.log(w, "*waiting");
    }
  });
}

self.addEventListener("message", event => {
  if (event.data && event.data.type === "SKIP_WAITING") {
    console.log("SKIP_WAITING - SKIP_WAITING");
  }
});

self.addEventListener("recargar", event => {
  console.log("*recargar", event);
  // window.location.reload();
});

navigator.serviceWorker.ready.then(() => {
  if (localStorage && !localStorage.getItem("sw-ini")) {
    localStorage.setItem("sw-ini", true);
    window.location.reload();
  }
});
