<template>
  <div class="container-game">
    <details
      class="card text-center"
      v-on:click="abrirDetalle(gameDetails.muestraTitulo)"
      :class="{ 'details-light text-dark': this.getThemelight, details: !this.getThemelight }"
    >
      <summary class="my-2">
        <div
          class="titleGame text-center"
          v-if="gameDetails.muestraTitulo || show"
          :class="{ 'titleGame-light': this.getThemelight }"
        >
          <p>{{ this.gameDetails.gameName }} ({{ this.gameDetails.gameDate }})</p>
        </div>
        <div
          class="row text-center"
          style="font-size: 0.9em"
          :class="{ 'bg-header-light font-weight-bold': this.getThemelight }"
        >
          <div class="col-4 border-right">
            {{ this.gameDetails.gameTime }}
          </div>
          <div class="col-4 border-right">
            <span> {{ this.gameDetails.teams[0].name }} (1)</span>
          </div>
          <div class="col-4">
            <span> {{ this.gameDetails.teams[1].name }} (2) </span>
          </div>
        </div>
      </summary>

      <table width="100%" id="oddsTable" class="mb-1">
        <tbody>
          <tr :id="this.gameDetails.id + 'FT1'" v-if="gameDetails.teams[0]['1X2FT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              1X2 FT <br />
              (A Ganar)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX1X' + this.gameDetails.id"
              @click="clickApuesta('FTX1X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX1X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["1X2FT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX2X' + this.gameDetails.id"
              @click="clickApuesta('FTX2X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX2X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["1X2FT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['HDPFT']"
          />
          <tr :id="this.gameDetails.id + 'FT2'" v-if="gameDetails.teams[0]['HDPFT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              HDP (FT) <br />
              (Runline)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX3X' + this.gameDetails.id"
              @click="clickApuesta('FTX3X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX3X'),
                'btnOdd-light font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["HDPFT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX4X' + this.gameDetails.id"
              @click="clickApuesta('FTX4X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX4X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["HDPFT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['SHDPFT']"
          />
          <tr :id="this.gameDetails.id + 'FT3'" v-if="gameDetails.teams[0]['SHDPFT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              S HDP (FT) <br />
              (Sup-Runline)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX8X' + this.gameDetails.id"
              @click="clickApuesta('FTX8X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX8X'),
                'btnOdd-light font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["SHDPFT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX9X' + this.gameDetails.id"
              @click="clickApuesta('FTX9X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX9X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["SHDPFT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['TOTALESFT']"
          />
          <tr :id="this.gameDetails.id + 'FT4'" v-if="gameDetails.teams[0]['TOTALESFT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              TOTALES FT <br />
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX5X' + this.gameDetails.id"
              @click="clickApuesta('FTX5X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX5X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["TOTALESFT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX6X' + this.gameDetails.id"
              @click="clickApuesta('FTX6X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX6X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["TOTALESFT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['1X2HT']"
          />
          <tr :id="this.gameDetails.id + 'HT1'" v-if="gameDetails.teams[0]['1X2HT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              1 y 2 HT <br />
              (A Ganar)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX1X' + this.gameDetails.id"
              @click="clickApuesta('HTX1X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX1X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["1X2HT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX2X' + this.gameDetails.id"
              @click="clickApuesta('HTX2X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX2X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["1X2HT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="this.gameDetails.teams[0]['HDPHT']"
          />
          <tr :id="this.gameDetails.id + 'HT2'" v-if="this.gameDetails.teams[0]['HDPHT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              HDP HT <br />
              (Runline)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX3X' + this.gameDetails.id"
              @click="clickApuesta('HTX3X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX3X'),
                'btnOdd-light font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["HDPHT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX4X' + this.gameDetails.id"
              @click="clickApuesta('HTX4X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX4X'),
                'btnOdd-light font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["HDPHT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['TOTALESHT']"
          />
          <tr :id="this.gameDetails.id + 'HT3'" v-if="gameDetails.teams[0]['TOTALESHT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              TOTALES HT
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX5X' + this.gameDetails.id"
              @click="clickApuesta('HTX5X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX5X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["TOTALESHT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX6X' + this.gameDetails.id"
              @click="clickApuesta('HTX6X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX6X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["TOTALESHT"] }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="my-2" v-if="gameDetails.hayFT || gameDetails.hayHT">
        <button
          class="btn boton-dark"
          v-on:click="detalle(gameDetails.id)"
          :id="'btnJugadas' + gameDetails.id"
          :class="{ 'boton-light': this.getThemelight }"
        >
          <i class="fa fa-plus"></i> Jugadas
        </button>
      </div>

      <div v-if="verDetalle" class="mb-1">
        <div class="title card mt-3" v-if="gameDetails.hayFT" :class="{ 'title-light': this.getThemelight }">
          JUEGO COMPLETO (FT)
        </div>

        <div v-if="this.gameDetails.totVisAlta">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            Totales Visitante (1) (FT)
          </div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX10X' + this.gameDetails.id"
                @click="clickApuesta('FTX10X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX10X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totVisAlta }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX11X' + this.gameDetails.id"
                @click="clickApuesta('FTX11X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX11X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totVisBaja }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="this.gameDetails.totLocAlta">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            Totales Local (2) (FT)
          </div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX12X' + this.gameDetails.id"
                @click="clickApuesta('FTX12X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX12X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totLocAlta }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX13X' + this.gameDetails.id"
                @click="clickApuesta('FTX13X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX13X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totLocBaja }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.car1InnSi">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            Carrera 1er Inning (FT)
          </div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX14X' + this.gameDetails.id"
                @click="clickApuesta('FTX14X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX14X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.car1InnSi }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX15X' + this.gameDetails.id"
                @click="clickApuesta('FTX15X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX15X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.car1InnNo }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.anotaLoc">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Anota Primero (FT)</div>

          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX16X' + this.gameDetails.id"
                @click="clickApuesta('FTX16X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX16X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.anotaVis }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX17X' + this.gameDetails.id"
                @click="clickApuesta('FTX17X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX17X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.anotaLoc }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totCheAlta">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales CHE (FT)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX18X' + this.gameDetails.id"
                @click="clickApuesta('FTX18X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX18X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totCheAlta }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX19X' + this.gameDetails.id"
                @click="clickApuesta('FTX19X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX19X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totCheBaja }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.extraSi">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Extrainning</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX119X' + this.gameDetails.id"
                @click="clickApuesta('FTX119X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX119X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.extraSi }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX120X' + this.gameDetails.id"
                @click="clickApuesta('FTX120X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX120X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.extraNo }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.gana1FT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 FT (9 Innings)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX129X' + this.gameDetails.id"
                  @click="clickApuesta('FTX129X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX129X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1FT }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX130X' + this.gameDetails.id"
                  @click="clickApuesta('FTX130X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX130X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXFT }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX131X' + this.gameDetails.id"
                  @click="clickApuesta('FTX131X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX131X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2FT }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.parFT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            Marcador Par/Impar (FT)
          </div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX60X' + this.gameDetails.id"
                @click="clickApuesta('FTX60X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX60X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.parFT }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX61X' + this.gameDetails.id"
                @click="clickApuesta('FTX61X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX61X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.imparFT }}
              </button>
            </div>
          </div>
        </div>

        <div class="title card mt-3" v-if="gameDetails.hayHT" :class="{ 'title-light': this.getThemelight }">
          1ERA MITAD (HT)
        </div>

        <div v-if="this.gameDetails.gana1HT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1er Inning 1 X 2</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'HTX116X' + this.gameDetails.id"
                  @click="clickApuesta('HTX116X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX116X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1HT }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'HTX117X' + this.gameDetails.id"
                  @click="clickApuesta('HTX117X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX117X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXHT }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'HTX118X' + this.gameDetails.id"
                  @click="clickApuesta('HTX118X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX118X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2HT }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.tot1inningAlta">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales 1er Inning</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'HTX127X' + this.gameDetails.id"
                @click="clickApuesta('HTX127X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX127X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.tot1inningAlta }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'HTX128X' + this.gameDetails.id"
                @click="clickApuesta('HTX128X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX128X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.tot1InningBaja }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </details>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { juegosDetalles } from "../../../../mixins/juegosDetallesMixin.js";
export default {
  data() {
    return {
      verDetalle: false,
      show: false
    };
  },
  mixins: [juegosDetalles],
  name: "baseballMovil",
  props: {
    gameDetails: Object
    // cartId: [],
    // oddId: String
  },
  computed: {
    ...mapGetters(["getApuestas", "getThemelight"])
  },
  methods: {
    abrirDetalle(muestraTitulo) {
      if (muestraTitulo) this.show = true;
      this.$emit("label", "details");
    },
    detalle(id) {
      $("#btnJugadas" + id).addClass("apuesta-seleccionada");
      this.verDetalle = !this.verDetalle;
    }
  }
};
</script>
<style>
.sub-title {
  font-weight: 200;
}
td {
  height: 2.8em;
}
</style>
