<template>
  <div class="container-game">
    <details
      class="details card text-center"
      v-on:click="abrirDetalle(gameDetails.muestraTitulo)"
      :class="{ 'details-light text-dark': this.getThemelight, details: !this.getThemelight }"
    >
      <summary class="my-2">
        <div
          class="titleGame text-center"
          v-if="gameDetails.muestraTitulo || show"
          :class="{ 'titleGame-light': this.getThemelight }"
        >
          <p>{{ this.gameDetails.gameName }} ({{ this.gameDetails.gameDate }})</p>
        </div>
        <div
          class="row text-center"
          style="font-size: 0.9em"
          :class="{ 'bg-header-light font-weight-bold': this.getThemelight }"
        >
          <div class="col-4 border-right">
            {{ this.gameDetails.gameTime }}
          </div>
          <div class="col-4 border-right">
            <span> {{ this.gameDetails.teams[0].name }} (1) </span>
          </div>
          <div class="col-4">
            <span> {{ this.gameDetails.teams[1].name }} (2) </span>
          </div>
        </div>
      </summary>
      <table width="100%" id="oddsTable" class="mb-1">
        <tbody>
          <tr :id="this.gameDetails.id + 'FT1'" v-if="gameDetails.teams[0]['1X2FT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              1X2 FT <br />
              (A Ganar)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX1X' + this.gameDetails.id"
              @click="clickApuesta('FTX1X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX1X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["1X2FT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX2X' + this.gameDetails.id"
              @click="clickApuesta('FTX2X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX2X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["1X2FT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['HDPFT']"
          />
          <tr :id="this.gameDetails.id + 'FT2'" v-if="gameDetails.teams[0]['HDPFT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              HDP (FT) <br />
              (Runline)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX3X' + this.gameDetails.id"
              @click="clickApuesta('FTX3X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX3X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["HDPFT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX4X' + this.gameDetails.id"
              @click="clickApuesta('FTX4X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX4X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["HDPFT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['TOTALESFT']"
          />
          <tr :id="this.gameDetails.id + 'FT4'" v-if="gameDetails.teams[0]['TOTALESFT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              TOTALES FT <br />
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX5X' + this.gameDetails.id"
              @click="clickApuesta('FTX5X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX5X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["TOTALESFT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX6X' + this.gameDetails.id"
              @click="clickApuesta('FTX6X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX6X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["TOTALESFT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['1X2HT']"
          />
          <tr :id="this.gameDetails.id + 'HT1'" v-if="gameDetails.teams[0]['1X2HT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              1 y 2 HT <br />
              (A Ganar)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX1X' + this.gameDetails.id"
              @click="clickApuesta('HTX1X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX1X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["1X2HT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX2X' + this.gameDetails.id"
              @click="clickApuesta('HTX2X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX2X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["1X2HT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="this.gameDetails.teams[0]['HDPHT']"
          />
          <tr :id="this.gameDetails.id + 'HT2'" v-if="this.gameDetails.teams[0]['HDPHT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              HDP HT <br />
              (Runline)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX3X' + this.gameDetails.id"
              @click="clickApuesta('HTX3X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX3X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["HDPHT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX4X' + this.gameDetails.id"
              @click="clickApuesta('HTX4X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX4X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["HDPHT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['TOTALESHT']"
          />
          <tr :id="this.gameDetails.id + 'HT3'" v-if="gameDetails.teams[0]['TOTALESHT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              TOTALES HT
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX5X' + this.gameDetails.id"
              @click="clickApuesta('HTX5X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX5X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["TOTALESHT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX6X' + this.gameDetails.id"
              @click="clickApuesta('HTX6X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX6X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["TOTALESHT"] }}
            </td>
          </tr>
          <hr :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }" v-if="gameDetails.teams[0]['SI']" />
          <tr :id="this.gameDetails.id + 'FT9'" v-if="gameDetails.teams[0]['SI']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              AMBOS <br />ANOTAN <br />
              FT (GG/NG)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX49X' + this.gameDetails.id"
              @click="clickApuesta('FTX49X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX49X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["SI"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX50X' + this.gameDetails.id"
              @click="clickApuesta('FTX50X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX50X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["NO"] }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="my-2" v-if="gameDetails.hayFT">
        <button
          class="btn boton-dark"
          v-on:click="detalle(gameDetails.id)"
          :id="'btnJugadas' + gameDetails.id"
          :class="{ 'boton-light': this.getThemelight }"
        >
          <i class="fa fa-plus"></i> Jugadas
        </button>
      </div>

      <div v-if="verDetalle" class="mb-1">
        <div class="title card mt-3" :class="{ 'title-light': this.getThemelight }">
          JUEGO COMPLETO (FT)
        </div>

        <div v-if="this.gameDetails.anotaLoc">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Anota Primero (FT)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX17X' + this.gameDetails.id"
                  @click="clickApuesta('FTX17X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX17X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.anotaLoc }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX114X' + this.gameDetails.id"
                  @click="clickApuesta('FTX114X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX114X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.anotaNin }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX16X' + this.gameDetails.id"
                  @click="clickApuesta('FTX16X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX16X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.anotaVis }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.anotaLocUlt">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Ultimo en anotar (FT)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX146X' + this.gameDetails.id"
                  @click="clickApuesta('FTX146X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX146X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.anotaLocUlt }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX147X' + this.gameDetails.id"
                  @click="clickApuesta('FTX147X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX147X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.anotaNinUlt }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX145X' + this.gameDetails.id"
                  @click="clickApuesta('FTX145X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX145X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.anotaVisUlt }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.parFT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            Marcador Par/Impar (FT)
          </div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FTX132X' + this.gameDetails.id"
                @click="clickApuesta('FTX132X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX132X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.parFT }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FTX133X' + this.gameDetails.id"
                @click="clickApuesta('FTX133X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FTX133X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.imparFT }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="this.gameDetails.dobleChance1X">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Doble Chance (FT)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX62X' + this.gameDetails.id"
                  @click="clickApuesta('FTX62X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX62X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChance1X }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX63X' + this.gameDetails.id"
                  @click="clickApuesta('FTX63X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX63X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChance12 }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX64X' + this.gameDetails.id"
                  @click="clickApuesta('FTX64X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX64X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChanceX2 }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.gana1FT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            1 X 2 (FT) (Sin OT)
          </div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX129X' + this.gameDetails.id"
                  @click="clickApuesta('FTX129X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX129X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1FT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX130X' + this.gameDetails.id"
                  @click="clickApuesta('FTX130X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX130X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXFT }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FTX131X' + this.gameDetails.id"
                  @click="clickApuesta('FTX131X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX131X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2FT }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div class="title card mt-3" v-if="gameDetails.hayFQ" :class="{ 'title-light': this.getThemelight }">
          1er Periodo (1st Prd)
        </div>

        <div v-if="this.gameDetails.gana1FQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 (1st Prd)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FQX129X' + this.gameDetails.id"
                  @click="clickApuesta('FQX129X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FQX129X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1FQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FQX130X' + this.gameDetails.id"
                  @click="clickApuesta('FQX130X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FQX130X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXFQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FQX131X' + this.gameDetails.id"
                  @click="clickApuesta('FQX131X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FQX131X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2FQ }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.spreadFQ1">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            P. Spread/HDP´(1st Prd)
          </div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FQX3X' + this.gameDetails.id"
                @click="clickApuesta('FQX3X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FQX3X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadFQ1 }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FQX4X' + this.gameDetails.id"
                @click="clickApuesta('FQX4X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FQX4X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadFQ2 }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totAltaFQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales (1st Prd)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FQX5X' + this.gameDetails.id"
                @click="clickApuesta('FQX5X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FQX5X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.totAltaFQ }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FQX6X' + this.gameDetails.id"
                @click="clickApuesta('FQX6X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FQX6X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.totBajaFQ }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.dobleChance1XFQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            Doble Chance (1st Prd)
          </div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FQX62X' + this.gameDetails.id"
                  @click="clickApuesta('FQX62X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FQX62X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChance1XFQ }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FQX63X' + this.gameDetails.id"
                  @click="clickApuesta('FQX63X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FQX63X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChance12FQ }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'FQX64X' + this.gameDetails.id"
                  @click="clickApuesta('FQX64X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FQX64X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChanceX2FQ }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div class="title card mt-3" v-if="gameDetails.haySQ" :class="{ 'title-light': this.getThemelight }">
          2do Periodo (2nd Prd)
        </div>

        <div v-if="this.gameDetails.gana1SQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 (2nd Prd)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'SQX129X' + this.gameDetails.id"
                  @click="clickApuesta('SQX129X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('SQX129X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1SQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'SQX130X' + this.gameDetails.id"
                  @click="clickApuesta('SQX130X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('SQX130X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXSQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'SQX131X' + this.gameDetails.id"
                  @click="clickApuesta('SQX131X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('SQX131X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2SQ }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.totAltaSQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales (2do Prd)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'SQX5X' + this.gameDetails.id"
                @click="clickApuesta('SQX5X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('SQX5X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.totAltaSQ }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'SQX6X' + this.gameDetails.id"
                @click="clickApuesta('SQX6X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('SQX6X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.totBajaSQ }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.spreadSQ1">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            P. Spread/HDP´(2do Prd)
          </div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'SQX3X' + this.gameDetails.id"
                @click="clickApuesta('SQX3X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('SQX3X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadSQ1 }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'SQX4X' + this.gameDetails.id"
                @click="clickApuesta('SQX4X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('SQX4X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadSQ2 }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.dobleChance1XSQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            Doble Chance (2nd Prd)
          </div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'SQX62X' + this.gameDetails.id"
                  @click="clickApuesta('SQX62X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('SQX62X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChance1XSQ }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'SQX63X' + this.gameDetails.id"
                  @click="clickApuesta('SQX63X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('SQX63X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChance12SQ }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'SQX64X' + this.gameDetails.id"
                  @click="clickApuesta('SQX64X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('SQX64X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChanceX2SQ }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div class="title card mt-3" v-if="gameDetails.hayTQ" :class="{ 'title-light': this.getThemelight }">
          3er Periodo (3rd Prd)
        </div>

        <div v-if="this.gameDetails.gana1TQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 (3rd Prd)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'TQX129X' + this.gameDetails.id"
                  @click="clickApuesta('TQX129X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('TQX129X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1TQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'TQX130X' + this.gameDetails.id"
                  @click="clickApuesta('TQX130X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('TQX130X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXTQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'TQX131X' + this.gameDetails.id"
                  @click="clickApuesta('TQX131X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('TQX131X'),
                    'btnTableOdd-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2TQ }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.spreadTQ1">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            P. Spread/HDP´(3rd Prd)
          </div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'TQX3X' + this.gameDetails.id"
                @click="clickApuesta('TQX3X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('TQX3X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadTQ1 }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'TQX4X' + this.gameDetails.id"
                @click="clickApuesta('TQX4X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('TQX4X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadTQ2 }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totAltaTQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales (3er Prd)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'TQX5X' + this.gameDetails.id"
                @click="clickApuesta('TQX5X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('TQX5X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.totAltaTQ }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'TQX6X' + this.gameDetails.id"
                @click="clickApuesta('TQX6X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('TQX6X'),
                  'btnDetailOdd-light  font-weight-bold': this.getThemelight
                }"
              >
                {{ this.gameDetails.totBajaTQ }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.dobleChance1XTQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">
            Doble Chance (3rd Prd)
          </div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'TQX62X' + this.gameDetails.id"
                  @click="clickApuesta('TQX62X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('TQX62X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChance1XTQ }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'TQX63X' + this.gameDetails.id"
                  @click="clickApuesta('TQX63X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('TQX63X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChance12TQ }}
                </button>
              </td>
              <td width="30" class="pb-2">
                <button
                  class="btn btn-logro"
                  :id="'TQX64X' + this.gameDetails.id"
                  @click="clickApuesta('TQX64X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('TQX64X'),
                    'btn-logro-light  font-weight-bold': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.dobleChanceX2TQ }}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </details>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { juegosDetalles } from "../../../../mixins/juegosDetallesMixin.js";
export default {
  name: "HockeyMovil",
  data() {
    return {
      verDetalle: false,
      show: false
    };
  },
  mixins: [juegosDetalles],
  props: {
    gameDetails: Object
    // selectOdds: []
  },
  computed: {
    ...mapGetters(["getThemelight"])
  },
  methods: {
    abrirDetalle(muestraTitulo) {
      if (muestraTitulo) this.show = true;
      this.$emit("label", "details");
    },
    detalle() {
      this.verDetalle = !this.verDetalle;
    }
  }
};
</script>
