<template>
  <div class="container-fluid">
    <iframe style="border: 0; width: 100%; height: 100%; position: absolute" :src="this.url"></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: null
    };
  },
  created() {
    this.url = localStorage.getItem("url_iframe");
  }
};
</script>

<style scoped></style>
