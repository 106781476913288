<template>
  <header style="position: fixed; top: 0; max-height: 75px; z-index: 4" id="menuMovil">
    <!-- boton de depliegue menu -->
    <div class="text-white text-left bt-menu">
      <i class="fas fa-bars bar-select" id="sidebarCollapse"></i>
    </div>

    <!-- logo -->
    <div class="container-logo mt-2">
      <img
        style="height: 80px"
        v-lazy="'/img/logo/sellatuparley.png'"
        v-on:click="toPage('Home')"
        :class="{ 'mobil-logo-custom': isMobilResolution() }"
      />
    </div>

    <!-- inicio de session -->
    <div style="position: absolute; top: 0; right: 25%; font-size: 1em; font-weight: 700" class="mt-3">
      <a v-if="!isLoged" href="#" data-toggle="modal" data-target="#loginModal" class="text-white">
        <p class="text-center">
          Iniciar sesión <br />
          Registro
        </p>
      </a>
    </div>

    <!-- Datos de usuario -->
    <div
      style="position: absolute; top: 35%; right: 46%; font-size: 1em; font-weight: 700; color: white"
      v-if="isLoged"
      v-on:click="modalCuenta()"
    >
      <i class="far fa-user fa-lg mr-2"></i>
    </div>

    <!-- Correos -->
    <div
      style="position: absolute; top: 35%; right: 34%; font-size: 1em; font-weight: 700; color: white"
      v-if="isLoged"
      v-on:click="toPage('Mensajeria')"
    >
      <span
        v-if="this.getMensajeria && this.getMensajeria.not_read"
        :class="{
          badge: true,
          'badge-success': true,
          'navbar-badge': true,
          'text-light': true
        }"
        style="position: absolute; top: -0.2em; right: -1em; z-index: 100"
        >{{ this.getMensajeria.not_read }}</span
      >
      <i class="fa fa-envelope fa-lg"></i>
      <span class="badge badge-success"></span>
    </div>

    <!-- Notificaciones -->
    <div style="position: absolute; top: 25%; right:21%; font-size: 1em; font-weight: 700;" v-if="isLoged">
      <Notificaciones />
    </div>

    <!-- switch modo dark/light -->
    <div class="icon-theme">
      <div :class="{ 'icon-theme-logged-in': isLoged, 'icon-theme-logged-out': !isLoged }">
        <i class="fas fa-sun" v-if="cambioTema"></i>
        <i class="fas fa-moon" v-else></i>
      </div>
      <b-form-checkbox
        name="check-button"
        v-model="cambioTema"
        switch
        :class="{ 'switch-theme-logged-in': isLoged, 'switch-theme-logged-out': !isLoged }"
      >
      </b-form-checkbox>
    </div>

    <!-- menu sidebar -->
    <div class="animate__animated no-show" id="wrap">
      <nav id="sidebar">
        <ul>
          <li>
            <div class="text-left mt-2" v-on:click="toPage('Home')">
              <span class="fa fa-home fa-lg my-3 mx-3"></span> Inicio
            </div>
          </li>
          <li v-if="isLoged">
            <div class="dropdown" id="subSidebarCollapse" style="background-color: #3f48cc" @click="subMenu()">
              <span class="fa fa-play fa-lg my-3 mx-3"></span> Jugar
            </div>
            <!-- submenu sidebar -->
            <nav id="sub-sidebar" class="animate__animated no-show" style="overflow-y: auto; height: auto">
              <ol>
                <li>
                  <div class="text-left ml-2" v-on:click="toPage('TaquillaMovil')">
                    <span class="fa fa-baseball-ball fa-lg my-3 ml-2 mr-2"></span>
                    Deportes
                  </div>
                </li>
                <li>
                  <div class="text-left ml-2" v-on:click="toPage('LiveBetting')">
                    <span class="fa fa-signal fa-lg my-3 mr-2"></span> Live Betting
                  </div>
                </li>
                <li>
                  <div class="text-left ml-2" v-on:click="toPage('PropsBetting')">
                    <span class="fa fa-dice fa-lg my-3"></span>
                    Props Betting
                  </div>
                </li>
                <li>
                  <div class="text-left ml-2" v-on:click="toPage('Hipismo')">
                    <span class="fa fa-horse fa-lg my-3 ml-2 mr-2"></span> Hipismo
                  </div>
                </li>
                <li>
                  <div class="text-left ml-2" v-on:click="toPage('SlotsMovil')">
                    <span class="fa fa-chart-pie fa-lg my-3 ml-2 mr-2"></span> Slots
                  </div>
                </li>
                <li>
                  <div class="text-left ml-2" v-on:click="toPage('CasinoLive')">
                    <span class="fa fa-apple-alt fa-lg my-3 ml-2 mr-2"></span> Casino Live
                  </div>
                </li>
                <li>
                  <div class="text-left ml-2" v-on:click="toPage('Poker')">
                    <span class="fa fa-gem fa-lg my-3 ml-2 mr-2"></span> Poker Online
                  </div>
                </li>
                <li>
                  <div class="text-left">
                    <div class="row" v-on:click="toPage('JuegosVirtuales')">
                      <div class="col-3">
                        <span class="fa fa-laptop fa-lg my-3 ml-3"></span>
                      </div>
                      <div class="col-8 text-left ml-1">
                        Juegos <br />
                        Virtuales
                      </div>
                    </div>
                  </div>
                </li>
                <li v-if="!contieneUSD(getUser.Coin.moneda)">
                  <div class="text-left ml-2" v-on:click="toPage('Spin500')">
                    <span class="fa fa-spinner fa-lg my-3 ml-2 mr-2"></span> Spin 500 Live
                  </div>
                </li>
                <li class="mb-3" v-if="!contieneUSD(getUser.Coin.moneda)">
                  <div class="text-left">
                    <div class="row">
                      <div class="col-3">
                        <span class="fa fa-dice fa-lg my-3 ml-3"></span>
                      </div>
                      <div class="col-8 text-left ml-1" v-on:click="toPage('SorteosTradicionales')">
                        Sorteos Tradicionales
                      </div>
                    </div>
                  </div>
                </li>
              </ol>
            </nav>
          </li>
          <li v-if="isLoged">
            <div class="text-left" v-on:click="toPage('Cuenta')" style="background-color: #3f48cc">
              <span class="fa fa-book fa-lg my-3 mx-3"></span> Cuenta
            </div>
          </li>
          <li>
            <div class="text-left" v-on:click="toPage('ResultadosMovil')">
              <span class="fa fa-list fa-lg my-3 mx-3"></span> Resultados
            </div>
          </li>
          <li>
            <div class="text-left" v-on:click="mostrarCalculadora()">
              <span class="fa fa-calculator fa-lg my-3 mx-3"></span> Calculadora
            </div>
          </li>
          <li>
            <div class="text-left" @click="verContactos()">
              <span class="fa fa-address-book fa-lg my-3 mx-3"></span> Contactos
            </div>
          </li>
          <div class="dropdown" id="subSidebarCollapse" style="background-color: #3f48cc" @click="subMenuSoporte()">
            <span class="far fa-question-circle fa-lg my-3 mx-3"></span> Soporte
          </div>

          <nav id="sub-sidebar-soporte" class="animate__animated no-show" style="overflow-y: auto; height: auto">
            <ol>
              <li>
                <div class="text-left ml-1" v-on:click="toPage('Preguntas')">
                  <div class="row my-1">
                    <div class="col-3">
                      <span class="fa fa-question fa-lg my-3 ml-3"></span>
                    </div>
                    <div class="col-8 text-left ml-1" v-on:click="toPage('SorteosTradicionales')">
                      Preguntas Frecuentes
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="text-left" v-on:click="toPage('ComoApostar')">
                  <div class="row my-1">
                    <div class="col-3">
                      <span class="fas fa-chalkboard-teacher fa-lg my-3 ml-3"></span>
                    </div>
                    <div class="col-8 text-left ml-2" v-on:click="toPage('SorteosTradicionales')">¿Como apostar?</div>
                  </div>
                </div>
              </li>
              <li>
                <div class="text-left ml-1" v-on:click="toPage('TiposApuestas')">
                  <div class="row my-1">
                    <div class="col-3">
                      <span class="fas fa-clipboard-list fa-lg my-3 ml-3"></span>
                    </div>
                    <div class="col-8 text-left" v-on:click="toPage('SorteosTradicionales')">Tipos de apuestas</div>
                  </div>
                </div>
              </li>
              <li>
                <div class="text-left ml-1" v-on:click="toPage('Terminos')">
                  <div class="row my-1">
                    <div class="col-3">
                      <span class="fas fa-atlas fa-lg my-3 ml-3"></span>
                    </div>
                    <div class="col-8 text-left" v-on:click="toPage('SorteosTradicionales')">
                      Términos y condiciones
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="text-left ml-1" v-on:click="toPage('BonusTermsConditions')">
                  <div class="row my-1">
                    <div class="col-3">
                      <span class="fas fa-book fa-lg my-3 ml-3"></span>
                    </div>
                    <div class="col-8 text-left" v-on:click="toPage('SorteosTradicionales')">
                      Bonos Términos y condiciones
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </nav>
          <li>
            <div class="text-left" v-on:click="cerrarSesion()" v-if="isLoged">
              <span class="fa fa-sign-out fa-lg my-3 mx-3"></span> Salir
            </div>
          </li>
        </ul>
      </nav>
    </div>

    <div
      :class="{
        'menu-registro': true,
        'color-fondo': this.getThemelight
      }"
    >
      <li
        :class="{
          'nav-item': true,
          'ml-4': isLoged && getPage != 'DeportesMovil',
          'w-25 text-center': !isLoged
        }"
      >
        <a class="nav-link link-jugar rounded mr-1 w-100" data-toggle="modal" data-target="#loginModal" v-if="!isLoged">
          <i class="far fa-futbol mr-1"></i> JUGAR
        </a>
        <a
          class="nav-link link-jugar rounded mr-1"
          v-on:click="toPage('TaquillaMovil')"
          v-if="isLoged && getPage != 'TaquillaMovil'"
        >
          <i class="far fa-futbol mr-1"></i> JUGAR
        </a>
      </li>
      <li
        :class="{
          'nav-item': true,
          'w-taquilla': getPage == 'TaquillaMovil'
        }"
        v-if="isLoged"
      >
        <a
          class="nav-link link-recargar rounded ml-1 pulsar-btn"
          href="#"
          v-on:click="$emit('changePage', 'RecargasTableMovil')"
        >
          <i class="fas fa-cash-register mr-1"></i>
          RECARGAR
        </a>
      </li>
      <li
        :class="{
          'nav-item': true
        }"
        v-if="isLoged"
      >
        <a
          :class="{
            'nav-link': true,
            'color-text': getThemelight
          }"
        >
          <div class="w-100">Saldo: {{ getSimboloMoneda }} {{ formatNumeric(getUser.credit) }}</div>
          <div class="w-100">Bonus: {{ getSimboloMoneda }} {{ formatNumeric(getUser.bonus) }}</div>
        </a>
      </li>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { essentialsData } from "../mixins/essentialsDataMixin.js";
import Notificaciones from "../pages/Notificaciones.vue";
export default {
  name: "MenuMovilTest",
  mixins: [essentialsData],
  data() {
    return {
      cambioTema: true
    };
  },
  components: {
    Notificaciones
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getTheme",
      "getVisualWidth",
      "getPage",
      "getMensajeria",
      "getThemelight",
      "getCenterBanks",
      "getCenterAccounts",
      "getSimboloMoneda"
    ]),
    isLoged() {
      return this.getUser && this.getUser != null && this.getUser != undefined ? true : false;
    }
  },
  methods: {
    isMobilResolution() {
      return this.getVisualWidth <= 1100;
    },
    taquillaWeb() {
      this.$emit("changePage", "Juegos");
    },
    toPage: async function(page) {
      this.hideMenu();

      // --------------------------------------------------
      // Validacion necesaria para selectores de Recargas |
      // --------------------------------------------------
      if (page == "Cuenta" && (!this.getCenterBanks || !this.getCenterAccounts)) {
        await this.$store.dispatch("getCenterAccountsBanksAsync");
        console.log("***", page);
      }

      if (page == "Mensajeria") {
        await this.$store.dispatch("getMessengerUserAsync");
      }

      // if (page == "Cuenta") {
      //   await this.$store.dispatch("enableSecurity");
      //   // await this.$store.dispatch("getLeaguesAsync");
      //   // await this.$store.dispatch("getVideoAsync");
      //   if (this.$store.state.user && this.$store.state.token && this.$store.state.token.access_token) {
      //     // await this.$store.dispatch("getGamesAsync");
      //   }
      //   await this.$store.dispatch("getCenterAccountsBanksAsync");
      // }

      if (page == "TaquillaMovil") {
        await this.$store.dispatch("checkLogin");
        await this.$store.dispatch("checkSession");
        await this.$store.dispatch("enableSecurity");
        await this.$store.dispatch("getLeaguesAsync");
        await this.$store.dispatch("getVideoAsync");
        if (this.$store.state.user && this.$store.state.token && this.$store.state.token.access_token) {
          await this.$store.dispatch("getGamesAsync");
        }

        if ([5, 7, 8].includes(this.getUser.center_id)) {
          page = "LiveBetting";
        }
      }

      this.$emit("changePage", page);
      // this.$emit("changePage", "DeportesMovil");
      this.$store.dispatch("togleMenuMobil");
    },
    cerrarSesion() {
      this.hideMenu();
      this.$store.dispatch("logOut");
    },
    subMenu() {
      if ($("#sub-sidebar-soporte").hasClass("animate__backInLeft")) {
        $("#sub-sidebar-soporte").removeClass("animate__backInLeft");
        $("#sub-sidebar-soporte").addClass("animate__backOutLeft");
        $("#sub-sidebar-soporte").removeClass("active");
      }

      this.toggleMenu("sub-sidebar", "sub-sidebar");
    },
    subMenuSoporte() {
      if ($("#sub-sidebar").hasClass("animate__backInLeft")) {
        $("#sub-sidebar").removeClass("animate__backInLeft");
        $("#sub-sidebar").addClass("animate__backOutLeft");
        $("#sub-sidebar").removeClass("active");
      }

      this.toggleMenu("sub-sidebar-soporte", "sub-sidebar-soporte");
    },
    mostrarCalculadora() {
      this.$store.dispatch("checkSession");
      this.$bvModal.show("modal-calculadora");
    },
    modalCuenta() {
      this.$store.dispatch("checkSession");
      this.$bvModal.show("modal-cuenta");
    },
    verContactos() {
      console.log("test");
      this.$bvModal.show("modal-contacto");
    },
    menuActivo() {
      let me = this;

      $("#sidebar").on("click", function(event) {
        event.stopPropagation();
      });

      $(document).on("click", function() {
        me.hideMenu();
      });

      $("#sidebarCollapse").on("click", function(event) {
        event.stopPropagation();
        me.toggleMenu("sidebar", "wrap");
      });
    },
    toggleMenu(trigger, tag) {
      $("#" + tag).removeClass("no-show");
      $("#" + trigger).toggleClass("active");

      if ($("#" + trigger).hasClass("active")) {
        $("#" + tag).removeClass("animate__backOutLeft");
        $("#" + tag).addClass("animate__backInLeft");
      } else {
        $("#" + tag).removeClass("animate__backInLeft");
        $("#" + tag).addClass("animate__backOutLeft");
      }
    },
    hideMenu() {
      $("#wrap").removeClass("animate__backInLeft");
      $("#wrap").addClass("animate__backOutLeft");

      $("#sub-sidebar").removeClass("animate__backInLeft");
      $("#sub-sidebar").addClass("animate__backOutLeft");

      $("#sub-sidebar-soporte").removeClass("animate__backInLeft");
      $("#sub-sidebar-soporte").addClass("animate__backOutLeft");

      $("#sidebar").removeClass("active");
      $("#sub-sidebar").removeClass("active");
      $("#sub-sidebar-soporte").removeClass("active");
    }
  },
  mounted() {
    this.menuActivo();

    if (this.getThemelight) {
      document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
      this.cambioTema = true;
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
      this.cambioTema = false;
    }

    if (this.isLoged && this.getMensajeria && this.getMensajeria.data) {
      this.getMensajeria.data.forEach(element => {
        if (element.leido == 0) {
          this.noLeido = true;
        }
      });
    }

    /* detectando click fuera */
    document.addEventListener("click", e => {
      if (
        this.menuActivo == true &&
        e.screenX >= window.screen.width / 2 &&
        e.screenY >= 255 &&
        e.target.className != "fas fa-bars" &&
        e.target.className != "bt-menu" &&
        e.target.className != "dropdown"
      ) {
        this.$store.dispatch("togleMenuMobil");
        this.menuActivo = false;
      }
    });
  },
  watch: {
    cambioTema(v) {
      this.$store.commit("setThemelight", v);
      if (this.getThemelight) {
        document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
        this.cambioTema = true;
      } else {
        document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
        this.cambioTema = false;
      }
    }
  }
};
</script>

<style scoped>
.bar-select:active {
  filter: invert(47%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(150%) contrast(119%);
}
.w-taquilla {
  margin-left: 50px;
  margin-right: 20px;
}
.no-show {
  display: none !important;
}
.mobil-logo-custom {
  width: auto !important;
  margin: 0px auto;
  display: block;
}
.container-logo {
  width: 30%;
  height: 20px !important;
  position: absolute;
  z-index: 100;
  top: -0.7em;
  margin-left: 15%;
}
.icon-theme {
  display: block;
  color: #fff;
  font-size: 1.6em;
}
.switch-theme-logged-in {
  position: absolute;
  top: 45%;
  right: 2%;
  font-size: 0.5em;
}
.icon-theme-logged-in {
  position: absolute;
  top: 20%;
  right: 7%;
  font-size: 0.5em;
}
.switch-theme-logged-out {
  position: absolute;
  top: 10%;
  right: 7%;
  font-size: 0.5em;
}
.icon-theme-logged-out {
  position: absolute;
  top: 12%;
  right: 4%;
  font-size: 0.5em;
}
.mail-sin-leer {
  background: red !important;
}
.bt-menu {
  display: block;
  padding: 20px;
  background: #00387f;
  color: #fff;
  height: 3em;
  text-decoration: none;
  font-weight: bold;
  font-size: 25px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#sidebar {
  position: absolute;
  width: 55%;
  top: 11% !important;
  left: 0;
  height: 100vh;
  display: block;
  z-index: 600 !important;
  color: #fff;
}
#sidebar ul li div:active {
  background: linear-gradient(-90deg, #6bff00, #0d6403) !important;
}
#sidebar div:active {
  background: linear-gradient(-90deg, #6bff00, #0d6403) !important;
}
#sub-sidebar {
  position: fixed;
  width: 170px;
  top: 20%;
  left: 40%;
  height: 100vh;
  color: #fff;
  background-color: #3f48cc;
}

#sub-sidebar-soporte {
  position: absolute;
  width: 160px;
  top: 30% !important;
  left: 75%;
  height: 50vh;
  color: #fff;
  background-color: #3f48cc;
}
.menu-registro {
  height: 45px;
  background: black;
  display: flex;
  align-items: center;
}
.color-fondo {
  background: #fff;
}
.color-text {
  color: black !important;
}
.menu-registro li {
  display: inline-block;
  align-items: center;
}

@media screen and (max-width: 340px) {
  .menu-registro li a {
    font-size: 10px !important;
    color: white;
  }
}
@media screen and (min-width: 341px) {
  .menu-registro li a {
    font-size: 12px !important;
    color: white;
  }
}

.link-jugar {
  font-size: 1em !important;
  padding: 4px 8px;
  background: linear-gradient(90deg, #f09819, #ff512f);
  color: #fff !important;
}
.link-jugar:active {
  background: #052460;
}
.link-recargar {
  font-size: 0.8em !important;
  padding: 4px 8px;
  background-image: linear-gradient(-90deg, #728cfa, #2d4dd7);
  color: #ffffff !important;
}
.link-recargar:active {
  background: #052460;
}
.link-soporte {
  font-size: 0.8em !important;
  background-color: transparent;
  border: none;
  padding-bottom: 9px;
  display: inline-block;
  align-items: center;
}

.link-pc {
  font-size: 0.8em !important;
  padding: 4px;
  background: linear-gradient(90deg, #f09819, #ff512f);
  color: #fff !important;
}

.popover {
  float: right;
  margin-right: -2.5em;
  background-color: #00387f !important;
  margin-left: 10px !important;
  min-width: 20% !important;
  font-size: 1em;
  font-weight: 500;
}
</style>
