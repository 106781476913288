<template>
  <div
    style="
      background-image: url('../img/JuegosVirtuales/virtuales_background.jpg');
      background-repeat: no-repeat;
      background-size: cover;
    "
  >
    <section
      :class="{
        'animate__animated animate__backInUp juegos-virtuales-container': true,
        'section-movil': this.getIsMobilResolution,
        'iframe-menu-1': this.menu == 1,
        'iframe-menu-2': this.menu == 2,
        'iframe-menu-3': this.menu == 3
        // 'light-theme bg-light': this.getThemelight
      }"
    >
      <div
        class="container-aviso"
        v-show="isVisibleAviso && !this.avisoClosed && this.isLoad && this.urlAviso && this.urlAviso != false"
      >
        <label class="lbl-closed" v-on:click="closeAviso">x</label>
        <img width="300" height="300" v-lazy="this.urlAviso" v-if="this.getIsMobilResolution" />
        <img v-lazy="this.urlAviso" v-else />
      </div>

      <div
        :class="{
          degrado4: true,
          tabla: this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          movil: this.getVisualWidth < 500
        }"
        style="cursor: pointer"
        v-if="!validateIframe && menu == 0"
        @click="backMenu()"
      >
        {{ $t("juegos_virtuales") }}
      </div>
      <div
        :class="{
          'degrado4 row': true,
          tabla: this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          movil: this.getVisualWidth < 500
        }"
        v-if="validateIframe || menu != 0"
      >
        <div class="col-4" v-if="!this.getIsMobilResolution"></div>
        <div class="col-4" v-if="!this.getIsMobilResolution">
          <label class="title-selection pt-2 pb-2">{{ this.getTitleSelection() }}</label>
        </div>
        <div class="col-4">
          <button
            class="btn-red btn-regresar"
            :class="{ 'float-right': !this.getIsMobilResolution, 'float-left': this.getIsMobilResolution }"
            @click="back()"
          >
            <span><i class="fa fa-angle-double-left"></i> REGRESAR</span>
          </button>
        </div>
      </div>

      <div
        :class="{
          'flex-container': this.getVisualWidth > 1280,
          'flex-container-tabla': this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          'flex-container-movil': this.getVisualWidth < 500
        }"
        v-if="menu == 0"
      >
        <div>
          <!-- src="https://sellatuparley.com/img/casino/juegos_virtuales_pragmatic.png" -->
          <img
            v-lazy="'https://sellatuparley.com/img/casino/juegos_virtuales_pragmatic.png'"
            class="img-fluid boder-img"
            alt=""
            @click="changeMenu(1)"
            style="cursor: pointer"
          />
        </div>
        <div>
          <!-- src="https://sellatuparley.com/img/casino/juegos_virtuales_del_caribe.png" -->
          <img
            v-lazy="'https://sellatuparley.com/img/casino/juegos_virtuales_del_caribe.png'"
            class="img-fluid boder-img"
            alt=""
            @click="changeMenu(2)"
            style="cursor: pointer"
          />
        </div>

        <div>
          <!--           src="/img/JuegosVirtuales/betradar.png" -->
          <img
            v-lazy="'/img/JuegosVirtuales/betradar.png'"
            class="img-fluid boder-img"
            alt=""
            @click="changeMenu(3)"
            style="cursor: pointer"
          />
        </div>
      </div>

      <div
        :class="{
          'flex-container': this.getVisualWidth > 1280,
          'flex-container-tabla': this.getVisualWidth < 1280 && this.getVisualWidth > 500,
          'flex-container-movil': this.getVisualWidth < 500
        }"
        v-if="!validateIframe && menu == 1"
      >
        <div v-for="jv in this.getJuegosVirtualesPragmatic.games" v-bind:key="jv.id">
          <!-- :src="`https://cdn.sellatuparley.com/virtuals/${jv.manufacturer}/${jv.id}.png`" -->
          <img
            v-lazy="`https://cdn.sellatuparley.com/virtuals/${jv.manufacturer}/${jv.id}.png`"
            class="img-fluid boder-img"
            alt=""
            @click="openIframe(1, jv.id)"
            style="cursor: pointer"
          />
        </div>
      </div>

      <div class="row" v-if="validateIframe">
        <div class="row">
          <div class="col"></div>
        </div>
        <div class="row">
          <!-- CARRERAS DE CABALLO -->
          <iframe
            v-if="iframe == 1 && !this.getIsMobilResolution"
            style="border: 0; width: 100%; position: absolute; right: 0px; height: 80vh"
            :src="urlIframe"
            :class="{
              'top-web': true,
              'class-iframe-1': true
            }"
            @load="estaCargado"
          >
          </iframe>

          <iframe
            v-if="iframe == 1 && this.getIsMobilResolution"
            style="right: 0; border: 0; width: 100%; position: absolute; height: 72vh"
            :src="urlIframe"
            allowfullscreen=""
            webkitallowfullscreen=""
            mozallowfullscreen=""
          ></iframe>

          <!-- TANDA DE PENALTIS -->
          <iframe
            v-if="iframe == 2"
            style="border: 0; width: 100%; height: 100%; position: absolute; right: 0px; height: 190vh"
            v-bind:src="urlIframe"
            allowfullscreen=""
            webkitallowfullscreen=""
            mozallowfullscreen=""
            :class="{
              'top-movil': this.getIsMobilResolution,
              'top-web': !this.getIsMobilResolution,
              'class-iframe-1': true
            }"
            @load="estaCargado"
          >
          </iframe>

          <!-- LIGA FANTÁSTICA -->
          <iframe
            v-if="iframe == 3"
            style="border: 0; width: 100%; position: absolute; right: 0px; height: 80vh"
            v-bind:src="urlIframe"
            allowfullscreen=""
            webkitallowfullscreen=""
            mozallowfullscreen=""
            @load="estaCargado"
            :class="{
              'top-movil': this.getIsMobilResolution,
              'top-web': !this.getIsMobilResolution
            }"
          >
          </iframe>

          <!-- CARRERAS DE GALGOS -->
          <iframe
            v-if="iframe == 4"
            style="border: 0; width: 100%; height: 100%; position: absolute; right: 0px"
            v-bind:src="urlIframe"
            allowfullscreen=""
            webkitallowfullscreen=""
            mozallowfullscreen=""
            @load="estaCargado"
            :class="{
              'top-movil': this.getIsMobilResolution,
              'top-web': !this.getIsMobilResolution,
              'class-iframe-1': true
            }"
          >
          </iframe>

          <!-- FORCE1 -->
          <iframe
            v-if="iframe == 5"
            style="border: 0; width: 100%; height: 100%; position: absolute; right: 0px"
            v-bind:src="urlIframe"
            allowfullscreen=""
            webkitallowfullscreen=""
            mozallowfullscreen=""
            @load="estaCargado"
            :class="{
              'top-movil': this.getIsMobilResolution,
              'top-web': !this.getIsMobilResolution
            }"
          >
          </iframe>
        </div>
      </div>

      <div class="text-center" v-if="menu == 2">
        <iframe
          :src="getUrliframe.url"
          allowfullscreen=""
          webkitallowfullscreen=""
          mozallowfullscreen=""
          @load="estaCargado"
          style="border: 0; width: 100%; position: absolute; right: 0px"
          :class="{
            'top-movil': this.getIsMobilResolution,
            'top-web': !this.getIsMobilResolution
          }"
        >
        </iframe>
      </div>

      <div class="flex-container" v-if="menu == 3 && !this.getIsMobilResolution">
        <div v-for="jv in this.getJuegosVirtualesBetradar.games" v-bind:key="jv.id">
          <img
            v-lazy="`https://cdn.sellatuparley.com/virtuals/${jv.provider}/${jv.id}.png`"
            class="img-fluid boder-img"
            alt=""
            @click="openIframe(3, jv.id)"
            style="cursor: pointer"
          />
        </div>
      </div>

      <div
        class="flex-container top-movil"
        v-if="menu == 3 && this.getIsMobilResolution && !validateIframe"
        style="overflow: scroll"
      >
        <div class="row">
          <div class="col-6 my-2" v-for="jv in this.getJuegosVirtualesBetradar.games" v-bind:key="jv.id">
            <img
              v-lazy="`https://cdn.sellatuparley.com/virtuals/${jv.provider}/${jv.id}.png`"
              class="img-fluid boder-img"
              alt=""
              @click="openIframe(3, jv.id)"
              style="cursor: pointer"
            />
          </div>
        </div>
      </div>

      <div
        v-if="this.validar"
        class="text-center bg-light"
        :class="{ 'w-25': !this.getIsMobilResolution }"
        style="margin-top: 10% !important"
      >
        <div>
          <img v-lazy="'/img/mantenimiento/mantenimiento.png'" width="350" height="250" />
          <div class="form-inline text-danger"></div>
        </div>
      </div>
      <AvisoIframe v-if="!this.validar" :urlnotice="this.urlnotice" />
      <button
        data-v-70a0ee36=""
        data-toggle="tooltip"
        title="Pantalla completa"
        class="btn-base border-green btn-iframe"
        v-if="this.iframe != 0"
        @click="abriIframeNuevaPagina"
      >
        <i data-v-70a0ee36="" class="fas fa-expand-arrows-alt" aria-hidden="true"></i>
      </button>
    </section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AvisoIframe from "../components/AvisoIframe.vue";
import { chatMixin } from "../mixins/chatMixin.js";
import openIframen from "../helpers/openIframen";

export default {
  name: "JuegosVirtuales",
  mixins: [chatMixin],
  components: {
    AvisoIframe
  },
  data() {
    return {
      validateIframe: false,
      avisoClosed: false,
      urlIframe: null,
      iframe: 0,
      menu: 0,
      images: [],
      isLoad: false,
      urlAviso: null,
      validar: false,
      urlnotice: null
    };
  },
  computed: {
    ...mapGetters([
      "getIsMobilResolution",
      "getToken",
      "getThemelight",
      "getVisualWidth",
      "getJuegosVirtualesPragmatic",
      "getJuegosVirtualesBetradar",
      "getUrliframe"
    ])
  },
  methods: {
    abriIframeNuevaPagina() {
      openIframen();
    },
    closeAviso() {
      this.avisoClosed = true;
    },
    isVisibleAviso() {
      return this.avisoClosed == false ? true : false;
    },
    estaCargado(evt) {
      this.avisoClosed = false;
      this.isLoad = true;
      console.log("estaCargado", evt);
    },
    async openIframe(valor, game) {
      this.validateIframe = true;
      this.$store.commit("showLoader");
      await this.getUrl(game);

      if (valor == 1) {
        this.iframe = 1;
      }
      if (valor == 2) {
        this.iframe = 2;
      }
      if (valor == 3) {
        this.iframe = 3;
      }
      if (valor == 4) {
        this.iframe = 4;
      }
      if (valor == 5) {
        this.iframe = 5;
      }

      this.$store.commit("hideLoader");
    },
    back() {
      this.validateIframe = false;
      this.iframe = 0;
      this.validar = false;
      this.urlIframe = null;
      this.isLoad = false;
      this.menu = 0;
    },
    backMenu() {
      this.menu = 0;
      this.isLoad = false;
    },
    async getUrl(game) {
      this.avisoClosed = false;
      this.urlAviso = null;
      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${this.getToken.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      let provider = "pragmatics";

      let urlRequest = process.env.VUE_APP_URL_API + `api/client?${provider}=${game}`;

      if (this.menu == 3) {
        //urlRequest = process.env.VUE_APP_URL_API + `api/client?game_url=${game}&provider=pragmatic`;
        provider = "slotegrator"; //'betradar' 'slotegrator';
        urlRequest = process.env.VUE_APP_URL_API + `api/client?game_url=${game}&provider=${provider}`;
      }

      if (this.menu == 1) {
        provider = "pragmatic";
        urlRequest = process.env.VUE_APP_URL_API + `api/client?game_url=${game}&provider=${provider}`;
      }

      console.log(this.menu, urlRequest, "***");

      return await fetch(urlRequest, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(provider, result, "**********");
          if (provider == "pragmatic" && result.game_url && result.game_url.url) {
            this.urlIframe = result.game_url.url;
          }

          if (provider == "pragmatic" && result.game_url && result.game_url.notice && result.game_url.notice != false) {
            this.urlAviso = result.game_url.notice;
          }

          if (provider == "betradar") {
            this.urlIframe = result.betradar;
          }

          if (
            provider == "slotegrator" &&
            result.game_url &&
            result.game_url.notice &&
            result.game_url.notice != false
          ) {
            this.urlAviso = result.game_url.notice;
          }

          if (provider == "slotegrator" && result.game_url && result.game_url.url) {
            this.urlIframe = result.game_url.url;
          }
          localStorage.setItem("url_iframe", this.urlIframe);
          return this.urlIframe;
        })
        .catch(async error => {
          const errorToken = error.toString().includes("Unexpected token < in JSON at position 0") ? true : false;

          if (errorToken) {
            localStorage.removeItem("page");
            localStorage.removeItem("lastPage");
            await this.$store.dispatch("logOut");
            await this.$store.dispatch("clearAndRedirect");
          }

          console.log("error", error);
        });
    },
    getTitleSelection() {
      let title = "";
      if (this.menu == 1) {
        title = "PRAGMATIC PLAY";
      }
      if (this.menu == 2) {
        title = "VIRTUALES DEL CARIBE";
      }
      if (this.menu == 3) {
        title = "BETRADAR";
      }
      return title;
    },
    async changeMenu(m) {
      if (m == 1) {
        await this.$store.dispatch("searchJuegosVirtualesPragmatic");
        this.validar = this.getJuegosVirtualesPragmatic ? false : true;
      }
      if (m == 2) {
        await this.$store.dispatch("urlProveedorIframeAsync", { url: "api/client?game_url=1&provider=kikiriki" });
        this.validar = this.getUrliframe.url ? false : true;
        if (this.getUrliframe.notice) {
          this.urlnotice = this.getUrliframe.notice;
          this.$bvModal.show("modal-aviso");
        }
      }
      if (m == 3) {
        await this.$store.dispatch("searchJuegosVirtualesBetradar");
        this.validar = this.getJuegosVirtualesBetradar ? false : true;

        console.log("bet radar: ", this.getJuegosVirtualesBetradar);
      }
      this.menu = m;
    }
  },
  async created() {
    await this.$store.dispatch("enableSecurity");
    this.hideChat();
  }
};
</script>
<style scoped>
.btn-iframe {
  top: 5% !important;
}
.container-aviso {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: fixed;
  top: 0px;
  left: 0;
  transform: translate(calc(50vw - 50%), calc(50vh - 50%));
}
.lbl-closed {
  position: absolute;
  color: #fff;
  top: 0;
  right: 0.5em;
  font-weight: 300 !important;
  font-size: x-large;
  cursor: pointer;
}
</style>
<style>
/* Global para iframe internos de betrada */
iframe body,
iframe > html > body#body-element,
iframe html > body#body-element,
#body-element {
  overflow: hidden !important;
}
</style>
<style scoped>
.iframe-menu-1 {
  height: 100vh;
}
.iframe-menu-3 {
  height: 100vh;
}
.iframe-menu-2 {
  height: 100vh;
  padding: 0;
  margin: 0;
}
.title-selection {
  margin: 0px;
  padding: 0px;
  height: 50px;
}
iframe {
  background-color: #232323;
  border: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 190vh;
  /*overflow-x: hidden;*/
  overflow: hidden !important;
}

.section-movil {
  margin-top: 30%;
  height: 85vh;
  overflow: hidden !important;
}
.top-movil {
  right: 0;
  border: none;
  width: 100%;
  height: 72vh;
}
.top-web {
  top: 160px !important;
  height: 80vh;
}
/* 
  border: 1px solid #333333;
  border-radius: 5px; 
  width: 100%;
  */
.degrado4 {
  border: none;
  color: #fffefd;
  font-size: 22px;
  font-weight: bold;
  padding: 20px;
  text-align: center;
  background: #101010;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 4.3em;
}
.degrado4-light {
  background: #052460;
}

/*width: 350px;*/

.subtitulo_ {
  color: #fff;
  font-size: 16px;
  font-family: Poppins, sans-serif !important;
}
.subtitulo_light {
  color: #202020;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
/*width: 300px;*/
.flex-container > div {
  margin: 10px;
  text-align: center;
}

.flex-container-movil {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-container-movil > div {
  width: 10em !important;
  margin: 10px !important;
  text-align: center !important;
}

.tabla {
  margin-top: 4.2em !important;
  font-size: 1.5em;
}

.movil {
  margin-top: 0.4em !important;
  font-size: 1.2em;
}
.flex-container-tabla {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.flex-container-tabla > div {
  width: 15em !important;
  margin: 10px !important;
  text-align: center !important;
}
</style>
