<template>
  <div class="container-game">
    <details
      class="details card text-center"
      v-on:click="abrirDetalle(gameDetails.muestraTitulo)"
      :class="{ 'details-light text-dark': this.getThemelight, details: !this.getThemelight }"
    >
      <summary class="my-2" style="color: #ffffff">
        <div
          class="titleGame text-center"
          v-if="gameDetails.muestraTitulo || show"
          :class="{ 'titleGame-light': this.getThemelight }"
        >
          <p>{{ this.gameDetails.gameName }} ({{ this.gameDetails.gameDate }})</p>
        </div>
        <div
          class="row text-center"
          style="font-size: 0.9em"
          :class="{ 'bg-header-light font-weight-bold': this.getThemelight }"
        >
          <div class="col-4 border-right">
            {{ this.gameDetails.gameTime }}
          </div>
          <div class="col-4 border-right">
            <span> {{ this.gameDetails.teams[0].name }} (1) </span>
          </div>
          <div class="col-4">
            <span> {{ this.gameDetails.teams[1].name }} (2) </span>
          </div>
        </div>
      </summary>
      <table width="100%" id="oddsTable" class="mb-1">
        <tbody>
          <tr :id="this.gameDetails.id + 'FT1'" v-if="gameDetails.teams[0]['1X2FT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              1X2 FT <br />
              (A Ganar)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX1X' + this.gameDetails.id"
              @click="clickApuesta('FTX1X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX1X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["1X2FT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX2X' + this.gameDetails.id"
              @click="clickApuesta('FTX2X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX2X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["1X2FT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['HDPFT']"
          />
          <tr :id="this.gameDetails.id + 'FT2'" v-if="gameDetails.teams[0]['HDPFT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              HDP (FT) <br />
              (Runline)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX3X' + this.gameDetails.id"
              @click="clickApuesta('FTX3X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX3X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["HDPFT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX4X' + this.gameDetails.id"
              @click="clickApuesta('FTX4X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX4X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["HDPFT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['TOTALESFT']"
          />
          <tr :id="this.gameDetails.id + 'FT4'" v-if="gameDetails.teams[0]['TOTALESFT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              TOTALES FT <br />
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX5X' + this.gameDetails.id"
              @click="clickApuesta('FTX5X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX5X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["TOTALESFT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX6X' + this.gameDetails.id"
              @click="clickApuesta('FTX6X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX6X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["TOTALESFT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['1X2HT']"
          />
          <tr :id="this.gameDetails.id + 'HT1'" v-if="gameDetails.teams[0]['1X2HT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              1 y 2 HT <br />
              (A Ganar)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX1X' + this.gameDetails.id"
              @click="clickApuesta('HTX1X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX1X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["1X2HT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX2X' + this.gameDetails.id"
              @click="clickApuesta('HTX2X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX2X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["1X2HT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="this.gameDetails.teams[0]['HDPHT']"
          />
          <tr :id="this.gameDetails.id + 'HT2'" v-if="this.gameDetails.teams[0]['HDPHT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              HDP HT <br />
              (Runline)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX3X' + this.gameDetails.id"
              @click="clickApuesta('HTX3X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX3X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["HDPHT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX4X' + this.gameDetails.id"
              @click="clickApuesta('HTX4X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX4X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["HDPHT"] }}
            </td>
          </tr>
          <hr
            :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }"
            v-if="gameDetails.teams[0]['TOTALESHT']"
          />
          <tr :id="this.gameDetails.id + 'HT3'" v-if="gameDetails.teams[0]['TOTALESHT']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              TOTALES HT
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX5X' + this.gameDetails.id"
              @click="clickApuesta('HTX5X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX5X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["TOTALESHT"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'HTX6X' + this.gameDetails.id"
              @click="clickApuesta('HTX6X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('HTX6X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["TOTALESHT"] }}
            </td>
          </tr>
          <hr :class="{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }" v-if="gameDetails.teams[0]['SI']" />
          <tr :id="this.gameDetails.id + 'FT9'" v-if="gameDetails.teams[0]['SI']">
            <td
              width="30%"
              class="refOdd border-right"
              :class="{ 'refOdd-light font-weight-bold': this.getThemelight }"
            >
              AMBOS <br />ANOTAN <br />
              FT (GG/NG)
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX49X' + this.gameDetails.id"
              @click="clickApuesta('FTX49X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX49X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[0]["SI"] }}
            </td>
            <td
              width="30%"
              class="btnOdd"
              :id="'FTX50X' + this.gameDetails.id"
              @click="clickApuesta('FTX50X')"
              :class="{
                'apuesta-seleccionada': this.existeApuesta('FTX50X'),
                'btnOdd-light  font-weight-bold': this.getThemelight
              }"
            >
              {{ this.gameDetails.teams[1]["NO"] }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="my-2" v-if="gameDetails.hayFT || gameDetails.hayHT">
        <button
          class="btn boton-dark"
          v-on:click="detalle(gameDetails.id)"
          :id="'btnJugadas' + gameDetails.id"
          :class="{ 'boton-light': this.getThemelight }"
        >
          <i class="fa fa-plus"></i> Jugadas
        </button>
      </div>

      <div v-if="verDetalle" class="mb-1">
        <div class="title card mt-3" :class="{ 'title-light': this.getThemelight }">JUEGO COMPLETO (FT)</div>

        <div v-if="this.gameDetails.parFT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Marcador Par/Impar (FT)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX132X' + this.gameDetails.id"
                @click="clickApuesta('FTX132X')"
                :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX132X'), 'btnOdd-light': this.getThemelight }"
              >
                {{ this.gameDetails.parFT }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnOdd"
                :id="'FTX133X' + this.gameDetails.id"
                @click="clickApuesta('FTX133X')"
                :class="{ 'apuesta-seleccionada': this.existeApuesta('FTX133X'), 'btnOdd-light': this.getThemelight }"
              >
                {{ this.gameDetails.imparFT }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.gana1FT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 FT (Sin OT)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX129X' + this.gameDetails.id"
                  @click="clickApuesta('FTX129X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX129X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1FT }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX130X' + this.gameDetails.id"
                  @click="clickApuesta('FTX130X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX130X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXFT }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FTX131X' + this.gameDetails.id"
                  @click="clickApuesta('FTX131X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FTX131X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2FT }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div class="title card mt-3" v-if="gameDetails.hayHT" :class="{ 'title-light': this.getThemelight }">
          1ERA MITAD (HT)
        </div>

        <div v-if="this.gameDetails.parHT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Marcador Par/Impar (HT)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'HTX60X' + this.gameDetails.id"
                @click="clickApuesta('HTX60X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX60X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.parHT }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'HTX61X' + this.gameDetails.id"
                @click="clickApuesta('HTX61X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('HTX61X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.imparHT }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.gana1HT">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 (HT)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'HTX129X' + this.gameDetails.id"
                  @click="clickApuesta('HTX129X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX129X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1HT }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'HTX130X' + this.gameDetails.id"
                  @click="clickApuesta('HTX130X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX130X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXHT }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'HTX131X' + this.gameDetails.id"
                  @click="clickApuesta('HTX131X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('HTX131X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2HT }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div class="title card mt-3" v-if="gameDetails.hayFQ" :class="{ 'titleOdd-light': this.getThemelight }">
          1er Periodo (1st Prd)
        </div>

        <div v-if="this.gameDetails.gana1FQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 (1st Prd)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FQX129X' + this.gameDetails.id"
                  @click="clickApuesta('FQX129X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FQX129X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1FQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FQX130X' + this.gameDetails.id"
                  @click="clickApuesta('FQX130X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FQX130X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXFQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'FQX131X' + this.gameDetails.id"
                  @click="clickApuesta('FQX131X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('FQX131X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2FQ }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.spreadFQ1">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">P. Spread/HDP´(1st Prd)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FQX3X' + this.gameDetails.id"
                @click="clickApuesta('FQX3X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FQX3X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadFQ1 }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FQX4X' + this.gameDetails.id"
                @click="clickApuesta('FQX4X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FQX4X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadFQ2 }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totAltaFQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales (1st Prd)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FQX5X' + this.gameDetails.id"
                @click="clickApuesta('FQX5X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FQX5X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totAltaFQ }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'FQX6X' + this.gameDetails.id"
                @click="clickApuesta('FQX6X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('FQX6X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totBajaFQ }}
              </button>
            </div>
          </div>
        </div>

        <div class="title card mt-3" v-if="gameDetails.haySQ" :class="{ 'title-light': this.getThemelight }">
          2do Periodo (2nd Prd)
        </div>

        <div v-if="this.gameDetails.gana1SQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 (2nd Prd)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'SQX1X' + this.gameDetails.id"
                  @click="clickApuesta('SQX1X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('SQX1X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1SQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'SQX7X' + this.gameDetails.id"
                  @click="clickApuesta('SQX7X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('SQX7X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXSQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'SQX2X' + this.gameDetails.id"
                  @click="clickApuesta('SQX2X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('SQX2X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2SQ }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.spreadSQ1">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">P. Spread/HDP´(2nd Prd)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'SQX3X' + this.gameDetails.id"
                @click="clickApuesta('SQX3X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('SQX3X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadSQ1 }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'SQX4X' + this.gameDetails.id"
                @click="clickApuesta('SQX4X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('SQX4X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadSQ2 }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totAltaSQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales (2nd Prd)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'SQX5X' + this.gameDetails.id"
                @click="clickApuesta('SQX5X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('SQX5X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totAltaSQ }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'SQX6X' + this.gameDetails.id"
                @click="clickApuesta('SQX6X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('SQX6X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totBajaSQ }}
              </button>
            </div>
          </div>
        </div>

        <div class="title card mt-3" v-if="gameDetails.hayTQ" :class="{ 'title-light': this.getThemelight }">
          3er Periodo (3rd Prd)
        </div>

        <div v-if="this.gameDetails.gana1TQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 (3rd Prd)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'TQX1X' + this.gameDetails.id"
                  @click="clickApuesta('TQX1X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('TQX1X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1TQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'TQX7X' + this.gameDetails.id"
                  @click="clickApuesta('TQX7X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('TQX7X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXTQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'TQX2X' + this.gameDetails.id"
                  @click="clickApuesta('TQX2X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('TQX2X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2TQ }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.spreadTQ1">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">P. Spread/HDP´(3rd Prd)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'TQX3X' + this.gameDetails.id"
                @click="clickApuesta('TQX3X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('TQX3X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadTQ1 }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'TQX4X' + this.gameDetails.id"
                @click="clickApuesta('TQX4X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('TQX4X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadTQ2 }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totAltaTQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales (3rd Prd)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'TQX5X' + this.gameDetails.id"
                @click="clickApuesta('TQX5X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('TQX5X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totAltaTQ }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'TQX6X' + this.gameDetails.id"
                @click="clickApuesta('TQX6X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('TQX6X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totBajaTQ }}
              </button>
            </div>
          </div>
        </div>

        <div class="title card mt-3" v-if="gameDetails.hayQQ" :class="{ 'title-light': this.getThemelight }">
          4to Periodo (4th Prd)
        </div>

        <div v-if="this.gameDetails.gana1QQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">1 X 2 (4th Prd)</div>

          <table width="100%" class="mt-2">
            <tr class="text-center">
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'QQX1X' + this.gameDetails.id"
                  @click="clickApuesta('QQX1X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('QQX1X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana1QQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'QQX7X' + this.gameDetails.id"
                  @click="clickApuesta('QQX7X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('QQX7X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.ganaXQQ }}
                </button>
              </td>
              <td width="30">
                <button
                  class="btn btnTableOdd"
                  :id="'QQX2X' + this.gameDetails.id"
                  @click="clickApuesta('QQX2X')"
                  :class="{
                    'apuesta-seleccionada': this.existeApuesta('QQX2X'),
                    'btnTableOdd-light': this.getThemelight
                  }"
                >
                  {{ this.gameDetails.gana2QQ }}
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="this.gameDetails.spreadQQ1">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">P. Spread/HDP´(4th Prd)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'QQX3X' + this.gameDetails.id"
                @click="clickApuesta('QQX3X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('QQX3X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadQQ1 }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'QQX4X' + this.gameDetails.id"
                @click="clickApuesta('QQX4X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('QQX4X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.spreadQQ2 }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.gameDetails.totAltaQQ">
          <div class="titleOdd mt-2" :class="{ 'titleOdd-light': this.getThemelight }">Totales (4th Prd)</div>
          <div class="row text-center mt-2">
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'QQX5X' + this.gameDetails.id"
                @click="clickApuesta('QQX5X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('QQX5X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totAltaQQ }}
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-block btnDetailOdd"
                :id="'QQX6X' + this.gameDetails.id"
                @click="clickApuesta('QQX6X')"
                :class="{
                  'apuesta-seleccionada': this.existeApuesta('QQX6X'),
                  'btnDetailOdd-light': this.getThemelight
                }"
              >
                {{ this.gameDetails.totBajaQQ }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </details>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { juegosDetalles } from "../../../../mixins/juegosDetallesMixin.js";
export default {
  name: "FutbolAmericanoMovil",
  data() {
    return {
      verDetalle: false,
      show: false
    };
  },
  mixins: [juegosDetalles],
  props: {
    gameDetails: Object
    // cartId: [],
    // oddId: String
  },
  computed: {
    ...mapGetters(["getThemelight"])
  },
  methods: {
    abrirDetalle(muestraTitulo) {
      if (muestraTitulo) this.show = true;
      this.$emit("label", "details");
    },
    detalle(id) {
      $("#btnJugadas" + id).addClass("apuesta-seleccionada");
      this.verDetalle = !this.verDetalle;
    }
  }
};
</script>
