<template>
  <div class="content text-center animate__animated animate__backInUp top-perfil">
    <div class="row align-items-center my-2">
      <div class="col-1">
        <i
          class="fas fa-chevron-left fa-lg text-light"
          :class="{ 'text-dark': this.getThemelight }"
          v-on:click="back()"
        ></i>
      </div>
      <div class="col-10">
        <div class="text-title" :class="{ 'text-light': !getThemelight, 'light-h1': getThemelight }">Editar perfil</div>
      </div>
    </div>

    <div style="height: 550px; !important; overflow: scroll; padding-bottom: 100px">
      <div class="card mt-3 bg-light" :class="{ 'btn-bg-light': this.getThemelight }">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="color-text text-left">Nombre y Apellido*</label>
                <input
                  type="text"
                  v-model="name"
                  disabled
                  :class="{
                    'form-control': true
                  }"
                />
              </div>
              <div class="form-group">
                <label class="color-text text-left">Usuario *</label>
                <input
                  type="text"
                  v-model="user"
                  disabled
                  :class="{
                    'form-control': true
                  }"
                />
              </div>
              <div class="form-group">
                <label class="color-text text-left">Cédula *</label>
                <input
                  type="text"
                  v-model="cedula"
                  disabled
                  :class="{
                    'form-control': true
                  }"
                />
              </div>
              <div class="form-group">
                <label for="fecha" class="label-formulario">Fecha de Nacimiento</label>
                <Datepicker
                  :language="languages[language]"
                  v-model="fecha"
                  placeholder="Seleccione fecha"
                  class="datepicker-custom max100"
                ></Datepicker>
              </div>
              <div class="form-group">
                <label for="fecha" class="label-formulario">Correo Electrónico</label>
                <input
                  type="email"
                  v-model="email"
                  disabled
                  :class="{
                    'form-control': true
                  }"
                />
              </div>
              <div class="form-group">
                <label for="estado" class="label-formulario">Estado</label>
                <select name="cities" v-model="apartment_id" class="form-control">
                  <option v-for="(cities, index) in getCities" :key="index" :value="cities.id" v-show="getCities">
                    {{ cities.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="ciudad" class="label-formulario">Ciudad</label>
                <input type="text" v-model="ciudad" class="form-control" id="ciudad" />
              </div>
              <div class="form-group">
                <label for="prefijo" class="label-formulario">Prefijo Internacional</label>
                <input type="text" v-model="pref_int" class="form-control" id="prefijo" disabled />
              </div>
              <div class="form-group">
                <label for="codigo" class="label-formulario">Còdigo de Área *</label>
                <select name="countries" v-model="prefijoSelected" class="form-control">
                  <option v-for="(prefijo, index) in prefMovil" :key="index" :value="prefijo.code">
                    {{ prefijo.code }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="telefono" class="label-formulario">Teléfono Fijo / Celular *</label>
                <input type="number" v-model="phone" class="form-control" id="telefono" />
              </div>
              <div class="form-group">
                <label for="pais" class="label-formulario">Pa&iacute;s(moneda)</label>
                <input type="text" class="form-control" id="pais" disabled />
              </div>
              <div class="form-group">
                <div class="form-check">
                  <label class="form-check-label" for="gridCheck"> Desea recibir notificaciones via SMS </label> <br />
                  <input class="form-check-input" type="checkbox" id="gridCheck" />
                </div>
              </div>
              <div class="form-group mt-5">
                <div class="text-center">
                  <button type="button" v-on:click="submit" class="btn btn-primary boton-color-fondo">GUARDAR</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import { required, minLength } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: "EditPerfilMovil",
  data() {
    return {
      name: "",
      email: "",
      cedula: null,
      fecha: moment(new Date().getFullYear() - 18 + "0101", "YYYYMMDD").format("llll"),
      language: "es", // the language I need
      languages: lang,
      user: "",
      pref_int: "",
      prefMovil: [{ code: "412" }, { code: "424" }, { code: "414" }, { code: "416" }, { code: "426" }],
      prefijoSelected: "412",
      ciudad: "",
      apartment_id: null,
      cities: null
    };
  },
  validations: {
    ciudad: {
      required,
      minLength: minLength(3)
    },
    phone: {
      required,
      minLength: minLength(7)
    },
    password: {
      required,
      minLength: minLength(8)
    }
  },
  components: {
    Datepicker
  },
  computed: {
    ...mapGetters(["getAccounts", "getThemelight", "getCities", "getUser"])
  },
  methods: {
    back() {
      this.$bvModal.show("modal-cuenta");
    },
    getForm() {
      this.name = this.getUser.name;
      this.email = this.getUser.email;
      this.user = this.getUser.username;
      this.cedula = this.getUser.cedula;
      this.pref_int = this.getUser.pref_int;
      this.phone = this.getUser.phone;
      this.prefijoSelected = this.getUser.pref_movil;
      this.ciudad = this.getUser.ciudad;
      this.fecha = this.getUser.fecha_nac;
      this.apartment_id = this.getUser.apartment_id;
    },
    async submit() {
      this.$store.commit("showLoader");

      const resp = await this.$userService.updateUserprofile({
        coin_id: this.getUser.coin_id,
        fecha_nac: moment(this.fecha).format("YYYY-MM-DD"),
        ciudad: this.ciudad,
        phone: this.phone,
        pref_movil: this.prefijoSelected,
        pref_int: this.pref_int,
        apartment_id: this.apartment_id
      });

      this.$store.commit("hideLoader");

      //actualizacion del User en el storage
      let UserModeladoUpdate = JSON.parse(JSON.stringify(this.getUser));

      UserModeladoUpdate.coin_id = this.coin_id;
      UserModeladoUpdate.fecha_nac = this.fecha_nac;
      UserModeladoUpdate.ciudad = this.ciudad;
      UserModeladoUpdate.phone = this.phone;
      UserModeladoUpdate.pref_movil = this.prefijoSelected;
      UserModeladoUpdate.pref_int = this.pref_int;
      UserModeladoUpdate.apartment_id = this.apartment_id;

      this.$store.commit("setUser", UserModeladoUpdate);

      console.log(resp, "updateUserprofile", UserModeladoUpdate);
      if (resp.error && resp.error != "" && resp.error.toUpperCase() != "OK") {
        this.Swal.fire("Error", resp.error, "error");
        return;
      }

      this.Swal.fire("Excelente", "Datos actualizados con éxito!", "success");
    }
  },
  created() {
    this.getForm();
  }
};
</script>

<style scoped>
.top-perfil {
  margin-top: 150px !important;
  height: auto;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>
