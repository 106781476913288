<template>
  <div class="container-slots">
    <div class="container-fluid">
      <section class="banner mb-3">
        <carousel
          id="carouselBannersSlots"
          :dots="false"
          :autoplay="true"
          :loop="true"
          :center="true"
          :responsive="{
            0: { items: 1, nav: false, center: true, loop: true, autoplay: true },
            600: { items: 1, nav: false, center: true }
          }"
          :class="{ 'mini-banners-web': true, 'mb-4': getUser }"
        >
          <div class="row center-block text-center">
            <a href="https://sellatuparley.com/pages/money-train/">
              <img
                v-lazy="'https://sellatuparley.com/img/iconos/banner_cashday_playson.webp'"
                style="width: 100%; max-height: 150px; margin: 0 auto"
              />
            </a>
          </div>

          <div class="row center-block text-center">
            <a href="https://sellatuparley.com/pages/fesstive-fire-place/" target="_blank">
              <img
                v-lazy="'https://sellatuparley.com/img/iconos/banner_boongo_Fesstivefireplace.webp'"
                class="img-responsive"
                style="margin: 0 auto"
              />
            </a>
          </div>

          <div class="row center-block text-center">
            <a href="https://sellatuparley.com/pages/dropsandwins/" target="_blank">
              <img
                v-lazy="'https://sellatuparley.com/img/iconos/banner_dropswinswinter_slots.webp'"
                class="img-responsive"
                style="margin: 0 auto"
              />
            </a>
          </div>

          <div class="row center-block text-center">
            <a href="https://sellatuparley.com/pages/merry-prize-month/" target="_blank">
              <img
                v-lazy="'https://sellatuparley.com/img/iconos/banner_playson_Marryprizemonth.webp'"
                class="img-responsive"
                style="margin: 0 auto"
              />
            </a>
          </div>
        </carousel>
      </section>

      <div class="aviso" v-if="this.noticeDollars">
        <div
          class="contenedor-imagen-notice"
          :style="{ background: `url(${this.noticeDollars}) no-repeat` }"
          @click="noticeDollars = null"
        >
          <spam class="float-right pr-2 cross-x">X</spam>
        </div>
      </div>

      <section class="busqueda" v-show="menuLateralActivo">
        <form class="activador-busqueda" @submit.prevent="buscarSlot()">
          <i class="fa fa-search" aria-hidden="true" @click="buscarSlot()"></i>
          <input type="text" class="campo-busqueda" autocomplete="off" @keyup="buscarSlot" placeholder="Buscar ..." />
        </form>

        <div class="text-center">
          <button class="btn btn-light btn-sound" v-on:click="activadorSound">
            <i class="fa fa-volume-up" v-show="this.audioReproduciendo"></i>
            <i class="fa fa-volume-off" v-show="!this.audioReproduciendo"></i>
          </button>
          <audio id="soundAmbiente" src="/sounds/0004790.mp3" preload="auto" />
        </div>
      </section>

      <div
        :class="{ 'activador-menu': true, activo: menuLateralActivo }"
        @click="menuLateralActivo = !menuLateralActivo"
      >
        <i class="fa fa-bars"></i>
      </div>

      <div class="row no-padding ml-0">
        <div class="col-1 contenedor-barralateral" v-if="menuLateralActivo">
          <div
            :class="{
              'btn btn-primary btn-filter': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'todos' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'todos' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('todos')"
          >
            TODOS
          </div>
          <div
            :class="{
              'btn btn-primary btn-filter mt-2': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'FAVORITOS' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'FAVORITOS' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('FAVORITOS')"
          >
            FAVORITOS
          </div>

          <div class="dropdown dropright">
            <button
              :class="{
                'btn btn-primary btn-filter dropdown-toggle mt-2': true,
                'menu-light': this.getThemelight,
                'btn-target-active': filterCategoria == 'FABRICANTES' && !this.getThemelight,
                'btn-target-active light': filterCategoria == 'FABRICANTES' && this.getThemelight
              }"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              FABRICANTES
            </button>
            <div class="dropdown-menu fabricante-scroll" aria-labelledby="dropdownMenuButton">
              <a
                v-show="fabricantes && fabricantes.length > 0"
                v-for="(f, i) in fabricantes"
                :key="i"
                class="dropdown-item"
                :href="'#' + f"
                @click="setFabricante(f)"
                >{{ f }}</a
              >
            </div>
          </div>

          <div
            :class="{
              'btn btn-primary btn-filter mt-2': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'slots' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'slots' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('slots')"
          >
            SLOTS
          </div>

          <div
            :class="{
              'btn btn-primary btn-filter mt-2': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'table' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'table' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('table')"
          >
            MESA
          </div>
          <div
            :class="{
              'btn btn-primary btn-filter mt-2': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'video poker' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'video poker' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('video poker')"
          >
            VIDEO POKER
          </div>

          <div
            :class="{
              'btn btn-primary btn-filter mt-2': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'card' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'card' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('card')"
          >
            CARTAS
          </div>
          <div
            :class="{
              'btn btn-primary btn-filter mt-2': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'poker' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'poker' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('poker')"
          >
            PÓKER
          </div>

          <div
            :class="{
              'btn btn-primary btn-filter mt-2': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'blackjack' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'blackjack' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('blackjack')"
          >
            BlackJack
          </div>

          <div
            :class="{
              'btn btn-primary btn-filter  mt-2': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'roulette' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'roulette' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('roulette')"
          >
            RULETA
          </div>

          <div
            :class="{
              'btn btn-primary btn-filter  mt-2': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'casual' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'casual' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('casual')"
          >
            CASUAL
          </div>

          <div
            :class="{
              'btn btn-primary btn-filter mt-2': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'lottery' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'lottery' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('lottery')"
          >
            LOTERIA
          </div>

          <div
            :class="{
              'btn btn-primary btn-filter mt-2': true,
              'menu-light': this.getThemelight,
              'btn-target-active': filterCategoria == 'craps' && !this.getThemelight,
              'btn-target-active light': filterCategoria == 'craps' && this.getThemelight
            }"
            v-on:click="filtrarPorCategoria('craps')"
          >
            Craps
          </div>
        </div>
        <div
          :class="{
            'col-11': menuLateralActivo,
            'col-12 full100': !menuLateralActivo,
            'no-padding contenedor-slots': true
          }"
        >
          <iframe
            v-if="this.iframeOpen"
            :src="this.urlIframe"
            frameborder="0"
            class="iframe-game mt-2"
            @load="iframeLoad"
          ></iframe>
          <div :class="{ 'row contenido-slots pt-4': true, full100: !menuLateralActivo }" v-show="!this.iframeOpen">
            <div class="col-sm-4 col-md-2 mb-2 mt-2 text-center" v-for="(slot, index) in this.dataResults" :key="index">
              <i
                @click="clickFavorito(slot)"
                :class="{
                  'fa-star-o fa icon-favorito': true,
                  'es-favorito': slot.id && estaEnFavoritos(slot.id)
                }"
              ></i>

              <a
                href="#"
                @click="clickOpenGame($event, urlBaseLink + 'game_url=' + slot.id + '&provider=' + slot.provider)"
              >
                <img
                  v-lazy="urlCdn + 'slots/' + slot.provider + '/' + slot.id + '.png'"
                  :alt="slot.name"
                  class="img-slog"
                />
              </a>
              <label class="name-slot">{{ slot.name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { chatMixin } from "../mixins/chatMixin.js";
import { slotsFavoritosMixin } from "../mixins/slotsFavoritosMixin.js";
import { filtrarSlotsMixin } from "../mixins/filtrarSlotsMixin.js";
import carousel from "vue-owl-carousel";

export default {
  name: "Slots",
  mixins: [chatMixin, slotsFavoritosMixin, filtrarSlotsMixin],
  components: { carousel },
  computed: {
    ...mapGetters(["getDataSlots", "getFavotitos", "getUser", "getThemelight"])
  },
  data() {
    return {
      promiseAudio: null,
      audioReproduciendo: false,
      maxVisible: 40,
      fabricantes: [],
      dataSlots: [],
      totalSlots: 0,
      dataResults: [],
      filterFavorites: true,
      filterByName: "",
      filterCategoria: "todos",
      filterCategoriaActive: false,
      filterFabricantesActive: false,
      fabricante: "",
      urlCdn: process.env.VUE_APP_CDN_SELLA,
      urlIframe: "",
      iframeOpen: false,
      menuLateralActivo: true,
      urlBaseLink: "https://apipwa.sellatuparley.com/api/client?",
      noticeDollars: null,
      position: 1,
      cantSlotXPag: 40,
      slotFiltrados: []
    };
  },
  watch: {
    filterByName() {
      this.buscarSlot();
    }
  },
  methods: {
    estaEnFavoritos(id) {
      return (
        this.getFavotitos.filter(f => {
          return f.id && f.id == id;
        }).length > 0
      );
    },
    focusCampoBusqueda() {
      if (document.getElementsByClassName("campo-busqueda")[0]) {
        document.getElementsByClassName("campo-busqueda")[0].focus();
      }
    },
    iframeLoad() {
      this.$store.commit("hideLoader");
      const scrollVertical = window.scrollY - 320;
      console.log(scrollVertical);
      window.scrollTo(0, 160);
      if (document.getElementsByClassName("activador-menu")) {
        document.getElementsByClassName("activador-menu")[0].classList.add("corrida-up");
      }
    },
    async clickOpenGame(evt, link) {
      evt.preventDefault();
      this.$store.commit("showLoader");
      const dataUrlIframe = await this.$store.dispatch("activarSlotGetURL", link);

      if (dataUrlIframe.game_url && dataUrlIframe.game_url.url) {
        this.urlIframe = dataUrlIframe.game_url.url;

        if (dataUrlIframe.game_url.notice) {
          this.noticeDollars = dataUrlIframe.game_url.notice;
        } else {
          this.noticeDollars = null;
        }

        this.iframeOpen = true;
      } else if (!dataUrlIframe.game_url) {
        this.Swal.fire(
          "Ha ocurrido un error",
          "en estos momentos no se escuentra disponible intente mas tarde",
          "error"
        );
      }
      this.$store.commit("hideLoader");
    },
    cargarMas() {
      if (this.totalSlots > this.cantSlotXPag) {
        this.cantSlotXPag += 12;
        this.ajustarDatos();
      }
    },
    // ******* Jesus H 18-04-2022 cargar los fabricantes ****
    getFabricantes() {
      this.fabricantes = this.getDataSlots.manufacturers;
    },
    // ****** Jesus H 18-04-2022 filtrar fabricantes ********
    async setFabricante(f) {
      this.cantSlotXPag = 40;
      this.$store.commit("showLoader");
      this.slotFiltrados = await this.filtrarFabricante(f);
      this.ajustarDatos();
      this.filterCategoria = "FABRICANTES";
      setTimeout(() => {
        this.$store.commit("hideLoader");
      }, 2000);
    },
    // ****** Jesus H 18-04-2022  filtrar por categoria *********
    async filtrarPorCategoria(categoriaTarget) {
      this.cantSlotXPag = 40;
      this.$store.commit("showLoader");
      this.slotFiltrados = await this.filtrarCategoria(categoriaTarget);
      this.ajustarDatos();
      this.filterCategoria = categoriaTarget;
      setTimeout(() => {
        this.$store.commit("hideLoader");
      }, 2000);
    },
    // ****** Jesus H 18-04-2022  Buscar por nombre *********
    buscarSlot(e) {
      this.cantSlotXPag = 40;
      if (e.target.value.length > 0) {
        this.dataResults = this.dataSlots.filter(slot =>
          slot.name
            .toString()
            .toLowerCase()
            .includes(e.target.value.toString().toLowerCase())
        );
      } else {
        this.ajustarDatos();
      }
    },
    // ****** Jesus H 18-04-2022  ajuestar la data antes de mostrar *********
    ajustarDatos() {
      this.dataSlots = this.slotFiltrados.length > 0 ? this.slotFiltrados : [];
      this.dataResults = this.dataSlots.slice(0, this.cantSlotXPag * 1);
      this.totalSlots = this.dataSlots.length;
    },
    activadorSound() {
      if (this.audioReproduciendo) {
        this.pauseSound();
      } else {
        this.playSound();
      }
    },
    sortById() {
      // fuente de datos
      return this.dataSlots.sort(function(a, b) {
        if (a.id <= b.id) {
          return 1;
        }
        if (a.id >= b.id) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    },
    sortByFavorites() {
      return this.dataSlots.games;

      // // fuente de datos Anterior
      // return this.dataSlots.sort(function(a, b) {
      //   if (a.favoritos < b.favoritos) {
      //     return 1;
      //   }
      //   if (a.favoritos > b.favoritos) {
      //     return -1;
      //   }
      //   // a must be equal to b
      //   return 0;
      // });
    },
    pauseSound() {
      document.querySelector("#soundAmbiente").pause();
      this.audioReproduciendo = false;
    },
    playSound() {
      document.querySelector("#soundAmbiente").play();
      this.audioReproduciendo = true;
    }
  },
  async created() {
    // verifica la session y extiende por actividad
    await this.$store.dispatch("checkSession");
  },
  async mounted() {
    if (!this.getUser) {
      // si no esta logueado se envia al home
      this.$store.commit("setPage", "Home");
      return;
    }
    await this.$store.dispatch("searchSlots");
    this.hideChat();
    this.checkFavoritos();
    this.promiseAudio = document.querySelector("#soundAmbiente").play();
    this.slotFiltrados = await this.filtrarCategoria("todos");
    this.ajustarDatos();
    this.getFabricantes();

    // ------ DEBUG -----
    // por los momentos hay Ids duplicados eso trae problemas para ordenar por ids
    // const dataOrdenado = await this.sortById();
    // this.dataSlots = dataOrdenado;
    // this.dataSlots = JSON.parse(JSON.stringify(dataOrdenado));
    // ------ DEBUG -----

    if (this.filterFavorites) {
      // this.dataSlots = await this.sortByFavorites();
    }

    if (this.promiseAudio !== undefined) {
      this.promiseAudio
        .then(() => {
          // Autoplay started!
          console.log("Autoplay started!!!");
          this.audioReproduciendo = true;
        })
        .catch(error => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
          console.log(error);
        });
    }

    // Evento cuando el user de scroll
    window.addEventListener("scroll", () => {
      let alturaDocumento = $(document).height();
      let alturaScroll = $(window).scrollTop();
      let alturaWiePort = $(window).height();

      if (alturaDocumento == alturaScroll + alturaWiePort) {
        console.log("Aqui estoy");
        this.cargarMas();
        window.scrollTo(0, alturaScroll - 1);
      }

      const scrollActual = window.scrollY;

      if (
        scrollActual < 106 &&
        document.getElementsByClassName("activador-menu") &&
        document.getElementsByClassName("activador-menu")[0]
      ) {
        let calculo = 260 - scrollActual;
        document.getElementsByClassName("activador-menu")[0].style.top = calculo + "px";
      }

      if (
        scrollActual > 106 &&
        document.getElementsByClassName("activador-menu") &&
        document.getElementsByClassName("activador-menu")[0]
      ) {
        document.getElementsByClassName("activador-menu")[0].style.top = "100px";
      }

      if (
        scrollActual < 159 &&
        document.getElementsByClassName("activador-menu") &&
        document.getElementsByClassName("activador-menu")[0]
      ) {
        let scrollBurger = window.scrollY - 50 + 100;

        if (scrollBurger >= 106 && scrollBurger <= 156) {
          document.getElementsByClassName("activador-menu")[0].style.top = scrollBurger + "px";
        }
      }

      let alturaContenidoCentral =
        document.getElementsByClassName("contenido-slots") && document.getElementsByClassName("contenido-slots")[0]
          ? document.getElementsByClassName("contenido-slots")[0].offsetHeight
          : 0;

      // NUEVO CONDICIONAL
      if (
        scrollActual >= 160 &&
        scrollActual < 260 &&
        document.getElementsByClassName("contenedor-barralateral") &&
        document.getElementsByClassName("contenedor-barralateral")[0]
      ) {
        let calculo = scrollActual - 190;
        calculo = calculo + 50;
        // padin btnes opciones lateral
        document.getElementsByClassName("contenedor-barralateral")[0].style.paddingTop = calculo + "px";
        return;
      }

      if (
        scrollActual > 260 &&
        scrollActual <= alturaContenidoCentral &&
        document.getElementsByClassName("contenedor-barralateral") &&
        document.getElementsByClassName("contenedor-barralateral")[0]
      ) {
        let calculo = scrollActual - 190;
        calculo = calculo + 50;
        document.getElementsByClassName("contenedor-barralateral")[0].style.paddingTop = calculo + "px";
        //console.log("calculo paddingTop: " + calculo + "px");
      } else if (
        scrollActual <= 260 &&
        document.getElementsByClassName("contenedor-barralateral") &&
        document.getElementsByClassName("contenedor-barralateral")[0]
      ) {
        document.getElementsByClassName("contenedor-barralateral")[0].style.paddingTop = "20px";
      }
    });
  }
};
</script>
<style scoped>
.fabricante-scroll {
  overflow-y: scroll;
  height: 20em;
}
div.row.contenido-slots > div {
  margin: 0;
}
.cross-x {
  color: #c1c1c1;
  font-weight: 300;
  cursor: pointer;
  text-shadow: 1px 1px 3px black;
  font-size: 20px;
}
.cross-x:hover {
  color: #fff;
}

.aviso {
  z-index: 1000;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  height: 100%;
  right: 0;
  display: flex;
  justify-content: center;
}
.contenedor-imagen-notice {
  width: 480px;
  height: 340px;
  margin: auto;
  margin: 15% auto;
  /*cursor: pointer;*/
}
.corrida-up {
  /*top: 97px!important;*/
}

.busqueda {
  background-color: transparent;
  width: 100% !important;
  height: 50px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  /*flex-direction: row-reverse;*/
}
.activador-busqueda {
  /*position: fixed;*/
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5px;
  border-radius: 7px;
  width: 350px;
  height: 48px;
  right: 14px;
  /*z-index: 5;*/
  z-index: 0;
  top: 239px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 65px;
}
.activador-busqueda > i.fa.fa-search:hover {
  color: #fdf46a;
}

.campo-busqueda {
  width: 90%;
  border-radius: 7px;
  border-style: unset;
  height: 90%;
  text-align: center;

  font-size: 25px;
  font-style: italic;
  font-weight: 200;
  color: #5b5b5c;
}

.activador-menu {
  width: 50px;
  height: 50px;
  position: fixed;
  background-color: #ffffff49;
  z-index: 50;
  /*top: 97px;*/
  top: 253px;
  left: 0px;
  border-radius: 0px 25px 25px 25px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  transition: top 0.5s, transform 0.5s, margin 0.5s, margin-top 0.5s;
}
.activador-menu:hover {
  background-color: #fff;
  box-shadow: 1px 2px 5px black;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.no-padding {
  padding: 0px;
}

.activador-menu:active,
.activador-menu.activo {
  background: linear-gradient(0deg, #f09819, #ff512f) !important;
}

.activador-menu:active > i,
.activador-menu.activo > i {
  color: #fff;
}

.full100 {
  max-width: 100% !important;
}

.contenedor-slots {
  max-width: 88%;
}
.contenedor-barralateral {
  /*  padding: 70px 0px 0px 0px;*/
  padding: 10px 0px 0px 0px;
  max-width: 12%;
}

.contenedor-barralateral .btn.btn-primary.btn-filter {
  width: 100%;
  /*
    font-size: 13px!important;
    height: 25px;
    line-height: 1;
    */
}

.iframe-game {
  border: 0;
  width: 100%;
  height: 600px;
  margin: 0px;
  padding: 0px;
}
.btn-load-more {
  width: 100%;
  cursor: pointer;
}
.barra-filtrado {
  /*height: 100px;*/
  background-color: #000000;
  background-image: linear-gradient(0deg, #000000 0%, #011f62 70%);
  border-radius: 7px;
  display: flex;
}

.btn.btn-primary.btn-filter,
.btn-filter {
  width: 100%;
  font-size: 15px;
  text-transform: capitalize !important;
  background-color: #363636;
  border-color: #000000;
}
.btn-filter:hover {
  background-color: #6c6c6c !important;
  border-color: #464646 !important;
}
.menu-light {
  color: #000 !important;
  background-color: #ffffff !important;
  border-color: #000000 !important;
  font-weight: bold !important;
}
.menu-light:hover {
  color: #fff !important;
  background-color: #0068ff !important;
  border-color: #464646 !important;
}

.name-slot {
  color: #fff;
  text-align: center;
  width: 100%;
  font-weight: bold !important;
  /*font-weight: 200 !important;*/
  font-family: Poppins, sans-serif;
  padding-top: 5px;
}

.icon-favorito {
  color: rgb(255, 78, 0);
  position: absolute;
  margin: -2px; /*2px*/
  cursor: pointer;

  background-color: black;
  border-radius: 50%;
  padding: 5px; /*3px*/
  transition: 500ms linear;
}

.icon-favorito:hover {
  text-decoration: none;
  font-weight: bold;
}

.es-favorito {
  color: #ebdb30 !important;
  border: #ebdb30 solid 2px;
  font-weight: bold !important;
  text-decoration: none;
}
.btn-target-active {
  /* background-image: linear-gradient(
    to right top,
    #ffd300,
    #ffc700,
    #ffba00,
    #ffae00,
    #ffa100,
    #fba70c,
    #f8ac17,
    #f4b120,
    #ecc73c,
    #e4db5a,
    #ddee7a,
    #d8ff9b
  ); */
  /* border-color: #f9aa14; */
  border-color: #0d6403;
  font-weight: 700;
  background: linear-gradient(-90deg, #6bff00, #0d6403) !important;
}
.light {
  border-color: #0068ff !important;
  font-weight: 700;
  background: linear-gradient(-90deg, #0068ff, #0068ff) !important;
  color: #fff !important;
}

.banner {
  padding: 0;
  margin: 0;
  height: 155px;
  overflow: hidden;
}
/*Efectos*/

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
<style>
.image-loader-logo {
  width: 200px;
}
/*
  max-width: 140px;
  height: 96px;
  */
.img-slog {
  border-radius: 7px;
  width: 140px;
  height: 140px;
}

.img-slog:hover {
  cursor: pointer;

  box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px #0ba9ca, 0 0 10px #0ba9ca, 0 0 10px #0ba9ca, 0 0 10px #0ba9ca;
  -webkit-animation: blink 0.7s infinite alternate;
  animation: blink 0.7s infinite alternate;
}

@-webkit-keyframes blink {
  100% {
    box-shadow: 0 0 3px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #0ba9ca, 0 0 30px #0ba9ca, 0 0 10px #0ba9ca;
  }
}

@keyframes blink {
  100% {
    box-shadow: 0 0 3px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #0ba9ca, 0 0 30px #0ba9ca, 0 0 10px #0ba9ca;
  }
}

/*min-height: 85vh;*/
.container-slots {
  min-height: 1100px;
  background-image: url(https://sellatuparley.com/img/lottos/fondo.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
}

.btn-sound,
.btn-sound:hover,
.btn-sound:active,
.btn-sound:focus,
.btn-sound:target,
.btn-sound:visited {
  background-color: transparent !important;
}

.btn-sound {
  z-index: 9;
  float: right;
  margin-right: 60px;
  margin-top: 500px !important;
  /* right: 15px; */
  /* position: absolute; */
  width: 50px;

  border: none;
  color: aliceblue !important;
  font-size: 30px;
}
.btn-sound:hover {
  color: orange !important;
}
</style>
