<template>
  <div class="container" id="container-mensajeria">
    <div class="row align-items-center my-3">
      <div class="text-center text-light mb-2 title-mensajeria" :class="{ 'text-dark': this.getThemelight }">
        <span> Mensajeria </span>
      </div>
    </div>

    <div
      class="card border-round-0 text-dark"
      v-for="mensaje of results"
      v-bind:key="mensaje.id"
      v-on:click="toPage('Mensaje', mensaje.id)"
    >
      <div class="row text-center d-flex justify-content-between bg-light border bg-title">
        <div class="ml-3">
          <b> {{ dateFormat(mensaje.fecha) }} </b> {{ timeFormat(mensaje.fecha) }}
        </div>
        <div class="float-right mr-3">
          <b>Asunto</b>
        </div>
      </div>
      <div class="row">
        <div class="text-center my-1 margenes-asunto" :class="{ msjNoLeido: !mensaje.leido }">
          {{ mensaje.asunto }}
        </div>
      </div>
    </div>
    <div class="mt-5 text-center">
      <i class="fas fa-plus-circle fa-lg" style="font-size: 50px" @click="more()"></i>
    </div>

    <div class="text-right mt-4 mb-4 fixed">
      <div class="badge shade" id="scroll" @click="top()">
        <i class="fas fa-chevron-up fa-lg"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  // created() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  data() {
    return {
      results: [],
      position: 1,
      disablePager: false
    };
  },
  name: "MensajeriaMovil",
  computed: {
    ...mapGetters(["getMensajeria", "getThemelight"])
  },
  methods: {
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    timeFormat(value) {
      if (value) {
        return moment(String(value)).format("LT");
      }
    },
    more() {
      // Any code to be executed when the window is scrolled
      //if ($(window).scrollTop() >= $(document).height() - $(window).height() && !this.disablePager) {
      this.$store.commit("showLoader");
      this.position++;
      this.$store.dispatch("getMessengerUserAsync", { page: this.position });
      setTimeout(() => {
        this.$store.commit("hideLoader");
        this.getMensajeria.data.forEach(element => {
          this.results.push(element);
        });
      }, 1000);
      //}
    },
    top() {
      //$([document.getElementById("container-preguntas"), document.body]).animate(
      $("html, #container-mensajeria").animate({ scrollTop: 0 }, 600);
    },
    toPage(page, id) {
      this.$store.commit("setMensajeId", id);
      this.$emit("changeTarget", page);
    },
    async getResults() {
      this.results = await this.getMensajeria.data;
    }
  },
  mounted() {
    if (this.getThemelight) {
      document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
    }

    this.getResults();
  }
};
</script>

<style scoped>
.bg-title {
  background: #e8eaea;
}
.title-orange {
  font-size: 1.5em;
  font-weight: 300;
  font-family: sans-serif;
  text-transform: uppercase;
  color: rgb(243, 156, 18);
}
.fixed {
  position: sticky;
  bottom: 10%;
  left: 10px;
}
.shade {
  font-size: 20px;
  background-color: rgba(217, 243, 252, 0.7);
}
.title-mensajeria {
  font-size: 1.5em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
  background-repeat: no-repeat;
}

.margenes-asunto {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.msjNoLeido {
  font-weight: 700;
}
</style>
