<template>
  <!-- v-if="getUser" -->
  <b-modal
    id="extenderSessionModal"
    centered
    no-close-on-backdrop
    title="Sesión Activa"
    hide-footer
    ref="session-extend-accounts"
    content-class="session-extend-accounts"
  >
    <template #modal-header>
      <button @click="closeModal" type="button" class="close x" data-dismiss="modal" aria-label="Close">
        <span @click="closeModal" style="font-size: 34px" aria-hidden="true">&times;</span>
      </button>
    </template>
    <h5>Su sesión está por expirar</h5>
    <i class="fas fa-sync icon-refresh"></i>
    <p>Expirará en {{ this.quedan }} segundos.</p>
    <button class="btn mt-3 float-left btn-info btn-size-custom btn-extender" @click="extenderSession">
      Extender sesión
    </button>
    <!-- se mando a quitar 
      <button class="btn mt-3 float-right btn-secondary btn-secondary btn-size-custom" @click="closeModal(true)">
        Cerrar sesión
      </button> 
    -->
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "ModalExtenderSession",
  computed: {
    ...mapGetters(["getUser", "getExpire", "getControlTime"])
  },
  data() {
    return {
      quedan: 0
    };
  },
  methods: {
    tiempoRestante() {
      // momento exacto en que se logueo y se calcula contra la diferencia
      let a = moment(parseInt(this.getControlTime));
      let b = moment(new Date().getTime());
      const diferenciaSegundos = parseInt(b.diff(a, "seconds"));
      this.quedan = parseInt(parseInt(this.getExpire) - diferenciaSegundos);
    },
    closeModal(cerrarSesion = false) {
      this.$bvModal.hide("extenderSessionModal");
      if (cerrarSesion == true) {
        this.$store.dispatch("logOut");
        this.$emit("changePage", "Home");
      }
    },
    async extenderSession() {
      this.$store.commit("showLoader");
      const resp = await this.$store.dispatch("extenderSession");
      console.log("extenderSession", resp);
      this.$store.commit("hideLoader");
      this.$bvModal.hide("extenderSessionModal");
    }
  },
  mounted() {
    setInterval(this.tiempoRestante, 1000);
  }
};
</script>
<style>
.btn-size-custom {
  min-width: 150px !important;
}

.session-extend-accounts .modal-body {
  display: contents;
}

.session-extend-accounts {
  display: grid;
  justify-content: center;
  justify-items: center;
  width: 100%;
  grid-template-columns: 1fr;
  border: none;
}

.session-extend-accounts h5 {
  font-size: 2em;
  font-weight: 300;
}

.session-extend-accounts a,
.session-extend-accounts p,
.session-extend-accounts button.btn-extender {
  width: 100%;
  text-align: center;
}

.session-extend-accounts i.icon-refresh {
  display: block;
  font-size: 5em;
  margin: 15px auto;
  text-align: center;
  color: #138496;
  animation-name: rotacion;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

@keyframes rotacion {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
