var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-game"},[_c('details',{staticClass:"card text-center",class:{ 'details-light text-dark': this.getThemelight, details: !this.getThemelight },on:{"click":function($event){return _vm.abrirDetalle(_vm.gameDetails.muestraTitulo)}}},[_c('summary',{staticClass:"my-2"},[(_vm.gameDetails.muestraTitulo || _vm.show)?_c('div',{staticClass:"titleGame text-center",class:{ 'titleGame-light': this.getThemelight }},[_c('p',[_vm._v(_vm._s(this.gameDetails.gameName)+" ("+_vm._s(this.gameDetails.gameDate)+")")])]):_vm._e(),_c('div',{staticClass:"row text-center",class:{ 'bg-header-light font-weight-bold': this.getThemelight },staticStyle:{"font-size":"0.9em"}},[_c('div',{staticClass:"col-4 border-right"},[_vm._v("\n          "+_vm._s(this.gameDetails.gameTime)+"\n        ")]),_c('div',{staticClass:"col-4 border-right"},[_c('span',[_vm._v(" "+_vm._s(this.gameDetails.teams[0].name)+" (1)")])]),_c('div',{staticClass:"col-4"},[_c('span',[_vm._v(" "+_vm._s(this.gameDetails.teams[1].name)+" (2) ")])])])]),_c('table',{staticClass:"mb-1",attrs:{"width":"100%","id":"oddsTable"}},[_c('tbody',[(_vm.gameDetails.teams[0]['1X2FT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT1'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            1X2 FT "),_c('br'),_vm._v("\n            (A Ganar)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX1X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX1X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX1X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["1X2FT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX2X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX2X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX2X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["1X2FT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['HDPFT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['HDPFT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT2'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            HDP (FT) "),_c('br'),_vm._v("\n            (Runline)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX3X'),
              'btnOdd-light font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX3X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["HDPFT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX4X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX4X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["HDPFT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['SHDPFT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['SHDPFT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT3'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            S HDP (FT) "),_c('br'),_vm._v("\n            (Sup-Runline)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX8X'),
              'btnOdd-light font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX8X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX8X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["SHDPFT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX9X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX9X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX9X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["SHDPFT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESFT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESFT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'FT4'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            TOTALES FT "),_c('br')]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX5X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX5X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["TOTALESFT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('FTX6X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'FTX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX6X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["TOTALESFT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['1X2HT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['1X2HT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'HT1'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            1 y 2 HT "),_c('br'),_vm._v("\n            (A Ganar)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX1X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX1X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX1X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["1X2HT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX2X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX2X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX2X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["1X2HT"])+"\n          ")])]):_vm._e(),(this.gameDetails.teams[0]['HDPHT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(this.gameDetails.teams[0]['HDPHT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'HT2'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            HDP HT "),_c('br'),_vm._v("\n            (Runline)\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX3X'),
              'btnOdd-light font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX3X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX3X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["HDPHT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX4X'),
              'btnOdd-light font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX4X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX4X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["HDPHT"])+"\n          ")])]):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESHT'])?_c('hr',{class:{ 'my-0': this.getThemelight, 'my-1': !this.getThemelight }}):_vm._e(),(_vm.gameDetails.teams[0]['TOTALESHT'])?_c('tr',{attrs:{"id":this.gameDetails.id + 'HT3'}},[_c('td',{staticClass:"refOdd border-right",class:{ 'refOdd-light font-weight-bold': this.getThemelight },attrs:{"width":"30%"}},[_vm._v("\n            TOTALES HT\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX5X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX5X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX5X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[0]["TOTALESHT"])+"\n          ")]),_c('td',{staticClass:"btnOdd",class:{
              'apuesta-seleccionada': this.existeApuesta('HTX6X'),
              'btnOdd-light  font-weight-bold': this.getThemelight
            },attrs:{"width":"30%","id":'HTX6X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX6X')}}},[_vm._v("\n            "+_vm._s(this.gameDetails.teams[1]["TOTALESHT"])+"\n          ")])]):_vm._e()])]),(_vm.gameDetails.hayFT || _vm.gameDetails.hayHT)?_c('div',{staticClass:"my-2"},[_c('button',{staticClass:"btn boton-dark",class:{ 'boton-light': this.getThemelight },attrs:{"id":'btnJugadas' + _vm.gameDetails.id},on:{"click":function($event){return _vm.detalle(_vm.gameDetails.id)}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Jugadas\n      ")])]):_vm._e(),(_vm.verDetalle)?_c('div',{staticClass:"mb-1"},[(_vm.gameDetails.hayFT)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        JUEGO COMPLETO (FT)\n      ")]):_vm._e(),(this.gameDetails.totVisAlta)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Totales Visitante (1) (FT)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX10X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX10X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX10X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totVisAlta)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX11X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX11X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX11X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totVisBaja)+"\n            ")])])])]):_vm._e(),(this.gameDetails.totLocAlta)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Totales Local (2) (FT)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX12X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX12X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX12X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totLocAlta)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX13X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX13X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX13X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totLocBaja)+"\n            ")])])])]):_vm._e(),(this.gameDetails.car1InnSi)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Carrera 1er Inning (FT)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX14X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX14X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX14X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.car1InnSi)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX15X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX15X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX15X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.car1InnNo)+"\n            ")])])])]):_vm._e(),(this.gameDetails.anotaLoc)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Anota Primero (FT)")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX16X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX16X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX16X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.anotaVis)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX17X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX17X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX17X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.anotaLoc)+"\n            ")])])])]):_vm._e(),(this.gameDetails.totCheAlta)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Totales CHE (FT)")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX18X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX18X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX18X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totCheAlta)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX19X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX19X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX19X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.totCheBaja)+"\n            ")])])])]):_vm._e(),(this.gameDetails.extraSi)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Extrainning")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX119X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX119X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX119X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.extraSi)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX120X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX120X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX120X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.extraNo)+"\n            ")])])])]):_vm._e(),(this.gameDetails.gana1FT)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1 X 2 FT (9 Innings)")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX129X'),
                  'btnTableOdd-light': this.getThemelight
                },attrs:{"id":'FTX129X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX129X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1FT)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX130X'),
                  'btnTableOdd-light': this.getThemelight
                },attrs:{"id":'FTX130X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX130X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXFT)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('FTX131X'),
                  'btnTableOdd-light': this.getThemelight
                },attrs:{"id":'FTX131X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX131X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2FT)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.parFT)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("\n          Marcador Par/Impar (FT)\n        ")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX60X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX60X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX60X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.parFT)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('FTX61X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'FTX61X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('FTX61X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.imparFT)+"\n            ")])])])]):_vm._e(),(_vm.gameDetails.hayHT)?_c('div',{staticClass:"title card mt-3",class:{ 'title-light': this.getThemelight }},[_vm._v("\n        1ERA MITAD (HT)\n      ")]):_vm._e(),(this.gameDetails.gana1HT)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("1er Inning 1 X 2")]),_c('table',{staticClass:"mt-2",attrs:{"width":"100%"}},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('HTX116X'),
                  'btnTableOdd-light': this.getThemelight
                },attrs:{"id":'HTX116X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX116X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana1HT)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('HTX117X'),
                  'btnTableOdd-light': this.getThemelight
                },attrs:{"id":'HTX117X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX117X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.ganaXHT)+"\n              ")])]),_c('td',{attrs:{"width":"30"}},[_c('button',{staticClass:"btn btnTableOdd",class:{
                  'apuesta-seleccionada': this.existeApuesta('HTX118X'),
                  'btnTableOdd-light': this.getThemelight
                },attrs:{"id":'HTX118X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX118X')}}},[_vm._v("\n                "+_vm._s(this.gameDetails.gana2HT)+"\n              ")])])])])]):_vm._e(),(this.gameDetails.tot1inningAlta)?_c('div',[_c('div',{staticClass:"titleOdd mt-2",class:{ 'titleOdd-light': this.getThemelight }},[_vm._v("Totales 1er Inning")]),_c('div',{staticClass:"row text-center mt-2"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('HTX127X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'HTX127X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX127X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.tot1inningAlta)+"\n            ")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-block btnOdd",class:{
                'apuesta-seleccionada': this.existeApuesta('HTX128X'),
                'btnDetailOdd-light': this.getThemelight
              },attrs:{"id":'HTX128X' + this.gameDetails.id},on:{"click":function($event){return _vm.clickApuesta('HTX128X')}}},[_vm._v("\n              "+_vm._s(this.gameDetails.tot1InningBaja)+"\n            ")])])])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }