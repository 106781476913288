<template>
  <div
    :class="{
      'animate__animated animate__backInUp': !this.modal,
      'light-theme bg-light': this.getThemelight,
      'soporte-movil': this.getIsMobilResolution,
      'container-terminos': !this.getIsMobilResolution && !this.modal
    }"
  >
    <div :class="{ container: !getIsMobilResolution, 'container-movil': getIsMobilResolution }">
      <div class="row mt-5">
        <div v-if="!isMobilResolution()" class="col-3"></div>
        <div class="col-12">
          <h1
            v-if="!modal"
            :class="{
              'title-orange-web': !getIsMobilResolution,
              'title-ayudas pt-2': getIsMobilResolution,
              'mb-4': true,
              letra: true,
              'text-center': true,
              'dark-h1': !this.getThemelight,
              'light-h1': this.getThemelight
            }"
            style="color: white; font-weight: 700"
          >
            {{ $t("terminos_y_condiciones") }}
          </h1>
          <h1
            v-if="modal"
            :class="{
              'title-orange-web py-3': true,
              'mb-4': true,
              letra: true,
              'text-center': true,
              'light-h1': true
            }"
            style="color: white; font-weight: 700"
          >
            {{ $t("terminos_y_condiciones") }}
          </h1>

          <div class="text-justify font5 container-html" :class="{ 'mx-2': getIsMobilResolution }">
            <AcordionCMS :data="this.getTerminosCondiciones" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JQ from "jquery";
import { mapGetters } from "vuex";
import AcordionCMS from "../components/AcordionCMS.vue";
import { essentialsData } from "../mixins/essentialsDataMixin.js";

export default {
  name: "Terminos",
  props: ["modal"],
  mixins: [essentialsData],
  components: {
    AcordionCMS
  },
  computed: {
    ...mapGetters(["getIsMobilResolution", "getThemelight", "getTerminosCondiciones", "getPage"])
  },
  data() {
    return {
      termsAndConditions: {
        general: null,
        limits: null,
        lotto: null,
        fortune: null,
        sport: null
      }
    };
  },
  watch: {
    getThemelight(t) {
      if (t && this.getIsMobilResolution) {
        document.getElementsByTagName("body")[0].style.backgroundColor = "rgb(239, 239, 239)";
      } else {
        document.getElementsByTagName("body")[0].style.backgroundColor = "#232323";
      }
    }
  },
  methods: {
    async getAyuda() {
      let lstobjAyuda = {
        url: "/api/v1/getAyuda?pagina_id=4&perPage=",
        set: "setTerminosCondiciones",
        hander: this.evaluaRespuestaErrorRequestMultiplesCasos,
        handerName: "Terminos y Condiciones"
      };

      await this.$store.dispatch("getData", lstobjAyuda);
    },
    isMobilResolution() {
      return this.getIsMobilResolution;
    }
  },
  async mounted() {
    //let instancia = this;
    JQ(document.getElementById("container-preguntas")).on("scroll", function() {
      // Any code to be executed when the window is scrolled
      if ($(this).scrollTop() > 100) {
        $("#scroll").fadeIn();
      } else {
        $("#scroll").fadeOut();
      }
    });

    this.$store.commit("showLoader");

    const TermsAndConditionsRequest = await this.$userService.getTermsAndConditions(
      this.evaluaRespuestaErrorRequestMultiplesCasos
    );

    const dt5 = TermsAndConditionsRequest.cont5 ? JSON.parse(TermsAndConditionsRequest.cont5) : null;

    const dt6 = TermsAndConditionsRequest.cont6 ? JSON.parse(TermsAndConditionsRequest.cont6) : null;

    const dt9 = TermsAndConditionsRequest.cont9 ? JSON.parse(TermsAndConditionsRequest.cont9) : null;

    const dt10 = TermsAndConditionsRequest.cont10 ? JSON.parse(TermsAndConditionsRequest.cont10) : null;

    const dt11 = TermsAndConditionsRequest.cont11 ? JSON.parse(TermsAndConditionsRequest.cont11) : null;

    if (TermsAndConditionsRequest && TermsAndConditionsRequest.cont5 && dt5.contenido_esp) {
      this.termsAndConditions.general = dt5.contenido_esp;
    }

    if (dt6 && dt6.contenido_esp) {
      let htmlContent = dt6.contenido_esp.toString().replace(/table/g, 'table class="table table-sm"');
      htmlContent = htmlContent.toString().replace(/style="width:500px"/g, "");
      htmlContent = htmlContent.toString().replace(/style="width:800px"/g, "");
      htmlContent = htmlContent.toString().replace(/border="2"/g, "");
      htmlContent = htmlContent.toString().replace(/cellpadding="1"/g, "");
      htmlContent = htmlContent.toString().replace(/cellspacing="1"/g, "");

      this.termsAndConditions.limits = htmlContent;
    }

    if (dt9 && dt9.contenido_esp) {
      if (this.getIsMobilResolution) {
        let htmlContent = dt9.contenido_esp;
        htmlContent = htmlContent.toString().replace(/<img/g, '<img style="width:15em; height:20em;"');
        this.termsAndConditions.lotto = htmlContent;
      } else {
        this.termsAndConditions.lotto = dt9.contenido_esp;
      }
    }

    if (dt10 && dt10.contenido_esp) {
      if (this.getIsMobilResolution) {
        let htmlContent = dt10.contenido_esp;
        htmlContent = htmlContent.toString().replace(/<img/g, '<img style="width:15em; height:20em;"');
        this.termsAndConditions.fortune = htmlContent;
      } else {
        this.termsAndConditions.fortune = dt10.contenido_esp;
      }
    }
    if (dt11 && dt11.contenido_esp) {
      if (this.getIsMobilResolution) {
        let htmlContent = dt11.contenido_esp;
        htmlContent = htmlContent.toString().replace(/<img/g, '<img style="width:15em; height:10em;"');
        htmlContent = htmlContent.toString().replace(/<ul>/g, '<div class="text-dark">');
        this.termsAndConditions.sport = htmlContent;
      } else {
        this.termsAndConditions.sport = dt11.contenido_esp;
      }
    }

    this.$store.commit("hideLoader");

    this.getAyuda();
  }
};

/*function Scrolled(instancia) {
  var winheight = JQ(document.getElementById("container-terminos")).height();
  var docheight = JQ(document).height();
  var scrollTop = JQ(document.getElementById("container-terminos")).scrollTop();
  var trackLength = docheight - winheight;
  var pctScrolled = Math.floor((scrollTop / trackLength) * 100);

  //console.log(pctScrolled + "% Scrolled *");

  if (pctScrolled >= 5) {
    instancia.$emit("barSolid", true);
  } else {
    instancia.$emit("barSolid", false);
  }

  instancia.$parent.updateScroll(pctScrolled);
}*/
</script>
<style>
body {
  background-color: #253137;
}
</style>
<style scoped="scoped">
.custom-h1-title {
  font-size: 1.6em;
  text-align: center;
}
section {
  display: block;
  height: 100vh;
  overflow-y: auto;
}
.container-terminos {
  padding-top: 100px;
  min-height: 85vh !important;
}
.container {
  margin-top: 60px;
}
.container-modal {
  margin-top: 20px;
}
.container-movil {
  margin-top: 20px;
}
h1,
p {
  color: #fff;
}
/*#limits > table > tbody > tr(1) {
  background-color: #253137 !important;
}*/

.title-orange-web {
  font-size: 2em;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
  color: #fff;
}
.shade {
  font-size: 20px;
  background-color: rgba(233, 227, 151, 0.7);
}
.fixed {
  position: sticky;
  bottom: 20%;
  left: 10px;
}
</style>

<style scoped>
.container {
  height: 100% !important;
}
.accordion-container {
  width: 100% !important;
}
.card-customer {
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
}
</style>
